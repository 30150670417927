import { default as IALoading } from 'ia.react.loading';
import * as React from 'react';
import styled from 'styled-components';
import './Loading.css';

const Container = styled.div` 
    position:  ${(props) => props.position};
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden; 
    background-color: transparent;
    left: 0;    
    top: ${(props) => props.top};
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height};
    z-index: ${(props) => props.zIndex};
    
    @media (max-width: 575px) {
        height: calc(100% - 60px);
        position: fixed;
    }
`;

const Loading = (props) => {
  const component = (
    <Container
      className={`ia-loading ${props.show ? 'show' : ''}`}
      zIndex={props.zIndex}
      height={props.height}
      width={props.width}
      position={props.position}
    >

      <IALoading icon={props.icon} color={props.color} />
    </Container>
  );

  return component;
};

Loading.defaultProps = {
  color: '#003da5',
  height: 'calc(100% - 40px)',
  icon: 'ELEPHANT',
  iconWidth: '120px',
  top: '0',
  width: '100%',
  zIndex: 9999,
  position: 'relative',

};

export default Loading;
