import * as React from 'react';
import { Trans } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import styled from 'styled-components';
import i18n from '~/app/i18n';
import { Typography } from '@mui/material';
import type { TableCellProps } from '@mui/material/TableCell';
import Colors from '~/common/themes/colors';

type RenderFunction = (row: any) => JSX.Element;
type MUICellAlignmentType = TableCellProps['align'];

export interface IColumnsV2 {
  header: string;
  align: MUICellAlignmentType;
  render?: RenderFunction;
  value?: string;
  width?: string;
}

export interface IProps {
  data: any[];
  columns: IColumnsV2[];
  title: string | null;
}

const StyledHeaderCell = withStyles(TableCell, {
  root: {
    fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open sans', 'san-serif'].join(','),
    fontSize: '16px',
    color: '#1B1B1B',
    fontWeight: 700,
    backgroundColor: '#e6e6e6',
    padding: '16px 16px',
  },
});

const StyledTableCell = withStyles(TableCell, {
  root: {
    fontFamily: `${['Open sans', 'san-serif'].join(',')}!important`,
    fontSize: '14px!important',
    color: '#1B1B1B!important',
    padding: '8px 16px',
    fontWeight: 'unset!important' as any,
    '& a': {
      fontWeight: 'unset!important' as any,
      fontFamily: 'unset!important' as any,
      fontSize: 'unset!important' as any,
    },
  },
});

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:first': {
      borderTop: 'none !important;',
    },
  },
}));
export const SectionHeaderLabel = styled.div`
  color: #1b1b1b;
  font-size: 21px;
  font-weight: 700;
  padding-top: 30px;
  font-family: 'Montserrat Bold', 'Montserrat Regular', 'Montserrat', sans-serif;
  margin-bottom: 16px;
`;

/*
  This is a generic data table component that can be used to render any data table.
  It difers from V1 related to data structure, instead of array in V1,
    this uses object as this is the default type of data in most of the cases.
 */

export const DataTableV2 = ({
  data,
  columns,
  title,
  emptyData,
}: {
  data: any[];
  columns: IColumnsV2[];
  title: string | null;
  emptyData: React.ReactElement;
}) => (
  <div>
    {title && (
      <SectionHeaderLabel>
        <Typography variant="h3" component="div">
          <Trans i18nKey={title} />
        </Typography>
      </SectionHeaderLabel>
    )}
    <Table aria-label="data-table table" style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
      <TableHead>
        <TableRow>
          {columns &&
            columns.map((col, colIndex) => (
              <StyledHeaderCell
                key={`header-${colIndex}`}
                sx={{ width: columns[colIndex].width }}
                align={columns[colIndex].align as any}
              >
                {i18n.t(col.header) as string}
              </StyledHeaderCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((row, index) => {
            const borderTopValue = index === 0 ? 'none' : `1px solid ${Colors.iaGray2};`;
            return (
              <StyledTableRow key={`row-${index}`}>
                {columns &&
                  columns.map((col, colIndex) => (
                    <StyledTableCell
                      key={`row-${index}-cell-${colIndex}`}
                      sx={{ borderTop: borderTopValue, width: columns[colIndex].width }}
                      align={columns[colIndex].align as any}
                    >
                      {columns[colIndex]?.value || columns[colIndex]?.render?.(row)}
                    </StyledTableCell>
                  ))}
              </StyledTableRow>
            );
          })}
        {data.length === 0 && emptyData && (
          <TableRow>
            <StyledTableCell align="center" colSpan={columns.length}>
              {emptyData}
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </div>
);

export default DataTableV2;
