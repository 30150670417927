import { Upload } from 'antd';
import styled from 'styled-components';

import { ReactComponent as Paperclip } from './icon-paperclip.svg';

const { Dragger } = Upload;

export const StyledDiv = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const DragAndDropZone = styled(Dragger)`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23003DA5FF' stroke-width='2' stroke-dasharray='14' stroke-dashoffset='32' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  &:focus {
    background-image: none;
  }
`;

export const DragAndDropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px;
`;

export const Message = styled.p`
  &&& {
    color: #003da5;
    font-family: "OpenSans", "Open Sans Bold";
    font-weight: bold;
    font-size: 1.1em;
    margin: 0;
  }
`;

export const Icon = styled(Paperclip)`
  margin-bottom: 12px;
`;
