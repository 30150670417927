import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { StyledLinkButton } from '~/common/styles/baseStyle';

export class DocumentDeliveryStyle {
  public static readonly ConsolidatedViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  public static readonly DocumentDeliveryDescription = styled.div`
    grid-column-start: 1;
    margin-right: 15px;
    text-align: justify;
  `;

  public static readonly DocumentDeliveryNote = styled.div`
    margin-top: 15px;
  `;

  public static readonly Header = styled.div`
    display: grid;
    grid-template-columns: auto 242px;
    border: solid 4px rgba(239, 241, 244, 1);
    padding: 20px 20px 20px 20px;
  `;

  public static readonly NotificationContainer = styled.div`
    border: solid 4px rgba(239, 241, 244, 1);
    padding: 20px 20px 20px 20px;
    margin-top: 20px;
  `;

  public static readonly HorizontalScrollDiv = styled.div`
    padding-top: 20px;
    overflowx: 'auto';
  `;

  public static readonly ResetButton = styled.span`
    font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
    padding: 0px 20px 0px 20px;
    color: #003da5;
    cursor: pointer;
    &:active {
      color: #003da5;
    }
  `;

  public static readonly Consent = styled(TextArea)`
    display: inline-block;
    &&&.ant-input-disabled {
      background-color: #fff;
      opacity: 1;
      cursor: auto;
      color: rgba(0, 0, 0, 0.65);
      padding-bottom: 8px;
      margin-bottom: 8px;
    }
  `;

  public static readonly GreyBox = styled.div`
    background-color: rgba(242, 242, 242, 1);
    padding: 20px 10px 20px 10px;
    display: block;
    &:first-child {
      margin-bottom: 20px;
    }
  `;
  public static readonly ChangeAllContainer = styled.div`
    grid-column-start: 2;
    float: right;
  `;

  public static readonly SaveChangesContainer = styled.div``;

  public static readonly SubTitle = styled.h4`
    font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
    padding-bottom: 8px;
    color: #333333;
    margin: 0px;
  `;

  public static readonly StyledLinkButton = styled(StyledLinkButton)`
    padding: 10px 20px 0 20px;
    float: right;
  `;

  public static readonly iaButtonStyle = {
    margin: '0px',
    width: '170px',
    height: '45px',
    float: 'right' as const,
  };

  public static readonly ecoIcon = {
    gridColumn: 1,
    gridRow: 1,
    fontSize: '30px',
  };
}
