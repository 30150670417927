import { Reducer } from 'redux';
import { IPositionDetails, PositionDetailActionTypes } from '../types';

const initialState: Partial<IPositionDetails> = {
  isFetchingHistoricalData: false,
  isFetchingFinancialData: false,
  isFetchingStockDetailedInfoData: false,
  isFetchingFundDetailedInfoData: false,
  isFetchingProfile: false,
  isFetchingDividendsHistoricalData: false,
  isFetchingStockDetailedInfoDataRefresh: false,
  isFetchingStockDetailedInfoRefreshHasError: false,
  positions: {},
};

const handleFetchHistoricalData = (state: any) => ({
  ...state,
  isFetchingHistoricalData: true,
});

const handleFetchHistoricalDataSuccessful = (state: any, payload: any) => {
  const { key, historicalData } = payload;
  return {
    ...state,
    isFetchingHistoricalData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        historicalData,
      },
    },
  };
};

const handleFetchHistoricalDataFailure = (state: any, payload: any) => {
  const { historicalData, key } = payload;
  return {
    ...state,
    isFetchingHistoricalData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        historicalData,
      },
    },
  };
};

const handleFetchFinancialInfo = (state: any) => ({
  ...state,
  isFetchingFinancialData: true,
});

const handleFetchFinancialInfoSuccessful = (state: any, payload: any) => {
  const { financialData, key, freqKey } = payload;
  return {
    ...state,
    isFetchingFinancialData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        financialData: {
          ...state.positions[key]?.financialData,
          [freqKey]: financialData,
        },
      },
    },
  };
};

const handleFetchFinancialDataFailure = (state: any, payload: any) => {
  const { financialData, key, freqKey } = payload;
  return {
    ...state,
    isFetchingFinancialData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        financialData: {
          ...state.positions[key]?.financialData,
          [freqKey]: financialData,
        },
      },
    },
  };
};

const handleFetchStockDetailedInfo = (state: any) => ({
  ...state,
  isFetchingStockDetailedInfoData: true,
});

const handleFetchStockDetailedInfoSuccessful = (state: any, payload: any) => {
  const { stockDetailedInfo, key } = payload;
  return {
    ...state,
    isFetchingStockDetailedInfoData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        stockDetailedInfo,
      },
    },
  };
};

const handleFetchStockDetailedInfoFailure = (state: any, payload: any) => {
  const { stockDetailedInfo, key } = payload;
  return {
    ...state,
    isFetchingStockDetailedInfoData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        stockDetailedInfo,
      },
    },
  };
};

const handleFetchStockDetailedInfoRefresh = (state: any) => ({
  ...state,
  isFetchingStockDetailedInfoDataRefresh: true,
});

const handleFetchStockDetailedInfoRefreshSuccessful = (state: any, payload: any) => {
  const { stockDetailedInfo, key } = payload;

  // Determine if stock or etf information is needed to retain
  if (state.positions[key].stockDetailedInfo.stockInfo) {
    stockDetailedInfo.stockInfo = state.positions[key].stockDetailedInfo.stockInfo;
  }
  if (state.positions[key].stockDetailedInfo.etfInfo) {
    stockDetailedInfo.etfInfo = state.positions[key].stockDetailedInfo.etfInfo;
  }

  // Common stock/ETF information needed to retain between refreshes
  stockDetailedInfo.priceToEarningsRatioTTM = state.positions[key].stockDetailedInfo.priceToEarningsRatioTTM;
  stockDetailedInfo.beta = state.positions[key].stockDetailedInfo.beta;
  stockDetailedInfo.expectedDividendPerShare = state.positions[key].stockDetailedInfo.expectedDividendPerShare;
  stockDetailedInfo.expectedDividendYield = state.positions[key].stockDetailedInfo.expectedDividendYield;
  stockDetailedInfo.sharesOutstanding = state.positions[key].stockDetailedInfo.sharesOutstanding;
  stockDetailedInfo.marketCap = state.positions[key].stockDetailedInfo.marketCap;

  return {
    ...state,
    isFetchingStockDetailedInfoDataRefresh: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        stockDetailedInfo,
      },
    },
  };
};

const handleFetchStockDetailedInfoRefreshFailure = (state: any, payload: any) => {
  const { key } = payload;
  return {
    ...state,
    isFetchingStockDetailedInfoDataRefresh: false,
    isFetchingStockDetailedInfoRefreshHasError: true,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
      },
    },
  };
};

const handleFetchFundDetailedInfo = (state: any) => ({
  ...state,
  isFetchingFundDetailedInfoData: true,
});

const handleFetchFundDetailedInfoSuccessful = (state: any, payload: any) => {
  const { fundDetailedInfo, key } = payload;
  return {
    ...state,
    isFetchingFundDetailedInfoData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        fundDetailedInfo,
      },
    },
  };
};

const handleFetchFundDetailedInfoFailure = (state: any, payload: any) => {
  const { fundDetailedInfo, key } = payload;
  return {
    ...state,
    isFetchingFundDetailedInfoData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        fundDetailedInfo,
      },
    },
  };
};

const handleFetchEarningsEstimate = (state: any) => ({
  ...state,
  isFetchingEarningsEstimate: true,
});

const handleFetchEarningsEstimateSuccessful = (state: any, payload: any) => {
  const { earningsEstimate, key } = payload;
  return {
    ...state,
    isFetchingEarningsEstimate: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        earningsEstimate,
      },
    },
  };
};

const handleFetchEarningsEstimateFailure = (state: any, payload: any) => {
  const { earningsEstimate, key } = payload;
  return {
    ...state,
    isFetchingEarningsEstimate: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        earningsEstimate,
      },
    },
  };
};

const handleFetchDividentsHistoricalData = (state: any) => ({
  ...state,
  isFetchingDividendsHistoricalData: true,
});

const handleFetchDividendsHistoricalDataSuccessful = (state: any, payload: any) => {
  const { dividendsHistoricalData, key } = payload;
  return {
    ...state,
    isFetchingDividendsHistoricalData: false,
    positions: {
      ...state.positions,
      [key]: {
        ...state.positions[key],
        dividendsHistoricalData,
      },
    },
  };
};

const handleFetchDividendsHistoricalDataFailure = (state: any) => ({
  ...state,
  isFetchingDividendsHistoricalData: false,
});

export const positionDetailReducer: Reducer<Partial<IPositionDetails>> = (state = initialState, action) => {
  switch (action.type) {
    case PositionDetailActionTypes.FETCH_HISTORICAL_DATA:
      return handleFetchHistoricalData(state);
    case PositionDetailActionTypes.FETCH_HISTORICAL_DATA_SUCCESSFUL:
      return handleFetchHistoricalDataSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_HISTORICAL_DATA_FAILED:
      return handleFetchHistoricalDataFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_FINANCIAL_DATA:
      return handleFetchFinancialInfo(state);
    case PositionDetailActionTypes.FETCH_FINANCIAL_DATA_SUCCESSFUL:
      return handleFetchFinancialInfoSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_FINANCIAL_DATA_FAILED:
      return handleFetchFinancialDataFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA:
      return handleFetchStockDetailedInfo(state);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_SUCCESSFUL:
      return handleFetchStockDetailedInfoSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_FAILED:
      return handleFetchStockDetailedInfoFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH:
      return handleFetchStockDetailedInfoRefresh(state);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_SUCCESSFUL:
      return handleFetchStockDetailedInfoRefreshSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_FAILED:
      return handleFetchStockDetailedInfoRefreshFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA:
      return handleFetchFundDetailedInfo(state);
    case PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA_SUCCESSFUL:
      return handleFetchFundDetailedInfoSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA_FAILED:
      return handleFetchFundDetailedInfoFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE:
      return handleFetchEarningsEstimate(state);
    case PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE_SUCCESSFUL:
      return handleFetchEarningsEstimateSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE_FAILURE:
      return handleFetchEarningsEstimateFailure(state, action.payload);
    case PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_DATA:
      return handleFetchDividentsHistoricalData(state);
    case PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_DATA_SUCCESSFUL:
      return handleFetchDividendsHistoricalDataSuccessful(state, action.payload);
    case PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_FAILED:
      return handleFetchDividendsHistoricalDataFailure(state);
    default:
      return state;
  }
};
