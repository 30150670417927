import { DataLoadStampContainer, LoadStampContainer, MainBox, UpdateDataContainer } from '~/common/styles/baseStyle';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import i18n from '~/app/i18n';
import Colors from '~/common/themes/colors';
import { IconButton, Typography } from '@mui/material';
import { appTheme } from '~/common/themes/theme';
import SyncIcon from '@mui/icons-material/Sync';
import Performance from '~/common/components/position-overview/performance/Performance';
import { Trans } from 'react-i18next';
import { ProductHeaderContainer } from '../../position-overview/ProductHeaderContainer';
import { PositionName } from '../../position-overview/PositionName';
import DataLoadStamp from '../../loadStamp/DataLoadStamp';
import AlertContainer from '../../info-container/alert-container';
import MarketProductHeaderSection from './MarketProductHeaderSection';
import { IndexSummary } from './IndexSummary';
import { TrendType } from '~/stores/marketData/marketDataTypes';
import { CurrencySummary } from './CurrencySummary';
import { isFieldEmptyOrNull } from '~/common/helpers';

export interface IProps {
  symbolDescription: string;
  productData: any;
  type: TrendType;
  didSetMarketDataRealtime: boolean;
  isLiveData: boolean;
  onBannerRefreshButtonClick?: () => void;
  onBannerRefreshButtonState?: boolean;
  didFetchMarketProductDetailFailed: boolean;
}

interface IMarketProductViewState {}

class MarketProductView extends React.PureComponent<IProps, IMarketProductViewState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  public componentDidMount() {
    if (this.props.type === 'commodity') document.title = i18n.t('marketProductDetails.commodityTitle');
    else if (this.props.type === 'currency') document.title = i18n.t('marketProductDetails.currencyTitle');
    else if (this.props.type === 'index') document.title = i18n.t('marketProductDetails.indexTitle');
    else document.title = '';
  }

  public render() {
    const { productData, symbolDescription, type } = this.props;
    const shouldNotShowMarketDataUpdateButton = this.props.didFetchMarketProductDetailFailed;
    const updatedHistory = !isFieldEmptyOrNull(productData.history)
      ? productData.history.map((e: any) => ({ ...e, volume: e.volume || 0, adjustedClose: e.adjustedClose || 0 }))
      : {};
    const performanceData = { symbol: productData.symbol, periodicalDatas: updatedHistory };
    const isTypeIndex = type === 'index';
    const isTypeCurrency = type === 'currency';
    // const isTypeCommodity = type === 'commodity';
    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <ProductHeaderContainer>
            <>
              {!isTypeCurrency && (
                <PositionName symbol={productData.symbol} name={symbolDescription} symbolColor={Colors.cobalt} />
              )}
              {isTypeCurrency && (
                <PositionName
                  symbol={i18n.t(`marketProductDetails.currencySymbols.${productData.symbol}`)}
                  name={i18n.t(`marketsPage.trends.table.currencySymbols.${productData.symbol}`)}
                  symbolColor={Colors.cobalt}
                />
              )}
            </>
            <Grid style={{ paddingTop: 5, paddingBottom: 15 }}>
              <Grid item xs={12}>
                <LoadStampContainer>
                  <UpdateDataContainer>
                    {!shouldNotShowMarketDataUpdateButton && (
                      <IconButton
                        onClick={this.props.onBannerRefreshButtonClick}
                        style={{
                          fontFamily: '"OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans"',
                          fontWeight: 650,
                          fontStyle: 'normal',
                          color: appTheme.palette.secondary.dark,
                          fontSize: '14px',
                          padding: '0',
                        }}
                      >
                        {i18n.t('landing.updateData') as string}
                        <SyncIcon fill={appTheme.palette.secondary.dark} />
                      </IconButton>
                    )}
                  </UpdateDataContainer>
                  <DataLoadStampContainer>
                    <DataLoadStamp isMarketDataTimestamp isLiveData isMarketScreen />
                  </DataLoadStampContainer>
                </LoadStampContainer>
              </Grid>
            </Grid>
            <>
              {shouldNotShowMarketDataUpdateButton && (
                <div>
                  <AlertContainer
                    text="marketProductDetails.alertMarketIndexMessage"
                    buttonText="landing.updateData"
                    onButtonClick={this.props.onBannerRefreshButtonClick}
                    isButtonLoading={this.props.onBannerRefreshButtonState}
                  />
                </div>
              )}
            </>
            <MainBox>
              <Grid container>
                <MarketProductHeaderSection marketProductData={productData} />
                {isTypeIndex && <IndexSummary indexData={productData} />}
                {/* {isTypeCommodity && <CommoditySummary commodityData={productData} />} */}
                {isTypeCurrency && <CurrencySummary currencyData={productData} />}
              </Grid>
            </MainBox>
          </ProductHeaderContainer>
        </Grid>
        <Grid item xs={12}>
          {!isFieldEmptyOrNull(productData.history) && (
            <Performance
              symbol={productData.symbol}
              historicalData={performanceData}
              sectionTitleKey="marketProductDetails.performanceViewTitle"
              isMarketProduct
              dataKey="close"
              defaultZoomFilter="1Y"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: Colors.anotherGrayShade6 }}>
            <Trans i18nKey="marketsPage.disclaimer" />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default MarketProductView;
