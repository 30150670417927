import * as React from 'react';
import styled from 'styled-components';
import { Tooltip, Zoom } from '@mui/material';
import { Info } from '@mui/icons-material';
import { Trans } from 'react-i18next';

export const OverviewToolTip = styled.div`
    color: #000;
    padding:0px;
    padding-left:2px;
    display:inline-block;
    svg {
        width: 16px;
        height: 16px;
        vertical-align: top;
    }
`;

export interface IToolTipProps {
  transKey: string,
  color?: string
}

export class ToolTip extends React.PureComponent<IToolTipProps> {
  public render() {
    return (
      <OverviewToolTip>
        <Tooltip title={<Trans i18nKey={this.props.transKey} />} TransitionComponent={Zoom} arrow>
          <Info style={{ color: this.props.color ? this.props.color : '#BBC2CA' }} />
        </Tooltip>
      </OverviewToolTip>
    );
  }
}
