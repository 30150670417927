import { Cultures, ErrorTypes } from '~/app/appTypes';
import { EventHttpClient } from '~/stores/events/services/EventHttpClient';
import { ISystemState, IToken, SystemActionTypes, IFeatureToggles } from '~/stores/system/systemTypes';
import moment from 'moment';
import { Reducer } from 'redux';

import { ClearSession } from '../../../app/sessionManager';

export const initialState: ISystemState = {
  culture: Cultures.english,
  hasError: false,
  token: undefined,
  tokenExpirationDate: undefined,
  isClaimsFetching: true,
  showDocumentDelivery: false,
  showContactCard: false,
  showAssetAllocationToggleButton: false,
  isAssetBreakdown: false,
  sessionExpiration: moment().add(SystemActionTypes.SESSION_MINUTES, 'minutes').toDate(),
  user: undefined,
};

const handleAllocationBreakdownView = (state: ISystemState, isAssetBreakdown: boolean) => ({
  ...state,
  isAssetBreakdown,
});

const handleFetchClaims = (state: ISystemState) => ({
  ...state,
  isClaimsFetching: true,
});

const handleFetchClaimsAborted = (state: ISystemState) => ({
  ...state,
  isClaimsFetching: false,
});

const handleFetchClaimsFailed = (state: ISystemState) => {
  sessionStorage.removeItem('partyId');

  return {
    ...state,
    isClaimsFetching: false,
  };
};

const handleFetchClaimsSuccessful = (state: ISystemState, token: IToken) => ({
  ...state,
  isClaimsFetching: false,
  user: token,
  tokenExpirationDate: moment().add(1, 'd').startOf('day').toDate(),
});

const handleFetchFeatureToggle = (state: ISystemState, features: IFeatureToggles): ISystemState => ({
  ...state,
  showDocumentDelivery: features.showDocumentDelivery,
  showContactCard: features.showContactCard,
  showAssetAllocationToggleButton: features.showAssetAllocationToggleButton,
});

const handleSetCulture = (state: ISystemState, culture: string) => ({
  ...state,
  culture,
});

const handleGlobalError = (state: any, err: any) => {
  console.log(err);

  if (err && (err === ErrorTypes.NoAccount || err === ErrorTypes.Token)) {
    LogOut();
    return;
  }

  let errorDetails = '';
  let partyId = null;

  if (err && err.payload) {
    errorDetails += `Error payload: ${err.payload}`;

    if (err.payload.response) {
      errorDetails += ` [Error.response: ${JSON.stringify(err.payload.response)}] `;
    }
  }

  errorDetails +=
    `  Browser details: [User-Agent > ${navigator.userAgent}]` +
    ` [Platform > ${navigator.platform}]` +
    ` [Cookies Enabled > ${navigator.cookieEnabled}]` +
    ` [Browser Version > ${navigator.appVersion}]`;

  try {
    partyId = sessionStorage.getItem('partyId');

    if (partyId === null) {
      EventHttpClient.trackError('Client iAs', errorDetails);
    } else {
      EventHttpClient.trackError(partyId, errorDetails);
    }
  } catch (error) {
    console.log(error);
  }

  if (!window.location.hostname.includes('localhost')) {
    if (
      err.payload.config &&
      err.payload.config.url &&
      err.payload.config.url.indexOf('/networks/iawealth/claims') !== -1
    ) {
      LogOut();
    }

    if (err.payload.response && err.payload.response.status) {
      const errorCode = err.payload.response.status;
      if (errorCode >= 400 && errorCode !== 404) {
        // TODO: Leo - We need to improve this error handling.
        console.log('Logout called from error handling');
        LogOut();
      }
    }
  }

  return {
    ...state,
    hasError: true,
  };
};

export function LogOut() {
  try {
    ClearSession();
    const clientSessionName = 'clientSession';
    document.cookie = `${clientSessionName}=${''};path=/`;
    const logoutButton = document.querySelector('.logoff');
    if (logoutButton !== null) {
      const location = logoutButton.getAttribute('href');
      if (location !== null) {
        window.location.href = location;
      } else {
        window.location.href = '/';
      }
    } else {
      window.location.href = '/';
    }
  } catch (error) {
    console.log(error);
    window.location.href = '/';
  }
}

let timer: ReturnType<typeof setTimeout>;
const handleRefreshSession = (state: ISystemState) => {
  if (moment().toDate() > state.sessionExpiration) {
    console.log('Session expire');
    LogOut();
  }
  clearTimeout(timer);
  timer = setTimeout(() => LogOut(), 60000 * SystemActionTypes.SESSION_MINUTES);
  return {
    ...state,
    sessionExpiration: moment().add(SystemActionTypes.SESSION_MINUTES, 'minutes').toDate(),
  };
};

export const systemReducer: Reducer<ISystemState> = (state = initialState, action) => {
  switch (action.type) {
    case SystemActionTypes.SET_CULTURE:
      return handleSetCulture(state, action.payload);
    case SystemActionTypes.SYSTEM_GLOBAL_ERROR:
      return handleGlobalError(state, action);
    case SystemActionTypes.FETCH_CLAIMS:
      return handleFetchClaims(state);
    case SystemActionTypes.FETCH_CLAIMS_ABORTED:
      return handleFetchClaimsAborted(state);
    case SystemActionTypes.FETCH_CLAIMS_FAILED:
      return handleFetchClaimsFailed(state);
    case SystemActionTypes.FETCH_CLAIMS_SUCCESSFUL:
      return handleFetchClaimsSuccessful(state, action.payload);
    case SystemActionTypes.FETCH_FEATURE_TOGGLE_SUCCESSFUL:
      return handleFetchFeatureToggle(state, action.payload);
    case SystemActionTypes.REFRESH_SESSION:
      return handleRefreshSession(state);
    case SystemActionTypes.SET_ASSETALLOCATION_BREAKDONW:
      return handleAllocationBreakdownView(state, action.payload);
    default:
      return state;
  }
};
