import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckmarkImage } from './icon-checkmark.svg';

const CheckmarkContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: #696969;
    border-style: solid;
    background-color: #e6e6e6;
    border-width: 1px;
    cursor: pointer;
    width: 16px;
    height: 16px;
`;
export const CheckmarkImageContainer = styled.div<{ isVisible: boolean }>`
    display: ${(props) => props.isVisible ? 'flex' : 'none'};
`;

export interface ICheckmarkProps {
  isChecked: boolean,
  id: any,
  onClick: (id: any) => void
}

class Checkmark extends React.PureComponent<ICheckmarkProps> {
  public handleCheckmarkContainerClick = () => {
    const { onClick, id } = this.props;

    onClick(id);
  };

  public render() {
    const { isChecked } = this.props;

    return (
      <CheckmarkContainer onClick={this.handleCheckmarkContainerClick}>
        <CheckmarkImageContainer isVisible={isChecked}>
          <CheckmarkImage width={15} height={15} />
        </CheckmarkImageContainer>
      </CheckmarkContainer>
    );
  }
}

export default Checkmark;
