import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getDidSetMarketDataRealtime, getIsPartyFetching, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { Switch as SwitchToggle } from 'antd';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { Trans } from 'react-i18next';
import { Container, HeaderPageContainer } from '~/common/styles/baseStyle';
import i18n from '~/app/i18n';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { saveMarketDataRealtime } from '~/stores/party/actions/partyActions';
import { Box, Typography } from '@mui/material';
import {
  HeaderBackItem,
  BackIconStyle,
  SettingPageTitleContainer,
  SettingItemRow,
  SettingBox,
} from './SettingPage.style';
import NicknameView from '../householding-page/nickname-view/NicknameView';
import Loading from '../../common/components/Loading/Loading';
import DocumentDeliveryView from '../householding-page/documentdelivery-view/DocumentDeliveryView';
import AccessSharingView from '../householding-page/accessharing-view/AccessSharingView';
import HouseholdView from '../householding-page/household-view/HouseholdView';
import ConsentView, { ConsentViewMode } from '../householding-page/consent-view/ConsentView';
import { isConsentFeatureActiveForClient } from '~/common/helpers';
import HoldingsSettingsView from './Holdings/HoldingsSettingsView';

export interface IPropsFromState {
  party: IPartyV1;
  isPartyFetching: boolean;
  culture: string;
  didSetMarketDataRealtime: boolean;
  bIsUserAdvisorOrAdmin: boolean;
}

interface IPropsFromDispatch {
  saveMarketDataRealtime: typeof saveMarketDataRealtime;
}

export class SettingPage extends React.PureComponent<RouteComponentProps & IPropsFromDispatch & IPropsFromState> {
  constructor(props: RouteComponentProps & IPropsFromState & IPropsFromDispatch) {
    super(props);

    document.title = i18n.t('setting.documentTitle');
  }

  public saveMarketDataLivePreference = (checked: boolean) => {
    this.props.saveMarketDataRealtime(checked);
  };

  private renderSettingView = () => {
    const { party, didSetMarketDataRealtime, bIsUserAdvisorOrAdmin } = this.props;
    return (
      <>
        <HeaderPageContainer>
          <Typography variant="h1" component="span" style={{ paddingBottom: '20px', paddingTop: '40px' }}>
            <Trans i18nKey="setting.title" />
          </Typography>
        </HeaderPageContainer>
        <SettingItemRow>
          <SettingBox>
            <Link to={RouteNames.settingdocumentDelivery} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" style={{ paddingBottom: '10px' }}>
                <Typography variant="h3" color="textSecondary">
                  <Trans i18nKey="setting.documentPreferencesTitle" />
                </Typography>
                <ForwardIcon />
              </Box>
            </Link>
            <Typography variant="body1">
              <Trans i18nKey="setting.documentPreferencesText" />
            </Typography>
          </SettingBox>
          <SettingBox>
            <Link to={RouteNames.settingAccessSharing} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" style={{ paddingBottom: '10px' }}>
                <Typography variant="h3" color="textSecondary">
                  <Trans i18nKey="setting.accessSharingtTitle" />
                </Typography>
                <ForwardIcon />
              </Box>
            </Link>
            <Typography variant="body1">
              <Trans i18nKey="setting.accessSharingText" />
            </Typography>
            <br />
            <Box display="flex" alignItems="center">
              <Typography variant="h6" component="span">
                <Trans i18nKey="setting.householdKey" />
              </Typography>
              <Typography variant="body1" component="span">
                : {party.id.substr(party.id.length - 6)}
              </Typography>
            </Box>
          </SettingBox>
        </SettingItemRow>
        <SettingItemRow>
          <SettingBox>
            <Link to={RouteNames.settingHousehold} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" style={{ paddingBottom: '10px' }}>
                <Typography variant="h3" color="textSecondary">
                  <Trans i18nKey="setting.householdTitle" />
                </Typography>
                <ForwardIcon />
              </Box>
            </Link>
            <Typography variant="body1">
              <Trans i18nKey="setting.householdText" />
            </Typography>
          </SettingBox>
          <SettingBox>
            <Link to={RouteNames.settingNickname} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" style={{ paddingBottom: '10px' }}>
                <Typography variant="h3" color="textSecondary">
                  <Trans i18nKey="setting.accountNicknamesTitle" />
                </Typography>
                <ForwardIcon />
              </Box>
            </Link>
            <Typography variant="body1">
              <Trans i18nKey="setting.accountNicknamesText" />
            </Typography>
          </SettingBox>
        </SettingItemRow>
        <SettingItemRow>
          <SettingBox>
            <Link to={RouteNames.settingHoldings} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" style={{ paddingBottom: '10px' }}>
                <Typography variant="h3" color="textSecondary">
                  <Trans i18nKey="setting.holdingsTitle" />
                </Typography>
                <ForwardIcon />
              </Box>
            </Link>
            <Typography variant="body1">
              <Trans i18nKey="setting.holdingsText" />
            </Typography>
          </SettingBox>
          <SettingBox style={{ background: 'transparent', boxShadow: 'none', border: 'none' }}></SettingBox>
        </SettingItemRow>
      </>
    );
  };

  private renderHouseholdView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.householdTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <HouseholdView />
    </>
  );

  private renderAccessSharingView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.accessSharingtTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <AccessSharingView />
    </>
  );

  private renderDocumentDeliveryView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.documentPreferencesTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <DocumentDeliveryView />
    </>
  );

  private renderNicknameView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.accountNicknamesTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <NicknameView />
    </>
  );

  private renderConsentView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.consentTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <ConsentView viewMode={ConsentViewMode.Page} />
    </>
  );

  private renderHoldingsView = () => (
    <>
      <HeaderPageContainer>
        <SettingPageTitleContainer>
          {this.renderBackToSetting()}
          <Typography variant="h1" component="span">
            <Trans i18nKey="setting.holdingsTitle" />
          </Typography>
        </SettingPageTitleContainer>
      </HeaderPageContainer>
      <HoldingsSettingsView />
    </>
  );

  private renderBackToSetting = () => (
    <Link to={RouteNames.settings} style={{ textDecoration: 'none' }}>
      <HeaderBackItem>
        <BackIcon style={BackIconStyle} />
        <Trans i18nKey="setting.title" />
      </HeaderBackItem>
    </Link>
  );

  private isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public render() {
    const { party, isPartyFetching } = this.props;

    if (isPartyFetching || this.isEmpty(party)) {
      return (
        <Container style={{ minHeight: 200 }}>
          <Loading show />
        </Container>
      );
    }

    return (
      <Container>
        <div>
          <Switch>
            <Route exact path={RouteNames.settings} render={this.renderSettingView} />
            <Route path={`${RouteNames.settingHousehold}`} render={this.renderHouseholdView} />
            <Route path={`${RouteNames.settingAccessSharing}`} render={this.renderAccessSharingView} />
            <Route path={`${RouteNames.settingdocumentDelivery}`} render={this.renderDocumentDeliveryView} />
            <Route path={`${RouteNames.settingNickname}`} render={this.renderNicknameView} />
            <Route path={`${RouteNames.settingHoldings}`} render={this.renderHoldingsView} />
            {isConsentFeatureActiveForClient(this.props.party) && (
              <Route path={`${RouteNames.settingConsent}`} render={this.renderConsentView} />
            )}
            <Route render={this.renderSettingView} />
          </Switch>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isPartyFetching: getIsPartyFetching(state),
    party: getPartyV1(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    bIsUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    culture: system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveMarketDataRealtime,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
