import { getDocumentsFromMessage } from '~/common/utils/DocumentHelper';
import { Reducer } from 'redux';
import { IMessage } from '../interfaces/Message';
import { IMessageState, MessageActionTypes } from '../messageTypes';

export const initialState: IMessageState = {
  isSendingMessage: false,
  isSendingMessageSuccess: false,
  isSendingMessageSuccessWithWarning: false,
  isSendingMessageFail: false,
  isSendingMessageResult: {},
  documents: [],
  isFetchingDocuments: false,
  isFetchingDocumentsSuccess: false,
  isFetchingDocumentsFail: false,
  isDocumentsLoaded: false,
};

const handleSendMessage = (state: IMessageState, payload: any) => ({
  ...state,
  isSendingMessage: true,
  isSendingMessageSuccess: false,
  isSendingMessageSuccessWithWarning: false,
  isSendingMessageFail: false,
  isSendingMessageResult: {},
});

const handleSendMessageSuccess = (state: IMessageState, message: IMessage) => {
  const allDocuments = buildDocumentsAfterSendSuccess(state, message);

  return {
    ...state,
    isSendingMessage: false,
    isSendingMessageSuccess: true,
    isSendingMessageSuccessWithWarning: false,
    isSendingMessageFail: false,
    documents: allDocuments,
  };
};

const handleSendMessageSuccessWithWarning = (state: IMessageState, payload: any) => {
  const { message, warning } = payload;
  const allDocuments = buildDocumentsAfterSendSuccess(state, message);

  return {
    ...state,
    isSendingMessage: false,
    isSendingMessageSuccess: true,
    isSendingMessageSuccessWithWarning: true,
    isSendingMessageFail: false,
    isSendingMessageResult: warning,
    documents: allDocuments,
  };
};

const handleSendMessageError = (state: IMessageState, payload: any) => ({
  ...state,
  isSendingMessage: false,
  isSendingMessageSuccess: false,
  isSendingMessageSuccessWithWarnings: false,
  isSendingMessageFail: true,
  isSendingMessageResult: payload,
});

const handleFetchSharedDocuments = (state: IMessageState, payload: any) => ({
  ...state,
  isFetchingDocuments: true,
  isFetchingDocumentsSuccess: false,
  isFetchingDocumentsFail: false,
  isDocumentsLoaded: true,
  documents: [],
});

const handleFetchSharedDocumentsSuccess = (state: IMessageState, payload: any) => ({
  ...state,
  isFetchingDocumentsSuccess: true,
  isFetchingDocumentsFail: false,
  documents: payload,
});

const handleFetchSharedDocumentsFail = (state: IMessageState, payload: any) => ({
  ...state,
  isFetchingDocumentsSuccess: false,
  isFetchingDocumentsFail: true,
  documents: [],
});

const handleResetSendMessage = (state: IMessageState, payload: any) => ({
  ...state,
  isSendingMessage: false,
  isSendingMessageSuccess: false,
  isSendingMessageSuccessWithWarning: false,
  isSendingMessageFail: false,
  isSendingMessageResult: {},
});

export const buildDocumentsAfterSendSuccess = (state: IMessageState, message: any) => {
  const newDocuments = getDocumentsFromMessage(message, message.fromId);
  let allDocuments = newDocuments;

  if (state.documents) {
    allDocuments = [...state.documents, ...newDocuments];
  }

  return allDocuments;
};

export const messageReducer: Reducer<IMessageState> = (state = initialState, action) => {
  switch (action.type) {
    case MessageActionTypes.SEND_MESSAGE:
      return handleSendMessage(state, action.payload);
    case MessageActionTypes.SEND_MESSAGE_ERROR:
      return handleSendMessageError(state, action.payload);
    case MessageActionTypes.SEND_MESSAGE_SUCCESS:
      return handleSendMessageSuccess(state, action.payload);
    case MessageActionTypes.SEND_MESSAGE_SUCCESS_WITH_WARNING:
      return handleSendMessageSuccessWithWarning(state, action.payload);
    case MessageActionTypes.FETCH_DOCUMENTS:
      return handleFetchSharedDocuments(state, action.payload);
    case MessageActionTypes.FETCH_DOCUMENTS_SUCCESS:
      return handleFetchSharedDocumentsSuccess(state, action.payload);
    case MessageActionTypes.FETCH_DOCUMENTS_ERROR:
      return handleFetchSharedDocumentsFail(state, action.payload);
    case MessageActionTypes.RESET_MESSAGE:
      return handleResetSendMessage(state, action.payload);
    default:
      return state;
  }
};
