import * as React from 'react';
import NumberQI from '../../changeValue/NumberQI';
import { ComponentWithValidation } from '../../position-overview/summary-table/ComponentWithValidation';
import { IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import Colors from '~/common/themes/colors';
import ChangeValue from '../../changeValue/ChangeValue';
import i18n from '~/app/i18n';

export interface ISummaryAccountValue {
  labelKey: string;
  toolTipKey?: string;
  toolTipColor?: string;
  positionValue: JSX.Element;
  xsItem: number;
}

export type INullableSummaryAccountValue = ISummaryAccountValue | null;

export function buildDataArrayForAccountValuePage(
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      clientAccountTopLevelInfo.previousDayChangePercent,
      clientAccountTopLevelInfo.previousDayChangeValue,
      'holdings.definitions.PreviousDayChange.Description',
      Colors.cobalt,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue(
        'common.intradayChange',
        4,
        clientAccountTopLevelInfo.daysChangePercent,
        clientAccountTopLevelInfo.daysChange,
        'holdings.definitions.IntradayChange.Tooltip',
        Colors.cobalt,
      ),
    );
  }

  values.push(createSimpleMoneySummaryAccountValue('common.cashBalance', 4, clientAccountTopLevelInfo.cashBalanceCad));

  if (isNotMarginAvailableAtPartyLevel(clientAccountTopLevelInfo)) {
    return values;
  }

  values.push(createSimpleMoneySummaryAccountValue('common.margin', 4, clientAccountTopLevelInfo.marginValueCad));

  return values;
}

export function buildDataArrayForConsolidatedPage(
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      clientAccountTopLevelInfo.previousDayChangePercent,
      clientAccountTopLevelInfo.previousDayChangeValue,
      'holdings.definitions.PreviousDayChange.Description',
      Colors.cobalt,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue(
        'common.intradayChange',
        4,
        clientAccountTopLevelInfo.daysChangePercent,
        clientAccountTopLevelInfo.daysChange,
        'holdings.definitions.IntradayChange.Tooltip',
        Colors.cobalt,
      ),
    );
  }

  values.push(createSimpleMoneySummaryAccountValue('common.cashBalance', 4, clientAccountTopLevelInfo.cashBalanceCad));
  if (!isNotMarginAvailableAtPartyLevel(clientAccountTopLevelInfo)) {
    values.push(createSimpleMoneySummaryAccountValue('common.margin', 4, clientAccountTopLevelInfo.marginValueCad));
  }

  return values;
}

export function buildDataArrayForInvestedCapitalPage(
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      clientAccountTopLevelInfo.previousDayChangePercent,
      clientAccountTopLevelInfo.previousDayChangeValue,
      'holdings.definitions.PreviousDayChange.Description',
      Colors.cobalt,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue(
        'common.intradayChange',
        4,
        clientAccountTopLevelInfo.daysChangePercent,
        clientAccountTopLevelInfo.daysChange,
        'holdings.definitions.IntradayChange.Tooltip',
        Colors.cobalt,
      ),
    );
  }

  values.push(createSimpleMoneySummaryAccountValue('common.cashBalance', 4, clientAccountTopLevelInfo.cashBalanceCad));
  if (!isNotMarginAvailableAtPartyLevel(clientAccountTopLevelInfo)) {
    values.push(createSimpleMoneySummaryAccountValue('common.margin', 4, clientAccountTopLevelInfo.marginValueCad));
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'holdings.accountTabView.investedCapital',
      4,
      clientAccountTopLevelInfo.investedCapitalCAD,
      'holdings.definitions.InvestedCapital.Description',
      Colors.cobalt,
      i18n.t('holdings.NA'),
    ),
  );
  values.push(
    createComplexSummaryAccountValue(
      'holdings.accountTabView.investedCapitalTab.investedCapitalGainLoss',
      4,
      clientAccountTopLevelInfo.investedCapitalGainLossPercentage,
      clientAccountTopLevelInfo.investedCapitalGainLossCAD,
      'holdings.definitions.InvestedCapitalGainLoss.Description',
      Colors.cobalt,
    ),
  );

  return values;
}

export function buildDataArrayForBookCostPage(
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      clientAccountTopLevelInfo.previousDayChangePercent,
      clientAccountTopLevelInfo.previousDayChangeValue,
      'holdings.definitions.PreviousDayChange.Description',
      Colors.cobalt,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue(
        'common.intradayChange',
        4,
        clientAccountTopLevelInfo.daysChangePercent,
        clientAccountTopLevelInfo.daysChange,
        'holdings.definitions.IntradayChange.Tooltip',
        Colors.cobalt,
      ),
    );
  }

  values.push(createSimpleMoneySummaryAccountValue('common.cashBalance', 4, clientAccountTopLevelInfo.cashBalanceCad));

  if (!isNotMarginAvailableAtPartyLevel(clientAccountTopLevelInfo)) {
    values.push(createSimpleMoneySummaryAccountValue('common.margin', 4, clientAccountTopLevelInfo.marginValueCad));
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'holdings.accountTabView.bookCost',
      4,
      clientAccountTopLevelInfo.bookValueCad,
      'holdings.definitions.BookCost.Description',
      Colors.cobalt,
    ),
  );
  values.push(
    createComplexSummaryAccountValue(
      'holdings.accountTabView.bookCostTab.unrealizedGainLoss',
      4,
      clientAccountTopLevelInfo.unrealizedGainAndLossPercent,
      clientAccountTopLevelInfo.unrealizedGainAndLossCAD,
      'holdings.definitions.UnrealizedGainLoss.Description',
      Colors.cobalt,
    ),
  );

  return values;
}

export function buildDataArrayForAccountValueSection(
  account: IAccount,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const isCAD = isAccountCurrencyCAD(account);
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      account.previousDayChangePercent,
      account.previousDayChangeValue,
    ),
  ];
  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue('common.intradayChange', 4, account.daysChangePercent, account.daysChange),
    );
  }
  values.push(
    createSimpleMoneySummaryAccountValue(
      'common.cashBalance',
      4,
      isCAD ? account.cashBalanceCad : account.cashBalanceUsd,
    ),
  );
  if (isNotMarginAvailableAtAccountLevel(account)) {
    return values;
  }

  values.push(
    createSimpleMoneySummaryAccountValue('common.margin', 4, isCAD ? account.marginValueCad : account.marginValueUsd),
  );

  return values;
}

export function buildDataArrayForInvestedCapitalSection(
  account: IAccount,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const isCAD = isAccountCurrencyCAD(account);
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      account.previousDayChangePercent,
      account.previousDayChangeValue,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue('common.intradayChange', 4, account.daysChangePercent, account.daysChange),
    );
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'common.cashBalance',
      4,
      isCAD ? account.cashBalanceCad : account.cashBalanceUsd,
    ),
  );

  if (!isNotMarginAvailableAtAccountLevel(account)) {
    values.push(
      createSimpleMoneySummaryAccountValue('common.margin', 4, isCAD ? account.marginValueCad : account.marginValueUsd),
    );
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'holdings.accountTabView.investedCapital',
      4,
      isCAD ? account.investedCapitalCAD : account.investedCapitalUSD,
      '',
      '',
      i18n.t('holdings.NA'),
    ),
  );
  values.push(
    createComplexSummaryAccountValue(
      'holdings.accountTabView.investedCapitalTab.investedCapitalGainLoss',
      4,
      account.investedCapitalGainLossPercentage,
      isCAD ? account.investedCapitalGainLossCAD : account.investedCapitalGainLossUSD,
    ),
  );

  return values;
}

export function buildDataArrayForBookCostSection(
  account: IAccount,
  shouldNotShowMarketDataInfo: boolean,
): INullableSummaryAccountValue[] {
  const isCAD = isAccountCurrencyCAD(account);
  const values: INullableSummaryAccountValue[] = [
    createComplexSummaryAccountValue(
      'common.previosDayChange',
      4,
      account.previousDayChangePercent,
      account.previousDayChangeValue,
    ),
  ];

  if (!shouldNotShowMarketDataInfo) {
    values.push(
      createComplexSummaryAccountValue('common.intradayChange', 4, account.daysChangePercent, account.daysChange),
    );
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'common.cashBalance',
      4,
      isCAD ? account.cashBalanceCad : account.cashBalanceUsd,
    ),
  );
  if (!isNotMarginAvailableAtAccountLevel(account)) {
    values.push(
      createSimpleMoneySummaryAccountValue('common.margin', 4, isCAD ? account.marginValueCad : account.marginValueUsd),
    );
  }

  values.push(
    createSimpleMoneySummaryAccountValue(
      'holdings.accountTabView.bookCost',
      4,
      isCAD ? account.bookValueCad : account.bookValueUsd,
    ),
  );
  values.push(
    createComplexSummaryAccountValue(
      'holdings.accountTabView.bookCostTab.unrealizedGainLoss',
      4,
      account.unrealizedGainAndLossPercent,
      isCAD ? account.unrealizedGainAndLossCAD : account.unrealizedGainAndLossUSD,
    ),
  );

  return values;
}

function isAccountCurrencyCAD(account: IAccount): boolean {
  return account.currency === 'CAD';
}

function createSimpleMoneySummaryAccountValue(
  labelKey: string,
  xsItem: number,
  value?: number,
  toolTipKey = '',
  toolTipColor = '',
  defaultValueIfUndefined = '-' as string,
): INullableSummaryAccountValue {
  return {
    labelKey,
    toolTipKey,
    toolTipColor,
    positionValue: (
      <ComponentWithValidation
        value={value}
        defaultValueIfUndefined={defaultValueIfUndefined}
        componentWithValue={<NumberQI value={value} isMoney />}
      />
    ),
    xsItem,
  };
}

function createComplexSummaryAccountValue(
  labelKey: string,
  xsItem: number,
  percent: number,
  value?: number,
  toolTipKey = '',
  toolTipColor = '',
): INullableSummaryAccountValue {
  return {
    labelKey,
    toolTipKey,
    toolTipColor,
    positionValue: (
      <ChangeValue
        value={value}
        percent={percent}
        decimalOverride={2}
        decimalOverrideForPercent={2}
        fontSize="16px"
        notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
        singleLineFormat
        noArrow
        signType="parentheses"
        noShowValuesSign={false}
      />
    ),
    xsItem,
  };
}

function isNotMarginAvailableAtPartyLevel(clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary) {
  return (
    clientAccountTopLevelInfo.marginValueCad === undefined ||
    clientAccountTopLevelInfo.marginValueCad === null ||
    clientAccountTopLevelInfo.marginValueUsd === undefined ||
    clientAccountTopLevelInfo.marginValueUsd === null
  );
}

function isNotMarginAvailableAtAccountLevel(account: IAccount) {
  return (
    account.marginValueCad === undefined ||
    account.marginValueCad === null ||
    account.marginValueUsd === undefined ||
    account.marginValueUsd === null
  );
}
