import * as React from 'react';
import { Typography } from '@mui/material';

interface Iprops {
  symbol: string,
  name: string,
  symbolColor?: string,
}
export const PositionName: React.FC<Iprops> = ({ symbol, name, symbolColor }) => (
  <>
    <Typography variant="h1" component="div" style={{ color: symbolColor }}>
      {symbol}
    </Typography>
    <Typography variant="h4" component="div" style={{ paddingBottom: 10, fontWeight: 700 }}>
      {name}
    </Typography>
  </>
);
