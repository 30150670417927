import 'antd/lib/modal/style/index.css';
import '~/common/styles/styleIAS.css';
import IconButton from '@mui/material/IconButton';
import { Modal, Table, Input } from 'antd';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import { IAccount } from '~/stores/account/accountTypes';
import { getAllAvailableAccounts, getIsAccountsFetching } from '~/stores/account/selectors/accountSelectors';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { Edit } from '@mui/icons-material';
import { Container, LabelBold, ContainerRow, Label, BoxShadow } from '~/common/styles/baseStyle';
import { saveNickNameAccount } from '~/stores/account/actions/accountActions';
import { Button } from '@mui/material';
import { FlexContainer, ActionContainer } from '../accessharing-view/AccessSharingView.style';
import { isUserAdvisorOrAdmin } from '../../../stores/system/selectors/SystemSelectors';
import Loading from '../../../common/components/Loading/Loading';

export interface IPropsFromDispatch {
  saveNickNameAccount: typeof saveNickNameAccount
}

export interface IPropsFromState {
  culture: string,
  state: IAppRootState,
  party: IPartyV1,
  accounts: IAccount[],
  isAccountsFetching: boolean,
  isUserAdvisorOrAdmin: boolean
}

interface INicknameState {
  editNicknameVisible: boolean,
  currentEditAccount?: IAccount,
  currentNickname: string
}

class NicknameView extends React.PureComponent<IPropsFromState & IPropsFromDispatch, INicknameState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.state = {
      editNicknameVisible: false,
      currentEditAccount: undefined,
      currentNickname: '',
    };

    this.handleChangeNickname = this.handleChangeNickname.bind(this);
  }

  private handleEdit = (e: any) => {
    const { accounts } = this.props;
    const currentAccount = accounts.filter((w) => w.id === e.currentTarget.dataset.id);
    if (currentAccount !== undefined && currentAccount.length > 0) {
      this.setState({
        editNicknameVisible: true,
        currentEditAccount: currentAccount[0],
        currentNickname: currentAccount[0].alias,
      });
    }
  };

  private handleCancel = (e: any) => {
    this.setState({
      editNicknameVisible: false,
      currentEditAccount: undefined,
      currentNickname: '',
    });
  };

  private handleSave = (e: any) => {
    if (this.state.currentEditAccount !== undefined) {
      this.props.saveNickNameAccount(this.props.party.id, this.state.currentEditAccount.id, this.state.currentNickname);
    }
    this.handleCancel(e);
  };

  private handleChangeNickname(event: any) {
    this.setState({ currentNickname: event.target.value });
  }

  public render() {
    const { accounts } = this.props;
    const renderAccess = (value: any, row: any, index: number) => {
      const obj = {
        children: (
          <FlexContainer>
            <ActionContainer>
              <IconButton data-id={row.id} onClick={this.handleEdit} style={{ color: '#003DA5' }}>
                <Edit />
              </IconButton>
            </ActionContainer>
          </FlexContainer>
        ),
        props: { className: 'groupColumn' },
      };
      return obj;
    };

    const columns = [
      {
        title: i18n.t('householding.ownerName'),
        dataIndex: 'name',
        key: 'name',
        width: '220px',
      },
      {
        title: i18n.t('householding.accountNumber'),
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: i18n.t('history.accountType'),
        key: 'accountType',
        dataIndex: 'accountType',
      },
      {
        title: i18n.t('householding.ownerNickname'),
        dataIndex: 'alias',
        key: 'alias',
      },
      {
        title: '',
        key: 'acces',
        width: '30px',
        render: renderAccess,
      },
    ];

    const buttonFooter = [
      <Button key="save" onClick={this.handleSave} disabled={this.props.isUserAdvisorOrAdmin} variant="contained" color="secondary"><Trans i18nKey="common.save" /></Button>,
      <Button key="cancel" onClick={this.handleCancel} variant="contained" color="secondary"><Trans i18nKey="common.cancel" /></Button>,
    ];
    if (this.props.isAccountsFetching) {
      return (
        <Container style={{ minHeight: 200 }}>
          <Loading show />
        </Container>
      );
    }

    return (
      <BoxShadow>
        <Container>
          <Table rowKey="id" columns={columns} dataSource={accounts} pagination={false} className="tableHousehold" locale={{ emptyText: ('nodata') }} />

          <Modal title={i18n.t('common.edit') as string} centered visible={this.state.editNicknameVisible} onOk={this.handleCancel} onCancel={this.handleCancel} width={500} footer={buttonFooter} maskClosable={false}>
            <Container style={{ width: '100%' }}>
              <ContainerRow>
                <LabelBold style={{ width: '50%' }}><Trans i18nKey="householding.accountNumber" /></LabelBold>
                <LabelBold style={{ width: '50%' }}><Trans i18nKey="householding.ownerName" /></LabelBold>
              </ContainerRow>

              <ContainerRow>
                <Label style={{ width: '50%' }}>{this.state.currentEditAccount !== undefined ? this.state.currentEditAccount.id : ''}</Label>
                <Label style={{ width: '50%' }}>{this.state.currentEditAccount !== undefined ? this.state.currentEditAccount.name : ''}</Label>
              </ContainerRow>
              <ContainerRow style={{ paddingTop: 15 }}>
                <LabelBold><Trans i18nKey="householding.ownerNickname" /></LabelBold>
              </ContainerRow>
              <ContainerRow>
                <Input onChange={this.handleChangeNickname} value={this.state.currentNickname} maxLength={32} />
              </ContainerRow>
            </Container>
          </Modal>

        </Container>
      </BoxShadow>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    culture: state.system.culture,
    state,
    party: getPartyV1(state),
    accounts: getAllAvailableAccounts(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    isAccountsFetching: getIsAccountsFetching(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  saveNickNameAccount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NicknameView);
