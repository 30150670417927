export interface IMessageState {
    readonly isSendingMessage: boolean,
    readonly isSendingMessageSuccess: boolean,
    readonly isSendingMessageSuccessWithWarning: boolean,
    readonly isSendingMessageFail: boolean,
    readonly isSendingMessageResult: {},
    readonly isFetchingDocuments: boolean,
    readonly isFetchingDocumentsSuccess: boolean,
    readonly isFetchingDocumentsFail: boolean,
    readonly isDocumentsLoaded: boolean,
    readonly documents: any
}

export const enum MessageActionTypes {
    SEND_MESSAGE = '[MESSAGE] Send',
    SEND_MESSAGE_SUCCESS = '[MESSAGE] Send Successful',
    SEND_MESSAGE_SUCCESS_WITH_WARNING = '[MESSAGE] Send Successful with warning',
    SEND_MESSAGE_ERROR = '[MESSAGE] Send Failed',
    FETCH_DOCUMENTS = '[MESSAGE] Fetch Documents',
    FETCH_DOCUMENTS_SUCCESS = '[MESSAGE] Fetch Documents successful',
    FETCH_DOCUMENTS_ERROR = '[MESSAGE] Fetch Documents Failed',
    RESET_MESSAGE = '[MESSAGE] Reset message state',
}
