import { Leaderboard, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { BoxShadow, ContainerRow, TabMenuContainer } from '~/common/styles/baseStyle';
import Colors from '~/common/themes/colors';
import {
  TopMoversSelectMarketMoverType,
  TopMoversSelectMarketType,
  fetchTopMovers,
} from '~/stores/marketData/actions/marketDataActions';
import { IMarketDataState } from '~/stores/marketData/marketDataTypes';
import i18n from '~/app/i18n';
import TopMoversTable from './TopMoversTable';
import { MarketSectionTab } from '~/common/components/market-overview/MarketSectionTab';
import { TopMoversNotAvailable } from './TopMoversNotAvailable';
import { RoundedButton } from '~/common/components/button/RoundedButton';

export interface IPropsFromState {
  marketDataState: IMarketDataState;
}

export interface IPropsFromDispatch {
  fetchTopMoversRef: typeof fetchTopMovers;
}

interface ITopMoversProps {}

const TopMovers: React.FC<ITopMoversProps & IPropsFromState> = ({ marketDataState }) => {
  const dispatch = useDispatch();

  const selectedMarketMoverType =
    marketDataState.topMoverSelectedMarketMoverType.get(marketDataState.topMoverSelectedMarketType) || 'gainers';
  const topMovers = marketDataState.topMovers.get(
    `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType}`,
  );

  const isDataUnavailable = marketDataState.isTopMoversUnavailable.get(
    `${marketDataState.topMoverSelectedMarketType}${
      marketDataState.topMoverSelectedMarketMoverType.get(marketDataState.topMoverSelectedMarketType) || 'gainers'
    }`,
  );
  return (
    <>
      <Grid item paddingBottom={3}>
        <ContainerRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Typography variant="h4" component="div" style={{ paddingBottom: '12px' }}>
            <Trans i18nKey="marketsPage.topMovers.title" />
          </Typography>
        </ContainerRow>
        <ContainerRow>
          <TabMenuContainer>
            <MarketSectionTab
              currentMarketIndex="fund"
              selectedMarketIndex={marketDataState.topMoverSelectedMarketType}
              tabTextTranslationKey="marketsPage.topMovers.funds"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TopMoversSelectMarketType('fund'));
              }}
            />
            <MarketSectionTab
              currentMarketIndex="stock"
              selectedMarketIndex={marketDataState.topMoverSelectedMarketType}
              tabTextTranslationKey="marketsPage.topMovers.stocks"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TopMoversSelectMarketType('stock'));
              }}
            />
            <MarketSectionTab
              currentMarketIndex="etf"
              selectedMarketIndex={marketDataState.topMoverSelectedMarketType}
              tabTextTranslationKey="marketsPage.topMovers.etf"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TopMoversSelectMarketType('etf'));
              }}
            />
          </TabMenuContainer>
        </ContainerRow>
        <BoxShadow
          style={{
            width: '100%',
            paddingBottom: '1.5rem',
            cursor: marketDataState.isFetchingTopMovers.get(
              `${marketDataState.topMoverSelectedMarketType}${
                marketDataState.topMoverSelectedMarketMoverType.get(marketDataState.topMoverSelectedMarketType) ||
                'gainers'
              }`,
            )
              ? 'wait'
              : 'auto',
          }}
        >
          <Stack direction="row" mt={2} mb={2} gap={1}>
            <RoundedButton
              isSelected={selectedMarketMoverType === 'gainers'}
              onClick={() => dispatch(TopMoversSelectMarketMoverType('gainers'))}
              title={i18n.t('marketsPage.topMovers.gainers')}
              key="gainers"
              icon={
                <TrendingUp
                  sx={{
                    color: 'green',
                  }}
                />
              }
            />
            <RoundedButton
              isSelected={selectedMarketMoverType === 'losers'}
              onClick={() => dispatch(TopMoversSelectMarketMoverType('losers'))}
              title={i18n.t('marketsPage.topMovers.losers')}
              key="losers"
              icon={
                <TrendingDown
                  sx={{
                    color: 'red',
                  }}
                />
              }
            />
            {marketDataState.topMoverSelectedMarketType !== 'fund' && (
              <RoundedButton
                isSelected={selectedMarketMoverType === 'mostActives'}
                onClick={() => dispatch(TopMoversSelectMarketMoverType('mostActives'))}
                title={i18n.t('marketsPage.topMovers.mostActives')}
                key="mostActives"
                icon={
                  <Leaderboard
                    sx={{
                      color: Colors.mariner,
                    }}
                  />
                }
              />
            )}
          </Stack>
          {!isDataUnavailable && (
            <TopMoversTable marketType={marketDataState.topMoverSelectedMarketType} topMovers={topMovers || []} />
          )}
          {isDataUnavailable && <TopMoversNotAvailable />}
        </BoxShadow>
      </Grid>
    </>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    marketDataState: state.marketData,
  };
}

export default connect(mapStateToProps)(TopMovers);
