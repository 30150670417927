import * as React from 'react';
import { Checkbox } from 'antd';
import i18n from '~/app/i18n';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { DocumentDeliveryStyle as Style } from './styles';

interface ILocalProps {
  handleLegalConsentChange: (event: CheckboxChangeEvent) => void,
  hasAcceptedLegalConsent: boolean
}

const SaveDocumentDeliveryChanges: React.FC<ILocalProps> = (props: ILocalProps) => (
  <Style.SaveChangesContainer>
    <Style.GreyBox>
      <Style.SubTitle style={{ fontFamily: 'Montserrat' }}>{i18n.t('documentDelivery.titleConsent') as string}</Style.SubTitle>
      <Style.Consent rows={10} disabled defaultValue={i18n.t('documentDelivery.legalConsent') as string} />
      <Checkbox defaultChecked={false} onChange={props.handleLegalConsentChange} value="" checked={props.hasAcceptedLegalConsent}>{i18n.t('documentDelivery.inputConsent') as string}</Checkbox>
    </Style.GreyBox>
  </Style.SaveChangesContainer>
);

export default SaveDocumentDeliveryChanges;
