import moment from 'moment';
import { Reducer } from 'redux';
import {
  IMarketDataState,
  MarketDataActionTypes,
  MarketType,
  MarketMoverType,
  TopMover,
} from '~/stores/marketData/marketDataTypes';

export const initialState: IMarketDataState = {
  isFailTopMovers: new Map([
    ['stockgainers', false],
    ['stocklosers', false],
    ['stockmostActives', false],
    ['etfgainers', false],
    ['etflosers', false],
    ['etfmostActives', false],
    ['fundgainers', false],
    ['fundlosers', false],
  ]),
  isFetchingTopMovers: new Map([
    ['stockgainers', false],
    ['stocklosers', false],
    ['stockmostActives', false],
    ['etfgainers', false],
    ['etflosers', false],
    ['etfmostActives', false],
    ['fundgainers', false],
    ['fundlosers', false],
  ]),
  topMovers: new Map([]),
  topMoverSelectedMarketType: 'fund',
  topMoverSelectedMarketMoverType: new Map([
    ['stock', 'gainers'],
    ['fund', 'gainers'],
    ['etf', 'gainers'],
  ]),
  isTopMoversUnavailable: new Map([]),
  securitySearchList: [],
  viewedSecurityList: [],
  securitySearchTerm: '',
  isFetchingSecurityList: false,
  isFailSecurityList: false,
  isFailTrends: new Map([
    // ['index', false],
    ['canadianSector', false],
    ['americanSector', false],
    ['commodity', false],
    ['currency', false],
  ]),
  isFetchingTrends: new Map([
    // ['index', false],
    ['canadianSector', false],
    ['americanSector', false],
    ['commodity', false],
    ['currency', false],
  ]),
  trends: new Map(),
  trendSelectedMarketType: 'canadianSector',
  keyIndicators: [],
  isFetchingKeyIndicators: false,
  isFailKeyIndicators: false,
  lastUpdateRequest: undefined,
};

const handleFetchTopMovers = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { marketType, marketMoverType }: { marketType: MarketType; marketMoverType: MarketMoverType } = payload;
  return {
    ...state,
    isFetchingTopMovers: state.isFetchingTopMovers.set(`${marketType}${marketMoverType}`, true),
  };
};

const handleFetchTopMoversFail = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { marketType, marketMoverType }: { marketType: MarketType; marketMoverType: MarketMoverType } = payload;
  return {
    ...state,
    isFetchingTopMovers: state.isFetchingTopMovers.set(`${marketType}${marketMoverType}`, false),
    isFailTopMovers: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, true),
    topMovers: state.topMovers.set(`${marketType}${marketMoverType}`, undefined),
    isTopMoversUnavailable: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, false),
  };
};

const handleFetchTopMoversSuccessful = (state: IMarketDataState, payload: any): IMarketDataState => {
  const {
    topMovers,
    marketType,
    marketMoverType,
  }: { topMovers: TopMover[]; marketType: MarketType; marketMoverType: MarketMoverType } = payload;
  return {
    ...state,
    topMovers: state.topMovers.set(`${marketType}${marketMoverType}`, topMovers),
    isFetchingTopMovers: state.isFetchingTopMovers.set(`${marketType}${marketMoverType}`, false),
    isFailTopMovers: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, false),
    isTopMoversUnavailable: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, false),
  };
};

const handleFetchTopMoversUnavailable = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { marketType, marketMoverType }: { marketType: MarketType; marketMoverType: MarketMoverType } = payload;
  return {
    ...state,
    topMovers: state.topMovers.set(`${marketType}${marketMoverType}`, []),
    isFetchingTopMovers: state.isFetchingTopMovers.set(`${marketType}${marketMoverType}`, false),
    isFailTopMovers: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, false),
    isTopMoversUnavailable: state.isFailTopMovers.set(`${marketType}${marketMoverType}`, true),
  };
};

const handleTopMoversSelectMarketType = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { marketType } = payload;
  return {
    ...state,
    topMoverSelectedMarketType: marketType,
  };
};

const handleTopMoversSelectMarketMoverType = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { marketMoverType } = payload;
  return {
    ...state,
    topMoverSelectedMarketMoverType: state.topMoverSelectedMarketMoverType.set(
      state.topMoverSelectedMarketType,
      marketMoverType,
    ),
  };
};

const handleTrendsSelectMarketType = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { trendType } = payload;
  return {
    ...state,
    trendSelectedMarketType: trendType,
  };
};

const handleFetchSecurityList = (state: IMarketDataState, payload: any): IMarketDataState => ({
  ...state,
  securitySearchTerm: payload.searchTerm,
  isFetchingSecurityList: true,
  isFailSecurityList: false,
});

const handleSecurityListClear = (state: IMarketDataState): IMarketDataState => ({
  ...state,
  securitySearchTerm: '',
  isFetchingSecurityList: false,
  isFailSecurityList: false,
  securitySearchList: [],
});

const handleViewedSecurity = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { security } = payload;

  return {
    ...state,
    viewedSecurityList: [security, ...state.viewedSecurityList.filter((s) => s.symbol !== security.symbol)],
  };
};

const handleFetchSecurityListSuccessful = (state: IMarketDataState, payload: any): IMarketDataState => ({
  ...state,
  isFetchingSecurityList: false,
  isFailSecurityList: false,
  securitySearchList: payload.securityList,
});

const handleFetchSecurityListFail = (state: IMarketDataState): IMarketDataState => ({
  ...state,
  isFetchingSecurityList: false,
  isFailSecurityList: true,
  securitySearchList: [],
});

const handleFetchTrends = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { trendType } = payload;
  return {
    ...state,
    isFetchingTrends: state.isFetchingTrends.set(trendType, true),
  };
};

const handleFetchTrendsFail = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { trendType } = payload;
  return {
    ...state,
    isFetchingTrends: state.isFetchingTrends.set(trendType, false),
    isFailTrends: state.isFailTrends.set(trendType, true),
    trends: state.trends.set(trendType, []),
  };
};

const handleFetchTrendsSuccessful = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { trends, trendType } = payload;
  const newState = {
    ...state,
    trends: state.trends.set(trendType, trends),
    isFetchingTrends: state.isFetchingTrends.set(trendType, false),
    isFailTrends: state.isFailTrends.set(trendType, false),
  };

  return newState;
};

const handleFetchKeyIndicators = (state: IMarketDataState): IMarketDataState => ({
  ...state,
  isFetchingKeyIndicators: true,
  isFailKeyIndicators: false,
});

const handleFetchKeyIndicatorsFailed = (state: IMarketDataState): IMarketDataState => ({
  ...state,
  isFetchingKeyIndicators: false,
  isFailKeyIndicators: true,
});
const handleFetchKeyIndicatorsSuccessful = (state: IMarketDataState, payload: any): IMarketDataState => {
  const { keyIndicators } = payload;
  return {
    ...state,
    isFetchingKeyIndicators: false,
    isFailKeyIndicators: false,
    keyIndicators,
  };
};

const handleFetchMarketData = (state: IMarketDataState) => ({
  ...state,
  lastUpdateRequest: moment().toDate(),
});

const handleSetMarketDataTimestamp = (state: IMarketDataState) => ({
  ...state,
  lastUpdateRequest: moment().toDate(),
});

const handleResetMarketData = (state: IMarketDataState) => ({
  ...state,
  topMovers: new Map(),
  trends: new Map(),
  keyIndicators: [],
});

export const marketDataReducer: Reducer<IMarketDataState> = (state = initialState, action) => {
  switch (action.type) {
    case MarketDataActionTypes.MARKET_DATA_TOP_MOVERS_SELECT_MARKET_TYPE:
      return handleTopMoversSelectMarketType(state, action.payload);
    case MarketDataActionTypes.MARKET_DATA_TOP_MOVERS_SELECT_MARKET_MOVER_TYPE:
      return handleTopMoversSelectMarketMoverType(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS:
      return handleFetchTopMovers(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_FAILED:
      return handleFetchTopMoversFail(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_SUCCESSFUL:
      return handleFetchTopMoversSuccessful(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_UNAVAILABLE:
      return handleFetchTopMoversUnavailable(state, action.payload);
    case MarketDataActionTypes.MARKET_DATA_TRENDS_SELECT_MARKET_TYPE:
      return handleTrendsSelectMarketType(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST:
      return handleFetchSecurityList(state, action.payload);
    case MarketDataActionTypes.MARKET_DATA_SECURITY_LIST_CLEAR:
      return handleSecurityListClear(state);
    case MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST_SUCCESSFUL:
      return handleFetchSecurityListSuccessful(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST_FAILED:
      return handleFetchSecurityListFail(state);
    case MarketDataActionTypes.MARKET_DATA_SECURITY_VIEWED:
      return handleViewedSecurity(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS:
      return handleFetchTrends(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS_FAILED:
      return handleFetchTrendsFail(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS_SUCCESSFUL:
      return handleFetchTrendsSuccessful(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS:
      return handleFetchKeyIndicators(state);
    case MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS_FAILED:
      return handleFetchKeyIndicatorsFailed(state);
    case MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS_SUCCESSFUL:
      return handleFetchKeyIndicatorsSuccessful(state, action.payload);
    case MarketDataActionTypes.FETCH_MARKET_DATA:
      return handleFetchMarketData(state);
    case MarketDataActionTypes.RESET_MARKET_DATA:
      return handleResetMarketData(state);
    case MarketDataActionTypes.SET_MARKET_DATA_TIMESTAMP:
      return handleSetMarketDataTimestamp(state);
    default:
      return state;
  }
};
