import styled from 'styled-components';
import { theme } from '~/app/iasTheme';

export const StyledDiv = styled.div`
  position: relative;
  background: #d9eaf8;
`;

export const Content = styled.div`
  padding-bottom: 30px;
  font-size: 14px;
  font-family: "OpenSans", "Open Sans", sans-serif;
`;

export const ButtonsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 0px 25px 0px 25px;
`;

export const BackgroundDivImage = styled.div`
  display: inline-block;
  text-align: right;
  width: 100%;
`;

export const InlineDiv = styled.div`
  position: absolute;
  width: 380px;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 30px;
`;

export const MarginDiv = styled.div`
  margin-top: 15px;
  margin-left: 24px;
`;

export const Title = styled.h1<{ size: 'md' | 'lg' }>`
  font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: ${(props) => (props.size === 'md' ? '20px' : '24px')};
  color: #333333;
`;

export const Text = styled.p`
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #000000;
`;

export const TextGrey = styled.div`
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #484848;
`;

export const PopupTitle = styled.p`
  font-family: "Open Sans Bold", "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: #ffffff;
`;

export const LinkButton = styled.span`
  color: #2062d4;
  cursor: pointer;
  font-size: 16px;
  font-family: ${theme.fontRegular};
  font-weight: ${theme.fontBoldWeight};
  text-decoration: underline;
`;

export const Link = styled.a`
  color: #2062d4;
  cursor: pointer;
  font-size: 14px;
  font-family: ${theme.fontRegular};
  font-weight: ${theme.fontBoldWeight};
  text-decoration: underline;
`;

export const CloseIconStyle = {
  fontSize: '32px',
  color: 'white',
};

export const CloseIconDiv = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
