import * as React from 'react';

import Item from './Item';
import { ItemContent, List, ListItem, RemoveItem } from './ItemList.style';
import { ReactComponent as Icon } from './remove-icon.svg';

interface ILocalProps {
  items: Item[];
  onItemRemoved: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemId: string) => void;
}

// tslint:disable:jsx-no-lambda
const ItemList: React.FC<ILocalProps> = ({ items, onItemRemoved }: ILocalProps): JSX.Element => (
  <List>
    {items.map((item) => (
      <ListItem key={item.id}>
        <ItemContent>{item.content}</ItemContent>
        <RemoveItem href="#" onClick={(event) => onItemRemoved(event, item.id)}>
          <Icon />
        </RemoveItem>
      </ListItem>
    ))}
  </List>
);

export default ItemList;
