import { Alert } from 'antd';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import { getSendMessageError, getSendMessageSuccess, getSendMessageSuccessWithWarning } from '~/stores/message/selectors/messageSelectors';
import * as React from 'react';
import { connect } from 'react-redux';
import SendMessageErrorStatus from './SendMessageErrorStatus';
import SendMessageWarningDetails from './SendMessageWarningDetails';

interface IPropsFromState {
  sendMessageError: boolean;
  sendMessageSuccess: boolean;
  sendMessageSuccessWithWarning: boolean;
  sendMessageResult: any;
}

class SendMessageAlertResult extends React.Component<IPropsFromState> {
  private alertType = (): 'success' | 'info' | 'warning' | 'error' => {
    const { sendMessageError } = this.props;

    return sendMessageError ? 'error' : 'success';
  };

  private messageToDisplay = (): string => {
    const { sendMessageError, sendMessageSuccess, sendMessageSuccessWithWarning, sendMessageResult } = this.props;
    let messageToDisplay = '';

    if (sendMessageSuccessWithWarning) {
      if (sendMessageResult.detail === SendMessageWarningDetails.NoEmailSent) {
        messageToDisplay = i18n.t('document.documentSentNoEmailSent');
      } else if (sendMessageResult.detail === SendMessageWarningDetails.SomeEmailSent) {
        messageToDisplay = i18n.t('document.documentSentSomeEmailSent');
      }
    } else if (sendMessageError) {
      if (sendMessageResult.status === SendMessageErrorStatus.VirusScanError) {
        messageToDisplay = i18n.t('document.virusScanDetectedAnAnomaly', { 0: sendMessageResult.detail });
      } else {
        messageToDisplay = i18n.t('document.errorMessage');
      }
    } else if (sendMessageSuccess) {
      messageToDisplay = i18n.t('document.documentSent');
    }

    return messageToDisplay;
  };

  public render() {
    return (
      <Alert message={this.messageToDisplay()} type={this.alertType()} showIcon style={{ width: '100%' }} />
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    sendMessageError: getSendMessageError(state),
    sendMessageSuccess: getSendMessageSuccess(state),
    sendMessageSuccessWithWarning: getSendMessageSuccessWithWarning(state),
    sendMessageResult: state.message.isSendingMessageResult,
  };
}

export default connect(mapStateToProps)(SendMessageAlertResult);
