import * as React from 'react';
import MonetaryValue, { IMonetaryValueProps } from '~/common/components/monetary-value/MonetaryValue';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const LabeledMonetaryValueContainer = styled.div`
    display: flex;
    > div {
        display: flex;
        &:first-child {
            margin-right: 8px;
        }
    }
`;
const ValueContainer = styled.div<{ valueWidth?: number }>`
    width: ${(props) => !props.valueWidth ? 'auto' : `${props.valueWidth}px`};
    & div {
        width: 100%;
        text-align: right;
    }
`;

export interface ILabeledMonetaryValueProps extends IMonetaryValueProps {
  label: string | React.ReactNode,
  valueWidth?: number,
  annotation?: string
}

const LabeledMonetaryValue: React.FC<ILabeledMonetaryValueProps> = ({ label, value, valueWidth, currency, culture, amountOfDigits, annotation }) => {
  const monetaryValueProps = { value, currency, culture, amountOfDigits };

  return (
    <LabeledMonetaryValueContainer>
      <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}> {label}</Typography>
      <ValueContainer valueWidth={valueWidth}>
        <Typography variant="body1" component="div">
          <MonetaryValue {...monetaryValueProps} />
          {annotation && <span>{annotation}</span>}
        </Typography>
      </ValueContainer>
    </LabeledMonetaryValueContainer>
  );
};

export default LabeledMonetaryValue;
