import { Stack } from '@mui/material';
import styled from 'styled-components';
import Colors from '~/common/themes/colors';
import * as React from 'react';
import { Trans } from 'react-i18next';

const NotAvailableTitle = styled.span`
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-size: 24px;
  color: ${Colors.anotherGrayShade};
`;

export const TopMoversNotAvailable = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{ textAlign: 'center' }}
    gap={2}
    paddingY={10}
    paddingX={10}
  >
    <NotAvailableTitle>
      <Trans i18nKey="marketsPage.topMovers.top10cleared" />
    </NotAvailableTitle>
  </Stack>
);
