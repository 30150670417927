import { IAppRootState } from '~/app/rootReducer';
import { IMarketProductDetailsState } from '~/stores/marketData/marketDataTypes';
import { createSelector } from 'reselect';

const getMarketProductsDetailsState = (state: IAppRootState) => state.marketProductDetails;

export const getProducts = createSelector(
  getMarketProductsDetailsState,
  (marketProductDetailsState: IMarketProductDetailsState) => marketProductDetailsState.products,
);

export const getIsProductDetailFetching = createSelector(
  getMarketProductsDetailsState,
  (marketProductDetailsState: IMarketProductDetailsState) => marketProductDetailsState.isFetchingProductDetailedInfoData,
);
