import * as React from 'react';
import { ContainerRow } from '~/common/styles/baseStyle';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { Grid, Typography } from '@mui/material';
import i18n from '~/app/i18n';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { appTheme } from '~/common/themes/theme';
import Loading from '../../Loading/Loading';
import { getIsProductDetailFetching } from '~/stores/marketData/selectors/marketProductDetailSelectors';
import { isFieldEmptyOrNull } from '~/common/helpers';

export interface IMarketProductHeaderSectionPropFromState {
  marketProductData: any;
  isFetchingMarketProductDetail: boolean;
}

export const MarketProductHeaderSection: React.FC<IMarketProductHeaderSectionPropFromState> = (props) => {
  const { marketProductData } = props;
  return (
    <Grid item xs={4}>
      <div className="boxGrey">
        <Grid>
          <Grid>
            <Typography variant="body2" component="div">
              {i18n.t('stockOverview.last') as string}
            </Typography>
            <Typography
              variant="h2"
              component="div"
              style={{ color: appTheme.palette.secondary.main, paddingTop: '5px' }}
            >
              <NumberQI value={marketProductData.last} isMoney noDollarSign />
            </Typography>
          </Grid>
          {props.isFetchingMarketProductDetail ? (
            <Loading show />
          ) : (
            <>
              <Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div" style={{ paddingTop: '25px' }}>
                    {i18n.t('marketProductDetails.daysChange') as string}
                  </Typography>
                  <ContainerRow style={{ paddingTop: 5, justifyContent: 'flex-start' }}>
                    {!isFieldEmptyOrNull(marketProductData.dayChange) && (
                      <ChangeValue
                        value={marketProductData.dayChange.value}
                        percent={marketProductData.dayChange.percentage}
                        fontSize="18px"
                        notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
                        singleLineFormat
                        noArrow
                        noShowValuesSign={false}
                      />
                    )}
                  </ContainerRow>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

function mapStateToProps(state: IAppRootState) {
  const { marketProductDetails } = state;
  return {
    marketProductDetails,
    isFetchingMarketProductDetail: getIsProductDetailFetching(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketProductHeaderSection);
