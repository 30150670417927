import { MARKETDATA_HIDE_PEERS, MARKETDATA_HIDE_SYMBOL_LINKS, MARKETDATA_HIDE_SYMBOL_SEARCH } from './API';

/**
 * Indicates whether the symbols can be clickable links
 * @returns boolean
 */
export const isSymbolLinkEnabled = (): boolean => !MARKETDATA_HIDE_SYMBOL_LINKS;
/**
 * Indicates whether the symbol search box on the left down corner of navigation is enabled/disabled based on current env
 * @returns boolean
 */
export const isSymbolSearchEnabled = (): boolean => !MARKETDATA_HIDE_SYMBOL_SEARCH;

/**
 * indicates whether the peers section on the positions details page is viewable
 * @returns boolean
 */
export const isPeersSectionEnabled = (): boolean => !MARKETDATA_HIDE_PEERS;
