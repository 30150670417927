import { IOption } from './multiSelectDropdownTypes';

export const establishLeafOptionIds = (options: IOption[]): string[] => {
  let leafOptionIds = [] as string[];

  options.forEach((option) => {
    if (option.childOptions) {
      leafOptionIds = leafOptionIds.concat(establishLeafOptionIds(option.childOptions));
    } else {
      leafOptionIds.push(option.id);
    }
  });

  return leafOptionIds;
};

export const establishIfAllOptionsAreSelected = (selectedOptionIds: string[], options: IOption[]) =>
// An empty selectedOptionIdsArray is considered the equivalent of all the options being selected
  selectedOptionIds.length <= 0 || selectedOptionIds.length >= getUniqueOptionsCount(options);
export const getUniqueOptionsCount = (options: IOption[]) => {
  const allOptions = getAllOptions(options);
  const uniqueOptions = Array.from(new Set(allOptions.map((item: any) => item)));
  return uniqueOptions.length;
};

export const getAllOptions = (options: IOption[]) => {
  let leafOptionIds = [] as string[];

  options.forEach((option) => {
    if (option.childOptions) {
      leafOptionIds = leafOptionIds.concat(establishLeafOptionIds(option.childOptions));
    } else {
      leafOptionIds.push(option.id);
    }
  });

  return leafOptionIds;
};
