const TextBold = "'Montserrat Bold'";
const TextSemiBold = "'Montserrat Bold'";
const TextRegular = "'Open Sans Regular', 'Open Sans'";

export const theme = {
  // appBackgroundColor: "#EFF1F4",
  appTitleColor: 'white',
  appTitleBackgroundColor: '#acafae',
  appTextColor: '#1b1b1b',
  foregroundMainColor: 'white',

  linkCurrentBackgroundColor: '#003da5',
  linkCurrentColor: 'white',
  linkBackgroundColor: '#e0e4e9',
  linkColor: '#003DA5',
  linkHoverBackgroundColor: '#d3d8de',

  inputBorderColor: '#c6c6c6',
  fontRegular: TextRegular,
  fontBold: TextBold,
  fontSemiBold: TextSemiBold,
  tableHeaderFont: TextSemiBold,
  tableFooterFont: TextSemiBold,

  fontSemiBoldWeight: '600',
  fontBoldWeight: '650',
  fontBoldTitleWeight: '700',

  tableHeaderBackgroundColor: '#003ba9',
  tableHeaderColor: 'white',
  tableSortingArrowColor: 'white',
  tableContentBackgroundColor: 'white',
  tableRowSeperatorColor: '#cccccc',

  expendedTableRowSeparatorColor: '#333333',
  expendedTableContentBackgroundColor: '#e0e4e9',

  buttonDrawerBackgroundColor: 'white',
  buttonDrawerColor: '#003da5',
  buttonDrawerPrimaryBackgrounColor: '#6687ac',
  buttonDrawerPrimaryColor: 'white',

  buttonBackgroundColor: '#003da5',
  buttonColor: 'white',
  buttonHoverBackgroundColor: '#003da5',

  black: '#000',
  gray2: '#e0e4e9',
};
