import * as React from 'react';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import { MonetaryValue } from '~/common/components';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import i18n from '~/app/i18n';
import { Stack, Typography } from '@mui/material';
import { SymbolDescrptionContainer } from '~/common/components/account/account-display/SymbolDescriptionDisplay';
import { IntradayPositionTypes } from '~/common/types';
import ChangeValue from '~/common/components/changeValue/ChangeValue';

export interface IPositionAggregate {
  positionSymbol: string;
  accountOwner: string;
  accountType: string;
  accountId: string;
  quantity: number;
  marketPrice: number;
  marketPriceCurrency: string;
  marketValue: number;
  security: string;
  alternateType: string;
  changeValue: number;
  changePercent: number;
}

export interface IConsolidatedPositionDrilldownProps {
  positionAggregates: IPositionAggregate[];
  didSetMarketDataRealtime: boolean;
  isFetchingMarketDataHasError: boolean;
}

export interface IPropsFromState {
  culture: string;
}

const ConsolidatedPositionDrilldown = ({
  positionAggregates,
  culture,
  didSetMarketDataRealtime,
  isFetchingMarketDataHasError,
}: IConsolidatedPositionDrilldownProps & IPropsFromState) => (
  <>
    <table style={{ width: 906 }}>
      <tbody>
        {positionAggregates.map((listValue: any, index) => {
          const marketPriceProps = { culture, value: listValue.marketPrice, amountOfDigits: 3 };
          const marketValueProps = { culture, value: listValue.marketValue };
          const isLastRow = index === positionAggregates.length - 1;
          return (
            <tr key={index}>
              <td style={{ width: 428, paddingBottom: isLastRow ? '0px' : '8px' }}>
                <Stack ml={3}>
                  <Typography variant="h6" component="div" style={{ color: '#000000', textAlign: 'left' }}>
                    &bull; {listValue.accountType}
                  </Typography>{' '}
                  <SymbolDescrptionContainer>
                    {listValue.accountId} {listValue.accountOwner}{' '}
                  </SymbolDescrptionContainer>
                </Stack>
              </td>
              <td style={{ width: 135, textAlign: 'right', fontSize: '14px', paddingRight: '6px' }}>
                {' '}
                <DecimalValue
                  value={listValue.quantity}
                  culture={culture}
                  amountOfDigits={3}
                  zeroPlaceholder="0"
                  areTrailingZerosVisible={false}
                />
              </td>
              <td style={{ width: 195, textAlign: 'right', fontSize: '14px' }}>
                <MonetaryValue {...marketPriceProps} culture={culture} zeroPlaceholder={`${i18n.t('holdings.NA')}¹`} />
              </td>
              <td style={{ width: 190, fontSize: '14px' }}>
                <MonetaryValue {...marketValueProps} zeroPlaceholder={i18n.t('holdings.NA')} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </>
);

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    culture: system.culture,
  };
}

export default connect(mapStateToProps)(ConsolidatedPositionDrilldown);
