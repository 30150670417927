import { put, takeLatest, call, select } from 'redux-saga/effects';
import { TransactionActionTypes, ITransactionAccountFilter } from '~/stores/transaction/transactionTypes';
import {
  fetchTransactionsSuccessful, fetchTransactionsFailed, fetchTransactionsAborted,
  setSelectedTransactionIds, IFetchTransactionPayload,
  setTransactionStartDate, setTransactionEndDate,
  setTransactionCurrencyFilter, setTransactionProductTypeFilter,
  setTransactionSymbolDescriptionFilter,
  setTransactioOperationTypeFilter,
} from '~/stores/transaction/actions/transactionActions';
import { TransactionHttpClient } from '~/stores/transaction/services/TransactionHttpClient';
import { RouteNames } from '~/app/appTypes';
import { IQueryStringParameter } from '~/stores/system/systemTypes';
import { getIsDataExpired } from '~/stores/transaction/selectors/transactionSelectors';
import { getFilteredAccountIds } from '~/stores/account/selectors/accountSelectors';
import { appGlobalError, refreshSession } from '~/stores/system/actions/systemActions';
import { LOCATION_CHANGE } from 'redux-first-history';
import { deserializeQueryString } from '~/common/helpers';

export function* fetchTransactions({ payload }: any): any {
  try {
    yield put(refreshSession());
    const { accountIds, startDate, endDate, hasFilterChanged, isAccountSwitch } = payload as IFetchTransactionPayload;
    const isDataExpired = yield select(getIsDataExpired);

    let transactionApiCall = false;
    if (accountIds != null && accountIds.length > 0) {
      if (hasFilterChanged || isDataExpired || isAccountSwitch) {
        const transactions = yield call(TransactionHttpClient.fetchByAccountIds, accountIds, startDate, endDate);
        yield put(fetchTransactionsSuccessful(transactions));
        transactionApiCall = true;
      }

      const filteredAccountIds = yield select(getFilteredAccountIds);
      const AccountFilter: ITransactionAccountFilter = { accountIds: filteredAccountIds, excludeAccountIds: [] };
      yield put(setSelectedTransactionIds(AccountFilter));
    }
    if (!transactionApiCall) {
      yield put(fetchTransactionsAborted());
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield put(fetchTransactionsFailed());
  }
}

export function* updateTransactionFilters({ payload }: any) {
  try {
    const { location } = payload;
    const pathName = location.pathname;
    const queryString = location.search as string;

    if (pathName === RouteNames.history) {
      const queryStringParameters = deserializeQueryString(queryString);

      const dateParameters = [] as IQueryStringParameter[];
      let symboldescriptionParameter = undefined as IQueryStringParameter | undefined;
      let marketParameters = undefined as IQueryStringParameter | undefined;
      let productTypeParameters = undefined as IQueryStringParameter | undefined;
      let operationTypeParameters = undefined as IQueryStringParameter | undefined;

      queryStringParameters.forEach((parameter: IQueryStringParameter) => {
        switch (parameter.name) {
          case 'startDate':
          case 'endDate':
            dateParameters.push(parameter);
            break;
          case 'symboldescription':
            symboldescriptionParameter = parameter;
            break;
          case 'currencyIds':
            marketParameters = parameter;
            break;
          case 'productTypeIds':
            productTypeParameters = parameter;
            break;
          case 'operationTypeIds':
            operationTypeParameters = parameter;
            break;
        }
      });

      if (dateParameters.length > 0) {
        yield updateDateFilter(dateParameters);
      }
      if (symboldescriptionParameter) {
        yield updateSymbolDescriptionFilter(symboldescriptionParameter);
      }
      if (marketParameters) {
        yield updateCurrencyFilter(marketParameters);
      }
      if (productTypeParameters) {
        yield updatProductTypeFilter(productTypeParameters);
      }
      if (operationTypeParameters) {
        yield updatOperationTypeFilter(operationTypeParameters);
      }
    }
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updateCurrencyFilter(marketParameter: IQueryStringParameter) {
  try {
    const currencyIds = marketParameter.value ? marketParameter.value.split('-') : [];

    yield put(setTransactionCurrencyFilter(currencyIds));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updatProductTypeFilter(productTypeParameters: IQueryStringParameter) {
  try {
    const Ids = productTypeParameters.value ? productTypeParameters.value.split('-') : [];

    yield put(setTransactionProductTypeFilter(Ids));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updatOperationTypeFilter(operationTypeParameters: IQueryStringParameter) {
  try {
    const Ids = operationTypeParameters.value ? operationTypeParameters.value.split('-') : [];

    yield put(setTransactioOperationTypeFilter(Ids));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updateSymbolDescriptionFilter(symbolDescriptionParameter: IQueryStringParameter) {
  try {
    yield put(setTransactionSymbolDescriptionFilter(symbolDescriptionParameter.value));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updateDateFilter(datesParameters: IQueryStringParameter[]) {
  try {
    yield put(setTransactionStartDate(datesParameters[0].value));
    yield put(setTransactionEndDate(datesParameters[1].value));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* updateAccounts(): any {
  try {
    const filteredAccountIds = yield select(getFilteredAccountIds);
    const AccountFilter: ITransactionAccountFilter = { accountIds: filteredAccountIds, excludeAccountIds: [] };
    yield put(setSelectedTransactionIds(AccountFilter));
  } catch (error) {
    yield put(appGlobalError(error as any));
  }
}

function* transactionSaga() {
  yield takeLatest(TransactionActionTypes.FETCH_TRANSACTION, fetchTransactions);
  yield takeLatest(TransactionActionTypes.UPDATE_ACCOUNTS, updateAccounts);
  yield takeLatest(LOCATION_CHANGE, updateTransactionFilters);
}

export default transactionSaga;
