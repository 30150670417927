import '~/common/styles/styleIAS.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as React from 'react';
import { connect } from 'react-redux';
import i18n from '~/app/i18n';
import { fetchDeliveryPreferences, saveDeliveryPreferences } from '~/stores/account/actions/accountActions';
import {
  getAllAvailableAccounts,
  getDeliveryPreferences,
  getIsAccountsFetching,
  getIsDeliveryPreferencesFetchFail,
  getIsDeliveryPreferencesFetching,
  getIsDeliveryPreferencesSaveFail,
  getIsDeliveryPreferencesSaving,
} from '~/stores/account/selectors/accountSelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { getIsPartyFetching, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { IAccount, IDeliveryPreferences } from '~/stores/account/accountTypes';
import { getOwnedOrCorpAccountsIds } from '~/common/partyHelpers';
import { BoxShadow } from '~/common/styles/baseStyle';
import { IAppRootState } from '~/app/rootReducer';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import LegalConsentModal from './LegalConsentModal';
import { DocumentDeliveryStyle as Style } from './styles';
import LoadDeliveryPreferences, { DocumentType, IDeliveryPreferencesTableData } from './deliveryPreferencesLoadMapper';
import Header from './Header';
import Footer from './Footer';
import ModalSaveConfirmation from '../../../common/components/Modal/ModalSaveSuccess';
import ModalSaveFailed from '../../../common/components/Modal/ModalSaveFail';
import Loading from '../../../common/components/Loading/Loading';
import DataTableEDelivery, { IEDeliveryRowData } from '~/common/components/data-table/data-table-edelivery';
import { v4 } from 'uuid';
import {
  IsThereSomethingToMoveToPaperless,
  MapToDeliveryPreferences,
  MapToDeliveryPreferencesTableRowForAllPaperless,
  MapToDeliveryPreferencesUpdatedToSource,
} from './deliveryPreferencesHelperMapper';
import { saveUpdateDeliveryPreferences } from '~/stores/party/actions/partyActions';
import Error from '@mui/icons-material/Error';

export interface IDeliveryPreferencesChangedTableData {
  documentType: DocumentType;
  rows: IEDeliveryRowData[];
}

interface IDocumentState {
  modalApplyVisible: boolean;
  modalChangeAllVisible: boolean;
  isDirty: boolean;
  hasAcceptedLegalConsent: boolean;
  dataSource: IDeliveryPreferencesTableData[] | undefined;
  changedDataSource: IDeliveryPreferencesChangedTableData[];
  successModalVisible: boolean;
  failModalVisible: boolean;
}

interface IPropsFromState {
  deliveryPreferences: IDeliveryPreferences[];
  isDeliveryPreferencesFetching: boolean;
  isDeliveryPreferencesFetchFail: boolean;
  party: IPartyV1;
  accounts: IAccount[];
  isPartyFetching: boolean;
  isAccountFetching: boolean;
  isDeliveryPreferencesSaving: boolean;
  isDeliveryPreferencesSaveFail: boolean;
  isUserAdvisorOrAdmin: boolean;
}

interface IPropsFromDispatch {
  fetchDeliveryPreferences: typeof fetchDeliveryPreferences;
  saveDeliveryPreferences: typeof saveDeliveryPreferences;
  saveUpdateDeliveryPreferences: typeof saveUpdateDeliveryPreferences;
}

class DocumentDeliveryView extends React.Component<IPropsFromState & IPropsFromDispatch, IDocumentState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.state = {
      modalApplyVisible: false,
      modalChangeAllVisible: false,
      isDirty: false,
      hasAcceptedLegalConsent: false,
      dataSource: undefined,
      changedDataSource: [],
      successModalVisible: false,
      failModalVisible: false,
    };
  }

  public componentDidMount() {
    this.fetchDeliveryPreferences();
  }

  private fetchDeliveryPreferences() {
    const { party } = this.props;

    if (party && party.accounts !== undefined) {
      const clientIds = party.accounts.map((a) => a.id);
      const partyId = this.props.isUserAdvisorOrAdmin ? party.id : '';
      this.props.fetchDeliveryPreferences(clientIds, partyId);
    }
  }

  public componentDidUpdate(prevProps: IPropsFromDispatch & IPropsFromState, prevState: IDocumentState) {
    this.fillDataSource();

    if (prevProps.isDeliveryPreferencesSaving && !this.props.isDeliveryPreferencesSaving) {
      if (this.props.isDeliveryPreferencesSaveFail) {
        this.showFailModal();
      } else {
        this.showSuccessModal();
      }
    }
  }

  private fillDataSource() {
    if (!this.isFetching() && this.state.dataSource === undefined) {
      const loadedDataSource = LoadDeliveryPreferences(
        this.props.deliveryPreferences,
        this.props.accounts,
        this.props.isUserAdvisorOrAdmin,
      );
      const preparingChanges: IDeliveryPreferencesChangedTableData[] = [];
      loadedDataSource?.forEach((e) => {
        preparingChanges.push({ documentType: e.documentType, rows: [] });
      });
      this.setState({ dataSource: loadedDataSource });
      this.setState({ changedDataSource: preparingChanges });
    }
  }

  private isFetching() {
    const { isDeliveryPreferencesFetching, isAccountFetching, isPartyFetching } = this.props;
    return isDeliveryPreferencesFetching || isAccountFetching || isPartyFetching;
  }

  private changeAllToPaperless = () => {
    this.setState({
      modalChangeAllVisible: true,
      hasAcceptedLegalConsent: false,
    });
  };

  private showConfirmModal = () => {
    // check if there is any changes to paperless.
    const atLeastOnePaperless = this.state.changedDataSource.some((e) => e.rows.some((r) => r.isPaperless));

    if (atLeastOnePaperless) {
      this.setState({
        modalApplyVisible: true,
        hasAcceptedLegalConsent: false,
      });
    } else {
      this.saveChanges();
    }
  };

  private saveChanges = () => {
    this.saveDeliveryPreferences();

    this.setState({
      modalApplyVisible: false,
      modalChangeAllVisible: false,
    });
  };

  private saveDeliveryPreferences = () => {
    if (!this.state.dataSource || this.props.isUserAdvisorOrAdmin) {
      return;
    }

    let changesToBeApply = [...this.state.changedDataSource];
    if (this.state.modalChangeAllVisible === true) {
      changesToBeApply = MapToDeliveryPreferencesTableRowForAllPaperless(this.state.dataSource);
    }

    const itemsToSave = MapToDeliveryPreferences(changesToBeApply);
    if (itemsToSave.length > 0) {
      this.props.saveDeliveryPreferences(itemsToSave);
      this.props.saveUpdateDeliveryPreferences({});

      // transform changed to currentData after saving
      const newData: IDeliveryPreferencesTableData[] = MapToDeliveryPreferencesUpdatedToSource(
        this.state.dataSource,
        changesToBeApply,
      );
      const preparingChanges: IDeliveryPreferencesChangedTableData[] = [];
      this.state.changedDataSource?.forEach((e) => {
        preparingChanges.push({ documentType: e.documentType, rows: [] });
      });
      this.setState({ dataSource: newData, changedDataSource: preparingChanges });
    }
  };

  private showSuccessModal = () => {
    this.setState({
      successModalVisible: true,
      isDirty: false,
    });
  };

  private showFailModal = () => {
    this.setState({
      failModalVisible: true,
    });
    setTimeout(() => {
      this.setState({ failModalVisible: false });
      this.resetChanges();
    }, 5000);
  };

  private closeSaveChangesModal = () => {
    this.setState({
      modalApplyVisible: false,
      modalChangeAllVisible: false,
    });
  };

  private resetChanges = () => {
    if (this.props.isUserAdvisorOrAdmin) {
      return;
    }

    this.fetchDeliveryPreferences();
    this.setState({
      isDirty: false,
      dataSource: undefined,
    });
  };

  private handleLegalConsentClick = (event: CheckboxChangeEvent) => {
    const userAsConsented: boolean = event.target.checked;
    this.setState({ hasAcceptedLegalConsent: userAsConsented });
  };

  private onItemChanged = (diffs: IEDeliveryRowData[], documentType: string) => {
    const copyChangedSource: IDeliveryPreferencesChangedTableData[] = [];
    this.state.changedDataSource.forEach((e, i) => {
      if ((e.documentType as unknown as string) === documentType) {
        copyChangedSource.push({ documentType: documentType as unknown as DocumentType, rows: diffs });
      } else {
        copyChangedSource.push(this.state.changedDataSource[i]);
      }
    });
    const atLeastOneDiff = copyChangedSource.some((e) => e.rows.length > 0);
    this.setState({ changedDataSource: copyChangedSource, isDirty: atLeastOneDiff });
  };

  private closeSuccessModal = () => {
    this.setState({
      successModalVisible: false,
    });
  };

  private closeFailureModal = () => {
    this.setState({
      failModalVisible: false,
    });
  };

  private createData = (
    clientId: string,
    name: string,
    isPaperless: boolean,
    canModify: boolean,
    isEmailNotification?: boolean,
    isTaxSeason?: boolean,
  ): IEDeliveryRowData => ({
    key: v4(),
    clientId,
    name,
    isPaperless,
    isEmailNotification,
    canModify,
    isTaxSeason,
  });

  public render() {
    const ownedRoots = getOwnedOrCorpAccountsIds(this.props.party);
    const enabledChangeAll =
      !!(ownedRoots && ownedRoots.length > 0) && IsThereSomethingToMoveToPaperless(this.state.dataSource);

    return (
      // remove the newline before the closing parenthesis
      <>
        <ModalSaveConfirmation
          onClose={this.closeSuccessModal}
          isVisible={this.state.successModalVisible}
          message={i18n.t('householding.savePreferencesConfirmation')}
        />
        <ModalSaveFailed
          onClose={this.closeFailureModal}
          isVisible={this.state.failModalVisible}
          message={i18n.t('householding.savePreferencesFailure')}
        />

        {this.props.isDeliveryPreferencesFetchFail && (
          <div className="boxErrorAlert">
            <Error />
            &nbsp;<div>{`${i18n.t('householding.edeliveryFailedLoading')}`}</div>
          </div>
        )}
        {this.props.isDeliveryPreferencesSaving === true || this.isFetching() ? (
          <Loading show />
        ) : (
          <BoxShadow>
            <Style.ConsolidatedViewContainer>
              <LegalConsentModal
                modalSaveChangesVisible={this.state.modalApplyVisible || this.state.modalChangeAllVisible}
                hasAcceptedLegalConsent={this.state.hasAcceptedLegalConsent}
                handleSave={this.saveChanges}
                handleCancel={this.closeSaveChangesModal}
                handleLegalConsentChange={this.handleLegalConsentClick}
                isUserAdvisor={this.props.isUserAdvisorOrAdmin}
              />
              <Header onChangeAllToPaperless={this.changeAllToPaperless} enabledChangeAll={enabledChangeAll} />
              <Style.HorizontalScrollDiv>
                {this.state.dataSource?.map((e, index) => {
                  if (e.rows && e.rows.length > 0) {
                    const rowsData = e.rows.map((row) =>
                      this.createData(
                        row.clientId,
                        row.name,
                        row.isPaperless,
                        row.canModify,
                        row.isEmailNotification,
                        row.isTaxSeason,
                      ));
                    return (
                      <DataTableEDelivery
                        data={rowsData}
                        documentType={e.documentType}
                        onItemChanged={this.onItemChanged}
                        key={`${e.documentType}-${index}`}
                        isAdminAdvisor={this.props.isUserAdvisorOrAdmin}
                      />
                    );
                  }
                  return undefined;
                })}
              </Style.HorizontalScrollDiv>

              <Footer
                isDirty={this.state.isDirty}
                onApply={this.showConfirmModal}
                onResetChanges={this.resetChanges}
                isUserAdvisorOrAdmin={this.props.isUserAdvisorOrAdmin}
              />
            </Style.ConsolidatedViewContainer>
          </BoxShadow>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IAppRootState): IPropsFromState => ({
  isDeliveryPreferencesFetching: getIsDeliveryPreferencesFetching(state),
  isDeliveryPreferencesFetchFail: getIsDeliveryPreferencesFetchFail(state),
  deliveryPreferences: getDeliveryPreferences(state),
  party: getPartyV1(state),
  accounts: getAllAvailableAccounts(state),
  isPartyFetching: getIsPartyFetching(state),
  isAccountFetching: getIsAccountsFetching(state),
  isDeliveryPreferencesSaving: getIsDeliveryPreferencesSaving(state),
  isDeliveryPreferencesSaveFail: getIsDeliveryPreferencesSaveFail(state),
  isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
});

const mapDispatchToProps: IPropsFromDispatch = {
  fetchDeliveryPreferences,
  saveDeliveryPreferences,
  saveUpdateDeliveryPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDeliveryView);
