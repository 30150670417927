import styled from 'styled-components';

export const MainAccountContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #FFF;
    padding: 15px;
    box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.349019607843137);
    flex-direction: column;
`;

export const AccountDisplaySummaryContainer = styled.div`
    display: flex; 
    flex-direction: column;
`;

export const AccountHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 15px;
`;

export const AccountSubHeaderContainer = styled.div`
    border-top: 3px solid #e0e4e9;
    border-bottom: 3px solid #e0e4e9;
    padding-top: 10px;
    margin-bottom: 15px;
`;

export const AccountInfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > span {
        &:nth-child(n) {
            align-self: flex-end;
        }
    }
`;

export const AccountInfoItemContainer = styled.span`
    padding:3px;
`;
