import * as React from 'react';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Trans } from 'react-i18next';
import { TabMenuContainer, TabContainer, ActiveNavStyle } from '~/common/styles/baseStyle';
import { Location } from 'history';
import { Skeleton } from '@mui/material';

export interface IHoldingsPageSubMenuProps {
  isFetching: boolean;
}
export interface IPropsFromState {
  currentLocation: string;
  currentSearch: string;
}

const HoldingsPageSubMenu = ({
  currentSearch,
  currentLocation,
  isFetching,
}: IHoldingsPageSubMenuProps & IPropsFromState) => {
  const isActiveConsolidated = currentLocation === RouteNames.holdingsConsolidatedView;

  if (isFetching) {
    return (
      <TabMenuContainer>
        <TabContainer isActive={() => !isActiveConsolidated} to={`${currentLocation}`} activeStyle={ActiveNavStyle}>
          <Skeleton variant="rectangular" width={100} height={20} />
        </TabContainer>
        <TabContainer isActive={() => isActiveConsolidated} to={`${currentLocation}`} activeStyle={ActiveNavStyle}>
          <Skeleton variant="rectangular" width={129} height={20} />
        </TabContainer>
      </TabMenuContainer>
    );
  }
  return (
    <TabMenuContainer>
      <TabContainer
        isActive={() => !isActiveConsolidated}
        to={`${RouteNames.holdingsAccountView}${currentSearch}`}
        activeStyle={ActiveNavStyle}
      >
        <Trans i18nKey="holdings.accountView" />
      </TabContainer>
      <TabContainer
        isActive={() => isActiveConsolidated}
        to={`${RouteNames.holdingsConsolidatedView}${currentSearch}`}
        activeStyle={ActiveNavStyle}
      >
        <Trans i18nKey="holdings.consolidatedView" />
      </TabContainer>
    </TabMenuContainer>
  );
};

export function mapStateToProps(state: IAppRootState) {
  return {
    currentLocation: (state.router.location as Location<{ source: string }>).pathname,
    currentSearch: (state.router.location as Location<{ source: string }>).search,
  };
}

export default connect(mapStateToProps)(HoldingsPageSubMenu);
