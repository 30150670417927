import * as React from 'react';
import { Trans } from 'react-i18next';
import { Modal } from 'antd';
import Button from '@mui/material/Button';
import { HelpOutlineRounded } from '@mui/icons-material';
import LinkButton from '~/common/components/link-button/LinkButton';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { fontGrid } from '@mui/material/styles/cssUtils';

const getModalWidthStyle = () => {
  const width = window.innerWidth < 768 ? '90%' : window.innerWidth < 1024 ? '60%' : '40%';
  return { width };
};

const styles = {
  boldText: {
    fontFamily: "'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif",
    fontWeight: 700,
    fontSize: '16px',
  },
  regularText: {
    fontFamily: "'OpenSans-Regular', 'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: '16px',
    fontSyle: 'normal',
  },
  button: {
    fontFamily: "'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif",
    fontStyle: 'normal',
    backgroundColor: '#003DA5',
    color: '#FFFFFF',
    marginTop: '20px',
    fontSize: '14px',
    minHeight: '40px',
    minWidth: '100px',
    borderRadius: '4px',
  },
};

const definitions = [
  {
    titleKey: 'holdings.definitions.InvestedCapital.Title',
    descKey: 'holdings.definitions.InvestedCapital.Description',
  },
  {
    titleKey: 'holdings.definitions.InvestedCapitalGainLoss.Title',
    descKey: 'holdings.definitions.InvestedCapitalGainLoss.Description',
  },
  {
    titleKey: 'holdings.definitions.UnrealizedGainLoss.Title',
    descKey: 'holdings.definitions.UnrealizedGainLoss.Description',
  },
  { titleKey: 'holdings.definitions.BookCost.Title', descKey: 'holdings.definitions.BookCost.Description' },
  {
    titleKey: 'holdings.definitions.PreviousDayChange.Title',
    descKey: 'holdings.definitions.PreviousDayChange.Description',
  },
  {
    titleKey: 'holdings.definitions.IntradayChange.Title',
    descKey: 'holdings.definitions.IntradayChange.Description',
    isLink: true,
  },
];

export interface IDefintionsProps {
  showModal: boolean | undefined;
  modalTitle: string;
  handleCancel: () => void;
}
export const HoldingPageDefinitions = ({ handleCancel, showModal, modalTitle }: IDefintionsProps) => (
  <Modal
    title={<div style={styles.boldText}>{modalTitle}</div>}
    centered
    open={showModal}
    onCancel={handleCancel}
    width={getModalWidthStyle().width}
    footer={null}
    closeIcon={<CloseOutlined style={{ fontSize: '24px', color: '#fff' }} />}
  >
    {definitions.map(({ titleKey, descKey, isLink }, index) => (
      <React.Fragment key={index}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={styles.boldText}>
            <Trans i18nKey={titleKey} />
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
          <span style={styles.regularText}>
            <Trans
              i18nKey={descKey}
              components={
                isLink
                  ? { privacyLink: <Link style={{ fontSize: '16px' }} to={RouteNames.settingHoldings} /> }
                  : undefined
              }
            />
          </span>
        </div>
      </React.Fragment>
    ))}
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
      <span style={styles.regularText}>
        <Trans i18nKey="holdings.definitions.Contact" />
      </span>
    </div>
    <Button onClick={handleCancel} style={styles.button}>
      <Trans i18nKey="common.close" />
    </Button>
  </Modal>
);

export const HoldingPageDefinitionsButton = ({
  onClick,
  isProcessing,
}: {
  onClick: () => void;
  isProcessing: boolean;
}) => (
  <LinkButton onClick={onClick} isProcessing={isProcessing} endIcon={<HelpOutlineRounded />}>
    <Trans i18nKey="holdings.definitions.Title" />
  </LinkButton>
);
