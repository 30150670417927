import { UploadFile } from 'antd/lib/upload/interface';

export function mapToFiles(entries: UploadFile[]): File[] {
  const files: File[] = [];
  entries.forEach((entry) => {
    if (entry.originFileObj) {
      files.push(entry.originFileObj as File);
    }
  });
  return files;
}
