import React from 'react';
import { Prompt } from 'react-router';

export const LeavePagePrompt = ({ shouldBlock, message }: { shouldBlock: boolean; message: string }) => {
  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', () => {});
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', () => {});
    };
  }, []);

  const alertUser = (e: BeforeUnloadEvent | Event) => {
    e.preventDefault();
    e.returnValue = '';
  };

  return <Prompt when={shouldBlock} message={message} />;
};
