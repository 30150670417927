import * as React from 'react';
import { Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { ActiveNavStyle, TabContainer } from '~/common/styles/baseStyle';
import { TrendType, MarketType } from '~/stores/marketData/marketDataTypes';

export interface ITabProps {
  selectedMarketIndex: TrendType | MarketType,
  currentMarketIndex: TrendType | MarketType,
  tabTextTranslationKey: string,
  onClick: (e: any) => void
}

export class MarketSectionTab extends React.PureComponent<ITabProps> {
  public render() {
    const { selectedMarketIndex, currentMarketIndex, tabTextTranslationKey } = this.props;

    return (
      <TabContainer
        isActive={() => selectedMarketIndex === currentMarketIndex}
        onClick={(e) => this.props.onClick(e)}
        activeStyle={ActiveNavStyle}
        to=""
      >
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
          <Trans i18nKey={tabTextTranslationKey} />
        </Stack>
      </TabContainer>
    );
  }
}
