import { appGlobalError } from '~/stores/system/actions/systemActions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import 'moment-timezone';
import {
  MarketDataActionTypes,
  MarketType,
  MarketMoverType,
  TopMover,
  TrendType,
  Trend,
  IKeyIndicator,
} from '../marketDataTypes';
import {
  fetchTopMoversSuccessful,
  fetchTopMoversFailed,
  fetchTopMovers,
  fetchTrends,
  fetchTrendsSuccessful,
  fetchTrendsFailed,
  TrendsSelectMarketType,
  fetchSecurityList as fetchSecurityListAction,
  fetchSecurityListFailed,
  fetchSecurityListSuccessful,
  fetchKeyIndicatorsSuccessful,
  fetchKeyIndicatorsFailed,
  fetchKeyIndicators,
  resetMarketData,
  fetchTopMoversDataUnavailable,
} from '../actions/marketDataActions';
import { MarketDataHttpClient } from '../services/marketDataHttpClient';
import { AxiosResponse } from 'axios';

function* handleFetchMarketData() {
  yield put(resetMarketData());
  yield all([
    // call(fetchTrendsType, { payload: { trendType: 'index' } }),
    // call(fetchTrendsType, { payload: { trendType: 'commodity' } }),
    // Loading default view components to avoid loading page for too long
    call(handleFetchKeyIndicators),
    call(fetchTopMoversType, { payload: { marketType: 'stock', marketMoverType: 'gainers' } }),
    call(fetchTrendsType, { payload: { trendType: 'canadianSector' } }),
    call(fetchTopMoversType, { payload: { marketType: 'fund', marketMoverType: 'gainers' } }),

    call(fetchTrendsType, { payload: { trendType: 'americanSector' } }),
    call(fetchTrendsType, { payload: { trendType: 'currency' } }),
    call(fetchTopMoversType, { payload: { marketType: 'stock', marketMoverType: 'losers' } }),
    call(fetchTopMoversType, { payload: { marketType: 'stock', marketMoverType: 'mostActives' } }),
    call(fetchTopMoversType, { payload: { marketType: 'etf', marketMoverType: 'gainers' } }),
    call(fetchTopMoversType, { payload: { marketType: 'etf', marketMoverType: 'losers' } }),
    call(fetchTopMoversType, { payload: { marketType: 'etf', marketMoverType: 'mostActives' } }),
    call(fetchTopMoversType, { payload: { marketType: 'fund', marketMoverType: 'losers' } }),
  ]);
}

function* handleFetchKeyIndicators() {
  try {
    const keyIndicators = (yield call(MarketDataHttpClient.fetchKeyIndicators)) as IKeyIndicator[];
    yield put(fetchKeyIndicatorsSuccessful(keyIndicators));
  } catch (err) {
    console.log(err);
    yield put(fetchKeyIndicatorsFailed());
  }
}

function* fetchSecurityList({ payload }: any): any {
  try {
    const { searchTerm }: { searchTerm: string } = payload;
    if (searchTerm) {
      const securityList = (yield call(MarketDataHttpClient.fetchSecurityList, searchTerm)) as any[];
      yield put(fetchSecurityListSuccessful(securityList));
    }
  } catch (err) {
    console.log(err);
    yield put(fetchSecurityListFailed());
  }
}

function* fetchTrendsType({ payload }: any): any {
  const { trendType }: { trendType: TrendType } = payload;
  try {
    yield put(fetchTrends(trendType));
    const trends = (yield call(MarketDataHttpClient.fetchTrends, trendType)) as Trend[];
    yield put(fetchTrendsSuccessful(trends, trendType));
  } catch (err) {
    console.log(err);
    yield put(fetchTrendsFailed(trendType));
  }
}

function* fetchTopMoversType({ payload }: any): any {
  const { marketType, marketMoverType }: { marketType: MarketType; marketMoverType: MarketMoverType } = payload;
  try {
    yield put(fetchTopMovers(marketType, marketMoverType));
    const response = (yield call(MarketDataHttpClient.fetchTopMovers, marketType, marketMoverType)) as AxiosResponse<
      TopMover[]
    >;
    if (response.status === 204) {
      yield put(fetchTopMoversDataUnavailable(marketType, marketMoverType));
    } else {
      yield put(fetchTopMoversSuccessful(response.data, marketType, marketMoverType));
    }
  } catch (err) {
    console.log(err);
    yield put(fetchTopMoversFailed(marketType, marketMoverType));
  }
}

function* marketDataSaga() {
  yield takeLatest(MarketDataActionTypes.FETCH_MARKET_DATA, handleFetchMarketData);
  yield takeLatest(MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS, handleFetchKeyIndicators);
  yield takeLatest(MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST, fetchSecurityList);
}

export default marketDataSaga;
