import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import {
  getAssetsAllocation,
  getIsAssetsAllocationFetching,
  getFilteredAccountIds,
  getAccountIds,
  getAllCurrentFilterAccounts,
} from '~/stores/account/selectors/accountSelectors';
import { IAssetsAllocation, IAssetAllocationPosition, IAccount } from '~/stores/account/accountTypes';
import styled from 'styled-components';
import {
  IAssetAllocationMix,
  BuildAssetsMix,
  getNegativeAssetPositions,
  GetDonutData,
  HideAssetAllocationPieChart,
} from '~/common/assetAlloccationHelper';
import { PieChart, Pie } from 'recharts';
import { Trans } from 'react-i18next';
import { SectionEnum } from '~/common/types';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import ExchangeRateDisclaimer from '~/common/components/loadStamp/ExchangeRateDisclaimer';
import {
  Container,
  PageTitleContainer,
  HeaderPageContainer,
  MainBox,
  ContainerLegend,
  Legendbox,
  ExpansionPanel,
  LabelBold,
  H3,
  ContainerRow,
  FilterContainer,
} from '~/common/styles/baseStyle';
import AccountFilter from '~/common/components/account/HouseholdAccountFilter';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { MonetaryValue } from '~/common/components';
import { AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { refreshSession, setAssetAllocationBreakDown } from '~/stores/system/actions/systemActions';
import { getSelectedAccountIds } from '~/common/accountsHelpers';
import { Switch } from 'antd';
import PrintButton from '~/common/components/print-button/PrintButton';

import Loading from '../../common/components/Loading/Loading';

const TotalLabel = styled.div`
  font-family: 'Open Sans Bold';
  font-weight: 100;
  font-style: normal;
  font-size: 14px;
  color: #000;
`;

const Total = styled.div`
  font-family: 'Montserrat Bold';
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #003da5;
  padding-top: 5px;
`;

const ContainerToggle = styled.div`
  font-family: 'Open Sans Regular', 'Open Sans';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  align-self: center;
`;

export interface IPropsFromState {
  assetsAllocation: IAssetsAllocation;
  filteredAccountIds: string[];
  accountIds: string[];
  accounts: IAccount[];
  isAssetsAllocationFetching: boolean;
  culture: string;
  showAssetAllocationToggleButton: boolean;
  isAssetBreakdown: boolean;
}

export interface IPropsFromDispatch {
  refreshSession: typeof refreshSession;
  setAssetAllocationBreakDown: typeof setAssetAllocationBreakDown;
}

class AssetsAllocationPage extends React.PureComponent<RouteComponentProps & any> {
  constructor(props: RouteComponentProps & IPropsFromState & IPropsFromDispatch) {
    super(props);

    document.title = i18n.t('assetAllocation.documentTitle');
    this.props.refreshSession();
  }

  public handleShowFundAllocationClick = (e: any) => {
    this.props.setAssetAllocationBreakDown(!this.props.isAssetBreakdown);
  };

  private CustomLabel = (viewBox: any) => {
    const { cx, cy, percentage, midAngle } = viewBox;
    const RADIAN = Math.PI / 180;
    const x = cx + 130 * Math.cos(-midAngle * RADIAN);
    const y = cy + 130 * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 14 }}>
        {' '}
        {percentage}%
      </text>
    );
  };

  private renderNegativeContent = (listNegativePosition: IAssetAllocationPosition[]) => {
    const { culture } = this.props;

    if (!listNegativePosition) {
      return null;
    }

    const totalNegative = listNegativePosition.reduce((acc, current) => acc + current.assetAllocationMarketPriceCad, 0);
    return (
      <ExpansionPanel>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="#003da5" />}
          id="debit"
          style={{ paddingLeft: 4, paddingRight: 20 }}
        >
          <Grid fluid style={{ width: '100%', paddingRight: 0 }}>
            <Row between="xs">
              <Col xs={7}>
                <ContainerRow>
                  <LabelBold style={{ paddingTop: '4px' }}>
                    <Trans i18nKey="assetAllocation.debit" />{' '}
                  </LabelBold>
                </ContainerRow>
              </Col>
              <Col xs={5}>
                <H3>
                  <MonetaryValue value={totalNegative} culture={culture} currency="CAD" />{' '}
                </H3>
              </Col>
            </Row>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
            <Row between="xs" style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)', padding: 2 }}>
              <Col xs={9}>
                <Typography variant="subtitle1" component="span">
                  <Trans i18nKey="common.symbolDescription" />
                </Typography>
              </Col>
              <Col xs={3}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Trans i18nKey="assetAllocation.marketValue" />
                </Typography>
              </Col>
            </Row>
            {listNegativePosition.map((item) => this.renderPosition(item, 'negative', 0))}
          </Grid>
        </AccordionDetails>
      </ExpansionPanel>
    );
  };

  private renderPosition = (position: IAssetAllocationPosition, assetCode: string, marketValue: number) => {
    const { culture } = this.props;

    let showPourcentage = false;
    let pourcentage = 0;
    if (marketValue && marketValue !== 0) {
      showPourcentage = true;
      pourcentage = (position.assetAllocationMarketPriceCad * 100) / marketValue;
    }

    return (
      <Row between="xs" key={`${assetCode}-${position.security}`}>
        <Col xs={7}>
          <Typography variant="body1" component="span">
            {position.symbol ? `${position.symbol} - ${position.description}` : position.description}
          </Typography>
        </Col>
        <Col xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {showPourcentage && (
            <Typography variant="body1" component="span">
              <DecimalValue value={pourcentage} culture={culture} amountOfDigits={1} areTrailingZerosVisible />
            </Typography>
          )}
        </Col>
        <Col xs={2}>
          <Typography variant="body1" component="span">
            <MonetaryValue value={position.assetAllocationMarketPriceCad} culture={culture} />
          </Typography>
        </Col>
      </Row>
    );
  };

  private renderAssets = (asset: IAssetAllocationMix) => {
    const { culture } = this.props;

    return (
      <ExpansionPanel key={asset.code}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="#003da5" />}
          id={asset.code}
          style={{ paddingLeft: 4, paddingRight: 20 }}
        >
          <Grid fluid style={{ width: '100%', paddingRight: 0 }}>
            <Row between="xs" style={{ alignItems: 'baseline' }}>
              <Col xs={7}>
                <ContainerRow>
                  <Legendbox color={asset.color} size="28px" font="14px">
                    {asset.code}
                  </Legendbox>
                  <Typography variant="h4" component="span" style={{ paddingTop: '4px' }}>
                    &nbsp; <Trans i18nKey={`${SectionEnum.AssetAllocation}.${asset.description}`} />{' '}
                  </Typography>
                </ContainerRow>
              </Col>
              <Col xs={1}>
                <ContainerRow style={{ fontSize: '16px' }}>
                  <DecimalValue
                    value={asset.allocationPourcentage}
                    culture={culture}
                    amountOfDigits={1}
                    areTrailingZerosVisible
                  />{' '}
                  %
                </ContainerRow>
              </Col>
              <Col xs={4}>
                <H3>
                  <MonetaryValue value={asset.marketValue} culture={culture} currency="CAD" />{' '}
                </H3>
              </Col>
            </Row>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
            <Row between="xs" style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)', padding: 2 }}>
              <Col xs={7}>
                <Typography variant="subtitle1" component="span">
                  <Trans i18nKey="common.symbolDescription" />{' '}
                </Typography>
              </Col>
              <Col xs={3}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  %
                </Typography>
              </Col>
              <Col xs={2}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Trans i18nKey="assetAllocation.marketValue" />
                </Typography>
              </Col>
            </Row>
            {asset.positions.map((item) => this.renderPosition(item, asset.code, asset.marketValue))}
          </Grid>
        </AccordionDetails>
      </ExpansionPanel>
    );
  };

  public render() {
    const {
      isAssetsAllocationFetching,
      filteredAccountIds,
      accountIds,
      accounts,
      assetsAllocation,
      culture,
      showAssetAllocationToggleButton,
      isAssetBreakdown,
    } = this.props;

    if (isAssetsAllocationFetching || !assetsAllocation || !assetsAllocation.breakDown || !assetsAllocation.regular) {
      return (
        <Container>
          <Loading show />
        </Container>
      );
    }

    const currentAssetAllocation = isAssetBreakdown ? assetsAllocation.breakDown : assetsAllocation.regular;
    const allAccountIds = getSelectedAccountIds(accountIds, accounts);
    const tableData = BuildAssetsMix(filteredAccountIds, currentAssetAllocation, allAccountIds);
    const donutData = GetDonutData(tableData);
    const totalMarketValue = tableData.reduce((acc, current) => acc + current.marketValue, 0);
    const negativePositions = getNegativeAssetPositions(currentAssetAllocation, filteredAccountIds, allAccountIds);
    const totalNegative = negativePositions.reduce((acc, current) => acc + current.assetAllocationMarketPriceCad, 0);

    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            <Typography variant="h1" component="span" style={{ paddingBottom: '12px' }}>
              <Trans i18nKey="common.assetAllocation" />
            </Typography>
            <DataLoadStamp isPreviousDay isPortalTimestamp={false} />
          </PageTitleContainer>

          <PageTitleContainer>
            <FilterContainer>
              {showAssetAllocationToggleButton && (
                <ContainerToggle>
                  <Trans i18nKey="assetAllocation.showFundAllocation" />
                  &nbsp;
                  <Switch
                    size="small"
                    defaultChecked={this.props.isAssetBreakdown}
                    onChange={this.handleShowFundAllocationClick}
                  />
                </ContainerToggle>
              )}
              <AccountFilter isRegisterAccountFilter={false} />
            </FilterContainer>
          </PageTitleContainer>
        </HeaderPageContainer>

        <MainBox>
          <Row>
            <Col xs={4}>
              <div className="boxGrey">
                <TotalLabel>{i18n.t('common.totalMarketValue') as string}</TotalLabel>
                <Total>
                  <MonetaryValue
                    value={totalMarketValue + totalNegative}
                    culture={culture}
                    currency="CAD"
                    alignRight={false}
                    fixCurrency
                  />
                </Total>
              </div>
            </Col>
            <Col xs={8}>
              <Grid fluid style={{ width: '100%', height: '100%', padding: 0 }}>
                {HideAssetAllocationPieChart(tableData) ? (
                  <Row middle="xs" center="xs" style={{ padding: 20, height: '100%' }}>
                    <Col xs={12}>
                      <NoData text={i18n.t('assetAllocation.noChart')} />
                    </Col>
                  </Row>
                ) : (
                  <Row middle="xs" center="xs">
                    <Col xs={6}>
                      {donutData && donutData.length > 0 ? (
                        <PieChart width={350} height={300}>
                          <Pie
                            data={donutData}
                            dataKey="value"
                            cx={175}
                            cy={150}
                            innerRadius={70}
                            outerRadius={108}
                            label={<this.CustomLabel />}
                            isAnimationActive={false}
                          />
                        </PieChart>
                      ) : (
                        <NoData text={i18n.t('common.noData')} />
                      )}
                    </Col>

                    <Col xs={6} style={{ paddingRight: 14 }}>
                      <Container>
                        <ContainerLegend>
                          <Legendbox color="#003DA5" size="28px">
                            A
                          </Legendbox>
                          <span>&nbsp;</span> <Trans i18nKey="assetAllocation.cashAndEquivalents" />
                        </ContainerLegend>
                        <ContainerLegend>
                          <Legendbox color="#acafae" size="28px">
                            B
                          </Legendbox>
                          <span>&nbsp;</span> <Trans i18nKey="assetAllocation.fixedIncomeSecurities" />
                        </ContainerLegend>
                        <ContainerLegend>
                          <Legendbox color="#836c4b" size="28px">
                            C
                          </Legendbox>
                          <span>&nbsp;</span> <Trans i18nKey="assetAllocation.equities" />{' '}
                        </ContainerLegend>
                        <ContainerLegend>
                          <Legendbox color="#000548" size="28px">
                            D
                          </Legendbox>
                          <span>&nbsp;</span> <Trans i18nKey="assetAllocation.otherAssets" />
                        </ContainerLegend>
                      </Container>
                    </Col>
                  </Row>
                )}
              </Grid>
            </Col>
          </Row>
        </MainBox>
        <br />
        <MainBox>
          {tableData.map((m) => (m != null ? this.renderAssets(m) : ''))}

          {negativePositions.length > 0 ? (
            <Grid
              fluid
              style={{
                width: '100%',
                paddingRight: 0,
                paddingBottom: 10,
                paddingTop: 10,
                borderTop: '1px solid rgba(0, 0, 0, .125)',
              }}
            >
              <Row between="xs">
                <Col xs={7}>
                  <Typography variant="h4" component="span">
                    <Trans i18nKey="assetAllocation.subTotal" />
                  </Typography>
                </Col>
                <Col xs={5} style={{ paddingRight: 63 }}>
                  <H3>
                    <MonetaryValue value={totalMarketValue} culture={culture} currency="CAD" />{' '}
                  </H3>
                </Col>
              </Row>
            </Grid>
          ) : null}
          {negativePositions.length > 0 ? this.renderNegativeContent(negativePositions) : null}

          <Grid
            fluid
            style={{
              width: '100%',
              paddingRight: 0,
              paddingBottom: 30,
              paddingTop: 10,
              borderTop: '1px solid rgba(0, 0, 0, .125)',
            }}
          >
            <Row between="xs">
              <Col xs={7}>
                <Typography variant="h4" component="span">
                  <Trans i18nKey="common.totalMarketValue" />
                </Typography>
              </Col>
              <Col xs={5} style={{ paddingRight: 63 }}>
                <H3>
                  <MonetaryValue value={totalMarketValue + totalNegative} culture={culture} currency="CAD" />{' '}
                </H3>
              </Col>
            </Row>
          </Grid>
        </MainBox>

        <ExchangeRateDisclaimer forceOff />
        <PrintButton />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;
  return {
    accountIds: getAccountIds(state),
    accounts: getAllCurrentFilterAccounts(state),
    assetsAllocation: getAssetsAllocation(state),
    filteredAccountIds: getFilteredAccountIds(state),
    isAssetsAllocationFetching: getIsAssetsAllocationFetching(state),
    culture: system.culture,
    showAssetAllocationToggleButton: system.showAssetAllocationToggleButton,
    isAssetBreakdown: system.isAssetBreakdown,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      refreshSession,
      setAssetAllocationBreakDown,
    },
    dispatch,
  );

export default connect<any>(mapStateToProps as any, mapDispatchToProps)(AssetsAllocationPage as any);
