import * as React from 'react';
import Colors from '~/common/themes/colors';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { PositionSummaryTable } from '../summary-table/PositionSummaryTable';
import { ComponentWithValidation } from '../summary-table/ComponentWithValidation';

export const StockSummary = (stockData: any) => {
  const stockDataInfo = stockData.stockData;

  return (
    <PositionSummaryTable
      rows={4}
      cols={3}
      positionSummaryContainerXS={8}
      data={[
        {
          labelKey: 'stockOverview.bidAsk',
          positionValue: <><ComponentWithValidation value={stockDataInfo.bid} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.bid} isMoney />} /> / <ComponentWithValidation value={stockDataInfo.ask} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.ask} isMoney />} /> </>,
        },
        {
          labelKey: 'stockOverview.previousClose',
          positionValue: <ComponentWithValidation value={stockDataInfo.previousClose} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.previousClose} isMoney />} />,
        },
        {
          labelKey: 'stockOverview.dayRange',
          positionValue: <><ComponentWithValidation value={stockDataInfo.low} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.low} isMoney />} /> - <ComponentWithValidation value={stockDataInfo.high} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.high} isMoney />} /> </>,
        },

        {
          labelKey: 'stockOverview.52WeekRange',
          positionValue: <><ComponentWithValidation value={stockDataInfo.low52Weeks} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.low52Weeks} isMoney />} /> - <ComponentWithValidation value={stockDataInfo.high52Weeks} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.high52Weeks} isMoney />} /> </>,
        },
        {
          labelKey: 'stockOverview.volumeAverage',
          positionValue: <><ComponentWithValidation value={stockDataInfo.volume} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.volume} />} />/ <ComponentWithValidation value={stockDataInfo.averageVolume30Days} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.averageVolume30Days} />} /> </>,
        },
        {
          labelKey: 'stockOverview.marketCap',
          toolTipKey: 'stockOverview.marketCapTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: <><ComponentWithValidation value={stockDataInfo.marketCap} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.marketCap} isMoney />} /> </>,
        },

        {
          labelKey: 'stockOverview.sharesOutstanding',
          toolTipKey: 'stockOverview.sharesOutstandingTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: <><ComponentWithValidation value={stockDataInfo.sharesOutstanding} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.sharesOutstanding} isMoney />} /> </>,
        },
        {
          labelKey: 'stockOverview.expectedDividend',
          positionValue: <><ComponentWithValidation value={stockDataInfo.expectedDividendPerShare} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.expectedDividendPerShare} isMoney />} /> / <ComponentWithValidation value={stockDataInfo.expectedDividendYield} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.expectedDividendYield} isPercent />} /> </>,
        },
        {
          labelKey: 'stockOverview.beta',
          toolTipKey: 'stockOverview.betaTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: <><ComponentWithValidation value={stockDataInfo.beta} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.beta} />} /> </>,
        },

        {
          labelKey: 'stockOverview.priceEarningsRatio',
          toolTipKey: 'stockOverview.sharesOutstandingTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: <><ComponentWithValidation value={stockDataInfo.priceToEarningsRatioTTM} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.priceToEarningsRatioTTM} />} /> </>,
        },
        {
          labelKey: 'stockOverview.earningsShare',
          positionValue: <><ComponentWithValidation value={stockDataInfo.stockInfo.earningsPerShareTTM} componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.stockInfo.earningsPerShareTTM} isMoney />} /> </>,
        },
      ]}
    />
  );
};
