import * as React from 'react';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { isFieldEmptyOrNull } from '~/common/helpers';
import DataGrid, { IDataGridDisplay } from '~/common/components/data-grid/data-grid';

export interface IProps {
    stock: any
}

class QuarterlyStatements extends React.PureComponent<IProps> {
  public render() {
    const data : IDataGridDisplay[] = [
      {
        key: 'stockOverview.bookValue',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.bookValueQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.bookValueQuarterly} isMoney />,
      },
      {
        key: 'stockOverview.cashflowPerShare',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.cashFlowPerShareQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.cashFlowPerShareQuarterly} isMoney />,
      },
      {
        key: 'stockOverview.earningsPerShare',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.earningsPerShareQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.earningsPerShareQuarterly} isMoney />,
      },
      {
        key: 'stockOverview.netIncome',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.netIncomeQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.netIncomeQuarterly} isMoney />,
      },
      {
        key: 'stockOverview.operatingFundShare',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.operatingFundPerShareQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.operatingFundPerShareQuarterly} isMoney />,
      },
      {
        key: 'stockOverview.totalRevenue',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.totalRevenueQuarterly) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.totalRevenueQuarterly} isMoney />,
      },
    ];
    return (
      <DataGrid data={data} title="stockOverview.quarterlyStatements" />
    );
  }
}

export default QuarterlyStatements;
