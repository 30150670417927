import * as React from 'react';
import Peers from '~/common/components/position-overview/Peers';
import PerformanceSection from '~/common/components/position-overview/performance/PerformanceSection';
import Performance from '~/common/components/position-overview/performance/Performance';
import i18n from '~/app/i18n';
import Grid from '@mui/material/Grid';
import { ChartContainer, TabsContainer } from '~/common/components/position-overview/PositionOverviewPage.style';
import { isPeersSectionEnabled } from '~/common/featureToggleHelper';
import TopHolding from './TopHolding';
import FundAsset from './FundAsset';

interface IFundOverviewDataProps {
  fundData: any,
  historicalData: any
}

export const FundOverview: React.FunctionComponent<IFundOverviewDataProps> = (props) => (
  <TabsContainer>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Performance symbol={props.fundData.fundSymbol} historicalData={props.historicalData} dataKey="close" />
      </Grid>
      <Grid item xs={12}>
        <PerformanceSection perfomanceData={props.fundData.performance} symbol={props.fundData.fundSymbol} />
      </Grid>
      <Grid item xs={!isPeersSectionEnabled() ? 12 : 5}>
        <TopHolding data={props.fundData.topHoldings} />
      </Grid>
      {isPeersSectionEnabled() &&
        <Grid item xs={7}>
          <Peers data={props.fundData.peers} />
        </Grid>}
      <Grid item xs={12}>
        <ChartContainer style={{ borderRight: 'none' }}>
          <FundAsset data={props.fundData.assetAllocations} type="assetType" title={i18n.t('stockOverview.fund.assetAllocation')} />
        </ChartContainer>
        <ChartContainer style={{ borderRight: 'none' }}>
          <FundAsset data={props.fundData.sectorAllocations} type="sectorType" title={i18n.t('stockOverview.fund.sectorAllocation')} />
        </ChartContainer>
        <ChartContainer>
          <FundAsset data={props.fundData.regionAllocations} type="geoType" title={i18n.t('stockOverview.fund.geographicAllocation')} />
        </ChartContainer>
      </Grid>
    </Grid>
  </TabsContainer>
);

export default FundOverview;
