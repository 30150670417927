import { Button } from '@mui/material';
import React from 'react';
import Colors from '~/common/themes/colors';

export interface ILinkButtonProps {
  onClick: () => void;
  isProcessing?: boolean;
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const LinkButton = ({ onClick, children, isProcessing, startIcon, endIcon }: ILinkButtonProps) => (
  <Button
    component="span"
    onClick={onClick}
    variant="text"
    startIcon={startIcon}
    endIcon={endIcon}
    sx={{
      color: Colors.mariner,
      textTransform: 'none',
      ':hover': { textDecoration: 'underline' },
      paddingLeft: 0,
      '& .MuiButton-startIcon': { paddingRight: 0.5, marginRight: 0.5 },
      '& .MuiButton-endIcon': { paddingLeft: 0.5, marginLeft: 0.5 },
    }}
    disableRipple
    disabled={isProcessing}
  >
    {children}
  </Button>
);

export default LinkButton;
