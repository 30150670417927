import * as React from 'react';
import i18n from '~/app/i18n';
import CancelIcon from '@mui/icons-material/Cancel';
import { CancelIconStyle } from './ModalSaveResult.style';
import ModalSaveResult from './ModalSaveResult';

interface IModalSaveFailedProps {
  onClose: () => void,
  isVisible: boolean,
  message: string
}

const ModalSaveFail: React.FC<IModalSaveFailedProps> = (props: IModalSaveFailedProps) => (
  <ModalSaveResult
    onClose={props.onClose}
    isVisible={props.isVisible}
    header={i18n.t('common.error')}
    icon={<CancelIcon style={CancelIconStyle} />}
    title={i18n.t('common.errorMessageTitle')}
    message={props.message}
  />);
export default ModalSaveFail;
