import * as React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { PositionSummaryTable2 } from '../../position-overview/summary-table/PositionSummaryTable';
import Colors from '~/common/themes/colors';

export interface IProps {
  data: any[];
  rows?: number;
  cols: number;
  isFetching?: boolean;
}

const SummaryRightGrid = ({ data, cols, rows, isFetching }: IProps) => (
  <Grid container item xs={12} style={{ backgroundColor: Colors.white }}>
    {isFetching ? (
      <Grid container item xs={12} m={3}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" width="95%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" width="50%" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" width="95%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" width="50%" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" width="95%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" width="50%" height={20} />
        </Grid>
      </Grid>
    ) : (
      <PositionSummaryTable2 cols={cols} rows={rows} positionSummaryContainerXS={12} data={data} />
    )}
  </Grid>
);

export default SummaryRightGrid;
