import * as React from 'react';
import {
  getSelectedTransactions,
  getIsTransactionFetching,
  getExcludeCloseAccount,
  getIsTransactionFetchingFail,
  getSelectedProductTypeIds,
  getSelectedOperationTypeIds,
  getOperationTypeContextualOptions,
  getSelectedCurrencyIds,
} from '~/stores/transaction/selectors/transactionSelectors';
import { fetchTransactions } from '~/stores/transaction/actions/transactionActions';
import { IAppRootState } from '~/app/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ITransaction } from '~/stores/transaction/transactionTypes';
import styled from 'styled-components';
import {
  getIsAccountsFetching,
  getAccountIds,
  getAllCurrentFilterAccounts,
  getFilteredAccountIds,
} from '~/stores/account/selectors/accountSelectors';
import { IAccount } from '~/stores/account/accountTypes';
import Loading from '../../common/components/Loading/Loading';
import { Trans } from 'react-i18next';
import { Table, Input, Checkbox } from 'antd';
import i18n from '~/app/i18n';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { MonetaryValue } from '~/common/components';
import memoizeOne from 'memoize-one';
import DateFormat from '~/common/components/date-format/DateFormat';
import moment from 'moment';
import 'antd/lib/pagination/style/index.css';
import NoData from '~/common/components/noData/NoData';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import { HttpGet } from '~/common/utils/HttpClient';
import FileSaver from 'file-saver';
import { getToken } from '~/stores/system/selectors/SystemSelectors';
import { getIsHouseHoldingView, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import {
  Container,
  PageTitleContainer,
  HeaderPageContainer,
  FilterContainer,
  ContainerRow,
  P,
} from '~/common/styles/baseStyle';
import AccountFilter from '~/common/components/account/HouseholdAccountFilter';
import { filterAccounts, getSelectedAccountIds } from '~/common/accountsHelpers';
import { ProductTypeEnum, CurrencyEnum } from '~/common/types';
import { setQueryStringParameters, refreshSession } from '~/stores/system/actions/systemActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { getCurrentIsoLang } from '~/common/helpers';
import { API_ACCOUNTS } from '~/common/API';
import PrintButton from '~/common/components/print-button/PrintButton';
import { Snackbar, Typography, IconButton } from '@mui/material';
import { ExportCSV, ExportCSVButton } from '~/common/components/export/ExportCSV';
import { autoHideDurationError, exportCSVType } from '~/common/constants';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelect, { IOption } from './MultiSelect';
import FilterHistory from './FilterHistory';

const { Search } = Input;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const SmallTextContainer = styled.div`
  font-size: 12px;
`;

export interface IPropsFromState {
  accountIds: string[];
  party: IPartyV1;
  transactions: ITransaction[];
  isTransactionFetching: boolean;
  isTransactionFetchingFail: boolean;
  accounts: IAccount[];
  isAccountFetching: boolean;
  filteredAccountIds: string[];
  transactionsStartDate: string;
  transactionsEndDate: string;
  excludeCloseAcount: boolean;
  selectedProductIds: ProductTypeEnum[];
  selectedOperationIds: string[];
  selectedCurrencyIds: string[];
  operationTypeContextualOptions: IOption[];
  symbolDescription: string;
  culture: string;
  token: string;
  isHouseHoldingView: boolean;
}

export interface ITransactionState {
  modalAddVisible: boolean;
  csvFilename: string;
  csvDelimiter: string;
  csvDecimalSeparator: string;
  csvDateFormat: string;
  csvRemoveAccent: boolean;
  openCSVError: boolean;
  isCSVDownloading: boolean;
}

export interface IPropsFromDispatch {
  fetchTransactions: typeof fetchTransactions;
  setQueryStringParameters: typeof setQueryStringParameters;
  refreshSession: typeof refreshSession;
}

export interface ITransactionTableRowData {
  id: number;
  tradeDate: string;
  processDate: string;
  settlementDate: string;
  account: string;
  accountOwner: string;
  accountType: string;
  operation: string;
  symbol: string;
  description: string;
  quantity: number;
  price: number;
  priceCurrency: string;
  netAmount: number;
  netAmountCurrency: string;
  dripValue?: number;
  isMutualFund: boolean;
}

export type SortOrder = 'descend' | 'ascend';
export type align = 'right' | 'left';

class HistoryPage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, ITransactionState> {
  constructor(props: any) {
    super(props);
    this.props.refreshSession();
    const defaultFileName = `Transactions_${moment().format('YYYY')}${moment().format('MM')}${moment().format('DD')}`;

    this.state = {
      modalAddVisible: false,
      csvFilename: defaultFileName,
      csvDelimiter: ',',
      csvDecimalSeparator: '.',
      csvDateFormat: 'dd/mm/yyyy',
      csvRemoveAccent: false,
      openCSVError: false,
      isCSVDownloading: false,
    };

    document.title = i18n.t('history.documentTitle');
  }

  private tableRowsData = memoizeOne(
    (transactions: ITransaction[], accounts: IAccount[]): ITransactionTableRowData[] => {
      const transactionTableRowsData = [] as ITransactionTableRowData[];

      const transactionFilter = transactions.filter((a) => accounts.some((id) => id.id === a.accountId));

      transactionFilter.forEach((transaction, index) => {
        const transactionAccount = accounts.find((a) => a.id === transaction.accountId);
        const account = !transactionAccount ? transaction.accountId : transactionAccount.id;
        const accountOwner = !transactionAccount ? '' : transactionAccount.name;
        const accountType = !transactionAccount ? '' : transactionAccount.accountType;

        const transactionTableRowData = {
          id: index,
          tradeDate: transaction.tradeDate,
          processDate: transaction.processDate,
          settlementDate: transaction.settlementDate,
          account,
          accountOwner,
          accountType,
          operation: transaction.operationDescription,
          symbol: transaction.symbol,
          description: transaction.description,
          quantity: transaction.nbUnits,
          price: transaction.unitPrice,
          priceCurrency: transaction.currencyCode,
          netAmount: transaction.net,
          netAmountCurrency: transaction.netAmountCurrency,
          dripValue: transaction.dripValue,
          isMutualFund: transaction.isMutualFund,
        } as ITransactionTableRowData;

        transactionTableRowsData.push(transactionTableRowData);
      });

      return transactionTableRowsData;
    },
  );

  private fetchData = (hasFilterChanged: boolean, isAccountSwitch: boolean) => {
    const { transactionsStartDate, transactionsEndDate, accountIds, accounts } = this.props;
    this.props.fetchTransactions({
      accountIds: getSelectedAccountIds(accountIds, accounts),
      startDate: transactionsStartDate,
      endDate: transactionsEndDate,
      hasFilterChanged,
      isAccountSwitch,
    });
  };

  public componentDidUpdate(prevProps: IPropsFromState & IPropsFromDispatch) {
    this.props.refreshSession();
    if (
      prevProps.transactionsStartDate !== this.props.transactionsStartDate ||
      prevProps.transactionsEndDate !== this.props.transactionsEndDate ||
      prevProps.isHouseHoldingView !== this.props.isHouseHoldingView
    ) {
      // || prevProps.filteredAccountIds !== this.props.filteredAccountIds
      this.fetchData(true, true);
    }
  }

  public showAddModal = (event: any) => {
    this.setState({
      modalAddVisible: true,
    });
  };

  // Calls account API
  public handleOk = () => {
    const { accountIds, transactionsStartDate, transactionsEndDate, token, accounts } = this.props;
    const csvAccountIds = getSelectedAccountIds(accountIds, accounts);
    const parameterAccountIds = `accountIds=${csvAccountIds.join(',')}`;
    const url = `${API_ACCOUNTS}/Transactions/Export/Csv?${parameterAccountIds}&startTransactionDate=${transactionsStartDate}&endTransactionDate=${transactionsEndDate}&lang=${getCurrentIsoLang()}&csvFilename=${encodeURIComponent(
      this.state.csvFilename,
    )}&csvDelimiter=${encodeURIComponent(this.state.csvDelimiter)}&csvDecimalSeparator=${encodeURIComponent(
      this.state.csvDecimalSeparator,
    )}&csvDateFormat=${encodeURIComponent(this.state.csvDateFormat)}&csvRemoveAccent=${this.state.csvRemoveAccent}`;
    const options = {
      headers: {
        Accept: 'text/csv',
      },
      responseType: 'arraybuffer',
    };

    HttpGet(url, token, this.downloadPdfCallback, this.downloadPdfCallbackError, options);

    this.setState({
      isCSVDownloading: true,
      modalAddVisible: false,
    });
  };

  public handleCancel = () => {
    this.setState({
      modalAddVisible: false,
    });
  };

  private downloadPdfCallback = (data: any) => {
    const blob = new Blob([data], { type: exportCSVType });
    const fileName = `${this.state.csvFilename}.csv`;
    FileSaver.saveAs(blob, fileName);
    this.setState({
      isCSVDownloading: false,
    });
  };

  private downloadPdfCallbackError = (error: any) => {
    this.setState({
      openCSVError: true,
      isCSVDownloading: false,
    });
  };

  public handleCsvFilename = (event: React.ChangeEvent<HTMLInputElement>) => {
    const csvFilename = event.target.value;
    this.setState({ csvFilename });
  };

  public handleCsvDelimiter = (csvDelimiter: string) => {
    this.setState({ csvDelimiter });
  };

  public handleCsvDecimalSeparator = (csvDecimalSeparator: string) => {
    this.setState({ csvDecimalSeparator });
  };

  public handleCsvDateFormat = (csvDateFormat: string) => {
    this.setState({ csvDateFormat });
  };

  public handleCsvRemoveAccent = (event: any) => {
    const csvRemoveAccent = event.target.checked;
    this.setState({ csvRemoveAccent });
  };

  /* eslint-disable function-paren-newline */
  public handleOnProductTypeSelected = (selectedOptionIds: string[]) => {
    if (!selectedOptionIds || selectedOptionIds.length <= 0) {
      this.props.setQueryStringParameters([{ name: 'productTypeIds', value: '' }]);
    } else {
      const optionIds = selectedOptionIds.reduce((accumulator, selectedOptionId, index) =>
        index === 0 ? `${selectedOptionId}` : `${accumulator}-${selectedOptionId}`,
      );
      this.props.setQueryStringParameters([{ name: 'productTypeIds', value: optionIds }]);
    }
  };
  /* eslint-enable function-paren-newline */

  public handleCurrencySelected = (event: any) => {
    let selectedCurrency = '';
    if (!event.target.checked) {
      if (event.target.value === CurrencyEnum.CAD) {
        selectedCurrency = CurrencyEnum.USD;
      } else {
        selectedCurrency = CurrencyEnum.CAD;
      }
    }

    this.props.setQueryStringParameters([{ name: 'currencyIds', value: selectedCurrency }]);
  };

  /* eslint-disable function-paren-newline */
  public handleOnOperationTypeSelected = (selectedOptionIds: string[]) => {
    if (!selectedOptionIds || selectedOptionIds.length <= 0) {
      this.props.setQueryStringParameters([{ name: 'operationTypeIds', value: '' }]);
    } else {
      const optionIds = selectedOptionIds.reduce((accumulator, selectedOptionId, index) =>
        index === 0 ? `${selectedOptionId}` : `${accumulator}-${selectedOptionId}`,
      );
      this.props.setQueryStringParameters([{ name: 'operationTypeIds', value: optionIds }]);
    }
  };
  /* eslint-enable function-paren-newline */

  public handleOnChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { symbolDescription } = this.props;

    const symbolDescriptionFilter = event.target.value;

    if (symbolDescription !== symbolDescriptionFilter) {
      this.props.setQueryStringParameters([{ name: 'symboldescription', value: symbolDescriptionFilter }]);
    }
  };

  private GetDripValueDisplay = (dripValue: number, culture: string) => {
    let dripValueDisplay = '';
    if (dripValue) {
      dripValueDisplay = String(dripValue.toFixed(2));
      if (culture === 'fr-CA') {
        dripValueDisplay = dripValueDisplay.replace('.', ',');
      }
    }

    return dripValueDisplay;
  };

  private isInitTransaction = false;

  private optionsProduct = [
    {
      id: 'all',
      text: <Trans i18nKey="common.filter_Choice_All" />,
      childOptions: [
        { id: ProductTypeEnum.STOCKS, text: <Trans i18nKey="history.productType_stocks" /> },
        { id: ProductTypeEnum.OPTIONS, text: <Trans i18nKey="history.productType_options" /> },
        { id: ProductTypeEnum.MUTUAL_FUND, text: <Trans i18nKey="history.productType_mutualFund" /> },
        { id: ProductTypeEnum.GICS, text: <Trans i18nKey="history.productType_gics" /> },
        {
          id: ProductTypeEnum.FIXED_INCOME_SECURITIES,
          text: <Trans i18nKey="history.productType_fixesIncomeSecurities" />,
        },
      ],
    },
  ] as IOption[];

  private renderAccount = (value: any, row: any, index: number) => {
    const obj = {
      children: (
        <RowContainer>
          <div>
            <b>{row.accountType}</b>
          </div>
          <SmallTextContainer>{row.accountOwner}</SmallTextContainer>
          <SmallTextContainer>{row.account}</SmallTextContainer>
        </RowContainer>
      ),
    };
    return obj;
  };

  private renderSymbol = (value: any, row: any, index: number) => {
    const dripValue = row.dripValue ? (
      <Trans i18nKey="history.dripValue" values={{ 0: this.GetDripValueDisplay(row.dripValue, this.props.culture) }} />
    ) : null;

    const obj = {
      children: (
        <RowContainer>
          <div>{row.symbol}</div>
          <SmallTextContainer>{row.description}</SmallTextContainer>
          <SmallTextContainer>{dripValue}</SmallTextContainer>
        </RowContainer>
      ),
    };
    return obj;
  };

  private tradeDateSorting = (a: any, b: any) => {
    const compare = moment(a.tradeDate).unix() - moment(b.tradeDate).unix();
    if (compare !== 0) {
      return compare;
    }
    return moment(a.processDate).unix() - moment(b.processDate).unix();
  };

  private columns = [
    {
      title: i18n.t('history.tradeDate'),
      dataIndex: 'tradeDate',
      key: 'tradeDate',
      render: (value: any) => <DateFormat value={value} />,
      width: '110px',
      sorter: (a: any, b: any) => this.tradeDateSorting(a, b),
      defaultSortOrder: 'descend' as SortOrder,
    },
    {
      title: i18n.t('history.settlementDate'),
      dataIndex: 'settlementDate',
      key: 'settlementDate',
      render: (value: any) => <DateFormat value={value} />,
      sorter: (a: any, b: any) => moment(a.settlementDate).unix() - moment(b.settlementDate).unix(),
      width: '105px',
    },
    {
      title: i18n.t('history.account'),
      dataIndex: 'accountType',
      key: 'accountType',
      render: this.renderAccount,
      width: '140px',
      sorter: (a: any, b: any, sortOrder: any) => {
        if (a.account != null && b.account != null) {
          return a.account.localeCompare(b.account);
        }
        if (a.account) {
          return sortOrder === 'ascend' ? 1 : -1;
        }
        if (b.account) {
          return sortOrder === 'ascend' ? -1 : 1;
        }
        return 0;
      },
    },
    {
      title: i18n.t('history.symbol'),
      key: 'symbol',
      dataIndex: 'symbol',
      render: this.renderSymbol,
      sorter: (a: any, b: any, sortOrder: any) => {
        if (a.symbol != null && b.symbol != null) {
          return a.symbol.localeCompare(b.symbol);
        }
        if (a.symbol) {
          return sortOrder === 'ascend' ? 1 : -1;
        }
        if (b.symbol) {
          return sortOrder === 'ascend' ? -1 : 1;
        }
        return 0;
      },
      width: '190px',
      className: 'wrap',
    },
    {
      title: i18n.t('history.operation'),
      key: 'operation',
      dataIndex: 'operation',
      width: '108px',
      sorter: (a: any, b: any, sortOrder: any) => {
        if (a.operation != null && b.operation != null) {
          return a.operation.localeCompare(b.operation);
        }
        if (a.operation) {
          return sortOrder === 'ascend' ? 1 : -1;
        }
        if (b.operation) {
          return sortOrder === 'ascend' ? -1 : 1;
        }
        return 0;
      },
      className: 'wrap',
    },
    {
      title: i18n.t('history.quantity'),
      key: 'quantity',
      dataIndex: 'quantity',
      render: (value: any, record: any) => (
        <DecimalValue
          value={value}
          culture={this.props.culture}
          amountOfDigits={3}
          zeroPlaceholder="0"
          areTrailingZerosVisible={record.isMutualFund}
        />
      ),
      width: '90px',
      sorter: (a: any, b: any) => a.quantity - b.quantity,
      align: 'right' as align,
    },
    {
      title: i18n.t('history.price'),
      key: 'price',
      dataIndex: 'price',
      width: '105px',
      sorter: (a: any, b: any) => a.price - b.price,
      render: (value: any, record: any) => (
        <MonetaryValue value={value} culture={this.props.culture} currency={record.priceCurrency} zeroPlaceholder="-" />
      ),
      align: 'right' as align,
    },
    {
      title: i18n.t('history.netAmount'),
      key: 'netAmount',
      dataIndex: 'netAmount',
      render: (value: any, record: any) => (
        <MonetaryValue
          value={value}
          culture={this.props.culture}
          currency={record.netAmountCurrency}
          zeroPlaceholder="-"
        />
      ),
      width: '120px',
      align: 'right' as align,
      sorter: (a: any, b: any) => a.netAmount - b.netAmount,
    },
  ];

  private handleCSVErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openCSVError: false });
  };

  public render() {
    const {
      transactions,
      isTransactionFetching,
      isTransactionFetchingFail,
      accounts,
      transactionsStartDate,
      transactionsEndDate,
      symbolDescription,
      isAccountFetching,
      filteredAccountIds,
      selectedProductIds,
      selectedOperationIds,
      operationTypeContextualOptions,
      selectedCurrencyIds,
    } = this.props;

    const accountFilter = filterAccounts(accounts, filteredAccountIds);
    const tableRowsData = this.tableRowsData(transactions, accountFilter);
    const isCAD = selectedCurrencyIds.length === 0 ? true : selectedCurrencyIds.indexOf(CurrencyEnum.CAD) !== -1;
    const isUSD = selectedCurrencyIds.length === 0 ? true : selectedCurrencyIds.indexOf(CurrencyEnum.USD) !== -1;

    const optionsOperation = [
      {
        id: 'all',
        text: <Trans i18nKey="common.filter_Choice_All" />,
        childOptions: operationTypeContextualOptions,
      },
    ] as IOption[];

    if (isAccountFetching || isTransactionFetching) {
      return <Loading show />;
    }

    if (!this.isInitTransaction) {
      this.isInitTransaction = true;
      this.fetchData(true, true);
    }

    const transactionEmptyText = isTransactionFetchingFail
      ? i18n.t('common.dataNotAvailable')
      : i18n.t('common.noData');
    const action = (
      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCSVErrorClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
    const sortText = i18n.t('common.sort');

    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            <Typography variant="h1" component="div" style={{ paddingBottom: '12px' }}>
              <Trans i18nKey="common.history" />
            </Typography>
            <DataLoadStamp isPreviousDay isPortalTimestamp={false} />
          </PageTitleContainer>
          <PageTitleContainer>
            <FilterContainer>
              <Search
                placeholder={i18n.t('history.description')}
                style={{ width: 140, margin: '4px 0px 4px 8px', color: 'blue' }}
                onChange={this.handleOnChangeDescription}
                value={symbolDescription}
              />
              <FilterHistory startDate={transactionsStartDate} endDate={transactionsEndDate} />
              <MultiSelect
                label={i18n.t('common.operationTypeFilter')}
                options={optionsOperation}
                selectedOptionIds={selectedOperationIds}
                onOptionSelected={this.handleOnOperationTypeSelected}
              />
              <MultiSelect
                label={i18n.t('common.productTypeFilter')}
                options={this.optionsProduct}
                selectedOptionIds={selectedProductIds}
                onOptionSelected={this.handleOnProductTypeSelected}
              />
              <AccountFilter isRegisterAccountFilter={false} />
            </FilterContainer>
          </PageTitleContainer>
        </HeaderPageContainer>

        <ContainerRow style={{ justifyContent: 'space-between', alignItems: 'flex-end', paddingBottom: 10 }}>
          <P style={{ fontSize: 14 }}>
            <Trans i18nKey="common.currencyFilter" /> :{' '}
            <Checkbox checked={isCAD} onChange={this.handleCurrencySelected} value={CurrencyEnum.CAD}>
              CAD
            </Checkbox>{' '}
            <Checkbox checked={isUSD} onChange={this.handleCurrencySelected} value={CurrencyEnum.USD}>
              USD
            </Checkbox>
          </P>
          <ExportCSVButton isProcessing={this.state.isCSVDownloading} onClick={() => this.showAddModal(null)} />
        </ContainerRow>

        <ExportCSV
          showModal={this.state.modalAddVisible}
          modalTitle={i18n.t('common.csvModalTitle')}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          handleCsvFilename={this.handleCsvFilename}
          csvFilename={this.state.csvFilename}
          handleCsvDelimiter={this.handleCsvDelimiter}
          delimiter={this.state.csvDelimiter}
          handleCsvDecimalSeparator={this.handleCsvDecimalSeparator}
          decimalSeparator={this.state.csvDecimalSeparator}
          handleCsvRemoveAccent={this.handleCsvRemoveAccent}
          removeAccent={this.state.csvRemoveAccent}
          disclaimer={i18n.t('history.csvModalDisclaimer')}
          dateFormatInclude
          handleCsvDateFormat={this.handleCsvDateFormat}
          csvDateFormat={this.state.csvDateFormat}
        />

        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          style={{ backgroundColor: 'white', boxShadow: '2px 2px 2px rgba(102, 102, 102, 0.349019607843137)' }}
          rowKey="id"
          columns={this.columns}
          dataSource={tableRowsData}
          pagination={{ showSizeChanger: true, size: 'small' }}
          locale={{
            emptyText: <NoData text={transactionEmptyText} />,
            triggerDesc: i18n.t('common.sortDescending'),
            triggerAsc: i18n.t('common.sortAscending'),
          }}
          showSorterTooltip={{ title: sortText, placement: 'bottom' }}
        />
        <PrintButton />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openCSVError}
          autoHideDuration={autoHideDurationError}
          onClose={this.handleCSVErrorClose}
          message={i18n.t('common.csvFileDownloadError') as string}
          action={action}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    token: getToken(state),
    party: getPartyV1(state),
    accountIds: getAccountIds(state),
    transactions: getSelectedTransactions(state),
    isTransactionFetching: getIsTransactionFetching(state),
    isTransactionFetchingFail: getIsTransactionFetchingFail(state),
    isAccountFetching: getIsAccountsFetching(state),
    accounts: getAllCurrentFilterAccounts(state),
    filteredAccountIds: getFilteredAccountIds(state),
    transactionsStartDate: state.transaction.filter.startDate,
    transactionsEndDate: state.transaction.filter.endDate,
    excludeCloseAcount: getExcludeCloseAccount(state),
    isHouseHoldingView: getIsHouseHoldingView(state),
    selectedProductIds: getSelectedProductTypeIds(state),
    selectedOperationIds: getSelectedOperationTypeIds(state),
    operationTypeContextualOptions: getOperationTypeContextualOptions(state),
    selectedCurrencyIds: getSelectedCurrencyIds(state),
    symbolDescription: state.transaction.filter.symbolDescription,
    culture: state.system.culture,
    modalAddVisible: false,
    csvFilename: '',
    csvDelimiter: '',
    csvDecimalSeparator: '',
    csvDateFormat: '',
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchTransactions,
      setQueryStringParameters,
      refreshSession,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);
