import axios, { AxiosResponse } from 'axios';

function AddTokenToOptions(options: any, token: string) {
  if (options === null || options === undefined) {
    return { headers: { Bearer: token } };
  }
  if (options.headers === null || options.headers === undefined) {
    options.headers = { Bearer: token };
    return options;
  }
  options.headers.Bearer = token;
  return options;
}

export function HttpGet(url: string, token: string, completedCallback: any, completedCallbackError: any, options: any = {}) {
  options = AddTokenToOptions(options, token);

  axios.get(url, options)
    .then((response) => {
      if (completedCallback) {
        completedCallback(response.data);
      }
    })
    .catch((error) => {
      completedCallbackError(error);
    });
}

export function HttpPatch(url: string, token: string, completedCallback: any, completedCallbackError: any, body: any, options: any = {}) {
  options = AddTokenToOptions(options, token);

  axiosInstance.patch(url, body, options)
    .then((response) => {
      if (completedCallback) {
        completedCallback(response.data);
      }
    })
    .catch((error) => {
      completedCallbackError(error);
    });
}

export function HttpGetBlob(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    axios.get(url, { responseType: 'blob' })
      .then((response: AxiosResponse<Blob>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}

export const axiosInstance = axios.create();
// axiosInstance.interceptors.response.use(function (response) {

//     return response;
// }, function (error) {
//     if (403 === error.response.status) {

//         if (!window.location.hostname.includes("localhost")) {
//             window.location.pathname = "/FWMWPNS1/unauthorized";
//         }
//         else {
//             return Promise.reject(error);
//         }
//     }
//     else {
//         return Promise.reject(error);
//     }
// });
