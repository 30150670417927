import { Container, Label } from '~/common/styles/baseStyle';
import styled from 'styled-components';
import { fetchFinancialData } from '~/stores/positionDetail/actions/positionDetailActions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import i18n from '~/app/i18n';
import * as React from 'react';
import NoData from '~/common/components/noData/NoData';
import FinancialDataTable from '~/common/components/position-overview/stock/financial-data/FinancialDataTable';
import { useState } from 'react';
import { isFieldEmptyOrNull } from '~/common/helpers';
import { FinancialFrequencies, RequestStatus } from '~/common/types';
import { Grid } from '@mui/material';
import { StyledSimpleTab, StyledSimpleTabs, Frequency } from './FinancialData.style';
import Loading from '../../../Loading/Loading';
import { StyledContainer } from '../../PositionOverviewPage.style';
interface IFinancialDataProps {
  symbol: string
  positionDetails: any
  fetchFinancialData: typeof fetchFinancialData
}

interface IFinancialDataState {
  frequency: string,
  tabIndex: number
}

const Disclaimer = styled.div`
  margin-top: 7px
`;

export const FinancialData: React.FunctionComponent<IFinancialDataProps> = (props) => {
  const [state, setState] = useState<IFinancialDataState>({ frequency: FinancialFrequencies.ANNUAL, tabIndex: 0 });
  const { frequency } = state;

  React.useEffect(() => {
    // check if frequency is already loaded, and loadData is needed
    if (isFieldEmptyOrNull(props.positionDetails.positions[props.symbol].financialData)
      || isFieldEmptyOrNull(props.positionDetails.positions[props.symbol].financialData[frequency])
      || props.positionDetails.positions[props.symbol].financialData[frequency].requestStatus === RequestStatus.FAILURE) {
      loadData();
    }
  }, [props.symbol, frequency]);

  const loadData = async () => {
    props.fetchFinancialData(props.symbol, frequency);
  };

  const handleFrequencyChange = (key: any) => {
    setState({
      ...state,
      frequency: key.target.value,
    });
  };

  const handleTabChange = (e: any, value: any) => {
    setState({
      ...state,
      tabIndex: value,
    });
  };

  return (
    <StyledContainer>
      <Grid>
        <Label>{i18n.t('financialData.periods') as string}</Label>
        <Frequency defaultValue={frequency} onChange={handleFrequencyChange}>
          <option value={FinancialFrequencies.ANNUAL}>{i18n.t('financialData.annual') as string}</option>
          <option value={FinancialFrequencies.QUARTERLY}> {i18n.t('financialData.quarterly') as string}</option>
        </Frequency>
      </Grid>
      <Grid>
        {
          props.positionDetails.isFetchingFinancialData
            || isFieldEmptyOrNull(props.positionDetails.positions[props.symbol].financialData)
            || isFieldEmptyOrNull(props.positionDetails.positions[props.symbol].financialData[frequency]) ?
            (
              <Container>
                <Loading show position="relative" />
              </Container>
            )
            :
            <Grid>
              <StyledSimpleTabs value={state.tabIndex} onChange={handleTabChange} textColor="secondary">
                <StyledSimpleTab label={i18n.t('financialData.incomeStatements') as string} />
                <StyledSimpleTab label={i18n.t('financialData.cashFlows') as string} />
                <StyledSimpleTab label={i18n.t('financialData.balanceSheets') as string} />
                <StyledSimpleTab label={i18n.t('financialData.ratios') as string} />
              </StyledSimpleTabs>
              {state.tabIndex === 0
                && (props.positionDetails.positions[props.symbol].financialData[frequency].incomeStatements
                  && props.positionDetails.positions[props.symbol].financialData[frequency].incomeStatements.length > 0
                  ? <><Disclaimer>{`${i18n.t('financialData.disclaimer')}.`}</Disclaimer><FinancialDataTable data={props.positionDetails.positions[props.symbol].financialData[frequency].incomeStatements} /> </>
                  : <NoData text={i18n.t('common.noData')} />)}
              {state.tabIndex === 1
                && (props.positionDetails.positions[props.symbol].financialData[frequency].cashFlows
                  && props.positionDetails.positions[props.symbol].financialData[frequency].cashFlows.length > 0
                  ? <><Disclaimer>{`${i18n.t('financialData.disclaimer')}.`}</Disclaimer><FinancialDataTable data={props.positionDetails.positions[props.symbol].financialData[frequency].cashFlows} /> </>
                  : <NoData text={i18n.t('common.noData')} />)}
              {state.tabIndex === 2
                && (props.positionDetails.positions[props.symbol].financialData[frequency].balanceSheets
                  && props.positionDetails.positions[props.symbol].financialData[frequency].balanceSheets.length > 0
                  ? <><Disclaimer>{`${i18n.t('financialData.disclaimer')}.`}</Disclaimer><FinancialDataTable data={props.positionDetails.positions[props.symbol].financialData[frequency].balanceSheets} /> </>
                  : <NoData text={i18n.t('common.noData')} />)}
              {state.tabIndex === 3
                && (props.positionDetails.positions[props.symbol].financialData[frequency].ratios
                  && props.positionDetails.positions[props.symbol].financialData[frequency].ratios.length > 0
                  ? <FinancialDataTable data={props.positionDetails.positions[props.symbol].financialData[frequency].ratios} displayDataInThousand={false} />
                  : <NoData text={i18n.t('common.noData')} />)}
            </Grid>
        }
      </Grid>
    </StyledContainer>
  );
};

function mapStateToProps(state: IFinancialDataProps) {
  const { positionDetails } = state;
  return {
    positionDetails,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchFinancialData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinancialData);
