import { Reducer } from 'redux';
import { generateId } from '~/common/helpers';
import moment from 'moment';
import {
  ITransactionState,
  TransactionActionTypes,
  ITransaction,
  ITransactionAccountFilter,
} from '../transactionTypes';

export const initialState: ITransactionState = {
  filteredTransactionIds: [],
  transactions: {},
  isFetching: false,
  isFail: false,
  filter: {
    symbolDescription: '',
    startDate: moment().add(-30, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    currencyIds: [],
    productTypeIds: [],
    operationTypeIds: [],
  },
  dataExpiration: undefined,
};

export const handleFetchTransactions = (state: ITransactionState) => ({
  ...state,
  isFetching: true,
});

const handleFetchTransactionsAborted = (state: ITransactionState) => ({
  ...state,
  isFetching: false,
});

const handleFetchTransactionsFail = (state: ITransactionState) => ({
  ...state,
  isFetching: false,
  isFail: true,
});

const handleFetchTransactionSuccessful = (state: ITransactionState, transactions: ITransaction[]) => {
  const newTransactions = {} as any;

  transactions.forEach((transaction) => {
    transaction.id = generateId();
    newTransactions[transaction.id] = transaction;
  });

  return {
    ...state,
    transactions: newTransactions,
    isFetching: false,
    isFail: false,
    dataExpiration: moment().add(1, 'd').startOf('day').toDate(),
  };
};

const handleSetSelectedTransactionids = (state: ITransactionState, accountFilter: ITransactionAccountFilter) => {
  const { accountIds } = accountFilter;

  const filteredTransactionIds = [] as string[];

  for (const transactionId in state.transactions) {
    if (state.transactions.hasOwnProperty(transactionId)) {
      const { accountId } = state.transactions[transactionId];
      if (accountIds.length <= 0 || accountIds.indexOf(accountId) >= 0) {
        filteredTransactionIds.push(transactionId);
      }
    }
  }

  return {
    ...state,
    filteredTransactionIds,
  };
};

const handleSetTransactionSymbolDescriptionFilter = (state: ITransactionState, symbolDescriptionFilter: string) => {
  const filter = { ...state.filter };

  return {
    ...state,
    filter: {
      ...filter,
      symbolDescription: symbolDescriptionFilter,
    },
  };
};

const handleSetTransactionCurrencyFilter = (state: ITransactionState, currencyIds: string[]) => {
  const filter = { ...state.filter };

  return {
    ...state,
    filter: {
      ...filter,
      currencyIds,
    },
  };
};

const handleSetTransactionProductTypeFilter = (state: ITransactionState, productTypeIds: string[]) => {
  const filter = { ...state.filter };

  return {
    ...state,
    filter: {
      ...filter,
      productTypeIds,
    },
  };
};

const handleSetTransactionOperationTypeFilter = (state: ITransactionState, operationTypeIds: string[]) => {
  const filter = { ...state.filter };

  return {
    ...state,
    filter: {
      ...filter,
      operationTypeIds,
    },
  };
};

const handleSetTransactionStartDate = (state: ITransactionState, startDate: string) => ({
  ...state,
  filter: {
    ...state.filter,
    startDate,
  },
});

const handleSetTransactionEndDate = (state: ITransactionState, endDate: string) => ({
  ...state,
  filter: {
    ...state.filter,
    endDate,
  },
});

const handleSetExcludeCloseAccount = (state: ITransactionState, excludeCloseAcount?: boolean) => ({
  ...state,
  excludeCloseAcount,
});

export const transactionReducer: Reducer<ITransactionState> = (state = initialState, action) => {
  switch (action.type) {
    case TransactionActionTypes.FETCH_TRANSACTION:
      return handleFetchTransactions(state);
    case TransactionActionTypes.FETCH_TRANSACTION_FAILED:
      return handleFetchTransactionsFail(state);
    case TransactionActionTypes.FETCH_TRANSACTION_ABORTED:
      return handleFetchTransactionsAborted(state);
    case TransactionActionTypes.FETCH_TRANSACTION_SUCCESSFUL:
      return handleFetchTransactionSuccessful(state, action.payload);
    case TransactionActionTypes.SET_SELECTED_TRANSACTION_IDS:
      return handleSetSelectedTransactionids(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_SYMBOL_DESCRIPTION_FILTER:
      return handleSetTransactionSymbolDescriptionFilter(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_CURRENCY_FILTER:
      return handleSetTransactionCurrencyFilter(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_PRODUCT_TYPE_FILTER:
      return handleSetTransactionProductTypeFilter(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_OPERATION_TYPE_FILTER:
      return handleSetTransactionOperationTypeFilter(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_START_DATE:
      return handleSetTransactionStartDate(state, action.payload);
    case TransactionActionTypes.SET_TRANSACTION_END_DATE:
      return handleSetTransactionEndDate(state, action.payload);
    case TransactionActionTypes.SET_EXCLUDE_CLOSE_ACCOUNT:
      return handleSetExcludeCloseAccount(state, action.payload);
    default:
      return state;
  }
};
