import * as React from 'react';
import { Trans } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/ReportRounded';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Colors from '~/common/themes/colors';
import { Stack } from '@mui/material';

export interface InfoProps {
  title: string;
  text?: string;
}

const ErrorInfoSection = styled.div`
  background-color: rgb(253, 237, 237);
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  margin-bottom: 15px;
  border-color: ${Colors.redAlert};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  font-weight: 700;
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  font-weight: 400;
`;

export const ErrorContainer = (props: InfoProps & { children?: React.ReactNode }) => (
  <ErrorInfoSection>
    <Grid container alignItems="center" spacing={0}>
      <Grid item style={{ textAlign: 'center' }}>
        <ErrorIcon color="error" fontSize="large" sx={{ marginRight: 1, marginLeft: 1 }} />
      </Grid>
      <Grid item xs={12} container sm>
        <Stack flexDirection="column">
          <Title>
            <Trans i18nKey={props.title} components={{ b: <strong /> }} />
          </Title>
          {props.text && (
            <InfoText>
              <Trans i18nKey={props.text} />
            </InfoText>
          )}
        </Stack>
      </Grid>
    </Grid>
    {props.children}
  </ErrorInfoSection>
);

export default ErrorContainer;
