import * as React from 'react';
import { Table } from 'antd';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { MonetaryValue } from '~/common/components';
import { Trans } from 'react-i18next';
import AccountDisplaySummary from '~/common/components/account/account-display-summary/accountDisplaySummary';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import { ContainerRow, Container } from '~/common/styles/baseStyle';
import './AccountDisplay.css';
import { getIsMarketDataFetching, getIsMarketDataFetchingHasError } from '~/stores/account/selectors/accountSelectors';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { withRouter } from 'react-router-dom';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { accountHasPositionWithAccruedInterest, getAccountPositionChangeValues } from '~/common/accountsHelpers';
import { IntradayPositionTypes } from '~/common/types';
import { RouteComponentProps } from 'react-router';
import SummarySplitGrid from '../account-view/SummarySplitGrid';
import { ComponentWithValidation } from '../../position-overview/summary-table/ComponentWithValidation';
import NumberQI from '../../changeValue/NumberQI';
import { buildDataArrayForAccountValueSection } from '../account-view/SummaryAccountViewHelper';
import { Grid, Stack } from '@mui/material';
import {
  AccountPositionsContainers,
  IAccountDisplayProps,
  IAccountPropsFromState,
  IAccountsPropsFromDispatch,
  align,
  IAccountSortingDisplayState,
} from './AccountDisplayTypes';
import {
  renderSymbol,
  saveHoldingSortPreferencesIfNeeded,
  sorterByDescription,
  sorterByIndividualPrice,
  sorterByQuantity,
  sorterByTotalMarketPrice,
} from './AccountViewDisplayHelper';
import { SortColumnKeys } from '~/stores/party/partyTypes';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { bindActionCreators, Dispatch } from 'redux';
import { saveHoldingSortPreferences } from '~/stores/party/actions/partyActions';
import { SortOrder } from 'antd/lib/table/interface';

export class AccountDisplay extends React.PureComponent<
  RouteComponentProps & IAccountDisplayProps & IAccountPropsFromState & IAccountsPropsFromDispatch,
  IAccountSortingDisplayState
> {
  constructor(props: RouteComponentProps & IAccountDisplayProps & IAccountPropsFromState & IAccountsPropsFromDispatch) {
    super(props);
    this.state = {
      sortPreference: this.props.sortPreference,
      dataSource: this.props.account.positions,
    };
  }

  private renderMarketValue = (value: any, row: any, index: number) => {
    const { culture } = this.props;
    const obj = {
      children: (
        <Container>
          <ContainerRow style={{ justifyContent: 'flex-end' }}>
            <MonetaryValue
              value={value}
              culture={culture}
              currency={row.netAmountCurrency}
              zeroPlaceholder={i18n.t('holdings.NA')}
            />
            {row.accruedInterest && <span>*</span>}
          </ContainerRow>

          {row.accruedInterest && (
            <ContainerRow style={{ justifyContent: 'flex-end' }} className="fontSmall">
              <div>
                <Trans i18nKey="holdings.accruedInterest" />
                &nbsp;
              </div>
              <MonetaryValue
                value={row.accruedInterest.amount}
                culture={culture}
                currency={row.netAmountCurrency}
                zeroPlaceholder={i18n.t('holdings.NA')}
              />
            </ContainerRow>
          )}
        </Container>
      ),
    };
    return obj;
  };

  private handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      saveHoldingSortPreferencesIfNeeded(
        { accountView: { sortColumn: sorter.columnKey, sortDirection: sorter.order } },
        this.props.isUserAdvisorOrAdmin,
        this.props.saveHoldingSortPreferences,
      );

      // change sorting of all accounts display tables
      this.props.onChangedTableSort(sorter.columnKey, sorter.order);
    }
  };

  private renderTable(accountCurrency: string) {
    const { culture, sortPreference } = this.props;
    const columns = [
      {
        title: <Trans i18nKey="common.symbolDescription" />,
        key: SortColumnKeys.DESCRIPTION,
        dataIndex: 'description',
        render: (value: any, record: any) =>
          renderSymbol(record, this.props.isSymbolLinkEnabled, this.props.location.pathname),
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByDescription(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.DESCRIPTION
            ? (sortPreference?.sortDirection.toString() as SortOrder)
            : undefined,
        className: 'wrap',
        width: '40%',
      },
      {
        title: i18n.t('holdings.quantity'),
        key: SortColumnKeys.QUANTITY,
        dataIndex: 'quantity',
        render: (value: any, record: any) => (
          <DecimalValue
            value={value}
            culture={culture}
            amountOfDigits={2}
            zeroPlaceholder="0"
            areTrailingZerosVisible={record.isMutualFund}
          />
        ),
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByQuantity(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.QUANTITY
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        align: 'right' as align,
        width: '20%',
      },
      {
        title: <Trans i18nKey="holdings.marketPrice" />,
        key: SortColumnKeys.MK_PRICE,
        dataIndex: 'individualMarketPrice',
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByIndividualPrice(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.MK_PRICE
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        render: (value: any, record: any) => {
          const { dollarChange, changePercent } = getAccountPositionChangeValues({
            account: this.props.account,
            dollarChange: record.changeValue,
            changePercent: record.changePercent,
            isFetchingMarketDataHasError: this.props.isFetchingMarketDataHasError,
          });
          return (
            <Stack alignContent="flex-end" display="block">
              <MonetaryValue
                value={value}
                culture={culture}
                amountOfDigits={3}
                currency={
                  record.individualMarketPrice !== record.individualMarketPriceInAccountCurrency
                    ? record.marketPriceCurrency
                    : undefined
                }
                zeroPlaceholder={`${i18n.t('holdings.NA')}¹`}
              />
              {this.props.didSetMarketDataRealtime &&
              Object.values(IntradayPositionTypes).includes(record.alternateType as IntradayPositionTypes) ? (
                <ChangeValue
                  value={record.notFoundAtProvider ? undefined : dollarChange}
                  percent={record.notFoundAtProvider ? undefined : changePercent}
                  decimalOverride={3}
                  decimalOverrideForPercent={2}
                  singleLineFormat
                  arrowFontSize="13"
                  alignRight
                  signType="parentheses"
                  noArrow
                  mainContainerStyleRules={{ style: { display: 'inline-flex' } }}
                  noShowValuesSign={false}
                />
              ) : (
                <div />
              )}
            </Stack>
          );
        },
        align: 'right' as align,
        width: '20%',
      },
      {
        title: <Trans i18nKey="holdings.marketValue" values={{ 0: accountCurrency }} />,
        key: SortColumnKeys.MK_VALUE,
        dataIndex: accountCurrency === 'CAD' ? 'totalMarketPriceCad' : 'totalMarketPriceUsd',
        render: this.renderMarketValue,
        align: 'right' as align,
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByTotalMarketPrice(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.MK_VALUE
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        width: '20%',
      },
    ];

    const sortText = i18n.t('common.sort');

    return (
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="security"
        columns={columns}
        dataSource={this.state.dataSource}
        pagination={false}
        locale={{
          emptyText: <NoData text={i18n.t('common.noPosition')} />,
          triggerDesc: i18n.t('common.sortDescending'),
          triggerAsc: i18n.t('common.sortAscending'),
        }}
        style={{ width: '100%' }}
        loading={this.props.isFetchingMarketData}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
        onChange={this.handleTableChange}
      />
    );
  }

  public render() {
    const { account, sortPreference } = this.props;
    this.setState({ sortPreference: this.props.sortPreference });
    const annotation = accountHasPositionWithAccruedInterest([account]) ? '*' : undefined;

    let initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByDescription(a, b));
    if (sortPreference?.sortColumn === SortColumnKeys.QUANTITY) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByQuantity(a, b));
    }
    if (sortPreference?.sortColumn === SortColumnKeys.MK_PRICE) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByIndividualPrice(a, b));
    }
    if (sortPreference?.sortColumn === SortColumnKeys.MK_VALUE) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByTotalMarketPrice(a, b));
    }

    this.setState({ dataSource: initialOrderedPositionByPreferences });

    return (
      <Grid container style={{ padding: '16px' }}>
        <Grid container item xs={12}>
          <AccountDisplaySummary account={account} />
        </Grid>
        <SummarySplitGrid
          labelKeyLeft="common.totalMarketValue"
          valueLeft={
            <>
              <ComponentWithValidation
                value={account.totalMarketValueCad}
                componentWithValue={<NumberQI value={account.totalMarketValueCad} isMoney />}
              />
              {annotation}
            </>
          }
          rowsOnRight={1}
          colsOnRight={3}
          dataOnRightSide={buildDataArrayForAccountValueSection(account, this.props.shouldNotShowMarketDataInfo)}
        />
        <AccountPositionsContainers>{this.renderTable(account.currency)}</AccountPositionsContainers>
      </Grid>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    culture: system.culture,
    isFetchingMarketData: getIsMarketDataFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveHoldingSortPreferences,
    },
    dispatch,
  );

export default connect<IAccountPropsFromState, IAccountsPropsFromDispatch, IAccountDisplayProps, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AccountDisplay));
