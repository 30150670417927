import * as React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { IAButtonSelect, ContainerRow, Container } from '~/common/styles/baseStyle';
import { Popper, ClickAwayListener, InputBase, createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import moment from 'moment';
import { Select } from 'antd';

const { Option } = Select;

const ContainerFilter = styled.div<{ isTaxSeasonSelected: boolean }>`
  font-family: 'Open Sans', 'Open Sans';
  box-shadow: 0px 5px 5px 2px rgba(102, 102, 102, 0.349019607843137);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 15px;
  background-color: #fff;
  height: ${(props) => (props.isTaxSeasonSelected ? '90px' : '180px')};
  width: 320px;
  overflow: auto;
`;

export const DateIA = withStyles(
  InputBase,
  () =>
    createStyles({
      root: {
        'label + &': {
          marginTop: '3px',
        },

        '& input:invalid ': {
          borderColor: 'red',
        },
      },

      input: {
        position: 'relative',
        border: '1px solid #d9d9d9',
        borderRadius: '5px !important',
        fontSize: 14,
        width: '130px',
        padding: '8px 5px',
        fontFamily: ['Open Sans'].join(','),
        '&:focus': {
          borderColor: '#003da5',
        },
      },
    }),
  // eslint-disable-next-line function-paren-newline
);

export interface IFilterHistoryProps {
  startDate: string;
  endDate: string;
  onPeriodSelected: (start: string, end: string, taxFilterSelected: boolean) => void;
  taxFilterAvailable: boolean;
  selectTaxFilter: boolean;
}

export interface IFilterHistoryState {
  isOpenPeriod: boolean;
  anchorPeriodfilter: any;
  startDatePicker: string;
  endDatePicker: string;
  taxFilterSelected: boolean;
}

export enum DateFilterOptionEnum {
  PAST_30_DAYS = '30',
  PAST_90_DAYS = '90',
  PAST_180_DAYS = '180',
  CURRENT_YEAR = 'CurrentYear',
  PREVIOUS_YEAR = 'PreviousYear',
  SpecificDate = 'SpecificDate',
  TAX_FILTER = 'TaxFilter',
}
export const outputDateFormat = 'YYYY-MM-DD';

class PeriodFilter extends React.PureComponent<IFilterHistoryProps, IFilterHistoryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpenPeriod: false,
      anchorPeriodfilter: null,
      startDatePicker: this.props.startDate,
      endDatePicker: this.props.endDate,
      taxFilterSelected: false,
    };
  }

  private getCurrentFiscalYear = () => moment().subtract(1, 'year').startOf('year').format('YYYY');

  private establishSelectedOption = (startDate: string, endDate: string) => {
    const startDateObject = moment(startDate);
    const endDateObject = moment(endDate);
    const deltaInDays = endDateObject.diff(startDateObject, 'days');

    if (this.state.taxFilterSelected || this.props.selectTaxFilter) {
      return DateFilterOptionEnum.TAX_FILTER;
    }
    if (deltaInDays === 30) {
      return DateFilterOptionEnum.PAST_30_DAYS;
    }
    if (deltaInDays === 90) {
      return DateFilterOptionEnum.PAST_90_DAYS;
    }
    if (deltaInDays === 180) {
      return DateFilterOptionEnum.PAST_180_DAYS;
    }
    if (
      startDateObject.year() === moment(moment().format(outputDateFormat)).year() &&
      startDateObject.dayOfYear() === 1
    ) {
      return DateFilterOptionEnum.CURRENT_YEAR;
    }
    if (
      startDateObject.year() === moment(moment().format(outputDateFormat)).add(-1, 'y').year() &&
      startDateObject.dayOfYear() === 1 &&
      endDateObject.year() === moment(moment().format(outputDateFormat)).add(-1, 'y').year() &&
      endDateObject.dayOfYear() >= 365
    ) {
      return DateFilterOptionEnum.PREVIOUS_YEAR;
    }

    return DateFilterOptionEnum.SpecificDate;
  };

  private establishEndDate = (id: string) => {
    switch (id) {
      case DateFilterOptionEnum.PAST_30_DAYS:
      case DateFilterOptionEnum.PAST_90_DAYS:
      case DateFilterOptionEnum.PAST_180_DAYS:
      case DateFilterOptionEnum.SpecificDate:
        return moment().format(outputDateFormat);
      case DateFilterOptionEnum.CURRENT_YEAR:
        return moment().format('YYYY-12-31');
      case DateFilterOptionEnum.PREVIOUS_YEAR:
        return moment().add(-1, 'y').format('YYYY-12-31');
    }

    return '';
  };

  private establishStartDate = (id: string) => {
    switch (id) {
      case DateFilterOptionEnum.PAST_30_DAYS:
        return moment().add(-30, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.PAST_90_DAYS:
        return moment().add(-90, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.PAST_180_DAYS:
        return moment().add(-180, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.CURRENT_YEAR:
        return moment().format('YYYY-01-01');
      case DateFilterOptionEnum.PREVIOUS_YEAR:
        return moment().add(-1, 'y').format('YYYY-01-01');
      case DateFilterOptionEnum.SpecificDate:
        return moment().add(-1, 'd').format(outputDateFormat);
    }

    return '';
  };

  private handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ isOpenPeriod: !this.state.isOpenPeriod, anchorPeriodfilter: event.currentTarget });
    if (this.state.isOpenPeriod) {
      this.props.onPeriodSelected(this.state.startDatePicker, this.state.endDatePicker, this.state.taxFilterSelected);
    }
  };

  private handleClickAway = () => {
    this.setState({ isOpenPeriod: false });
    this.props.onPeriodSelected(this.state.startDatePicker, this.state.endDatePicker, this.state.taxFilterSelected);
  };

  public handleOnOptionSelected = (value: string) => {
    if (value === DateFilterOptionEnum.TAX_FILTER) {
      this.setState({ taxFilterSelected: true });

      this.props.onPeriodSelected('', '', true);
    } else {
      const startDate = this.establishStartDate(value);
      const endDate = this.establishEndDate(value);
      this.setState({ startDatePicker: startDate, endDatePicker: endDate, taxFilterSelected: false });
      this.props.onPeriodSelected(startDate, endDate, false);
    }
  };

  private handleOnStartDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startDatePicker: event.target.value });
  };

  private handleOnEndDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endDatePicker: event.target.value });
  };

  private renderDisplayName = (selection: string) => {
    switch (selection) {
      case DateFilterOptionEnum.PAST_30_DAYS:
        return <Trans i18nKey="common.period_dropdown_choice1" />;
      case DateFilterOptionEnum.PAST_90_DAYS:
        return <Trans i18nKey="common.period_dropdown_choice2" />;
      case DateFilterOptionEnum.PAST_180_DAYS:
        return <Trans i18nKey="common.period_dropdown_choice3" />;
      case DateFilterOptionEnum.CURRENT_YEAR:
        return <Trans i18nKey="common.period_dropdown_choice4" />;
      case DateFilterOptionEnum.PREVIOUS_YEAR:
        return <Trans i18nKey="common.period_dropdown_choice5" />;
      case DateFilterOptionEnum.SpecificDate:
        return <Trans i18nKey="common.period_dropdown_choice6" />;
      case DateFilterOptionEnum.TAX_FILTER:
        return <Trans i18nKey="common.period_dropdown_taxFilter" values={{ 0: this.getCurrentFiscalYear() }} />;
      default:
        return <Trans i18nKey="common.period_dropdown" />;
    }
  };

  componentDidUpdate(prevProps: IFilterHistoryProps, prevState: IFilterHistoryState) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.setState({ startDatePicker: this.props.startDate, endDatePicker: this.props.endDate });
    }
    if (prevProps.selectTaxFilter !== this.props.selectTaxFilter) {
      this.setState({ taxFilterSelected: this.props.selectTaxFilter });
    }
  }

  public render() {
    const idPopper = this.state.isOpenPeriod ? 'popperPeriodFilter' : undefined;
    const selectedPeriod = this.establishSelectedOption(this.props.startDate, this.props.endDate);
    return (
      <div>
        <IAButtonSelect
          onClick={this.handleFilterClick}
          variant="outlined"
          endIcon={<ExpandMoreIcon htmlColor="#003da5" />}
        >
          {this.renderDisplayName(selectedPeriod)}
        </IAButtonSelect>
        {this.state.isOpenPeriod && (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Popper
              id={idPopper}
              open={this.state.isOpenPeriod}
              anchorEl={this.state.anchorPeriodfilter}
              placement="bottom-end"
              disablePortal={false}
            >
              <ContainerFilter isTaxSeasonSelected={this.state.taxFilterSelected}>
                <Container>
                  <Trans i18nKey="common.period_dropdown" />
                  <Select
                    id="period"
                    onChange={this.handleOnOptionSelected}
                    value={selectedPeriod}
                    style={{ width: 200 }}
                  >
                    <Option value={DateFilterOptionEnum.PAST_30_DAYS}>
                      <Trans i18nKey="common.period_dropdown_choice1" />
                    </Option>
                    <Option value={DateFilterOptionEnum.PAST_90_DAYS}>
                      <Trans i18nKey="common.period_dropdown_choice2" />
                    </Option>
                    <Option value={DateFilterOptionEnum.PAST_180_DAYS}>
                      <Trans i18nKey="common.period_dropdown_choice3" />
                    </Option>
                    <Option value={DateFilterOptionEnum.CURRENT_YEAR}>
                      <Trans i18nKey="common.period_dropdown_choice4" />
                    </Option>
                    <Option value={DateFilterOptionEnum.PREVIOUS_YEAR}>
                      <Trans i18nKey="common.period_dropdown_choice5" />
                    </Option>
                    <Option value={DateFilterOptionEnum.SpecificDate}>
                      <Trans i18nKey="common.period_dropdown_choice6" />
                    </Option>
                    {this.props.taxFilterAvailable && (
                      <Option value={DateFilterOptionEnum.TAX_FILTER}>
                        <Trans i18nKey="common.period_dropdown_taxFilter" values={{ 0: this.getCurrentFiscalYear() }} />
                      </Option>
                    )}
                  </Select>
                </Container>
                {!this.state.taxFilterSelected && (
                  <Container>
                    <ContainerRow style={{ paddingTop: 10 }}>
                      <Container style={{ color: this.state.taxFilterSelected ? 'lightgray' : 'inherit' }}>
                        <Trans i18nKey="common.period_dropdown_from" />
                        <DateIA
                          type="date"
                          defaultValue={this.props.startDate}
                          onChange={this.handleOnStartDatePickerChange}
                          disabled={this.state.taxFilterSelected}
                        />
                      </Container>

                      <Container style={{ color: this.state.taxFilterSelected ? 'lightgray' : 'inherit' }}>
                        <Trans i18nKey="common.period_dropdown_to" />
                        <DateIA
                          type="date"
                          defaultValue={this.props.endDate}
                          onChange={this.handleOnEndDatePickerChange}
                          disabled={this.state.taxFilterSelected}
                        />
                      </Container>
                    </ContainerRow>
                  </Container>
                )}
              </ContainerFilter>
            </Popper>
          </ClickAwayListener>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<{}, {}, IFilterHistoryProps, IAppRootState>(mapStateToProps, mapDispatchToProps)(PeriodFilter);
