import { IAdvisor } from '~/common/interfaces/advisor/Advisor';

export interface IAdvisorState {
    readonly advisors: IAdvisor[],
    readonly isFetching: boolean,
    readonly isFail: boolean,
    readonly dataExpiration?: Date
}

export const enum AdvisorActionTypes {
    FETCH_ADVISOR = '[Advisor] Fetch',
    FETCH_ADVISOR_SUCCESSFUL = '[Advisor] Fetch Successful',
    FETCH_ADVISOR_FAILED = '[Advisor] Fetch Failed',
    FETCH_ADVISOR_ABORTED = '[Advisor] Fetch Aborted',
}
