import * as React from 'react';
import { AccountDisplay } from '~/common/components/account';
import styled from 'styled-components';
import { AccountViewTabEnum, IRootAccounts } from '~/common/types';
import { isSymbolLinkEnabled } from '~/common/featureToggleHelper';
import InvestedCapitalAccountDisplay from '~/common/components/account/account-display/InvestedCapitalAccountDisplay';
import BookCostAccountDisplay from '~/common/components/account/account-display/BookCostAccountDisplay';
import Colors from '~/common/themes/colors';

const RootAccountsContainer = styled.div`
  & > div {
    margin-bottom: 2px;
  }
`;
const RootIndividualAccountContainer = styled.div`
  & > div {
    border-bottom: ${['1px solid', Colors.quillGray].join(' ')}
  }
`;

interface IRootAccountsState {
}

class RootAccounts extends React.PureComponent<IRootAccounts, IRootAccountsState> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const { accounts, viewType } = this.props;

    if (!accounts || accounts.length <= 0) {
      return null;
    }

    return (
      <RootAccountsContainer>
        {accounts.map((account, index) => {
          if (viewType === AccountViewTabEnum.AccountValue || viewType === undefined) {
            return (
              <RootIndividualAccountContainer>
                <AccountDisplay
                  account={account}
                  key={index}
                  isSymbolLinkEnabled={isSymbolLinkEnabled()}
                  shouldNotShowMarketDataInfo={this.props.shouldNotShowMarketDataInfo}
                  onChangedTableSort={this.props.onChangedTableSort}
                  sortPreference={this.props.sortingPreferencesByTab}
                />
              </RootIndividualAccountContainer>
            );
          }
          if (viewType === AccountViewTabEnum.InvestedCapital) {
            return (
              <RootIndividualAccountContainer>
                <InvestedCapitalAccountDisplay
                  account={account}
                  key={index}
                  isSymbolLinkEnabled={isSymbolLinkEnabled()}
                  shouldNotShowMarketDataInfo={this.props.shouldNotShowMarketDataInfo}
                  onChangedTableSort={this.props.onChangedTableSort}
                  sortPreference={this.props.sortingPreferencesByTab}
                />
              </RootIndividualAccountContainer>
            );
          } if (viewType === AccountViewTabEnum.BookCost) {
            return (
              <RootIndividualAccountContainer>
                <BookCostAccountDisplay
                  account={account}
                  key={index}
                  isSymbolLinkEnabled={isSymbolLinkEnabled()}
                  shouldNotShowMarketDataInfo={this.props.shouldNotShowMarketDataInfo}
                  onChangedTableSort={this.props.onChangedTableSort}
                  sortPreference={this.props.sortingPreferencesByTab}
                />
              </RootIndividualAccountContainer>
            );
          }
          return (
            <RootIndividualAccountContainer>
              <AccountDisplay
                account={account}
                key={index}
                isSymbolLinkEnabled={isSymbolLinkEnabled()}
                shouldNotShowMarketDataInfo={this.props.shouldNotShowMarketDataInfo}
                onChangedTableSort={this.props.onChangedTableSort}
                sortPreference={this.props.sortingPreferencesByTab}
              />
            </RootIndividualAccountContainer>
          );
        })}
      </RootAccountsContainer>
    );
  }
}

export default RootAccounts;
