import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import moment from 'moment';

export class IaLocales {
  public static createMomentLocales(): void {
    moment.updateLocale('en-ia', {
      parentLocale: 'en-ca',
      monthsShort: 'Jan._Feb._Mar._Apr._May_Jun._Jul._Aug._Sep._Oct._Nov._Dec.'.split('_'),
    });

    moment.updateLocale('fr-ia', {
      parentLocale: 'fr-ca',
    });
  }
}
