import * as React from 'react';
import { FunctionComponent } from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';

import i18n from '~/app/i18n';
import { Trans } from 'react-i18next';
import { Header, RowCashGrid } from '~/common/styles/common.style';
import { appTheme } from '~/common/themes/theme';
import { MonetaryValue } from '../../..';
import { DateFormatYYYY } from '../../../date-format/DateFormat';

export interface IFinancialDataTableProps {
  data: any[],
  displayDataInThousand?: boolean
}

const FinancialDataTable: FunctionComponent<IFinancialDataTableProps> = ({ data, displayDataInThousand = true }) => {
  const dateHeader = data.map((element) => <Col xs key={element.date}><Header style={{ textAlign: 'center' }}><DateFormatYYYY value={element.date} /> </Header> </Col>);

  const rows: { key: string, value: any[] }[] = Object.keys(data[0]).filter((e) => !['year', 'date', 'fiscalYear', 'name', 'quarter'].includes(e))
    .map((e) => ({ key: e, value: [] }));
  rows.forEach((e) => {
    e.value = data.map((i) => i[e.key]);
  });

  const rowsCol = rows.map((element) => {
    const restOfCells = element.value.map((i, index) => <Col xs key={Math.random() * index}><MonetaryValue value={displayDataInThousand ? i / 1000 : i} culture={i18n.language} amountOfDigits={2} nanPlaceholder="-" /></Col>);
    return (<RowCashGrid style={{ alignItems: 'center' }} key={element.key}>
      <Col xs={3} style={{ backgroundColor: '#eeebe3', padding: '5px 3px 5px 8px' }}>
        <Trans i18nKey={`financialData.financialDataTable.${element.key}`} />
      </Col>
      {restOfCells}
    </RowCashGrid>);
  });

  return (
    <Grid fluid style={{ width: '100%', marginTop: 23, paddingRight: 7, paddingLeft: 7 }}>
      <Row style={{ backgroundColor: appTheme.palette.primary.main }}>
        <Col xs={3}>
          <Header style={{ padding: 8, color: appTheme.palette.primary.contrastText }} />
        </Col>
        {dateHeader}
      </Row>
      {rowsCol}
    </Grid>
  );
};

export default FinancialDataTable;
