import { action } from 'typesafe-actions';
import {
  AccountActionTypes,
  IRegisteredAccount,
  IDeliveryPreferences,
  IAssetsAllocation,
  IDocument,
  IClientAccount,
  IMergeDownloadPdfs,
  IDocumentDownload,
  IRateOfReturn,
  IDocumentMergeDownloadComplete,
  IDeliveryPreferencesToSave,
  IDeliveryPreferencesSummary,
  AccountHoldingsView,
} from '~/stores/account/accountTypes';
import { IMessage } from '~/stores/message/interfaces/Message';

export const fetchAccountsInfo = () => action(AccountActionTypes.FETCH_ACCOUNTS_INFO);

export const fetchAccounts = (accountIds?: string[], isAccountSwitch?: boolean, partyId?: string) =>
  action(AccountActionTypes.FETCH_ACCOUNTS, { accountIds, isAccountSwitch, partyId });
export const fetchAccountsSuccessful = (clientAccounts: IClientAccount) =>
  action(AccountActionTypes.FETCH_ACCOUNTS_SUCCESSFUL, clientAccounts);
export const fetchAccountsFailed = () => action(AccountActionTypes.FETCH_ACCOUNTS_FAILED);
export const fetchAccountsAborted = () => action(AccountActionTypes.FETCH_ACCOUNTS_ABORTED);

export const setFilteringAccountIds = (selectedAccountIds: string[]) =>
  action(AccountActionTypes.SET_FILTERING_ACCOUNT_IDS, selectedAccountIds);
export const setFilteringPartyAccountIds = (selectedAccountIds: string[]) =>
  action(AccountActionTypes.SET_FILTERING_PARTY_ACCOUNT_IDS, selectedAccountIds);
export const setFilteringRegisterAccountIds = (selectedAccountIds: string[]) =>
  action(AccountActionTypes.SET_FILTERING_REGISTER_ACCOUNT_IDS, selectedAccountIds);
export const cleanFilteringAccountIds = (accountIds: string[]) =>
  action(AccountActionTypes.CLEAN_FILTERING_ACCOUNT_IDS, accountIds);

export const fetchAssetsAllocation = (accountIds: string[], isAccountSwitch: boolean) =>
  action(AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION, { accountIds, isAccountSwitch });
export const fetchAssetsAllocationSuccessful = (assetsAllocation: IAssetsAllocation[]) =>
  action(AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION_SUCCESSFUL, assetsAllocation);
export const fetchAssetsAllocationFailed = () => action(AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION_FAILED);
export const fetchAssetsAllocationAborted = () => action(AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION_ABORTED);

export const fetchRegisteredAccounts = (accountIds: string[], year: number) =>
  action(AccountActionTypes.FETCH_REGISTERED_ACCOUNTS, { accountIds, year });
export const fetchRegisteredAccountsSuccessful = (registeredAccounts: IRegisteredAccount[], year: number) =>
  action(AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_SUCCESSFUL, { registeredAccounts, year });
export const fetchRegisteredAccountsFailed = () => action(AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_FAILED);
export const fetchRegisteredAccountsAborted = () => action(AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_ABORTED);

export const fetchNetInvested = (accountIds: string[]) => action(AccountActionTypes.FETCH_NETINVESTED, { accountIds });
export const fetchNetInvestedSuccessful = (perfs: any) =>
  action(AccountActionTypes.FETCH_NETINVESTED_SUCCESSFUL, perfs);
export const fetchNetInvestedFailed = () => action(AccountActionTypes.FETCH_NETINVESTED_FAILED);
export const fetchNetInvestedAborted = () => action(AccountActionTypes.FETCH_NETINVESTED_ABORTED);
export const resetNetInvestedExpirationDate = () => action(AccountActionTypes.RESET_NETINVESTED_EXPIRATION_DATE);

export const fetchRateOfReturn = (accountIds: string[]) =>
  action(AccountActionTypes.FETCH_RATEOFRETURN, { accountIds });
export const fetchRateOfReturnSuccessful = (perfs: IRateOfReturn) =>
  action(AccountActionTypes.FETCH_RATEOFRETURN_SUCCESSFUL, perfs);
export const fetchRateOfReturnFailed = () => action(AccountActionTypes.FETCH_RATEOFRETURN_FAILED);
export const fetchRateOfReturnAborted = () => action(AccountActionTypes.FETCH_RATEOFRETURN_ABORTED);

export const fetchCashFlowSummary = (accountIds: string[]) =>
  action(AccountActionTypes.FETCH_CASHFLOWSUMMARY, { accountIds });
export const fetchCashFlowSummarySuccessful = (cashFlow: any) =>
  action(AccountActionTypes.FETCH_CASHFLOWSUMMARY_SUCCESSFUL, cashFlow);
export const fetchCashFlowSummaryFailed = () => action(AccountActionTypes.FETCH_CASHFLOWSUMMARY_FAILED);
export const fetchCashFlowSummaryAborted = () => action(AccountActionTypes.FETCH_CASHFLOWSUMMARY_ABORTED);

export const fetchDeliveryPreferences = (clientIds: string[], partyId: string) =>
  action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES, { clientIds, partyId });
export const fetchDeliveryPreferencesSuccessful = (deliveryPreferences: IDeliveryPreferences[]) =>
  action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUCCESSFUL, deliveryPreferences);
export const fetchDeliveryPreferencesFailed = () => action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_FAILED);

export const saveDeliveryPreferences = (deliveryPreferences: IDeliveryPreferencesToSave[]) =>
  action(AccountActionTypes.SAVE_DELIVERY_PREFERENCES, { deliveryPreferences });
export const saveDeliveryPreferencesSuccessful = () => action(AccountActionTypes.SAVE_DELIVERY_PREFERENCES_SUCCESSFUL);
export const saveDeliveryPreferencesFailed = () => action(AccountActionTypes.SAVE_DELIVERY_PREFERENCES_FAILED);

export const fetchDeliveryPreferencesSummary = () => action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY);
export const fetchDeliveryPreferencesSummarySuccessful = (deliveryPreferencesSummary: IDeliveryPreferencesSummary) =>
  action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY_SUCCESSFUL, deliveryPreferencesSummary);
export const fetchDeliveryPreferencesSummaryFailed = () =>
  action(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY_FAILED);

export const saveNickNameAccount = (id: string, accountId: string, nickname: string) =>
  action(AccountActionTypes.SAVE_NICKNAME, { id, accountId, nickname });
export const saveNickNameAccountSuccessful = () => action(AccountActionTypes.SAVE_NICKNAME_SUCCESSFUL);
export const saveNickNameAccountFailed = () => action(AccountActionTypes.SAVE_NICKNAME_FAILED);

export const fetchAccountDocuments = () => action(AccountActionTypes.FETCH_DOCUMENTS);
export const downloadDocument = (documentId: string) => action(AccountActionTypes.DOWNLOAD_DOCUMENT, { documentId });
export const fetchAccountDocumentsSuccessful = (docs: IDocument[]) =>
  action(AccountActionTypes.FETCH_DOCUMENTS_SUCCESSFUL, docs);
export const fetchAccountDocumentsFailed = () => action(AccountActionTypes.FETCH_DOCUMENTS_FAILED);
export const fetchAccountDocumentsAborted = () => action(AccountActionTypes.FETCH_DOCUMENTS_ABORTED);
export const addNewDocumentsSuccessful = (message: IMessage) =>
  action(AccountActionTypes.ADD_NEW_DOCUMENTS_SUCCESSFUL, message);
export const setDocumentReadStatusFlag = (documentId: string) =>
  action(AccountActionTypes.SET_DOCUMENT_READ_STATUS, documentId);
export const setDocumentDateFiltering = (from: string, to: string) =>
  action(AccountActionTypes.SET_DOCUMENT_DATE_FILTERING, { from, to });

export const fetchTradeConfirmationDocuments = () => action(AccountActionTypes.FETCH_TRADECONFIRMATION_DOCUMENTS);
export const fetchTradeConfirmationDocumentsSuccessful = (docs: IDocument[]) =>
  action(AccountActionTypes.FETCH_TRADECONFIRMATION_SUCCESSFUL, docs);
export const fetchTradeConfirmationDocumentsFailed = () => action(AccountActionTypes.FETCH_TRADECONFIRMATION_FAILED);
export const fetchTradeConfirmationDocumentsAborted = () => action(AccountActionTypes.FETCH_TRADECONFIRMATION_ABORTED);

export const mergeDownloadPdfs = (mergeDownloadPdfsRequest: IMergeDownloadPdfs) =>
  action(AccountActionTypes.MERGE_DOWNLOAD_PDFS, { mergeDownloadPdfsRequest });
export const mergeDownloadPdfsSuccessful = (results: IDocumentMergeDownloadComplete) =>
  action(AccountActionTypes.MERGE_DOWNLOAD_PDFS_SUCCESSFUL, results);
export const mergeDownloadPdfsFailed = () => action(AccountActionTypes.MERGE_DOWNLOAD_PDFS_FAILED);

export const fetchMarketData = () => action(AccountActionTypes.FETCH_MARKETDATA);
export const fetchMarketDataSuccessful = (stocks: any[]) =>
  action(AccountActionTypes.FETCH_MARKETDATA_SUCCESSFUL, stocks);
export const fetchMarketDataNoLiveSuccessful = (stocks: any[]) =>
  action(AccountActionTypes.FETCH_MARKETDATA_NOLIVE_SUCCESSFUL, stocks);
export const fetchMarketDataFailed = () => action(AccountActionTypes.FETCH_MARKETDATA_FAILED);

export const fetchClosedMarkets = () => action(AccountActionTypes.FETCH_CLOSEDMARKETS);
export const fetchClosedMarketsSuccessful = (markets: any[]) =>
  action(AccountActionTypes.FETCH_CLOSEDMARKETS_SUCCESSFUL, markets);
export const fetchClosedMarketsFailed = () => action(AccountActionTypes.FETCH_CLOSEDMARKETS_FAILED);

export const fetchCSVByDomain = (domain: string, csvFilename: string) =>
  action(AccountActionTypes.FETCH_CSV_BY_DOMAIN, { domain, csvFilename });
export const fetchCSVByDomainSuccessful = (results: IDocumentDownload) =>
  action(AccountActionTypes.FETCH_CSV_BY_DOMAIN_SUCCESSFUL, results);
export const fetchCSVByDomainFailed = () => action(AccountActionTypes.FETCH_CSV_BY_DOMAIN_FAILED);

export const selectHoldingsAccountView = (accountView: AccountHoldingsView) =>
  action(AccountActionTypes.SET_HOLDINGS_ACCOUNT_VIEW, { accountView });

export const clearDocuments = () => action(AccountActionTypes.CLEAR_DOCUMENTS);
export const fetchTaxDocuments = () => action(AccountActionTypes.FETCH_TAX_DOCUMENTS);
