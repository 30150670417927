import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import { Trans } from 'react-i18next';
import { Button, Dialog, Stack } from '@mui/material';
import {
  getConsentPrompt,
  getConsentVerbiages,
  getIsConsentVerbiageFailed,
  getIsConsentVerbiageFetching,
  getIsConsentVerbiageLoaded,
  getIsSavingConsent,
  getIsSavingConsentFailed,
  getIsSavingConsentSuccess,
  getPartyV1,
  getShowConsentPrompt,
} from '~/stores/party/selectors/partySelectors';
import { loadConsentVerbiage, resetSaveConsent, saveConsentPrompt } from '~/stores/party/actions/partyActions';
import { IConsentPromptMetadata, IConsentVerbiages, IPartyV1 } from '~/stores/party/partyTypes';
import background from './background.png'; // relative path to image
import {
  CloseIconDiv,
  LinkButton,
  BackgroundDivImage,
  ButtonsContainer,
  Content,
  InlineDiv,
  CloseIconStyle,
  MarginDiv,
  StyledDiv,
  Text,
  Title,
  PopupTitle,
} from './ConsentPrompt.style';
import ConsentView, { ConsentViewMode } from '~/pages/householding-page/consent-view/ConsentView';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Loading from '~/common/components/Loading/Loading';
import ModalSaveConfirmation, { ModalSaveSuccessLayout } from '../../../common/components/Modal/ModalSaveSuccess';
import ModalSaveFailed from '../../../common/components/Modal/ModalSaveFail';
import i18n from '~/app/i18n';
import { PartyTypeCode } from '~/common/partyHelpers';

interface IPropsFromState {
  party: IPartyV1;
  showConsentPrompt: boolean;
  consentPrompt: IConsentPromptMetadata;
  isSavingConsent: boolean;
  isSavingConsentFailed: boolean;
  isSavingConsentSuccess: boolean;
  isConsentVerbiageLoaded: boolean;
  isConsentVerbiageFetching: boolean;
  isConsentVerbiageFailed: boolean;
  consentVerbiages: IConsentVerbiages[];
}

interface IPropsFromDispatch {
  loadConsentVerbiage: typeof loadConsentVerbiage;
  saveConsentPrompt: typeof saveConsentPrompt;
  resetSaveConsent: typeof resetSaveConsent;
}

type UiState = 'splash' | 'update';

interface ILocalState {
  isVisible: boolean;
  uiState: UiState;
  successSaveModalVisible: boolean;
  failSaveModalVisible: boolean;
  saveModalDismissed: boolean;
}

class ConsentPrompt extends React.Component<IPropsFromState & IPropsFromDispatch, ILocalState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.setInitialState(this.props.showConsentPrompt);
  }
  public componentDidMount() {}

  public componentDidUpdate() {
    if (this.props.isSavingConsentFailed && !this.state.failSaveModalVisible && !this.state.saveModalDismissed) {
      this.showFailModal();
    } else if (
      this.props.isSavingConsentSuccess &&
      !this.state.successSaveModalVisible &&
      !this.state.saveModalDismissed
    ) {
      this.showSuccessModal();
    }
  }

  setInitialState = (isVisible: boolean) => {
    const newState: ILocalState = {
      isVisible,
      uiState: 'splash',
      successSaveModalVisible: false,
      failSaveModalVisible: false,
      saveModalDismissed: false,
    };

    if (this.state) {
      this.setState(newState);
    } else {
      this.state = newState;
    }
  };

  close = () => {
    this.setState({ isVisible: false });
  };

  setUiState(newState: UiState) {
    this.setState({ uiState: newState });
  }

  showSuccessModal = () => {
    this.setState({
      saveModalDismissed: false,
      successSaveModalVisible: true,
    });
  };

  showFailModal = () => {
    this.setState({
      saveModalDismissed: false,
      failSaveModalVisible: true,
    });
  };

  closeSuccessModal = () => {
    this.props.resetSaveConsent();
    this.setInitialState(false);
  };

  closeFailureModal = () => {
    this.props.resetSaveConsent();
    this.setInitialState(false);
  };

  getActualPostponements = (promptMetadata: IConsentPromptMetadata): number => {
    const postponements = promptMetadata.postponements ?? 0;
    const postponementsIsEmpty = postponements === 0;
    const userHasPostponedInPast = !!promptMetadata.showOn;
    if (postponementsIsEmpty && userHasPostponedInPast) {
      // We consider the user has postponed when ShowOn has any date and postponements is emtpy
      return 1;
    }
    return postponements;
  };

  remindLater = () => {
    const dateIn30days = new Date(Date.now());

    dateIn30days.setDate(dateIn30days.getDate() + 30);
    const postponements = this.getActualPostponements(this.props.consentPrompt) + 1;
    this.saveConsentPrompt({ show: true, showOn: dateIn30days, postponements });
    this.setState({ isVisible: false });
  };

  saveConsentPrompt = (metadata: IConsentPromptMetadata) => {
    this.props.saveConsentPrompt(metadata);
  };

  isPartyCustomer = () => this.props.party?.partyTypeCode === PartyTypeCode.Customer;

  getIsOpen() {
    return (
      this.isPartyCustomer() &&
      this.haveSomeConsentPendingAnswer() &&
      this.props.showConsentPrompt &&
      this.state.isVisible
    );
  }

  haveSomeConsentPendingAnswer() {
    if (!this.props.isConsentVerbiageLoaded) {
      return false;
    }
    return this.props.consentVerbiages?.some((cv) => cv.value === undefined || cv.value === null);
  }

  renderSplash = () => (
    <Content>
      <StyledDiv>
        <InlineDiv>
          <Title size="lg">
            <Trans i18nKey="consent.prompt.personalInfo" />
          </Title>
          <Text>
            <Trans i18nKey="consent.prompt.description" />
          </Text>
        </InlineDiv>
        <BackgroundDivImage>
          <img src={background} />
        </BackgroundDivImage>
      </StyledDiv>
      <MarginDiv>
        <Title size="md">
          <Trans i18nKey="consent.prompt.dear" values={{ username: this.props.party.firstName }} />
        </Title>
      </MarginDiv>
      <MarginDiv>
        <Text>
          <Trans i18nKey="consent.prompt.confirmYourPreferences" />
        </Text>
        <Text>
          <Trans i18nKey="consent.prompt.importanceOfProvingConsent" />
        </Text>
      </MarginDiv>
      <ButtonsContainer>
        <Button variant="contained" color="secondary" onClick={() => this.setUiState('update')}>
          <Trans i18nKey="consent.prompt.update" />
        </Button>
        <LinkButton onClick={this.remindLater}>
          <Trans i18nKey="consent.prompt.remindLater" />
        </LinkButton>
      </ButtonsContainer>
    </Content>
  );

  renderUpdate = () => (
    <>
      <Stack style={{ backgroundColor: '#003da5', height: '50px' }}>
        <PopupTitle style={{ marginLeft: '12px', marginTop: '12px' }}>
          <Trans i18nKey="common.menuConsentPreferences" />
        </PopupTitle>
        <CloseIconDiv>
          <HighlightOffIcon onClick={this.close} style={CloseIconStyle} />
        </CloseIconDiv>
      </Stack>
      <ConsentView viewMode={ConsentViewMode.Popup} onCancel={this.remindLater} />
    </>
  );

  renderUpdateFeedbackModal = () => (
    <>
      <ModalSaveConfirmation
        onClose={this.closeSuccessModal}
        layout={ModalSaveSuccessLayout.noHeader}
        isVisible={this.state.successSaveModalVisible && !this.state.saveModalDismissed}
        title={i18n.t('common.thankYou')}
        message={i18n.t('consent.prompt.successMessage')}
      />
      <ModalSaveFailed
        onClose={this.closeFailureModal}
        isVisible={this.state.failSaveModalVisible && !this.state.saveModalDismissed}
        message={i18n.t('householding.savePreferencesFailure')}
      />
    </>
  );

  shouldShowFeedbackUpdateModal = () =>
    !this.state.saveModalDismissed && (this.state.failSaveModalVisible || this.state.successSaveModalVisible);

  public render() {
    if (!this.state.isVisible) {
      return <></>;
    }

    const loading = this.props.isConsentVerbiageFetching;

    if (
      !this.props.isConsentVerbiageLoaded &&
      !this.props.isConsentVerbiageFetching &&
      !this.props.isConsentVerbiageFailed
    ) {
      this.props.loadConsentVerbiage();
    }

    if (this.shouldShowFeedbackUpdateModal()) {
      return this.renderUpdateFeedbackModal();
    }

    return (
      <Dialog open={this.getIsOpen()} onClose={this.close} fullWidth maxWidth="md" scroll="body">
        {loading && <Loading show />}
        {!loading && this.state.uiState === 'splash' && this.renderSplash()}
        {!loading && this.state.uiState === 'update' && this.renderUpdate()}
      </Dialog>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    party: getPartyV1(state),
    showConsentPrompt: getShowConsentPrompt(state),
    consentPrompt: getConsentPrompt(state),
    isSavingConsent: getIsSavingConsent(state),
    isSavingConsentFailed: getIsSavingConsentFailed(state),
    isSavingConsentSuccess: getIsSavingConsentSuccess(state),
    isConsentVerbiageLoaded: getIsConsentVerbiageLoaded(state),
    isConsentVerbiageFetching: getIsConsentVerbiageFetching(state),
    isConsentVerbiageFailed: getIsConsentVerbiageFailed(state),
    consentVerbiages: getConsentVerbiages(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveConsentPrompt,
      loadConsentVerbiage,
      resetSaveConsent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConsentPrompt);
