export interface IIncomeStatement {
    totalRevenu: number,
    costOfSales: number,
    corporateCost: number,
    depreciation: number,
    interestExpense: number,
    researchDevelopment: number,
    provisionForLosses: number,
    otherExpense: number,
    otherIncome: number,
    unusualItem: number,
    preTaxIncome: number,
    incomeTax: number,
    discontinuedOperations: number,
    other: number,
    netIncomeMinorityInterest: number,
    netIncome: number,
    preferredDividend: number,
    interestEECD: number,
    incomeToCommonShareholders: number,
    numberOfShareYearEnd: number,
    numberOfShareAverage: number,
    commonDividend: number,
    dividendArrears: number,
    extraordinaryItems: number,
    year: string,
    date: Date,
    fiscalYear: string,
    name: string
}

export interface ICashFlow {
    cashFlow: number,
    changeInWorkingCap: number,
    otherCashFlow: number,
    totalOperatingCashFlow: number,
    capitalExpenditure: number,
    investingAndAcquisitions: number,
    otherAndDisposalAssets: number,
    totalInvestingActivities: number,
    totalDividendPayout: number,
    changesToLongTermDebt: number,
    changesToConvertibleDebentures: number,
    changesToCapitalStock: number,
    minorityInterestAndOther: number,
    totalFinancingActivities: number,
    cashIncreaseOrDecrease: number,
    financialPositionYearEnd: number,
    year: string,
    date: Date,
    fiscalYear: string,
    name: string
}

export interface IBalanceSheet {
    cashAndEquivalent: number,
    accountsReceivable: number,
    inventory: number,
    marketableSecurities: number,
    otherCurrentAssets: number,
    totalCurrentAssets: number,
    fixedAssets: number,
    investmentsRealEstate: number,
    investmentsOther: number,
    longTermReceivables: number,
    intangiblesAssets: number,
    otherLongTermAssets: number,
    totalAssets: number,
    bankLoansAndEquivalent: number,
    accountsPayable: number,
    currentPortionLongTermDebt: number,
    otherCurrentLiabilities: number,
    totalCurrentLiabilities: number,
    longTermDebtAndDebentures: number,
    deferredTaxesAndCredits: number,
    minorityInterest: number,
    depositAndCertificates: number,
    otherLongTermLiabilities: number,
    totalLiabilities: number,
    equityPreferredStock: number,
    equityElementConvertibleDebt: number,
    equityCommonStock: number,
    equityRetainedEarnings: number,
    equityOtherCapital: number,
    equityOther: number,
    totalEquity: number,
    totalLiabilitiesAndEquity: number,
    year: string,
    date: Date,
    fiscalYear: string,
    name: string
}

export interface IProfile {
    activity: any
    officers: any
    principleShareholders: any
}

export interface IFinancialInfo {
    symbol: string,
    market: string,
    marketIdentificationCode: string,
    requestStatus: string,
    incomeStatement: IIncomeStatement
    cashFlow: ICashFlow
    balanceSheet: IBalanceSheet
    ratio: any
}

export const frequencyAnnual = 'Annual';
export const frequencyQuarterly = 'Quarterly';

export interface IPositionDetail {
    historicalData: IHistoricalData
    financialData: {
        frequencyAnnual: IFinancialInfo,
        frequencyQuarterly: IFinancialInfo
    }
    stockDetailedInfo: IStockData
    dividentsHistoricalData: IDividendsHistoricalData
}

export interface IPositionDetails {
    isFetchingHistoricalData: boolean
    isFetchingFinancialData: boolean
    isFetchingStockDetailedInfoData: boolean,
    isFetchingFundDetailedInfoData: boolean
    isFetchingProfile: boolean
    isFetchingDividendsHistoricalData: boolean
    isFetchingStockDetailedInfoDataRefresh: boolean,
    isFetchingStockDetailedInfoRefreshHasError: boolean,
    positions: {
        [key: string] : IPositionDetail
    }
}

export interface IStockData {
    ask: number,
    askSize: number,
    averageVolume30Days:number,
    beta: number,
    bid: number,
    bidSize: number,
    currency: string,
    delayMinutes: number,
    dollarChange: number,
    expectedDividendYield: number,
    high:number,
    high52Weeks: number,
    last: number,
    lastSize: number,
    lastDateTime: Date,
    low: number,
    low52Weeks: number,
    market: string,
    marketCap: number,
    marketIdentificationCode: string,
    name: string,
    open: number,
    percentChange: number,
    previousClose: number,
    previousCloseVolume: number,
    previousCloseDateTime: Date,
    sharesOutstanding: number,
    symbol: number,
    volume: number,
    expectedDividendPerShare: number,
    priceToEarningsRatioTTM: number,
    requestStatus: string,
    peers: IPeer[],
    stockInfo: {
      earningsPerShareQuarterly: number,
      cashFlowPerShareQuarterly: number,
      operatingFundPerShareQuarterly: number,
      netIncomeQuarterly: number,
      totalRevenueQuarterly: number,
      cashFlowPerShareTTM: number,
      earningsYieldTTM: number,
      priceToBookTTM: number,
      totalRevenueTTM: number,
      earningsPerShareTTM: number,
      netIncomeTTM: number,
      priceToSalesTTM: number,
      profile : IStockProfile[]
    }
    etfInfo:{
        assetClass: string,
        categoryEn: string,
        categoryFr: string,
        inceptionDate: Date,
        managementExpenseRatio: number,
        managementFees: number,
        riskTaking: string,
        benchmarkEn: string,
        benchmarkFr: string,
        targetOutcome: string,
        activePassive: string,
        economicDevelopment: string,
        eligibility: string,
        listingType: string,
        country: string,
        exchange: string,
        inverse: string,
        leverage: string,
        leverageFactor: string,
        performance: IPerformance,
        profile:{
            strategyEn: string,
            strategyFr: string,
            paysTrailer: string,
            trailerFee: string,
            rebalancingFrequencyCode: string,
            fiscalYearEnd: string,
            distributionFrequencyCode: string,
            custodianCompany: string,
            etfIssuer: {
                name: string,
                addressLine1: string,
                city: string,
                province: string,
                postalCode: string,
                country: string,
                uRL: string,
                telephone: string,
                fax: string,
            },
            portfolioManagement: {
                id: string,
                companyCode: string,
                asOfDate: Date,
                nameEn: string,
                nameFr: string,
                addressLine1 : string,
                addressLine2: string,
                city: string,
                province: string,
                postalCode: string,
                country: string,
                telephone: string,
                fax: string,
                url: string,
            }
        }
    }
}

export interface IStockProfile{
    otherTicker: string,
    auditors: string,
    address: string,
    city: string,
    province: string,
    country: string,
    postalCode: string,
    telephone: string,
    fax: string,
    website: string,
    fiscalYearEnd: string,
    reportingCurrency: string,
    numberOfEmployees: string,
    registrarTransferAgents: string,
    principalShareholdersDescription: string,
    principalShareholdersDate: Date,
    description: string,
    completedAddress: string
    compensations: ICompensantion[]
}

export interface ICompensantion {
    year: number,
    officerAndExecutives: IOfficerAndExecutives[]
}

export interface IOfficerAndExecutives{
    name: string,
    position: string,
    salary: number,
    bonus: number,
    other: number
}

export interface IPeer{
    symbol: string,
    marketIdentificationCode: string,
    nameEn: string,
    nameFr: string,
    last: number,
    change: number,
    percentChange: number,
    marketCap: number
}

export interface IPerodicalData {
    date: Date
    open: number
    close: number
    high: number
    low: number
    volumn: number
    adjustedClose: number
}

export interface IHistoricalData {
    symbol: string
    market: string
    marketIdentificationCode: string
    currencyIsoCode: string
    requestStatus: string
    periodicalDatas: IPerodicalData[]
}

export interface IFundData {
    categoryNameEn: string,
    categoryNameFr: string,
    currency: string,
    distributionYield: number,
    dollarChange: number,
    family: string,
    fiveYearReturnAnnualized: number,
    fundSymbol: string,
    inceptionDate: Date,
    fundNameEn: string,
    fundNameFr: string,
    high52Weeks: number
    managementFeePercent: number,
    mer: number,
    last: number,
    netAssets: number,
    oneYearReturn: number,
    percentChange: number,
    previousNavps: number
    requestStatus: string,
    standardDeviationThreeYears: number,
    updateTimestamp: Date,
    volatilityRankingThreeYears: number,
    tTMYield: number,
    profile: IFundProfile,
    peers: IPeer[],
    assetAllocations: IAllocation[],
    sectorAllocations: IAllocation[],
    regionAllocations: IAllocation[],
    topHoldings: ITopHolding[],
    performance: IPerformance,
    loadTypeEn: string,
    loadTypeFr: string
}

export interface ITopHolding{
    rankOrder: number,
    securityName: string,
    marketValue: string,
    marketPercent: string,
    updateDate: Date
}

export interface IPerformance{
    asOfReturn: Date,
    oneMonthReturn: number,
    threeMonthsReturn: number,
    sixMonthsReturn: number,
    ytdReturn: number,
    oneYearReturn: number,
    twoYearsReturn: number,
    threeYearsReturn: number,
    fiveYearsReturn: number,
    tenYearsReturn: number,
    fifteenYearsReturn: number,
    twentyYearsReturn: number,
    sinceInceptionReturn: number,
    calendarReturnYear1: number,
    calendarReturnYear2: number,
    calendarReturnYear3: number,
    calendarReturnYear4: number,
    calendarReturnYear5: number,
    calendarReturnYear6: number,
    calendarReturnYear7: number,
    calendarReturnYear8: number,
    calendarReturnYear9: number,
    calendarReturnYear10: number,
    asOfQuartile: number,
    oneMonthQuartile: number,
    threeMonthsQuartile: number,
    sixMonthsQuartile: number,
    yTDQuartile: number,
    oneYearQuartile: number,
    twoYearsQuartile: number,
    threeYearsQuartile: number,
    fiveYearsQuartile: number,
    tenYearsQuartile: number,
    fifteenYearsQuartile: number,
    twentyYearsQuartile: number,
    sinceInceptionQuartile: number,
    calendarQuartileYear1: number,
    calendarQuartileYear2: number,
    calendarQuartileYear3: number,
    calendarQuartileYear4: number,
    calendarQuartileYear5: number,
    calendarQuartileYear6: number,
    calendarQuartileYear7: number,
    calendarQuartileYear8: number,
    calendarQuartileYear9: number,
    calendarQuartileYear10: number
}

export interface IFundProfile{
    fundGrade: string,
    category: string,
    classification: string,
    minimumInvestment: number,
    investmentObjectiveEn: string,
    investmentObjectiveFr: string,
    investmentStrategyEn: string,
    investmentStrategyFr: string,
    companyNameEn: string,
    companyNameFr: string,
    address: string,
    phoneNumber: string,
    faxNumber: string,
    tollFreeNumber: string,
    isIFICMember: boolean,
    iFICGroupNameEn: string,
    iFICGroupNameFr: string,
    websiteAddress: string,
    lastUpdate: Date,
    managers: IManager[]
}

export interface IManager{
    biographyEn: string,
    biographyFr: string,
    name: string,
    companyName: string,
    startDate: Date
}

export interface IAllocation{
    code: string,
    nameEn: string,
    bameFr: string,
    percent: number,
    dollar: number,
    updateDate: Date
}

export interface IDividendsHistoricalData {
    symbol: string
    market: string
    marketIdentificationCode: string
    requestStatus: string
    nextPayableDividendDate: Date
    payoutRatioEarning: number
    payoutRatioCashflow: number
    dividendHistory: IDividendHistory[]
}

export interface IDividendHistory {
    exDate: Date
    payDate: Date
    recordDate: Date
    amount: number
    currency: string
    type: string
    frequency: string
  }

export interface IEarningsEstimate {
    symbol: string
    years: IYearlyEstimate[]
    quarters: IQuarterlyEstimate[]
}

export interface IYearlyEstimate {
    year: number
    releaseDate: Date
    epsEstimate: number
}

export interface IQuarterlyEstimate {
    fiscalYear: number
    fiscalQuarter: number
    releaseDate: Date
    epsEstimate: number
}

export const enum PositionDetailActionTypes {
    FETCH_HISTORICAL_DATA = '[Position Detail] Fetch Historical Data',
    FETCH_HISTORICAL_DATA_SUCCESSFUL = '[Position Detail] Fetch Historical Data Successful',
    FETCH_HISTORICAL_DATA_NOTFOUND = '[Position Detail] Fetch Historical Data Not found',
    FETCH_HISTORICAL_DATA_FAILED = '[Position Detail] Fetch Historical Data Failed',

    FETCH_FINANCIAL_DATA = '[Position Detail] Fetch Financial Data',
    FETCH_FINANCIAL_DATA_SUCCESSFUL = '[Position Detail] Fetch Financial Data Successful',
    FETCH_FINANCIAL_DATA_NOTFOUND = '[Position Detail] Fetch Financial Data Not found',
    FETCH_FINANCIAL_DATA_FAILED = '[Position Detail] Fetch Financial Data Failed',

    FETCH_STOCK_DETAILED_INFO_DATA = '[Position Detail] Fetch Stock Detailed Info Data',
    FETCH_STOCK_DETAILED_INFO_DATA_SUCCESSFUL = '[Position Detail] Fetch Stock Detailed Info Data Successful',
    FETCH_STOCK_DETAILED_INFO_DATA_NOTFOUND = '[Position Detail] Fetch Stock Detailed Info Data Not found',
    FETCH_STOCK_DETAILED_INFO_DATA_FAILED = '[Position Detail] Fetch Stock Detailed Info Data Failed',

    FETCH_STOCK_DETAILED_INFO_DATA_REFRESH = '[Position Detail] Fetch Stock Detailed Info Data Refresh',
    FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_SUCCESSFUL = '[Position Detail] Fetch Stock Detailed Info Data Refresh Successful',
    FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_FAILED = '[Position Detail] Fetch Stock Detailed Info Data Refresh Failed',

    FETCH_FUND_DETAILED_INFO_DATA = '[Position Detail] Fetch Fund Detailed Info Data',
    FETCH_FUND_DETAILED_INFO_DATA_SUCCESSFUL = '[Position Detail] Fetch Fund Detailed Info Data Successful',
    FETCH_FUND_DETAILED_INFO_DATA_NOTFOUND = '[Position Detail] Fetch Fund Detailed Info Data Not found',
    FETCH_FUND_DETAILED_INFO_DATA_FAILED = '[Position Detail] Fetch Fund Detailed Info Data Failed',

    FETCH_EARNINGS_ESTIMATE = '[Position Detail] Fetch Earnings Estimate',
    FETCH_EARNINGS_ESTIMATE_SUCCESSFUL = '[Position Detail] Fetch Earnings Estimate Successful',
    FETCH_EARNINGS_ESTIMATE_FAILURE = '[Position Detail] Fetch Earnings Estimate Failed',

    FETCH_DIVIDENDS_HISTORICAL_DATA = '[Position Detail] Fetch Dividends Historical Data',
    FETCH_DIVIDENDS_HISTORICAL_DATA_SUCCESSFUL = '[Position Detail] Fetch Dividends Historical Data Successful',
    FETCH_DIVIDENDS_HISTORICAL_FAILED = '[Position Detail] Fetch Dividends Historical Data Failed',
  }
