import * as React from 'react';
import i18n from '~/app/i18n';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckCircleIconStyle } from './ModalSaveResult.style';
import ModalSaveResult from './ModalSaveResult';

export enum ModalSaveSuccessLayout {
  default,
  noHeader
}
interface IModalSaveSuccessProps {
  onClose: () => void,
  layout?: ModalSaveSuccessLayout,
  isVisible: boolean,
  title?: string,
  message: string
}

const ModalSaveSuccess: React.FC<IModalSaveSuccessProps> = (props: IModalSaveSuccessProps) => (
  <ModalSaveResult
    onClose={props.onClose}
    isVisible={props.isVisible}
    header={props.layout === ModalSaveSuccessLayout.noHeader ? '' : i18n.t('householding.confirm')}
    icon={<CheckCircleIcon style={CheckCircleIconStyle} />}
    title={props.title || i18n.t('common.confirmationMessageTitle')}
    message={props.message}
    layout={props.layout}
  />);

export default ModalSaveSuccess;
