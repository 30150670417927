import * as React from 'react';
import { Trans } from 'react-i18next';
import LinkButton from '../link-button/LinkButton';
import { Sync as SyncIcon } from '@mui/icons-material';

export const UpdateDataButton = ({ onClick, isProcessing }: { onClick: () => void; isProcessing?: boolean }) => (
  <LinkButton onClick={onClick} isProcessing={isProcessing} endIcon={<SyncIcon />}>
    <Trans i18nKey="common.updateData" />
  </LinkButton>
);
