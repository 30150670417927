import 'antd/lib/button/style/index.css';
import '~/common/styles/styleIAS.css';
import { Checkbox, Modal, Table } from 'antd';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import { IHouseholdViewData, mapPartyToHouseholdView } from '~/common/partyHelpers';
import { Container, BoxShadow } from '~/common/styles/baseStyle';
import { IAccount } from '~/stores/account/accountTypes';
import { getAllAvailableAccounts } from '~/stores/account/selectors/accountSelectors';
import { resetPartyPreferedAccountSaving, savePartyPreferedAccount } from '~/stores/party/actions/partyActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import {
  getIsFetchingSavePreferredAccount,
  getIsSavePreferredAccountSuccess,
  getPartyV1,
} from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { Button, Typography } from '@mui/material';
import Loading from '../../../common/components/Loading/Loading';
import { isUserAdvisorOrAdmin } from '../../../stores/system/selectors/SystemSelectors';

const ConsolidatedViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SaveContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding : 10px;
`;

const HouseholdDescription = styled.div`
    padding-top:20px;
    padding-bottom:20px;
`;

export interface IPropsFromState {
  culture: string,
  state: IAppRootState,
  party: IPartyV1,
  accounts: IAccount[],
  isFetchingSavePreferredAccount: boolean,
  isSavePreferredSuccess: boolean,
  isUserAdvisorOrAdmin: boolean
}

export interface IPropsFromDispatch {
  savePartyPreferedAccount: typeof savePartyPreferedAccount
  resetPartyPreferedAccountSaving: typeof resetPartyPreferedAccountSaving
}

class HouseholdView extends React.PureComponent<IPropsFromState & IPropsFromDispatch> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.setPreviousHolding();
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  private originalPreferedAccountIds = [] as string[];

  private setPreviousHolding = () => {
    this.originalPreferedAccountIds = [...this.props.party.preferedAccountIds];
  };

  private establishAccounts = (): IHouseholdViewData[] => {
    const { party, accounts } = this.props;
    let results: IHouseholdViewData[] = [];
    results = mapPartyToHouseholdView(party, accounts);
    return results;
  };

  private getSelectedAccountsIds = (): string[] => {
    const accountIds = this.props.party.preferedAccountIds;
    return accountIds;
  };

  private isFormDirty = (): boolean => {
    if (this.props.isSavePreferredSuccess) {
      return false;
    }
    const selectedAccountsIds = this.getSelectedAccountsIds();
    return JSON.stringify(selectedAccountsIds.sort()) !== JSON.stringify(this.originalPreferedAccountIds.sort());
  };

  private hasAccountSelected = (): boolean => {
    const selectedAccountsIds = this.getSelectedAccountsIds();
    return (selectedAccountsIds && selectedAccountsIds.length > 0);
  };

  public handleOk = () => {
    const { party } = this.props;
    this.RemovePurgeAccountFromPartyPreferedAccounts(party);
    this.props.savePartyPreferedAccount(party.id, party);
    return true;
  };

  private RemovePurgeAccountFromPartyPreferedAccounts = (party: IPartyV1) => {
    const accountIds = this.props.accounts.map((x) => x.id);
    const preferedAccounts = party.preferedAccountIds.filter((item) => accountIds.indexOf(item) !== -1);
    party.preferedAccountIds = preferedAccounts;
  };

  public toggleCheckbox(event: any) {
    const { party, accounts } = this.props;

    if (event.target.checked) {
      party.preferedAccountIds.push(event.target.value);
    } else {
      if (party.preferedAccountIds.length === 0) {
        party.preferedAccountIds.push(...accounts.map((m) => m.id));
      }
      const index = party.preferedAccountIds.indexOf(event.target.value);
      party.preferedAccountIds.splice(index, 1);
    }
    this.forceUpdate();
  }

  public resetForm = () => {
    this.props.party.preferedAccountIds = this.originalPreferedAccountIds;
    this.props.resetPartyPreferedAccountSaving();
    return true;
  };

  public render() {
    const { isFetchingSavePreferredAccount, isSavePreferredSuccess } = this.props;

    if (isFetchingSavePreferredAccount) {
      return (
        <Container style={{ minHeight: 200 }}>
          <Loading show />
        </Container>
      );
    }

    if (isSavePreferredSuccess) {
      this.props.resetPartyPreferedAccountSaving();
      this.setPreviousHolding();
    }

    const renderOwner = (value: any, row: any, index: number) => {
      const obj = {
        children: value,
        props: { rowSpan: row.rowSpan, className: 'groupColumn' },
      };

      if (row.isUserOwner) {
        obj.props.className = 'groupColumnSelected';
        if (row.isLastRow) {
          obj.props.className = 'groupColumnSelected borderBottomBlue';
        }
      } else if (row.isLastRow) {
        obj.props.className = 'groupColumn borderBottomBlue';
      }
      return obj;
    };

    const renderItem = (value: any, row: any, index: number) => {
      const obj = {
        children: value,
        props: { className: row.isLastRow ? 'borderBottomBlue' : '' },
      };

      return obj;
    };

    const renderCheckbox = (value: any, row: any, index: number) => {
      const obj = {
        children: <span>
          <Checkbox defaultChecked={row.isHousehold} onChange={this.toggleCheckbox} key={row.id} value={row.accountNumber} disabled={this.props.isUserAdvisorOrAdmin} />
        </span>,
        props: { className: row.isLastRow ? 'borderBottomBlue centerContent' : 'centerContent' },
      };

      return obj;
    };

    const columns = [
      {
        title: i18n.t('householding.owner'),
        dataIndex: 'owner',
        key: 'Owner',
        render: renderOwner,
      },
      {
        title: i18n.t('householding.accounts'),
        dataIndex: 'accountType',
        key: 'accountType',
        render: renderItem,
      },
      {
        title: '',
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        render: renderItem,
        width: 100,
      },
      {
        title: '',
        key: 'productType',
        dataIndex: 'productType',
        render: renderItem,
      },
      {
        title: i18n.t('householding.includeHousehold'),
        key: 'action',
        width: 150,
        render: renderCheckbox,
      },
    ];

    const data = this.establishAccounts();

    return (
      <BoxShadow>
        <ConsolidatedViewContainer>
          <NavigationPrompt renderIfNotActive={false} when={this.isFormDirty()} afterConfirm={this.resetForm}>
            {({ isActive, onCancel, onConfirm }) => {
              if (isActive) {
                return (
                  <Modal title={i18n.t('householding.deleteHouseHoldingTitle') as string} open={isActive} onOk={onConfirm} onCancel={onCancel} okText={i18n.t('common.yes') as string} cancelText={i18n.t('common.cancel') as string}>
                    <p> <Trans i18nKey="householding.deleteHouseHolding" /> </p>
                  </Modal>
                );
              }
              return false;
            }}
          </NavigationPrompt>
          <HouseholdDescription>
            <Typography variant="body1">
              <Trans i18nKey="householding.householdDescrption" />
              <br /> <br />
              <Trans i18nKey="householding.householdDescrption2" />
              <br /> <br />
              <Trans i18nKey="householding.householdDescrption3" />
            </Typography>
          </HouseholdDescription>
          <Table columns={columns} dataSource={data} pagination={false} className="tableHousehold" />
          <SaveContainer>
            <Button key="submit" onClick={this.handleOk} disabled={!this.isFormDirty() || !this.hasAccountSelected() || this.props.isUserAdvisorOrAdmin} variant="contained" color="secondary"><Trans i18nKey="common.save" /></Button>
          </SaveContainer>

        </ConsolidatedViewContainer>
      </BoxShadow>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    culture: state.system.culture,
    party: getPartyV1(state),
    isFetchingSavePreferredAccount: getIsFetchingSavePreferredAccount(state),
    isSavePreferredSuccess: getIsSavePreferredAccountSuccess(state),
    accounts: getAllAvailableAccounts(state),
    state,
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  savePartyPreferedAccount,
  resetPartyPreferedAccountSaving,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdView);
