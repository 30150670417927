import { action } from 'typesafe-actions';
import { TransactionActionTypes, ITransaction, ITransactionAccountFilter } from '../transactionTypes';

export interface IFetchTransactionPayload {
    accountIds: string[],
    startDate: string,
    endDate: string,
    hasFilterChanged: boolean,
    isAccountSwitch: boolean
}

export const fetchTransactions = (payload: IFetchTransactionPayload) => action(TransactionActionTypes.FETCH_TRANSACTION, payload);
export const fetchTransactionsSuccessful = (transactions: ITransaction[]) => action(TransactionActionTypes.FETCH_TRANSACTION_SUCCESSFUL, transactions);
export const fetchTransactionsFailed = () => action(TransactionActionTypes.FETCH_TRANSACTION_FAILED);
export const fetchTransactionsAborted = () => action(TransactionActionTypes.FETCH_TRANSACTION_ABORTED);

export const setSelectedTransactionIds = (accountFilter: ITransactionAccountFilter) => action(TransactionActionTypes.SET_SELECTED_TRANSACTION_IDS, accountFilter);
export const setTransactionSymbolDescriptionFilter = (symbolDescriptionFilter: string) => action(TransactionActionTypes.SET_TRANSACTION_SYMBOL_DESCRIPTION_FILTER, symbolDescriptionFilter);
export const setTransactionCurrencyFilter = (currencyIds: string[]) => action(TransactionActionTypes.SET_TRANSACTION_CURRENCY_FILTER, currencyIds);
export const setTransactionProductTypeFilter = (productTypeIds: string[]) => action(TransactionActionTypes.SET_TRANSACTION_PRODUCT_TYPE_FILTER, productTypeIds);
export const setTransactioOperationTypeFilter = (productTypeIds: string[]) => action(TransactionActionTypes.SET_TRANSACTION_OPERATION_TYPE_FILTER, productTypeIds);
export const updateTransactionsAccounts = () => action(TransactionActionTypes.UPDATE_ACCOUNTS);

export const setTransactionStartDate = (startDate: string) => action(TransactionActionTypes.SET_TRANSACTION_START_DATE, startDate);
export const setTransactionEndDate = (endDate: string) => action(TransactionActionTypes.SET_TRANSACTION_END_DATE, endDate);

export const setExcludeCloseAcount = (excludeCloseAcount?: boolean) => action(TransactionActionTypes.SET_EXCLUDE_CLOSE_ACCOUNT, { excludeCloseAcount });
