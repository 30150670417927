import * as React from 'react';

import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { IEmail } from '~/common/interfaces/advisor/Email';
import { ITradeName } from '~/common/interfaces/advisor/TradeName';
import { IWebsite } from '~/common/interfaces/Website';
import { User } from '~/common/svg';
import {
  Container,
  EmailSection,
  Icon,
  Informations,
  Name,
  OrganisationSection,
  WebsitesSection,
  PhoneSection,
} from './styles/advisorCard.style';
import { RegulatoryRole } from '~/common/enum/RegulatoryRole';
import { getPrimaryPhone } from './helper';
import i18n from '~/app/i18n';
import { Typography } from '@mui/material';

interface ILocalProps {
  advisor: IAdvisor;
}

const emailSection = (emails: IEmail[]): JSX.Element | null => {
  if (!emails) {
    return null;
  }

  const primaryEmail = emails.find((e: IEmail) => e.primary);
  return primaryEmail
    ? <a href={`mailto:${primaryEmail.emailAddress}`}>{primaryEmail.emailAddress}</a>
    : null;
};

const websiteSection = (websites: IWebsite[]): JSX.Element | null => {
  if (!websites) {
    return null;
  }

  const website = websites.find((w: IWebsite) => w.primary);
  return website
    ? <a href={`${website.url}`}>{website.url}</a>
    : null;
};

const tradeNameSection = (tradeNames: ITradeName[]): JSX.Element | null => {
  if (!tradeNames) {
    return null;
  }

  const item = tradeNames.find((tradeName: ITradeName) => tradeName.primary);
  return item
    ? <>{` | ${item.name}`}</>
    : null;
};

const roleSection = (regulatoryRole: RegulatoryRole): JSX.Element | null => <Typography variant="h6" component="span"><>{i18n.t(`regulatoryRoles.${regulatoryRole}`)}</></Typography>;

const formatPhone = (phone: string): JSX.Element => {
  const linkPhoneNumber = phone !== null ? phone.replace(/\D/g, '') : '';
  const linkToPhone = `tel:${linkPhoneNumber}`;
  return (<a href={linkToPhone}>{phone}</a>);
};

const AdvisorCard: React.FC<ILocalProps> = ({ advisor }: ILocalProps) => {
  const primaryPhone = getPrimaryPhone(advisor.phones);

  return (
    <Container>
      <Icon>{User(40)}</Icon>
      <Informations>
        <Name>
          {advisor.preferredName ? advisor.preferredName : advisor.firstName} {advisor.lastName}
        </Name>
        <OrganisationSection>
          {roleSection(advisor.regulatoryRole)}
          {tradeNameSection(advisor.tradeNames)}
        </OrganisationSection>
        {primaryPhone && <PhoneSection>{formatPhone(primaryPhone)}</PhoneSection>}
        <EmailSection>{emailSection(advisor.emails)}</EmailSection>
        <WebsitesSection>{websiteSection(advisor.websites)}</WebsitesSection>
      </Informations>
    </Container>
  );
};

export default AdvisorCard;
