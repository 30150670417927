export interface IContestState {
  readonly contest?: Contest;
  readonly contestDialogType: ContestDialogType;
}

export type ContestDialogType = 'details' | 'unregister' | 'none';

export interface Contest {
  title: string;
  description: string;
  contestRulesUri: string;
  contestUri: string;
  unsubscribeConfirmationMessage: string;
  summary: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  isSubscribed?: boolean;
  isPostponed?: boolean;
}

export const enum ContestActionTypes {
  LOAD_CONTEST = '[Contest] Load',
  LOAD_CONTEST_SUCCESSFUL = '[Contest] Load Successful',
  LOAD_CONTEST_FAILED = '[Contest] Load Failed',

  SUBSCRIBE_CONTEST = '[Contest] Subscribe',
  SUBSCRIBE_CONTEST_SUCCESSFUL = '[Contest] Subscribe Successful',
  SUBSCRIBE_CONTEST_FAILED = '[Contest] Subscribe Failed',

  INCREMENT_VIEWED_CONTEST = '[Contest] Increment Viewed',
  INCREMENT_VIEWED_CONTEST_SUCCESSFUL = '[Contest] Increment Viewed Successful',
  INCREMENT_VIEWED_CONTEST_FAILED = '[Contest] Increment Viewed Failed',

  POSTPONE_CONTEST = '[Contest] Postpone',
  POSTPONE_CONTEST_SUCCESSFUL = '[Contest] Postpone Successful',
  POSTPONE_CONTEST_FAILED = '[Contest] Postpone Failed',

  UNSUBSCRIBE_CONTEST = '[Contest] Unsubscribe',
  UNSUBSCRIBE_CONTEST_SUCCESSFUL = '[Contest] Unsubscribe Successful',
  UNSUBSCRIBE_CONTEST_FAILED = '[Contest] Unsubscribe Failed',
}
