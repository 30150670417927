import * as React from 'react';
import { Box, Fab, Fade, Tooltip } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import i18n from '~/app/i18n';
import Colors from '~/common/themes/colors';
import { ArrowUpThin } from '~/common/svg';

interface ScrollTopProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement<unknown>;
}

export const ScrollTop = ({ children, window }: ScrollTopProps) => {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (!anchor) {
      console.info("Couldn't find anchor element to BackToTop action");
    }
    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children || (
          <Tooltip title={`${i18n.t('common.backToTop')}`} placement="left">
            <>
              <Fab
                size="small"
                sx={{
                  boxShadow: 'none',
                  backgroundColor: Colors.lightGray,
                  ':hover': {
                    backgroundColor: Colors.anotherGrayShade,
                  },
                }}
                aria-label={`${i18n.t('common.backToTop')}`}
              >
                {ArrowUpThin(40)}
              </Fab>
            </>
          </Tooltip>
        )}
      </Box>
    </Fade>
  );
};
