import * as React from 'react';
import { Popover } from '~/common/components';
import styled from 'styled-components';

const DropdownContainer = styled.div`
    display: flex;
`;
const DropdownInputContainer = styled.div`
    display: flex;
`;
const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const OptionContainer = styled.div`
    display: flex;
`;

export interface IDropdownProps {
    renderSelectedOption: () => JSX.Element,
    renderOption: (option: IDropdownOption) => JSX.Element,
    options: IDropdownOption[],
    onOptionSelected: (id: string) => void
}

export interface IDropdownState {
    isPopoverVisible: boolean
}

export interface IDropdownOption {
    id: string
}

class Dropdown extends React.PureComponent<IDropdownProps, IDropdownState> {
  private selectedOptionsElement: React.RefObject<HTMLDivElement>;
  private optionsElement: React.RefObject<HTMLDivElement>;

  constructor(props: IDropdownProps) {
    super(props);

    this.selectedOptionsElement = React.createRef<HTMLDivElement>();
    this.optionsElement = React.createRef<HTMLDivElement>();

    this.state = {
      isPopoverVisible: false,
    };
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  private handleDocumentMouseDown = (event: Event) => {
    if (!this.optionsElement.current) {
      return;
    }

    if (!this.optionsElement.current.contains(event.target as any)) {
      this.setState({ isPopoverVisible: false });
    }
  };

  public handleOnDropdownInputClick = () => {
    this.setState({ isPopoverVisible: true });
  };

  public handleOnOptionClick = (id: string) => {
    const { onOptionSelected } = this.props;

    onOptionSelected(id);
    this.setState({ isPopoverVisible: false });
  };

  public renderPopover() {
    const { renderOption, options } = this.props;
    const { isPopoverVisible } = this.state;

    if (!isPopoverVisible || !this.selectedOptionsElement.current) {
      return null;
    }

    const parentBoundingRect = this.selectedOptionsElement.current.getBoundingClientRect();

    return (
      <Popover parentBoundingRect={parentBoundingRect}>
        <OptionsContainer ref={this.optionsElement as any}>
          {options.map((option: IDropdownOption) => (
            <OptionContainer key={option.id} onClick={this.handleOnOptionClick.bind(this, option.id)}>
              {renderOption(option)}
            </OptionContainer>
          ))}
        </OptionsContainer>
      </Popover>
    );
  }

  public render() {
    const { renderSelectedOption } = this.props;

    return (
      <DropdownContainer>
        <DropdownInputContainer onClick={this.handleOnDropdownInputClick} ref={this.selectedOptionsElement as any}>
          {renderSelectedOption()}
        </DropdownInputContainer>
        {this.renderPopover()}
      </DropdownContainer>
    );
  }
}

export default Dropdown;
