import React from 'react';
import Help from '@mui/icons-material/Help';
import IconTooltip from '../icon-tooltip/IconTooltip';

interface INotFoundAtProviderProps {
  size?: 'small' | 'default' | 'large'
  style?: React.CSSProperties
}

export const NotFoundAtProvider: React.FC<INotFoundAtProviderProps> =
  ({ size, style }) => <IconTooltip icon={<Help style={style} />} messageKey="common.positionNotFoundAtProvider" />;

NotFoundAtProvider.defaultProps = {
  size: 'small',
  style: {
    marginLeft: '20px',
    color: '#acafae',
  },
};
