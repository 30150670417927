import { RouterState } from 'redux-first-history';
import { IAccountState } from '~/stores/account/accountTypes';
import { accountReducer } from '~/stores/account/reducers/accountReducer';
import { IAdvisorState } from '~/stores/advisor/AdvisorTypes';
import { advisorReducer } from '~/stores/advisor/reducers/advisorReducer';
import { IPartyState } from '~/stores/party/partyTypes';
import { partyReducer } from '~/stores/party/reducers/partyReducer';
import { systemReducer } from '~/stores/system/reducers/systemReducer';
import { ISystemState } from '~/stores/system/systemTypes';
import { transactionReducer } from '~/stores/transaction/reducers/transactionReducer';
import { ITransactionState } from '~/stores/transaction/transactionTypes';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { IMessageState } from '~/stores/message/messageTypes';
import { messageReducer } from '~/stores/message/reducers/messageReducer';
import { IPositionDetails } from '~/stores/positionDetail/types';
import { positionDetailReducer } from '~/stores/positionDetail/reducers/positionDetailReducer';
import { oidcReducer } from '../stores/oidc/reducers/oidcReducers';
import { IoidcState } from '../stores/oidc/oidcTypes';
import { IKYCState } from '~/stores/kyc/kycTypes';
import { kycReducer } from '~/stores/kyc/kycReducers';
import { IMarketDataState, IMarketProductDetailsState } from '~/stores/marketData/marketDataTypes';
import { marketDataReducer } from '~/stores/marketData/reducers/marketDataReducer';
import { marketProductDetailReducer } from '~/stores/marketData/reducers/marketProductDetailReducer';
import { IContestState } from '~/stores/contest/contestTypes';
import { contestReducer } from '~/stores/contest/reducers/contestReducer';

export interface IAppRootState {
  router: RouterState;
  account: IAccountState;
  system: ISystemState;
  transaction: ITransactionState;
  party: IPartyState;
  advisor: IAdvisorState;
  oidc: IoidcState;
  message: IMessageState;
  positionDetails: Partial<IPositionDetails>;
  kyc: IKYCState;
  contest: IContestState;
  marketData: IMarketDataState;
  marketProductDetails: IMarketProductDetailsState;
}

export const createRootReducer = (routerReducer: Reducer<RouterState, AnyAction>) =>
  combineReducers<IAppRootState>({
    router: routerReducer,
    account: accountReducer,
    system: systemReducer,
    transaction: transactionReducer,
    party: partyReducer,
    advisor: advisorReducer,
    oidc: oidcReducer,
    message: messageReducer,
    positionDetails: positionDetailReducer,
    kyc: kycReducer,
    marketData: marketDataReducer,
    marketProductDetails: marketProductDetailReducer,
    contest: contestReducer,
  });
