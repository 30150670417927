import { IAppRootState } from '~/app/rootReducer';
import { HouseholdType, isValidRepCode } from '~/common/partyHelpers';
import {
  affiliateConsentAvailableKey,
  thirdPartyConsentAvailableKey,
  affiliateConsentVerbiageAvailableKey,
  thirdPartyConsentVerbiageAvailableKey,
  distinctiveClientExpConsentAvailableKey,
} from '~/common/constants';
import { IPartyState } from '~/stores/party/partyTypes';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
  GetDidConsentToMailNotification,
  GetDidSetMarketDataRealtime,
  GetDidConsentValueByKey,
  GetShowConsentPrompt,
  GetConsentPrompt,
  GetDidSetIntradayChangePref,
  GetHoldingSortPreferences,
} from '../../../common/utils/MetadataHelper';

export const getPartyState = (state: IAppRootState) => state.party;

export const getIsDataExpired = createSelector(
  getPartyState,
  (partyState: IPartyState) => !partyState.dataExpiration || moment(partyState.dataExpiration).isBefore(moment()),
);

export const getParty = createSelector(getPartyState, (partyState: IPartyState) => partyState.partyV1);

export const getUserId = createSelector(getPartyState, (partyState: IPartyState) => partyState.partyV1.id);

export const getAccountShare = createSelector(getPartyState, (partyState: IPartyState) => partyState.accountShare);

export const getOwnedAccountIdsFromPartyState = createSelector(getPartyState, (partyState: IPartyState) => {
  const ownedAccounts = partyState.partyV1.accounts.filter(
    (account) =>
      (account.role === HouseholdType.OWNER || account.role === HouseholdType.COMPANY) &&
      isValidRepCode(account.repCode),
  );
  return ownedAccounts.map((account) => account.id);
});

export const getPartyV1 = createSelector(getPartyState, (partyState: IPartyState) => partyState.partyV1);

export const getIsPartyFetching = createSelector(getPartyState, (partyState: IPartyState) => partyState.isFetching);

export const getIsSearchFetching = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isFetchingPartySearch,
);
export const getAccountSearch = createSelector(getPartyState, (partyState: IPartyState) => partyState.accountSearch);

export const getIsFetchingPartyByUser = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isFetchingPartyUser,
);

export const getIsFetchingSavePreferredAccount = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isFetchingSavePreferredAccount,
);

export const getIsSavePreferredAccountSuccess = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isSavePreferredAccountSuccess,
);

export const getIsHouseHoldingView = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isHouseHoldingView,
);

export const getIsProgramView = createSelector(getPartyState, (partyState: IPartyState) => partyState.isProgramView);

export const getIsFetchingAccesSharing = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isFetchingAccesSharing,
);

export const getIsFetchingPartyUserHasError = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isFetchingPartyUserHasError,
);

export const getPartySearchForSharing = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.accountSearch,
);

export const getPartiesForSharing = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.partiesShared,
);

export const getDidConsentToMailNotification = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidConsentToMailNotification(metadata);
});

export const getDidConsentToThirdPartyConsent = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidConsentValueByKey(metadata, thirdPartyConsentAvailableKey);
});

export const getDidConsentToAffiliateConsent = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidConsentValueByKey(metadata, affiliateConsentAvailableKey);
});

export const getDidConsentToDistinctiveClientExpConsent = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidConsentValueByKey(metadata, distinctiveClientExpConsentAvailableKey);
});

export const getConsentVerbiages = createSelector(getPartyState, (partyState: IPartyState) => {
  const verbiage = partyState.consentVerbiages;
  if (verbiage && verbiage.length >= 1) {
    return verbiage;
  }
  return [];
});

export const getIsConsentVerbiageLoaded = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isConsentVerbiageLoaded,
);

export const getIsConsentVerbiageFailed = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isConsentVerbiageFailed,
);

export const getIsConsentVerbiageFetching = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isConsentVerbiageFetching,
);

export const getAffiliateConsentVersion = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  const item = metadata.find((m) => m.key === affiliateConsentVerbiageAvailableKey);
  if (item === undefined) {
    return undefined;
  }
  return item.value[0];
});

export const getThirdPartyConsentVersion = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  const item = metadata.find((m) => m.key === thirdPartyConsentVerbiageAvailableKey);
  if (item === undefined) {
    return undefined;
  }
  return item.value[0];
});

export const getIsSavingConsent = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isSavingConsentPreferences,
);

export const getIsSavingConsentFailed = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isSavingConsentPreferencesFail,
);

export const getIsSavingConsentSuccess = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isSavingConsentPreferencesSuccess,
);

export const getConsentPrompt = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetConsentPrompt(metadata);
});

export const getShowConsentPrompt = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetShowConsentPrompt(metadata);
});

export const getDidSetMarketDataRealtime = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidSetMarketDataRealtime(metadata);
});

export const getDidSetIntradayChangePref = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetDidSetIntradayChangePref(metadata);
});

export const getHoldingSortingPref = createSelector(getPartyState, (partyState: IPartyState) => {
  const { metadata } = partyState.partyV1;
  return GetHoldingSortPreferences(metadata);
});

export const getIsDocumentDeliveryNotificationSaving = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isDocumentDeliveryNotificationSaving,
);

export const getisDocumentDeliveryNotificationFail = createSelector(
  getPartyState,
  (partyState: IPartyState) => partyState.isDocumentDeliveryNotificationFail,
);
