import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { Address as AddressIcon } from '~/common/svg';
import i18n from '~/app/i18n';
import { isCurrentIsoLangEN, isFieldEmptyOrNull } from '~/common/helpers';
import { SectionHeaderLabel, PositionItemValue, StyledContainer } from '~/common/components/position-overview/PositionOverviewPage.style';
import { Typography } from '@mui/material';

interface IFundManagerDataProps {
  fundData: any
}
export const FundManagers: React.FunctionComponent<IFundManagerDataProps> = (props) => (
  <StyledContainer>
    {!props.fundData.profile.address && (!props.fundData.profile.managers || props.fundData.profile.managers.length === 0) ?
      <SectionHeaderLabel>{i18n.t('stockOverview.noData') as string}</SectionHeaderLabel>
      :
      <Grid fluid style={{ width: '100%', height: '100%', padding: 8, margin: 0 }}>
        <Row>
          <SectionHeaderLabel>
            <Typography variant="h3" component="div">
              {i18n.t('stockOverview.fund.contactInformation') as string}
            </Typography>
          </SectionHeaderLabel>
        </Row>
        <Row style={{ paddingTop: 20, paddingBottom: 15 }}>
          <Col xs={1}>
            {AddressIcon(30)}
          </Col>
          <Col xs={6}>
            {props.fundData.profile.address ? props.fundData.profile.address : <a style={{ textDecoration: 'none' }}>-</a>}
          </Col>
        </Row>
        {
          props.fundData.profile.managers.map((fundItem:any) => (
          <Row style={{ paddingTop: 15, paddingBottom: 15, borderTop: 'solid 1px #e8e8e8' }}>
            <Col xs={3}>
              <PositionItemValue>{fundItem.name}</PositionItemValue>
              <p>{i18n.t('stockOverview.fund.managerStartDate')}<br />
                {isFieldEmptyOrNull(fundItem.startDate) ? <a style={{ textDecoration: 'none' }}>-</a> : fundItem.startDate}
              </p>
            </Col>
            <Col xs={9}>
              {isCurrentIsoLangEN() ? fundItem.biographyEn : fundItem.biographyFr}
            </Col>
          </Row>
          ))
        }
      </Grid>}
  </StyledContainer>
);
export default FundManagers;
