import { IAssetsAllocationItem, IAssetAllocationPosition } from '~/stores/account/accountTypes';
import { AssetAllocationCategoryType } from './types';

export interface IAssetAllocationMix {
    code: string,
    description: string | React.ReactNode,
    marketValue: number,
    allocationPourcentage: number,
    color: string,
    positions: IAssetAllocationPosition[],
    cashBalanceValueCad?: number
}

export const BuildAssetsMix = (filteredAccountIds: string[], assetsAllocation: IAssetsAllocationItem[], accountIds: string[]): IAssetAllocationMix[] => {
  const filteredAssetsAllocation = getFilteredAssetsAllocation(assetsAllocation, filteredAccountIds, accountIds);
  return GetUniqueCategories(filteredAssetsAllocation);
};

export const getNegativeAssetPositions = (assetsAllocation: IAssetsAllocationItem[], filteredAccountIds: string[], accountIds: string[]): IAssetAllocationPosition[] => {
  const filteredAssetsAllocation = getFilteredAssetsAllocation(assetsAllocation, filteredAccountIds, accountIds);
  const assetPositions = filteredAssetsAllocation.reduce((a, b) => a.concat(b.positions), [] as IAssetAllocationPosition[]);
  let negativePositions = assetPositions.filter((p) => p.assetAllocationMarketPriceCad < 0);
  negativePositions = removeNegativeFundRatioPosition(negativePositions);
  return consolidateAssetPositionsBySecurity(negativePositions);
};

export const GetDonutData = (assetAllocData: IAssetAllocationMix[]): any[] => assetAllocData.filter((f) => f.marketValue > 0).map((d) => ({ name: d.code, value: Math.trunc(d.marketValue), fill: d.color, percentage: Math.round(d.allocationPourcentage * 10) / 10 }));

export const HideAssetAllocationPieChart = (assetAllocData: IAssetAllocationMix[]): boolean => assetAllocData.filter((f) => f.marketValue < 0).length > 0;

const GetUniqueCategories = (assets: IAssetsAllocationItem[]): IAssetAllocationMix[] => {
  const output: IAssetAllocationMix[] = [];

  const totalMarketValue: number = getPositiveConsolidateAssetPositions(assets).reduce((a, b) => a + b.assetAllocationMarketPriceCad, 0);

  const assetCategories = [AssetAllocationCategoryType.CASHANDEQUIVALENTS, AssetAllocationCategoryType.FIXEDINCOMESECURITIES, AssetAllocationCategoryType.EQUITIES, AssetAllocationCategoryType.OTHERASSETS];
  assetCategories.forEach((cat) => {
    const categoryPositions = getPositiveConsolidateAssetPositionsByCategory(assets, cat);
    const categoryMarketValue = categoryPositions.reduce((a, b) => a + b.assetAllocationMarketPriceCad, 0);

    output.push({
      code: cat,
      description: getCategoryDescription(cat),
      color: getCategoryColor(cat),
      marketValue: categoryMarketValue,
      allocationPourcentage: totalMarketValue ? (categoryMarketValue * 100) / totalMarketValue : 0,
      positions: categoryPositions,
    });
  });

  return output;
};

const getPositiveConsolidateAssetPositions = (assets: IAssetsAllocationItem[]): IAssetAllocationPosition[] => {
  const assetPositions = assets.reduce((a, b) => a.concat(b.positions), [] as IAssetAllocationPosition[]);
  const positivePositions = assetPositions.filter((p) => p.assetAllocationMarketPriceCad >= 0);
  return consolidateAssetPositionsBySecurity(positivePositions);
};

const getPositiveConsolidateAssetPositionsByCategory = (assets: IAssetsAllocationItem[], categoryCode: string): IAssetAllocationPosition[] => {
  const assetPositions = assets.filter((a) => a.category === categoryCode).reduce((a, b) => a.concat(b.positions), [] as IAssetAllocationPosition[]);
  const positivePositions = assetPositions.filter((p) => p.assetAllocationMarketPriceCad >= 0);
  const negativeFundRatioPosition = GetNegativeFundRatioPosition(assetPositions);
  const positions = positivePositions.concat(negativeFundRatioPosition);
  return consolidateAssetPositionsBySecurity(positions);
};

const GetNegativeFundRatioPosition = (assetAllocationPosition: IAssetAllocationPosition[]): IAssetAllocationPosition[] => assetAllocationPosition.filter((p) => p.mutualFundRatio && p.mutualFundRatio < 0);

const removeNegativeFundRatioPosition = (assetAllocationPosition: IAssetAllocationPosition[]): IAssetAllocationPosition[] => assetAllocationPosition.filter((p) => (!p.mutualFundRatio) || p.mutualFundRatio > 0);

const getFilteredAssetsAllocation = (assetsAllocation: IAssetsAllocationItem[], filteredAccountIds: string[], accountIds: string[]): IAssetsAllocationItem[] => {
  const selectedAccountIds = filteredAccountIds.length > 0 ? filteredAccountIds : accountIds;
  return assetsAllocation.filter((a) => selectedAccountIds.indexOf(a.accountId) >= 0);
};

const consolidateAssetPositionsBySecurity = (positions: IAssetAllocationPosition[]): IAssetAllocationPosition[] => {
  const positionsConsolidated: IAssetAllocationPosition[] = [];

  const duplicatedSecurity: string[] = [];
  positions.forEach((p) => {
    if (duplicatedSecurity.indexOf(p.security) === -1) {
      const positionDuplicated = positions.filter((po) => po.security === p.security);
      if (positionDuplicated.length > 1) {
        duplicatedSecurity.push(p.security);

        const newPositionValue = { ...p };
        newPositionValue.assetAllocationMarketPriceCad = positionDuplicated.reduce((posDuplicated, current) => posDuplicated + current.assetAllocationMarketPriceCad, 0);
        positionsConsolidated.push(newPositionValue);
      } else {
        positionsConsolidated.push(p);
      }
    }
  });
  return positionsConsolidated;
};

const getCategoryDescription = (categoryCode: string) => {
  switch (categoryCode.toUpperCase()) {
    case AssetAllocationCategoryType.CASHANDEQUIVALENTS:
      return 'cashAndEquivalents';
    case AssetAllocationCategoryType.FIXEDINCOMESECURITIES:
      return 'fixedIncomeSecurities';
    case AssetAllocationCategoryType.EQUITIES:
      return 'equities';
    case AssetAllocationCategoryType.OTHERASSETS:
      return 'otherAssets';
    default: return '';
  }
};

const getCategoryColor = (categoryCode: string) => {
  switch (categoryCode.toUpperCase()) {
    case AssetAllocationCategoryType.CASHANDEQUIVALENTS:
      return '#003DA5';
    case AssetAllocationCategoryType.FIXEDINCOMESECURITIES:
      return '#acafae';
    case AssetAllocationCategoryType.EQUITIES:
      return '#836c4b';
    case AssetAllocationCategoryType.OTHERASSETS:
      return '#000548';
    default: return '80DEEA';
  }
};
