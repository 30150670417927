import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorIcon from '@mui/icons-material/ReportRounded';
import Info from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Fade,
  Grid,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteNames } from '~/app/appTypes';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import Loading from '~/common/components/Loading/Loading';
import ModalSaveFail from '~/common/components/Modal/ModalSaveFail';
import ErrorContainer from '~/common/components/error-container/error-container';
import RadioControl from '~/common/components/radio/RadioControl';
import { BoxShadow } from '~/common/styles/baseStyle';
import Colors from '~/common/themes/colors';
import { KYCFormCompleted, KYCFormIgnoreSession, fetchKYCDetails, saveKYCDetails } from '~/stores/kyc/kycActions';
import { getKYCState } from '~/stores/kyc/kycSelectors';
import {
  ACCOUNT_SUBSECTION_ATTRIBUTE,
  IKYCState,
  KYCDetailsFormSchema,
  KYCDetailsFormSchemaType,
  KYCDetailsResponseSchema,
  KYCDetailsResponseType,
} from '~/stores/kyc/kycTypes';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { getIsFetchingPartyByUser, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import {
  AnswerError,
  ConfirmationIconStyle,
  InformationIconStyle,
  ConfirmContainer,
  StyledP,
  iaButtonOutlined,
  iaButtonStyle,
  Text,
  StepperText,
  ConfirmationTitle,
  LinkButton,
  CollapseIconStyle,
  QuestionTitle,
  DefinitionText,
} from './KYCPage.style';
import { LeavePagePrompt } from '~/common/components/leave-page-prompt/LeavePagePrompt';
import { getIsAccountsFetching } from '~/stores/account/selectors/accountSelectors';
import { GetIsStorageValueByKey } from '~/app/sessionManager';

export interface IPropsFromState {
  culture: string;
  isPartyFetching: boolean;
  isAccountFetching: boolean;
  party: IPartyV1;
  kycState: IKYCState;
  isMirrorViewMode: boolean;
}

export interface IPropsFromDispatch {
  saveKYCDetails: typeof saveKYCDetails;
  KYCFormCompleted: typeof KYCFormCompleted;
  fetchKYCDetails: typeof fetchKYCDetails;
  KYCFormIgnoreSession: typeof KYCFormIgnoreSession;
}

interface IKYCPageProps {
  history: any;
}

const scrollToTop = (ref?: HTMLElement) => {
  window.scrollTo({
    top: ref?.offsetTop || 0,
    left: 0,
    behavior: 'smooth',
  });
};

const KYCPage: React.FC<IKYCPageProps & IPropsFromDispatch & IPropsFromState> = (props) => {
  const redirectToHome = () => {
    props.history.push(RouteNames.landing);
  };

  const isLoading =
    props.isPartyFetching ||
    props.isAccountFetching ||
    props.kycState.showPromptFetching ||
    props.kycState.detailsFetching;

  const [selectedSection, setSelectedSection] = useState(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean | undefined>(undefined);
  const shouldBlock = props.kycState.isSavingKYCDetails || props.kycState.show || props.kycState.saveKYCDetailsFail;

  React.useEffect(() => {
    scrollToTop();
  }, [selectedSection]);

  const kycs = props.kycState.reviewKYCDetails;
  const parsedKyc = KYCDetailsResponseSchema.safeParse(kycs);
  const emptyKYC = {} as KYCDetailsResponseType;
  const kycDefaultFormData = parsedKyc.success ? parsedKyc.data : emptyKYC;

  const { control, setValue, handleSubmit, trigger, getFieldState, formState, reset } = useForm({
    resolver: zodResolver(KYCDetailsFormSchema),
    defaultValues: kycDefaultFormData,
  });

  React.useEffect(() => {
    // When user join this page and the KYC details hasn't finished yet the load,
    // then we'll need to reset the default values as this is cached by RHF
    reset(kycDefaultFormData); // update your defaultValues && form values
  }, [props.kycState.reviewKYCDetails]);

  const { fields: fieldsForSections } = useFieldArray({
    control,
    name: 'sections',
  });

  if (isLoading) {
    return <Loading show />;
  }

  const onSubmit: SubmitHandler<KYCDetailsFormSchemaType> = (data) => {
    setShowFeedbackPopup(true);
    props.saveKYCDetails({ reviewIds: props.kycState.reviewIds, sections: data.sections });
  };

  const handlePageChangeRequest = async (action: 'next' | 'back') => {
    let isSectionValid = true;

    if (action === 'next') {
      isSectionValid = await trigger(`sections.${selectedSection}`);
    }
    if (isSectionValid) {
      const newPage = selectedSection + (action === 'next' ? 1 : -1);
      setSelectedSection(newPage);
    } else {
      trigger(`sections.${selectedSection}`);
    }
  };

  const handleCloseSuccess = () => {
    setShowFeedbackPopup(false);
    props.KYCFormCompleted();
    redirectToHome();
  };

  const handleBackHome = () => {
    props.KYCFormIgnoreSession();
    redirectToHome();
  };

  const handleCloseErrorPopup = () => {
    setShowFeedbackPopup(false);
  };

  const splitSubSectionText = ({
    title,
    attribute,
    identifier,
  }: {
    title: string;
    attribute: string;
    identifier: string | null | undefined;
  }): string[] => {
    /**
     * Breaks into two parts when Subsection is Account Info to allow better formatting
     */
    const isAccountSubSection = attribute === ACCOUNT_SUBSECTION_ATTRIBUTE;
    if (!isAccountSubSection || !identifier || !title.includes(identifier)) {
      return [title];
    }
    const splitted = title.split(identifier);
    return [`${splitted[0]}${identifier}`, splitted[1]];
  };

  const getAnswerText = (answer: unknown): string => (!answer ? i18n.t('KYC.notAvailable') : `${answer}`);

  const isAnswerStringOrEmpty = (answer: unknown) => typeof answer === 'string' || !answer;

  const activeSection = selectedSection + 1;

  const isKycSavedSuccessfully = !!GetIsStorageValueByKey('isKYCSavedSuccessfully');
  const isKycDetailsNoLongerNeeded = !!GetIsStorageValueByKey('isKYCDetailsNoLongerNeeded');
  // Using permanent storage to check if the KYC details were saved
  //   successfully (Redux state is reset on page refresh)
  const shouldShowSuccessfulBanner = !!isKycSavedSuccessfully;
  if (shouldShowSuccessfulBanner) {
    if (isKycDetailsNoLongerNeeded) {
      return (
        <Grid container justifyContent="center" padding={10} minHeight="auto">
          <Grid item xs={8}>
            <BoxShadow style={{ padding: '24px' }}>
              <ConfirmContainer>
                <StyledP>
                  <Info style={InformationIconStyle} />
                </StyledP>
                <Stack marginY={2}>
                  <ConfirmationTitle>
                    <Trans i18nKey="KYC.questionnaireWithdraw" />
                  </ConfirmationTitle>
                </Stack>
                <Stack marginY={2}>
                  <Typography component="span">
                    <Trans i18nKey="KYC.questionnaireWithdrawDescription" />
                  </Typography>
                </Stack>
                <StyledP style={{ marginTop: '12px' }}>
                  <Button variant="contained" color="secondary" onClick={handleCloseSuccess}>
                    <Trans i18nKey="KYC.goToOverview" />
                  </Button>
                </StyledP>
              </ConfirmContainer>
            </BoxShadow>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="center" padding={10} minHeight="auto">
        <Grid item xs={8}>
          <BoxShadow style={{ padding: '24px' }}>
            <ConfirmContainer>
              <StyledP>
                <CheckCircleIcon style={ConfirmationIconStyle} />
              </StyledP>
              <Stack marginY={2}>
                <ConfirmationTitle>
                  <Trans i18nKey="KYC.saveSuccess" />
                </ConfirmationTitle>
              </Stack>
              <Stack marginY={2}>
                <Typography component="span">
                  <Trans i18nKey="KYC.saveSuccessDescription" />
                </Typography>
              </Stack>
              <StyledP style={{ marginTop: '12px' }}>
                <Button variant="contained" color="secondary" onClick={handleCloseSuccess}>
                  <Trans i18nKey="common.close" />
                </Button>
              </StyledP>
            </ConfirmContainer>
          </BoxShadow>
        </Grid>
      </Grid>
    );
  }

  if (props.kycState.reviewIds.length === 0) {
    redirectToHome();
  }

  if (props.kycState.detailsFail || !parsedKyc.success) {
    return (
      <Box m="auto">
        <Container maxWidth="lg">
          <Grid gap={5} margin={5}>
            <Grid item xs={12}>
              <Grid item xs={12} style={{ paddingBottom: '12px' }}>
                <Text variant="lg">
                  <Trans i18nKey="KYC.header" />
                </Text>
              </Grid>
              <Grid item xs={12}>
                {' '}
                <ErrorContainer title="KYC.technicalIssue" text="KYC.technicalIssueDetail">
                  <Stack direction="row" padding={2} paddingLeft="51px" gap={2}>
                    <Button
                      key="next"
                      style={iaButtonStyle('md')}
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={() => props.fetchKYCDetails(props.kycState.reviewIds)}
                    >
                      <span style={{ fontSize: '14px' }}>
                        <Trans i18nKey="KYC.retry" />
                      </span>
                    </Button>
                    <Button
                      key="submit"
                      type="submit"
                      size="large"
                      style={iaButtonOutlined('xl')}
                      variant="outlined"
                      onClick={handleBackHome}
                      color="secondary"
                    >
                      <Trans i18nKey="KYC.backHomePage" />
                    </Button>
                  </Stack>
                </ErrorContainer>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  const isSectionInvalid = Object.keys(formState.errors.sections || {}).includes(`${selectedSection}`);

  return (
    <>
      {shouldBlock && <LeavePagePrompt shouldBlock={shouldBlock} message={i18n.t('KYC.exitPromptMessage')} />}
      <ModalSaveFail
        onClose={handleCloseErrorPopup}
        isVisible={!!props.kycState.saveKYCDetailsFail && !!showFeedbackPopup}
        message={i18n.t('KYC.saveFailure')}
      />

      <Box m="auto">
        <Container maxWidth="lg">
          <Grid gap={5} margin={5}>
            <Grid item xs={12} paddingBottom={2}>
              <Text variant="lg">
                <Trans i18nKey="KYC.header" />
              </Text>
            </Grid>
            {isSectionInvalid && (
              <Grid item xs={12} paddingBottom={1}>
                <ErrorContainer title="KYC.missingFields" text="KYC.ensureAllQuestionsAnswered" />
              </Grid>
            )}
            <Grid item xs={12} paddingBottom={1}>
              <Text variant="md">{`${props.party.firstName} ${props.party.lastName}`}</Text>
            </Grid>
            <Grid item xs={12} paddingBottom={1}>
              <Typography component="span">
                <Trans i18nKey="KYC.description" />
              </Typography>
            </Grid>

            <Grid container paddingBottom={2}>
              <Grid item xs={12} paddingBottom={0.5}>
                <StepperText>
                  {i18n.t('KYC.sectionStepper', { 0: selectedSection + 1, 1: kycs?.sections.length }) as string}
                </StepperText>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  {kycs?.sections.map((_, sectionIndex) => (
                    <Box
                      key={sectionIndex}
                      sx={{
                        flexGrow: 1,
                        height: '5px',
                        backgroundColor: activeSection >= sectionIndex + 1 ? 'rgb(0, 61, 165)' : Colors.iaGray,
                      }}
                    >
                      &nbsp;
                    </Box>
                  ))}
                </Stack>
              </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
              {fieldsForSections.map((section, sectionIndex) => (
                <Grid
                  item
                  xs={12}
                  key={`${section.title}${section.attribute}`}
                  style={{ display: selectedSection !== sectionIndex ? 'none' : 'block' }}
                >
                  <Grid item xs={12}>
                    <Text variant="md">{`${section.title}`}</Text>
                  </Grid>
                  {section.subSections.map((subSection, subSectionIndex) => (
                    <div key={`${subSection.title}${subSection.attribute}${subSection.identifier}`}>
                      <Grid item xs={12} paddingBottom={1} paddingTop={4}>
                        {splitSubSectionText({
                          title: subSection.title,
                          attribute: subSection.attribute,
                          identifier: subSection.identifier,
                        }).map((title, index, array) => (
                          <Text
                            variant="sm"
                            fontWeight={array.length > 1 && index >= 1 ? '400' : undefined}
                          >{`${title}`}</Text>
                        ))}
                      </Grid>
                      <BoxShadow>
                        {subSection.questionnaires.map((questionnaire, questionnaireIndex) => (
                          <div
                            key={`${questionnaire.title}${subSection.title}${subSection.attribute}${subSection.identifier}`}
                          >
                            <Grid container padding={2} paddingRight={0}>
                              <Grid item xs={8}>
                                <Text variant="xs">{questionnaire.title}</Text>
                              </Grid>
                              <Grid item xs={4} textAlign="right" paddingRight={2}>
                                <Button
                                  key={`nothingHasChanged-${questionnaire.title}${subSection.title}${subSection.attribute}${subSection.identifier}`}
                                  size="small"
                                  style={iaButtonStyle('md')}
                                  variant="contained"
                                  color="secondary"
                                  sx={{ width: '180px', height: '40px' }}
                                  onClick={() => {
                                    questionnaire.questions.forEach((_, index) => {
                                      setValue(
                                        `sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${index}.hasChanged`,
                                        'false',
                                        {
                                          shouldDirty: true,
                                        },
                                      );
                                    });
                                    if (getFieldState(`sections.${sectionIndex}`).invalid) {
                                      trigger(`sections.${sectionIndex}`);
                                    }
                                  }}
                                >
                                  <Trans i18nKey="KYC.nothingHasChanged" />
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                paddingTop={2}
                                marginRight={isAnswerStringOrEmpty(questionnaire.questions[0].answer) ? 0 : 2}
                              >
                                {questionnaire.questions.map((question, questionIndex) => (
                                  <React.Fragment
                                    key={`${subSection.attribute}${subSection.title}${question.attribute}`}
                                  >
                                    {!isAnswerStringOrEmpty(question.answer) && (
                                      <Grid xs={12} item paddingTop={questionIndex !== 0 ? 3 : 0}>
                                        <QuestionTitle>{question.title}</QuestionTitle>
                                      </Grid>
                                    )}
                                    <Grid
                                      container
                                      paddingY={isAnswerStringOrEmpty(question.answer) ? 0 : 2}
                                      paddingLeft={isAnswerStringOrEmpty(question.answer) ? 0 : 2}
                                      paddingRight={isAnswerStringOrEmpty(question.answer) ? 0 : 0}
                                      sx={{
                                        border: !isAnswerStringOrEmpty(question.answer) ? '1px solid #dadad8' : '',
                                      }}
                                    >
                                      <Grid item xs={7}>
                                        {isAnswerStringOrEmpty(question.answer) && (
                                          <Stack direction="column" gap={1}>
                                            <QuestionTitle>{question.title}</QuestionTitle>
                                            <Typography component="span">{getAnswerText(question.answer)}</Typography>
                                          </Stack>
                                        )}
                                        {!isAnswerStringOrEmpty(question.answer) && (
                                          <>
                                            <Grid container border="thin">
                                              {question.answer &&
                                                Object.keys(question.answer).map((answerKey) => (
                                                  <Grid
                                                    key={`${answerKey}${subSection.attribute}${subSection.title}${question.attribute}`}
                                                    item
                                                    xs={
                                                      question.answer && Object.keys(question.answer).length === 1
                                                        ? 12
                                                        : 3
                                                    }
                                                  >
                                                    <Stack direction="column" gap={1}>
                                                      <Text variant="xs">{answerKey}</Text>
                                                      <Typography component="span">
                                                        {getAnswerText((question.answer as any)[`${answerKey}`])}
                                                      </Typography>
                                                    </Stack>
                                                  </Grid>
                                                ))}
                                            </Grid>
                                          </>
                                        )}
                                        {getFieldState(
                                          `sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}`,
                                        )?.invalid && (
                                          <Stack direction="row" alignItems="flex-start" sx={{ marginY: 1 }}>
                                            <ErrorIcon
                                              fontSize="small"
                                              sx={{ color: 'rgb(231, 19, 19)', marginRight: 1 }}
                                            />

                                            <AnswerError>
                                              <Trans i18nKey="KYC.selectAnAnswer"></Trans>
                                            </AnswerError>
                                          </Stack>
                                        )}
                                      </Grid>
                                      <Grid item xs={5} justifyContent="flex-end">
                                        <Controller
                                          name={`sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}.hasChanged`}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                            <RadioGroup
                                              color="secondary"
                                              sx={{ justifyContent: 'flex-end' }}
                                              row
                                              value={value}
                                              onChange={onChange}
                                            >
                                              <RadioControl
                                                labelKey="KYC.changesNeeded"
                                                value="true"
                                                variant="blue"
                                                disabled={props.isMirrorViewMode || props.kycState.isSavingKYCDetails}
                                                selected={value === 'true'}
                                                invalid={
                                                  getFieldState(
                                                    `sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}`,
                                                  )?.invalid
                                                }
                                              />
                                              <RadioControl
                                                value="false"
                                                variant="blue"
                                                selected={value === 'false'}
                                                labelKey="KYC.noChanges"
                                                disabled={props.isMirrorViewMode || props.kycState.isSavingKYCDetails}
                                                invalid={
                                                  getFieldState(
                                                    `sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}`,
                                                  )?.invalid
                                                }
                                              />
                                            </RadioGroup>
                                          )}
                                        />
                                      </Grid>
                                      {!!question.definitions?.length && (
                                        <>
                                          <Controller
                                            name={`sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}.isDefinitionCollapsed`}
                                            control={control}
                                            render={({ field: { value: isDefinitionCollapsed } }) => (
                                              <>
                                                {!isDefinitionCollapsed && (
                                                  <Grid item xs={12} paddingTop={2}>
                                                    <Fade in={!isDefinitionCollapsed}>
                                                      <Stack direction="column">
                                                        {question.definitions?.map((definition, definitionIndex) => (
                                                          <React.Fragment
                                                            key={`${sectionIndex}${subSectionIndex}${questionIndex}${definitionIndex}`}
                                                          >
                                                            <Text variant="xs">{definition?.title}</Text>
                                                            <Typography component="span">
                                                              {definition?.content}
                                                            </Typography>
                                                          </React.Fragment>
                                                        ))}
                                                      </Stack>
                                                    </Fade>
                                                  </Grid>
                                                )}
                                                <Grid item xs={12} paddingTop={1}>
                                                  <LinkButton
                                                    onClick={() => {
                                                      setValue(
                                                        `sections.${sectionIndex}.subSections.${subSectionIndex}.questionnaires.${questionnaireIndex}.questions.${questionIndex}.isDefinitionCollapsed`,
                                                        !isDefinitionCollapsed,
                                                      );
                                                    }}
                                                  >
                                                    <Stack direction="row" alignItems="flex-end" paddingRight={2}>
                                                      <DefinitionText>
                                                        <Trans
                                                          i18nKey={`KYC.${
                                                            !isDefinitionCollapsed ? 'hide' : 'show'
                                                          }Definitions`}
                                                        />
                                                      </DefinitionText>
                                                      {!isDefinitionCollapsed ? (
                                                        <KeyboardArrowUpIcon style={CollapseIconStyle} />
                                                      ) : (
                                                        <KeyboardArrowDownIcon style={CollapseIconStyle} />
                                                      )}
                                                    </Stack>
                                                  </LinkButton>
                                                </Grid>
                                              </>
                                            )}
                                          />
                                        </>
                                      )}
                                      {isAnswerStringOrEmpty(question.answer) &&
                                        questionnaire.questions.length - 1 !== questionIndex && (
                                          <Grid item xs={12} paddingY={2} marginRight={2}>
                                            <Divider sx={{ borderColor: 'rgb(218, 218, 216)' }} variant="fullWidth" />
                                          </Grid>
                                        )}
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      </BoxShadow>
                    </div>
                  ))}
                </Grid>
              ))}

              <Stack direction="row" paddingY={5} gap={2}>
                <Button
                  key="next"
                  style={iaButtonStyle('md')}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => handlePageChangeRequest('next')}
                  disabled={formState.isSubmitting || props.kycState.isSavingKYCDetails}
                  hidden={selectedSection === (kycs?.sections?.length || 1) - 1}
                >
                  <span style={{ fontSize: '14px' }}>
                    <Trans i18nKey="common.next" />
                  </span>
                </Button>
                <Button
                  disabled={props.isMirrorViewMode || formState.isSubmitting || props.kycState.isSavingKYCDetails}
                  key="submit"
                  type="submit"
                  size="large"
                  style={iaButtonStyle('md')}
                  variant="contained"
                  color="secondary"
                  hidden={selectedSection !== (kycs?.sections?.length || 1) - 1}
                >
                  {props.kycState.isSavingKYCDetails && (
                    <CircularProgress size={24} sx={{ color: Colors.mariner, marginRight: '12px' }} />
                  )}
                  <Trans i18nKey="KYC.submit" />
                </Button>
                <Button
                  disabled={formState.isSubmitting || props.kycState.isSavingKYCDetails}
                  key="back"
                  size="large"
                  style={iaButtonStyle('md')}
                  variant="outlined"
                  color="secondary"
                  hidden={selectedSection === 0}
                  onClick={() => handlePageChangeRequest('back')}
                >
                  <Trans i18nKey="common.back" />
                </Button>
                <Button
                  key="cancel"
                  size="large"
                  style={iaButtonStyle('md')}
                  variant="outlined"
                  disabled={formState.isSubmitting || props.kycState.isSavingKYCDetails}
                  color="secondary"
                  hidden={selectedSection !== 0}
                  onClick={redirectToHome}
                >
                  <Trans i18nKey="common.cancel" />
                </Button>
              </Stack>
            </form>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
    isPartyFetching: getIsFetchingPartyByUser(state),
    isAccountFetching: getIsAccountsFetching(state),
    party: getPartyV1(state),
    kycState: getKYCState(state),
    isMirrorViewMode: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveKYCDetails,
      KYCFormCompleted,
      fetchKYCDetails,
      KYCFormIgnoreSession,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KYCPage);
