import { IAdvisor } from './interfaces/advisor/Advisor';
import { AdvisorPartyTypeCode } from './interfaces/advisor/AdvisorPartyTypeCode';
import { IRelationship } from './interfaces/advisor/Relationship';
import { RelationshipTypeCode } from './interfaces/advisor/RelationshipTypeCode';
import { IRepCode } from './interfaces/advisor/RepCode';
import { ITeam } from './interfaces/advisor/Team';
import { ITeamByRepCode } from './interfaces/advisor/TeamByRepCode';
import { AdvisorHelper } from './utils/AdvisorHelper';

export const mapAdvisorsToTeams = (financialProfessionals: IAdvisor[]): ITeamByRepCode => {
  const teamByRepCodes: { [repcode: string]: ITeam } = {};
  const advisors = getDistinctAdvisor(financialProfessionals.filter((a: IAdvisor) => a && a.partyTypeCode === AdvisorPartyTypeCode.Advisor));
  advisors.forEach((advisor: IAdvisor) => {
    const assistants: IAdvisor[] = advisor.relationships.filter((r: IRelationship) => r.relationshipTypeCode === RelationshipTypeCode.Assistant).map((r: IRelationship) => r.relationParty);

    advisor.repCodes.forEach((repCode: IRepCode) => {
      const { code } = repCode;
      createTeamIfNotExist(teamByRepCodes, code);
      teamByRepCodes[code].Advisors.push(advisor);
      teamByRepCodes[code].Assistants = teamByRepCodes[code].Assistants.concat(assistants);
    });
  });

  const branches = financialProfessionals.filter((a: IAdvisor) => a && a.partyTypeCode === AdvisorPartyTypeCode.Branch);
  branches.forEach((branch: IAdvisor) => {
    branch.repCodes.forEach((repCode: IRepCode) => {
      const { code } = repCode;
      createTeamIfNotExist(teamByRepCodes, code);
      teamByRepCodes[code].Branches.push(branch);
    });
  });

  for (const key of Object.keys(teamByRepCodes)) {
    const advisorsSorted = getDistinctAdvisor(teamByRepCodes[key].Advisors).sort(AdvisorHelper.compareByRank);
    const branchesSorted = getDistinctAdvisor(teamByRepCodes[key].Branches).sort(AdvisorHelper.compareByRank);
    const assistantsSorted = getDistinctAdvisor(teamByRepCodes[key].Assistants).sort(AdvisorHelper.compareByRank);

    if (branchesSorted.length > 0) {
      const branchId = branchesSorted[0].id;
      const assistantsSortedFiltered = assistantsSorted.filter((assistant: IAdvisor) => assistant.relationships.find((relation: IRelationship) => relation.relationPartyId === branchId));

      teamByRepCodes[key].Advisors = advisorsSorted;
      teamByRepCodes[key].Branches = branchesSorted;
      teamByRepCodes[key].Assistants = assistantsSortedFiltered.slice(0, 3);
    } else {
      teamByRepCodes[key].Advisors = [];
      teamByRepCodes[key].Branches = [];
      teamByRepCodes[key].Assistants = [];
    }
  }

  return teamByRepCodes;
};

export const filterTeamWithPrimaryEmail = (team: ITeam): ITeam => {
  team.Advisors = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Advisors);
  team.Assistants = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Assistants);
  team.Branches = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Branches);

  return team;
};

function getDistinctAdvisor(advisors: IAdvisor[]): IAdvisor[] {
  const uniqueAdvisors: IAdvisor[] = [];
  advisors.forEach((advisor: IAdvisor) => {
    if (uniqueAdvisors.find((a) => a.firstName === advisor.firstName && a.lastName === advisor.lastName) === undefined) {
      uniqueAdvisors.push(advisor);
    }
  });
  return uniqueAdvisors;
}

function createTeamIfNotExist(teamByRepCodes: ITeamByRepCode, code: string) {
  if (!teamByRepCodes[code]) {
    teamByRepCodes[code] = {
      Advisors: [],
      Assistants: [],
      Branches: [],
      RepCode: code,
    };
  }
}
