import { IDocument } from '~/stores/account/accountTypes';
import { MessageActionTypes } from '~/stores/message/messageTypes';
import { action } from 'typesafe-actions';
import { IMessage } from '../interfaces/Message';

export const sendMessage = (clientId: string, recipientId: string, recipients: string[] = [], attachments?: File[]) => action(MessageActionTypes.SEND_MESSAGE, { clientId, recipientId, recipients, attachments });
export const sendMessageSuccess = (message: IMessage) => action(MessageActionTypes.SEND_MESSAGE_SUCCESS, message);
export const sendMessageSuccessWithWarning = (message: IMessage, warning: any) => action(MessageActionTypes.SEND_MESSAGE_SUCCESS_WITH_WARNING, { message, warning });
export const sendMessageError = (error: any) => action(MessageActionTypes.SEND_MESSAGE_ERROR, error);
export const resetMessage = () => action(MessageActionTypes.RESET_MESSAGE);

export const fetchSharedDocuments = (partyId: string) => action(MessageActionTypes.FETCH_DOCUMENTS, { partyId });
export const fetchSharedDocumentsSuccessful = (documents: IDocument[]) => action(MessageActionTypes.FETCH_DOCUMENTS_SUCCESS, documents);
export const fetchSharedDocumentsError = () => action(MessageActionTypes.FETCH_DOCUMENTS_ERROR);
