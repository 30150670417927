import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import i18n from '~/app/i18n';
import {
  ContainerRow,
  DataLoadStampContainer,
  LoadStampContainer,
  IconButtonWithLeftPadding,
} from '~/common/styles/baseStyle';
import { IClientAccountDictionary } from '~/stores/account/accountTypes';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { MonetaryValue } from '~/common/components';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import { Tooltip, Stack } from '@mui/material';
import {
  getIsMarketDataFetching,
  getIsMarketDataFetchingHasError,
  getClientAccountsFromState,
} from '~/stores/account/selectors/accountSelectors';
import { fetchMarketData } from '~/stores/account/actions/accountActions';
import styled from 'styled-components';
import { TotalLabel, Total, ChangeLabel } from './LandingPage.style';
import Loading from '../../common/components/Loading/Loading';
import { TotalMarketSectionSkeleton } from './LandingSkeleton';
import { MARKET_SECTION_HEIGHT } from './LandingPage';
import Colors from '~/common/themes/colors';
import Info from '@mui/icons-material/Info';
import IconTooltip from '~/common/components/icon-tooltip/IconTooltip';
import { UpdateDataButton } from '~/common/components/update-data-button/UpdateDataButton';

export interface IPropsFromState {
  didSetMarketDataRealtime: boolean;
  isFetchingMarketData: boolean;
  isFetchingMarketDataHasError: boolean;
  clientAccounts: IClientAccountDictionary;
  culture: string;
}
const ToolTipContainer = styled.div<{ color?: string; font?: string }>`
  font-size: 12px;
`;
export interface IPropsFromDispatch {
  fetchMarketData: typeof fetchMarketData;
}
const OverviewContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export class ClientOverview extends React.Component<IPropsFromState & IPropsFromDispatch> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.state = {};
  }

  private refreshMarket = (_: any) => {
    this.props.fetchMarketData();
  };

  public render() {
    const { clientAccounts, culture, didSetMarketDataRealtime } = this.props;
    const shouldNotShowMarketDataUpdateButton = this.props.didSetMarketDataRealtime && !clientAccounts.isLiveData;

    if (didSetMarketDataRealtime && this.props.isFetchingMarketData) {
      return (
        <OverviewContainer style={{ height: MARKET_SECTION_HEIGHT }}>
          <Stack direction="column" height="100%" p="20px" pt="17px" sx={{ bgcolor: Colors.quillGray }}>
            <TotalMarketSectionSkeleton />
          </Stack>
        </OverviewContainer>
      );
    }
    return (
      <>
        {didSetMarketDataRealtime ? (
          <div className="boxGrey">
            <OverviewContainer>
              <TotalLabel>{i18n.t('common.totalMarketValue') as string}</TotalLabel>
              <Total>
                <MonetaryValue
                  value={clientAccounts.totalMarketValue}
                  culture={culture}
                  currency="CAD"
                  alignRight={false}
                  fixCurrency
                />
              </Total>
              <TotalLabel topPadding={15}>
                {i18n.t('landing.previousDaysChange') as string}{' '}
                <Tooltip
                  title={<ToolTipContainer>{i18n.t('landing.previousDaysChangeDesc') as string}</ToolTipContainer>}
                  placement="top"
                  arrow
                >
                  <IconButtonWithLeftPadding>
                    {' '}
                    <Info fontSize="small" style={{ color: '#2062D4' }} />
                  </IconButtonWithLeftPadding>
                </Tooltip>
              </TotalLabel>
              <ContainerRow style={{ paddingTop: 0, paddingBottom: 10, justifyContent: 'flex-start' }}>
                <ChangeValue
                  value={clientAccounts.previousDayChangeValue}
                  percent={clientAccounts.previousDayChangePercent}
                  fontSize="18px"
                  decimalOverrideForPercent={3}
                  notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
                  singleLineFormat
                  noArrow
                  noShowValuesSign={false}
                />
              </ContainerRow>
              <>
                {this.props.isFetchingMarketData ? (
                  <Loading show />
                ) : (
                  <>
                    <ChangeLabel>
                      {i18n.t('landing.intraDayChange.label') as string}
                      <span style={{ padding: 0, paddingLeft: '5px' }}>
                        <IconTooltip
                          icon={<Info fontSize="small" style={{ color: '#2062D4' }} />}
                          messageKey="landing.intraDayChange.tooltips"
                        />
                      </span>
                    </ChangeLabel>
                    <ContainerRow style={{ paddingTop: 5, paddingBottom: 30, justifyContent: 'flex-start' }}>
                      <ChangeValue
                        value={clientAccounts.daysChange}
                        percent={clientAccounts.daysChangePercent}
                        decimalOverrideForPercent={3}
                        fontSize="18px"
                        notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
                        singleLineFormat
                        noArrow
                        noShowValuesSign={false}
                      />
                    </ContainerRow>

                    <LoadStampContainer>
                      {!shouldNotShowMarketDataUpdateButton && (
                        <UpdateDataButton onClick={() => this.refreshMarket(null)} />
                      )}
                      <DataLoadStampContainer>
                        <DataLoadStamp
                          isPortalTimestamp
                          isLiveMarket
                          isLiveData={clientAccounts.isLiveData}
                          color="#1B1B1B"
                          font={"'OpenSans-Regular', 'Open Sans'"}
                        />
                      </DataLoadStampContainer>
                    </LoadStampContainer>
                  </>
                )}
              </>
            </OverviewContainer>
          </div>
        ) : (
          <div className="boxGrey">
            <OverviewContainer>
              <TotalLabel>{i18n.t('common.totalMarketValue') as string}</TotalLabel>
              <Total>
                <MonetaryValue
                  value={clientAccounts.totalMarketValue}
                  culture={culture}
                  currency="CAD"
                  alignRight={false}
                  fixCurrency
                />
              </Total>
              <LoadStampContainer>
                <DataLoadStampContainer>
                  <DataLoadStamp isPortalTimestamp color="#333333" font={"'OpenSans-Regular', 'Open Sans'"} />
                </DataLoadStampContainer>
              </LoadStampContainer>
            </OverviewContainer>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  const { system } = state;

  return {
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketData: getIsMarketDataFetching(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    clientAccounts: getClientAccountsFromState(state),
    culture: system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchMarketData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientOverview);
