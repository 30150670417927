import axios from 'axios';
import { ITransaction } from '~/stores/transaction/transactionTypes';
import { getCurrentIsoLang } from '~/common/helpers';
import { API_ACCOUNTS } from '~/common/API';

export const TransactionHttpClient = new class {
  public fetchByAccountIds = (accountIds: string[], startDate: string, endDate: string) => {
    const ids = `accountIds=${accountIds.join(',')}`;

    return axios.get(`${API_ACCOUNTS}/Transactions?${ids}&startTransactionDate=${startDate}&endTransactionDate=${endDate}&lang=${getCurrentIsoLang()}`)
      .then((response) => response.data.data as ITransaction[]);
  };
}();
