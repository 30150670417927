import axios from 'axios';
import {
  IPartySearchRequest,
  IPartyV1,
  IUpdateDeliveryPreferencesMetadata,
  IConsentPreferences,
  IConsentVerbiages,
  IConsentPromptMetadata,
  ISortViewsMetadata,
} from '~/stores/party/partyTypes';

import { API_PARTIES, API_CONSENT, CLIENT_PORTAL_SOURCE_KEY, IMPERSONATE } from '~/common/API';
import { IPatchJsonOperation } from '../../system/systemTypes';
import {
  UpdateEmailNotificationDocumentAvailableKey,
  UpdateDeliveryPreferences,
  UpdateMarketDataRealtimeKey,
  UpdateConsentPrompt,
  UpdateIntradayChangePreferenceKey,
  UpdateHoldingSortPreferences,
} from '../../../common/utils/MetadataHelper';

export const PartyHttpClient = new (class {
  public fetchPartial = async (accounts: string[], fields: any) => {
    const query = accounts.join(',');
    const response = await axios.get(`${API_PARTIES}?fields=Data(${fields})&accounts=${query}`);
    return response.data.data as IPartyV1[];
  };

  public fetchById = async (partyId: string) => {
    const fields =
      'Data(accounts,businessName,firstName,id,lastName,partyTypeCode,preferedAccountIds,metadata,languageCode,emails)';
    const response = await axios.get(`${API_PARTIES}/${partyId}?fields=${fields}&impersonate=${IMPERSONATE}`);
    return response.data.data as IPartyV1;
  };

  public fetchByToken = async () => {
    const fields =
      'Data(accounts,businessName,firstName,id,lastName,partyTypeCode,preferedAccountIds,metadata,languageCode,emails)';
    const response = await axios.get(`${API_PARTIES}/partyInfo?fields=${fields}`);
    return response.data.data as IPartyV1;
  };

  public fetchPartySearch = async (request: IPartySearchRequest) => {
    const requestProp = `firstName=${request.firstName.trim()}&lastName=${request.lastName.trim()}&householdKey=${request.householdKey.trim()}&fields=Data(id,lastName,firstName,accounts),total`;
    const response = await axios.get(`${API_PARTIES}?${requestProp}`);

    return response.data.data[0] as IPartyV1;
  };

  public updateShareAccount = async (id: string, data: IPatchJsonOperation[]) => {
    await this.patchParty(id, data);
  };

  public updatePreferredAccounts = async (id: string, data: IPartyV1) => {
    await this.patchParty(id, [{ op: 'replace', path: '/preferedAccountIds', value: data.preferedAccountIds }]);
  };

  public saveDocumentDeliveryNotification = async (party: IPartyV1, didConsent: boolean) => {
    const newMetadata = UpdateEmailNotificationDocumentAvailableKey(party.metadata, didConsent);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  public saveMarketDataRealtime = async (party: IPartyV1, realtime: boolean) => {
    const newMetadata = UpdateMarketDataRealtimeKey(party.metadata, realtime);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  public saveIntradayChangePreference = async (party: IPartyV1, realtime: boolean) => {
    const newMetadata = UpdateIntradayChangePreferenceKey(party.metadata, realtime);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  public saveUpdateDeliveryPreferencesMetadata = async (
    party: IPartyV1,
    metadata: IUpdateDeliveryPreferencesMetadata,
  ) => {
    const newMetadata = UpdateDeliveryPreferences(party.metadata, metadata);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  public saveConsentPromptMetadata = async (party: IPartyV1, metadata: IConsentPromptMetadata) => {
    const newMetadata = UpdateConsentPrompt(party.metadata, metadata);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  public saveHoldingSortPreferencesMetadata = async (
    party: IPartyV1,
    metadata: ISortViewsMetadata,
  ) => {
    const newMetadata = UpdateHoldingSortPreferences(party.metadata, metadata);
    await this.patchParty(party.id, [{ op: 'replace', path: '/metadata', value: newMetadata }]);
  };

  private patchParty = async (id: string, data: IPatchJsonOperation[]) => {
    await axios.patch(`${API_PARTIES}/${id}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  };

  public saveClientConsent = async (party: IPartyV1, consent: IConsentPreferences[]) => {
    const body = {
      consents: consent,
    };
    const headers = {
      'x-source': CLIENT_PORTAL_SOURCE_KEY,
    };
    const response = await axios.post(`${API_CONSENT}/${party.id}`, body, { headers });

    return response;
  };

  public fetchConsentVerbiage = async (party: IPartyV1) => {
    const response = await axios.get(`${API_CONSENT}/${party.id}?isLegacy=false`);
    return response.data.verbiages as IConsentVerbiages[];
  };
})();
