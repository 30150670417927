import { MainBox } from '~/common/styles/baseStyle';
import styled from 'styled-components';

export const SettingItemRow = styled.div`
      display: flex;
      margin-bottom: 20px;

      > div {
            display: flex;
            flex: 1;
            margin-right: 20px;
      }
`;

export const EmptyBox = styled.div`
      padding: 30px 20px;
`;

export const SettingBox = styled(MainBox)`
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      padding: 30px 20px;
`;

export const BackIconStyle = {
  marginRight: '3px',
  marginTop: '0px',
  fontSize: '18px',
};

export const SettingPageTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 38px 0px 20px 0px;    
`;

export const HeaderBackItem = styled.div`
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: normal;
      color: #003DA5;
      font-family: ${(props) => props.theme.fontBold};
      padding-bottom: 5px;
`;

export const HeaderItem = styled.div`
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 18px;
      line-height: normal;
      color: #003DA5;
      font-family: ${(props) => props.theme.fontBold};
      padding-bottom: 10px;
`;
