import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const NoteContainer = styled.div`
    background-Color: #888bce;
    color: #FFFFFF;
    align-items: center;
`;

export const VisibilityIconStyle = styled(VisibilityIcon)`
    font-size: 30px;
    padding: 5px 10px 0px 20px;
`;
