import * as React from 'react';
import styled from 'styled-components';
import PrintIcon from '@mui/icons-material/Print';
import { Trans } from 'react-i18next';
import LinkButton from '../link-button/LinkButton';
const DivWrapper = styled.div<{ marginY: number }>`
   {
    @media print {
      @page {
        size: landscape;
      }
    }
  }
  margin: ${(props) => props.marginY}px 0;
`;

interface IPrintProps {
  marginY?: number;
}

const PrintButton = ({ marginY }: IPrintProps) => {
  const handlePrintClick = () => {
    window.print();
  };

  return (
    <DivWrapper marginY={marginY === undefined ? 10 : marginY}>
      <LinkButton onClick={handlePrintClick} startIcon={<PrintIcon />}>
        <Trans i18nKey="common.print" />
      </LinkButton>
    </DivWrapper>
  );
};

export default PrintButton;
