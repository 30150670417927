import * as React from 'react';
import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import { appTheme } from '~/common/themes/theme';
import { DocumentDeliveryStyle as Style } from './styles';

interface IFooterProps {
  isDirty: boolean,
  isUserAdvisorOrAdmin: boolean,
  onApply: () => void,
  onResetChanges: () => void
}

export default class Footer extends React.Component<IFooterProps> {
  public render() {
    let disabledApply = !this.props.isDirty;
    if (this.props.isUserAdvisorOrAdmin) disabledApply = true;
    return (<Style.HorizontalScrollDiv>
      <Button key="apply" size="large" style={Style.iaButtonStyle} disabled={disabledApply} onClick={this.props.onApply} variant="contained" color="secondary">
        <span style={{ fontSize: '14px' }}>
          <Trans i18nKey="householding.apply" />
        </span>
      </Button>
      <Style.StyledLinkButton onClick={this.props.onResetChanges}>
        <Typography variant="h6" style={{ color: appTheme.palette.secondary.main, fontWeight: 600 }}>
          <Trans i18nKey="householding.reset" />
        </Typography>
      </Style.StyledLinkButton>
    </Style.HorizontalScrollDiv>);
  }
}
