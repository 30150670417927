import * as React from 'react';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

export type iconSizeType = 'lg' | 'md' | 'sm';
interface INoDataProp {
  text: string;
  height?: string;
  iconSize?: iconSizeType;
}

const NoDataContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height ? props.height : '30px')};
  color: #97a1b0;
  padding: 10px;
  flex-direction: row;
`;

export default class NoData extends React.Component<INoDataProp> {
  constructor(props: INoDataProp) {
    super(props);
  }

  getIconSize = (iconSize: iconSizeType = 'lg') => {
    switch (iconSize) {
      case 'lg':
        return 32;
      case 'sm':
        return 12;
      default:
        return 24;
    }
  };

  public render() {
    const { text, height, iconSize } = this.props;
    return (
      <NoDataContainer height={height}>
        <InfoCircleOutlined style={{ fontSize: `${this.getIconSize(iconSize)}px` }} /> &nbsp;
        {text}
      </NoDataContainer>
    );
  }
}
