import axios, { AxiosResponse } from 'axios';
import { API_MARKETDATA, API_MARKET_DATA_PROVIDER, MARKETDATA_USE_SECTOR_HISTORY } from '~/common/API';
import {
  MarketMoverType,
  Trend,
  TrendType,
  MarketType,
  TopMover,
  IKeyIndicator,
  IMarketProductDetail,
} from '../marketDataTypes';
import { getCurrentIsoLang } from '~/common/helpers';

export const MarketDataHttpClient = new (class {
  public fetchTopMovers = async (marketType: MarketType, marketMoverType: MarketMoverType): Promise<AxiosResponse<TopMover[]>> => {
    const response = await axios.get(
      `${API_MARKETDATA}/Overview/marketMovers?marketType=${marketType}&marketMoverType=${marketMoverType}`,
    );
    return response;
  };

  public fetchSecurityList = async (searchTerm: string): Promise<any[]> => {
    const response = await axios.get(
      `${API_MARKET_DATA_PROVIDER}/search/suggest?q=${searchTerm}&maxResult=10&searchedFields=symbol,nameen,namefr&countryPreference=ca`,
    );
    return response.data.map((item: any) => ({
      symbol: item.symbol,
      ticker: item.ticker,
      nameEn: item.nameEn,
      nameFr: item.nameFr,
      exchange: item.exchange,
      category: item.category,
      label: `${item.symbol} - ${getCurrentIsoLang() === 'en' ? item.nameEn : item.nameFr}`,
    }));
  };

  public fetchTrends = async (trendType: TrendType): Promise<Trend[]> => {
    let params = '';
    if ((trendType === 'americanSector' || trendType === 'canadianSector') && !MARKETDATA_USE_SECTOR_HISTORY) {
      params = '&historyAggregationPeriod=none';
    }
    const response = await axios.get(`${API_MARKETDATA}/Overview/marketTrends?marketType=${trendType}${params}`);
    return response.data;
  };

  public fetchKeyIndicators = async () => {
    const url = `${API_MARKETDATA}/Overview/KeyIndicators`;
    const response = (await axios.get(url)) as any;
    return response.data as IKeyIndicator[];
  };

  public fetchMarketDataProductDetailedInfo = async (type: TrendType, symbol: string) => {
    const url = `${API_MARKETDATA}/${type}/detailedInfo?id=${symbol}`;
    const response = (await axios.get(url)) as any;
    return response.data as IMarketProductDetail;
  };
})();
