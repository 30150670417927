import * as React from 'react';
import { connect } from 'react-redux';
import { IAppRootState } from './rootReducer';

export interface IProps {
  children?: any
  hasServerError: boolean
}

export interface IErrorBoundary {
  hasError: boolean,
}

export class ErrorBoundary extends React.PureComponent<IProps, IErrorBoundary> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any, info: any) {
    // Catch any component children Error
    console.log(error);
    console.log(info);
    this.setState({ hasError: true });
  }

  public render() {
    // const { hasError } = this.state;
    // if ( hasError || this.props.hasServerError ) {
    //   notification.error({
    //     message: 'Global Error',
    //     description: 'An error was encountered. Please contact your system administrator.',
    //     duration: 0
    //   });

    // }
    return this.props.children;
  }
}

function mapStateToProps(state: IAppRootState) {
  return {};
}

export default connect<{}, {}, IProps, IAppRootState>(mapStateToProps)(ErrorBoundary);
