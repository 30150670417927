import * as React from 'react';
import { IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import memoizeOne from 'memoize-one';
import { establishAccounts } from '~/common/accountsHelpers';
import RootAccounts from '../root-accounts/RootAccounts';
import Grid from '@mui/material/Grid';
import SummarySplitGrid from '~/common/components/account/account-view/SummarySplitGrid';
import Colors from '~/common/themes/colors';
import { ComponentWithValidation } from '~/common/components/position-overview/summary-table/ComponentWithValidation';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { AccountViewTabEnum, ISortPreference } from '~/common/types';
import { buildDataArrayForAccountValuePage } from '~/common/components/account/account-view/SummaryAccountViewHelper';
import { HoldingViewKeys, SortColumnKeys } from '~/stores/party/partyTypes';
import { SortDirection } from '~/app/appTypes';
import { useState } from 'react';
import { GetStorageValueByKey, SetStorageKeyValue } from '~/app/sessionManager';

export interface IAccountValueViewProps {
  accountsParam: IAccount[];
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary;
  shouldNotShowMarketDataInfo: boolean;
  accountViewSortPreferences: ISortPreference;
}

const AccountValueView: React.FC<IAccountValueViewProps> = ({
  accountsParam,
  clientAccountTopLevelInfo,
  shouldNotShowMarketDataInfo,
  accountViewSortPreferences,
}: IAccountValueViewProps) => {
  const accountsList = memoizeOne(establishAccounts);

  const calculatedAccounts = accountsList(accountsParam);
  const topLevelValuesToBeDisplayed = buildDataArrayForAccountValuePage(
    clientAccountTopLevelInfo,
    shouldNotShowMarketDataInfo,
  );

  const accountViewInSessionValue = GetStorageValueByKey(HoldingViewKeys.ACCOUNT_VIEW);
  const accountViewInSession =
    accountViewInSessionValue !== undefined ? (JSON.parse(accountViewInSessionValue) as ISortPreference) : undefined;
  const mostUpdatedAccountView = accountViewInSession ?? accountViewSortPreferences;
  const [sortingPreferencesByTab, setSortingPreferencesByTab] = useState<ISortPreference>(mostUpdatedAccountView);

  function onChangedTableSort(sortColumn: SortColumnKeys, sortDirection: SortDirection): void {
    // save current sorting preferences to session
    const sortingPreferencesByTabTemp: ISortPreference = { sortColumn, sortDirection };
    SetStorageKeyValue(HoldingViewKeys.ACCOUNT_VIEW, JSON.stringify(sortingPreferencesByTabTemp));

    setSortingPreferencesByTab((prevState) => ({
      ...prevState,
      sortColumn,
      sortDirection,
    }));
  }

  return (
    <>
      <Grid container style={{ padding: '0 16px' }}>
        <SummarySplitGrid
          backgroundLeftColor={Colors.babyBlue}
          labelKeyLeft="common.totalMarketValue"
          valueLeft={
            <ComponentWithValidation
              value={clientAccountTopLevelInfo.totalMarketValue}
              componentWithValue={<NumberQI value={clientAccountTopLevelInfo.totalMarketValue} isMoney />}
            />
          }
          commentKeyLeft="common.valuesAreInCAD"
          colsOnRight={3}
          dataOnRightSide={topLevelValuesToBeDisplayed}
        />
      </Grid>
      {calculatedAccounts.map((rootAccounts) => (
        <RootAccounts
          {...rootAccounts}
          key={rootAccounts.rootId}
          shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
          viewType={AccountViewTabEnum.AccountValue}
          onChangedTableSort={onChangedTableSort}
          sortingPreferencesByTab={sortingPreferencesByTab}
        />
      ))}
    </>
  );
};

export default AccountValueView;
