import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CsvExportDomain, IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import {
  getIsAccountsFetching,
  getFilteredAccountIds,
  getAllCurrentFilterAccounts,
  getIsMarketDataFetching,
  getIsMarketDataFetchingHasError,
  getIsLiveDataFromState,
  getSimplifyClientAccountsFromState,
} from '~/stores/account/selectors/accountSelectors';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { filterAccounts } from '~/common/accountsHelpers';
import { Trans } from 'react-i18next';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import { HoldingViewKeys, IPartyV1 } from '~/stores/party/partyTypes';
import {
  getDidSetIntradayChangePref,
  getDidSetMarketDataRealtime,
  getHoldingSortingPref,
  getPartyV1,
} from '~/stores/party/selectors/partySelectors';
import AccountFilter from '~/common/components/account/HouseholdAccountFilter';
import { Container, PageTitleContainer, HeaderPageContainer, ContainerRow } from '~/common/styles/baseStyle';
import { refreshSession } from '~/stores/system/actions/systemActions';
import i18n from '~/app/i18n';
import moment from 'moment';
import { ExportCSV, ExportCSVButton } from '~/common/components/export/ExportCSV';
import FileSaver from 'file-saver';
import PrintButton from '~/common/components/print-button/PrintButton';
import { fetchMarketData } from '~/stores/account/actions/accountActions';
import { Typography, Skeleton, Stack, Snackbar, IconButton } from '@mui/material';
import { autoHideDurationError, exportCSVType } from '~/common/constants';
import { ContentPageContainer } from './HoldingsPage.style';
import HoldingsPageSubMenu from './HoldingsPageSubMenu';
import ConsolidatedView from './consolidated-view/ConsolidatedView';
import { isSymbolLinkEnabled } from '~/common/featureToggleHelper';
import AlertContainer from '~/common/components/info-container/alert-container';
import AccountView from './account-view/AccountView';
import NoDataBanner from '~/common/components/noData/NoDataBanner';
import { UpdateDataButton } from '~/common/components/update-data-button/UpdateDataButton';
import SkeletonView from './account-view/SkeletonView';
import HoldingFooter from '~/common/components/loadStamp/HoldingFooter';
import { ISortPreference } from '~/common/types';
import { HoldingPageDefinitionsButton, HoldingPageDefinitions } from './HoldingPageDefinitions';
import { API_ACCOUNTS_V2 } from '~/common/API';
import { HttpGet } from '~/common/utils/HttpClient';
import { getToken } from '~/stores/system/selectors/SystemSelectors';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrentIsoLang } from '~/common/helpers';

export interface IHoldingsExportState {
  modalVisible: boolean;
  csvFilename: string;
  csvDelimiter: string;
  csvDecimalSeparator: string;
  csvDateFormat: string;
  csvRemoveAccent: boolean;
  isCSVDownloading: boolean;
  modalDefinitionsVisible?: boolean;
  openCSVError: boolean;
  currentRenderView: CsvExportDomain;
}

export interface IPropsFromState {
  accounts: IAccount[];
  filteredAccountIds: string[];
  isFetching: boolean;
  isFetchingMarketData: boolean;
  didSetMarketDataRealtime: boolean;
  shouldShowIntradayChange: boolean;
  culture: string;
  party: IPartyV1;
  isFetchingMarketDataHasError: boolean;
  lastUpdatePortal?: Date;
  lastUpdate?: Date;
  isLiveData: boolean;
  clientAccountsSimplifiedInfo: IClientAccountSimplifiedDictionary;
  holdingSortingPreferences: Record<HoldingViewKeys, ISortPreference>;
  token: string | undefined;
}

interface IPropsFromDispatch {
  refreshSession: typeof refreshSession;
  fetchMarketData: typeof fetchMarketData;
}

type Props = RouteComponentProps & IPropsFromState & IPropsFromDispatch;

export class HoldingsPage extends React.PureComponent<Props, IHoldingsExportState> {
  constructor(props: Props) {
    super(props);
    document.title = i18n.t('holdings.documentTitle');
    const defaultFileName = `${i18n.t('holdings.csvExportFilenameForHoldingTab')}_${moment().format('YYYYMMDD')}`;

    this.state = {
      modalVisible: false,
      csvFilename: defaultFileName,
      csvDelimiter: ',',
      csvDecimalSeparator: '.',
      csvDateFormat: 'dd/mm/yyyy',
      csvRemoveAccent: false,
      isCSVDownloading: false,
      modalDefinitionsVisible: false as boolean,
      openCSVError: false as boolean,
      currentRenderView: CsvExportDomain.HOLDINGS,
    };
  }

  private isIntradayChangeActiveAndLive = () => this.props.shouldShowIntradayChange && this.props.isLiveData;

  public renderAccountView = () => {
    const { accounts, filteredAccountIds, clientAccountsSimplifiedInfo, holdingSortingPreferences } = this.props;
    this.props.refreshSession();
    this.setState({
      currentRenderView: CsvExportDomain.HOLDINGS,
      csvFilename: `${i18n.t('holdings.csvExportFilenameForHoldingTab')}_${moment().format('YYYYMMDD')}`,
    });

    return (
      <AccountView
        accounts={filterAccounts(accounts, filteredAccountIds)}
        clientAccountTopLevelInfo={clientAccountsSimplifiedInfo}
        shouldNotShowMarketDataInfo={!this.isIntradayChangeActiveAndLive()}
        holdingViewSortPreferences={holdingSortingPreferences}
      />
    );
  };

  public renderConsolidatedView = () => {
    const { accounts, filteredAccountIds, clientAccountsSimplifiedInfo, holdingSortingPreferences } = this.props;
    this.props.refreshSession();
    const accountsMapped = filterAccounts(accounts, filteredAccountIds);
    this.setState({
      currentRenderView: CsvExportDomain.CONSOLIDATED,
      csvFilename: `${i18n.t('holdings.csvExportFilenameForConsolidatedTab')}_${moment().format('YYYYMMDD')}`,
    });
    return (
      <ConsolidatedView
        accounts={accountsMapped}
        isSymbolLinkEnabled={isSymbolLinkEnabled()}
        clientAccountTopLevelInfo={clientAccountsSimplifiedInfo}
        shouldNotShowMarketDataInfo={!this.isIntradayChangeActiveAndLive()}
        holdingViewSortingPreferences={holdingSortingPreferences}
      />
    );
  };

  public showAddModal = () => {
    this.setState({
      modalVisible: true,
    });
  };
  public showAddDefinitionsModal = () => {
    this.setState({
      modalDefinitionsVisible: true,
    });
  };

  private refreshMarket = (event: any) => {
    this.props.fetchMarketData();
  };

  public handleCsvFilename = (event: React.ChangeEvent<HTMLInputElement>) => {
    const csvFilename = event.target.value;
    this.setState({ csvFilename });
  };

  public handleCsvDelimiter = (csvDelimiter: string) => {
    this.setState({ csvDelimiter });
  };

  public handleCsvDecimalSeparator = (csvDecimalSeparator: string) => {
    this.setState({ csvDecimalSeparator });
  };

  public handleCsvDateFormat = (csvDateFormat: string) => {
    this.setState({ csvDateFormat });
  };

  public handleCsvRemoveAccent = (event: any) => {
    const csvRemoveAccent = event.target.checked;
    this.setState({ csvRemoveAccent });
  };

  public handleOk = () => {
    let { accounts } = this.props;

    accounts = filterAccounts(accounts, this.props.filteredAccountIds);
    const csvAccountIds = accounts && accounts.length > 0 ? accounts.map((m) => m.id) : [];
    const parameterAccountIds = `accountIds=${csvAccountIds.join(',')}`;
    const url = `${API_ACCOUNTS_V2}/Export/Csv?${parameterAccountIds}&csvFilename=${encodeURIComponent(
      this.state.csvFilename,
    )}&csvDelimiter=${encodeURIComponent(this.state.csvDelimiter)}&csvDecimalSeparator=${encodeURIComponent(
      this.state.csvDecimalSeparator,
    )}&csvDateFormat=${encodeURIComponent(this.state.csvDateFormat)}&csvRemoveAccent=${
      this.state.csvRemoveAccent
    }&domain=${this.state.currentRenderView}`;
    const options = {
      headers: {
        Accept: 'text/csv',
        'Accept-Language': getCurrentIsoLang(),
      },
      responseType: 'arraybuffer',
    };

    HttpGet(url, this.props.token ?? '', this.downloadPdfCallback, this.downloadPdfCallbackError, options);

    this.setState({
      modalVisible: false,
      isCSVDownloading: true,
    });
  };

  private downloadPdfCallback = (data: any) => {
    const blob = new Blob([data], { type: exportCSVType });
    const fileName = `${this.state.csvFilename}.csv`;
    FileSaver.saveAs(blob, fileName);
    this.setState({
      isCSVDownloading: false,
    });
  };

  private downloadPdfCallbackError = (error: any) => {
    this.setState({
      openCSVError: true,
      isCSVDownloading: false,
    });
  };

  public handleDefinitionsOk = () => {
    this.setState({
      modalDefinitionsVisible: false,
    });
  };

  private handleCSVErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openCSVError: false });
  };

  public handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  public handleDefinitionsCancel = () => {
    this.setState({
      modalDefinitionsVisible: false,
    });
  };

  private renderHeaderTitle = (isFetching: boolean, shouldNotShowMarketDataUpdateButton: boolean) => (
    <>
      <Typography variant="h1" component="div" style={{ paddingBottom: '12px' }}>
        <Trans i18nKey="common.holdings" />
      </Typography>
      {isFetching ? (
        <Skeleton variant="rectangular" width="300px" height={20} sx={{ mt: 0, mb: 1 }} />
      ) : (
        <DataLoadStamp
          isPortalTimestamp
          isLiveMarket={this.props.didSetMarketDataRealtime == null ? false : this.props.didSetMarketDataRealtime}
          isLiveData={this.props.isLiveData}
        />
      )}
      <div>
        {!shouldNotShowMarketDataUpdateButton &&
          (isFetching ? (
            <Skeleton variant="rounded" width="125px" height={22} sx={{ my: 0.75 }} />
          ) : (
            <UpdateDataButton onClick={() => this.refreshMarket(null)} />
          ))}
      </div>
    </>
  );

  public render() {
    const { accounts, isFetching: IsFetchingAccounts, isFetchingMarketData } = this.props;
    const shouldNotShowMarketDataUpdateButton = this.props.didSetMarketDataRealtime && !this.props.isLiveData;
    const isFetching = IsFetchingAccounts || isFetchingMarketData || !accounts || accounts.length <= 0;

    const action = (
      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCSVErrorClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );

    if (!isFetching && accounts.length <= 0) {
      return (
        <Container>
          <HeaderPageContainer>
            <PageTitleContainer>
              {this.renderHeaderTitle(isFetching, shouldNotShowMarketDataUpdateButton)}
            </PageTitleContainer>
          </HeaderPageContainer>
          <NoDataBanner />
        </Container>
      );
    }

    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            {this.renderHeaderTitle(isFetching, shouldNotShowMarketDataUpdateButton)}
          </PageTitleContainer>
          <PageTitleContainer>
            {isFetching ? (
              <Skeleton variant="rectangular" width="130px" height={30} sx={{ mt: 1 }} />
            ) : (
              <AccountFilter isRegisterAccountFilter={false} />
            )}
          </PageTitleContainer>
        </HeaderPageContainer>
        {shouldNotShowMarketDataUpdateButton && (
          <div>
            <AlertContainer
              text="common.alertMarketDataMessage"
              buttonText="landing.updateData"
              onButtonClick={this.props.fetchMarketData}
              isButtonLoading={this.props.isFetchingMarketData}
            />
          </div>
        )}

        <ContainerRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <HoldingsPageSubMenu isFetching={isFetching} />
          <HoldingPageDefinitionsButton isProcessing={false} onClick={this.showAddDefinitionsModal} />
        </ContainerRow>

        {isFetching ? (
          <ContentPageContainer>
            <SkeletonView />
          </ContentPageContainer>
        ) : (
          <ContentPageContainer>
            <Switch>
              <Route exact path={RouteNames.holdings} render={this.renderAccountView} />
              <Route path={`${RouteNames.holdingsConsolidatedView}`} render={this.renderConsolidatedView} />
              <Route render={this.renderAccountView} />
            </Switch>
          </ContentPageContainer>
        )}

        {isFetching ? (
          <Stack mt={2}>
            <Skeleton />
            <Skeleton width="40%" />
            <Skeleton />
            <Skeleton width="75%" />
          </Stack>
        ) : (
          <HoldingFooter accounts={accounts} />
        )}

        <ExportCSV
          showModal={this.state.modalVisible}
          modalTitle={i18n.t('common.csvModalTitle')}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          handleCsvFilename={this.handleCsvFilename}
          csvFilename={this.state.csvFilename}
          handleCsvDelimiter={this.handleCsvDelimiter}
          delimiter={this.state.csvDelimiter}
          handleCsvDecimalSeparator={this.handleCsvDecimalSeparator}
          handleCsvDateFormat={this.handleCsvDateFormat}
          decimalSeparator={this.state.csvDecimalSeparator}
          handleCsvRemoveAccent={this.handleCsvRemoveAccent}
          removeAccent={this.state.csvRemoveAccent}
          disclaimer={i18n.t('holdings.csvExportDisclaimer')}
        />
        <HoldingPageDefinitions
          showModal={this.state.modalDefinitionsVisible}
          modalTitle={i18n.t('holdings.definitions.Title')}
          handleCancel={this.handleDefinitionsCancel}
        />
        <Stack direction="row" mt={isFetching ? 2 : 0} spacing={2}>
          {isFetching ? (
            <>
              <Skeleton variant="rounded" width="125px" height={25} />
              <Skeleton variant="rounded" width="125px" height={25} />
            </>
          ) : (
            <>
              <ExportCSVButton isProcessing={this.state.isCSVDownloading} onClick={this.showAddModal} />
              <PrintButton marginY={0} />
            </>
          )}
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openCSVError}
          autoHideDuration={autoHideDurationError}
          onClose={this.handleCSVErrorClose}
          message={i18n.t('common.csvFileDownloadError') as string}
          action={action}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    filteredAccountIds: getFilteredAccountIds(state),
    isFetching: getIsAccountsFetching(state),
    isFetchingMarketData: getIsMarketDataFetching(state),
    accounts: getAllCurrentFilterAccounts(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    shouldShowIntradayChange: getDidSetIntradayChangePref(state),
    culture: system.culture,
    party: getPartyV1(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    lastUpdatePortal: state.account.lastUpdatePortal,
    lastUpdate: state.account.lastUpdate,
    isLiveData: getIsLiveDataFromState(state),
    clientAccountsSimplifiedInfo: getSimplifyClientAccountsFromState(state),
    holdingSortingPreferences: getHoldingSortingPref(state),
    token: getToken(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      refreshSession,
      fetchMarketData,
    },
    dispatch,
  );

export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(HoldingsPage);
