import axios from 'axios';
import { API_MARKETDATA } from '~/common/API';

export const PositionDetailsHttp = new class {
  public fetchMarketDataStockBasicInfo = async (symbol: string) => {
    const url = `${API_MARKETDATA}/StockQuote/BasicInfo?symbol=${symbol}`;

    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataStockDetailedInfo = async (symbol: string) => {
    const url = `${API_MARKETDATA}/StockQuote/DetailedInfo?symbol=${symbol}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataStockDetailedInfoRefresh = async (symbol: string) => {
    const url = `${API_MARKETDATA}/StockQuote/DetailedInfoRefresh?symbol=${symbol}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataFinancialStatementInfo = async (symbol: string, frequency:string) => {
    const url = `${API_MARKETDATA}/StockQuote/FinancialStatement?symbol=${symbol}&reportType=${frequency}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataStockHistorical = async (symbol: string, frequency: string) => {
    const url = `${API_MARKETDATA}/HistoricalData?symbols=${symbol}&&frequency=${frequency}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataFundBasicInfo = async (symbol: string) => {
    const url = `${API_MARKETDATA}/FundQuote/DetailedInfo?symbol=${symbol}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataOptionsQuotesIntraday = async (stocks: string[]) => {
    const url = `${API_MARKETDATA}/Options/Quotes/Intraday}`;
    const resp = await axios.post(url, stocks);
    return resp.data;
  };

  public fetchEarningsEstimate = async (symbol: string) => {
    const url = `${API_MARKETDATA}/Earnings?symbol=${symbol}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public fetchMarketDataDividendsHistoricalData = async (symbol: string) => {
    const url = `${API_MARKETDATA}/Dividends/Historical?symbol=${symbol}&maxYears=7`;
    const resp = await axios.get(url);
    return resp.data;
  };
}();
