export type MarketType = 'stock' | 'etf' | 'fund';
export type MarketMoverType = 'gainers' | 'losers' | 'mostActives';
type MarketMoverTypeConcat = `${MarketType}${MarketMoverType}`;

export type TrendType = 'canadianSector' | 'americanSector' | 'commodity' | 'currency' | 'index';

export type History = {
  value: number;
  date: string;
};

export interface MarketIndex {
  market: string;
  currency: string;
  symbol: string;
  nameEn: string;
  nameFr: string;
  last: number;
  change: number;
  baseHistoryValue?: number;
  changePercentage: number;
  history?: History[];
}

export interface TopMover extends MarketIndex {
  type: MarketType;
}

export interface Trend extends MarketIndex {
  type: TrendType;
}

export type Security = {
  category: string;
  exchange: string;
  ticker: string;
  symbol: string;
  nameEn: string;
  nameFr: string;
  label: string;
};

export interface ICurrency {
  symbol: string;
  baseCurrency: string;
  quoteCurrency: string;
  bid: number;
  ask: number;
  spread: number;
  mid: number;
  open: number;
  previousClose: number;
  percentChange: number;
  dollarChange: number;
  periodicalData: IHistoryElement[];
  daysRange: string;
  yearRange: string;
}

export interface ICurrencyHistory {
  symbol: string;
  daysRange: string;
  yearRange: string;
  open: number;
  periodicalData: IHistoryElement[];
}

export interface IHistoryElement {
  date: string;
  open: number;
  close: number;
  high: number;
  low: number;
}

export interface IIndex {
  symbol: string;
  indexGroup: string;
  name: string;
  last: number;
  lastDateTime: string;
  change: number;
  percentChange: number;
  open: number;
  high: number;
  low: number;
  volume: number;
  previousClose: number;
  previousCloseDateTime: string;
  previousCloseVolume: number;
  high52Weeks: number;
  low52Weeks: number;
  currency: string;
  periodicalData: IHistoryElement[];
}

export interface IIndexHistory {
  symbol: string;
  indexGroup: string;
  periodicalData: IHistoryElement[];
}

export interface IKeyIndicator {
  type: string;
  market: string;
  symbol: string;
  name: string;
  last: number;
  change: number;
  changePercentage: number;
  currency: string;
  previousClose: number;
  history: KeyIndicatorHistory[];
}
export type KeyIndicatorHistory = {
  value: number;
  timestamp: string;
};

export interface IMarketDataState {
  readonly topMoverSelectedMarketType: MarketType;
  readonly topMoverSelectedMarketMoverType: Map<MarketType, MarketMoverType>;
  readonly topMovers: Map<MarketMoverTypeConcat, TopMover[] | undefined>;
  readonly isFetchingTopMovers: Map<MarketMoverTypeConcat, boolean>;
  readonly isTopMoversUnavailable: Map<MarketMoverTypeConcat, boolean>;
  readonly isFailTopMovers: Map<MarketMoverTypeConcat, boolean>;

  readonly securitySearchTerm: string;
  readonly securitySearchList: Security[];
  readonly viewedSecurityList: Security[];
  readonly isFetchingSecurityList: boolean;
  readonly isFailSecurityList: boolean;

  readonly trendSelectedMarketType: TrendType;
  readonly trends: Map<TrendType, Trend[] | undefined>;
  readonly isFetchingTrends: Map<TrendType, boolean>;
  readonly isFailTrends: Map<TrendType, boolean>;

  readonly keyIndicators: IKeyIndicator[];
  readonly isFetchingKeyIndicators: boolean;
  readonly isFailKeyIndicators: boolean;

  readonly lastUpdateRequest?: Date;
}

export const enum MarketDataActionTypes {
  FETCH_MARKET_DATA = '[Market Data] Fetch',
  FETCH_MARKET_DATA_SUCCESSFUL = '[Market Data] Fetch Successful',
  FETCH_MARKET_DATA_FAILED = '[Market Data] Fetch Failed',

  MARKET_DATA_TOP_MOVERS_SELECT_MARKET_TYPE = '[Market Data] Top Movers Select Market Type',
  MARKET_DATA_TOP_MOVERS_SELECT_MARKET_MOVER_TYPE = '[Market Data] Top Movers Select Market Mover Type',
  FETCH_MARKET_DATA_TOP_MOVERS = '[Market Data] Fetch Top Movers',
  FETCH_MARKET_DATA_TOP_MOVERS_SUCCESSFUL = '[Market Data] Fetch Top Movers Successful',
  FETCH_MARKET_DATA_TOP_MOVERS_UNAVAILABLE = '[Market Data] Fetch Top Movers Unavailable',
  FETCH_MARKET_DATA_TOP_MOVERS_FAILED = '[Market Data] Fetch Top Movers Failed',

  FETCH_MARKET_DATA_SECURITY_LIST = '[Market Data] Fetch Security List',
  FETCH_MARKET_DATA_SECURITY_LIST_SUCCESSFUL = '[Market Data] Fetch Security List Successful',
  FETCH_MARKET_DATA_SECURITY_LIST_FAILED = '[Market Data] Fetch Security List Failed',
  MARKET_DATA_SECURITY_LIST_CLEAR = '[Market Data] Security List Clear',
  MARKET_DATA_SECURITY_VIEWED = '[Market Data] Fetch Security Viewed',

  MARKET_DATA_TRENDS_SELECT_MARKET_TYPE = '[Market Data] Market Trends Select Market Type',
  FETCH_MARKET_DATA_TRENDS = '[Market Data] Fetch Market Trends',
  FETCH_MARKET_DATA_TRENDS_SUCCESSFUL = '[Market Data] Fetch Market Trends Successful',
  FETCH_MARKET_DATA_TRENDS_FAILED = '[Market Data] Fetch Market Trends Failed',

  FETCH_MARKET_DATA_KEY_INDICATORS = '[Market Data] Fetch Key Indicators',
  FETCH_MARKET_DATA_KEY_INDICATORS_SUCCESSFUL = '[Market Data] Fetch Key Indictors Successful',
  FETCH_MARKET_DATA_KEY_INDICATORS_FAILED = '[Market Data] Fetch Key Indicators Failed',

  RESET_MARKET_DATA = '[Market Data] Removing previous values',
  SET_MARKET_DATA_TIMESTAMP = '[Market Data] Updating timestamp',
}

export interface IMarketProductDetail {
  marketType: TrendType;
  symbol: string;
  market: string;
  nameEn: string;
  nameFr: string;
  lastDateTime: Date;
  currency: string;
  last: number;
  dayChange: {
    value: number;
    percentage: number;
  };
  previousClose: number;
  open: number;
  daysRange: {
    low: number;
    high: number;
  };
  daysVolume: number;
  weeks52Range: {
    low: number;
    high: number;
  };
  month1AverageVolume: number;
  history: IProductDetailHistory[];
}

export interface IProductDetailHistory {
  date: Date;
  open: number;
  close: number;
  high: number;
  low: number;
  volume: number;
  adjustedClose: number;
}

export interface IMarketProductDetailsState {
  isFetchingProductDetailedInfoData: boolean;
  isFetchingProductDetailedInfoDataHasError: boolean;
  products: {
    [key: string]: IMarketProductDetail;
  };
}

export const enum MarketProductDetailsActionTypes {
  FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA = '[Market Product Details] Fetch Market Product Details Info Data',
  FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA_SUCCESSFUL = '[Market Product Details] Fetch Market Product Details Info Data Successful',
  FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA_FAILED = '[Market Product Details] FetchMarket Product Details Info Data Failed',
}
