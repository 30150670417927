import * as React from 'react';
import { Grid } from '@mui/material';
import SummaryLeftGrid from './SummaryLeftGrid';
import SummaryRightGrid from './SummaryRightGrid';
import Colors from '~/common/themes/colors';

export interface IProps {
  backgroundLeftColor?: string;
  labelKeyLeft: string;
  valueLeft?: any;
  commentKeyLeft?: string;
  dataOnRightSide: any[];
  rowsOnRight?: number;
  colsOnRight: number;
  isFetching?: boolean;
}

const SummarySplitGrid = ({
  backgroundLeftColor,
  labelKeyLeft,
  valueLeft,
  commentKeyLeft,
  colsOnRight,
  dataOnRightSide,
  rowsOnRight,
  isFetching,
}: IProps) => {
  const borderStyle = ['1px', 'solid', Colors.quillGray].join(' ');
  return (
    <Grid container style={{ border: borderStyle, margin: '16px 0' }}>
      <Grid container item xs={3}>
        <SummaryLeftGrid
          backgroundColor={backgroundLeftColor}
          labelKey={labelKeyLeft}
          value={valueLeft}
          comment={commentKeyLeft}
          isFetching={isFetching}
        />
      </Grid>
      <Grid container item xs={9} style={{ borderLeft: borderStyle }}>
        <SummaryRightGrid data={dataOnRightSide} rows={rowsOnRight} cols={colsOnRight} isFetching={isFetching} />
      </Grid>
    </Grid>
  );
};

export default SummarySplitGrid;
