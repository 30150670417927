import * as React from 'react';
import { Trans } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles, makeStyles } from 'tss-react/mui';
import styled from 'styled-components';
import i18n from '~/app/i18n';
import { Typography } from '@mui/material';

export interface IColumns {
  header: string,
  align: string,
}

export interface IProps {
  data: any[],
  columns: IColumns[],
  title: string | null,
  boldFirstRow: boolean
}

export const StyledHeaderCell = withStyles(TableCell, {
  root: {
    fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open sans', 'san-serif'].join(','),
    fontSize: '14px',
    color: '#1B1B1B',
    fontWeight: 700,
    backgroundColor: '#e6e6e6',
    padding: '12px',
  },
});

export const StyledTableCell = withStyles(TableCell, {
  root: {
    fontFamily: `${['Open sans', 'san-serif'].join(',')}!important`,
    fontSize: '14px!important',
    color: '#1B1B1B!important',
    padding: '12px',
    fontWeight: 'unset!important' as any,
    '& a': {
      fontWeight: 'unset!important' as any,
      fontFamily: 'unset!important' as any,
      fontSize: 'unset!important' as any,
    },
  },
});

const useStyles = makeStyles()({
  boldFirstRow: {
    '&:nth-of-type(1)': {
      fontWeight: 'bold!important',
    },
  },
});

const StyledTableRow = withStyles(TableRow, (theme) => ({
}));

export const SectionHeaderLabel = styled.div`
  color: #1B1B1B;
  font-size: 21px;
  font-weight: 700;
  padding-top:30px;
  font-family:'Montserrat Bold', 'Montserrat Regular', 'Montserrat', sans-serif;
  margin-bottom: 15px;
`;

export const DataTable = ({ data, columns, title, boldFirstRow }: { data: any[], columns: IColumns[], title: string | null, boldFirstRow: boolean }) => {
  const { classes } = useStyles();
  return (
    <div>
      {title &&
        <SectionHeaderLabel>
          <Typography variant="h3" component="div">
            <Trans i18nKey={title} />
          </Typography>
        </SectionHeaderLabel>}
      <Table aria-label="data-table table">
        <TableHead>
          <TableRow>
            {columns && columns.map((col) => (
              <StyledHeaderCell key={Math.random()}>{i18n.t(col.header) as string}</StyledHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row, index) => (
            <StyledTableRow
              className={boldFirstRow ? classes.boldFirstRow : ''}
              key={Math.random()}
            >
              {row && row.map((cell: any, cellIndex: number) => (
                <StyledTableCell align={columns[cellIndex].align as any} key={Math.random()}>
                  {cell}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
