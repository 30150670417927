import { Button, Typography } from '@mui/material';
import { Modal, Select } from 'antd';
import 'antd/lib/alert/style/index.css';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { RouteNames } from '~/app/appTypes';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import LabelForm from '~/common/components/label-form/LabelForm';
import { HouseholdType } from '~/common/partyHelpers';
import { MainBox, PageTitleContainer } from '~/common/styles/baseStyle';
import { AdvisorHelper } from '~/common/utils/AdvisorHelper';
import { getFileExtension, isAuthorizedFileSize, isAuthorizedFileType } from '~/common/utils/DocumentHelper';
import { sendMessage } from '~/stores/message/actions/messageActions';
import {
  getIsSendingMessage,
  getSendMessageDataLoadingState,
  getSendMessageError,
  getSendMessageSuccess,
  userCanSendMessages,
} from '~/stores/message/selectors/messageSelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Row } from 'react-flexbox-grid';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../../../common/components/Loading/Loading';
import { getRepcodes } from '../../../common/accountsHelpers';
import DragAndDropUpload from '../../../common/components/drag-and-drop-upload/DragAndDropUpload';
import { mapToFiles } from '../../../common/fileHelper';
import { ITeam } from '../../../common/interfaces/advisor/Team';
import { ITeamByRepCode } from '../../../common/interfaces/advisor/TeamByRepCode';
import { IAccount } from '../../../stores/account/accountTypes';
import { getOpenOwnedAccounts } from '../../../stores/account/selectors/accountSelectors';
import { getTeamsByRepCode } from '../../../stores/advisor/selectors/advisorSelectors';
import SendMessageAlertResult from '../SendMessageAlertResult';
import RecipientsSection from './RecipientsSection';
import {
  BreadcrumbNavigation,
  BreadcrumbNavigationItem,
  ButtonsContainer,
  CancelButton,
  SendMessageForm,
  SendMessagePageHeader,
  UploadContainer,
  UploadErrorList,
  UploadErrorListItem,
} from './SendMessagePage.style';

const { Option } = Select;

interface ISendMessageState {
  attachments: UploadFile[];
  hasRecipientsToDisplay: boolean | undefined;
  selectedTeam: ITeam | undefined;
  selectedAccountId: string;
  idsToNotify: string[];
}

interface IProps {
  history: any;
}

interface IPropsFromState {
  culture: string;
  party: IPartyV1;
  isSendingMessage: boolean;
  sendMessageError: boolean;
  sendMessageSuccess: boolean;
  primaryDataLoading: boolean;
  userCanSendMessage: boolean;
  accounts: IAccount[],
  teamsByRepcodes: ITeamByRepCode
}

interface IPropsFromDispatch {
  sendMessage: typeof sendMessage;
}

type Props = IProps & IPropsFromState & IPropsFromDispatch;
class SendMessagePage extends React.Component<Props, ISendMessageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attachments: [],
      hasRecipientsToDisplay: undefined,
      idsToNotify: [],
      selectedTeam: undefined,
      selectedAccountId: '',
    };
  }

  private isValid = (): boolean => {
    const { idsToNotify, attachments, hasRecipientsToDisplay } = this.state;
    const { userCanSendMessage } = this.props;

    if (hasRecipientsToDisplay) {
      return idsToNotify.length > 0 && attachments.length > 0 && userCanSendMessage;
    }
    return attachments.length > 0 && userCanSendMessage;
  };

  private handleSubmit = async () => {
    if (this.isValid()) {
      this.sendMessage();
    }
  };

  private sendMessage = () => {
    const { attachments, idsToNotify, selectedAccountId } = this.state;
    const { party, accounts } = this.props;
    const mappedAttachments = mapToFiles(attachments);
    let repCode: string;
    const selectedAccount = accounts[accounts.findIndex((a) => a.id === selectedAccountId)];
    if (selectedAccount) {
      repCode = party.accounts.filter((a) => a.id === selectedAccount.rootId).length > 0 ? party.accounts.filter((a) => a.id === selectedAccount.rootId)[0].repCode : '';
    } else {
      repCode = party.accounts.filter((account) => account.role !== HouseholdType.SHARING)[0].repCode;
    }

    this.props.sendMessage(party.id, repCode, idsToNotify, mappedAttachments);
  };

  private breadcrumbNavigationDisplay = (): JSX.Element => (
    <BreadcrumbNavigation>
      <Link to={RouteNames.documents}>
        <BreadcrumbNavigationItem>
          <Trans i18nKey="document.title" />
        </BreadcrumbNavigationItem>
      </Link>
    </BreadcrumbNavigation>
  );

  private onAccountSelectionChanged = (value: string) => {
    this.setState(SendMessagePage.getStateFromSelectedAccountId(this.props, value));
  };

  private renderAccountsSelectBox = (): JSX.Element => {
    const { accounts } = this.props;
    const { selectedAccountId } = this.state;
    const rootIds: string[] = [];

    return (
      <Select style={{ width: '450px' }} value={selectedAccountId} onChange={this.onAccountSelectionChanged}>
        {accounts.map((account) => {
          if (account.rootId && rootIds.findIndex((x) => x === account.rootId) === -1) {
            rootIds.push(account.rootId);
            const description = `${account.rootId} - ${account.name}`;
            return (<Option key={account.id}>{description}</Option>);
          }

          return '';
        })}
      </Select>);
  };

  private showModal = (unauthorizedFiles: UploadFile[]) => {
    Modal.info({
      title: i18n.t('document.uploadError') as string,
      content: (
        <UploadErrorList>
          {
            unauthorizedFiles.map((unauthorizedAttachment) => {
              if (!isAuthorizedFileType(unauthorizedAttachment.name)) {
                return (<UploadErrorListItem key={unauthorizedAttachment.uid}>{i18n.t('document.unauthorizedFileTypeMessage', { 0: unauthorizedAttachment.name, 1: getFileExtension(unauthorizedAttachment.name) }) as string}</UploadErrorListItem>);
              }
              return (<UploadErrorListItem key={unauthorizedAttachment.uid}>{i18n.t('document.unauthorizedFileSizeMessage', { 0: unauthorizedAttachment.name }) as string}</UploadErrorListItem>);
            })
          }
        </UploadErrorList>
      ),
      width: 800,
    });
  };

  private onAttachmentsChanged = (filesInfo: UploadChangeParam): void => {
    const currentFileToUpload = filesInfo.file as unknown as File;
    const lastFileToUpload = filesInfo.fileList[filesInfo.fileList.length - 1].originFileObj;
    if (currentFileToUpload?.name === lastFileToUpload?.name) {
      const unauthorizedFiles: UploadFile[] = [];
      const authorizedFiles: UploadFile[] = [];

      filesInfo.fileList.forEach((file) => {
        if (!isAuthorizedFileType(file.name) || !isAuthorizedFileSize(file.size as number)) {
          unauthorizedFiles.push(file);
        } else {
          authorizedFiles.push(file);
        }
      });
      if (unauthorizedFiles.length > 0) {
        this.showModal(unauthorizedFiles);
      }
      this.setState({ attachments: authorizedFiles });
    }
  };

  private onAttachmentRemoved = (fileId: string): void => {
    const { attachments } = this.state;
    const newStateValue = [...attachments].filter((attachment) => attachment.uid !== fileId);
    this.setState({
      attachments: newStateValue,
    });
  };

  public componentDidUpdate(prevProps: Props, prevState: ISendMessageState) {
    const { sendMessageSuccess, history } = this.props;

    if (!prevProps.sendMessageSuccess && sendMessageSuccess) {
      history.push(RouteNames.documents);
    }
  }

  private static notifiableAdvisorsIds = (team: ITeam): string[] => {
    const { Advisors, Assistants, Branches } = team;
    const teamMembersWithAnEmail = AdvisorHelper.filterAdvisorsWithPrimaryEmail([...Advisors, ...Assistants, ...Branches]);
    return teamMembersWithAnEmail.map((t) => t.id);
  };

  private static getStateFromSelectedAccountId(props: IPropsFromState, selectedAccountId: string) {
    const { accounts, teamsByRepcodes } = props;
    const repcode = accounts[accounts.findIndex((a) => a.id === selectedAccountId)].repCode;
    const teamToDisplay = teamsByRepcodes[repcode];
    const idsToNotify = SendMessagePage.notifiableAdvisorsIds(teamToDisplay);

    return {
      selectedAccountId,
      selectedTeam: teamToDisplay,
      idsToNotify,
      hasRecipientsToDisplay: idsToNotify.length > 0,
    };
  }

  public static getDerivedStateFromProps(props: IPropsFromState, state: ISendMessageState) {
    const { accounts, teamsByRepcodes } = props;
    const { selectedAccountId } = state;

    if (selectedAccountId === '' && accounts.length > 0 && teamsByRepcodes[accounts[0].repCode]) {
      return SendMessagePage.getStateFromSelectedAccountId(props, accounts[0].id);
    }

    return {};
  }

  public render() {
    const { attachments, hasRecipientsToDisplay, selectedTeam } = this.state;
    const { sendMessageError, isSendingMessage, primaryDataLoading, accounts } = this.props;
    const hasMultipleRepcodes = getRepcodes(accounts).length > 1;

    if (isSendingMessage || primaryDataLoading) {
      return <Loading show />;
    }

    return (
      <>
        <SendMessagePageHeader>
          <Row>{this.breadcrumbNavigationDisplay()}</Row>
          <Row>
            <PageTitleContainer>
              <Trans i18nKey="document.sendADocument" />
            </PageTitleContainer>
          </Row>
          {sendMessageError && (
            <Row style={{ paddingBottom: '20px' }}>
              <SendMessageAlertResult />
            </Row>
          )}
        </SendMessagePageHeader>
        <MainBox>
          <SendMessageForm>
            {hasMultipleRepcodes && (
              <>
                <Row>
                  <LabelForm name="Accounts" label={<Trans i18nKey="document.accountsExplanation" />} />
                </Row>
                <Row style={{ paddingBottom: '15px' }}>
                  {this.renderAccountsSelectBox()}
                </Row>
              </>)}

            {selectedTeam !== undefined && hasRecipientsToDisplay !== false && (
              <>
                <Row>
                  <LabelForm name="Recipients" label={<Trans i18nKey="document.recipients" />} />
                </Row>
                <Row style={{ paddingBottom: '30px' }}>
                  <RecipientsSection team={selectedTeam} />
                </Row>
              </>
            )}
            <Row style={{ display: 'block' }}>
              <UploadContainer>
                <LabelForm
                  name="documents"
                  label={<Trans i18nKey="document.documents" />}
                />
                <Typography variant="body1" component="span"><Trans i18nKey="document.maxFileSize" /></Typography>
                <DragAndDropUpload texts={<Trans i18nKey="document.documentUploadInformation" />} files={attachments} onFilesAdded={this.onAttachmentsChanged} onFileRemoved={this.onAttachmentRemoved} />
              </UploadContainer>
            </Row>
            <Row>
              <ButtonsContainer>
                <Link to={RouteNames.documents}>
                  <CancelButton variant="outlined">
                    <Trans i18nKey="common.cancel" />
                  </CancelButton>
                </Link>
                <Button onClick={this.handleSubmit} disabled={!this.isValid()} variant="contained" color="secondary"><Trans i18nKey="document.send" /> </Button>
              </ButtonsContainer>
            </Row>
          </SendMessageForm>
        </MainBox>
      </>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  const { system } = state;
  return {
    party: getPartyV1(state),
    culture: system.culture,
    isSendingMessage: getIsSendingMessage(state),
    sendMessageError: getSendMessageError(state),
    sendMessageSuccess: getSendMessageSuccess(state),
    primaryDataLoading: getSendMessageDataLoadingState(state),
    userCanSendMessage: userCanSendMessages(state),
    accounts: getOpenOwnedAccounts(state),
    teamsByRepcodes: getTeamsByRepCode(state),
  };
}

const mapDispatchToProps: IPropsFromDispatch = {
  sendMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendMessagePage);
