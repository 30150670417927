import * as React from 'react';
import Grid from '@mui/material/Grid/Grid';
import {
  ContainerRow,
  DataLoadStampContainer,
  LoadStampContainer,
  UpdateDataContainer,
} from '~/common/styles/baseStyle';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { IconButton, Typography } from '@mui/material';
import moment from 'moment';
import i18n from '~/app/i18n';
import { IaLocales } from '~/common/locales/ia-locales';
import SyncIcon from '@mui/icons-material/Sync';
import { fetchStockDetailedInfoRefresh } from '~/stores/positionDetail/actions/positionDetailActions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getMarketSymbolFormatted } from '~/common/helpers';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { IAppRootState } from '~/app/rootReducer';
import { fetchMarketData } from '~/stores/account/actions/accountActions';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { SymbolInfoTypes } from '~/common/types';
import { appTheme } from '~/common/themes/theme';
import Loading from '../Loading/Loading';
import { getIsLiveDataFromState, getIsMarketDataFetching } from '~/stores/account/selectors/accountSelectors';
import DataLoadStamp from '../loadStamp/DataLoadStamp';
import { UpdateDataButton } from '../update-data-button/UpdateDataButton';

export interface IProductHeaderSectionPropFromState {
  stockData: any;
  didSetMarketDataRealtime: boolean;
  positionDetails: any;
  product: SymbolInfoTypes;
  isLiveData: boolean;
  isFetchingMarketData: boolean;
  isHeld: boolean;
  isFromMarketsScreen: boolean;
}

export interface IProductHeaderSectionPropFromDispatch {
  fetchStockDetailedInfoRefresh: typeof fetchStockDetailedInfoRefresh;
  fetchMarketData: typeof fetchMarketData;
}

export const ProductHeaderSection: React.FC<
  IProductHeaderSectionPropFromState & IProductHeaderSectionPropFromDispatch
> = (props) => {
  const refreshStockDetailedInfo = (event: any) => {
    props.fetchStockDetailedInfoRefresh(getMarketSymbolFormatted(props.stockData));
    if (props.isHeld) {
      props.fetchMarketData();
    }
  };

  const isFund = props.product === SymbolInfoTypes.FUND;
  IaLocales.createMomentLocales();
  moment.locale(`${i18n.language}`);

  const shouldNotShowMarketDataUpdateButton = props.didSetMarketDataRealtime && !props.isLiveData;

  return (
    <Grid item xs={4}>
      <div className="boxGrey">
        <Grid>
          <Grid>
            <Typography variant="body2" component="div">
              {i18n.t('stockOverview.marketPrice') as string}
            </Typography>
            <Typography
              variant="h2"
              component="div"
              style={{ color: appTheme.palette.secondary.main, paddingTop: '5px' }}
            >
              <NumberQI value={props.stockData.last} isMoney />
            </Typography>
          </Grid>
          {props.positionDetails.isFetchingStockDetailedInfoDataRefresh || props.isFetchingMarketData ? (
            <Loading show />
          ) : (
            <>
              <Grid>
                <Grid item xs={12}>
                  {!isFund && (
                    <>
                      <Typography variant="body2" component="div" style={{ paddingTop: '25px' }}>
                        {i18n.t('stockOverview.daysChange') as string}
                      </Typography>

                      <ContainerRow style={{ paddingTop: 5, paddingBottom: 30, justifyContent: 'flex-start' }}>
                        <ChangeValue
                          value={props.stockData.dollarChange}
                          percent={props.stockData.percentChange}
                          fontSize="18px"
                          notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
                          singleLineFormat
                          noArrow
                          noShowValuesSign={false}
                        />
                      </ContainerRow>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid style={{ paddingTop: 25 }}>
                <Grid item xs={12}>
                  <LoadStampContainer>
                    {!isFund && (
                      <UpdateDataContainer>
                        {!shouldNotShowMarketDataUpdateButton && (
                          <UpdateDataButton onClick={() => refreshStockDetailedInfo(null)} />
                        )}
                      </UpdateDataContainer>
                    )}
                    <DataLoadStampContainer marginTop={isFund ? 100 : 0}>
                      <DataLoadStamp
                        isPreviousDay={isFund}
                        isLiveMarket={!isFund}
                        isPortalTimestamp={false}
                        color="#1B1B1B"
                        font={"'OpenSans-Regular', 'Open Sans'"}
                        isLiveData={props.isLiveData}
                        isMarketDataTimestamp={props.isFromMarketsScreen}
                      />
                    </DataLoadStampContainer>
                  </LoadStampContainer>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

function mapStateToProps(state: IAppRootState) {
  const { positionDetails } = state;
  return {
    positionDetails,
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isLiveData: getIsLiveDataFromState(state),
    isFetchingMarketData: getIsMarketDataFetching(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchStockDetailedInfoRefresh,
      fetchMarketData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductHeaderSection);
