import * as React from 'react';
import { Trans } from 'react-i18next';
import i18n from '~/app/i18n';
import moment from 'moment';
import { isCurrentIsoLangEN } from '~/common/helpers';
import Colors from '~/common/themes/colors';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { PositionSummaryTable } from '../summary-table/PositionSummaryTable';
import { ComponentWithValidation } from '../summary-table/ComponentWithValidation';

export const EtfSummary = (stockData: any) => {
  const stockDataInfo = stockData.stockData;
  return (
    <PositionSummaryTable
      rows={4}
      cols={4}
      positionSummaryContainerXS={8}
      data={[
        {
          labelKey: 'stockOverview.bidAsk',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.bid}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.bid} isMoney />}
              />{' '}
              /{' '}
              <ComponentWithValidation
                value={stockDataInfo.ask}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.ask} isMoney />}
              />
            </>
          ),
        },
        {
          labelKey: 'stockOverview.previousClose',
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.previousClose}
              componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.previousClose} isMoney />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.dayRange',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.low}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.low} isMoney />}
              />{' '}
              -{' '}
              <ComponentWithValidation
                value={stockDataInfo.high}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.high} isMoney />}
              />
            </>
          ),
        },
        {
          labelKey: 'stockOverview.52WeekRange',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.low52Weeks}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.low52Weeks} isMoney />}
              />{' '}
              -{' '}
              <ComponentWithValidation
                value={stockDataInfo.high52Weeks}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.high52Weeks} isMoney />}
              />{' '}
            </>
          ),
        },

        {
          labelKey: 'stockOverview.volumeAverage',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.volume}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.volume} />}
              />{' '}
              /{' '}
              <ComponentWithValidation
                value={stockDataInfo.averageVolume30Days}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.averageVolume30Days} />}
              />
            </>
          ),
        },
        {
          labelKey: 'stockOverview.marketCap',
          toolTipKey: 'stockOverview.etfTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.marketCap}
                componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.marketCap} isMoney />}
              />{' '}
            </>
          ),
        },
        {
          labelKey: 'stockOverview.unitsOutstanding',
          toolTipKey: 'stockOverview.etfTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.sharesOutstanding}
              componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.sharesOutstanding} isMoney />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.distributionYield',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.expectedDividendYield}
                componentWithValue={
                  <NumberQI isFormatLargeNumber value={stockDataInfo.expectedDividendYield} isPercent />
                }
              />{' '}
            </>
          ),
        },

        {
          labelKey: 'stockOverview.beta',
          toolTipKey: 'stockOverview.betaTooltip',
          toolTipColor: Colors.cobalt,
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.beta}
              componentWithValue={<NumberQI isFormatLargeNumber value={stockDataInfo.beta} />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.assetClass',
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.etfInfo.assetClass}
              componentWithValue={<Trans i18nKey={`stockOverview.assetClasses.${stockDataInfo.etfInfo.assetClass}`} />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.category',
          positionValue: (
            <>
              {isCurrentIsoLangEN() ? (
                <ComponentWithValidation value={stockDataInfo.etfInfo.categoryEn} />
              ) : (
                <ComponentWithValidation value={stockDataInfo.etfInfo.categoryFr} />
              )}
            </>
          ),
        },
        {
          labelKey: 'stockOverview.inceptionDate',
          positionValue: (
            <>
              <ComponentWithValidation
                value={stockDataInfo.etfInfo.inceptionDate}
                componentWithValue={moment(stockDataInfo.etfInfo.inceptionDate).locale(`${i18n.language}`).format('ll')}
              />{' '}
            </>
          ),
        },

        {
          labelKey: 'stockOverview.mer',
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.etfInfo.managementExpenseRatio}
              componentWithValue={<NumberQI value={stockDataInfo.etfInfo.managementExpenseRatio} isPercent />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.managementFees',
          positionValue: (
            <ComponentWithValidation
              value={stockDataInfo.etfInfo.managementFees}
              componentWithValue={<NumberQI value={stockDataInfo.etfInfo.managementFees} isPercent />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.currency',
          positionValue: (
            <>
              <ComponentWithValidation value={stockDataInfo.currency} />{' '}
            </>
          ),
        },
        {
          labelKey: 'stockOverview.riskRating',
          positionValue: (
            <>
              <ComponentWithValidation value={stockDataInfo.etfInfo.riskTaking} />{' '}
            </>
          ),
        },
      ]}
    />
  );
};
