import styled from 'styled-components';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { appTheme } from '~/common/themes/theme';
import { Row } from 'react-flexbox-grid';

export const SemiBold = styled.span`
  font-family: ${(props) => props.theme.fontSemiBold};
`;

export const Header = styled.div`
    font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #1b1b1b;
    padding: 8px;
`;

export const RowCash = styled(Grid)`
    background-color: #fff;
    align-items: center;
    // box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2); 
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding:12px;
`;

export const RowCashGrid = styled(Row)`
    background-color: #fff;
    align-items: center;
    // box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2); 
    border-bottom: 1px solid rgba(0,0,0,0.2);
`;

export const FakeHeaderTableGrid = withStyles(Grid, {
  root: {
    fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open sans', 'san-serif'].join(','),
    fontSize: '14px',
    color: appTheme.palette.primary.contrastText,
    backgroundColor: appTheme.palette.primary.main,
    padding: '12px',
    fontWeight: 'bold',
    textAlign: 'right',
  },
});
