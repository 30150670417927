import { IAppRootState } from '~/app/rootReducer';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { IBranch, ITeamUI } from '~/common/interfaces/advisor/Team';
import { ITeamByRepCode } from '~/common/interfaces/advisor/TeamByRepCode';
import { AdvisorHelper } from '~/common/utils/AdvisorHelper';
import { getTeamsByRepCode, getIsAdvisorFail, getIsAdvisorFetching } from '~/stores/advisor/selectors/advisorSelectors';
import * as React from 'react';
import { useMemo } from 'react';
import { connect } from 'react-redux';

import SearchResult from './SearchResult';
import AlertContainer from '../info-container/alert-container';
import { LandingContactSectionSkeleton } from '~/pages/landing-page/LandingSkeleton';

interface IPropsFromState {
  teams: ITeamByRepCode;
  isLoadingFail: boolean;
  isAdvisorFetching: boolean;
}

export interface IProp {
  repCodes: string[];
}

const Contact = ({ teams, repCodes, isAdvisorFetching, isLoadingFail }: IProp & IPropsFromState) => {
  const getAdvisorInfo = () => {
    const teamByRepCode = teams;
    const mappedTeams: ITeamUI = { Advisors: [], Branchs: [] };
    repCodes.forEach((rep) => {
      const t = teamByRepCode[rep];
      if (t !== undefined) {
        t.Advisors.forEach((advisor: IAdvisor) => {
          if (
            mappedTeams.Advisors.find((a) => a.firstName === advisor.firstName && a.lastName === advisor.lastName) ===
            undefined
          ) {
            mappedTeams.Advisors.push(advisor);
            let branchFound = false;
            mappedTeams.Branchs.forEach((branch) => {
              if (
                branch.Branches.addresses.length > 0 &&
                t.Branches[0].addresses.length > 0 &&
                branch.Branches.addresses[0].streetName === t.Branches[0].addresses[0].streetName
              ) {
                branchFound = true;
              }
            });
            if (!branchFound) {
              const branch: IBranch = { Branches: t.Branches[0], Assistants: t.Assistants };
              mappedTeams.Branchs.push(branch);
            }
          }
        });
      }
    });
    mappedTeams.Advisors.sort(AdvisorHelper.compareByRank);
    return mappedTeams;
  };
  const team = useMemo(() => getAdvisorInfo(), [repCodes, teams]);

  if (isAdvisorFetching) {
    return <LandingContactSectionSkeleton />;
  }

  if (isLoadingFail) {
    return <AlertContainer title="common.attention" text="contactCard.alertMessage" />;
  }

  if (team && team.Advisors.length > 0) {
    return <SearchResult advisors={team.Advisors} teams={team} />;
  }

  return <></>;
};

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    isLoadingFail: getIsAdvisorFail(state),
    isAdvisorFetching: getIsAdvisorFetching(state),
    teams: getTeamsByRepCode(state),
  };
}

export default connect(mapStateToProps)(Contact);
