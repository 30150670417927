import * as React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { IAButtonSelect, ContainerRow, Container } from '~/common/styles/baseStyle';
import { Popper, ClickAwayListener, InputBase, createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setQueryStringParameters } from '~/stores/system/actions/systemActions';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import moment from 'moment';
import { Select } from 'antd';

const { Option } = Select;

const ContainerFilter = styled.div`
    font-family: 'Open Sans', 'Open Sans';
    box-shadow: 0px 5px 5px 2px rgba(102, 102, 102, 0.349019607843137);
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    padding: 15px;
    background-color: #fff;
    width: 320px;
    overflow: auto;
`;

export const DateIA = withStyles(InputBase, () =>
  createStyles({
    root: {
      'label + &': {
        marginTop: '3px',
      },

      '& input:invalid ': {
        borderColor: 'red',
      },
    },

    input: {
      position: 'relative',
      border: '1px solid #d9d9d9',
      borderRadius: '5px !important',
      fontSize: 14,
      width: '130px',
      padding: '8px 5px',
      fontFamily: [
        'Open Sans',

      ].join(','),
      '&:focus': {
        borderColor: '#003da5',
      },
    },
  }));

export interface IFilterHistoryProps {
  startDate: string,
  endDate: string,
}

export interface IFilterHistoryState {
  isOpenPeriod: boolean,
  anchorPeriodfilter: any,
  startDatePicker: string,
  endDatePicker: string,

}

export interface IPropsFromDispatch {
  setQueryStringParameters: typeof setQueryStringParameters
}

export enum DateFilterOptionEnum {
  PAST_30_DAYS = '30',
  PAST_90_DAYS = '90',
  PAST_180_DAYS = '180',
  CURRENT_YEAR = 'CurrentYear',
  PREVIOUS_YEAR = 'PreviousYear',
  SpecificDate = 'SpecificDate'
}
export const outputDateFormat = 'YYYY-MM-DD';

class FilterHistory extends React.PureComponent<IFilterHistoryProps & IPropsFromDispatch, IFilterHistoryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpenPeriod: false,
      anchorPeriodfilter: null,
      startDatePicker: this.props.startDate,
      endDatePicker: this.props.endDate,
    };
  }

  private establishSelectedOption = (startDate: string, endDate: string) => {
    const startDateObject = moment(startDate);
    const endDateObject = moment(endDate);
    const todayObject = moment(moment().format(outputDateFormat));
    const deltaInDays = endDateObject.diff(startDateObject, 'days');

    if (deltaInDays === 30) {
      return DateFilterOptionEnum.PAST_30_DAYS;
    }
    if (deltaInDays === 90) {
      return DateFilterOptionEnum.PAST_90_DAYS;
    }
    if (deltaInDays === 180) {
      return DateFilterOptionEnum.PAST_180_DAYS;
    }
    if (startDateObject.year() === todayObject.year() && startDateObject.dayOfYear() === 1) {
      return DateFilterOptionEnum.CURRENT_YEAR;
    }
    if (startDateObject.year() === todayObject.add(-1, 'y').year() && startDateObject.dayOfYear() === 1) {
      return DateFilterOptionEnum.PREVIOUS_YEAR;
    }

    return DateFilterOptionEnum.SpecificDate;
  };

  private establishEndDate = (id: string) => {
    switch (id) {
      case DateFilterOptionEnum.PAST_30_DAYS:
      case DateFilterOptionEnum.PAST_90_DAYS:
      case DateFilterOptionEnum.PAST_180_DAYS:
        return moment().format(outputDateFormat);
      case DateFilterOptionEnum.CURRENT_YEAR:
        return moment().format('YYYY-12-31');
      case DateFilterOptionEnum.PREVIOUS_YEAR:
        return moment().add(-1, 'y').format('YYYY-12-31');
    }

    return '';
  };

  private establishStartDate = (id: string) => {
    switch (id) {
      case DateFilterOptionEnum.PAST_30_DAYS:
        return moment().add(-30, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.PAST_90_DAYS:
        return moment().add(-90, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.PAST_180_DAYS:
        return moment().add(-180, 'd').format(outputDateFormat);
      case DateFilterOptionEnum.CURRENT_YEAR:
        return moment().format('YYYY-01-01');
      case DateFilterOptionEnum.PREVIOUS_YEAR:
        return moment().add(-1, 'y').format('YYYY-01-01');
    }

    return '';
  };

  private handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState(
      { isOpenPeriod: !this.state.isOpenPeriod, anchorPeriodfilter: event.currentTarget },
    );
  };

  private handleClickAway = () => {
    this.setState({ isOpenPeriod: false });
    this.props.setQueryStringParameters([
      { name: 'startDate', value: this.state.startDatePicker },
      { name: 'endDate', value: this.state.endDatePicker },
    ]);
  };

  public handleOnOptionSelected = (value: string) => {
    const startDate = this.establishStartDate(value);
    const endDate = this.establishEndDate(value);

    if (value !== DateFilterOptionEnum.SpecificDate) {
      this.props.setQueryStringParameters([
        { name: 'startDate', value: startDate },
        { name: 'endDate', value: endDate },
      ]);
    }
  };

  private handleOnStartDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startDatePicker: event.target.value });
  };

  private handleOnEndDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endDatePicker: event.target.value });
  };

  public render() {
    const idPopper = this.state.isOpenPeriod ? 'popperPeriodFilter' : undefined;
    const selectedPeriod = this.establishSelectedOption(this.props.startDate, this.props.endDate);

    return (
      <div>
        <IAButtonSelect onClick={this.handleFilterClick} variant="outlined" endIcon={<ExpandMoreIcon htmlColor="#003da5" />}>
          <Trans i18nKey="common.period_dropdown" />
        </IAButtonSelect>
        {this.state.isOpenPeriod &&
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Popper id={idPopper} open={this.state.isOpenPeriod} anchorEl={this.state.anchorPeriodfilter} placement="bottom-end" disablePortal={false}>
              <ContainerFilter>
                <Container>
                  <Trans i18nKey="common.period_dropdown" />
                  <Select id="period" onChange={this.handleOnOptionSelected} value={selectedPeriod} style={{ width: 150 }}>
                    <Option value={DateFilterOptionEnum.PAST_30_DAYS}><Trans i18nKey="common.period_dropdown_choice1" /></Option>
                    <Option value={DateFilterOptionEnum.PAST_90_DAYS}><Trans i18nKey="common.period_dropdown_choice2" /></Option>
                    <Option value={DateFilterOptionEnum.PAST_180_DAYS}><Trans i18nKey="common.period_dropdown_choice3" /></Option>
                    <Option value={DateFilterOptionEnum.CURRENT_YEAR}><Trans i18nKey="common.period_dropdown_choice4" /></Option>
                    <Option value={DateFilterOptionEnum.PREVIOUS_YEAR}><Trans i18nKey="common.period_dropdown_choice5" /></Option>
                    <Option value={DateFilterOptionEnum.SpecificDate}><Trans i18nKey="common.period_dropdown_choice6" /></Option>
                  </Select>
                </Container>
                <Container>
                  <ContainerRow style={{ paddingTop: 10 }}>
                    <Container>
                      <Trans i18nKey="common.period_dropdown_from" />
                      <DateIA type="date" defaultValue={this.props.startDate} onChange={this.handleOnStartDatePickerChange} />
                    </Container>

                    <Container>
                      <Trans i18nKey="common.period_dropdown_to" />
                      <DateIA type="date" defaultValue={this.props.endDate} onChange={this.handleOnEndDatePickerChange} />

                    </Container>
                  </ContainerRow>
                </Container>
              </ContainerFilter>
            </Popper>
          </ClickAwayListener>}
      </div>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {

  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setQueryStringParameters,
}, dispatch);

export default connect<{}, IPropsFromDispatch, IFilterHistoryProps, IAppRootState>(mapStateToProps, mapDispatchToProps)(FilterHistory);
