import { ChartContainer, ChartsReferenceLine, LinePlot } from '@mui/x-charts';
import React from 'react';

export type SparklineData = {
  value: number;
  date: Date;
};

export const IASparklineChart = ({
  height,
  width,
  data,
  baseLine,
  trend,
}: {
  height: number;
  width: number;
  baseLine?: number;
  data: SparklineData[];
  trend: 'positive' | 'negative';
}) => {
  let maxY;
  let minY;

  try {
    maxY =
      data !== undefined
        ? Math.max(
            ...data
              .filter((x) => !isNaN(x.value))
              .map((o) => o.value)
              .concat(baseLine ?? data.filter((x) => !isNaN(x.value))[0].value),
          ) +
          Math.max(
            ...data
              .filter((x) => !isNaN(x.value))
              .map((o) => o.value)
              .concat(baseLine ?? data.filter((x) => !isNaN(x.value))[0].value),
          ) *
            0.01
        : 0;
    minY =
      data !== undefined
        ? Math.min(
            ...data
              .filter((x) => !isNaN(x.value))
              .map((o) => o.value)
              .concat(baseLine ?? data.filter((x) => !isNaN(x.value))[0].value),
          ) -
          Math.min(
            ...data
              .filter((x) => !isNaN(x.value))
              .map((o) => o.value)
              .concat(baseLine ?? data.filter((x) => !isNaN(x.value))[0].value),
          ) *
            0.01
        : 0;
  } catch (error) {
    // If we can't calculate the max and min, we'll just use the default value = undefined
  }

  return (
    <>
      <ChartContainer
        height={height}
        width={width}
        margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
        series={[
          {
            showMark: true,
            connectNulls: true,
            color: trend === 'negative' ? 'red' : 'green',
            type: 'line',
            area: true,
            data: data?.map((item) => item.value),
          },
        ]}
        xAxis={[
          {
            data: data?.map((item) => item.date),
          },
        ]}
        yAxis={[
          {
            max: maxY,
            min: minY,
          },
        ]}
      >
        {baseLine && <ChartsReferenceLine y={baseLine} lineStyle={{ stroke: 'lightgray', strokeDasharray: '10 4' }} />}{' '}
        {baseLine}
        <LinePlot
          style={{
            strokeWidth: '1px',
          }}
        />
      </ChartContainer>
    </>
  );
};
