import * as React from 'react';
import moment from 'moment';
import { IaLocales } from '~/common/locales/ia-locales';
import i18n from '~/app/i18n';

export interface IDateFormatProps {
  value: Date | string;
}

const DateFormat: React.FC<IDateFormatProps> = ({ value }) => {
  IaLocales.createMomentLocales();
  moment.locale(`${i18n.language}`);

  return <span>{moment(value).format(i18n.language === 'fr-CA' ? 'DD/MM/YY' : 'YY/MM/DD')}</span>;
};

export const DateFormatYYYY: React.FC<IDateFormatProps> = ({ value }) => {
  moment.locale(i18n.language);

  return <span>{moment(value).format(i18n.language === 'fr-CA' ? 'DD/MM/YYYY' : 'YYYY/MM/DD')}</span>;
};

export default DateFormat;
