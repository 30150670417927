import axios from 'axios';

import { API_CONTEST, CONTEST_PROMOTION_CODE } from '~/common/API';
import { getCurrentIsoLang } from '~/common/helpers';
import { Contest } from '../contestTypes';

export const ContestHttpClient = new (class {
  private getHeaders = () => ({
    headers: {
      'accept-language': getCurrentIsoLang(),
      'x-ia-channel': 'web',
      'x-ia-user-context': 'client',
    },
  });

  public fetchContest = async (): Promise<Contest> => {
    const response = await axios.get(`${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}`, this.getHeaders());
    return response.data.data as Contest;
  };

  public fetchContestSubscription = async (): Promise<{
    isSubscribed: boolean;
    isPostponed: boolean;
  }> => {
    const response = await axios.get(
      `${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}/participants/self`,
      this.getHeaders(),
    );
    return response.data.data as { isSubscribed: boolean; isPostponed: boolean };
  };

  public subscribeClientToContest = async (): Promise<{
    isSubscribed: boolean;
    isPostponed: boolean;
  }> => {
    const response = await axios.post(
      `${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}/participants`,
      {},
      this.getHeaders(),
    );
    return response.data.data as { isSubscribed: boolean; isPostponed: boolean };
  };

  public incrementClientViewContest = async () => {
    await axios.post(
      `${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}/participants/self/entries`,
      {},
      this.getHeaders(),
    );
  };

  public postponeContest = async () => {
    await axios.post(
      `${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}/participants/self/reminder`,
      {},
      this.getHeaders(),
    );
  };

  public unsubscribeContest = async () => {
    await axios.delete(`${API_CONTEST}/contests/${CONTEST_PROMOTION_CODE}/participants/self`, this.getHeaders());
  };
})();
