import * as React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { IGenericDataTableHeaderProps, IRows } from './types';
import { StyledTableSortLabel } from './styles';

const GenericDataTableHeader: React.FC<IGenericDataTableHeaderProps> = (props) => {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof IRows) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            className={classes.tableHeader}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              headCell.sortable ? <StyledTableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </StyledTableSortLabel>
                : headCell.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(GenericDataTableHeader);
