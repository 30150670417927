import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getPartyV1, getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { IAppRootState } from '~/app/rootReducer';
import { Tooltip, AreaChart, Area, XAxis, YAxis } from 'recharts';
import { IPartyV1 } from '~/stores/party/partyTypes';
import {
  Container,
  PageTitleContainer,
  BoxShadow,
  HeaderPageContainer,
  ContainerRow,
  ContainerLegend,
  Legendbox,
  Label,
  ExpansionPanel,
  LabelBoldSubtitle,
  LabelBoldBigSubtitle,
} from '~/common/styles/baseStyle';
import AccountFilter from '~/common/components/account/HouseholdAccountFilter';
import {
  getNetInvested,
  getIsNetInvestedFetching,
  getIsNetInvestedFetchingFail,
  getIsAccountsFetching,
  getRateOfReturn,
  getIsRateOfReturnFetching,
  getCashFlowSummaryFetching,
  getFilteredAccountIds,
  getCashFlow,
  getAllCurrentFilterAccounts,
  getCashFlowSummaryFetchingFail,
} from '~/stores/account/selectors/accountSelectors';
import {
  fetchNetInvested,
  fetchRateOfReturn,
  fetchCashFlowSummary,
  fetchNetInvestedAborted,
  fetchRateOfReturnAborted,
  fetchCashFlowSummaryAborted,
} from '~/stores/account/actions/accountActions';

import { getSelectedAccountIds, isCashFlowValid } from '~/common/accountsHelpers';
import { IAccount, ICashFlow } from '~/stores/account/accountTypes';
import NoData from '~/common/components/noData/NoData';
import { Trans } from 'react-i18next';
import { MonetaryValue } from '~/common/components';
import i18n from '~/app/i18n';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import styled from 'styled-components';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { Tooltip as Tooltip2, Grid, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material/';

import Info from '@mui/icons-material/Info';
import ExchangeRateDisclaimer from '~/common/components/loadStamp/ExchangeRateDisclaimer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PerformanceDisclaimerType } from '~/common/types';
import { DateFormatYYYY } from '~/common/components/date-format/DateFormat';
import PrintButton from '~/common/components/print-button/PrintButton';
import { FakeHeaderTableGrid, RowCash } from '~/common/styles/common.style';
import Colors from '~/common/themes/colors';
import { appTheme } from '~/common/themes/theme';
import Loading from '../../common/components/Loading/Loading';

export const RateOfReturn = styled.div`
  width: 160px;
  height: 35px;
  margin-right: 3px;
  text-align: center;
`;

export const RateOfReturnContent = styled.div`
  width: 160px;
  height: 90px;
  margin-right: 3px;
  background-color: #fff;
`;

export const RowCenter = styled(ContainerRow)`
  justify-content: center;
  align-items: center;
  height: 90px;
`;

export const Inception = styled(RateOfReturn)`
  margin-right: 0px;
  background-color: rgba(0, 61, 165, 1);
  width: 165px;
`;

export const InceptionContent = styled(RateOfReturnContent)`
  margin-right: 0px;
  background-color: rgba(0, 61, 165, 1);
  color: #fff;
  width: 165px;
`;

export const Disclaimer = styled.div`
  margin-top: 10px;

  & > div {
    font-size: 13px;
  }

  & > ul {
    padding-left: 20px;
  }

  & > ul > li {
    font-size: 13px;
  }
`;

interface IPropsFromState {
  selectedAccountIds: string[];
  party: IPartyV1;
  culture: string;
  performance: any;
  isAccountsFetching: boolean;
  accounts: IAccount[];
  isPerformanceFetching: boolean;
  isPerformanceFetchingFail: boolean;
  rateOfReturn: any[];
  didSetMarketDataRealtime: boolean;
  isRateOfReturnFetching: boolean;
  isCashFlowFetching: boolean;
  isCashFlowFetchingFail: boolean;
  isNetInvestedFetching: boolean;
  cashFlow: ICashFlow;
}

interface IPropsFromDispatch {
  fetchNetInvested: typeof fetchNetInvested;
  fetchRateOfReturn: typeof fetchRateOfReturn;
  fetchCashFlowSummary: typeof fetchCashFlowSummary;
  fetchNetInvestedAborted: typeof fetchNetInvestedAborted;
  fetchRateOfReturnAborted: typeof fetchRateOfReturnAborted;
  fetchCashFlowSummaryAborted: typeof fetchCashFlowSummaryAborted;
}

interface IStatePerformance {
  isPerformanceIsLoad: boolean;
  isRateOfReturnIsLoad: boolean;
  isCashFlowSummaryIsLoad: boolean;
}

export enum NetInvestedIntervalType {
  YEARLY = 'Y',
  MONTHLY = 'M',
  QUARTERLY = 'Q',
}

export class PerformancePage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IStatePerformance> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);

    document.title = i18n.t('performance.documentTitle');
    this.state = {
      isPerformanceIsLoad: false,
      isRateOfReturnIsLoad: false,
      isCashFlowSummaryIsLoad: false,
    };
  }

  private CustomTooltip = (viewBox: any) => {
    const { active, payload } = viewBox;
    if (active && payload) {
      return (
        <div className="boxTooltip">
          {payload[0].payload.date.substring(0, payload[0].payload.date.indexOf('T'))} <br />
          {payload[0].name} <MonetaryValue culture="fr-CA" value={payload[0].value} /> <br />
          {payload[1].name} <MonetaryValue culture="fr-CA" value={payload[1].value} />
        </div>
      );
    }

    return null;
  };

  private AxisXLabel = (value: number) => {
    const { performance } = this.props;

    const itemIndex = (performance.data as any[]).findIndex((w) => w.intervalRatio === value);

    if (itemIndex === 0 || itemIndex === performance.data.length - 1) {
      return '';
    }

    return performance.data[itemIndex].label;
  };

  private getRateofReturn = (period: string, color?: string) => {
    const { rateOfReturn, culture } = this.props;
    if (rateOfReturn === undefined || rateOfReturn.length === 0) {
      return (
        <RowCenter>
          <LabelBoldBigSubtitle style={{ color: color || '' }}>N/A</LabelBoldBigSubtitle>
        </RowCenter>
      );
    }

    const rate = rateOfReturn.filter((w) => w.period === period);
    if (rate.length > 0 && rate[0].performance !== undefined) {
      return (
        <RowCenter>
          <LabelBoldBigSubtitle style={{ display: 'contents', color: color || '' }}>
            <DecimalValue
              value={rate[0].performance}
              culture={culture}
              amountOfDigits={2}
              areTrailingZerosVisible={false}
            />
            {rate[0].performance !== undefined ? '%' : null}
          </LabelBoldBigSubtitle>
        </RowCenter>
      );
    }

    return (
      <RowCenter>
        <LabelBoldBigSubtitle style={{ color: color || '' }}>N/A</LabelBoldBigSubtitle>
      </RowCenter>
    );
  };

  private getRateofReturnTitle = (period: string, label: string) => {
    const { rateOfReturn } = this.props;
    if (rateOfReturn === undefined) {
      return;
    }
    const rate = rateOfReturn.filter((w) => w.period === period);
    return (
      <RateOfReturn style={{ backgroundColor: appTheme.palette.primary.main }}>
        <Typography variant="body2" component="p">
          {label}
        </Typography>
        {rate.length > 0 && (
          <Typography variant="subtitle1" component="p">
            <DateFormatYYYY value={rate[0].startDate} /> - <DateFormatYYYY value={rate[0].endDate} />
          </Typography>
        )}
        {rate.length <= 0 && (
          <Typography variant="subtitle1" component="p">
            -
          </Typography>
        )}
      </RateOfReturn>
    );
  };

  private getRateofReturnTitleInception = () => {
    const { rateOfReturn } = this.props;
    if (rateOfReturn === undefined) {
      return;
    }
    const rate = rateOfReturn.filter((w) => w.period === 'INCEPTION');
    if (rate.length > 0) {
      return (
        <Inception>
          <Typography variant="body2" component="p" style={{ color: Colors.white }}>
            <Trans i18nKey="performance.inception" />
          </Typography>
          <Typography variant="subtitle1" component="p" style={{ color: Colors.white }}>
            <DateFormatYYYY value={rate[0].startDate} />* - <DateFormatYYYY value={rate[0].endDate} />
          </Typography>
        </Inception>
      );
    }
    return (
      <Inception>
        <Trans i18nKey="performance.inception" />
      </Inception>
    );
  };

  private getRateOfReturnDisclaimer = () => {
    const { rateOfReturn } = this.props;

    if (rateOfReturn === undefined || rateOfReturn.length === 0) {
      return null;
    }

    const { disclaimerType } = rateOfReturn[0];
    return this.getRateOfReturnDisclaimerText(disclaimerType);
  };

  private getRateOfReturnDisclaimerText = (disclaimerType: string) => {
    switch (disclaimerType) {
      case PerformanceDisclaimerType.CRM2:
        return i18n.t('performance.disclaimerCRM2');
      case PerformanceDisclaimerType.SinceInceptionIAS:
        return i18n.t('performance.disclaimerSinceInceptionIAS');
      case PerformanceDisclaimerType.SinceInceptionHW:
        return i18n.t('performance.disclaimerSinceInceptionHW');
      default:
        return null;
    }
  };

  public onModifyAccountFilter = () => {
    if (this.props.isNetInvestedFetching) {
      this.props.fetchNetInvestedAborted();
    }
    if (this.props.isRateOfReturnFetching) {
      this.props.fetchRateOfReturnAborted();
    }
    if (this.props.isCashFlowFetching) {
      this.props.fetchCashFlowSummaryAborted();
    }
    this.setState({ isPerformanceIsLoad: false, isRateOfReturnIsLoad: false, isCashFlowSummaryIsLoad: false });
  };

  private renderCashFlowByCategory = (type: string, amount: number, amountYTD: number) => {
    const { culture } = this.props;
    return (
      <Grid container>
        <Grid xs={5} item>
          <Typography variant="body1" component="span">
            {i18n.t(`performance.${type}`) as string}{' '}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography variant="body1" component="span">
            <MonetaryValue value={amount} culture={culture} />
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography variant="body1" component="span">
            <MonetaryValue value={amountYTD} culture={culture} />
          </Typography>
        </Grid>
        <Grid xs={1} item />
      </Grid>
    );
  };

  private renderCashFlowDetail = (category: string, previousYear: any, currentYear: any) => {
    const { culture } = this.props;

    const previousTotal = Object.values(previousYear).reduce((a: any, b: any) => a + b);
    const ytdTotal = Object.values(currentYear).reduce((a: any, b: any) => a + b);

    return (
      <ExpansionPanel style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="#003da5" />}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Grid container>
            <Grid xs={5} item>
              <Typography variant="body1" component="span">
                {i18n.t(`performance.${category}`) as string}{' '}
              </Typography>
            </Grid>
            <Grid xs={4} item style={{ paddingRight: 55 }}>
              <Typography variant="body1" component="span">
                <MonetaryValue value={previousTotal as number} culture={culture} />
              </Typography>
            </Grid>
            <Grid xs={3} item style={{ paddingRight: 45 }}>
              <Typography variant="body1" component="span">
                <MonetaryValue value={ytdTotal as number} culture={culture} />
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 10 }}>
            {Object.keys(previousYear).map((keyName, i) => (
              <Grid container key={category + i}>
                <Grid xs={4} item>
                  <Typography variant="body1" component="span">
                    {i18n.t(`performance.${keyName}`) as string}{' '}
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <Typography variant="body1" component="span">
                    <MonetaryValue value={previousYear[keyName]} culture={culture} />
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <Typography variant="body1" component="span">
                    <MonetaryValue value={currentYear[keyName]} culture={culture} />
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </ExpansionPanel>
    );
  };

  public render() {
    const { isAccountsFetching, accounts, isPerformanceFetchingFail, culture, selectedAccountIds } = this.props;
    const defaultAccountId = getSelectedAccountIds(selectedAccountIds, accounts);

    if (isAccountsFetching) {
      return (
        <Container>
          <Loading show />
        </Container>
      );
    }

    if (!this.state.isPerformanceIsLoad && !this.props.isPerformanceFetching && accounts.length > 0) {
      this.props.fetchNetInvested(defaultAccountId);
      this.setState({ isPerformanceIsLoad: true });
    }

    if (!this.state.isRateOfReturnIsLoad && !this.props.isRateOfReturnFetching && accounts.length > 0) {
      this.props.fetchRateOfReturn(defaultAccountId);
      this.setState({ isRateOfReturnIsLoad: true });
    }

    if (
      !this.state.isCashFlowSummaryIsLoad &&
      !this.props.isCashFlowFetching &&
      accounts.length > 0 &&
      this.state.isPerformanceIsLoad &&
      !this.props.isPerformanceFetching
    ) {
      this.props.fetchCashFlowSummary(defaultAccountId);
      this.setState({ isCashFlowSummaryIsLoad: true });
    }

    let perfsData = [];

    let previousYearFees: { totalFees?: number } = { totalFees: 0 };
    let ytdFees: { totalFees?: number } = { totalFees: 0 };
    let previousYearIncome: { totalIncome?: number } = { totalIncome: 0 };
    let ytdIncome: { totalIncome?: number } = { totalIncome: 0 };

    let previousYearOpeningValue = 0;
    let previousYearDeposit = 0;
    let previousYearWithdraw = 0;
    let previousYearOthers = 0;
    let previousYearChangeInMarketValue = 0;
    let previousYearClosingMarketValue = 0;

    let yearToDateOpeningValue = 0;
    let yearToDateDeposit = 0;
    let yearToDateWithdraw = 0;
    let yearToDateOthers = 0;
    let yearToDateChangeInMarketValue = 0;
    let yearToDateClosingMarketValue = 0;

    if (this.props.performance) {
      perfsData = this.props.performance.data;
    }
    if (this.props.cashFlow && !this.props.isCashFlowFetching && this.state.isCashFlowSummaryIsLoad) {
      const { cashFlow } = this.props;
      yearToDateClosingMarketValue = cashFlow.ytd.closingMarketValue;

      yearToDateDeposit = cashFlow.ytd.deposits === null ? 0 : cashFlow.ytd.deposits;
      yearToDateWithdraw = cashFlow.ytd.withdrawals === null ? 0 : cashFlow.ytd.withdrawals;
      yearToDateOthers = cashFlow.ytd.taxes === null ? 0 : cashFlow.ytd.taxes;

      yearToDateOpeningValue = cashFlow.ytd.openingMarketValue === null ? 0 : cashFlow.ytd.openingMarketValue;

      yearToDateChangeInMarketValue = cashFlow.ytd.changeInMarketValue === null ? 0 : cashFlow.ytd.changeInMarketValue;

      previousYearOpeningValue =
        cashFlow.previousYear.openingMarketValue === null ? 0 : cashFlow.previousYear.openingMarketValue;
      previousYearClosingMarketValue =
        cashFlow.previousYear.closingMarketValue === null ? 0 : cashFlow.previousYear.closingMarketValue;
      previousYearDeposit = cashFlow.previousYear.deposits === null ? 0 : cashFlow.previousYear.deposits;
      previousYearWithdraw = cashFlow.previousYear.withdrawals === null ? 0 : cashFlow.previousYear.withdrawals;
      previousYearOthers = cashFlow.previousYear.taxes === null ? 0 : cashFlow.previousYear.taxes;
      previousYearChangeInMarketValue =
        cashFlow.previousYear.changeInMarketValue === null ? 0 : cashFlow.previousYear.changeInMarketValue;

      previousYearIncome = this.props.cashFlow.previousYear.investmentIncome;
      delete previousYearIncome.totalIncome;
      ytdIncome = this.props.cashFlow.ytd.investmentIncome;
      delete ytdIncome.totalIncome;
      previousYearFees = this.props.cashFlow.previousYear.feesAndCharges;
      delete previousYearFees.totalFees;
      ytdFees = this.props.cashFlow.ytd.feesAndCharges;
      delete ytdFees.totalFees;
    }

    const toAxisY = (decimal: number) => `${decimal / 1000} k`;
    const disclaimer = this.getRateOfReturnDisclaimer();

    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            <Typography variant="h1" component="span" style={{ paddingBottom: '12px' }}>
              Performance
            </Typography>
            <DataLoadStamp isPreviousDay />
          </PageTitleContainer>
          <PageTitleContainer>
            <AccountFilter isRegisterAccountFilter={false} onClose={this.onModifyAccountFilter} />
          </PageTitleContainer>
        </HeaderPageContainer>

        <BoxShadow style={{ height: 355, width: 980 }}>
          {this.props.isPerformanceFetching ? (
            <Loading show position="relative" />
          ) : (
            <Grid container>
              {perfsData.length > 0 ? (
                <AreaChart width={900} height={300} data={perfsData}>
                  <XAxis
                    dataKey="intervalRatio"
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    scale="linear"
                    tickFormatter={this.AxisXLabel}
                    tickLine={false}
                    minTickGap={30}
                    tick={{ fontSize: 14 }}
                  />
                  <YAxis tickFormatter={toAxisY} tick={{ fontSize: 14 }} />
                  <Tooltip content={this.CustomTooltip} />
                  <Area
                    type="monotone"
                    dataKey="totalValue"
                    stroke="#003da5"
                    fill="#003da5"
                    name={i18n.t('landing.marketValue') as string}
                    fillOpacity="1"
                  />
                  <Area
                    type="monotone"
                    dataKey="netInvestment"
                    stroke="#acafae"
                    fill="#acafae"
                    name={i18n.t('landing.netInvestment') as string}
                  />
                </AreaChart>
              ) : isPerformanceFetchingFail ? (
                <Grid container style={{ justifyContent: 'center' }}>
                  <NoData height="277px" text={i18n.t('common.dataNotAvailable')} />
                </Grid>
              ) : (
                <Grid container style={{ justifyContent: 'center' }}>
                  <NoData text={i18n.t('common.noData')} />{' '}
                </Grid>
              )}
              <Grid container style={{ justifyContent: 'center' }}>
                <ContainerLegend style={{ width: 160 }}>
                  <Legendbox color="#acafae" size="14px" />
                  &nbsp; <Trans i18nKey="landing.netInvestment" />
                </ContainerLegend>
                <ContainerLegend style={{ width: 160 }}>
                  <Legendbox color="#003da5" size="14px" />
                  &nbsp; <Trans i18nKey="landing.marketValue" />
                </ContainerLegend>
              </Grid>
            </Grid>
          )}
        </BoxShadow>

        {this.props.isCashFlowFetching || this.props.isNetInvestedFetching ? (
          <Container style={{ height: 150, width: 980 }}>
            <Loading show position="relative" />
          </Container>
        ) : (
          <div>
            {!this.props.isCashFlowFetching && isCashFlowValid(this.props.cashFlow) ? (
              <Grid container style={{ width: '100%', marginTop: 30 }}>
                <FakeHeaderTableGrid container>
                  <Grid xs={5} item style={{ textAlign: 'left' }}>
                    <Trans i18nKey="performance.summary" />
                  </Grid>
                  <Grid xs={3} item>
                    <Trans i18nKey="performance.previousYear" /> ($)
                  </Grid>
                  <Grid xs={3} item>
                    <Trans i18nKey="performance.yearToDate" /> ($)
                  </Grid>
                  <Grid xs={1} item />
                </FakeHeaderTableGrid>
                <RowCash container>
                  <Grid xs={5} item>
                    <Typography variant="body2" component="span">
                      <Trans i18nKey="performance.openingMarket" />{' '}
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body2" component="span">
                      <MonetaryValue value={previousYearOpeningValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body2" component="span">
                      <MonetaryValue value={yearToDateOpeningValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={1} item />
                </RowCash>
                <RowCash container>
                  {this.renderCashFlowByCategory('DEP', previousYearDeposit, yearToDateDeposit)}
                </RowCash>
                <RowCash container>
                  {this.renderCashFlowByCategory('WIT', previousYearWithdraw, yearToDateWithdraw)}
                </RowCash>

                <RowCash container style={{ paddingTop: 0, paddingBottom: 0 }}>
                  {this.renderCashFlowDetail('INCOME', previousYearIncome, ytdIncome)}
                </RowCash>
                <RowCash container style={{ paddingTop: 0, paddingBottom: 0 }}>
                  {this.renderCashFlowDetail('FEES', previousYearFees, ytdFees)}
                </RowCash>
                <RowCash container>
                  {this.renderCashFlowByCategory('OTHER', previousYearOthers, yearToDateOthers)}
                </RowCash>
                <RowCash container>
                  <Grid xs={5} item>
                    <Typography variant="body1" component="span">
                      {i18n.t('performance.changeValue') as string}{' '}
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body1" component="span">
                      <MonetaryValue value={previousYearChangeInMarketValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body1" component="span">
                      <MonetaryValue value={yearToDateChangeInMarketValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={1} item />
                </RowCash>
                <RowCash container>
                  <Grid xs={5} item>
                    <Typography variant="body2" component="span">
                      <Trans i18nKey="performance.closingMarket" />
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body2" component="span">
                      <MonetaryValue value={previousYearClosingMarketValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography variant="body2" component="span">
                      <MonetaryValue value={yearToDateClosingMarketValue} culture={culture} />
                    </Typography>
                  </Grid>
                  <Grid xs={1} item />
                </RowCash>
              </Grid>
            ) : (
              <NoData height="277px" text={i18n.t('common.dataNotAvailable')} />
            )}
          </div>
        )}
        {!this.state.isRateOfReturnIsLoad || this.props.isRateOfReturnFetching ? (
          <Container style={{ height: 150, width: 980 }}>
            <Loading show position="relative" />
          </Container>
        ) : (
          <div>
            <Typography variant="h3" component="div" style={{ marginTop: 30, marginBottom: 10 }}>
              <Trans i18nKey="performance.moneyWeighted" />
              <Tooltip2 title={<Trans i18nKey="performance.toolTip" />} placement="top" arrow>
                <IconButton>
                  <Info style={{ color: 'rgba(0, 61, 165, 1)' }} />
                </IconButton>
              </Tooltip2>
            </Typography>

            <Container>
              <ContainerRow>
                {this.getRateofReturnTitle('YTD', i18n.t('performance.yearToDate'))}
                {this.getRateofReturnTitle('1', `1 ${i18n.t('performance.year')}`)}
                {this.getRateofReturnTitle('3', `3 ${i18n.t('performance.years')}`)}
                {this.getRateofReturnTitle('5', `5 ${i18n.t('performance.years')}`)}
                {this.getRateofReturnTitle('10', `10 ${i18n.t('performance.years')}`)}
                {this.getRateofReturnTitleInception()}
              </ContainerRow>
              <ContainerRow>
                <RateOfReturnContent>{this.getRateofReturn('YTD')}</RateOfReturnContent>
                <RateOfReturnContent>{this.getRateofReturn('1')}</RateOfReturnContent>
                <RateOfReturnContent>{this.getRateofReturn('3')}</RateOfReturnContent>
                <RateOfReturnContent>{this.getRateofReturn('5')}</RateOfReturnContent>
                <RateOfReturnContent>{this.getRateofReturn('10')}</RateOfReturnContent>
                <InceptionContent>{this.getRateofReturn('INCEPTION', Colors.white)}</InceptionContent>
              </ContainerRow>
            </Container>
          </div>
        )}

        <ExchangeRateDisclaimer forceOff />
        {disclaimer && <Label>* {disclaimer} </Label>}
        <Disclaimer>
          <LabelBoldSubtitle style={{ color: Colors.codGray }}>
            <Trans i18nKey="performance.disclaimerDefinition" />
          </LabelBoldSubtitle>
          <ul>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer1" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer2" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer3" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer4" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer5" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer6" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer7" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer8" />
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span">
                <Trans i18nKey="performance.disclaimer9" />
              </Typography>
            </li>
          </ul>
        </Disclaimer>
        <PrintButton />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;
  return {
    party: getPartyV1(state),
    selectedAccountIds: getFilteredAccountIds(state),
    accounts: getAllCurrentFilterAccounts(state),
    isAccountsFetching: getIsAccountsFetching(state),
    culture: system.culture,
    performance: getNetInvested(state),
    rateOfReturn: getRateOfReturn(state),
    isPerformanceFetching: getIsNetInvestedFetching(state),
    isPerformanceFetchingFail: getIsNetInvestedFetchingFail(state),
    isRateOfReturnFetching: getIsRateOfReturnFetching(state),
    isCashFlowFetching: getCashFlowSummaryFetching(state),
    isCashFlowFetchingFail: getCashFlowSummaryFetchingFail(state),
    isNetInvestedFetching: getIsNetInvestedFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    cashFlow: getCashFlow(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchNetInvested,
      fetchRateOfReturn,
      fetchCashFlowSummary,
      fetchNetInvestedAborted,
      fetchRateOfReturnAborted,
      fetchCashFlowSummaryAborted,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PerformancePage);
