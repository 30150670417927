import { User, UserManager } from 'oidc-client';
import { call, put, takeLatest } from 'redux-saga/effects';

import { appGlobalError } from '../../system/actions/systemActions';
import { fetchTokenError, fetchTokenSuccessful } from '../actions/oidcActions';
import { oidcConfig } from '../oidcConfig';
import { oidcActionTypes } from '../oidcTypes';

export function* handleFetchToken() {
  try {
    const userManager = new UserManager(oidcConfig);

    const user = (yield call([userManager, userManager.signinSilent])) as User;

    yield put(fetchTokenSuccessful(user));
  } catch (err) {
    console.log(err);
    yield put(appGlobalError(err as any));
    yield put(fetchTokenError());
  }
}

function* oidcSaga() {
  yield takeLatest(oidcActionTypes.FETCH_TOKEN, handleFetchToken);
}

export default oidcSaga;
