import React from 'react';
import { TotalLabel } from './LandingPage.style';
import { Skeleton, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Separator } from '~/common/components/separator/Separator';
import i18n from '~/app/i18n';
import { MARKET_SECTION_HEIGHT, PartyNameWithLoadingSkeleton } from './LandingPage';
import { IPartyV1 } from '~/stores/party/partyTypes';

import { Icon } from '~/common/components/advisor/styles/advisorCard.style';
import { Address as AddressIcon, Laptop as LaptopIcon, Phone as PhoneIcon, User as UserIcon } from '~/common/svg';
import Colors from '~/common/themes/colors';

export const TotalMarketSectionSkeleton = () => (
  <>
    <TotalLabel style={{ marginTop: '3px', paddingBottom: '6px' }}>{`${i18n.t('common.totalMarketValue')}`}</TotalLabel>

    <Skeleton variant="text" width="80%" height={100} />

    <Skeleton variant="text" width="40%" height={40} sx={{ marginTop: 3 }} />
    <Skeleton variant="text" width="50%" height={80} />

    <div style={{ height: '100%' }} />
    <Skeleton variant="text" width="95%" height={50} />
    <Skeleton variant="text" width="50%" height={50} />
  </>
);

export const LandingMarketSectionSkeleton = () => (
  <Stack direction="row" height={MARKET_SECTION_HEIGHT}>
    <Stack direction="column" width="478px" height="100%" p={2} pl="20px" sx={{ bgcolor: Colors.quillGray }}>
      <TotalMarketSectionSkeleton />
    </Stack>
    <Stack
      direction="column"
      width="100%"
      height="100%"
      alignItems="center"
      alignContent="center"
      sx={{ bgcolor: 'white' }}
      p={3}
    >
      <Skeleton animation="wave" variant="rounded" width="100%" height="100%" />
    </Stack>
  </Stack>
);

export const LandingHeaderSectionSkeleton = ({ party }: { party: IPartyV1 }) => (
  <PartyNameWithLoadingSkeleton party={party} />
);

export const LandingAccountsSectionSkeleton = () => (
  <>
    <Stack direction="column" sx={{ bgcolor: 'white' }} mt={4}>
      <Stack direction="row" minHeight="60px" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" component="div" padding="14px 14px 14px 22px">
          <Trans i18nKey="landing.allAccounts" />
        </Typography>

        <Skeleton variant="text" width="150px" height={35} sx={{ marginRight: '16px' }} />
      </Stack>
      <Separator style={{ width: '100%', margin: '0px' }} />
      <Stack direction="row" py={1} pt="12px" px={2} justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width="400px" height={35} />
        <Skeleton variant="text" width="200px" height={40} />
      </Stack>
      <Stack direction="column" width="100%" alignItems="center" alignContent="center" sx={{ bgcolor: 'white' }} m={2}>
        <Stack direction="column" width="100%" height="100%" sx={{ bgcolor: '#EFF1F4' }} p="20px">
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <Stack key={index} direction="row" justifyContent="space-between" alignItems="center" py={0} my={0}>
                <Skeleton variant="text" width="400px" height={40} />
                <Skeleton variant="text" width="100px" height={40} style={{ marginRight: '80px' }} />
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>

    <Stack direction="column" width="100%" height="100%" mt={5} mb={3}>
      <Skeleton variant="text" width="100%" height={20} />
      <Skeleton variant="text" width="100%" height={20} />
      <Skeleton variant="text" width="75%" height={20} />
    </Stack>
  </>
);

export const LandingContactSectionSkeleton = () => (
  <Stack direction="column" p={3} width="100%" height="100%" mt={3} sx={{ border: 1, backgroundColor: 'white' }}>
    <Stack direction="row" mb={2}>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <Stack key={index} direction="row" width="100%">
            <Stack direction="row" gap={2} width="100%">
              <Icon>{UserIcon(40)}</Icon>
              <Stack direction="column" gap={0.5} width="100%">
                <Skeleton variant="rectangular" width="55%" height={22} />
                <Skeleton variant="rectangular" width="55%" height={16} style={{ marginTop: '12px' }} />
                <Skeleton variant="rectangular" width="40%" height={16} />
                <Skeleton variant="rectangular" width="75%" height={16} />
              </Stack>
            </Stack>
          </Stack>
        ))}
    </Stack>
    <Separator style={{ width: '100%' }} />
    <Stack direction="row" mt={2}>
      <Stack direction="row" width="100%">
        <Stack direction="row" gap={2} width="100%">
          <Icon>{AddressIcon(40)}</Icon>
          <Stack direction="column" gap={0.5} width="100%">
            <Skeleton variant="rectangular" width="45%" height={22} />
            <Skeleton variant="rectangular" width="55%" height={16} />
            <Skeleton variant="rectangular" width="35%" height={16} />
            <Skeleton variant="rectangular" width="25%" height={16} />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" width="100%">
        <Stack direction="row" gap={2} width="100%">
          <Icon>{PhoneIcon(40)}</Icon>
          <Stack direction="column" gap={0.5} width="100%">
            <Skeleton variant="rectangular" width="45%" height={22} />
            <Skeleton variant="rectangular" width="40%" height={16} style={{ marginTop: '6px' }} />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" width="100%">
        <Stack direction="row" gap={2} width="100%">
          <Icon>{LaptopIcon(40)}</Icon>
          <Stack direction="column" gap={0.5} width="100%">
            <Skeleton variant="rectangular" width="40%" height={22} />
            <Skeleton variant="rectangular" width="85%" height={16} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
