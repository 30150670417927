import * as React from 'react';
import { Location } from 'history';
import NumberQI from '~/common/components/changeValue/NumberQI';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { RouteNames } from '~/app/appTypes';
import { Link } from 'react-router-dom';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import { DataTable, IColumns } from '~/common/components/data-table/data-table';
// import { Typography } from '@mui/material';

export interface IProps {
  data: any[]
}

export interface IPropsFromState {
  previousPage: string,
}

export const Peers = ({ previousPage, data }: { previousPage: string; data: any[] }) => {
  const peersData = data?.map((row) => (
    [
      (i18n.language === 'en-CA' ? row.nameEn : row.nameFr),
      <Link
        to={{
          pathname: `${RouteNames.positionOverview}/`,
          key: row.symbol,
          state: {
            symbol: row.marketIdentificationCode ? `${row.marketIdentificationCode}:${row.symbol}` : row.symbol,
            source: previousPage,
          },
        }}
      >{row.symbol}
      </Link>,
      <NumberQI isFormatLargeNumber value={row.last} isMoney />,
      <ChangeValue percent={row.percentChange} value={row.change} alignRight />,
      <NumberQI isFormatLargeNumber value={row.marketCap} isMoney />,
    ]
  ));

  const columns: IColumns[] = [
    {
      header: 'peers.companyName',
      align: 'left',
    },
    {
      header: 'peers.symbol',
      align: 'left',
    },
    {
      header: 'peers.current',
      align: 'right',
    },
    {
      header: 'peers.change',
      align: 'right',
    },
    {
      header: 'peers.marketCap',
      align: 'right',
    },
  ];

  return (
    <div>
      <DataTable data={peersData} columns={columns} title="stockOverview.peers" boldFirstRow />
    </div>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    previousPage: (state.router.location as Location<{ source: string }>).state.source,
  };
}

export default connect(mapStateToProps)(Peers);
