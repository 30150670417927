import { SortDirection } from '~/app/appTypes';

export interface IPartyState {
  readonly partyV1: IPartyV1;
  readonly accountShare: IAccountAccessSharingModel[];
  readonly partiesShared: IPartyV1[];
  readonly accountSearch: IPartyV1 | null;
  readonly isFetching: boolean;
  readonly isFetchingPartySearch: boolean;
  readonly isFetchingSavePreferredAccount: boolean;
  readonly isSavePreferredAccountSuccess: boolean;
  readonly isFetchingPartyUser: boolean;
  readonly dataExpiration?: Date;
  readonly isHouseHoldingView: boolean;
  readonly isProgramView: boolean;
  readonly isFetchingAccesSharing: boolean;
  readonly isFetchingPartyUserHasError: boolean;
  readonly isSavingConsentPreferences: boolean;
  readonly isConsentVerbiageLoaded: boolean;
  readonly isConsentVerbiageFetching: boolean;
  readonly isConsentVerbiageFailed: boolean;
  readonly consentVerbiages: IConsentVerbiages[];
  readonly isDocumentDeliveryNotificationSaving: boolean;
  readonly isDocumentDeliveryNotificationFail: boolean;
  readonly isSavingConsentPreferencesFail: boolean;
  readonly isSavingConsentPreferencesSuccess: boolean;
}

export interface IConsentVerbiages {
  consentVerbiageVersion: string;
  consentPurpose: string;
  value: boolean;
  frenchText: string[];
  englishText: string[];
  isLegacy: boolean;
}

export interface IAccountAccessSharingModel {
  id: string;
  partyId: string;
  name: string;
  role: string;
  noRole: string;
}

export interface IPartySearchRequest {
  readonly lastName: string;
  readonly firstName: string;
  readonly householdKey: string;
}

export interface IPartyV1 {
  id: string;
  businessName: string;
  firstName: string;
  lastName: string;
  accounts: IPartyAccount[];
  preferedAccountIds: string[];
  partyTypeCode: string;
  metadata: IMetadata[];
  languageCode: string;
  emails: IPartyEmail[];
}

export interface IPartyEmail {
  emailTypeCode: string; // "main"
  emailAddress: string;
}

export interface IPartyAccount {
  id: string;
  role: string;
  noRole: number;
  repCode: string;
}

export interface IMetadata {
  key: string;
  value: string[];
  system: string;
  lastModified: Date;
}

export interface IUpdateDeliveryPreferencesMetadata {
  showOn?: Date;
  reviewedOn?: Date;
}

export interface IConsentPromptMetadata {
  show: boolean;
  showOn: Date | undefined;
  postponements: number | undefined;
}

export interface IConsentPreferences {
  consentPurpose: string;
  value: boolean;
  consentVerbiageVersion: string;
}

export interface ISortViewsMetadata {
  accountView?: ICommonSortDetails;
  investedView?: IInvestedCapitalSortDetails;
  bookCostView?: IBookCostSortDetails;
  consolidateView?: ICommonSortDetails;
}

export interface ISortDetails {
  sortDirection: SortDirection;
  sortColumn: any;
}

export interface ICommonSortDetails extends ISortDetails {
  sortColumn: SortColumnKeys.DESCRIPTION | SortColumnKeys.QUANTITY | SortColumnKeys.MK_PRICE | SortColumnKeys.MK_VALUE;
}

export interface IInvestedCapitalSortDetails extends ISortDetails {
  sortColumn: SortColumnKeys.DESCRIPTION | SortColumnKeys.QUANTITY | SortColumnKeys.MK_PRICE_VALUE | SortColumnKeys.INV_CAP | SortColumnKeys.INV_CAP_GL;
}

export interface IBookCostSortDetails extends ISortDetails {
  sortColumn: SortColumnKeys.DESCRIPTION | SortColumnKeys.QUANTITY | SortColumnKeys.BC | SortColumnKeys.UNREALIZED_GL;
}

export const enum HoldingViewKeys {
  ACCOUNT_VIEW = 'accountView',
  INVESTED_VIEW = 'investedView',
  BOOK_COST_VIEW = 'bookCostView',
  CONSOLIDATE_VIEW = 'consolidateView',
}

export const enum SortColumnKeys {
  DESCRIPTION = 'description',
  QUANTITY = 'quantity',
  MK_PRICE = 'mkPrice',
  MK_VALUE = 'mkValue',
  MK_PRICE_VALUE = 'mkPriceValue',
  INV_CAP = 'invCap',
  INV_CAP_GL = 'invCapGL',
  BC = 'bookCost',
  UNREALIZED_GL = 'unrealizedGL',
}

export const enum PartyActionTypes {
  FETCH_PARTY_SEARCH = '[Party Search] Fetch',
  FETCH_PARTY_SEARCH_SUCCESSFUL = '[Party Search] Fetch Successful',
  FETCH_PARTY_SEARCH_NOTFOUND = '[Party Search] Fetch Not found',
  FETCH_PARTY_SEARCH_FAILED = '[Party Search] Fetch Failed',
  FETCH_PARTY_SEARCH_ABORTED = '[Party Search] Fetch Aborted',

  FETCH_ACCES_SHARING = '[Party Share] Fetch',
  FETCH_ACCES_SHARING_SUCCESSFUL = '[Share] Fetch Successful',
  FETCH_ACCES_SHARING_FAIL = '[Share] Fetch Failed',

  FETCH_PARTY = '[Party] Fetch',
  FETCH_PARTY_SUCCESSFUL = '[Party] Fetch Successful',
  FETCH_PARTY_NOTFOUND = '[Party] Fetch Not found',
  FETCH_PARTY_FAILED = '[Party] Fetch Failed',
  FETCH_PARTY_ABORTED = '[Party] Fetch Aborted',

  SAVE_PREFERRED_ACCOUNT = '[Party PreferredAccount] Save',
  SAVE_PREFERRED_ACCOUNT_SUCCESSFUL = '[Party PreferredAccount] Save Successful',
  SAVE_PREFERRED_ACCOUNT_FAILED = '[Party PreferredAccount] Save FAILED',
  RESET_PARTY_PREFERED_ACCOUNT_SAVING = '[Party PreferredAccount SAVING] RESET',

  SAVE_SHARE_ACCOUNT = '[Party Share Account] Save',
  SAVE_SHARE_ACCOUNT_SUCCESSFUL = '[Party Share Account] Save Successful',
  DELETE_SHARE_ACCOUNT = '[Party Delete Share] delete',
  DELETE_SHARE_ACCOUNT_SUCCESSFUL = '[Party Delete Share] Delete Successful',

  TOGGLE_HOUSEHOLD_VIEW = '[Party HouseholdView] Toggle',
  TOGGLE_PROGRAM_VIEW = '[ProgramView] Toggle',

  SAVE_DOCUMENT_DELIVERY_NOTIFICATION = '[Party Document Delivery Notification] Save',
  SAVE_DOCUMENT_DELIVERY_NOTIFICATION_SUCCESSFUL = '[Party Document Delivery Notification] Save Successful',
  SAVE_DOCUMENT_DELIVERY_NOTIFICATION_FAILED = '[Party Document Delivery Notification] Save FAILED',

  SAVE_UPDATE_DELIVERY_PREFERENCES_METADATA = '[Party Update Delivery Preference Metadata] Save',
  SAVE_CONSENT_PROMPT_METADATA = '[Party Consent Prompt Metadata] Save',
  SAVE_MARKET_DATA_REALTIME_PREFERENCES_METADATA = '[Party Update Market Data Real-time Metadata] Save',
  SAVE_INTRADAY_CHANGE_PREFERENCES_METADATA = '[Party Update Intraday Change Metadata] Save',
  SAVE_HOLDING_SORT_PREFERENCES_METADATA = '[Party Holding Sort Preferences Metadata] Save',
  INIT_AUTO_MARKET_DATA_UPDATE = '[Party Auto Update Market Data] Init',

  SAVE_CONSENT_PREFERENCES = '[ClientConsent] Save',
  SAVE_CONSENT_PREFERENCES_SUCCESSFUL = '[ClientConsent] Save Successful',
  SAVE_CONSENT_PREFERENCES_FAILED = '[ClientConsent] Save Failed',

  LOAD_CONSENT_VERBIAGE = '[ClientConsent] Load Current Verbiage',
  LOAD_CONSENT_VERBIAGE_SUCCESSFUL = '[ClientConsent] Load Current Verbiage Successful',
  LOAD_CONSENT_VERBIAGE_FAILED = '[ClientConsent] Load Current Verbiage Failed',

  UPDATE_PARTY_CONSENTS = '[ClientConsent] Update Client Consents',
  RESET_SAVE_CONSENT_PREFERENCES = '[ClientConsent] Resset Save Client Consents',
}
