import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import { Trans } from 'react-i18next';
import { Button, Dialog, Stack } from '@mui/material';
import background from './background.jpg'; // relative path to image
import {
  LinkButton,
  BackgroundDivImage,
  ButtonsContainer,
  Content,
  InlineDiv,
  MarginDiv,
  StyledDiv,
  Text,
  Title,
  TextGrey,
} from './KYCPrompt.style';
import Loading from '~/common/components/Loading/Loading';
import { Link } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { saveKYCRemindLater } from '~/stores/kyc/kycActions';
import { getShowKYCPrompt } from '~/stores/kyc/kycSelectors';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import AccessTime from '@mui/icons-material/AccessTime';
import { KycReviewIdsType } from '~/stores/kyc/kycTypes';

interface IPropsFromState {
  isShowPromptFetching: boolean;
  showPrompt: boolean;
  reviewIds: KycReviewIdsType;
  retryCount: number;
  party: IPartyV1;
}

interface IPropsFromDispatch {
  saveKYCRemindLater: typeof saveKYCRemindLater;
}

interface ILocalState {
  isVisible: boolean;
}

class KYCPrompt extends React.Component<IPropsFromState & IPropsFromDispatch, ILocalState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    if (this.state) {
      this.setState({ isVisible: this.props.showPrompt });
    } else {
      this.state = { isVisible: this.props.showPrompt };
    }
  }

  close = () => {
    this.setState({ isVisible: false });
  };

  remindLater = () => {
    this.props.saveKYCRemindLater(this.props.reviewIds);
    this.setState({ isVisible: false });
  };

  getIsOpen() {
    return this.state.isVisible;
  }

  getRetryCount() {
    return this.props.retryCount;
  }

  renderSplash = () => (
    <Content>
      <StyledDiv>
        <InlineDiv>
          <Title size="lg">
            <Trans i18nKey="KYC.prompt.updateYourFinancialInfo" />
          </Title>
          <Text>
            <Trans i18nKey="KYC.prompt.description" />
          </Text>
        </InlineDiv>
        <BackgroundDivImage>
          <img src={background} />
        </BackgroundDivImage>
      </StyledDiv>
      <MarginDiv>
        <Title size="md">
          <Trans i18nKey="KYC.prompt.dear" values={{ username: this.props.party.firstName }} />
        </Title>
      </MarginDiv>
      <MarginDiv>
        <Text>
          {this.getRetryCount() > 1 ? (
            <Trans i18nKey="KYC.prompt.reviewDescriptionFinal" />
          ) : (
            <Trans i18nKey="KYC.prompt.reviewDescription" />
          )}
        </Text>
        <TextGrey>
          <Stack direction="row" spacing={1}>
            <AccessTime style={{ marginRight: '6px' }} />
            <Trans i18nKey="KYC.prompt.timeToComplete" />
          </Stack>
        </TextGrey>
      </MarginDiv>
      <ButtonsContainer style={{ marginTop: '16px' }}>
        <Link to={RouteNames.kyc}>
          <Button variant="contained" color="secondary">
            <Trans i18nKey="KYC.prompt.update" />
          </Button>
        </Link>
        <LinkButton onClick={this.remindLater}>
          <Trans i18nKey="KYC.prompt.remindLater" />
        </LinkButton>
      </ButtonsContainer>
    </Content>
  );

  public render() {
    if (!this.state.isVisible) {
      return <></>;
    }
    const loading = this.props.isShowPromptFetching;
    return (
      <Dialog open={this.getIsOpen()} fullWidth maxWidth="md" scroll="body">
        {loading && <Loading show />}
        {!loading && this.renderSplash()}
      </Dialog>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    showPrompt: getShowKYCPrompt(state),
    party: getPartyV1(state),
    isShowPromptFetching: state.kyc.showPromptFetching,
    reviewIds: state.kyc.reviewIds,
    retryCount: state.kyc.retryCount,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveKYCRemindLater,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KYCPrompt);
