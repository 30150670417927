import * as React from 'react';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import { Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import NumberQI from './NumberQI';
import { NotFoundAtProvider } from './NotFoundAtProvider';

interface IChangeValueProp {
  value?: number;
  percent?: number;
  fontSize?: string;
  decimalOverride?: number;
  decimalOverrideForPercent?: number;
  notFoundStyleRules?: {
    style?: React.CSSProperties;
    size: 'small' | 'large' | 'default';
  };
  singleLineFormat?: boolean;
  arrowFontSize?: string;
  mainContainerStyleRules?: {
    style?: React.CSSProperties;
  };
  alignRight?: boolean;
  colorful?: boolean;
  signType?: 'parentheses' | 'symbol' | 'none';
  operationType?: 'positive' | 'negative' | 'neutral';
  noArrow?: boolean;
  currency?: string;
  noShowValuesSign?: boolean;
}

const getColor = (color: string, colorful?: boolean): string => {
  if (colorful) {
    if (color === 'red') {
      return 'red';
    }
    if (color === 'green') {
      return 'green';
    }
    return '#003DA5';
  }
  if (color === 'red') {
    return '#e71313';
  }
  if (color === 'green') {
    return '#318500';
  }
  return '#003DA5';
};

const Container = styled.div<{ size?: string; color: string; alignRight?: boolean; colorful?: boolean }>`
  color: ${(props) => getColor(props.color, props.colorful)};
  font-weight: 'bold';
  font-size: ${(props) => (props.size ? props.size : '14px')};
  display: ${(props) => (props.alignRight === undefined || props.alignRight === false ? 'flex' : '')};
  justify-content: ${(props) =>
    props.alignRight === undefined || props.alignRight === false ? 'flex-start' : 'flex-end'};
  align-items: center;
`;

export const ChangeValue: React.FC<IChangeValueProp> = ({
  value,
  percent,
  fontSize,
  decimalOverride,
  decimalOverrideForPercent,
  notFoundStyleRules,
  singleLineFormat,
  arrowFontSize,
  mainContainerStyleRules,
  alignRight,
  colorful,
  signType = 'parentheses',
  operationType = 'positive',
  noArrow,
  currency,
  noShowValuesSign,
}: IChangeValueProp) => {
  let color = 'green';
  if (value === undefined && percent === undefined) {
    return <NotFoundAtProvider size={notFoundStyleRules?.size} style={notFoundStyleRules?.style} />;
  }
  if ((value !== undefined && value < 0) || (percent !== undefined && percent < 0)) {
    color = 'red';
    operationType = 'negative';
  } else if ((value !== undefined && value > 0) || (percent !== undefined && percent > 0)) {
    operationType = 'positive';
  } else {
    color = '#003DA5';
    operationType = 'neutral';
  }

  arrowFontSize = arrowFontSize === undefined ? '22' : arrowFontSize;
  noArrow = noArrow === undefined ? false : noArrow;
  noShowValuesSign = noShowValuesSign === undefined ? true : noShowValuesSign;
  decimalOverride = decimalOverride !== undefined && decimalOverride >= 0 ? decimalOverride : undefined;
  decimalOverrideForPercent = decimalOverrideForPercent !== undefined && decimalOverrideForPercent >= 0 ? decimalOverrideForPercent : undefined;

  return (
    <>
      {singleLineFormat === undefined || singleLineFormat === false ? (
        <Container size={fontSize} color={color} colorful={colorful} alignRight={alignRight}>
          <Row middle="xs">
            <Col
              xs={alignRight === undefined || alignRight === false ? 8 : 9}
              style={alignRight === undefined || alignRight === false ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              <div>
                <NumberQI value={percent} isPercent decimalOverride={decimalOverrideForPercent} />
              </div>
              <div>
                {value !== undefined ? (
                  <NumberQI
                    value={value}
                    isMoney
                    decimalOverride={decimalOverride}
                  />
                ) : (
                  <a />
                )}
              </div>
            </Col>
            <Col xs={alignRight === undefined || alignRight === false ? 4 : 2}>
              {(value !== undefined && value >= 0) || (percent !== undefined && percent >= 0) ? (
                <UpIcon style={{ paddingBottom: 10 }} />
              ) : (
                <DownIcon style={{ paddingBottom: 10 }} />
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container size={fontSize} colorful={colorful} color={color} style={mainContainerStyleRules?.style}>
          {value !== undefined ? (
            <>
              {!noShowValuesSign && operationType === 'positive' && '+'}
              <NumberQI
                value={value}
                isMoney={false}
                decimalOverride={decimalOverride}
                currency={currency !== undefined ? currency : ''}
              />
            </>
          ) : (
            <a />
          )}
          {signType === 'symbol' && operationType === 'negative' && '-'}
          {signType === 'symbol' && operationType === 'positive' && '+'}
          &nbsp;
          <NumberQI
            value={signType === 'symbol' && percent ? Math.abs(percent) : percent}
            isPercent
            useBrackets={signType === 'parentheses'}
            decimalOverride={decimalOverrideForPercent}
            noShowValueSign={noShowValuesSign}
          />
          {noArrow === false && (
            <>
              &nbsp;
              {operationType === 'negative' && <DownIcon style={{ fontSize: arrowFontSize }} />}
              {operationType === 'positive' && <UpIcon style={{ fontSize: arrowFontSize }} />}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default ChangeValue;
