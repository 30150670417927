import NumberQI from '~/common/components/changeValue/NumberQI';
import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { Trans } from 'react-i18next';
import moment from 'moment';
import Info from '@mui/icons-material/Info';
import Colors from '~/common/themes/colors';
import IconTooltip from '../../icon-tooltip/IconTooltip';
import { ComponentWithValidation } from '../summary-table/ComponentWithValidation';

export interface IProps {
  perfomanceData: any
  symbol: string
}

class PerformanceSection extends React.PureComponent<IProps> {
  private getYear = (value: number) => moment().add(value, 'year').year();

  public render() {
    return (
      <Grid>
        <Row className="StyledHeaderRow">
          <Col xs={2}>
            <Trans i18nKey="stockOverview.return" />
          </Col>
          <Col xs={1}>
            1 <Trans i18nKey="stockOverview.month" />
          </Col>
          <Col xs={1}>
            3 <Trans i18nKey="stockOverview.months" />
          </Col>
          <Col xs={1}>
            6 <Trans i18nKey="stockOverview.months" />
          </Col>
          <Col xs={1}>
            <Trans i18nKey="stockOverview.yearToDate" />
          </Col>
          <Col xs={1}>
            1  <Trans i18nKey="stockOverview.year" />
          </Col>
          <Col xs={1}>
            2  <Trans i18nKey="stockOverview.years" />
          </Col>
          <Col xs={1}>
            3 <Trans i18nKey="stockOverview.years" />
          </Col>
          <Col xs={1}>
            5 <Trans i18nKey="stockOverview.years" />
          </Col>
          <Col xs={1}>
            10 <Trans i18nKey="stockOverview.years" />
          </Col>
          <Col xs={1}><Trans i18nKey="stockOverview.inception" /></Col>
        </Row>
        <Row className="StyledRow">
          <Col xs={2}>{this.props.symbol} </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.oneMonthReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.oneMonthReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.threeMonthsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.threeMonthsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.sixMonthsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.sixMonthsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.ytdReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.ytdReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.oneYearReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.oneYearReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.twoYearsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.twoYearsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.threeYearsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.threeYearsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.fiveYearsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.fiveYearsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.tenYearsReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.tenYearsReturn} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.sinceInceptionReturn} componentWithValue={<NumberQI value={this.props.perfomanceData.sinceInceptionReturn} isPercent />} />
          </Col>
        </Row>
        <Row className="StyledRow">
          <Col xs={2}>
            <Trans i18nKey="stockOverview.quartile" />
            <IconTooltip icon={<Info fontSize="small" style={{ color: Colors.cobalt }} />} messageKey="stockOverview.quartileTooltip" />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.oneMonthQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.threeMonthsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.sixMonthsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.ytdQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.oneYearQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.twoYearsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.threeYearsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.fiveYearsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.tenYearsQuartile} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.sinceInceptionQuartile} />
          </Col>
        </Row>

        <Row className="StyledHeaderRow">
          <Col xs={2}>
            <Trans i18nKey="stockOverview.return" />
          </Col>
          <Col xs={1}>{this.getYear(-1)} </Col>
          <Col xs={1}>{this.getYear(-2)} </Col>
          <Col xs={1}>{this.getYear(-3)} </Col>
          <Col xs={1}>{this.getYear(-4)} </Col>
          <Col xs={1}>{this.getYear(-5)} </Col>
          <Col xs={1}>{this.getYear(-6)} </Col>
          <Col xs={1}>{this.getYear(-7)} </Col>
          <Col xs={1}>{this.getYear(-8)} </Col>
          <Col xs={1}>{this.getYear(-9)} </Col>
          <Col xs={1}>{this.getYear(-10)}</Col>
        </Row>
        <Row className="StyledRow">
          <Col xs={2}>{this.props.symbol}</Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear1} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear1} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear2} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear2} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear3} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear3} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear4} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear4} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear5} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear5} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear6} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear6} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear7} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear7} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear8} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear8} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear9} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear9} isPercent />} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarReturnYear10} componentWithValue={<NumberQI value={this.props.perfomanceData.calendarReturnYear10} isPercent />} />
          </Col>
        </Row>
        <Row className="StyledRow">
          <Col xs={2}>
            <Trans i18nKey="stockOverview.quartile" />
            <IconTooltip icon={<Info fontSize="small" style={{ color: '#2062D4' }} />} messageKey="stockOverview.quartileTooltip" />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear1} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear2} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear3} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear4} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear5} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear6} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear7} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear8} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear9} />
          </Col>
          <Col xs={1}>
            <ComponentWithValidation value={this.props.perfomanceData.calendarQuartileYear10} />
          </Col>
        </Row>
      </Grid>
    );
  }
}
export default PerformanceSection;
