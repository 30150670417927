import i18n from '~/app/i18n';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { AdvisorPartyTypeCode } from '~/common/interfaces/advisor/AdvisorPartyTypeCode';
import { ITeam } from '~/common/interfaces/advisor/Team';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { AdvisorHelper } from '../../../common/utils/AdvisorHelper';
import { ListElement, RecipientSection, RecipientsList } from './RecipientSection.style';
import { getCurrentIsoLang } from '~/common/helpers';
interface ILocalProps {
  team: ITeam;
}

const RecipientsSection: React.FC<ILocalProps> = ({ team }: ILocalProps): JSX.Element => {
  const setRecipientOptions = (): string[] => {
    if (team.Advisors.some((advisor) => advisor.isNationalBranch)) {
      return recipientOptionsForNationalBranch(team.Advisors);
    }
    const advisorsWithEmail = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Advisors);
    const assistantsWithEmail = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Assistants);
    const branchesWithEmail = AdvisorHelper.filterAdvisorsWithPrimaryEmail(team.Branches);
    const options: string[] = [
      ...makeOptions(assistantsWithEmail),
      ...makeOptions(advisorsWithEmail),
      ...makeOptions(branchesWithEmail),
    ];
    return options;
  };

  const recipientOptionsForNationalBranch = (advisors: IAdvisor[]): string[] => {
    const optionsForNationalBranch = makeOptions(AdvisorHelper.filterAdvisorsWithPrimaryEmail(advisors));
    if (optionsForNationalBranch.length > 1) {
      return [optionsForNationalBranch[0]];
    }
    return optionsForNationalBranch;
  };

  const makeOptions = (advisors: IAdvisor[]): string[] => advisors.map((advisor) => formatRecipientLabel(advisor));

  const formatRecipientLabel = (advisor: IAdvisor): string => {
    let recipientLabel = '';
    if (advisor.partyTypeCode === AdvisorPartyTypeCode.Branch) {
      recipientLabel = advisor.emails.filter((email) => email.primary)[0].emailAddress;
    } else if (advisor.partyTypeCode === AdvisorPartyTypeCode.Assistant) {
      recipientLabel = formatAssistantRecipientLabel(advisor);
    } else {
      recipientLabel = advisor.isNationalBranch
        ? formatNationalBranchRecipientLabel(advisor)
        : formatAdvisorRecipientLabel(advisor);
    }

    return recipientLabel;
  };

  const formatAdvisorRecipientLabel = (advisor: IAdvisor): string => {
    const role = `${i18n.t(`regulatoryRoles.${advisor.regulatoryRole}`)}`;
    let recipientLabel = `${advisor.preferredName ? advisor.preferredName : advisor.firstName} ${advisor.lastName}`;

    if (role.length > 0) {
      recipientLabel = `${recipientLabel} - ${i18n.t(`regulatoryRoles.${advisor.regulatoryRole}`)}`;
    }
    return recipientLabel;
  };

  const formatNationalBranchRecipientLabel = (advisor: IAdvisor): string => {
    const { emails } = advisor;
    const primaryEmails = emails.filter((email) => email.primary);
    if (primaryEmails.length) {
      return primaryEmails[0].emailAddress;
    }
    return '';
  };

  const formatAssistantRecipientLabel = (assistant: IAdvisor): string => {
    const language = getCurrentIsoLang();
    const { titles } = assistant;
    const assistantDisplayName = `${assistant.preferredName ? assistant.preferredName : assistant.firstName} ${
      assistant.lastName
    }`;
    if (!titles || (titles && titles.filter((t) => t.language === language).length === 0)) {
      return assistantDisplayName;
    }

    const title = titles.find((t) => t.language === language);
    return title ? `${assistantDisplayName} - ${title.text}` : assistantDisplayName;
  };

  const recipients = React.useMemo(setRecipientOptions, [team]);
  return (
    <RecipientSection>
      <RecipientsList>
        {recipients.map((recipient: string) => (
          <ListElement key={uuid()}>{recipient}</ListElement>
        ))}
      </RecipientsList>
    </RecipientSection>
  );
};

export default RecipientsSection;
