import { FileDocument, FileExcel, FileImage, FilePdf, FilePowerPoint, FileWord } from '~/common/svg';
import { IDocument } from '~/stores/account/accountTypes';
import { IAttachment } from '~/stores/message/interfaces/Attachment';
import { IMessage } from '~/stores/message/interfaces/Message';
import { DocumentFromTypeEnum, DocumentTypeEnum } from '../types';

export const PDFExntension = 'pdf';

export const getFileExtension = (fileName: string) => {
  const fileExtension = fileName ? fileName.split('.').pop() : '';
  if (fileExtension) {
    return fileExtension.toLowerCase();
  }
  return '';
};

export const getFileIconByExtension = (fileName: string) => {
  const fileExtension = getFileExtension(fileName);
  if (fileExtension) {
    switch (fileExtension) {
      case 'doc':
      case 'docx':
        return FileWord();
      case PDFExntension:
        return FilePdf();
      case 'ppt':
      case 'pptx':
        return FilePowerPoint();
      case 'xls':
      case 'xlsx':
        return FileExcel();
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
      case 'gif':
      case 'tif':
      case 'tiff':
      case 'heif':
      case 'heic':
        return FileImage();
      default:
        return FileDocument();
    }
  }
  return FileDocument();
};

const authorizedFileExtension = [
  'csv',
  'txt',
  'doc',
  'docx',
  PDFExntension,
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'gif',
  'tif',
  'tiff',
  'heif',
  'heic',
];

export const isAuthorizedFileType = (fileName: string) => {
  const fileExtension = getFileExtension(fileName);
  return fileExtension && authorizedFileExtension.indexOf(fileExtension) >= 0;
};

const maxFileSize = 20;

export const isAuthorizedFileSize = (fileSize: number) => fileSize / 1024 / 1024 < maxFileSize;

// TODO: we need to clean up the messaging pieces.
export const getDocumentsFromMessage = (message: IMessage, partyId: string) => {
  const { attachments } = message;
  const documents: IDocument[] = [];
  attachments.forEach((attachment: IAttachment) => {
    documents.push({
      documentYear: new Date(message.createdDateTime).getFullYear().toString(),
      effectiveDate: new Date(message.createdDateTime),
      publicationDate: new Date(message.publicationDateTime),
      documentId: attachment.documentId,
      name: attachment.name,
      clientId: '-',
      typeDocument: DocumentTypeEnum.SHARING,
      type: 'Shared',
      fromType: partyId === attachment.fromId ? DocumentFromTypeEnum.FROM_YOU : DocumentFromTypeEnum.FROM_ADVISOR,
      fromName: attachment.fromName,
      id: attachment.documentId,
      read: false,
    });
  });
  return documents;
};
