import { call, put, select, takeLatest } from 'redux-saga/effects';
import { TOGGLE_KYC } from '~/common/API';
import { KYCHttpClient } from './kycHttpClient';
import {
  fetchKYCDetails as fetchKYCDetailsAction,
  fetchKYCDetailsFailed,
  fetchKYCDetailsSuccessful,
  fetchKYCStatusFailed,
  fetchKYCStatusSuccessful,
  saveKYCDetailsFailed,
  saveKYCDetailsNoLongerNeeded,
  saveKYCDetailsSuccessful,
  saveKYCRemindLaterFailed,
  saveKYCRemindLaterSuccessful,
} from './kycActions';
import {
  IKYCState,
  KYCActionTypes,
  KYCDetailsResponseType,
  KYCGetStatusRequest,
  KYCStatusFetchSchemaType,
  KycReviewIdsType,
} from './kycTypes';
import { IPartyV1 } from '../party/partyTypes';
import { getPartyV1 } from '../party/selectors/partySelectors';
import { getKYCReviewIds, getKYCState } from './kycSelectors';
import { isKYCFeatureActiveForClient } from '~/common/helpers';

import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { GetIsStorageValueByKey } from '~/app/sessionManager';

export function* fetchKYCStatus() {
  const party: IPartyV1 = yield select(getPartyV1);
  const isMirrorViewMode: boolean = yield select(isUserAdvisorOrAdmin);
  if (TOGGLE_KYC && !isMirrorViewMode && isKYCFeatureActiveForClient(party)) {
    try {
      const isKycSavedSuccessfully = !!GetIsStorageValueByKey('isKYCSavedSuccessfully');
      if (isKycSavedSuccessfully) {
        // Should not reload KYC status if KYC details were saved successfully
        return;
      }
      const request = new KYCGetStatusRequest(party.accounts);
      const response = (yield call(KYCHttpClient.fetchKYCStatus, request)) as KYCStatusFetchSchemaType;
      yield put(fetchKYCStatusSuccessful(response));
      if (response.kycReviewIds && response.kycReviewIds.length > 0) {
        yield put(fetchKYCDetailsAction(response.kycReviewIds));
      }
    } catch (err) {
      yield put(fetchKYCStatusFailed());
    }
  }
}

export function* fetchKYCDetails() {
  const party: IPartyV1 = yield select(getPartyV1);
  const isMirrorViewMode: boolean = yield select(isUserAdvisorOrAdmin);
  if (TOGGLE_KYC && !isMirrorViewMode && isKYCFeatureActiveForClient(party)) {
    try {
      const state: IKYCState = yield select(getKYCState);
      const response: KYCDetailsResponseType = yield call(KYCHttpClient.fetchKYCDetails, state.reviewIds);
      yield put(fetchKYCDetailsSuccessful(response));
    } catch (err) {
      yield put(fetchKYCDetailsFailed());
    }
  }
}

export function* saveKYCRemindLater() {
  const party: IPartyV1 = yield select(getPartyV1);
  if (TOGGLE_KYC && isKYCFeatureActiveForClient(party)) {
    try {
      const reviewIds: KycReviewIdsType = yield select(getKYCReviewIds);
      yield call(KYCHttpClient.saveKYCRemindLater, reviewIds);
      yield put(saveKYCRemindLaterSuccessful());
    } catch (err) {
      yield put(saveKYCRemindLaterFailed());
    }
  }
}

export function* saveKYCDetails({ payload }: any) {
  const party: IPartyV1 = yield select(getPartyV1);
  if (TOGGLE_KYC && isKYCFeatureActiveForClient(party)) {
    try {
      const { KYCs } = payload;
      yield call(KYCHttpClient.submitKYCDetails, KYCs);
      yield put(saveKYCDetailsSuccessful());
    } catch (err: any) {
      if (err.response?.status === 404) {
        yield put(saveKYCDetailsNoLongerNeeded());
      } else {
        yield put(saveKYCDetailsFailed());
      }
    }
  }
}

function* kycSaga() {
  yield takeLatest(KYCActionTypes.KYC_STATUS_FETCH, fetchKYCStatus);
  yield takeLatest(KYCActionTypes.KYC_REMIND_LATER, saveKYCRemindLater);
  yield takeLatest(KYCActionTypes.KYC_DETAILS_FETCH, fetchKYCDetails);
  yield takeLatest(KYCActionTypes.KYC_DETAILS_SAVE, saveKYCDetails);
}

export default kycSaga;
