import { IAppRootState } from '~/app/rootReducer';
import { ISystemState, UserRoles, UserType } from '~/stores/system/systemTypes';
import moment from 'moment';
import { createSelector } from 'reselect';

export const getSystemState = (state: IAppRootState) => state.system;

export const getCulture = createSelector(getSystemState, (systemState: ISystemState) => systemState.culture);

export const getIsClaimsFetching = createSelector(
  getSystemState,
  (systemState: ISystemState) => systemState.isClaimsFetching,
);

export const getToken = createSelector(getSystemState, (systemState: ISystemState) => systemState.token);

export const getIsTokenDataExpired = createSelector(
  getSystemState,
  (systemState: ISystemState) =>
    !systemState.tokenExpirationDate || moment(systemState.tokenExpirationDate).isBefore(moment()),
);

export const getHasError = createSelector(getSystemState, (systemState: ISystemState) => systemState.hasError);

export const getUserType = createSelector(getSystemState, (systemState: ISystemState) => {
  const { user } = systemState;
  if (user && user.roles.some((role) => role === UserRoles.Administrator)) {
    return UserType.Administrator;
  }
  return user && user.accounts && user.accounts.length > 0 ? UserType.Client : UserType.Advisor;
});

export const getUserToken = createSelector(getSystemState, (systemState: ISystemState) => systemState.user);

export const isUserClient = createSelector(getUserType, (userType: UserType) => userType === UserType.Client);

export const isUserAdvisorOrAdmin = createSelector(
  getUserType,
  (userType: UserType) => userType === UserType.Advisor || userType === UserType.Administrator,
);
