import * as React from 'react';
import i18n from '~/app/i18n';
import '~/common/styles/styleIAS.css';
import { Modal } from 'antd';
import { Trans } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button } from '@mui/material';
import SaveDocumentDeliveryChanges from './SaveChanges';

interface ILegalConsentProps {
  modalSaveChangesVisible: boolean,
  hasAcceptedLegalConsent: boolean,
  isUserAdvisor: boolean,
  handleSave: () => void,
  handleCancel: () => void,
  handleLegalConsentChange: (event: CheckboxChangeEvent) => void,
}

export default class LegalConsentModal extends React.PureComponent<ILegalConsentProps> {
  private renderFooter = (): JSX.Element[] => [
    <Button key="cancel" onClick={this.props.handleCancel} variant="contained" color="secondary"><Trans i18nKey="common.cancel" /></Button>,
    <Button key="apply" onClick={this.props.handleSave} disabled={!this.props.hasAcceptedLegalConsent || this.props.isUserAdvisor} variant="contained" color="secondary"><Trans i18nKey="common.acceptConfirm" /></Button>,
  ];

  public render() {
    const footer = this.renderFooter();

    return <Modal title={i18n.t('householding.confirm') as string} visible={this.props.modalSaveChangesVisible} footer={footer} onCancel={this.props.handleCancel}>
      <SaveDocumentDeliveryChanges
        handleLegalConsentChange={this.props.handleLegalConsentChange}
        hasAcceptedLegalConsent={this.props.hasAcceptedLegalConsent}
      />
    </Modal>;
  }
}
