import axios, { AxiosRequestConfig } from 'axios';
import { API_MESSAGING } from '~/common/API';
import { getDocumentsFromMessage } from '~/common/utils/DocumentHelper';
import { IDocument } from '~/stores/account/accountTypes';
import { getCurrentIsoLang } from '../../../common/helpers';
import { IConversation } from '../interfaces/Conversation';
import { IMessage } from '../interfaces/Message';
import { IMessagesForConversation } from '../interfaces/MessagesForConversation';

export const MessageHttpClient = new class {
  public saveMessage = async (clientId: string, toId: string, idsToBeNotified?: string[], attachments?: File[]) => {
    const url = `${API_MESSAGING}`;
    const form = new FormData();
    form.append('FromId', clientId);
    form.append('ToId', toId);
    form.append('Lang', getCurrentIsoLang());
    if (idsToBeNotified) {
      idsToBeNotified.forEach((idToBeNotified) => {
        form.append('IdsToBeNotified', idToBeNotified);
      });
    }
    if (attachments) {
      attachments.forEach((file) => {
        form.append('files', file, file.name);
      });
    }
    const options: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

    const response = await axios.post(url, form, options);
    return response.data;
  };

  public async getConversationsByMembersId(partyId: string): Promise<IConversation[]> {
    const response = await axios.get(`${API_MESSAGING}/Conversations?membersId=${partyId}`);
    return response.data.data as IConversation[];
  }

  public async getMessagesByConversationsId(conversationId: string): Promise<IMessagesForConversation> {
    return axios.get(`${API_MESSAGING}/Messages?conversationId=${conversationId}`);
  }

  public async fetchDocuments(partyId: string) {
    const calls: any[] = [];
    const conversations: IConversation[] = await MessageHttpClient.getConversationsByMembersId(partyId);
    if (conversations) {
      const conversationIds = conversations.map((conversation) => conversation.id);

      conversationIds.forEach((conversationId) => {
        calls.push(MessageHttpClient.getMessagesByConversationsId(conversationId));
      });
    }
    const resps = await Promise.all(calls);
    let docs: IDocument[] = [];
    resps.forEach((f) => {
      f.data.data.forEach((message: IMessage) => {
        const newDocuments = getDocumentsFromMessage(message, partyId);
        docs = [...docs, ...newDocuments];
      });
    });
    return docs;
  }
}();
