import { Button, Stack } from '@mui/material';
import { Modal } from 'antd';
import React from 'react';
import { withStyles } from 'tss-react/mui';
import i18n from '~/app/i18n';

const TextButton = withStyles(Button, {
  root: {
    ':hover': {
      textDecoration: 'none !important',
    },
  },
});

const CustomDialog = ({
  title,
  isOpen,
  children,
  okText,
  okAction,
  cancelText,
  cancelAction,
  closeAction,
}: {
  title?: string;
  isOpen: boolean;
  children?: React.ReactNode;
  okText?: string;
  cancelText?: string;
  okAction?: () => void;
  cancelAction?: () => void;
  closeAction?: () => void;
}) => (
  <Modal
    centered
    title={title}
    width="500px"
    open={isOpen}
    footer={null}
    cancelButtonProps={{ style: { display: closeAction ? 'block' : 'none' } }}
    onCancel={closeAction}
  >
    {children}

    <Stack direction="row" gap={2} mt={4}>
      {okAction && (
        <Button variant="contained" color="secondary" style={{ padding: '12px 32px' }} onClick={okAction}>
          {okText || `${i18n.t('common.ok')}`}
        </Button>
      )}
      {cancelAction && (
        <TextButton variant="text" color="secondary" style={{ padding: '12px 32px' }} onClick={cancelAction}>
          {cancelText || `${i18n.t('common.cancel')}`}
        </TextButton>
      )}
    </Stack>
  </Modal>
);

export default CustomDialog;
