import { SortDirection } from '~/app/appTypes';
import { IAccount } from '~/stores/account/accountTypes';
import { SortColumnKeys } from '~/stores/party/partyTypes';

export interface IFetchError {
  status: number;
  description: string;
}

export enum CurrencyEnum {
  USD = 'USD',
  CAD = 'CAD',
}

export enum AccountViewTabEnum {
  AccountValue = 'AccountValue',
  InvestedCapital = 'InvestedCapital',
  BookCost = 'BookCost',
}

export interface IRootAccounts {
  rootId: string;
  totalMarketValueCad?: number;
  accounts?: IAccount[];
  shouldNotShowMarketDataInfo: boolean;
  viewType?: AccountViewTabEnum;
  onChangedTableSort: (sortColumn: SortColumnKeys, sortDirection: SortDirection) => void;
  sortingPreferencesByTab?: ISortPreference;
}

export interface ISortPreference {
  sortColumn: SortColumnKeys;
  sortDirection: SortDirection;
}

export enum ProductTypeEnum {
  STOCKS = '1',
  OPTIONS = '2',
  MUTUAL_FUND = '3',
  GICS = '4',
  FIXED_INCOME_SECURITIES = '5',
  STOCKS_OPTIONS = '10',
}

export enum SectionEnum {
  Holdings = 'holdings',
  Landing = 'landing',
  BookValue = 'bookValue',
  History = 'history',
  Common = 'common',
  AssetAllocation = 'assetAllocation',
  Householding = 'householding',
}

export enum AccountFilterGroup {
  CLOSE_ACCOUNT = 1,
  HOUSEHOLD = 2,
}

export enum PerformanceDisclaimerType {
  SinceInceptionHW = 'SinceInceptionHW',
  SinceInceptionIAS = 'SinceInceptionIAS',
  CRM2 = 'CRM2',
}

export enum AssetAllocationCategoryType {
  CASHANDEQUIVALENTS = 'A',
  FIXEDINCOMESECURITIES = 'B',
  EQUITIES = 'C',
  OTHERASSETS = 'D',
}

export enum DocumentTypeEnum {
  TAXSlIP = 1,
  STATEMENT = 4,
  TRADECONFIRM = 6,
  YEARENDREPORT = 11,
  SHARING = 5000, // Prevent conflict with Imagerie type.
  QUARTERLYREVIEW = 12,
}

export enum DocumentFromTypeEnum {
  IAPRIVATEWEALTH = 0,
  FROM_YOU = 1,
  FROM_ADVISOR = 2,
  FROM_LBS = 3
}

export enum IntradayPositionTypes {
  PREFERREDSTOCK = '310',
  OPTIONELIBIBLE = '315',
  COMMONSTOCK = '330',
  FRACTIONSTOCK = '331',
  ESCROWSTOCK = '340',
  OPTION_PUT_STOCK = '550',
  OPTION_PUT_USINDEX_TSE = '551',
  OPTION_PUT_USINDEX_SP = '552',
  OPTION_PUT_100MUSTREASURY = '553',
  OPTION_PUT_100MCURRENCY = '554',
  OPTION_PUT_GOLD = '555',
  OPTION_PUT_SILVER = '556',
  OPTION_PUT_CANADABOND = '557',
  OPTION_PUT_CURRENCY = '558',
  OPTION_PUT_USTREASURY = '559',
  OPTION_CALL_STOCK = '560',
  OPTION_CALL_USINDEX_TSE = '561',
  OPTION_CALL_USINDEX_SP = '562',
  OPTION_CALL_100MUSTREASURY = '563',
  OPTION_CALL_100MCURRENCY = '564',
  OPTION_CALL_GOLD = '565',
  OPTION_CALL_SILVER = '566',
  OPTION_CALL_CANADABOND = '567',
  OPTION_CALL_CURRENCY = '568',
  OPTION_CALL_USTREASURY = '569',
  OPTION_PUT_100MUSBOND = '570',
  OPTION_PUT_BINARY = '571',
  OPTION_PUT_PLATINUM = '572',
  OPTION_PUT_TSXINDEX = '573',
  OPTION_CALL_BINARY = '580',
  OPTION_CALL_PLATINUM = '582',
  OPTION_CALL_TXSINDEX = '583',
  // BONDESCROW = "342",
  // CERTIFICATEOFDEPOSIT = "345",
  // MUTUALFUNDS = "380",
  // UNITWITHDEBT = "410",
  // UNITWITHOUTDEBT = "420",
  // WARRANTS = "510",
  // RIGHTS="520",
  // COMMODITIES="600",
  MISCSTOCKS='900',
}

export enum HistoricalFrequencyTypes {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly'
}

export enum SymbolInfoTypes {
  STOCK = 'stock',
  FUND = 'fund',
  ETF = 'etf',
}

export enum FinancialFrequencies{
  ANNUAL= 'Annual',
  QUARTERLY = 'Quarterly'
}

export enum RequestStatus {
  FAILURE = 'failure',
  SUCCESS = 'success'
}
