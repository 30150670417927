import * as React from 'react';
import { Tooltip } from '@mui/material/';
import { Trans } from 'react-i18next';
import { IconButtonWithoutPadding } from '~/common/styles/baseStyle';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface IIconTooltipValueProps {
  icon: React.ReactElement<SvgIconProps>,
  messageKey: string
}

const IconTooltipContainer = styled.div`
    display: inline;
    & .MuiIconButton-root:hover{
        background-color: transparent !important; 
      }
`;

const IconTooltip: FunctionComponent<IIconTooltipValueProps> = ({ icon, messageKey }) => (
  <IconTooltipContainer>
    <Tooltip title={<Trans i18nKey={messageKey} />} placement="top" arrow>
      <IconButtonWithoutPadding>
        {icon}
      </IconButtonWithoutPadding>
    </Tooltip>
  </IconTooltipContainer>
);

export default IconTooltip;
