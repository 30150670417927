import {
  AccountActionTypes,
  IAccountDictionnary,
  IAssetsAllocation,
  IClientAccount,
  IDeliveryPreferences,
  IDeliveryPreferencesSummary,
  IDocument,
  IDocumentDownload,
  IDocumentMergeDownload,
  IRateOfReturn,
  IRegisteredAccount,
} from '~/stores/account/accountTypes';
import {
  fetchAccountsAborted,
  fetchAccountsFailed,
  fetchAccountsSuccessful,
  fetchAssetsAllocation,
  fetchAssetsAllocationAborted,
  fetchAssetsAllocationFailed,
  fetchAssetsAllocationSuccessful,
  fetchNetInvestedAborted,
  fetchNetInvestedFailed,
  fetchNetInvestedSuccessful,
  fetchRateOfReturnAborted,
  fetchRateOfReturnFailed,
  fetchRateOfReturnSuccessful,
  fetchCashFlowSummaryFailed,
  fetchCashFlowSummarySuccessful,
  fetchRegisteredAccountsFailed,
  fetchRegisteredAccountsSuccessful,
  fetchDeliveryPreferencesSuccessful,
  fetchDeliveryPreferencesFailed,
  saveDeliveryPreferencesFailed,
  saveDeliveryPreferencesSuccessful,
  saveNickNameAccountSuccessful,
  saveNickNameAccountFailed,
  fetchAccounts,
  fetchAccountDocumentsSuccessful,
  fetchAccountDocumentsAborted,
  fetchAccountDocumentsFailed,
  fetchMarketDataSuccessful,
  fetchMarketDataFailed,
  fetchClosedMarketsSuccessful,
  fetchClosedMarketsFailed,
  fetchClosedMarkets,
  mergeDownloadPdfsSuccessful,
  mergeDownloadPdfsFailed,
  fetchCSVByDomainSuccessful,
  fetchCSVByDomainFailed,
  fetchTradeConfirmationDocumentsSuccessful,
  fetchTradeConfirmationDocumentsAborted,
  fetchTradeConfirmationDocumentsFailed,
  fetchDeliveryPreferencesSummarySuccessful,
  fetchDeliveryPreferencesSummaryFailed,
  fetchMarketDataNoLiveSuccessful,
  fetchAccountsInfo,
} from '~/stores/account/actions/accountActions';
import {
  getIsAssetsAllocationDataExpired,
  getIsDataExpired,
  getAccountsFromState,
  getAccountIds,
  getNetInvested,
  getIsAccountsFetching,
  getAllAvailableAccounts,
  getDocumentDateFiltering,
} from '~/stores/account/selectors/accountSelectors';
import { AccountHttpClient } from '~/stores/account/services/AccountHttpClient';
import { appGlobalError, refreshSession } from '~/stores/system/actions/systemActions';
import { call, put, select, takeLatest, take, delay, race } from 'redux-saga/effects';
import { getParty, getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { IPartyV1, PartyActionTypes } from '~/stores/party/partyTypes';
import { ErrorTypes } from '~/app/appTypes';
import { filterAccounts, isAllAccountSameCurrency, getTotalMarketValueInCurrency } from '~/common/accountsHelpers';
import 'moment-timezone';
import moment from 'moment';
import { DocumentTypeEnum } from '~/common/types';
import { getEDeliveryTypesAvailable } from '~/common/helpers';

export function* handleFetchAccounts({ payload }: any) {
  try {
    yield put(refreshSession());
    let { accountIds, isAccountSwitch, partyId } = payload;

    let party: IPartyV1 = yield select(getParty);

    if (!party || Object.values(party).length === 0) {
      yield race({
        party: take(PartyActionTypes.FETCH_PARTY_SUCCESSFUL),
        timeout: delay(5000),
      });
      party = yield select(getParty);
      partyId = party.id;
      accountIds = accountIds;
      isAccountSwitch = isAccountSwitch;
    }

    yield put(fetchClosedMarkets());

    const isDataExpired: boolean = yield select(getIsDataExpired);
    const useIntraday: boolean = yield select(getDidSetMarketDataRealtime);
    const filteredAccountIds: string[] = yield select(getAccountIds);

    if (isDataExpired || isAccountSwitch) {
      const clientAccounts = (yield call(
        AccountHttpClient.fetchAccounts,
        accountIds,
        partyId,
        useIntraday,
        filteredAccountIds,
      )) as IClientAccount;
      if (clientAccounts && clientAccounts.accounts.length > 0) {
        const ids = clientAccounts.accounts.map((m) => m.id);
        // yield put(setFilteringAccountIds([]));
        yield put(fetchAccountsSuccessful(clientAccounts));
        yield put(fetchAssetsAllocation(ids, true));

        if (useIntraday) {
          if (clientAccounts.isLiveData) {
            const lastUpdatePortal = moment().tz('America/Toronto').toISOString();
            yield put(fetchMarketDataSuccessful([clientAccounts.lastUpdatedDate, lastUpdatePortal]));
          } else {
            yield put(fetchMarketDataNoLiveSuccessful([clientAccounts.lastUpdatedDate]));
          }
        }
      } else {
        yield put(fetchAccountsAborted());
        yield put(appGlobalError(ErrorTypes.NoAccount));
      }
    } else {
      yield put(fetchAccountsAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(appGlobalError(err as any));
    yield put(fetchAccountsFailed());
  }
}

export function* handleFetchInitialAccounts() {
  try {
    yield put(refreshSession());
    yield put(fetchClosedMarkets());
    yield put(fetchAccountsInfo());

    const clientAccounts = (yield call(AccountHttpClient.fetchAccountsInfo)) as IClientAccount;
    if (clientAccounts && clientAccounts.accounts.length > 0) {
      const ids = clientAccounts.accounts.map((m) => m.id);
      yield put(fetchAccountsSuccessful(clientAccounts));
      yield put(fetchAssetsAllocation(ids, true));

      if (clientAccounts.isLiveData) {
        const lastUpdatePortal = moment().tz('America/Toronto').toISOString();
        yield put(fetchMarketDataSuccessful([clientAccounts.lastUpdatedDate, lastUpdatePortal]));
      } else {
        yield put(fetchMarketDataNoLiveSuccessful([clientAccounts.lastUpdatedDate]));
      }
    } else {
      yield put(fetchAccountsAborted());
      yield put(appGlobalError(ErrorTypes.NoAccount));
    }
  } catch (err) {
    console.log(err);
    yield put(appGlobalError(err as any));
    yield put(fetchAccountsFailed());
  }
}

export function* fetchAssets({ payload }: any) {
  try {
    yield put(refreshSession());
    const { accountIds, isAccountSwitch } = payload;
    const isDataExpired: boolean = yield select(getIsAssetsAllocationDataExpired);

    if (isDataExpired || isAccountSwitch) {
      const assets: IAssetsAllocation[] = yield call(AccountHttpClient.fetchAssetsAllocation, accountIds);
      yield put(fetchAssetsAllocationSuccessful(assets));
    } else {
      yield put(fetchAssetsAllocationAborted());
    }
  } catch (err) {
    yield put(appGlobalError(err as any));
    yield put(fetchAssetsAllocationFailed());
  }
}

function* fetchRegisteredAccount({ payload }: any) {
  try {
    yield put(refreshSession());
    const { accountIds, year } = payload;
    const accounts: IRegisteredAccount[] = yield call(AccountHttpClient.fetchRegisteredAccounts, accountIds, year);
    yield put(fetchRegisteredAccountsSuccessful(accounts, year));
  } catch (err) {
    yield put(fetchRegisteredAccountsFailed());
  }
}

function* fetchNetInvested({ payload }: any): any {
  yield put(refreshSession());
  try {
    const { accountIds } = payload;
    const perfs = yield call(AccountHttpClient.fetchNetInvested, accountIds);
    if (perfs && perfs.data) {
      yield put(fetchNetInvestedSuccessful(perfs));
    } else {
      yield put(fetchNetInvestedAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchNetInvestedFailed());
  }
}

export function* fetchNetInvestedToken(): any {
  yield put(refreshSession());
  try {
    const perfs = yield call(AccountHttpClient.fetchNetInvestedToken);
    if (perfs && perfs.data) {
      yield put(fetchNetInvestedSuccessful(perfs));
    } else {
      yield put(fetchNetInvestedAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchNetInvestedFailed());
  }
}

function* fetchRateOfReturn({ payload }: any) {
  try {
    yield put(refreshSession());
    const { accountIds } = payload;
    const perfs = (yield call(AccountHttpClient.fetchRateOfReturnV2, accountIds)) as IRateOfReturn;
    if (perfs !== undefined) {
      yield put(fetchRateOfReturnSuccessful(perfs));
    } else {
      yield put(fetchRateOfReturnAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchRateOfReturnFailed());
  }
}

function* fetchCashFlowSummary({ payload }: any): any {
  try {
    yield put(refreshSession());
    const { accountIds } = payload;
    let clientAccounts: any = yield select(getAllAvailableAccounts);
    const accountsFetching: boolean = yield select(getIsAccountsFetching);
    if (!clientAccounts || accountsFetching) {
      yield race({
        acc: take(AccountActionTypes.FETCH_ACCOUNTS_SUCCESSFUL),
        timeout: delay(5000),
      });
      clientAccounts = yield select(getAllAvailableAccounts);
    }
    const selectedAccounts = filterAccounts(clientAccounts, accountIds);
    const isAllAccountCurrencyUSD = isAllAccountSameCurrency(selectedAccounts, 'USD');
    const totalMarketValue: number = isAllAccountCurrencyUSD
      ? getTotalMarketValueInCurrency(selectedAccounts, 'USD')
      : getTotalMarketValueInCurrency(selectedAccounts, 'CAD');

    let netInvested: any = yield select(getNetInvested);
    if (!netInvested) {
      yield race({
        netInvested: take(AccountActionTypes.FETCH_NETINVESTED_SUCCESSFUL),
        timeout: delay(5000),
      });
      netInvested = yield select(getNetInvested);
    }

    const cashFlow = (yield call(
      AccountHttpClient.fetchCashFlowSummaryV2,
      accountIds,
      totalMarketValue,
      netInvested,
    )) as IRateOfReturn;

    if (cashFlow !== undefined) {
      yield put(fetchCashFlowSummarySuccessful(cashFlow));
    } else {
      yield put(fetchCashFlowSummaryFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchCashFlowSummaryFailed());
  }
}

function* fetchDeliveryPreferences({ payload }: any) {
  try {
    yield put(refreshSession());
    const { clientIds, partyId } = payload;
    const deliveryPreferences: IDeliveryPreferences[] = yield call(
      AccountHttpClient.fetchDeliveryPreferences,
      clientIds,
      partyId,
    );
    yield put(fetchDeliveryPreferencesSuccessful(deliveryPreferences));
  } catch (err) {
    console.log(err);
    yield put(fetchDeliveryPreferencesFailed());
  }
}

function* saveDeliveryPreferences({ payload }: any) {
  try {
    yield put(refreshSession());
    const { deliveryPreferences } = payload;
    yield call(AccountHttpClient.saveDeliveryPreferences, deliveryPreferences);
    yield put(saveDeliveryPreferencesSuccessful());
  } catch (err) {
    console.log(err);
    yield put(saveDeliveryPreferencesFailed());
  }
}

function* fetchDeliveryPreferencesSummary() {
  try {
    yield put(refreshSession());
    const filteredDocumentTypes = getEDeliveryTypesAvailable();
    const deliveryPreferencesSummary: IDeliveryPreferencesSummary = yield call(
      AccountHttpClient.fetchDeliveryPreferencesSummary,
      filteredDocumentTypes,
    );
    yield put(fetchDeliveryPreferencesSummarySuccessful(deliveryPreferencesSummary));
  } catch (err) {
    console.log(err);
    yield put(fetchDeliveryPreferencesSummaryFailed());
  }
}

function* saveNickname({ payload }: any) {
  try {
    yield put(refreshSession());
    const { id, accountId, nickname } = payload;
    yield call(AccountHttpClient.saveNickName, id, accountId, nickname);
    yield put(saveNickNameAccountSuccessful());
    const party: IPartyV1 = yield select(getParty);
    const accounts = party.accounts.map((m) => m.id);
    yield put(fetchAccounts(accounts, true, payload.id));
  } catch (err) {
    console.log(err);
    yield put(saveNickNameAccountFailed());
  }
}

function* fetchAccountDocuments({ payload }: any) {
  yield put(refreshSession());

  try {
    const party: IPartyV1 = yield select(getParty);
    const partyId = party.id;

    const { documentsStartDate, documentsEndDate } = yield select(getDocumentDateFiltering);
    const from = moment(documentsStartDate).toDate();
    const to = moment(documentsEndDate).hour(23).minute(59).second(59).millisecond(999).toDate();

    const documents: IDocument[] = yield call(
      AccountHttpClient.fetchDocuments,
      `${DocumentTypeEnum.TAXSlIP},${DocumentTypeEnum.STATEMENT},${DocumentTypeEnum.QUARTERLYREVIEW},${DocumentTypeEnum.SHARING},${DocumentTypeEnum.YEARENDREPORT}`,
      partyId,
      from,
      to,
    );
    if (documents) {
      documents.map((d) => {
        d.id = d.documentId;
      });
      yield put(fetchAccountDocumentsSuccessful(documents));
    } else {
      yield put(fetchAccountDocumentsAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchAccountDocumentsFailed());
  }
}

function* fetchTradeConfirmationDocuments({ payload }: any) {
  yield put(refreshSession());

  try {
    const party: IPartyV1 = yield select(getParty);
    const partyId = party.id;

    const { documentsStartDate, documentsEndDate } = yield select(getDocumentDateFiltering);
    const from = moment(documentsStartDate).toDate();
    const to = moment(documentsEndDate).hour(23).minute(59).second(59).millisecond(999).toDate();

    const documents: IDocument[] = yield call(
      AccountHttpClient.fetchDocuments,
      `${DocumentTypeEnum.TRADECONFIRM}`,
      partyId,
      from,
      to,
    );
    if (documents) {
      documents.map((d) => {
        d.id = d.documentId;
      });
      yield put(fetchTradeConfirmationDocumentsSuccessful(documents));
    } else {
      yield put(fetchTradeConfirmationDocumentsAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchTradeConfirmationDocumentsFailed());
  }
}

function* downloadDocument({ payload }: any) {
  try {
    const { documentId } = payload;
    yield call(AccountHttpClient.downloadDocument, documentId);
  } catch (err) {
    console.log(err);
  }
}

function* handleMergeDownloadPdfs({ payload }: any) {
  try {
    // yield put(refreshSession());
    const { mergeDownloadPdfsRequest } = payload;
    // TODO: determine when the apis are finalized
    const result = (yield call(
      AccountHttpClient.mergeDowloadPdfs,
      mergeDownloadPdfsRequest,
    )) as IDocumentMergeDownload[];
    if (result.length > 0) {
      yield put(mergeDownloadPdfsSuccessful({ data: result[0], mergedIds: mergeDownloadPdfsRequest.documents }));
    } else {
      yield put(mergeDownloadPdfsFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(mergeDownloadPdfsFailed());
  }
}

export function* fetchMarketData() {
  try {
    yield put(refreshSession());
    let accounts: IAccountDictionnary = yield select(getAccountsFromState);

    if (!accounts || Object.values(accounts).length === 0) {
      yield race({
        accounts: take(AccountActionTypes.FETCH_ACCOUNTS_SUCCESSFUL),
        timeout: delay(3000),
      });
      accounts = yield select(getAccountsFromState);
    }
    const filteredAccountIds: string[] = yield select(getAccountIds);
    if (accounts && Object.values(accounts).length > 0) {
      const newAccounts = (yield call(
        AccountHttpClient.intradayUpdateAccounts,
        Object.values(accounts),
        filteredAccountIds,
      )) as IClientAccount;
      if (newAccounts && newAccounts.accounts.length > 0) {
        yield put(fetchAccountsSuccessful(newAccounts));
        if (newAccounts.isLiveData) {
          const lastUpdatePortal = moment().tz('America/Toronto').toISOString();
          yield put(fetchMarketDataSuccessful([newAccounts.lastUpdatedDate, lastUpdatePortal]));
        } else {
          yield put(fetchMarketDataNoLiveSuccessful([newAccounts.lastUpdatedDate]));
        }
      } else {
        yield put(fetchMarketDataFailed());
        yield put(appGlobalError(ErrorTypes.NoAccount));
      }
    }
  } catch (err) {
    yield put(appGlobalError(err as any));
    yield put(fetchMarketDataFailed());
  }
}

export function* handleFetchClosedMarkets() {
  try {
    const closedMarkets = (yield call(AccountHttpClient.fetchClosedMarkets)) as any[];
    yield put(fetchClosedMarketsSuccessful(closedMarkets));
  } catch (err) {
    yield put(appGlobalError(err as any));
    yield put(fetchClosedMarketsFailed());
  }
}

function* handleFetchCSVByDomain({ payload }: any) {
  try {
    const { domain, csvFilename } = payload;
    const useIntraday: boolean = yield select(getDidSetMarketDataRealtime);
    const filteredAccountIds: string[] = yield select(getAccountIds);
    const result = (yield call(
      AccountHttpClient.fetchCSV,
      filteredAccountIds,
      useIntraday,
      domain,
      csvFilename,
    )) as IDocumentDownload;
    yield put(fetchCSVByDomainSuccessful(result));
  } catch (err) {
    yield put(fetchCSVByDomainFailed());
  }
}

function* handleFetchTaxDocuments() {
  yield put(refreshSession());

  try {
    const party: IPartyV1 = yield select(getParty);
    const partyId = party.id;

    const documents: IDocument[] = yield call(AccountHttpClient.fetchTaxDocuments, partyId);
    if (documents) {
      documents.map((d) => {
        d.id = d.documentId;
      });
      yield put(fetchAccountDocumentsSuccessful(documents));
    } else {
      yield put(fetchAccountDocumentsAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchAccountDocumentsFailed());
  }
}

function* accountSaga() {
  yield takeLatest(AccountActionTypes.FETCH_ACCOUNTS, handleFetchAccounts);
  yield takeLatest(AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION, fetchAssets);
  yield takeLatest(AccountActionTypes.FETCH_REGISTERED_ACCOUNTS, fetchRegisteredAccount);
  yield takeLatest(AccountActionTypes.FETCH_NETINVESTED, fetchNetInvested);
  yield takeLatest(AccountActionTypes.FETCH_RATEOFRETURN, fetchRateOfReturn);
  yield takeLatest(AccountActionTypes.FETCH_CASHFLOWSUMMARY, fetchCashFlowSummary);
  yield takeLatest(AccountActionTypes.FETCH_DELIVERY_PREFERENCES, fetchDeliveryPreferences);
  yield takeLatest(AccountActionTypes.SAVE_DELIVERY_PREFERENCES, saveDeliveryPreferences);
  yield takeLatest(AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY, fetchDeliveryPreferencesSummary);
  yield takeLatest(AccountActionTypes.SAVE_NICKNAME, saveNickname);
  yield takeLatest(AccountActionTypes.FETCH_DOCUMENTS, fetchAccountDocuments);
  yield takeLatest(AccountActionTypes.FETCH_MARKETDATA, fetchMarketData);
  yield takeLatest(AccountActionTypes.DOWNLOAD_DOCUMENT, downloadDocument);
  yield takeLatest(AccountActionTypes.MERGE_DOWNLOAD_PDFS, handleMergeDownloadPdfs);
  yield takeLatest(AccountActionTypes.FETCH_CLOSEDMARKETS, handleFetchClosedMarkets);
  yield takeLatest(AccountActionTypes.FETCH_TRADECONFIRMATION_DOCUMENTS, fetchTradeConfirmationDocuments);
  yield takeLatest(AccountActionTypes.FETCH_CSV_BY_DOMAIN, handleFetchCSVByDomain);
  yield takeLatest(AccountActionTypes.FETCH_TAX_DOCUMENTS, handleFetchTaxDocuments);
}

export default accountSaga;
