import { Reducer } from 'redux';
import { IAdvisorState, AdvisorActionTypes } from '~/stores/advisor/AdvisorTypes';
import moment from 'moment';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';

export const initialState: IAdvisorState = {
  advisors: [] as IAdvisor[],
  isFetching: false,
  isFail: false,
  dataExpiration: undefined,
};

const handleFetchAdvisor = (state: IAdvisorState) => ({
  ...state,
  isFetching: true,
});

const handleFetchAdvisorAborted = (state: IAdvisorState) => ({
  ...state,
  isFetching: false,
});

const handleFetchAdvisorFail = (state: IAdvisorState) => ({
  ...state,
  isFetching: false,
  isFail: true,
});

const handleFetchAdvisorSuccessful = (state: IAdvisorState, payload: any) => {
  const { advisors } = payload;
  return {
    ...state,
    advisors,
    isFetching: false,
    isFail: false,
    dataExpiration: moment().add(1, 'd').startOf('day').toDate(),
  };
};

export const advisorReducer: Reducer<IAdvisorState> = (state = initialState, action) => {
  switch (action.type) {
    case AdvisorActionTypes.FETCH_ADVISOR:
      return handleFetchAdvisor(state);
    case AdvisorActionTypes.FETCH_ADVISOR_FAILED:
      return handleFetchAdvisorFail(state);
    case AdvisorActionTypes.FETCH_ADVISOR_ABORTED:
      return handleFetchAdvisorAborted(state);
    case AdvisorActionTypes.FETCH_ADVISOR_SUCCESSFUL:
      return handleFetchAdvisorSuccessful(state, action.payload);
    default:
      return state;
  }
};
