import * as React from 'react';
import { Trans } from 'react-i18next';

import { Typography } from '@mui/material';
import { DocumentDeliveryStyle as Style } from './styles';
import ChangeAllToPaperless from './ChangeAllToPaperless';
import Colors from '~/common/themes/colors';

interface IHeaderProps {
  onChangeAllToPaperless: () => void;
  enabledChangeAll: boolean;
}

const Header: React.FC<IHeaderProps> = ({ onChangeAllToPaperless, enabledChangeAll }: IHeaderProps) => (
  <Style.Header>
    <Typography variant="h3" style={{ fontWeight: 800, color: '#003EA5' }}>
      <Trans i18nKey="documentDelivery.goPaperless" />
    </Typography>
    <Style.DocumentDeliveryDescription>
      <Typography variant="body1">
        <Trans i18nKey="documentDelivery.description" />
      </Typography>
      <Style.DocumentDeliveryNote>
        <Typography variant="body1">
          <span style={{ color: Colors.redAlert }}>*</span>
          <Trans i18nKey="documentDelivery.paperlessNote" />
        </Typography>
      </Style.DocumentDeliveryNote>
    </Style.DocumentDeliveryDescription>
    {enabledChangeAll &&
      <Style.ChangeAllContainer>
        <ChangeAllToPaperless onChangeAllToPaperless={onChangeAllToPaperless} />
      </Style.ChangeAllContainer>}
  </Style.Header>
);

export default Header;
