import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Trans } from 'react-i18next';
import { TabContainer, ActiveNavStyle, TabMenuContainer } from '~/common/styles/baseStyle';
import { refreshSession } from '~/stores/system/actions/systemActions';
import { Location } from 'history';

interface IPropsFromState {
  currentLocation: string,
  currentSearch: string,
  showDocumentDelivery: boolean
}

interface IPropsFromDispatch {
  refreshSession: typeof refreshSession
}

class HouseholdSubMenu extends React.PureComponent<IPropsFromState & IPropsFromDispatch> {
  public render() {
    const { currentSearch, showDocumentDelivery } = this.props;

    const isHouseHoldView = (match: any, location: any) => {
      this.props.refreshSession();
      return location.pathname === RouteNames.householdingView;
    };

    const isAccessSharingView = (match: any, location: any) => {
      this.props.refreshSession();
      return location.pathname === RouteNames.accessSharingView;
    };

    const isDocumentDeliveryView = (match: any, location: any) => {
      this.props.refreshSession();
      return location.pathname === RouteNames.documentDeliveryView;
    };

    const isNicknameView = (match: any, location: any) => {
      this.props.refreshSession();
      return location.pathname === RouteNames.nickName;
    };

    return (
      <TabMenuContainer>
        <TabContainer isActive={isHouseHoldView} to={`${RouteNames.householdingView}${currentSearch}`} activeStyle={ActiveNavStyle}>
          <Trans i18nKey="householding.householdView" />
        </TabContainer>
        <TabContainer isActive={isAccessSharingView} to={`${RouteNames.accessSharingView}${currentSearch}`} activeStyle={ActiveNavStyle}>
          <Trans i18nKey="householding.accessSharingView" />
        </TabContainer>
        {
          showDocumentDelivery && (
            <TabContainer isActive={isDocumentDeliveryView} to={`${RouteNames.documentDeliveryView}${currentSearch}`} activeStyle={ActiveNavStyle}>
              <Trans i18nKey="householding.documentDeliveryView" />
            </TabContainer>
          )
        }
        <TabContainer isActive={isNicknameView} to={`${RouteNames.nickName}${currentSearch}`} activeStyle={ActiveNavStyle}>
          <Trans i18nKey="householding.nickname" />
        </TabContainer>
      </TabMenuContainer>
    );
  }
}

export function mapStateToProps(state: IAppRootState) {
  return {
    currentLocation: (state.router.location as Location<{ source: string }>).pathname,
    currentSearch: (state.router.location as Location<{ source: string }>).search,
    showDocumentDelivery: state.system.showDocumentDelivery,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  refreshSession,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdSubMenu);
