export const ClearSession = () => {
  sessionStorage.clear();
};

type SessionName = 'isLoginLogged' | 'eDelSplash' | 'isKYCSavedSuccessfully' | 'isKYCDetailsNoLongerNeeded' | 'accountView' | 'bookCostView' | 'investedView' | 'consolidateView';

export const SetStorageKeyValue = (key: SessionName, value: any) => {
  sessionStorage.setItem(key, String(value));
};

export const RemoveStorageByKey = (key: SessionName) => {
  sessionStorage.removeItem(key);
};

export const GetIsStorageValueByKey = (key: SessionName): boolean => {
  const value = sessionStorage.getItem(key);
  return value !== undefined && value === 'true';
};

export const GetStorageValueByKey = (key: SessionName): any => {
  const value = sessionStorage.getItem(key);
  return value;
};
