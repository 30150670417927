import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles, withStyles } from 'tss-react/mui';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import { appTheme } from '~/common/themes/theme';

export const StyledTableSortLabel = withStyles(TableSortLabel, (_theme, _params, classes) => ({
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    '&:focus': {
      color: appTheme.palette.primary.contrastText,
    },
    '&:hover': {
      color: appTheme.palette.primary.contrastText,
      [`& .${classes.icon}`]: {
        opacity: 0.5,
        color: appTheme.palette.primary.contrastText,
      },
    },
    [`&.${classes.active}`]: {
      color: appTheme.palette.primary.contrastText,
      [`&& .${classes.icon}`]: {
        opacity: 1,
        color: appTheme.palette.primary.contrastText,
      },
    },
  },
  active: {},
  icon: {
    fontSize: 18,
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    color: appTheme.palette.primary.contrastText,
  },
  iconDirectionDesc: {
    transform: 'rotate(0deg)',
  },
  iconDirectionAsc: {
    transform: 'rotate(180deg)',
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: '100%',
      paddingTop: '20px',
      paddingBottom: '20px',
    },

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    table: {
      minWidth: 750,
    },

    tableHeader: {
      color: appTheme.palette.primary.contrastText,
      backgroundColor: appTheme.palette.primary.main,
      fontFamily: 'Open Sans Bold, Open Sans Regular, Open Sans, sans-serif',
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '8px',
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
