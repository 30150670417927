import 'antd/lib/modal/style/index.css';
import '~/common/styles/styleIAS.css';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import { Modal, Table } from 'antd';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import NoData from '~/common/components/noData/NoData';
import { hasOwnedOrCorpAccounts, HouseholdType, mapPartyToShareListView } from '~/common/partyHelpers';
import { IAccount } from '~/stores/account/accountTypes';
import { getAllAvailableAccounts } from '~/stores/account/selectors/accountSelectors';
import { deletePartyShareAccount, fetchAccesSharing } from '~/stores/party/actions/partyActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { getAccountShare, getIsFetchingAccesSharing, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { BoxShadow, Container, ContainerRow, IAButtonOutline, LabelBold, MainBox } from '~/common/styles/baseStyle';
import { Typography } from '@mui/material';
import Loading from '../../../common/components/Loading/Loading';
import { isUserAdvisorOrAdmin } from '../../../stores/system/selectors/SystemSelectors';
import EditAccesSharing from './EditAccesSharing';
import { FlexContainer, SharingViewContainer, RowContainer, ActionContainer, NameContainer, SharingDescription } from './AccessSharingView.style';

export interface IPropsFromDispatch {
  fetchAccesSharing: typeof fetchAccesSharing
  deletePartyShareAccount: typeof deletePartyShareAccount,
}

export interface IPropsFromState {
  culture: string,
  accountShare: any[],
  party: IPartyV1,
  isFetchingAccesSharing: boolean,
  accounts: IAccount[],
  isUserAdvisorOrAdmin: boolean
}

export interface IAccessSharingState {
  editAccounts: string[],
  confirmVisible: boolean,
  idShareDelete: string
}

class AccessSharingView extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IAccessSharingState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);

    this.state = {
      editAccounts: [],
      confirmVisible: false,
      idShareDelete: '',
    };

    const { party } = this.props;
    if (hasOwnedOrCorpAccounts(party)) {
      this.props.fetchAccesSharing();
    }
  }

  public handleRemoveShare = (e: any) => {
    const { party } = this.props;
    this.props.deletePartyShareAccount(party.id, this.state.idShareDelete);
    this.setState({
      confirmVisible: false,
      idShareDelete: '',
    });
  };

  public handleDelete = (e: any) => {
    this.setState({
      confirmVisible: true,
      idShareDelete: e.currentTarget.dataset.id,
    });
  };

  public handleCancel = (e: any) => {
    this.setState({
      confirmVisible: false,
      idShareDelete: '',
    });
  };

  private showLearnMore = (e: any) => {
    Modal.info({
      title: i18n.t('householding.houseKeyTitle') as string,
      content: (
        <div>
          <Trans i18nKey="householding.information" components={{ b: <strong /> }} />
        </div>
      ),
    });
  };

  public render() {
    const { accountShare, isFetchingAccesSharing } = this.props;

    if (isFetchingAccesSharing) {
      return (
        <Container style={{ minHeight: 200 }}>
          <Loading show />
        </Container>
      );
    }

    const renderContent = (value: any, row: any, index: number) => {
      const obj = {
        children: (
          <FlexContainer>
            <NameContainer>
              {value}
            </NameContainer>
          </FlexContainer>
        ),
        props: { rowSpan: row.rowSpan, className: 'groupColumn' },
      };

      if (row.isLastRow) {
        obj.props.className = 'groupColumn borderBottomBlue';
      }

      return obj;
    };

    const renderAccess = (value: any, row: any, index: number) => {
      const obj = {
        children: (
          <FlexContainer>
            <ActionContainer>
              <EditAccesSharing isEdit userGuid={row.userId} sharedAccount={accountShare} />
              <IconButton data-id={row.userId} onClick={this.handleDelete} style={{ color: '#003DA5' }}>
                <Delete />
              </IconButton>
            </ActionContainer>
          </FlexContainer>
        ),
        props: { rowSpan: row.rowSpan, className: 'groupColumn' },
      };

      if (row.isLastRow) {
        obj.props.className = 'groupColumn borderBottomBlue';
      }

      return obj;
    };

    const renderItem = (value: any, row: any, index: number) => {
      const obj = {
        children: value,
        props: { className: row.isLastRow ? 'borderBottomBlue' : '' },
      };

      return obj;
    };

    const columns = [
      {
        title: i18n.t('householding.name'),
        dataIndex: 'owner',
        key: 'Owner',
        width: '220px',
        render: renderContent,
      },
      {
        title: i18n.t('householding.manageAccess'),
        key: 'acces',
        width: '120px',
        render: renderAccess,
      },
      {
        title: i18n.t('householding.accesToMyAccount'),
        dataIndex: 'accountType',
        key: 'accountType',
        render: renderItem,
      },
      {
        title: '',
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        render: renderItem,
      },
    ];

    const accessSharingAccounts = accountShare.filter((x) => x.role === HouseholdType.SHARING);
    const data = mapPartyToShareListView(accessSharingAccounts, this.props.accounts);
    const noDataText = (hasOwnedOrCorpAccounts(this.props.party)) ? i18n.t('householding.noShare') : i18n.t('householding.noAccountToShare');
    const sortText = i18n.t('common.sort');

    return (
      <BoxShadow>
        <SharingViewContainer>
          <FlexContainer>

            <Typography variant="body1" component="div" style={{ paddingBottom: '20px', paddingTop: '20px', width: '65%' }}>
              <Trans i18nKey="householding.accesDescription" />
            </Typography>

            <SharingDescription style={{ width: '35%' }}>
              <MainBox style={{ padding: 7, backgroundColor: '#eff1f4' }}>
                <ContainerRow>
                  <LabelBold>
                    <Typography variant="body2" component="span">
                      <Trans i18nKey="householding.houseKey" />
                    </Typography>
                    <br />
                    <Typography variant="body1" component="span">
                      {this.props.party.id.substr(this.props.party.id.length - 6)}
                    </Typography>
                  </LabelBold>
                  <IAButtonOutline onClick={this.showLearnMore} variant="outlined" style={{ marginLeft: 50 }}>
                    <Trans i18nKey="common.learnMore" />
                  </IAButtonOutline>
                </ContainerRow>
              </MainBox>
            </SharingDescription>
          </FlexContainer>

          <Table sortDirections={['ascend', 'descend', 'ascend']} rowKey="key" columns={columns} dataSource={data} pagination={false} className="tableHousehold" locale={{ emptyText: (<NoData text={noDataText} />), triggerDesc: i18n.t('common.sortDescending'), triggerAsc: i18n.t('common.sortAscending') }} showSorterTooltip={{ title: sortText, placement: 'bottom' }} />
          <br />
          {hasOwnedOrCorpAccounts(this.props.party) &&
            <RowContainer>
              <EditAccesSharing isEdit={false} userGuid={null} sharedAccount={accountShare} />
            </RowContainer>}
          <Modal
            title={i18n.t('householding.deleteMember') as string}
            open={this.state.confirmVisible}
            onOk={this.handleRemoveShare}
            onCancel={this.handleCancel}
            okButtonProps={{ disabled: this.props.isUserAdvisorOrAdmin }}
          >
            <Trans i18nKey="householding.deleteMemberConfirmation" />
          </Modal>
        </SharingViewContainer>
      </BoxShadow>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    culture: state.system.culture,
    accountShare: getAccountShare(state),
    party: getPartyV1(state),
    isFetchingAccesSharing: getIsFetchingAccesSharing(state),
    accounts: getAllAvailableAccounts(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchAccesSharing,
  deletePartyShareAccount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccessSharingView);
