/* eslint-disable quote-props */
import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { BoxShadow, ContainerRow, TabMenuContainer } from '~/common/styles/baseStyle';
import { TrendsSelectMarketType, fetchTrends } from '~/stores/marketData/actions/marketDataActions';
import { IMarketDataState } from '~/stores/marketData/marketDataTypes';
import TrendsTable from './TrendsTable';
import { MarketSectionTab } from '~/common/components/market-overview/MarketSectionTab';

export interface IPropsFromState {
  marketDataState: IMarketDataState;
}

export interface IPropsFromDispatch {
  fetchTrendsRef: typeof fetchTrends;
}

interface ITrendsProps {}

const Trends: React.FC<ITrendsProps & IPropsFromState> = ({ marketDataState }) => {
  const dispatch = useDispatch();

  const trends = marketDataState.trends.get(marketDataState.trendSelectedMarketType);
  return (
    <>
      <Grid item paddingY={3}>
        <ContainerRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Typography variant="h4" component="div" style={{ paddingBottom: '12px' }}>
            <Trans i18nKey="marketsPage.trends.title" />
          </Typography>
        </ContainerRow>
        <ContainerRow>
          <TabMenuContainer>
            {/* <MarketSectionTab
              currentMarketIndex="index"
              selectedMarketIndex={marketDataState.trendSelectedMarketType}
              tabTextTranslationKey="marketsPage.trends.marketIndices"
              onClick={(e) => { e.preventDefault(); dispatch(TrendsSelectMarketType('index')); }}
            /> */}
            {/* <MarketSectionTab
              currentMarketIndex="commodity"
              selectedMarketIndex={marketDataState.trendSelectedMarketType}
              tabTextTranslationKey="marketsPage.trends.commodities"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TrendsSelectMarketType('commodity'));
              }}
            /> */}
            <MarketSectionTab
              currentMarketIndex="canadianSector"
              selectedMarketIndex={marketDataState.trendSelectedMarketType}
              tabTextTranslationKey="marketsPage.trends.canadianSectors"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TrendsSelectMarketType('canadianSector'));
              }}
            />
            <MarketSectionTab
              currentMarketIndex="americanSector"
              selectedMarketIndex={marketDataState.trendSelectedMarketType}
              tabTextTranslationKey="marketsPage.trends.americanSectors"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TrendsSelectMarketType('americanSector'));
              }}
            />
            <MarketSectionTab
              currentMarketIndex="currency"
              selectedMarketIndex={marketDataState.trendSelectedMarketType}
              tabTextTranslationKey="marketsPage.trends.currencies"
              onClick={(e) => {
                e.preventDefault();
                dispatch(TrendsSelectMarketType('currency'));
              }}
            />
          </TabMenuContainer>
        </ContainerRow>
        <BoxShadow
          style={{
            width: '100%',
            paddingBottom: '1.5rem',
            cursor: marketDataState.isFetchingTrends.get(marketDataState.trendSelectedMarketType) ? 'wait' : 'auto',
          }}
        >
          <TrendsTable trendType={marketDataState.trendSelectedMarketType} trends={trends || []} />
          {(marketDataState.trendSelectedMarketType === 'americanSector' ||
            marketDataState.trendSelectedMarketType === 'currency' ||
            marketDataState.trendSelectedMarketType === 'canadianSector') &&
          (trends || []).length > 0 ? (
            <div style={{ paddingTop: '24px' }}>
              <Typography component="span">
                <Trans i18nKey={`marketsPage.trends.${marketDataState.trendSelectedMarketType}Note`} />
              </Typography>
            </div>
          ) : undefined}
        </BoxShadow>
      </Grid>
    </>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    marketDataState: state.marketData,
  };
}

export default connect(mapStateToProps)(Trends);
