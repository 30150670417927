import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import i18n from '~/app/i18n';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { formatDatePerLocale, isFieldEmptyOrNull } from '~/common/helpers';
import React from 'react';
import { DividendSummaryContainer } from './DividentSummary.style';

interface IDividendSummarySectionProps {
  nextDividendPayDate: Date
  ForwardDividendPerShare: number
  ForwardDividendYield: number
  etf?: boolean
}

export const DividendSummarySection: React.FC<IDividendSummarySectionProps> = ({ nextDividendPayDate, ForwardDividendPerShare, ForwardDividendYield, etf }) => (
  <DividendSummaryContainer container justifyContent="space-between" alignItems="center">
    <Grid item><Typography variant="body2" component="div"> {(etf ? i18n.t('stockOverview.tabs.dividends.nextDistribution') : i18n.t('stockOverview.tabs.dividends.nextDividend')) as string}</Typography>{isFieldEmptyOrNull(nextDividendPayDate) ? <a>-</a> : formatDatePerLocale(nextDividendPayDate)}</Grid>
    <Grid item><Typography variant="body2" component="div">{(etf ? i18n.t('stockOverview.tabs.dividends.forwardDistribution') : i18n.t('stockOverview.tabs.dividends.forwardDividend')) as string}</Typography>{isFieldEmptyOrNull(ForwardDividendPerShare) ? <a>-</a> : <NumberQI isMoney value={ForwardDividendPerShare} />}</Grid>
    <Grid item><Typography variant="body2" component="div">{(etf ? i18n.t('stockOverview.tabs.dividends.forwardDistributionYield') : i18n.t('stockOverview.tabs.dividends.forwardDividendYield')) as string}</Typography>{isFieldEmptyOrNull(ForwardDividendYield) ? <a>-</a> : <NumberQI value={ForwardDividendYield} isPercent />}</Grid>
  </DividendSummaryContainer>
);
