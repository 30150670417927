import { CookiesNameEnum, Languages, MenuHiddenRouteNames, RouteNames } from '~/app/appTypes';
import { establishCulture, isConsentFeatureActiveForClient, isCurrentIsoLangEN } from '~/common/helpers';
import AssetsAllocationPage from '~/pages/assets-allocation/AssetsAllocationPage';
import HistoryPage from '~/pages/history-page/HistoryPage';
import HoldingsPage from '~/pages/holdings-page/HoldingsPage';
import HouseHoldingPage from '~/pages/householding-page/HouseHoldingPage';
import LandingPage from '~/pages/landing-page/LandingPage';
import NotFoundPage from '~/pages/not-found-page/NotFoundPage';
import PerformancePage from '~/pages/performance/PerformancePage';
import RegisteredAccount from '~/pages/registered-account-page/RegisteredAccountPage';
import SendMessagePage from '~/pages/messages-page/sendmessage-page/SendMessagePage';
import {
  getIsFetchingPartyByUser,
  getIsFetchingPartyUserHasError,
  getParty,
} from '~/stores/party/selectors/partySelectors';
import { setCulture, fetchClaims } from '~/stores/system/actions/systemActions';
import { getCulture, getHasError, getIsClaimsFetching, getToken } from '~/stores/system/selectors/SystemSelectors';
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import {
  REACT_APP_USE_API_GATEWAY,
  REACT_APP_MOCK_TOKEN,
  TOGGLE_MARKETDATA_FEATURE_PAGE,
  IMPERSONATE,
} from '~/common/API';
import { axiosInstance } from '~/common/utils/HttpClient';
import DocumentPage from '~/pages/document-page/DocumentPage';
import { getIsAccountsFetching, hasOnlySharedAccounts } from '~/stores/account/selectors/accountSelectors';
import SettingPage from '~/pages/settings-page/SettingPage';
import PositionOverviewPage from '~/pages/position-overview-page/PositionOverviewPage';
import { isSymbolLinkEnabled } from '~/common/featureToggleHelper';
import { Trans } from 'react-i18next';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { appTheme } from '~/common/themes/theme';
import { ThemeProvider } from '@mui/material/styles';
import { appFRTheme } from '~/common/themes/theme.fr';
import { ClearSession } from './sessionManager';
import { IAppRootState } from './rootReducer';
import i18n from './i18n';
import { ErrorBoundary } from './ErrorBoundary';
import { fetchToken } from '../stores/oidc/actions/oidcActions';
import Loading from '../common/components/Loading/Loading';
import NavigationMenu from './components/navigation-menu/NavigationMenu';
import ConsentPage from '~/pages/householding-page/consent-view/ConsentView';
import KYCPage from '~/pages/kyc-page/KYCPage';
import { getShowKYCMenu } from '~/stores/kyc/kycSelectors';
import MarketPage from '~/pages/markets-page/MarketPage';
import MarketOverviewPage from '~/pages/market-overview-page/MarketOverviewPage';
import {
  LandingAccountsSectionSkeleton,
  LandingContactSectionSkeleton,
  LandingHeaderSectionSkeleton,
  LandingMarketSectionSkeleton,
} from '~/pages/landing-page/LandingSkeleton';
import { Container } from '~/common/styles/baseStyle';
import { ScrollTop } from '~/pages/holdings-page/BackToTopButton';

const tagManagerArgs: TagManagerArgs = {
  gtmId:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID !== undefined ? process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID : '',
};
TagManager.initialize(tagManagerArgs);

const AppContainer = styled.div`
  color: ${appTheme.palette.primary.contrastText};

  width: 1265px;
  max-width: 1265px;

  box-sizing: border-box;
  display: flex;
  background-color: ${appTheme.palette.background.default};

  div {
    box-sizing: border-box;
  }
`;

export const PageContainer = styled('div')<{ isMenuHidden?: boolean }>`
  width: ${(props) => (props.isMenuHidden ? '1265px;' : '980px;')}
  margin-bottom: 50px;
  min-height: 200px;
`;
const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  margin-left: 6px;
`;
export const ArrowDown = styled(Arrow)<{ color?: string }>`
  border-top: 5px solid ${(props) => (props.color ? props.color : 'black')};
`;
export const ArrowUp = styled(Arrow)<{ color?: string }>`
  border-bottom: 5px solid ${(props) => (props.color ? props.color : 'black')};
`;
export const TableArrowDown = styled(Arrow)`
  border-top: 7px solid ${(props) => props.theme.tableSortingArrowColor};
`;
export const TableArrowUp = styled(Arrow)`
  border-bottom: 7px solid ${(props) => props.theme.tableSortingArrowColor};
`;

export interface IPropsFromState {
  culture: string;
  areResourcesLoaded: boolean;
  hasError: boolean;
  isClaimsFetching: boolean;
  token: string;
  isPartyFetching: boolean;
  isFetchingPartyUser: boolean;
  isFetchingPartyUserHasError: boolean;
  hasAccessToDocumentExchange: boolean;
  showKYCMenu: boolean | null;
  currentPathLocation: string;
  isFetchingAccount: boolean;
  isFetchingToken: boolean;
}

export interface IPropsFromDispatch {
  setCulture: typeof setCulture;
  fetchToken: typeof fetchToken;
  fetchClaims: typeof fetchClaims;
}

export class App extends React.Component<any & any> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.setLanguage();
    document.title = i18n.t('common.mainPageTitle');
  }

  private setLanguage = () => {
    const cookieValue = Cookies.get(CookiesNameEnum.language);

    if (!cookieValue) {
      Cookies.set(CookiesNameEnum.language, Languages.english);
    }

    const culture = establishCulture(cookieValue);

    i18n.changeLanguage(culture);
    this.props.setCulture(culture);
  };

  private getUserConnected = () => {
    if (REACT_APP_USE_API_GATEWAY) {
      this.props.fetchToken();
    } else {
      axiosInstance.defaults.headers.Bearer = `${REACT_APP_MOCK_TOKEN}`;
      this.props.fetchClaims();
    }
  };

  public componentDidMount() {
    this.getUserConnected();
    this.setLanguage();
    this.handleLogOff();
    setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: this.props.location.pathname + this.props.location.search,
        },
      });
    }, 200);
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.location !== this.props.location) {
      setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            pagePath: this.props.location.pathname + this.props.location.search,
          },
        });
      }, 200);
    }
  }

  private handleLogOff = () => {
    const logOutButton = document.querySelector('.logoff');

    if (logOutButton) {
      $('.logoff').bind('click', ClearSession);
    }
  };

  private isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  private renderLoading = (type: 'loading' | 'landingSkeleton' = 'loading') => {
    const { hasError, party } = this.props;
    return (
      <IntlProvider locale={i18n.language}>
        <ThemeProvider theme={isCurrentIsoLangEN() ? appTheme : appFRTheme}>
          <AppContainer>
            <NavigationMenu />
            <ErrorBoundary hasServerError={hasError}>
              <PageContainer>
                {type === 'loading' ? (
                  <Loading show />
                ) : (
                  <Container>
                    <LandingHeaderSectionSkeleton party={party} />
                    <LandingMarketSectionSkeleton />
                    <LandingAccountsSectionSkeleton />
                    <LandingContactSectionSkeleton />
                  </Container>
                )}
              </PageContainer>
            </ErrorBoundary>
          </AppContainer>
        </ThemeProvider>
      </IntlProvider>
    );
  };

  private isLandingPage = (currentRoute: string) => {
    const replaceAllSlashes = (text: string) => text.replace(/\//g, '');
    return replaceAllSlashes(RouteNames.landing) === replaceAllSlashes(currentRoute);
  };

  public render() {
    const {
      hasError,
      isClaimsFetching,
      isFetchingPartyUser,
      isFetchingAccount,
      isFetchingPartyUserHasError,
      party,
      hasAccessToDocumentExchange,
      currentPathLocation,
      isFetchingToken,
    } = this.props;

    const isLandingPage = this.isLandingPage(currentPathLocation);
    const isLoadingInitialData =
      isClaimsFetching || this.isEmpty(party) || isFetchingPartyUser || isFetchingAccount || isFetchingToken;
    if (isLandingPage && isLoadingInitialData && !isFetchingPartyUserHasError) {
      return this.renderLoading('landingSkeleton');
    }

    if (!isLandingPage && isLoadingInitialData && !isFetchingPartyUserHasError) {
      return this.renderLoading();
    }

    const hideMenu = MenuHiddenRouteNames.includes(currentPathLocation);

    return (
      <IntlProvider locale={i18n.language}>
        <ThemeProvider theme={isCurrentIsoLangEN() ? appTheme : appFRTheme}>
          <AppContainer>
            {!hideMenu && <NavigationMenu />}
            {isFetchingPartyUserHasError ? (
              <Trans i18nKey="common.globalError" />
            ) : (
              <ErrorBoundary hasServerError={hasError}>
                <PageContainer isMenuHidden={hideMenu}>
                  <div id="back-to-top-anchor" />
                  <Switch>
                    <Route exact path={RouteNames.landing} component={LandingPage} />
                    <Route path={RouteNames.mirrorview} component={LandingPage} />
                    <Route path={RouteNames.holdings} component={HoldingsPage} />
                    <Route path={RouteNames.history} component={HistoryPage} />
                    <Route path={RouteNames.assetsAllocation} component={AssetsAllocationPage} />
                    <Route path={RouteNames.householding} component={HouseHoldingPage} />
                    <Route path={RouteNames.registeredAccount} component={RegisteredAccount} />
                    <Route path={RouteNames.performance} component={PerformancePage} />
                    <Route path={RouteNames.settings} component={SettingPage} />
                    {isSymbolLinkEnabled() && (
                      <Route path={RouteNames.positionOverview} component={PositionOverviewPage} />
                    )}
                    MarketIndexOverview
                    {TOGGLE_MARKETDATA_FEATURE_PAGE && (
                      <Route path={RouteNames.marketProductOverview} component={MarketOverviewPage} />
                    )}
                    {hasAccessToDocumentExchange && <Route path={RouteNames.sendMessage} component={SendMessagePage} />}
                    <Route path={RouteNames.documents} component={DocumentPage} />
                    {isConsentFeatureActiveForClient(party) && (
                      <Route path={RouteNames.settingConsent} component={ConsentPage} />
                    )}
                    <Route path={RouteNames.kyc} component={KYCPage} />
                    {TOGGLE_MARKETDATA_FEATURE_PAGE && <Route path={RouteNames.markets} component={MarketPage} />}
                    <Route component={NotFoundPage} />
                  </Switch>
                </PageContainer>
                <ScrollTop {...this.props} />
              </ErrorBoundary>
            )}
          </AppContainer>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export function mapStateToProps(state: IAppRootState) {
  return {
    culture: getCulture(state),
    hasError: getHasError(state),
    isClaimsFetching: getIsClaimsFetching(state),
    token: getToken(state),
    party: getParty(state),
    isFetchingPartyUser: getIsFetchingPartyByUser(state),
    isFetchingPartyUserHasError: getIsFetchingPartyUserHasError(state),
    hasAccessToDocumentExchange: !hasOnlySharedAccounts(state),
    showKYCMenu: getShowKYCMenu(state),
    currentPathLocation: state.router.location?.pathname,
    isAccountsFetching: getIsAccountsFetching(state),
    isFetchingToken: IMPERSONATE ? false : !state.oidc.tokenFetched,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCulture,
      fetchToken,
      fetchClaims,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps as any, mapDispatchToProps as any)(App as any) as any);
