import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AdvisorActionTypes } from '~/stores/advisor/AdvisorTypes';
import { fetchAdvisorFailed, fetchAdvisorAborted, fetchAdvisorSuccessful } from '~/stores/advisor/actions/advisorActions';
import { AdvisorHttpClient } from '~/stores/advisor/service/AdvisorHttpClient';
import { getIsDataExpired } from '~/stores/advisor/selectors/advisorSelectors';
import { getToken } from '~/stores/system/selectors/SystemSelectors';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';

export function* fetchAdvisor({ payload }: any): any {
  try {
    const { repcodes } = payload;
    const isDataExpired = yield select(getIsDataExpired);
    if (isDataExpired) {
      const advisors = (yield call(AdvisorHttpClient.fetchAdvisor, yield select(getToken), repcodes)) as IAdvisor[];
      if (advisors.length > 0) {
        yield put(fetchAdvisorSuccessful(advisors));
      } else {
        yield put(fetchAdvisorFailed());
      }
    } else {
      yield put(fetchAdvisorAborted());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchAdvisorFailed());
  }
}

function* advisorSaga() {
  yield takeLatest(AdvisorActionTypes.FETCH_ADVISOR, fetchAdvisor);
}

export default advisorSaga;
