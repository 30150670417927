import * as React from 'react';
import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { MainBox } from '~/common/styles/baseStyle';
import { IFundData } from '~/stores/positionDetail/types';
import { SymbolInfoTypes } from '~/common/types';
import { StyledTab, StyledTabs } from '../PositionOverviewPage.style';
import ProductHeaderSection from '../ProductHeaderSection';
import FundManagers from './FundManagers';
import FundOverview from './FundOverview';
import { FundSummary } from './FundSummary';
import { ProductHeaderContainer } from '../ProductHeaderContainer';
import { PositionName } from '../PositionName';

export interface IProps {
  symbolDescription: string,
  fundData: IFundData,
  historicalData: any,
  isHeld: boolean,
  isFromMarketsScreen: boolean,
}

interface IFundState {
  tabIndex: number
}

class FundView extends React.PureComponent<IProps, IFundState> {
  constructor(props: any) {
    super(props);

    this.state = {
      tabIndex: 0,
    };
  }

  private handleTabChange = (e: any, value: any) => {
    this.setState({ tabIndex: value });
  };

  public render() {
    const { fundData, symbolDescription } = this.props;

    return (
      <>
        <ProductHeaderContainer>
          <PositionName symbol={fundData.fundSymbol} name={symbolDescription} />
          <MainBox>
            <Grid container>
              <ProductHeaderSection stockData={fundData} product={SymbolInfoTypes.FUND} isHeld={this.props.isHeld} isFromMarketsScreen={this.props.isFromMarketsScreen} />
              <FundSummary fundData={fundData} />
            </Grid>
          </MainBox>
        </ProductHeaderContainer>
        <StyledTabs value={this.state.tabIndex} onChange={this.handleTabChange} textColor="secondary">
          <StyledTab label={<Trans i18nKey="stockOverview.tabs.overview" />} />
          <StyledTab label={<Trans i18nKey="stockOverview.tabs.manager" />} />
        </StyledTabs>

        {this.state.tabIndex === 0 && <FundOverview fundData={fundData} historicalData={this.props.historicalData} />}
        {this.state.tabIndex === 1 && <FundManagers fundData={fundData} />}
      </>
    );
  }
}

export default FundView;
