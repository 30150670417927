import styled from 'styled-components';

export const ConfirmContainer = styled.div`
    text-align : center;
    width : 100%;
`;

export const StyledP = styled.p`
    margin: 0px;
    color: #333333;
`;

export const Title = styled(StyledP)`
    font-size: 24px;
    font-weight: bold;
`;

export const CheckCircleIconStyle = {
  fontSize: '58px',
  color: '#7cb342',
};

export const CancelIconStyle = {
  fontSize: '58px',
  color: '#FA2625',
};
