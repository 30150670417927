import { push } from 'redux-first-history';
import { ErrorTypes } from '~/app/appTypes';
import { IAppRootState } from '~/app/rootReducer';
import { GetIsStorageValueByKey, SetStorageKeyValue } from '~/app/sessionManager';
import { deserializeQueryString, isKYCFeatureActiveForClient, serializeQueryStringParameters } from '~/common/helpers';
import { AccountActionTypes, IAccount, IAccountState } from '~/stores/account/accountTypes';
import {
  fetchAccounts,
  fetchDeliveryPreferencesSummary,
  fetchNetInvested,
} from '~/stores/account/actions/accountActions';
import {
  getAccountIds,
  getClientAccountsFromState,
  getRepCodesOfOwnedAccounts,
} from '~/stores/account/selectors/accountSelectors';
import { fetchAdvisor } from '~/stores/advisor/actions/advisorActions';
import { EventHttpClient } from '~/stores/events/services/EventHttpClient';
import { oidcActionTypes } from '~/stores/oidc/oidcTypes';
import { fetchParty } from '~/stores/party/actions/partyActions';
import { IPartyV1, PartyActionTypes } from '~/stores/party/partyTypes';
import { getPartyV1, getUserId } from '~/stores/party/selectors/partySelectors';
import {
  appGlobalError,
  fetchClaims,
  fetchClaimsAborted,
  fetchClaimsFailed,
  fetchClaimsSuccessful,
  fetchFeatureTogglesAborted,
  fetchFeatureTogglesFailed,
  fetchFeatureTogglesSuccessful,
} from '~/stores/system/actions/systemActions';
import { getUserToken, isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { IFeatureToggles, IQueryStringParameter, IToken, SystemActionTypes } from '~/stores/system/systemTypes';
import { parse } from 'query-string';
import { all, call, put, select, take, takeLatest, race } from 'redux-saga/effects';

import { FeatureTogglesHttpClient } from '../services/FeatureTogglesHttpClient';
import { UserHttpClient } from '../services/UserHttpClient';
import { fetchConsentVerbiage, handleFetchPartyInfo } from '~/stores/party/sagas/partySaga';
import {
  IMPERSONATE,
  REACT_APP_USE_API_GATEWAY,
  TOGGLE_CONSENT,
  TOGGLE_CONTEST_BANNER,
  TOGGLE_KYC,
} from '~/common/API';
import { fetchKYCStatus } from '~/stores/kyc/kycActions';
import { KYCGetStatusRequest } from '~/stores/kyc/kycTypes';
import {
  fetchNetInvestedToken,
  handleFetchAccounts,
  handleFetchInitialAccounts,
} from '~/stores/account/sagas/accountSaga';
import { getSelectedAccountIds } from '~/common/accountsHelpers';
import { fetchMarketData } from '~/stores/marketData/actions/marketDataActions';
import { loadContest } from '~/stores/contest/actions/contestActions';

function* setQueryStringParameters({ payload }: any): any {
  try {
    const parametersToSet = payload as IQueryStringParameter[];
    const currentLocation = yield select((state: IAppRootState) => state.router.location);
    const currentQueryString = currentLocation.search;
    const currentRoute = currentLocation.pathname;
    const currentParameters = deserializeQueryString(currentQueryString);
    const newParameters = [] as IQueryStringParameter[];

    currentParameters.forEach((currentParameter: IQueryStringParameter) => {
      const parameterToAdd = parametersToSet.filter((p) => p.name === currentParameter.name);
      if (parameterToAdd.length > 0) {
        newParameters.push(parameterToAdd[0]);
      } else {
        newParameters.push(currentParameter);
      }
    });
    parametersToSet.forEach((parameterToSet) => {
      const currentParameter = currentParameters.filter((cp) => cp.name === parameterToSet.name);
      if (!currentParameter || currentParameter.length <= 0) {
        newParameters.push(parameterToSet);
      }
    });

    const serializedNewParameters = serializeQueryStringParameters(newParameters);
    yield put(push(`${currentRoute}${serializedNewParameters}`));
  } catch {
    yield null;
  }
}

function* handleFetchClaims() {
  try {
    const response = (yield call(UserHttpClient.fetchClaims)) as IToken;

    if (response) {
      const idFromClaims = response.partyId;

      const queryParsed = parse(window.location.search);

      let partyId = idFromClaims && idFromClaims !== '' ? idFromClaims : '';

      if (!partyId && queryParsed.id) {
        partyId = queryParsed.id;
        // Use session storage for an Advisor from Mirrowview
        sessionStorage.setItem('partyId', partyId);
      }

      if (!partyId) {
        // if the Advisor navigate into website or refresh get the session Id
        const sessionParty = sessionStorage.getItem('partyId');
        partyId = sessionParty ?? '';
      }

      if ((response.partyId === '' || response.partyId === undefined) && partyId) {
        response.partyId = partyId;
      }

      yield put(fetchClaimsSuccessful(response));
    } else {
      yield put(fetchClaimsFailed());
      yield put(appGlobalError(ErrorTypes.Token));
    }
  } catch (err) {
    console.log(err);
    yield put(appGlobalError(err as any));
    yield put(fetchClaimsAborted());
  }
}

function* handleFetchPortalInfo() {
  if (REACT_APP_USE_API_GATEWAY) {
    yield put(fetchClaims());
    yield all([take(SystemActionTypes.FETCH_CLAIMS_SUCCESSFUL)]);
  }

  const isAdvisorOrAdmin: boolean = yield select(isUserAdvisorOrAdmin);
  if (IMPERSONATE) {
    // When running on localhost, claims is not available.
    //   We need to wait claims to load to continue the initial load
    yield all([take(SystemActionTypes.FETCH_CLAIMS_SUCCESSFUL)]);
  }
  if (IMPERSONATE || isAdvisorOrAdmin) {
    // This is the flow of non-parallel loading of data
    const userToken: IToken = yield select(getUserToken);

    yield put(fetchParty(userToken.partyId));

    yield all([take(PartyActionTypes.FETCH_PARTY_SUCCESSFUL)]);

    yield put(fetchAccounts(userToken.accounts, true, userToken.partyId));

    // Wait for the accounts to load NetInvested
    yield all([take(AccountActionTypes.FETCH_ACCOUNTS_SUCCESSFUL)]);
    const selectedAccountIds: string[] = yield select(getAccountIds);
    const clientAccounts: IAccountState = yield select(getClientAccountsFromState);
    const { accounts }: { accounts: IAccount[] } = clientAccounts as any;
    const householdAccountIds = getSelectedAccountIds(selectedAccountIds, accounts);

    if (Object.keys(accounts).length) {
      yield put(fetchNetInvested(householdAccountIds));
    }
  } else {
    // Here we call all the same time and we use token info to load data
    yield all([
      call(handleFetchClaims),
      call(handleFetchPartyInfo),
      call(handleFetchInitialAccounts),
      call(fetchNetInvestedToken),
    ]);
    yield all([call(handleLogLogin)]);
  }
  yield call(handleFetchAdvisors);

  if (!isAdvisorOrAdmin) {
    yield put(fetchDeliveryPreferencesSummary());
  }

  const party: IPartyV1 = yield select(getPartyV1);
  if (TOGGLE_KYC && isKYCFeatureActiveForClient(party)) {
    const isKycSavedSuccessfully = !!GetIsStorageValueByKey('isKYCSavedSuccessfully');
    if (isKycSavedSuccessfully) {
      // Should not reload KYC status if KYC details were saved successfully
      // Edge case - when user tries to translate KYC page after saving KYC details
      return;
    }
    yield put(fetchKYCStatus(new KYCGetStatusRequest(party.accounts)));
  }
  if (TOGGLE_CONSENT) {
    yield call(fetchConsentVerbiage);
  }
  if (TOGGLE_CONTEST_BANNER && !isAdvisorOrAdmin) {
    yield put(loadContest());
  }
}

function* handleFetchAdvisors() {
  try {
    const repCodes: string[] = yield select(getRepCodesOfOwnedAccounts);
    if (repCodes.length > 0) {
      yield put(fetchAdvisor(repCodes));
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleFetchFeaturestoggles() {
  try {
    const response = (yield call(
      FeatureTogglesHttpClient.fetchFeaturesToggles,
      `${process.env.PUBLIC_URL}/api`,
    )) as IFeatureToggles;

    if (response) {
      yield put(fetchFeatureTogglesSuccessful(response));
    } else {
      yield put(fetchFeatureTogglesFailed());
      yield put(appGlobalError(ErrorTypes.Token));
    }
  } catch (err) {
    console.log(err);
    yield put(appGlobalError(err as any));
    yield put(fetchFeatureTogglesAborted());
  }
}

function* handleLogLogin() {
  const isAdvisorOrAdmin: boolean = yield select(isUserAdvisorOrAdmin);
  if (isAdvisorOrAdmin) {
    return;
  }

  const isLoginLogged = GetIsStorageValueByKey('isLoginLogged');
  if (isLoginLogged) {
    return;
  }

  try {
    SetStorageKeyValue('isLoginLogged', true);
    const partyId: string = yield select(getUserId);
    yield call(EventHttpClient.trackLogin, partyId);
  } catch (err) {
    console.log(err);
  }
}

function* systemSaga() {
  yield takeLatest(SystemActionTypes.SET_QUERY_STRING_PARAMETERS, setQueryStringParameters);
  yield takeLatest(SystemActionTypes.FETCH_CLAIMS, handleFetchClaims);
  yield takeLatest(SystemActionTypes.FETCH_CLAIMS, handleFetchFeaturestoggles);
  yield takeLatest(SystemActionTypes.FETCH_CLAIMS, handleLogLogin);

  if (IMPERSONATE) {
    yield takeLatest(SystemActionTypes.FETCH_CLAIMS, handleFetchPortalInfo);
  } else {
    yield takeLatest(oidcActionTypes.FETCH_TOKEN_SUCCESSFUL, handleFetchPortalInfo);
  }
}

export default systemSaga;
