import styled from 'styled-components';
import { StyledLinkButton } from '../../common/styles/baseStyle';

export const LinkButtonWithIcon = styled(StyledLinkButton)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const StyledAlert = styled.div`
  background-color: ${(props) => props.color};
`;
