import styled from 'styled-components';

export const RecipientSection = styled.div`
  min-width: 450px;
  width: auto;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
`;

export const RecipientsList = styled.ul`
  padding: 0 15px;
  list-style-type: none;
`;

export const ListElement = styled.li`
  padding-top: 5px;
`;
