import { IAppRootState } from '~/app/rootReducer';
import {
  fetchAccounts,
  resetNetInvestedExpirationDate,
  cleanFilteringAccountIds,
  setFilteringAccountIds,
  fetchMarketData as initFetchMarketData,
} from '~/stores/account/actions/accountActions';
import { getAllAvailableAccounts, getAccountIds } from '~/stores/account/selectors/accountSelectors';
import {
  fetchAccesSharing,
  fetchAccesSharingFailed,
  fetchAccesSharingSuccessful,
  fetchPartySearchAborted,
  fetchPartySearchFailed,
  fetchPartySearchNotFound,
  fetchPartySearchSuccessful,
  savePartyPreferedAccountSuccessful,
  fetchPartySuccessful,
  fetchPartyFailed,
  saveConsentPartyFailed,
  saveConsentPartySuccessful,
  initAutoMarketDataUpdate as initiateAutoUpdate,
  loadConsentVerbiageSuccessful,
  loadConsentVerbiageFailed,
  saveDocumentDeliveryNotificationFailed,
  saveDocumentDeliveryNotificationSuccessful,
  updateConsentParty,
  saveIntradayChangePreference,
} from '~/stores/party/actions/partyActions';

import {
  IAccountAccessSharingModel,
  IPartyAccount,
  IPartyState,
  IPartyV1,
  PartyActionTypes,
  IConsentVerbiages,
  IConsentPreferences,
} from '~/stores/party/partyTypes';
import { PartyHttpClient } from '~/stores/party/services/PartyHttpClient';
import { appGlobalError, refreshSession } from '~/stores/system/actions/systemActions';
import { IPatchJsonOperation } from '~/stores/system/systemTypes';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import {
  CompanyRole,
  getPartyFullName,
  HouseholdType,
  sharedAccountsJsonPatchOperation,
} from '../../../common/partyHelpers';
import {
  getDidSetMarketDataRealtime,
  getPartiesForSharing,
  getParty,
  getPartySearchForSharing,
  getPartyV1,
} from '../selectors/partySelectors';
import { TOGGLE_CONSENT } from '~/common/API';

export const getPartyState = (state: IAppRootState) => state.party;
export const getRootState = (state: IAppRootState) => state;

export function* fetchPartyAccesSharing({ payload }: any) {
  try {
    yield put(refreshSession());

    const party: IPartyV1 = yield select(getParty);
    const accountsIdsFiltred: string[] = party.accounts
      .filter(
        (account: IPartyAccount) => account.role === HouseholdType.OWNER || account.role === HouseholdType.COMPANY,
      )
      .map((account: IPartyAccount) => account.id);
    const parties = (yield call(
      PartyHttpClient.fetchPartial,
      accountsIdsFiltred,
      'id,partyTypeCode,firstName,lastname,businessname,accounts',
    )) as IPartyV1[];
    const partiesFiltred: IPartyV1[] = parties.filter(
      (p: IPartyV1) =>
        p.id !== party.id &&
        !p.accounts.some(
          (account: IPartyAccount) => account.role === HouseholdType.OWNER && account.noRole === CompanyRole,
        ),
    );
    const accountsAccessSharing: IAccountAccessSharingModel[] = [];

    partiesFiltred.forEach((p: IPartyV1) => {
      const accountsFiltred = p.accounts.filter(
        (account: IPartyAccount) =>
          accountsIdsFiltred.some((af: string) => af === account.id) && account.role === HouseholdType.SHARING,
      );

      accountsFiltred.forEach(
        (account: IPartyAccount) =>
          accountsAccessSharing.push({
            id: account.id,
            partyId: p.id,
            name: getPartyFullName(p),
            role: account.role,
            noRole: account.noRole.toString(),
          }),
        // eslint-disable-next-line function-paren-newline
      );
    });

    yield put(fetchAccesSharingSuccessful(accountsAccessSharing, parties));
  } catch (err) {
    yield put(fetchAccesSharingFailed());
    yield put(appGlobalError(err as any));
  }
}

export function* fetchPartyUserSearch({ payload }: any) {
  try {
    yield put(refreshSession());
    if (payload.firstName.length > 1 && payload.lastName.length > 1) {
      const user = (yield call(PartyHttpClient.fetchPartySearch, payload)) as IPartyV1;

      if (user) {
        const partySate: IPartyState = yield select(getPartyState);

        if (user.id === partySate.partyV1.id) {
          yield put(fetchPartySearchAborted());
        } else {
          yield put(fetchPartySearchSuccessful(user));
        }
      } else {
        yield put(fetchPartySearchNotFound());
      }
    } else {
      yield put(fetchPartySearchAborted());
    }
  } catch (err) {
    yield put(fetchPartySearchFailed());
  }
}

export function* handleFetchParty({ payload }: any) {
  try {
    yield put(refreshSession());

    const response: IPartyV1 = yield call(PartyHttpClient.fetchById, payload.id);

    yield put(fetchPartySuccessful(response));

    const isAdvisorOrAdmin: boolean = yield select(isUserAdvisorOrAdmin);

    if (isAdvisorOrAdmin) {
      const rootAccountIds = response.accounts.map((m: IPartyAccount) => m.id);
      yield put(fetchAccounts(rootAccountIds, true, payload.id));
    }
  } catch (err) {
    yield put(fetchPartyFailed());
    yield put(appGlobalError(err as any));
  }
}

export function* handleFetchPartyInfo() {
  try {
    yield put(refreshSession());

    const response: IPartyV1 = yield call(PartyHttpClient.fetchByToken);

    yield put(fetchPartySuccessful(response));

    const isAdvisorOrAdmin: boolean = yield select(isUserAdvisorOrAdmin);

    if (isAdvisorOrAdmin) {
      const rootAccountIds = response.accounts.map((m: IPartyAccount) => m.id);
      yield put(fetchAccounts(rootAccountIds, true, response.id));
    }
  } catch (err) {
    yield put(fetchPartyFailed());
    yield put(appGlobalError(err as any));
  }
}
export function* savePartyPreferedAccount({ payload }: any) {
  const { id, data } = payload;
  try {
    yield put(refreshSession());
    yield call(PartyHttpClient.updatePreferredAccounts, id, data);
    yield put(fetchPartySuccessful(data));
    yield put(savePartyPreferedAccountSuccessful());

    const rootState: IAppRootState = yield select(getRootState);
    yield put(cleanFilteringAccountIds(getAccountIds(rootState)));

    yield put(resetNetInvestedExpirationDate());

    const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
    yield put(
      fetchAccounts(
        party.accounts.map((a) => a.id),
        true,
        party.id,
      ),
    );
  } catch (err) {
    yield put(fetchPartyFailed());
    yield put(appGlobalError(err as any));
  }
}

function* getPartyToUpdateShare(id: string): any {
  let partyToAddSharing = yield select(getPartySearchForSharing) as unknown as IPartyV1 | null;

  if (!partyToAddSharing) {
    const partiesSharedAccounts = yield select(getPartiesForSharing) as unknown as IPartyV1[];
    if (partiesSharedAccounts && partiesSharedAccounts.length > 0) {
      partyToAddSharing = partiesSharedAccounts.find((party: IPartyV1) => party.id === id);
    }
  }

  return partyToAddSharing;
}

export function* SaveShareAccount({ payload }: any): any {
  const { id, data } = payload;
  try {
    yield put(refreshSession());

    const currentParty: IPartyV1 = yield select(getParty) as unknown as IPartyV1;

    const partyToAddSharing = yield call(getPartyToUpdateShare, id) as unknown as IPartyV1 | null;

    if (!partyToAddSharing) {
      yield put(fetchPartyFailed());
      return;
    }

    const patchOperations: IPatchJsonOperation[] = sharedAccountsJsonPatchOperation(
      data,
      currentParty,
      partyToAddSharing,
    );

    yield call(PartyHttpClient.updateShareAccount, partyToAddSharing.id, patchOperations);
  } catch (err) {
    yield put(fetchPartyFailed());
    yield put(appGlobalError(err as any));
  }
}

export function* DeleteShareAccount({ payload }: any): any {
  const { idToDelete } = payload;
  try {
    const currentParty: IPartyV1 = yield select(getParty) as unknown as IPartyV1;
    const partyToAddSharing = yield call(getPartyToUpdateShare, idToDelete) as unknown as IPartyV1 | null;

    if (!partyToAddSharing) {
      yield put(fetchPartyFailed());
      return;
    }

    const patchOperations: IPatchJsonOperation[] = sharedAccountsJsonPatchOperation(
      [],
      currentParty,
      partyToAddSharing,
    );

    yield put(refreshSession());
    yield call(PartyHttpClient.updateShareAccount, partyToAddSharing.id, patchOperations);
    yield put(fetchAccesSharing());
  } catch (err) {
    yield put(fetchPartyFailed());
    yield put(appGlobalError(err as any));
  }
}

function* saveDocumentDeliveryNotification({ payload }: any) {
  yield put(refreshSession());
  const { didConsent } = payload;
  try {
    const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
    yield call(PartyHttpClient.saveDocumentDeliveryNotification, party, didConsent);
    yield put(saveDocumentDeliveryNotificationSuccessful());
  } catch (err) {
    yield put(saveDocumentDeliveryNotificationFailed());
  }
}

function* saveUpdateDeliveryPreferencesMetadata({ payload }: any) {
  yield put(refreshSession());
  const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
  yield call(PartyHttpClient.saveUpdateDeliveryPreferencesMetadata, party, payload);
}

function* saveConsentPromptMetadata({ payload }: any) {
  yield put(refreshSession());
  const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
  yield call(PartyHttpClient.saveConsentPromptMetadata, party, payload);
}

function* saveHoldingSortPreferencesMetadata({ payload }: any) {
  yield put(refreshSession());
  const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
  yield call(PartyHttpClient.saveHoldingSortPreferencesMetadata, party, payload);
}

function* saveMarketDataRealtime({ payload }: any) {
  yield put(refreshSession());
  const { realtime } = payload;
  const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
  yield call(PartyHttpClient.saveMarketDataRealtime, party, realtime);
  if (realtime) {
    yield put(initiateAutoUpdate());
  } else {
    yield put(
      fetchAccounts(
        party.accounts.map((a) => a.id),
        true,
        party.id,
      ),
    );
  }
}

function* saveIntradayChange({ payload }: any) {
  yield put(refreshSession());
  const { realtime } = payload;
  const party: IPartyV1 = yield select(getPartyV1) as unknown as IPartyV1;
  yield call(PartyHttpClient.saveIntradayChangePreference, party, realtime);
}

export function* initAutoMarketDataUpdate() {
  const isMarketDataToggleOn: boolean = yield select(getDidSetMarketDataRealtime);
  if (isMarketDataToggleOn) {
    yield put(initFetchMarketData());
  }
}

export function* toggleHouseHoldView() {
  yield put(refreshSession());
  const partySate: IPartyState = yield select(getPartyState);
  const rootState: IAppRootState = yield select(getRootState);
  const party = partySate.partyV1;
  const allAccount = getAllAvailableAccounts(rootState);
  const defaultAccountIds = partySate.isHouseHoldingView ? party.preferedAccountIds : allAccount.map((m) => m.id);
  yield put(setFilteringAccountIds(defaultAccountIds));
  yield put(resetNetInvestedExpirationDate());
  yield put(
    fetchAccounts(
      party.accounts.map((a) => a.id),
      true,
      party.id,
    ),
  );
}

function* saveConsentPreferences({ payload }: any) {
  try {
    yield put(refreshSession());
    const consents = payload;
    const partySate: IPartyState = yield select(getPartyState);
    const party = partySate.partyV1;

    yield call(PartyHttpClient.saveClientConsent, party, consents as IConsentPreferences[]);
    yield put(saveConsentPartySuccessful());

    yield put(updateConsentParty(consents));
  } catch (err) {
    console.log(err);
    yield put(saveConsentPartyFailed());
  }
}

export function* fetchConsentVerbiage() {
  if (TOGGLE_CONSENT) {
    try {
      yield put(refreshSession());
      const partySate: IPartyState = yield select(getPartyState);
      const party = partySate.partyV1;

      const response: IConsentVerbiages[] = yield call(PartyHttpClient.fetchConsentVerbiage, party);
      yield put(loadConsentVerbiageSuccessful(response));
    } catch (err) {
      yield put(loadConsentVerbiageFailed());
      yield put(appGlobalError(err as any));
    }
  }
}

function* partySaga() {
  yield takeLatest(PartyActionTypes.DELETE_SHARE_ACCOUNT, DeleteShareAccount);
  yield takeLatest(PartyActionTypes.FETCH_PARTY_SEARCH, fetchPartyUserSearch);
  yield takeLatest(PartyActionTypes.FETCH_ACCES_SHARING, fetchPartyAccesSharing);
  yield takeLatest(PartyActionTypes.FETCH_PARTY, handleFetchParty);
  yield takeLatest(PartyActionTypes.SAVE_PREFERRED_ACCOUNT, savePartyPreferedAccount);
  yield takeLatest(PartyActionTypes.SAVE_SHARE_ACCOUNT, SaveShareAccount);
  yield takeLatest(PartyActionTypes.TOGGLE_HOUSEHOLD_VIEW, toggleHouseHoldView);
  yield takeLatest(PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION, saveDocumentDeliveryNotification);
  yield takeLatest(PartyActionTypes.SAVE_UPDATE_DELIVERY_PREFERENCES_METADATA, saveUpdateDeliveryPreferencesMetadata);
  yield takeLatest(PartyActionTypes.SAVE_CONSENT_PROMPT_METADATA, saveConsentPromptMetadata);
  yield takeLatest(PartyActionTypes.SAVE_MARKET_DATA_REALTIME_PREFERENCES_METADATA, saveMarketDataRealtime);
  yield takeLatest(PartyActionTypes.SAVE_INTRADAY_CHANGE_PREFERENCES_METADATA, saveIntradayChange);
  yield takeLatest(PartyActionTypes.SAVE_HOLDING_SORT_PREFERENCES_METADATA, saveHoldingSortPreferencesMetadata);
  yield takeLatest(PartyActionTypes.INIT_AUTO_MARKET_DATA_UPDATE, initAutoMarketDataUpdate);
  yield takeLatest(PartyActionTypes.SAVE_CONSENT_PREFERENCES, saveConsentPreferences);
  yield takeLatest(PartyActionTypes.LOAD_CONSENT_VERBIAGE, fetchConsentVerbiage);
}

export default partySaga;
