import { IAccount, IDeliveryPreferences } from '~/stores/account/accountTypes';
import { TOGGLE_HIDE_QIR, TOGGLE_TRADECONFIRMATIONS } from '~/common/API';

export interface IDeliveryPreferencesTableData {
  documentType: DocumentType,
  rows: IDeliveryPreferencesTableRow[]
}

export interface IDeliveryPreferencesTableRow {
  key: string,
  clientId: string,
  name: string,
  isPaperless: boolean,
  isEmailNotification?: boolean,
  canModify: boolean,
  isUserAdvisor: boolean,
  isTaxSeason?: boolean
}

export default function LoadDeliveryPreferences(preferences: IDeliveryPreferences[], accounts: IAccount[], isUserAdvisor: boolean): IDeliveryPreferencesTableData[] | undefined {
  if (!preferences || !accounts || accounts.length === 0) {
    return undefined;
  }

  const data: IDeliveryPreferencesTableData[] = [];
  let canContinueToAddDocumentType = true;

  for (const docType in DocumentType) {
    if (isNaN(Number(docType))) {
      if (docType === DocumentType.TradeConfirmation) {
        if (!TOGGLE_TRADECONFIRMATIONS) {
          canContinueToAddDocumentType = false;
        }
      }
      if (docType === DocumentType.ManagedStatement) {
        if (TOGGLE_HIDE_QIR) {
          canContinueToAddDocumentType = false;
        }
      }
      if (canContinueToAddDocumentType) {
        data.push({
          documentType: docType as DocumentType,
          rows: GetTableData(preferences, isUserAdvisor, docType as DocumentType),
        });
      }
      canContinueToAddDocumentType = true;
    }
  }

  return data;
}

function sortData(rows: IDeliveryPreferencesTableRow[]) {
  rows.sort((a, b) => (a.canModify === b.canModify ? 0 : (a.canModify ? -1 : 1)));
}

function GetTableData(preferences: IDeliveryPreferences[], isUserAdvisor: boolean, docType: DocumentType): IDeliveryPreferencesTableRow[] {
  const rows: IDeliveryPreferencesTableRow[] = [];

  preferences.forEach((p) => {
    let { canModify } = p;

    const rowForClientIdByType = p.documentPreferences.find((e) => e.type === docType);
    if (rowForClientIdByType) {
      const isEmailNotif = rowForClientIdByType ? (rowForClientIdByType.isEmailNotification === true || rowForClientIdByType.isEmailNotification === false ? rowForClientIdByType.isEmailNotification : undefined) : undefined;

      if (docType === DocumentType.TaxDocument && (rowForClientIdByType && rowForClientIdByType.isTaxSeason !== undefined)) {
        if (rowForClientIdByType.isTaxSeason === true) canModify = false;
      }
      if (isUserAdvisor) canModify = false;

      rows.push({
        key: `${p.clientId}${docType}`,
        clientId: p.clientId,
        name: p.accountName,
        isPaperless: rowForClientIdByType ? rowForClientIdByType.isPaperless : false,
        isEmailNotification: isEmailNotif,
        canModify,
        isUserAdvisor,
        isTaxSeason: rowForClientIdByType ? rowForClientIdByType.isTaxSeason : false,
      });
    }
  });
  sortData(rows);
  return rows;
}

export enum DocumentType {
  Statement = 'Statement',
  TradeConfirmation = 'TradeConfirmation',
  TaxDocument = 'TaxDocument',
  ManagedStatement = 'ManagedStatement'
}
