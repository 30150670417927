import * as React from 'react';
import { Portal } from '~/common/components';
import styled from 'styled-components';

const PopoverContentContainer = styled.div<{ left: number, top: number }>`
    display: flex;
    position: absolute;
    z-index: 5000;
    top: ${(props) => `${props.top}px`};
    left: ${(props) => `${props.left}px`};
    background-color: white;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.36);
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    font-family: "Open sans";
    font-size: 14px;
`;

export interface IPopoverProps {
    children?: React.ReactNode;
    parentBoundingRect: DOMRect;
}

class Popover extends React.PureComponent<IPopoverProps> {
  public render() {
    const { children, parentBoundingRect } = this.props;

    const top = parentBoundingRect.bottom + window.scrollY;
    const left = parentBoundingRect.left + window.scrollX;

    return (
      <Portal>
        <PopoverContentContainer {...{ top, left }}>
          {children}
        </PopoverContentContainer>
      </Portal>
    );
  }
}

export default Popover;
