import { IAppRootState } from '~/app/rootReducer';
import { IMessageState } from '~/stores/message/messageTypes';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { createSelector } from 'reselect';

import { getIsAccountsFetching } from '../../account/selectors/accountSelectors';
import { getIsAdvisorFetching } from '../../advisor/selectors/advisorSelectors';

export const getMessageState = (state: IAppRootState) => state.message;

export const getIsSendingMessage = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isSendingMessage,
);

export const getSendMessageSuccess = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isSendingMessageSuccess,
);

export const getSendMessageSuccessWithWarning = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isSendingMessageSuccessWithWarning,
);

export const getSendMessageError = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isSendingMessageFail,
);

export const getIsFetchingSharedDocuments = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isFetchingDocuments,
);

export const getFetchSharedDocumentsSuccess = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isFetchingDocumentsSuccess,
);

export const getFetchSharedDocumentsError = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isFetchingDocumentsFail,
);

export const getIsSharedDocumentsLoaded = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.isDocumentsLoaded,
);

export const getSharedDocuments = createSelector(
  getMessageState,
  (messageState: IMessageState) => messageState.documents,
);

export const getSendMessageDataLoadingState = createSelector(
  getIsAccountsFetching,
  getIsAdvisorFetching,
  (accountsAreLoading: boolean, advisorAreLoading: boolean) => accountsAreLoading || advisorAreLoading,
);

export const userCanSendMessages = createSelector(
  isUserAdvisorOrAdmin,
  (isAdvisor: boolean) => !(isAdvisor),
);
