import {
  IAccountAccessSharingModel,
  IPartySearchRequest,
  IPartyV1,
  PartyActionTypes,
  IUpdateDeliveryPreferencesMetadata,
  IConsentPreferences,
  IConsentVerbiages,
  IConsentPromptMetadata,
  ISortViewsMetadata,
} from '~/stores/party/partyTypes';
import { action } from 'typesafe-actions';

export const fetchPartySearch = (request: IPartySearchRequest) => action(PartyActionTypes.FETCH_PARTY_SEARCH, request);
export const fetchPartySearchSuccessful = (user: IPartyV1) =>
  action(PartyActionTypes.FETCH_PARTY_SEARCH_SUCCESSFUL, { user });
export const fetchPartySearchNotFound = () => action(PartyActionTypes.FETCH_PARTY_SEARCH_NOTFOUND);
export const fetchPartySearchFailed = () => action(PartyActionTypes.FETCH_PARTY_SEARCH_FAILED);
export const fetchPartySearchAborted = () => action(PartyActionTypes.FETCH_PARTY_SEARCH_ABORTED);
export const fetchAccesSharing = () => action(PartyActionTypes.FETCH_ACCES_SHARING);
export const fetchAccesSharingSuccessful = (accounts: IAccountAccessSharingModel[], parties: IPartyV1[]) =>
  action(PartyActionTypes.FETCH_ACCES_SHARING_SUCCESSFUL, { accounts, parties });
export const fetchAccesSharingFailed = () => action(PartyActionTypes.FETCH_PARTY_SEARCH_FAILED);

export const fetchParty = (id: string) => action(PartyActionTypes.FETCH_PARTY, { id });
export const fetchPartySuccessful = (party: IPartyV1) => action(PartyActionTypes.FETCH_PARTY_SUCCESSFUL, { party });
export const fetchPartyNotFound = () => action(PartyActionTypes.FETCH_PARTY_NOTFOUND);
export const fetchPartyFailed = () => action(PartyActionTypes.FETCH_PARTY_FAILED);

export const savePartyPreferedAccount = (id: string, data: IPartyV1) =>
  action(PartyActionTypes.SAVE_PREFERRED_ACCOUNT, { id, data });
export const savePartyPreferedAccountSuccessful = () => action(PartyActionTypes.SAVE_PREFERRED_ACCOUNT_SUCCESSFUL);
export const savePartyPreferedAccountFailed = () => action(PartyActionTypes.SAVE_PREFERRED_ACCOUNT_FAILED);
export const resetPartyPreferedAccountSaving = () => action(PartyActionTypes.RESET_PARTY_PREFERED_ACCOUNT_SAVING);

export const savePartyShareAccount = (partyId: string, id: string, data: string[]) =>
  action(PartyActionTypes.SAVE_SHARE_ACCOUNT, { partyId, id, data });
export const savePartyShareAccountSuccessful = () => action(PartyActionTypes.SAVE_SHARE_ACCOUNT_SUCCESSFUL);
export const deletePartyShareAccount = (userId: string, idToDelete: string) =>
  action(PartyActionTypes.DELETE_SHARE_ACCOUNT, { userId, idToDelete });
export const deletePartyShareAccountSuccessful = () => action(PartyActionTypes.DELETE_SHARE_ACCOUNT_SUCCESSFUL);

export const toggleHouseHoldView = () => action(PartyActionTypes.TOGGLE_HOUSEHOLD_VIEW);
export const toggleProgramView = () => action(PartyActionTypes.TOGGLE_PROGRAM_VIEW);

export const saveDocumentDeliveryNotification = (didConsent: boolean) =>
  action(PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION, { didConsent });
export const saveDocumentDeliveryNotificationSuccessful = () =>
  action(PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION_SUCCESSFUL);
export const saveDocumentDeliveryNotificationFailed = () =>
  action(PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION_FAILED);

export const saveUpdateDeliveryPreferences = (metadata: IUpdateDeliveryPreferencesMetadata) =>
  action(PartyActionTypes.SAVE_UPDATE_DELIVERY_PREFERENCES_METADATA, metadata);
export const saveConsentPrompt = (metadata: IConsentPromptMetadata) =>
  action(PartyActionTypes.SAVE_CONSENT_PROMPT_METADATA, metadata);
export const saveHoldingSortPreferences = (metadata: ISortViewsMetadata) =>
  action(PartyActionTypes.SAVE_HOLDING_SORT_PREFERENCES_METADATA, metadata);

export const saveMarketDataRealtime = (realtime: boolean) =>
  action(PartyActionTypes.SAVE_MARKET_DATA_REALTIME_PREFERENCES_METADATA, { realtime });
export const saveIntradayChangePreference = (realtime: boolean) =>
  action(PartyActionTypes.SAVE_INTRADAY_CHANGE_PREFERENCES_METADATA, { realtime });
export const initAutoMarketDataUpdate = () => action(PartyActionTypes.INIT_AUTO_MARKET_DATA_UPDATE);

export const saveConsentParty = (consent: IConsentPreferences[]) =>
  action(PartyActionTypes.SAVE_CONSENT_PREFERENCES, consent);
export const saveConsentPartySuccessful = () => action(PartyActionTypes.SAVE_CONSENT_PREFERENCES_SUCCESSFUL);
export const saveConsentPartyFailed = () => action(PartyActionTypes.SAVE_CONSENT_PREFERENCES_FAILED);

export const loadConsentVerbiage = () => action(PartyActionTypes.LOAD_CONSENT_VERBIAGE);
export const loadConsentVerbiageSuccessful = (verbiages: IConsentVerbiages[]) =>
  action(PartyActionTypes.LOAD_CONSENT_VERBIAGE_SUCCESSFUL, { verbiages });
export const loadConsentVerbiageFailed = () => action(PartyActionTypes.LOAD_CONSENT_VERBIAGE_FAILED);

export const updateConsentParty = (consent: IConsentPreferences[]) =>
  action(PartyActionTypes.UPDATE_PARTY_CONSENTS, consent);
export const resetSaveConsent = () => action(PartyActionTypes.RESET_SAVE_CONSENT_PREFERENCES);
