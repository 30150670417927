import React from 'react';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { getClosedMarkets } from '~/stores/account/selectors/accountSelectors';
import InfoContainer from '../info-container/info-container';

export interface IPropsFromState {
  closedMarkets: string[];
}

const ClosedMarketBanner: React.FC<IPropsFromState> = ({ closedMarkets }) => {
  const showClosedMarketInfo =
    closedMarkets.length > 0 && (closedMarkets.includes('Canada') || closedMarkets.includes('USA'));

  let closedMarketTitle = '';
  let closedMarketText = '';

  if (showClosedMarketInfo) {
    closedMarketTitle = 'landing.marketsClosed';

    if (closedMarkets.includes('Canada') && closedMarkets.includes('USA')) {
      closedMarketText = 'landing.dualHoliday';
    } else if (closedMarkets.includes('Canada')) {
      closedMarketText = 'landing.canadianHoliday';
    } else if (closedMarkets.includes('USA')) {
      closedMarketText = 'landing.usaHoliday';
    }
    return <InfoContainer title={closedMarketTitle} text={closedMarketText} />;
  }

  return <></>;
};

function mapStateToProps(state: IAppRootState) {
  return {
    closedMarkets: getClosedMarkets(state),
  };
}

export default connect(mapStateToProps)(ClosedMarketBanner);
