import * as React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '@mui/material';
import { ConsentStyle as Style } from './styles';
import { ConsentViewMode } from './ConsentView';
import { LinkButton } from '~/pages/landing-page/ConsentPrompt/ConsentPrompt.style';

interface IFooterProps {
  canSave: boolean,
  canReset: boolean,
  isUserAdvisorOrAdmin: boolean,
  viewMode: ConsentViewMode,
  onApply: () => void,
  onResetChanges: () => void
  onCancel?: () => void
}

export default class Footer extends React.Component<IFooterProps> {
  public render() {
    return (<Style.HorizontalScrollDiv>
      <Button key="apply" size="large" style={Style.iaButtonStyle} disabled={!this.props.canSave || this.props.isUserAdvisorOrAdmin} onClick={this.props.onApply} variant="contained" color="secondary">
        <span style={{ fontSize: '14px' }}>
          <Trans i18nKey={this.props.viewMode === ConsentViewMode.Page ? 'consent.applyChangesButton' : 'common.submit'} />
        </span>
      </Button>
      {this.props.viewMode === ConsentViewMode.Page &&
        <Button key="reset" size="large" style={Style.iaButtonStyle} disabled={!this.props.canReset} onClick={this.props.onResetChanges} variant="contained" color="primary">
          <span style={{ fontSize: '14px' }}>
            <Trans i18nKey="consent.resetChangesButton" />
          </span>
        </Button>}
      {this.props.viewMode === ConsentViewMode.Popup &&
        <LinkButton style={{ marginLeft: '24px' }} key="cancel" onClick={this.props.onCancel}>
            <Trans i18nKey="common.cancel" />
        </LinkButton>}
    </Style.HorizontalScrollDiv>);
  }
}
