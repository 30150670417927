import { isFieldEmptyOrNull } from '~/common/helpers';
import * as React from 'react';

interface Iprops {
    value?: any,
    defaultValueIfUndefined?: string,
    componentWithValue?: any
}
export const ComponentWithValidation: React.FC<Iprops> = ({ value, defaultValueIfUndefined, componentWithValue }) => {
  defaultValueIfUndefined = isFieldEmptyOrNull(defaultValueIfUndefined) ? '-' : defaultValueIfUndefined;
  componentWithValue = isFieldEmptyOrNull(componentWithValue) ? value : componentWithValue;
  return (
    <>
      {isFieldEmptyOrNull(value) ? defaultValueIfUndefined : componentWithValue}
    </>
  );
};
