import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import MuiExpansionPanel from '@mui/material/Accordion';
import { IconButton, TextField } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 38px 0px 10px 0px;
  font-size: 24px;
  font-family: ${(props) => props.theme.fontBold};
  font-weight: ${(props) => props.theme.fontBoldTitleWeight};
`;

export const BoxShadow = styled.div<{ hover?: boolean }>`
  background: #fff;
  padding: 16px;
  box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.349019607843137);
  ${(props) =>
    props.hover &&
    `
    &:hover {
      background: rgba(228, 228, 228, 1);
    }
  `}
`;

export const MainBox = styled.div`
  background: #fff;
  box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.35);
`;

export const P = styled.div`
  font-family: 'OpenSans', 'Open Sans';
  font-style: normal;
  font-size: 16px;
`;
export const LoadStampContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  flex-grow: 1;
`;

export const DataLoadStampContainer = styled.div<{ color?: string; marginTop?: number }>`
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
`;

export const Label = styled.div<{ fontSize?: number }>`
  font-family: 'OpenSans-Regular', 'Open Sans';
  font-style: normal;
  font-size: ${({ fontSize }) => fontSize || '14'}px;
  font-weight: 400;
`;

export const LabelBold = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  font-weight: 650;
`;

export const LabelBoldSubtitle = styled.div`
  font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px !important;
`;

export const LabelBoldBigSubtitle = styled.div`
  font-family: 'Montserrat SemiBold', 'Montserrat Regular', 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  text-align: center;
`;

export const LabelBigSubtitle = styled.div`
  font-family: 'Montserrat Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  text-align: center;
`;

export const LabelMonetary = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
`;

export const H3 = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
`;

export const BoxHeader = styled.div`
  font-family: Montserrat;
  font-weight: 100;
  font-size: 20px;
  color: #000;
  line-height: 22px;
`;

export const HeaderPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const HeaderSmall = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 11px;
  font-weight: 800;
  color: #666666;
`;

export const IAButtonSelect = withStyles(Button, {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    margin: '4px 0px 4px 8px',
    color: '#999999',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    borderColor: '#99999',
    borderRadius: 5,
    fontFamily: ['OpenSans', 'Open Sans'].join(','),
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#003da5',
      color: '#003da5',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#fff',
      borderColor: '#003da5',
      color: '#003da5',
      boxShadow: 'none',
    },
  },
});

export const IAButtonOutline = withStyles(Button, {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    margin: '4px 0px 4px 8px',
    color: '#003da5',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    borderColor: '#003da5',
    borderRadius: 5,
    fontFamily: ['OpenSans', 'Open Sans'].join(','),
    '&:hover': {
      backgroundColor: '#003da5',
      borderColor: '#003da5',
      color: '#fff',
      boxShadow: 'none',
    },
  },
});

export const Legendbox = styled.div<{ color: string; size: string; font?: string }>`
  width: ${(props) => props.size};
  min-width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: inherit;
  background-color: ${(props) => props.color};
  border: none;
  font-size: ${(props) => (props.font ? props.font : '12px')};
  color: #ffffff;
  padding-top: 5px;
  text-align: center;
  font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
  width: 250px;
  text-align: left;
  line-height: 15px;
  align-self: flex-end;
  align-items: center;
  font-size: 12px;
`;

export const ExpansionPanel = withStyles(MuiExpansionPanel, (_theme, _params, classes) => ({
  root: {
    border: '1px 0px 0px 0px  solid rgba(0, 0, 0, .125)',
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export const InputIA = withStyles(TextField, {
  root: {
    border: '1px solid #99999',
    borderRadius: 4,
    backgroundColor: '#fff',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #003da5',
    },
    '&$focused': {
      border: '1px solid #003da5',
    },
  },
});

export const IconButtonWithoutPadding = withStyles(IconButton, {
  root: {
    padding: 0,
  },
});

export const IconButtonWithLeftPadding = withStyles(IconButton, {
  root: {
    padding: 0,
    paddingLeft: '5px',
  },
});

export const TabMenuContainer = styled.div`
  display: flex;

  & > a {
    color: #003da5;

    &:link,
    visited {
      text-decoration: none;
    }

    &:hover,
    active {
      text-decoration: none;
      background: #003da5;
      color: #fff;
    }
  }
`;

export const TabContainer = styled(NavLink)`
  padding: 15px 15px;
  margin-right: 10px;
  border-top: 5px solid #eff1f4;
  font-size: 14px;
  font-weigth: 400;
  font-family: OpenSans-Bold, Open Sans Bold, Open Sans, sans-serif !important;
  background: rgba(187, 194, 202, 1);
  display: flex;
  max-width: max-content;
  min-width: 140px;
  justify-content: center;
`;

export const ActiveNavStyle = {
  background: '#FFF',
  boxShadow: '2px 0 2px -2px rgba(204, 204, 204, 0.35)',
  borderTop: '5px solid #003ba9',
  textDecoration: 'none',
  color: '#000',
  fontWeigth: '500',
  fontFamily: 'OpenSans-Bold, Open Sans Bold, Open Sans, sans-serif',
  cursor: 'default',
  display: 'flex',
};

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-left: 4px;
`;

export const StyledLinkButton = styled.span`
  font-family: 'Open Sans', sans-serif;
  padding: 0px;
  color: #003da5;
  cursor: pointer;
  &:active {
    color: #003da5;
  }
`;

export const UpdateDataContainer = styled.div`
  font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 650;
  color: #2062d4;
  text-align: left;
  &:hover {
    text-decoration: underline !important;
  }
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;

export const KeyIndicatorTitle = styled.div<{ fontWeight?: number; color?: string; fontSize?: number }>`
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  color: ${(props) => (props.color ? props.color : '#2062D4')};
  font-size: ${({ fontSize }) => fontSize || '17'}px;
`;

export const KeyIndicatorBoldBigSubtitle = styled.span`
  font-family: 'Montserrat SemiBold', 'Montserrat Regular', 'Montserrat', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  line-height: 22px;
`;
export const KeyIndicatorLabel = styled.span<{ fontWeight?: number; fontSize?: number; color?: string }>`
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-style: normal;
  font-size: ${({ fontSize }) => fontSize || '14'}px;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color }) => color || '#000000'};
  text-align: left;
`;
