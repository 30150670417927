import * as React from 'react';

import { IPhone } from '../../interfaces/Phone';
import { BranchPhone, BranchPhones } from './styles/SearchResult.style';

interface ILocalProps {
    phones: IPhone[];
  }

const formatPhone = (phone: IPhone): JSX.Element => {
  const linkPhoneNumber = phone !== null ? phone.phoneNumber.replace(/\D/g, '') : '';
  const linkToPhone = `tel:${linkPhoneNumber}`;

  return (<BranchPhone key={linkPhoneNumber}><a href={linkToPhone}>{phone.phoneNumber}</a></BranchPhone>);
};

const BranchPhoneCard: React.FC<ILocalProps> = ({ phones }: ILocalProps) => {
  const phoneList = phones.map((phone) => formatPhone(phone));

  return (
    <BranchPhones>
      {phoneList}
    </BranchPhones>
  );
};

export default BranchPhoneCard;
