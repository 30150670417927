import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import { RouteComponentProps } from 'react-router';
import {
  getIsAccountsFetching,
  getAccountIds,
  getAssetsAllocation,
  getHouseholdTotalMarketValue,
  getNetInvested,
  getIsNetInvestedFetching,
  getIsNetInvestedFetchingFail,
  getIsMarketDataFetching,
  getIsMarketDataFetchingHasError,
  getClientAccountsFromState,
  getAllCurrentFilterAccounts,
  getCSVResult,
  getisFetchingCSVByDomain,
  getisFetchingCSVByDomainDone,
  getisFetchingCSVByDomainFail,
  getDeliveryPreferencesSummary,
} from '~/stores/account/selectors/accountSelectors';
import {
  IAssetsAllocation,
  IAccount,
  IClientAccount,
  CsvExportDomain,
  IDeliveryPreferencesSummary,
} from '~/stores/account/accountTypes';
import {
  IAssetAllocationMix,
  BuildAssetsMix,
  GetDonutData,
  HideAssetAllocationPieChart,
} from '~/common/assetAlloccationHelper';

import { Row, Col, Grid } from 'react-flexbox-grid';
import { Trans } from 'react-i18next';
import i18n from '~/app/i18n';
import ExchangeRateDisclaimer from '~/common/components/loadStamp/ExchangeRateDisclaimer';
import {
  getDidSetMarketDataRealtime,
  getPartyV1,
  getIsFetchingPartyByUser,
} from '~/stores/party/selectors/partySelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import {
  Container,
  MainBox,
  ContainerRow,
  ContainerLegend,
  Legendbox,
  ExpansionPanel,
  LabelMonetary,
} from '~/common/styles/baseStyle';

import { PieChart, Pie, Tooltip, AreaChart, Area, XAxis, YAxis } from 'recharts';
import {
  getTotalMarketValueInCurrency,
  getSelectedAccountIds,
  getAccountName,
  getAccountsFromRoot,
} from '~/common/accountsHelpers';
import { fetchMarketData, fetchCSVByDomain, setFilteringAccountIds } from '~/stores/account/actions/accountActions';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { MonetaryValue } from '~/common/components';
import { push } from 'redux-first-history';
import { RouteNames } from '~/app/appTypes';
import NoData from '~/common/components/noData/NoData';
import {
  getIdsOwner,
  getIdsJoint,
  getAccountCompanyIds,
  getRootIdsShared,
  getPartyFullName,
  getOwnedOrCorpAccountsIds,
} from '~/common/partyHelpers';

import Contact from '~/common/components/advisor/Contact';
import PrintButton from '~/common/components/print-button/PrintButton';
import InfoContainer from '~/common/components/info-container/info-container';
import AlertContainer from '~/common/components/info-container/alert-container';
import styled from 'styled-components';
import {
  Typography,
  AccordionDetails,
  AccordionSummary,
  Grid as GridMUI,
  Snackbar,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import FileSaver from 'file-saver';
import moment from 'moment';
import { autoHideDurationError, exportCSVType } from '~/common/constants';
import ClientOverview from './ClientOverview';
import DocumentDeliveryPrompt from './DocumentDeliveryPrompt/DocumentDeliveryPrompt';
import { getUserToken, isUserAdvisorOrAdmin } from '../../stores/system/selectors/SystemSelectors';
import {
  Link,
  MirrorViewContainer,
  ToggleContainer,
  BtToggle,
  BaselineRow,
  StyledChevronRight,
  MonetaryValueContainer,
} from './LandingPage.style';

import MirrorViewNote from './MirrorViewNote';
import {
  TOGGLE_HIDE_MFA_BANNER,
  TOGGLE_HIDE_BROWSER_BANNER,
  TOGGLE_CONTEST_BANNER,
  TOGGLE_HIDE_MARKETDATA_BANNER,
  TOGGLE_MARKETDATA_FEATURE_PAGE,
} from '~/common/API';
import ConsentPrompt from './ConsentPrompt/ConsentPrompt';
import { getCurrentIsoLang, isConsentFeatureActiveForClient, isKYCFeatureActiveForClient } from '~/common/helpers';
import { GetIsStorageValueByKey } from '~/app/sessionManager';
import KYCPrompt from './KYCPrompt/KYCPrompt';
import { getShowKYCPrompt } from '~/stores/kyc/kycSelectors';
import { Contest } from '~/stores/contest/contestTypes';
import { getContest } from '~/stores/contest/selectors/contestSelectors';
import ContestBanner, { shouldShowContestBanner } from './ContestBanner/ContestBanner';
import ClosedMarketBanner from '~/common/components/closed-market-banner/ClosedMarketBanner';
import {
  LandingAccountsSectionSkeleton,
  LandingHeaderSectionSkeleton,
  LandingMarketSectionSkeleton,
} from './LandingSkeleton';
import { ExportCSVButton } from '~/common/components/export/ExportCSV';

export const MARKET_SECTION_HEIGHT = '380px';

export interface IPropsFromState {
  isFetchingAccount: boolean;
  selectedAccountIds: string[];
  isAccountsFetching: boolean;
  assetsAllocation: IAssetsAllocation;
  clientAccounts: IClientAccount;
  accounts: IAccount[];
  party: IPartyV1;
  householdTotalMarketValue: number;
  performance: any;
  isPerformanceFetching: boolean;
  isPerformanceFetchingFail: boolean;
  culture: string;
  state: IAppRootState;
  isUserAdvisorOrAdmin: boolean;
  showContactCard: boolean;
  isAssetBreakdown: boolean;
  repCodes: string[];
  didSetMarketDataRealtime: boolean;
  isFetchingMarketData: boolean;
  isFetchingMarketDataHasError: boolean;
  isCSVFetching: boolean;
  isCSVFetchingDone: boolean;
  isCSVFetchingFail: boolean;
  csvResult: string;
  showDeliveryPreferencesSummary: IDeliveryPreferencesSummary;
  showKYCPrompt: boolean;
  contest: Contest;
}
export interface IPropsFromDispatch {
  navigateTo: typeof push;
  setFilteringAccountIds: typeof setFilteringAccountIds;
  fetchMarketData: typeof fetchMarketData;
  fetchCSVByDomain: typeof fetchCSVByDomain;
}

export interface IAccountSwitchView {
  isChartAssetAllocation: boolean;
  isCSVDownloading: boolean;
  csvFilename: string;
  openCSVError: boolean;
}

const GridContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  height: ${MARKET_SECTION_HEIGHT};
`;

export const PartyNameWithLoadingSkeleton = ({ party }: { party: IPartyV1 }) => {
  const isPartyLoaded = party && party.id;

  if (!isPartyLoaded) {
    return <Skeleton width="80%" height={70} style={{ marginTop: '30px' }} />;
  }

  return (
    <Typography variant="h1" component="span" style={{ paddingBottom: '20px', paddingTop: '40px' }}>
      {`${i18n.t('landing.welcome')} ${getPartyFullName(party)}`}
    </Typography>
  );
};

class LandingPage extends React.PureComponent<RouteComponentProps & any, IAccountSwitchView> {
  constructor(props: RouteComponentProps & IPropsFromState & IPropsFromDispatch) {
    super(props);
    document.title = i18n.t('landing.documentTitle');
    this.state = {
      isChartAssetAllocation: false,
      isCSVDownloading: false,
      csvFilename: `${i18n.t('landing.csvExportFilename')}_${moment().format('YYYYMMDD')}`,
      openCSVError: false,
    };

    this.handleChartToggleClick = this.handleChartToggleClick.bind(this);
  }

  private handleChartToggleClick(): any {
    this.setState({
      isChartAssetAllocation: !this.state.isChartAssetAllocation,
    });
  }

  private renderAccount = (account: IAccount) => {
    const { culture } = this.props;
    const name = account.alias === undefined || account.alias === '' ? account.accountTypeLong : account.alias;
    return (
      <BaselineRow key={account.id} between="xs">
        <Col xs={8}>
          <Link onClick={this.handleAccountClick.bind(this, account.id)}>
            {account.id} | {name}
            <StyledChevronRight color="primary" />
          </Link>
        </Col>
        <Col xs={4}>
          <MonetaryValueContainer>
            <MonetaryValue value={account.totalMarketValueCad} culture={culture} />
          </MonetaryValueContainer>
        </Col>
      </BaselineRow>
    );
  };

  private renderRoot = (rootId: string) => {
    const { culture, accounts } = this.props;
    const accountsRoot = getAccountsFromRoot(accounts, [rootId]);

    return (
      <ExpansionPanel key={rootId}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="#003da5" />}
          style={{ paddingLeft: 4, paddingRight: 20 }}
        >
          <Grid fluid style={{ width: '100%', paddingRight: 0 }}>
            <Row between="xs">
              <Col xs={6}>
                <Typography variant="h4" component="span">
                  {getAccountName(accountsRoot, [rootId])}
                </Typography>
              </Col>
              <Col xs={6}>
                <LabelMonetary>
                  <MonetaryValue
                    value={getTotalMarketValueInCurrency(accountsRoot, 'CAD')}
                    culture={culture}
                    currency="CAD"
                  />
                </LabelMonetary>
              </Col>
            </Row>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
            {accountsRoot.map((item) => this.renderAccount(item))}
          </Grid>
        </AccordionDetails>
      </ExpansionPanel>
    );
  };

  public renderGroup = (accountIds: string[], idName: string, name: string, isExpand: boolean) => {
    const { culture, accounts } = this.props;
    if (accountIds === null || accountIds.length === 0) {
      return null;
    }
    const accountsRoot = getAccountsFromRoot(accounts, accountIds);
    if (accountsRoot.length > 0) {
      return (
        <ExpansionPanel key={idName} defaultExpanded={isExpand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon htmlColor="#003da5" />}
            style={{ paddingLeft: 4, paddingRight: 20 }}
          >
            <Grid fluid style={{ width: '100%', paddingRight: 0 }}>
              <Row between="xs">
                <Col xs={6}>
                  <Typography variant="h4" component="span">
                    {name}
                  </Typography>
                </Col>
                <Col xs={6}>
                  <LabelMonetary>
                    <MonetaryValue
                      value={getTotalMarketValueInCurrency(accountsRoot, 'CAD')}
                      culture={culture}
                      currency="CAD"
                    />
                  </LabelMonetary>
                </Col>
              </Row>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
              {accountsRoot.map((item) => this.renderAccount(item))}
            </Grid>
          </AccordionDetails>
        </ExpansionPanel>
      );
    }
    return null;
  };

  public handleAccountClick = (acountId: string, element: any) => {
    this.props.setFilteringAccountIds([acountId]);
    this.props.navigateTo(`${RouteNames.holdings}`);
  };

  private AxisXLabel = (value: number) => {
    const { performance } = this.props;

    const itemIndex = (performance.data as any[]).findIndex((w) => w.intervalRatio === value);

    if (itemIndex === 0 || itemIndex === performance.data.length - 1) {
      return '';
    }

    return performance.data[itemIndex].label;
  };

  private renderAccounts = (selectedAccounts: IAccount[]) => {
    const { party, accounts } = this.props;
    const shareIds = getRootIdsShared(party);
    const ownerIds = getIdsOwner(party);
    const jointIds = getIdsJoint(party);
    const companyIds = getAccountCompanyIds(party);
    const accountsIndividualsRoot = getAccountsFromRoot(accounts, ownerIds);
    let rootAccount = null;
    if (shareIds.length > 0) {
      rootAccount = getAccountsFromRoot(selectedAccounts, shareIds)
        .map((item) => item.rootId)
        .filter((value, index, self) => self.indexOf(value) === index);
    }

    return (
      <>
        {this.renderGroup(ownerIds, 'ownerAccount', getAccountName(accountsIndividualsRoot, ownerIds), true)}
        {this.renderGroup(jointIds, 'jointAccount', i18n.t('common.joint'), false)}
        {companyIds.map((c) => this.renderGroup([c], c, getAccountName(accounts, [c]), false))}
        {rootAccount !== null ? rootAccount.map((m) => (m != null ? this.renderRoot(m) : '')) : ''}
      </>
    );
  };

  public CustomLabel = (viewBox: any) => {
    const { cx, cy, percentage, midAngle } = viewBox;
    const RADIAN = Math.PI / 180;
    const x = cx + 130 * Math.cos(-midAngle * RADIAN);
    const y = cy + 130 * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 14 }}>
        {' '}
        {percentage}%
      </text>
    );
  };

  public CustomTooltip = (viewBox: any) => {
    const { active, payload } = viewBox;
    if (active && payload && payload.length > 0 && payload[0].payload.date) {
      return (
        <div className="boxTooltip">
          {payload[0].payload.date.substring(0, payload[0].payload.date.indexOf('T'))} <br />
          {payload[0].name} <MonetaryValue culture="fr-CA" value={payload[0].value} /> <br />
          {payload[1].name} <MonetaryValue culture="fr-CA" value={payload[1].value} />
        </div>
      );
    }

    return null;
  };

  private handleFetchCSVClick = () => {
    this.setState({ isCSVDownloading: true });
    this.forceUpdate();
    if (!this.state.isCSVDownloading) {
      if (!this.props.isCSVFetching) {
        this.props.fetchCSVByDomain(CsvExportDomain.OVERVIEW, this.state.csvFilename);
      }
    }
  };

  private handleCSVErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openCSVError: false });
  };

  public componentDidMount() {
    const jsCode = `
                window.setTimeout(function(){  if(window.EEWidgets !== undefined){window.EEWidgets.loadAdsSlideshows() } }, 1800);`;
    new Function(jsCode)();
  }

  public renderHeaders = (props: IPropsFromState) => {
    const { isFetchingAccount, party, clientAccounts, showDeliveryPreferencesSummary, contest } = props;

    if (isFetchingAccount || !party?.id) {
      return <LandingHeaderSectionSkeleton party={party} />;
    }

    const ownedRoots = getOwnedOrCorpAccountsIds(party);
    const enabledDocumentDeliveryPrompt = !!(ownedRoots && ownedRoots.length > 0 && !this.props.isUserAdvisorOrAdmin);
    const isMirrorViewMode = this.props.isUserAdvisorOrAdmin;
    const eDelSplanAlreadyShown = GetIsStorageValueByKey('eDelSplash');
    /*
      We should show Prompts in priority order - one at each login
      1. KYC
      2. EDelivery
      3. Consent
    */
    const shouldShowKYCPrompt = !isMirrorViewMode && isKYCFeatureActiveForClient(party) && this.props.showKYCPrompt;
    const shouldShowEDeliveryPrompt =
      enabledDocumentDeliveryPrompt &&
      showDeliveryPreferencesSummary?.IsDueToReview &&
      !eDelSplanAlreadyShown &&
      !shouldShowKYCPrompt;
    const shouldShowConsentPrompt =
      isConsentFeatureActiveForClient(party) && !shouldShowEDeliveryPrompt && !isMirrorViewMode;
    const shouldShowMarketDataFailureBanner = this.props.didSetMarketDataRealtime && !clientAccounts.isLiveData;

    return (
      <>
        {shouldShowEDeliveryPrompt && <DocumentDeliveryPrompt />}
        {shouldShowConsentPrompt && <ConsentPrompt />}
        {shouldShowKYCPrompt && <KYCPrompt />}
        {this.props.isUserAdvisorOrAdmin && (
          <MirrorViewContainer>
            {this.props.isUserAdvisorOrAdmin && (
              <MainBox>
                {' '}
                <MirrorViewNote />{' '}
              </MainBox>
            )}
          </MirrorViewContainer>
        )}

        <PartyNameWithLoadingSkeleton party={party} />
        {TOGGLE_CONTEST_BANNER && shouldShowContestBanner(contest) && <ContestBanner campaign={contest} />}
        <ClosedMarketBanner />

        {!TOGGLE_HIDE_MFA_BANNER && (
          <a
            target="_blank"
            href={
              getCurrentIsoLang() === 'fr'
                ? 'https://iagestionprivee.ca/authentification-multifactorielle'
                : 'https://iaprivatewealth.ca/multi-factor-authentication'
            }
            rel="noreferrer"
          >
            <InfoContainer title="landing.multiFactorAuth" />
          </a>
        )}
        {!TOGGLE_HIDE_BROWSER_BANNER && <InfoContainer title="landing.browserMessage" />}
        {TOGGLE_MARKETDATA_FEATURE_PAGE && !TOGGLE_HIDE_MARKETDATA_BANNER && (
          <InfoContainer title="marketsPage.bannerMessage" />
        )}
        {shouldShowMarketDataFailureBanner && (
          <AlertContainer
            text="common.alertMarketDataMessage"
            buttonText="landing.updateData"
            onButtonClick={this.props.fetchMarketData}
            isButtonLoading={this.props.isFetchingMarketData}
          />
        )}
      </>
    );
  };

  public renderMarketSection = (props: IPropsFromState) => {
    const {
      isFetchingAccount,
      party,
      assetsAllocation,
      clientAccounts,
      isPerformanceFetchingFail,
      isPerformanceFetching,
      selectedAccountIds,
      isAssetBreakdown,
    } = props;

    if (isFetchingAccount || !party?.id) {
      return <LandingMarketSectionSkeleton />;
    }

    const householdAccountIds = getSelectedAccountIds(selectedAccountIds, clientAccounts.accounts);
    let assetAllocData: IAssetAllocationMix[] = [];

    if (assetsAllocation && assetsAllocation.breakDown && assetsAllocation.regular) {
      const currentAssetAllocation = isAssetBreakdown ? assetsAllocation.breakDown : assetsAllocation.regular;
      assetAllocData = BuildAssetsMix([], currentAssetAllocation, householdAccountIds);
    }

    const donutData = GetDonutData(assetAllocData);

    let perfsData = [];
    if (this.props.performance) {
      perfsData = this.props.performance.data;
    }

    const toAxisY = (decimal: number) => `${decimal / 1000} k`;

    return (
      <MainBox>
        <Row>
          <Col xs={4} style={{ paddingRight: '0px' }}>
            <ClientOverview />
          </Col>
          <Col xs={8} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            {isPerformanceFetching ? (
              <Stack
                direction="column"
                width="100%"
                height={MARKET_SECTION_HEIGHT}
                alignItems="center"
                alignContent="center"
                sx={{ bgcolor: 'white' }}
                my={0}
                p={3}
              >
                <Skeleton animation="wave" variant="rounded" width="100%" height="100%" />
              </Stack>
            ) : (
              <GridContainer>
                <ToggleContainer>
                  <BtToggle onClick={this.handleChartToggleClick} isActif={!this.state.isChartAssetAllocation}>
                    <Trans i18nKey="landing.netInvestment" />
                  </BtToggle>
                  <BtToggle onClick={this.handleChartToggleClick} isActif={this.state.isChartAssetAllocation}>
                    <Trans i18nKey="landing.assetAllocation" />
                  </BtToggle>
                </ToggleContainer>
                <Grid fluid style={{ width: '100%', height: '100%', padding: 0 }}>
                  {this.state.isChartAssetAllocation ? (
                    HideAssetAllocationPieChart(assetAllocData) ? (
                      <Row middle="xs" center="xs" style={{ paddingBottom: 75, height: '100%' }}>
                        <Col xs={12}>
                          {' '}
                          <NoData text={i18n.t('assetAllocation.noChart')} />
                        </Col>
                      </Row>
                    ) : (
                      <Row middle="xs" center="xs">
                        <Col xs={6}>
                          <PieChart width={350} height={300}>
                            <Pie
                              data={donutData}
                              dataKey="value"
                              cx={175}
                              cy={150}
                              innerRadius={70}
                              outerRadius={108}
                              label={this.CustomLabel}
                              isAnimationActive={false}
                            />
                          </PieChart>
                        </Col>

                        <Col xs={6} style={{ paddingRight: 14 }}>
                          <Container>
                            <ContainerLegend>
                              <Legendbox color="#003DA5" size="28px">
                                A
                              </Legendbox>
                              <span>&nbsp;</span> <Trans i18nKey="assetAllocation.cashAndEquivalents" />
                            </ContainerLegend>
                            <ContainerLegend>
                              <Legendbox color="#acafae" size="28px">
                                B
                              </Legendbox>
                              <span>&nbsp;</span> <Trans i18nKey="assetAllocation.fixedIncomeSecurities" />
                            </ContainerLegend>
                            <ContainerLegend>
                              <Legendbox color="#836c4b" size="28px">
                                C
                              </Legendbox>
                              <span>&nbsp;</span> <Trans i18nKey="assetAllocation.equities" />{' '}
                            </ContainerLegend>
                            <ContainerLegend>
                              <Legendbox color="#000548" size="28px">
                                D
                              </Legendbox>
                              <span>&nbsp;</span> <Trans i18nKey="assetAllocation.otherAssets" />
                            </ContainerLegend>
                          </Container>
                        </Col>
                      </Row>
                    )
                  ) : (
                    <Row middle="xs" center="xs">
                      <Col xs={12}>
                        {perfsData.length > 0 ? (
                          <AreaChart width={620} height={277} data={perfsData}>
                            <XAxis
                              dataKey="intervalRatio"
                              type="number"
                              domain={['dataMin', 'dataMax']}
                              scale="linear"
                              tickFormatter={this.AxisXLabel}
                              tickLine={false}
                              minTickGap={30}
                              tick={{ fontSize: 12 }}
                            />
                            <YAxis tickFormatter={toAxisY} tick={{ fontSize: 12 }} />
                            <Tooltip content={this.CustomTooltip} />
                            <Area
                              type="monotone"
                              dataKey="totalValue"
                              stroke="#003da5"
                              fill="#003da5"
                              name={i18n.t('landing.marketValue') as string}
                              fillOpacity="1"
                            />
                            <Area
                              type="monotone"
                              dataKey="netInvestment"
                              stroke="#acafae"
                              fill="#acafae"
                              name={i18n.t('landing.netInvestment') as string}
                            />
                          </AreaChart>
                        ) : isPerformanceFetchingFail ? (
                          <NoData height="277px" text={i18n.t('common.dataNotAvailable')} />
                        ) : (
                          <NoData text={i18n.t('common.noData')} />
                        )}
                        <ContainerRow style={{ justifyContent: 'center' }}>
                          <ContainerLegend style={{ width: 160 }}>
                            <Legendbox color="#acafae" size="14px" />
                            &nbsp; <Trans i18nKey="landing.netInvestment" />
                          </ContainerLegend>
                          <ContainerLegend style={{ width: 160 }}>
                            <Legendbox color="#003da5" size="14px" />
                            &nbsp; <Trans i18nKey="landing.marketValue" />
                          </ContainerLegend>
                        </ContainerRow>
                      </Col>
                    </Row>
                  )}
                </Grid>
              </GridContainer>
            )}
          </Col>
        </Row>
      </MainBox>
    );
  };

  public renderAccountsSection = (props: IPropsFromState) => {
    const { isFetchingAccount, party, accounts } = props;

    if (isFetchingAccount || !party?.id) {
      return <LandingAccountsSectionSkeleton />;
    }

    return (
      <>
        <MainBox style={{ minHeight: MARKET_SECTION_HEIGHT, marginTop: '32px' }}>
          <GridMUI container minHeight="60px" alignItems="center">
            <GridMUI item xs={10}>
              <Typography variant="h2" component="div" style={{ padding: '14px 14px 14px 22px' }}>
                <Trans i18nKey="landing.allAccounts" />
              </Typography>
            </GridMUI>
            <GridMUI item xs={2} pr={2} display="flex" justifyContent="flex-end">
              <ExportCSVButton isProcessing={this.state.isCSVDownloading} onClick={this.handleFetchCSVClick} />
            </GridMUI>
          </GridMUI>
          {this.renderAccounts(accounts)}
        </MainBox>
        <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: '<ee-ads></ee-ads>' }} />

        <ExchangeRateDisclaimer />
      </>
    );
  };

  public render() {
    const { showContactCard, isCSVFetchingDone, isCSVFetchingFail, csvResult } = this.props;

    const action = (
      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCSVErrorClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );

    if (isCSVFetchingDone && this.state.isCSVDownloading) {
      if (isCSVFetchingFail) {
        this.setState({ openCSVError: true });
      } else {
        const blob = new Blob([csvResult], { type: exportCSVType });
        const fileName = `${this.state.csvFilename}.csv`;
        FileSaver.saveAs(blob, fileName);
      }
      this.setState({ isCSVDownloading: false });
    }

    return (
      <Container>
        {this.renderHeaders(this.props as IPropsFromState)}
        {this.renderMarketSection(this.props as IPropsFromState)}
        {this.renderAccountsSection(this.props as IPropsFromState)}
        {showContactCard && <Contact repCodes={this.props.repCodes} />}
        <PrintButton />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openCSVError}
          autoHideDuration={autoHideDurationError}
          onClose={this.handleCSVErrorClose}
          message={i18n.t('common.csvFileDownloadError') as string}
          action={action}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isFetchingAccount: getIsAccountsFetching(state),
    isFetchingPartyUser: getIsFetchingPartyByUser(state),
    party: getPartyV1(state),
    selectedAccountIds: getAccountIds(state),
    assetsAllocation: getAssetsAllocation(state),
    clientAccounts: getClientAccountsFromState(state),
    accounts: getAllCurrentFilterAccounts(state),
    householdTotalMarketValue: getHouseholdTotalMarketValue(state),
    performance: getNetInvested(state),
    isPerformanceFetching: getIsNetInvestedFetching(state),
    isPerformanceFetchingFail: getIsNetInvestedFetchingFail(state),
    culture: system.culture,
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    showContactCard: system.showContactCard,
    isAssetBreakdown: system.isAssetBreakdown,
    repCodes: getUserToken(state)?.repCodes || [],
    isFetchingMarketData: getIsMarketDataFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    isCSVFetching: getisFetchingCSVByDomain(state),
    isCSVFetchingDone: getisFetchingCSVByDomainDone(state),
    isCSVFetchingFail: getisFetchingCSVByDomainFail(state),
    csvResult: getCSVResult(state),
    showDeliveryPreferencesSummary: getDeliveryPreferencesSummary(state),
    showKYCPrompt: getShowKYCPrompt(state),
    contest: getContest(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateTo: push,
      setFilteringAccountIds,
      fetchMarketData,
      fetchCSVByDomain,
    },
    dispatch,
  );

export default connect<any>(mapStateToProps as any, mapDispatchToProps)(LandingPage as any);
