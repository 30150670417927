import styled from 'styled-components';

export const SharingViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
`;

export const ConsentsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-arround;
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const NameContainer = styled.div`
    min-width: 200px;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const SharingDescription = styled.div`
    padding-top:20px;
    padding-bottom:20px;
`;
