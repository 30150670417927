export interface ITransaction {
    id?: string,
    tradeDate: string,
    processDate: string,
    settlementDate: string,
    accountId: string,
    operation: string,
    symbol: string,
    description: string,
    nbUnits: number,
    unitPrice: number,
    currencyCode: string,
    net: number,
    netAmountCurrency: string,
    dripValue?: number,
    isMutualFund: boolean,
    productType: number,
    operationType: string,
    operationDescription: string,
    operationTypeDescription: string,
    withholdingTaxFederal: number,
    withholdingTaxProvincial: number,
    grossAmount: number
}

export interface ITransactionFilter {
    symbolDescription: string,
    startDate: string,
    endDate: string,
    currencyIds: string[]
    productTypeIds: string[]
    operationTypeIds: string[]
}

export interface ITransactionAccountFilter {
    accountIds: string[],
    excludeAccountIds: string[]
}

export interface ITransactionDictionnary {
    [id: string]: ITransaction
}

export interface ITransactionState {
    readonly filteredTransactionIds: string[],
    readonly transactions: ITransactionDictionnary,
    readonly filter: ITransactionFilter,
    readonly isFetching: boolean,
    readonly isFail: boolean,
    readonly dataExpiration?: Date
    readonly excludeCloseAcount?: boolean
}

export const enum TransactionActionTypes {
    FETCH_TRANSACTION = '[Transaction] Fetch',
    FETCH_TRANSACTION_SUCCESSFUL = '[Transaction] Fetch Successful',
    FETCH_TRANSACTION_FAILED = '[Transaction] Fetch Failed',
    FETCH_TRANSACTION_ABORTED = '[Transaction] Fetch Aborted',

    SET_SELECTED_TRANSACTION_IDS = '[Transaction] Set Selected Transaction Ids',
    UPDATE_ACCOUNTS = '[Transaction] Update accounts',

    SET_TRANSACTION_SYMBOL_DESCRIPTION_FILTER = '[Transaction] Set Transaction Symbol or Description Filter',
    SET_TRANSACTION_CURRENCY_FILTER = '[Transaction] Set Transaction Currency Filter',
    SET_TRANSACTION_PRODUCT_TYPE_FILTER = '[Transaction] Set Product type Filter',
    SET_TRANSACTION_OPERATION_TYPE_FILTER = '[Transaction] Set Operation Product type Filter',

    SET_TRANSACTION_START_DATE = '[Transaction] Set Transaction Start Date',
    SET_TRANSACTION_END_DATE = '[Transaction] Set Transaction End Date',
    SET_TRANSACTION_SORT = '[Transaction] Set Transaction Sort',

    SET_EXCLUDE_CLOSE_ACCOUNT = '[Transaction] Set Exclude Close Account',
}
