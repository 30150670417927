import * as React from 'react';
import { IAccount } from '~/stores/account/accountTypes';
import ExchangeRateDisclaimer from './ExchangeRateDisclaimer';
import { accountHasNonDeterminable, accountHasPositionWithAccruedInterest } from '~/common/accountsHelpers';
import { Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export interface IHoldingFooterProps {
  accounts: IAccount[]
}

const HoldingFooter: React.FC<IHoldingFooterProps> = ({ accounts }: IHoldingFooterProps) => {
  const nonDeterminableNoteNeeded = accountHasNonDeterminable(accounts);
  const accruedInterestNoteNeeded = accountHasPositionWithAccruedInterest(accounts);

  return (
    <>
      {nonDeterminableNoteNeeded && (
        <Grid container>
          <Typography style={{ marginTop: '20px' }}>¹ <Trans i18nKey="holdings.nonDeterminable" /></Typography>
        </Grid>
      )}
      {accruedInterestNoteNeeded && (
        <Grid container>
          <Typography style={{ marginTop: '20px' }}><Trans i18nKey="holdings.accruedInterestDisclaimer" /></Typography>
        </Grid>
      )}
      <ExchangeRateDisclaimer />
    </>
  );
};

export default HoldingFooter;
