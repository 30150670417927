import * as React from 'react';
import { Trans } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import styled from 'styled-components';
import i18n from '~/app/i18n';
import { Checkbox, TableCell, Typography } from '@mui/material';
import { IColumns } from './data-table';
import Colors from '~/common/themes/colors';
import { v4 } from 'uuid';
import { DocumentType } from '~/pages/householding-page/documentdelivery-view/deliveryPreferencesLoadMapper';
import InfoContainer from '../info-container/info-container';

export interface IColumnsComplex extends IColumns {
  type?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checkedAll?: boolean,
  width?: string,
  additionalNote?: string,
  additionalNoteColor?: string,
  disableCol?: boolean,
}

export interface IEDeliveryRowData {
  key: string,
  clientId: string,
  name: string,
  isPaperless: boolean,
  isEmailNotification?: boolean,
  canModify: boolean,
  isTaxSeason?: boolean
}

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:first': {
      borderTop: 'none;',
    },
    borderTop: '2px solid #DADAD8;',
    '&.readonly-row': {
      backgroundColor: Colors.desertStorm,
    },
  },
}));

export const SectionHeaderLabel = styled.div`
  color: #ffffff;
  background-color: #003ea5;
  height: 50px;
  padding: 15px;
`;

export const StyledHeaderCell = withStyles(TableCell, {
  root: {
    fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open sans', 'san-serif'].join(','),
    fontSize: '14px',
    color: '#1B1B1B',
    fontWeight: 700,
    backgroundColor: '#e6e6e6',
    padding: '4px 12px 3px 12px',
  },
});

export const StyledTableCell = withStyles(TableCell, {
  root: {
    fontFamily: `${['Open sans', 'san-serif'].join(',')}!important`,
    fontSize: '14px!important',
    color: '#1B1B1B!important',
    padding: '0 12px',
    fontWeight: 'unset!important' as any,
    '& a': {
      fontWeight: 'unset!important' as any,
      fontFamily: 'unset!important' as any,
      fontSize: 'unset!important' as any,
    },
  },
});

export const DataTableEDelivery = ({ data, documentType, isAdminAdvisor, onItemChanged }: {
  data: IEDeliveryRowData[],
  documentType: string,
  isAdminAdvisor: boolean,
  onItemChanged: (diffs: IEDeliveryRowData[], documentType: string) => void
}) => {
  const isDisabledTaxSlips = () => documentType === DocumentType.TaxDocument && (data && data.length > 0 && data[0].isTaxSeason);
  const disableAllColumns = isAdminAdvisor || (documentType === DocumentType.TaxDocument && isDisabledTaxSlips());
  let foundPaperIndex = data.length === 0 ? -1 : data.findIndex((e) => !e.isPaperless && e.canModify);
  const isShowingMailColumn = (data && data.length > 0) && (data[0].isEmailNotification !== undefined);
  const [selectedAllPaperless, setSelectedAllPaperless] = React.useState(foundPaperIndex === -1);
  let foundMailIndex = data.length === 0 ? -1 : data.findIndex((e) => !e.isEmailNotification && e.canModify);
  const [selectedAllMail, setSelectedAllMail] = React.useState(foundMailIndex === -1);
  const [disableAllMailColumn, setDisableAllMailColumn] = React.useState(disableAllColumns || !(foundPaperIndex === -1));
  const [updatedData, setUpdatedData] = React.useState(data);
  const [ableToModify] = React.useState(data.some((e) => e.canModify));

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isTrue = event.target.checked;
    const newList = updatedData.map((item) => {
      if (item.canModify) {
        let updatedItem = { ...item, isPaperless: isTrue };
        if (isShowingMailColumn) {
          updatedItem = { ...updatedItem, isEmailNotification: isTrue };
        }
        return updatedItem;
      }
      return item;
    });
    setUpdatedData(newList);
    setSelectedAllPaperless(event.target.checked);
    if (isTrue && isShowingMailColumn) {
      setSelectedAllMail(isTrue);
    }

    if (isShowingMailColumn) {
      // recheck for mail
      setSelectedAllMail(isTrue);
      setDisableAllMailColumn(disableAllColumns || !isTrue);
    }

    checkDataTableDifferencesAndNotify(newList);
  };

  const handleOnChangePaperless = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isTrue = event.target.checked;
    const cId = event.target.name.replace('paperless-checkbox-', '');
    const newList = updatedData.map((item) => {
      if (item.clientId === cId) {
        let updatedItem = { ...item, isPaperless: isTrue };
        if (isShowingMailColumn) {
          updatedItem = { ...updatedItem, isEmailNotification: isTrue };
        }
        return updatedItem;
      }
      return item;
    });
    setUpdatedData(newList);

    // recheck for paper
    foundPaperIndex = newList.findIndex((e) => !e.isPaperless && e.canModify);
    setSelectedAllPaperless(foundPaperIndex === -1);

    if (isShowingMailColumn) {
      // recheck for mail
      foundMailIndex = newList.findIndex((e) => !e.isEmailNotification && e.canModify);
      setSelectedAllMail(foundMailIndex === -1);
      setDisableAllMailColumn(disableAllColumns || !(foundMailIndex === -1));
    }

    checkDataTableDifferencesAndNotify(newList);
  };

  const handleSelectAllEmailNotificationClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newList = updatedData.map((item) => {
      if (item.canModify) {
        return {
          ...item,
          isEmailNotification: event.target.checked,
        };
      }
      return item;
    });
    setUpdatedData(newList);
    setSelectedAllMail(event.target.checked);

    checkDataTableDifferencesAndNotify(newList);
  };

  const handleOnChangeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cId = event.target.name.replace('mail-checkbox-', '');
    const newList = updatedData.map((item) => {
      if (item.clientId === cId) {
        return {
          ...item,
          isEmailNotification: event.target.checked,
        };
      }
      return item;
    });
    setUpdatedData(newList);

    // recheck for mail
    foundMailIndex = newList.findIndex((e) => !e.isEmailNotification && e.canModify);
    setSelectedAllMail(foundMailIndex === -1);

    checkDataTableDifferencesAndNotify(newList);
  };

  const checkDataTableDifferencesAndNotify = (newUpdatedList: IEDeliveryRowData[]) => {
    const diffs: IEDeliveryRowData[] = [];
    data.forEach((e, i) => {
      if (e.isPaperless !== newUpdatedList[i].isPaperless || e.isEmailNotification !== newUpdatedList[i].isEmailNotification) {
        diffs.push(newUpdatedList[i]);
      }
    });
    onItemChanged(diffs, documentType);
  };

  const columns: IColumnsComplex[] = [
    {
      header: 'documentDelivery.clientId',
      align: 'left',
      width: '130px',
    },
    {
      header: 'documentDelivery.name',
      align: 'left',
      width: '250px',
    },
    {
      header: 'documentDelivery.isPaperless',
      align: 'right',
      type: true,
      onChange: handleSelectAllClick,
      checkedAll: selectedAllPaperless,
      additionalNote: '*',
      additionalNoteColor: Colors.redAlert,
      disableCol: disableAllColumns,
    },
  ];

  if (data && data.length > 0) {
    if (data[0].isEmailNotification !== undefined) {
      columns.push({
        header: 'documentDelivery.emailNotificationConsent',
        align: 'right',
        type: true,
        onChange: handleSelectAllEmailNotificationClick,
        checkedAll: selectedAllMail,
        disableCol: disableAllMailColumn,
      });
    }
  }

  const mainKeyValue = v4();
  return (
    <div key={`edelivery-div-${mainKeyValue}`}>
      {documentType &&
        <SectionHeaderLabel key={`edelivery-section-title-${mainKeyValue}`}>
          <Typography variant="body2" component="p" style={{ color: Colors.white }} key={`edelivery-typo-${mainKeyValue}`}>
            <Trans i18nKey={`documentDelivery.${documentType}`} key={`edelivery-title-${mainKeyValue}`} />
          </Typography>
        </SectionHeaderLabel>}
      <Table aria-label="data-table table" style={{ marginBottom: '15px' }} key={`edelivery-table-${mainKeyValue}`}>
        <TableHead key={`edelivery-head-${mainKeyValue}`}>
          <TableRow key={`edelivery-row-${mainKeyValue}`} style={{ height: '50px' }}>
            {columns && columns.map((col: IColumnsComplex, cellIndex) => {
                const headerName = i18n.t(col.header) as string;
                const keyValue = v4();
                if (col.type) {
                  return (<StyledHeaderCell key={`col-${keyValue}`} align={columns[cellIndex].align as any}>
                              {headerName}<span style={{ color: col.additionalNoteColor }}>{col.additionalNote}</span>
                              {ableToModify && <Checkbox
                                key={`checkboxall-${keyValue}`}
                                color="primary"
                                checked={col.checkedAll}
                                onChange={col.onChange}
                                disabled={col.disableCol}
                              />}
                          </StyledHeaderCell>);
                }
                return (<StyledHeaderCell key={`col-${keyValue}`} align={columns[cellIndex].align as any} style={{ width: col.width }}>{headerName}</StyledHeaderCell>);
              })}
          </TableRow>
        </TableHead>
        <TableBody key={`edelivery-body-${mainKeyValue}`}>
          {updatedData && updatedData.map((row, colIndex) => (
            <StyledTableRow key={`edelivery-tablerow-${v4()}`} className={`${!row.canModify ? 'readonly-row' : ''}`}>
              <StyledTableCell key={`cell-${row.clientId}-${row.key}`} align="left">{row.clientId}</StyledTableCell>
              <StyledTableCell key={`cell-${row.name}-${row.key}`} align="left">{row.name}</StyledTableCell>
              <StyledTableCell key={`cell-paper-${row.key}`} align="right">
                <Checkbox
                  color="primary"
                  checked={row.isPaperless}
                  onChange={handleOnChangePaperless}
                  name={`paperless-checkbox-${row.clientId}`}
                  key={`checkboxpaper-${row.key}-${colIndex}`}
                  disabled={!row.canModify}
                />
              </StyledTableCell>
              {(row.isEmailNotification === true || row.isEmailNotification === false) &&
                  <StyledTableCell key={`cell-mail-${row.key}`} align="right">
                      <Checkbox
                        color="primary"
                        checked={row.isEmailNotification}
                        onChange={handleOnChangeMail}
                        name={`mail-checkbox-${row.clientId}`}
                        key={`checkboxmail-${row.key}-${colIndex}`}
                        disabled={!row.canModify || !row.isPaperless}
                      />
                  </StyledTableCell>}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {isDisabledTaxSlips() && documentType === DocumentType.TaxDocument &&
          <InfoContainer title="documentDelivery.disabledTaxSlipsMsg" />}
    </div>
  );
};

export default DataTableEDelivery;
