import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';
import SummarySplitGrid from '~/common/components/account/account-view/SummarySplitGrid';
import Colors from '~/common/themes/colors';

const SkeletonView = () => (
  <div>
    {/* Account view selector section */}
    <Stack direction="row" margin={2} marginTop={4} gap={1}>
      <Grid item>
        <Skeleton variant="rounded" width="175px" height={40} />
      </Grid>
      <Grid item>
        <Skeleton variant="rounded" width="175px" height={40} />
      </Grid>
      <Grid item>
        <Skeleton variant="rounded" width="175px" height={40} />
      </Grid>
    </Stack>

    {/* Summary section */}
    <Grid container style={{ padding: '0 16px' }}>
      <SummarySplitGrid
        backgroundLeftColor={Colors.babyBlue}
        labelKeyLeft="common.totalMarketValue"
        commentKeyLeft="common.valuesAreInCAD"
        rowsOnRight={1}
        colsOnRight={3}
        dataOnRightSide={[{}, {}, {}]}
        isFetching
      />
    </Grid>

    {/* Account section */}
    <Grid container style={{ margin: '16px 0 8px 16px' }}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="20%" height={23} />
      </Grid>
      <Grid item xs={12} mt={0.5}>
        <Skeleton variant="rectangular" width="35%" height={22} />
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '0px', padding: '0 16px' }}>
      <SummarySplitGrid
        labelKeyLeft="common.totalMarketValue"
        rowsOnRight={2}
        colsOnRight={3}
        dataOnRightSide={[]}
        isFetching
      />
    </Grid>

    <Grid container item xs={12} style={{ padding: '0 16px' }} mb={2}>
      <Grid item xs={12} mb={1}>
        <Skeleton variant="rectangular" width="100%" height={30} />
      </Grid>
      {[...Array(3)].map((_, index) => (
        <React.Fragment key={`item-${index}`}>
          <Grid item xs={3} my={0.5}>
            <Skeleton variant="rectangular" width="25%" height={20} sx={{ mb: 0.2 }} />
            <Skeleton variant="rectangular" width="85%" height={20} />
          </Grid>
          <Grid item xs={3} my={0.5}>
            <Skeleton variant="rectangular" width="20%" height={20} />
          </Grid>
          <Grid item xs={3} my={0.5}>
            <Skeleton variant="rectangular" width="25%" height={20} sx={{ mb: 0.2 }} />
            <Skeleton variant="rectangular" width="85%" height={20} />
          </Grid>
          <Grid item xs={3} my={0.5}>
            <Skeleton variant="rectangular" width="80%" height={20} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  </div>
);

export default SkeletonView;
