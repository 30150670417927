import { ContestHttpClient } from '~/stores/contest/services/contestHttpClient';
import { refreshSession } from '~/stores/system/actions/systemActions';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { TOGGLE_CONTEST_BANNER } from '~/common/API';
import { AxiosError } from 'axios';
import { ContestActionTypes, Contest } from '../contestTypes';
import {
  incrementViewedContest as incrementViewedContestAction,
  incrementViewedContestFailed,
  incrementViewedContestSuccessful,
  loadContestFailed,
  loadContestSuccessful,
  postponeContestFailed,
  postponeContestSuccessful,
  subscribeClientToContest,
  subscribeClientToContestFailed,
  subscribeClientToContestSuccessful,
  unsubscribeContestFailed,
  unsubscribeContestSuccessful,
} from '../actions/contestActions';

export function* fetchContest() {
  if (TOGGLE_CONTEST_BANNER) {
    try {
      yield put(refreshSession());

      const campaign: Contest = yield call(ContestHttpClient.fetchContest);

      if (campaign.isActive) {
        let subscription = {
          isSubscribed: false,
          isPostponed: false,
        };
        try {
          // Loading subscription status
          subscription = yield call(ContestHttpClient.fetchContestSubscription);
          campaign.isSubscribed = subscription.isSubscribed;
          campaign.isPostponed = subscription.isPostponed;
        } catch (err: AxiosError | any) {
          if (err.response?.status === 404) {
            // Subscription not found, try to subscribe
            yield put(subscribeClientToContest());
            const [_, error]: [any | void, any | void] = yield race([
              take(ContestActionTypes.SUBSCRIBE_CONTEST_SUCCESSFUL),
              take(ContestActionTypes.SUBSCRIBE_CONTEST_FAILED),
            ]);
            if (error === undefined) {
              campaign.isSubscribed = true;
              campaign.isPostponed = false;
            }
          } else {
            yield put(subscribeClientToContestFailed());
          }
        }
        yield put(loadContestSuccessful(campaign));

        if (campaign.isSubscribed && !campaign.isPostponed) {
          yield put(incrementViewedContestAction());
        }
      }
    } catch (err) {
      yield put(loadContestFailed());
    }
  }
}

export function* subscribeContest() {
  if (TOGGLE_CONTEST_BANNER) {
    try {
      yield put(refreshSession());
      yield call(ContestHttpClient.subscribeClientToContest);
      yield put(subscribeClientToContestSuccessful());
    } catch (err) {
      yield put(subscribeClientToContestFailed());
    }
  }
}

export function* incrementViewedContest() {
  if (TOGGLE_CONTEST_BANNER) {
    try {
      yield put(refreshSession());
      yield call(ContestHttpClient.incrementClientViewContest);
      yield put(incrementViewedContestSuccessful());
    } catch (err) {
      yield put(incrementViewedContestFailed());
    }
  }
}

export function* postponeContest() {
  if (TOGGLE_CONTEST_BANNER) {
    try {
      yield put(refreshSession());
      yield call(ContestHttpClient.postponeContest);
      yield put(postponeContestSuccessful());
    } catch (err) {
      yield put(postponeContestFailed());
    }
  }
}

export function* unsubscribeContest() {
  if (TOGGLE_CONTEST_BANNER) {
    try {
      yield put(refreshSession());
      yield call(ContestHttpClient.unsubscribeContest);
      yield put(unsubscribeContestSuccessful());
    } catch (err) {
      yield put(unsubscribeContestFailed());
    }
  }
}
function* contestSaga() {
  yield takeLatest(ContestActionTypes.LOAD_CONTEST, fetchContest);
  yield takeLatest(ContestActionTypes.SUBSCRIBE_CONTEST, subscribeContest);
  yield takeLatest(ContestActionTypes.INCREMENT_VIEWED_CONTEST, incrementViewedContest);
  yield takeLatest(ContestActionTypes.POSTPONE_CONTEST, postponeContest);
  yield takeLatest(ContestActionTypes.UNSUBSCRIBE_CONTEST, unsubscribeContest);
}

export default contestSaga;
