import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import { Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { legacy_createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import App from '~/app/App';
import { createRootReducer } from '~/app/rootReducer';
import rootSaga from '~/app/rootSaga';

import 'src/app/i18n';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware();
const store = legacy_createStore(
  createRootReducer(routerReducer),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware,
      sagaMiddleware,
    ),
  ),
);
sagaMiddleware.run(rootSaga);

const history = createReduxHistory(store);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);
