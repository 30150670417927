import { IDeliveryPreferencesToSave, IDocumentPreferencesToSave } from '~/stores/account/accountTypes';
import { IDeliveryPreferencesTableData, IDeliveryPreferencesTableRow } from './deliveryPreferencesLoadMapper';
import { IDeliveryPreferencesChangedTableData } from './DocumentDeliveryView';

export function IsThereSomethingToMoveToPaperless(dataSource: IDeliveryPreferencesTableData[] | undefined): boolean {
  if (dataSource === undefined || dataSource.length === 0) return false;
  if (!(dataSource instanceof Array)) return false;
  let foundPaper = false;
  dataSource.forEach((e) => {
    const rowsToUpdate = e.rows.filter((r) => ((!r.isPaperless || (r.isEmailNotification !== undefined && !r.isEmailNotification)) && r.canModify));
    if (rowsToUpdate.length > 0) {
      foundPaper = true;
    }
  });
  return foundPaper;
}

export function MapToDeliveryPreferencesTableRowForAllPaperless(dataSource: IDeliveryPreferencesTableData[]): IDeliveryPreferencesChangedTableData[] {
  const changesToBeApply: IDeliveryPreferencesChangedTableData[] = [];
  dataSource.forEach((e) => {
    const rowsToUpdate = e.rows.filter((r) => ((!r.isPaperless || (r.isEmailNotification !== undefined && !r.isEmailNotification)) && r.canModify));
    if (rowsToUpdate.length > 0) {
      const updated: IDeliveryPreferencesTableRow[] = [];
      rowsToUpdate.forEach((r) => {
        updated.push(r.isEmailNotification !== undefined ? { ...r, isPaperless: true, isEmailNotification: true } : { ...r, isPaperless: true });
      });
      changesToBeApply.push({ documentType: e.documentType, rows: updated });
    } else {
      changesToBeApply.push({ documentType: e.documentType, rows: [] });
    }
  });
  return changesToBeApply;
}

export function MapToDeliveryPreferencesUpdatedToSource(dataSource: IDeliveryPreferencesTableData[], changesToBeApply: IDeliveryPreferencesChangedTableData[]): IDeliveryPreferencesTableData[] {
  const newData: IDeliveryPreferencesTableData[] = [];
  dataSource?.forEach((e) => {
    const index = changesToBeApply.findIndex((c) => c.documentType === e.documentType);
    if (changesToBeApply[index].rows.length === 0) {
      newData.push(e);
    } else {
      const updatedRows: IDeliveryPreferencesTableRow[] = [];
      e.rows.forEach((r) => {
        const modifiedIndex = changesToBeApply[index].rows.findIndex((i) => i.clientId === r.clientId);
        if (modifiedIndex === -1) {
          updatedRows.push(r);
        } else {
          updatedRows.push({ key: r.clientId + e.documentType, clientId: r.clientId, name: r.name, isPaperless: changesToBeApply[index].rows[modifiedIndex].isPaperless, isEmailNotification: changesToBeApply[index].rows[modifiedIndex].isEmailNotification, isUserAdvisor: r.isUserAdvisor, canModify: r.canModify });
        }
      });
      newData.push({ documentType: e.documentType, rows: updatedRows });
    }
  });
  return newData;
}

export function MapToDeliveryPreferences(changedDataSource: IDeliveryPreferencesChangedTableData[]): IDeliveryPreferencesToSave[] {
  const itemsToSaved: IDeliveryPreferencesToSave[] = [];
  changedDataSource.forEach((e) => {
    e.rows.forEach((r) => {
      const index = itemsToSaved.findIndex((i) => i.clientId === r.clientId);
      const rowValue: IDocumentPreferencesToSave = { type: e.documentType as unknown as DocumentType, isPaperless: r.isPaperless, isEmailNotification: r.isEmailNotification === true || r.isEmailNotification === false ? r.isEmailNotification : undefined };
      if (index > -1) {
        itemsToSaved[index].documentPreferences.push(rowValue);
      } else {
        const addClientChanges = { clientId: r.clientId, documentPreferences: [] as IDocumentPreferencesToSave[] };
        addClientChanges.documentPreferences.push(rowValue);
        itemsToSaved.push(addClientChanges);
      }
    });
  });

  return itemsToSaved;
}
