import * as React from 'react';
import { IFundData } from '~/stores/positionDetail/types';
import Colors from '~/common/themes/colors';
import { isCurrentIsoLangEN } from '~/common/helpers';
import NumberQI from '~/common/components/changeValue/NumberQI';
import moment from 'moment';
import i18n from '~/app/i18n';
import { ComponentWithValidation } from '../summary-table/ComponentWithValidation';
import { PositionSummaryTable } from '../summary-table/PositionSummaryTable';

interface IProps {
  fundData: IFundData;
}

export const FundSummary: React.FC<IProps> = ({ fundData }: IProps) => (
  <PositionSummaryTable
    rows={4}
    cols={3}
    positionSummaryContainerXS={8}
    data={[
      {
        labelKey: 'stockOverview.fund.netAsset',
        toolTipKey: 'stockOverview.fund.netAssetsTooltip',
        toolTipColor: Colors.cobalt,
        positionValue: (
          <>
            <ComponentWithValidation
              value={fundData.netAssets}
              componentWithValue={<NumberQI isFormatLargeNumber value={fundData.netAssets} isMoney />}
            />{' '}
          </>
        ),
      },
      {
        labelKey: 'stockOverview.fund.minimumInvestment',
        positionValue: (
          <>
            <ComponentWithValidation
              value={fundData.profile.minimumInvestment}
              componentWithValue={<NumberQI isFormatLargeNumber value={fundData.profile.minimumInvestment} isMoney />}
            />{' '}
          </>
        ),
      },
      {
        labelKey: 'stockOverview.fund.category',
        positionValue: (
          <>
            <ComponentWithValidation value={fundData.profile.category} />{' '}
          </>
        ),
      },

      {
        labelKey: 'stockOverview.fund.mer',
        positionValue: (
          <ComponentWithValidation
            value={fundData.mer}
            componentWithValue={<NumberQI value={fundData.mer} isPercent />}
          />
        ),
      },
      {
        labelKey: 'stockOverview.fund.managementFees',
        positionValue: (
          <ComponentWithValidation
            value={fundData.managementFeePercent}
            componentWithValue={<NumberQI value={fundData.managementFeePercent} isPercent />}
          />
        ),
      },
      {
        labelKey: 'stockOverview.fund.fundGrade',
        toolTipKey: 'stockOverview.fund.fundGradeTooltip',
        toolTipColor: Colors.cobalt,
        positionValue: (
          <>
            <ComponentWithValidation value={fundData.profile.fundGrade} />{' '}
          </>
        ),
      },

      {
        labelKey: 'stockOverview.fund.inceptionDate',
        positionValue: (
          <>
            <ComponentWithValidation
              value={fundData.inceptionDate}
              componentWithValue={moment(fundData.inceptionDate).locale(`${i18n.language})}`).format('ll')}
            />{' '}
          </>
        ),
      },
      {
        labelKey: 'stockOverview.fund.ytdReturn',
        positionValue: (
          <ComponentWithValidation
            value={fundData.performance.ytdReturn}
            componentWithValue={<NumberQI value={fundData.performance.ytdReturn} isPercent />}
          />
        ),
      },
      {
        labelKey: 'stockOverview.fund.1YearReturn',
        positionValue: (
          <>
            <ComponentWithValidation
              value={fundData.performance.oneYearReturn}
              componentWithValue={<NumberQI value={fundData.performance.oneYearReturn} isPercent />}
            />{' '}
          </>
        ),
      },

      {
        labelKey: 'stockOverview.fund.annualized5YearReturn',
        positionValue: (
          <>
            <ComponentWithValidation
              value={fundData.performance.fiveYearsReturn}
              componentWithValue={<NumberQI value={fundData.performance.fiveYearsReturn} isPercent />}
            />{' '}
          </>
        ),
      },
      {
        labelKey: 'stockOverview.fund.loadType',
        positionValue: (
          <>
            {isCurrentIsoLangEN() ? (
              <ComponentWithValidation value={fundData.loadTypeEn} />
            ) : (
              <ComponentWithValidation value={fundData.loadTypeFr} />
            )}{' '}
          </>
        ),
      },
    ]}
  />
);
