import React from 'react';
import PropTypes from 'prop-types';

import { StyledCircle, StyledWrapper } from './Loader.styles';

const LoaderPropTypes = {
  /** The loader size */
  size: PropTypes.number,
  /** Whether the color should be reversed */
  reversed: PropTypes.bool,
};

const LoaderDefaultProps = {
  size: 40,
};

const Loader = (props) => {
  const { size, reversed } = props;
  return (
    <StyledWrapper size={size} data-testid="Loader" {...props}>
      <StyledCircle reversed={reversed} />
      <StyledCircle reversed={reversed} rotate={30} delay={-1.1} />
      <StyledCircle reversed={reversed} rotate={60} delay={-1} />
      <StyledCircle reversed={reversed} rotate={90} delay={-0.9} />
      <StyledCircle reversed={reversed} rotate={120} delay={-0.8} />
      <StyledCircle reversed={reversed} rotate={150} delay={-0.7} />
      <StyledCircle reversed={reversed} rotate={180} delay={-0.6} />
      <StyledCircle reversed={reversed} rotate={210} delay={-0.5} />
      <StyledCircle reversed={reversed} rotate={240} delay={-0.4} />
      <StyledCircle reversed={reversed} rotate={270} delay={-0.3} />
      <StyledCircle reversed={reversed} rotate={300} delay={-0.2} />
      <StyledCircle reversed={reversed} rotate={330} delay={-0.1} />
    </StyledWrapper>
  );
};

Loader.propTypes = LoaderPropTypes;
Loader.defaultProps = LoaderDefaultProps;

export default Loader;
