import styled from 'styled-components';
import { SortDirection } from '~/app/appTypes';
import { ISortPreference } from '~/common/types';
import { IAccount, IPosition, IPositionAfterIntraday } from '~/stores/account/accountTypes';
import { saveHoldingSortPreferences } from '~/stores/party/actions/partyActions';
import { SortColumnKeys } from '~/stores/party/partyTypes';

export const AccountPositionsContainers = styled.div`
  display: flex;
  flex-grow: 1;
`;

export interface IAccountDisplayProps {
    account: IAccount;
    isSymbolLinkEnabled: boolean;
    shouldNotShowMarketDataInfo: boolean;
    onChangedTableSort: (sortColumn: SortColumnKeys, sortDirection: SortDirection) => void;
    sortPreference?: ISortPreference;
  }

  export interface IAccountPropsFromState {
    culture: string;
    isFetchingMarketData: boolean;
    didSetMarketDataRealtime: boolean;
    isFetchingMarketDataHasError: boolean;
    isUserAdvisorOrAdmin: boolean;
  }

  export interface IAccountSortingDisplayState {
    sortPreference?: ISortPreference;
    dataSource: IPosition[] | IPositionAfterIntraday[];
  }

  export interface IAccountsPropsFromDispatch {
    saveHoldingSortPreferences: typeof saveHoldingSortPreferences;
  }

  export type align = 'right' | 'left';
