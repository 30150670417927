import * as React from 'react';
import { saveAs } from 'file-saver';
import { IAppRootState } from '~/app/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getIsAccountsFetching,
  getAccountIds,
  getAllAccountsFiltered,
  getAccountDocuments,
  getIsAccountDocumentsFetching,
  getIsAccountDocumentsFetchingFail,
  getIsTradeConfirmationDocumentsFetching,
  getIsTradeConfirmationDocumentsFetchingFail,
  getAllCurrentFilterAccounts,
  getIsAccountDocumentsLoaded,
  getIsTradeConfirmationsLoaded,
  hasOnlySharedAccounts,
  getIsMergingDownloadPdfs,
  getIsMergingDownloadPdfsFail,
  getMergePdfsResults,
  getIsMergingDownloadPdfsDone,
  getAllAvailableAccounts,
} from '~/stores/account/selectors/accountSelectors';
import { getSendMessageSuccess } from '~/stores/message/selectors/messageSelectors';
import { IAccount, IDocument, IDocumentMergeDownloadComplete } from '~/stores/account/accountTypes';
import { Trans } from 'react-i18next';
import { Table, Input } from 'antd';
import i18n from '~/app/i18n';
import DateFormat from '~/common/components/date-format/DateFormat';
import moment from 'moment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import 'antd/lib/pagination/style/index.css';
import { fetchSharedDocuments } from '~/stores/message/actions/messageActions';
import NoData from '~/common/components/noData/NoData';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { Container, PageTitleContainer, HeaderPageContainer, FilterContainer } from '~/common/styles/baseStyle';
import PartyAccountFilter from '~/common/components/account/PartyAccountFilter';
import { DocumentTypeEnum, DocumentFromTypeEnum } from '~/common/types';
import { refreshSession } from '~/stores/system/actions/systemActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import MultiSelect, { IOption } from '~/pages/history-page/MultiSelect';
import {
  fetchAccountDocuments,
  downloadDocument,
  mergeDownloadPdfs,
  fetchTradeConfirmationDocuments,
  setDocumentReadStatusFlag,
  setDocumentDateFiltering,
  setFilteringAccountIds,
  clearDocuments,
  fetchTaxDocuments,
} from '~/stores/account/actions/accountActions';
import PeriodFilter from './PeriodFilter';
import axios from 'axios';
import {
  API_ACCOUNTS_V2,
  REACT_APP_USE_MESSAGING,
  TOGGLE_DOCUMENTS_BANNER,
  TOGGLE_DOCUMENTS_TRADECONF_ERROR,
  TOGGLE_TAX_DOCUMENTS_BANNER,
} from '~/common/API';
import { Link } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { LinkButtonWithIcon, StyledAlert } from './DocumentPage.style';
import SendMessageAlertResult from '~/pages/messages-page/SendMessageAlertResult';
import { ColumnProps } from 'antd/lib/table/Column';
import { getFileExtension, PDFExntension } from '~/common/utils/DocumentHelper';
import PrintButton from '~/common/components/print-button/PrintButton';
import {
  AlertTitle,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '~/common/styles/styleIAS.css';
import { appTheme } from '~/common/themes/theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { isFieldEmptyOrNull, isTaxFilterEnabled } from '~/common/helpers';
import Loading from '../../common/components/Loading/Loading';
import Loader from '../../common/components/loader/Loader/Loader';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import InfoContainer from '~/common/components/info-container/info-container';
import Error from '@mui/icons-material/Error';
const { Search } = Input;

export interface IPropsFromState {
  accountIds: string[];
  party: IPartyV1;
  accountDocuments: IDocument[];
  accounts: IAccount[];
  isAccountDocumentsFetching: boolean;
  isAccountDocumentsFetchingFail: boolean;
  isTradeConfirmationDocumentsFetching: boolean;
  isTradeConfirmationDocumentsFetchingFail: boolean;
  isAccountDocumentsLoaded: boolean;
  isTradeConfirmationsLoaded: boolean;
  isAccountFetching: boolean;
  getAllAccountsFiltered: string[];
  culture: string;
  sendMessageSuccess: boolean;
  hasAccessToDocumentExchange: boolean;
  isMergingDownloadPdfs: boolean;
  isMergingDownloadPdfsDone: boolean;
  isMergingDownloadPdfsFail: boolean;
  mergePdfsResults: IDocumentMergeDownloadComplete;
  isUserAdvisorOrAdmin: boolean;
  allAccountIds: IAccount[];
}

export interface IPropsFromDispatch {
  fetchAccountDocuments: typeof fetchAccountDocuments;
  fetchTradeConfirmationDocuments: typeof fetchTradeConfirmationDocuments;
  fetchSharedDocuments: typeof fetchSharedDocuments;
  downloadDocument: typeof downloadDocument;
  mergeDownloadPdfs: typeof mergeDownloadPdfs;
  refreshSession: typeof refreshSession;
  setDocumentReadStatusFlag: typeof setDocumentReadStatusFlag;
  setDocumentDateFiltering: typeof setDocumentDateFiltering;
  setFilteringAccountIds: typeof setFilteringAccountIds;
  clearDocuments: typeof clearDocuments;
  fetchTaxDocuments: typeof fetchTaxDocuments;
}

interface IDocState {
  selectedRowKeys: string[];
  selectedRowButtonText: string;
  textFilter: string;
  typeFilter: string[];
  fromFilter: string[];
  isDownloading: boolean;
  isMergeDownloading: boolean;
  documentsStartDate: string;
  documentsEndDate: string;
  columns: Array<ColumnProps<any>>;
  selectedIndexButton: number;
  openButton: boolean;
  anchorRef: any;
  openMergeError: boolean;
  openMergeSuccess: boolean;
  isAnyPDFSelected: boolean;
  taxFilterActive: boolean;
}

const defaultSelection = {
  typeFilter: ['all'],
  fromFilter: ['all'],
  taxFilterActive: false,
};

class DocumentPage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IDocState> {
  constructor(props: any) {
    super(props);
    this.props.refreshSession();
    document.title = i18n.t('document.documentTitle');
    this.state = {
      selectedRowKeys: [],
      selectedRowButtonText: i18n.t('document.documentDownloadMain'),
      textFilter: '',
      documentsStartDate: moment().add(-180, 'd').format('YYYY-MM-DD'),
      documentsEndDate: moment().format('YYYY-MM-DD'),
      isDownloading: false,
      isMergeDownloading: false,
      selectedIndexButton: 0,
      openButton: false,
      anchorRef: { current: null },
      openMergeError: false,
      openMergeSuccess: false,
      isAnyPDFSelected: false,
      ...defaultSelection,
      columns: [
        {
          title: i18n.t('document.date') as string,
          dataIndex: 'effectiveDate',
          key: 'effectiveDate',
          render: (value: any, row: any) =>
            row.read ? (
              <DateFormat value={value} />
            ) : (
              <Typography variant="body2" component="span">
                <DateFormat value={value} />
              </Typography>
            ),
          width: '135px',
          sorter: (a: any, b: any) => this.dateSorting2(a, b),
        },
        {
          title: i18n.t('document.datePublished') as string,
          dataIndex: 'publicationDate',
          key: 'publicationDate',
          render: (value: any, row: any) =>
            row.read ? (
              <DateFormat value={value} />
            ) : (
              <Typography variant="body2" component="span">
                <DateFormat value={value} />
              </Typography>
            ),
          width: '135px',
          sorter: (a: any, b: any) => this.dateSorting(a, b),
        },
        {
          title: i18n.t('document.account') as string,
          dataIndex: 'clientId',
          key: 'clientId',
          render: (value: any, row: any) =>
            row.read ? (
              value
            ) : (
              <Typography variant="body2" component="span">
                {value}
              </Typography>
            ),
          width: '95px',
          sorter: (a: any, b: any, sortOrder: any) => {
            if (a.type === DocumentTypeEnum.SHARING || b.type === DocumentTypeEnum.SHARING) {
              if (a.type === DocumentTypeEnum.SHARING && b.type === DocumentTypeEnum.SHARING) {
                return 0;
              }

              if (a.type === DocumentTypeEnum.SHARING) {
                return sortOrder === 'ascend' ? 1 : -1;
              }

              return sortOrder === 'ascend' ? -1 : 1;
            }

            if (a.clientId != null && b.clientId != null) {
              return a.clientId.localeCompare(b.clientId);
            }
            if (a.clientId) {
              return sortOrder === 'ascend' ? 1 : -1;
            }
            if (b.clientId) {
              return sortOrder === 'ascend' ? -1 : 1;
            }
            return 0;
          },
        },
        {
          title: i18n.t('document.type') as string,
          dataIndex: 'typeDocument',
          key: 'typeDocument',
          render: (value: any, row: any) =>
            row.read ? (
              i18n.t(`typeDocument.${DocumentTypeEnum[value]}`)
            ) : (
              <Typography variant="body2" component="span">
                {i18n.t(`typeDocument.${DocumentTypeEnum[value]}`) as string}
              </Typography>
            ),
          width: '160px',
          sorter: (a: any, b: any, sortOrder: any) => {
            if (a.typeDocument != null && b.typeDocument != null) {
              return DocumentTypeEnum[a.typeDocument].localeCompare(DocumentTypeEnum[b.typeDocument]);
            }
            if (a.typeDocument) {
              return sortOrder === 'ascend' ? 1 : -1;
            }
            if (b.typeDocument) {
              return sortOrder === 'ascend' ? -1 : 1;
            }
            return 0;
          },
        },
        {
          title: i18n.t('document.file') as string,
          key: 'nameFile',
          dataIndex: 'name',
          render: this.renderLink,
          sorter: (a: any, b: any, sortOrder: any) => {
            if (a.name != null && b.name != null) {
              return a.name.localeCompare(b.name);
            }
            if (a.name) {
              return sortOrder === 'ascend' ? 1 : -1;
            }
            if (b.name) {
              return sortOrder === 'ascend' ? -1 : 1;
            }
            return 0;
          },
          className: 'wrap',
        },
      ],
    };
  }

  public componentDidMount() {
    if (REACT_APP_USE_MESSAGING) {
      this.addFromNameColumns();
    }
  }

  private addFromNameColumns = () => {
    const columns = [...this.state.columns];
    columns.push({
      title: i18n.t('document.fromName') as string,
      key: 'fromType',
      dataIndex: 'fromType',
      width: '140px',
      render: (value: any, row: any) => {
        if (value) {
          if (value === DocumentFromTypeEnum.FROM_ADVISOR) {
            return row.read ? (
              row.fromName
            ) : (
              <Typography variant="body2" component="span">
                {row.fromName}
              </Typography>
            );
          }

          return row.read ? (
            i18n.t(`fromTypeDocument.${DocumentFromTypeEnum[value]}`)
          ) : (
            <Typography variant="body2" component="span">
              {i18n.t(`fromTypeDocument.${DocumentFromTypeEnum[value]}`) as string}
            </Typography>
          );
        }
        return row.read ? (
          i18n.t(`fromTypeDocument.${DocumentFromTypeEnum[0]}`)
        ) : (
          <Typography variant="body2" component="span">
            {i18n.t(`fromTypeDocument.${DocumentFromTypeEnum[0]}`) as string}
          </Typography>
        );
      },
      className: 'wrap',
    });
    this.setState({ columns });
  };

  private getFromOptions = (): IOption[] => {
    const { accountDocuments } = this.props;

    const options: IOption[] = [];
    const docs = accountDocuments;

    if (docs.find((doc) => !doc.fromType || doc.fromType === DocumentFromTypeEnum.IAPRIVATEWEALTH)) {
      options.push({
        id: DocumentFromTypeEnum.IAPRIVATEWEALTH.toString(),
        text: <Trans i18nKey="fromTypeDocument.IAPRIVATEWEALTH" />,
        disabled: this.state.taxFilterActive,
      });
    }

    if (docs.find((doc) => doc.fromType === DocumentFromTypeEnum.FROM_YOU)) {
      options.push({
        id: DocumentFromTypeEnum.FROM_YOU.toString(),
        text: <Trans i18nKey="fromTypeDocument.FROM_YOU" />,
        disabled: this.state.taxFilterActive,
      });
    }

    if (docs.find((doc) => doc.fromType === DocumentFromTypeEnum.FROM_LBS)) {
      options.push({
        id: DocumentFromTypeEnum.FROM_LBS.toString(),
        text: <Trans i18nKey="fromTypeDocument.FROM_LBS" />,
        disabled: this.state.taxFilterActive,
      });
    }

    return [
      {
        id: 'all',
        text: <Trans i18nKey="common.filter_Choice_All" />,
        childOptions: options,
        disabled: this.state.taxFilterActive,
      },
    ];
  };

  /* eslint-disable function-paren-newline */
  private filterDocuments = (): IDocument[] => {
    const { accountDocuments } = this.props;
    let docs = accountDocuments;

    const startDate = moment(this.state.documentsStartDate);
    const endDate = moment(this.state.documentsEndDate).hour(23).minute(59).second(59).millisecond(999);
    if (!this.state.taxFilterActive) {
      docs = docs.filter(
        (w) =>
          moment(w.effectiveDate === undefined ? moment().add(-1, 'd') : w.effectiveDate).isBetween(
            startDate,
            endDate,
            undefined,
            '[]',
          ) ||
          moment(w.publicationDate === undefined ? moment().add(-1, 'd') : w.publicationDate).isBetween(
            startDate,
            endDate,
            undefined,
            '[]',
          ),
      );
    }
    if (this.state.typeFilter.length > 0 && this.state.typeFilter.indexOf('all') === -1) {
      docs = docs.filter((w) => this.state.typeFilter.indexOf(w.typeDocument.toString()) > -1);
    }

    if (this.state.textFilter.length > 0) {
      docs = docs.filter((w) => w.name.toUpperCase().indexOf(this.state.textFilter.toUpperCase()) >= 0);
    }

    if (this.props.getAllAccountsFiltered.length > 0) {
      const rootIds = this.props.getAllAccountsFiltered
        .map((m) => m.substring(0, 6))
        .filter((v, i, a) => a.indexOf(v) === i);
      docs = docs.filter(
        (w) =>
          w.clientId.length === 6
            ? rootIds.indexOf(w.clientId) > -1
            : this.props.getAllAccountsFiltered.indexOf(w.clientId) > -1 || w.clientId === '-',
        // eslint-disable-next-line function-paren-newline
      );
    } else {
      const rootIds = this.props.allAccountIds.map((m) => m.id.substring(0, 6)).filter((v, i, a) => a.indexOf(v) === i);
      docs = docs.filter(
        (w) =>
          w.clientId.length === 6
            ? rootIds.indexOf(w.clientId) > -1
            : this.props.allAccountIds.filter((a) => a.id === w.clientId).length > 0 || w.clientId === '-',
        // eslint-disable-next-line function-paren-newline
      );
    }

    let docsByMostRecent = docs;
    if (REACT_APP_USE_MESSAGING) {
      if (this.state.fromFilter.length > 0 && this.state.fromFilter.indexOf('all') === -1) {
        docs = docs.filter(
          (w) =>
            (this.state.fromFilter.indexOf(DocumentFromTypeEnum.IAPRIVATEWEALTH.toString()) >= 0 &&
              (!w.fromType || w.fromType === DocumentFromTypeEnum.IAPRIVATEWEALTH)) ||
            (this.state.fromFilter.indexOf(DocumentFromTypeEnum.FROM_LBS.toString()) >= 0 &&
              (!w.fromType || w.fromType === DocumentFromTypeEnum.FROM_LBS)) ||
            (this.state.fromFilter.indexOf(DocumentFromTypeEnum.FROM_YOU.toString()) >= 0 &&
              w.fromType === DocumentFromTypeEnum.FROM_YOU) ||
            (this.state.fromFilter.indexOf(w.fromName) >= 0 && w.fromType === DocumentFromTypeEnum.FROM_ADVISOR),
        );
      }

      docsByMostRecent = docs.sort(
        (firstDocument, secondDocument) =>
          moment.utc(secondDocument.effectiveDate).diff(moment.utc(firstDocument.effectiveDate)),
        // eslint-disable-next-line function-paren-newline
      );
    }

    const filteredKeys: string[] = [];
    this.state.selectedRowKeys.forEach(async (element) => {
      if (docs.filter((d) => d.id === element).length > 0) {
        filteredKeys.push(element);
      }
    });
    if (this.state.selectedRowKeys.length !== filteredKeys.length) {
      this.setState({
        selectedRowKeys: filteredKeys,
        selectedRowButtonText: this.renderButtonDownloadSelectionText(filteredKeys),
      });
    }

    return REACT_APP_USE_MESSAGING ? docsByMostRecent : docs;
  };
  /* eslint-enable function-paren-newline */

  private handleOnDocumentTypeSelected = (selectedOptionIds: string[]) => {
    this.setState({ typeFilter: selectedOptionIds });
  };

  private handleOnFromSelected = (selectedOptionIds: string[]) => {
    this.setState({ fromFilter: selectedOptionIds });
  };

  private handleOnPeriodSelected = (start: string, end: string, taxFilterSelected: boolean) => {
    if (this.state.taxFilterActive === taxFilterSelected && taxFilterSelected) {
      // avoid refreshing again for tax documents - it was already called.
      return;
    }
    if (this.state.taxFilterActive !== taxFilterSelected) {
      this.props.clearDocuments();
    }
    if (taxFilterSelected) {
      this.taxDocumentFilter();
    }
    if (this.state.taxFilterActive && !taxFilterSelected) {
      // Clear tax filter
      this.setState({ documentsStartDate: start, documentsEndDate: end, ...defaultSelection });
    } else {
      this.setState({ documentsStartDate: start, documentsEndDate: end, taxFilterActive: taxFilterSelected });
    }
    this.props.setDocumentDateFiltering(start, end);
  };

  private handleOnChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ textFilter: event.target.value });
  };

  private getTypeDocumentFilter = (): IOption[] => {
    const result = [
      {
        id: 'all',
        text: <Trans i18nKey="common.filter_Choice_All" />,
        disabled: this.state?.taxFilterActive,
        childOptions: [
          {
            id: DocumentTypeEnum.STATEMENT.toString(),
            text: <Trans i18nKey="typeDocument.STATEMENT" />,
            disabled: this.state?.taxFilterActive,
          },
          {
            id: DocumentTypeEnum.TAXSlIP.toString(),
            text: <Trans i18nKey="typeDocument.TAXSlIP" />,
            disabled: this.state?.taxFilterActive,
          },
          {
            id: DocumentTypeEnum.TRADECONFIRM.toString(),
            text: <Trans i18nKey="typeDocument.TRADECONFIRM" />,
            disabled: this.state?.taxFilterActive,
          },
          {
            id: DocumentTypeEnum.YEARENDREPORT.toString(),
            text: <Trans i18nKey="typeDocument.YEARENDREPORT" />,
            disabled: this.state?.taxFilterActive,
          },
          {
            id: DocumentTypeEnum.QUARTERLYREVIEW.toString(),
            text: <Trans i18nKey="typeDocument.QUARTERLYREVIEW" />,
            disabled: this.state?.taxFilterActive,
          },
        ],
      },
    ] as IOption[];

    if (result[0].childOptions) {
      result[0].childOptions.push({
        id: DocumentTypeEnum.SHARING.toString(),
        text: <Trans i18nKey="typeDocument.SHARING" />,
        disabled: this.state.taxFilterActive,
      });
    }

    return result;
  };

  private openPdf = (pdfWindow: Window | null, file: Blob) => {
    const fileData = URL.createObjectURL(file);
    if (pdfWindow) {
      pdfWindow.location.href = fileData;
    }
  };

  private handleOpenDocument = (documentId: string, fileName: string, _: number) => {
    const fromAdView = this.props.isUserAdvisorOrAdmin;
    return async (__: React.MouseEvent) => {
      try {
        const url = `${API_ACCOUNTS_V2}/documents/download`;
        const fileExtension = getFileExtension(fileName);
        const pdfWindow = window.open();
        const body = { documentId, fromAdView };
        await axios
          .post(url, body)
          .then((response) => {
            if (response.data.data.length > 0) {
              const file = new Blob([this.base64ToArrayBuffer(atob(response.data.data[0].fileData))], {
                type: `application/${fileExtension}`,
              });
              if (fileExtension && fileExtension.toLowerCase() === PDFExntension) {
                this.openPdf(pdfWindow, file);
              } else {
                saveAs(file, fileName);
              }
              if (!fromAdView) {
                this.props.setDocumentReadStatusFlag(documentId);
              }
            }
          })
          .catch((error) => {
            console.log(documentId);
            console.log(error);
          });
      } catch (error) {
        console.log(documentId);
        console.log(error);
        return { error };
      }

      return undefined;
    };
  };

  private handleDownloadClick = () => {
    const fromAdView = this.props.isUserAdvisorOrAdmin;
    this.setState({ openButton: false });
    this.setState({ isDownloading: true });
    this.forceUpdate();
    if (!this.state.isDownloading) {
      this.state.selectedRowKeys.forEach(async (documentId) => {
        const doc = this.props.accountDocuments.filter((d) => d.documentId === documentId)[0];
        const fileExtension = getFileExtension(doc.name);

        const url = `${API_ACCOUNTS_V2}/documents/download`;
        const body = { documentId, fromAdView };
        await axios
          .post(url, body)
          .then((response) => {
            if (response.data.data.length > 0) {
              const file = new Blob([this.base64ToArrayBuffer(atob(response.data.data[0].fileData))], {
                type: `application/${fileExtension}`,
              });
              saveAs(file, doc.name);
              if (!fromAdView) {
                this.props.setDocumentReadStatusFlag(documentId);
              }
            }
          })
          .catch((error) => {
            console.log(documentId);
            console.log(error);
          });
      });
    }
    this.setState({ isDownloading: false });
  };

  private handleMergeDownloadClick = () => {
    this.setState({ openButton: false });
    this.setState({ isMergeDownloading: true });
    this.forceUpdate();
    const fromAdView = this.props.isUserAdvisorOrAdmin;
    const listOfMergePdfs = [] as any[];
    if (!this.state.isDownloading || !this.state.isMergeDownloading) {
      this.state.selectedRowKeys.forEach(async (documentId) => {
        const doc = this.props.accountDocuments.filter((d) => d.documentId === documentId)[0];
        const fileExtension = getFileExtension(doc.name);
        if (fileExtension && fileExtension.toLowerCase() === PDFExntension) {
          listOfMergePdfs.push({ documentId });
        } else {
          // download individually
          const url = `${API_ACCOUNTS_V2}/documents/download`;
          const body = { documentId, fromAdView };
          await axios
            .post(url, body)
            .then((response) => {
              const file = new Blob([this.base64ToArrayBuffer(atob(response.data.data[0].fileData))], {
                type: `application/${fileExtension}`,
              });
              saveAs(file, doc.name);
              if (!fromAdView) {
                this.props.setDocumentReadStatusFlag(documentId);
              }
            })
            .catch((error) => {
              console.log(documentId);
              console.log(error);
            });
        }
      });
      if (listOfMergePdfs.length > 0) {
        if (!this.props.isMergingDownloadPdfs) {
          this.props.mergeDownloadPdfs({ documents: listOfMergePdfs, mergePdfDocuments: true, fromAdView });
        }
      }
    }
  };

  private renderLink = (_: any, row: any, __: number) => ({
    children: (
      <LinkButtonWithIcon onClick={this.handleOpenDocument(`${row.documentId}`, `${row.name}`, row.documentType)}>
        <Typography style={{ color: appTheme.palette.secondary.dark }} variant={row.read ? 'body1' : 'body2'}>
          {row.name}
        </Typography>
      </LinkButtonWithIcon>
    ),
  });

  private dateSorting = (a: any, b: any) => {
    const compare = moment(a.publicationDate).unix() - moment(b.publicationDate).unix();
    if (compare !== 0) {
      return compare;
    }
    return moment(a.publicationDate).unix() - moment(b.publicationDate).unix();
  };
  private dateSorting2 = (a: any, b: any) => {
    const compare = moment(a.effectiveDate).unix() - moment(b.effectiveDate).unix();
    if (compare !== 0) {
      return compare;
    }
    return moment(a.effectiveDate).unix() - moment(b.effectiveDate).unix();
  };
  private onSelectChange = (selectedRowKeys: any) => {
    this.setState({ openButton: false });
    let pdfSelected = false;
    let countPdfs = 0;
    selectedRowKeys.forEach((element: string) => {
      const doc = this.props.accountDocuments.filter((d) => d.documentId === element)[0];
      const fileExtension = getFileExtension(doc.name);
      if (fileExtension && fileExtension.toLowerCase() === PDFExntension) {
        countPdfs++;
        if (countPdfs > 1) {
          pdfSelected = true;
        }
      }
    });

    this.setState({
      selectedRowKeys,
      selectedRowButtonText: this.renderButtonDownloadSelectionText(selectedRowKeys),
      isAnyPDFSelected: pdfSelected,
    });
  };

  private renderButtonDownloadSelectionText = (selectedRowKey: any) =>
    i18n.t('document.documentDownloadMain', {
      0: selectedRowKey.length > 0 ? `(${selectedRowKey.length.toString()})` : '',
    });

  private handleToggle = () => {
    this.setState({ openButton: !this.state.openButton });
  };

  private handleClose = (event: any) => {
    if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    this.setState({ openButton: false });
  };

  private handleMergeErrorClose = (_: React.SyntheticEvent | Event, __?: string) => {
    this.setState({ openMergeError: false });
  };

  private handleMergeSuccessClose = (_: React.SyntheticEvent | Event, __?: string) => {
    this.setState({ openMergeSuccess: false });
  };

  private base64ToArrayBuffer(binaryString: string) {
    const { length } = binaryString;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < length; i++) {
      uintArray[i] = binaryString.charCodeAt(i);
    }
    return uintArray;
  }

  private getCurrentFiscalYear = () => moment().subtract(1, 'year').startOf('year').format('YYYY');

  private taxDocumentFilter() {
    this.props.setFilteringAccountIds(this.props.accounts.map((x) => x.id));
    const start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
    const end = moment().dayOfYear(60).format('YYYY-MM-DD');
    this.handleOnDocumentTypeSelected([DocumentTypeEnum.TAXSlIP.toString()]);
    this.handleOnFromSelected([
      DocumentFromTypeEnum.IAPRIVATEWEALTH.toString(),
      DocumentFromTypeEnum.FROM_LBS.toString(),
    ]);
    this.setState({ documentsStartDate: start, documentsEndDate: end, taxFilterActive: true });
    this.props.setDocumentDateFiltering(start, end);

    this.props.clearDocuments();
    this.setState({ taxFilterActive: true });
  }

  public render() {
    const { selectedRowKeys, selectedRowButtonText, isAnyPDFSelected } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRowButtonText,
      isAnyPDFSelected,
      onChange: this.onSelectChange,
    };

    const {
      isAccountDocumentsFetching,
      isAccountFetching,
      isAccountDocumentsLoaded,
      isTradeConfirmationsLoaded,
      sendMessageSuccess,
      hasAccessToDocumentExchange,
      isMergingDownloadPdfsDone,
      isMergingDownloadPdfsFail,
      mergePdfsResults,
      isTradeConfirmationDocumentsFetching,
      isAccountDocumentsFetchingFail,
      isTradeConfirmationDocumentsFetchingFail,
      allAccountIds,
    } = this.props;
    if (isAccountFetching) {
      return (
        <Container>
          <Loading show />
        </Container>
      );
    }

    const action = (
      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleMergeErrorClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );

    if (
      !isAccountDocumentsLoaded &&
      !isAccountDocumentsFetching &&
      !isAccountFetching &&
      !isAccountDocumentsFetchingFail &&
      this.props.accounts.length > 0
    ) {
      if (this.state.taxFilterActive) {
        this.props.fetchTaxDocuments();
      } else {
        this.props.fetchAccountDocuments();
      }
    }
    if (
      !isTradeConfirmationsLoaded &&
      !isTradeConfirmationDocumentsFetching &&
      !isAccountFetching &&
      !isTradeConfirmationDocumentsFetchingFail &&
      this.props.accounts.length > 0 &&
      !this.state.taxFilterActive
    ) {
      this.props.fetchTradeConfirmationDocuments();
    }

    if (isMergingDownloadPdfsDone && this.state.isMergeDownloading) {
      if (isMergingDownloadPdfsFail || isFieldEmptyOrNull(mergePdfsResults.data.fileData)) {
        this.setState({ openMergeError: true });
      } else {
        this.setState({ openMergeSuccess: true });
        // download result and reset variables
        const file = new Blob([this.base64ToArrayBuffer(atob(mergePdfsResults.data.fileData))], {
          type: 'application/pdf',
        });
        saveAs(file, mergePdfsResults.data.fileName);
        // update status flag
        mergePdfsResults.mergedIds.forEach((e) => {
          if (!this.props.isUserAdvisorOrAdmin) {
            this.props.setDocumentReadStatusFlag(e.documentId);
          }
        });
      }

      this.setState({ isMergeDownloading: false });
    }

    const docs = this.filterDocuments();

    const emptyText = this.state.taxFilterActive
      ? i18n.t('document.noDataTaxSeason', { 0: this.getCurrentFiscalYear() })
      : i18n.t('document.noData') +
        this.state.documentsStartDate +
        i18n.t('document.noDataTo') +
        this.state.documentsEndDate;
    const sortText = i18n.t('common.sort');
    return (
      <Container>
        {REACT_APP_USE_MESSAGING && sendMessageSuccess && (
          <Grid style={{ marginTop: '35px' }}>
            <SendMessageAlertResult />
          </Grid>
        )}
        <HeaderPageContainer>
          <PageTitleContainer>
            <Typography variant="h1" component="span" style={{ paddingBottom: '12px' }}>
              <Trans i18nKey="document.title" />
            </Typography>
            <DataLoadStamp isDocumentPage />
          </PageTitleContainer>
          <PageTitleContainer>
            <FilterContainer>
              <Search
                placeholder={i18n.t('document.search')}
                style={{ width: 140, margin: '4px 0px 4px 8px', color: 'blue' }}
                onChange={this.handleOnChangeDescription}
                value={this.state.textFilter}
              />
              <PeriodFilter
                startDate={this.state.documentsStartDate}
                endDate={this.state.documentsEndDate}
                onPeriodSelected={this.handleOnPeriodSelected}
                taxFilterAvailable={TOGGLE_TAX_DOCUMENTS_BANNER && isTaxFilterEnabled()}
                selectTaxFilter={this.state.taxFilterActive}
              />
              <MultiSelect
                label={i18n.t('document.type')}
                options={this.getTypeDocumentFilter()}
                selectedOptionIds={this.state.typeFilter}
                onOptionSelected={this.handleOnDocumentTypeSelected}
              />
              {REACT_APP_USE_MESSAGING && (
                <MultiSelect
                  label={i18n.t('document.fromName')}
                  options={this.getFromOptions()}
                  selectedOptionIds={this.state.fromFilter}
                  onOptionSelected={this.handleOnFromSelected}
                />
              )}
              <PartyAccountFilter />
            </FilterContainer>
          </PageTitleContainer>
        </HeaderPageContainer>

        {REACT_APP_USE_MESSAGING && hasAccessToDocumentExchange && (
          <div>
            <Link to={RouteNames.sendMessage} style={{ textDecoration: 'none' }}>
              <Button style={{ marginBottom: 20 }} variant="contained" color="secondary">
                <Trans i18nKey="document.sendADocument" />
                <AddCircleIcon style={{ paddingLeft: '10px' }} />{' '}
              </Button>
            </Link>
          </div>
        )}
        <>
          {isAccountDocumentsFetching || isAccountFetching ? (
            <Loading show />
          ) : (
            <div>
              {isAccountDocumentsLoaded && !isTradeConfirmationsLoaded && !isTradeConfirmationDocumentsFetchingFail && (
                <div className="boxDocumentError">
                  <Loader size={18} />
                  &nbsp;<div>{`${i18n.t('document.documentsStillLoading')}`}</div>
                </div>
              )}
              {(isAccountDocumentsFetchingFail || isTradeConfirmationDocumentsFetchingFail) && (
                <div className="boxErrorAlert">
                  <Error />
                  &nbsp;<div>{`${i18n.t('document.documentsFailedLoading')}`}</div>
                </div>
              )}

              {!TOGGLE_DOCUMENTS_BANNER && (
                <div className="boxDocumentError">
                  <Error />
                  &nbsp;<div>{`${i18n.t('document.bannerMessage')}`}</div>
                </div>
              )}
              {!TOGGLE_DOCUMENTS_TRADECONF_ERROR && <InfoContainer title="landing.tradeConfErrorMessage" />}

              {TOGGLE_TAX_DOCUMENTS_BANNER && isTaxFilterEnabled() && (
                <InfoContainer
                  title="document.taxSeasonDocuments1"
                  linkTitle="document.taxSeasonLink"
                  title2={i18n.t('document.taxSeasonDocuments2', { 0: this.getCurrentFiscalYear() })}
                  clickFn={() => this.taxDocumentFilter()}
                />
              )}

              <Table
                sortDirections={['ascend', 'descend', 'ascend']}
                style={{ backgroundColor: 'white', boxShadow: '2px 2px 2px rgba(102, 102, 102, 0.349019607843137)' }}
                rowClassName={(doc) => (doc.read ? '' : 'ant-table-row-bold unreadRowDocument')}
                rowKey="id"
                rowSelection={rowSelection}
                columns={this.state.columns}
                dataSource={docs}
                pagination={{ showSizeChanger: true, size: 'small' }}
                locale={{
                  emptyText: <NoData text={emptyText} />,
                  triggerDesc: i18n.t('common.sortDescending'),
                  triggerAsc: i18n.t('common.sortAscending'),
                }}
                showSorterTooltip={{ title: sortText, placement: 'bottom' }}
              />
              <Grid container direction="column" alignItems="flex-start">
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                  <Button
                    id="demo-customized-button"
                    aria-controls={this.state.openButton ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.openButton ? 'true' : undefined}
                    disableElevation
                    variant="outlined"
                    color="secondary"
                    disableRipple
                    size="large"
                    onClick={this.handleToggle}
                    endIcon={
                      this.state.isMergeDownloading ? (
                        <Loader size={14} />
                      ) : this.state.openButton ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                    ref={this.state.anchorRef}
                    disabled={
                      rowSelection.selectedRowKeys.length === 0 ||
                      this.state.isDownloading ||
                      this.state.isMergeDownloading
                    }
                  >
                    {rowSelection.selectedRowButtonText}
                  </Button>
                  <Popper
                    open={this.state.openButton}
                    anchorEl={this.state.anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                      >
                        <Paper style={{ marginTop: 7 }}>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList className="MenuListDocuments">
                              <MenuItem
                                className="MenuListDocumentsItem"
                                disabled={this.state.isDownloading || rowSelection.selectedRowKeys.length === 0}
                                disableRipple
                                onClick={this.handleDownloadClick}
                              >
                                {i18n.t('document.documentDownloadSeparateFiles') as string}
                              </MenuItem>
                              <MenuItem
                                className="MenuListDocumentsItem"
                                disabled={
                                  this.state.isMergeDownloading ||
                                  rowSelection.selectedRowKeys.length === 0 ||
                                  !rowSelection.isAnyPDFSelected
                                }
                                disableRipple
                                onClick={this.handleMergeDownloadClick}
                              >
                                {i18n.t('document.documentMergeDownload') as string}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            </div>
          )}
        </>
        <PrintButton />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openMergeError}
          onClose={this.handleMergeErrorClose}
          message={i18n.t('document.documentMergeDownloadError') as string}
          action={action}
          autoHideDuration={5000}
        >
          <StyledAlert color="#FDECEC">
            <Alert icon={<ReportIcon fontSize="large" />} color="error" variant="outlined" severity="error">
              <AlertTitle>
                <Typography variant="h4" component="span">
                  {i18n.t('common.error') as string}
                </Typography>
              </AlertTitle>
              <Typography variant="body1" component="span">
                {i18n.t('document.documentMergeDownloadError') as string}
              </Typography>
            </Alert>
          </StyledAlert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openMergeSuccess}
          onClose={this.handleMergeSuccessClose}
          message={i18n.t('document.documentMergeDownloadSuccess') as string}
          action={action}
          autoHideDuration={5000}
        >
          <StyledAlert color="#F7F7ED">
            <Alert icon={<CheckCircleIcon fontSize="large" />} color="success" variant="outlined" severity="error">
              <AlertTitle>
                <Typography variant="h4" component="span">
                  {i18n.t('common.success') as string}
                </Typography>
              </AlertTitle>
              <Typography variant="body1" component="span">
                {i18n.t('document.documentMergeDownloadSuccess') as string}
              </Typography>
            </Alert>
          </StyledAlert>
        </Snackbar>
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    party: getPartyV1(state),
    accountIds: getAccountIds(state),
    accounts: getAllCurrentFilterAccounts(state),
    accountDocuments: getAccountDocuments(state),
    isAccountDocumentsFetching: getIsAccountDocumentsFetching(state),
    isAccountDocumentsFetchingFail: getIsAccountDocumentsFetchingFail(state),
    isAccountDocumentsLoaded: getIsAccountDocumentsLoaded(state),
    isTradeConfirmationsLoaded: getIsTradeConfirmationsLoaded(state),
    isTradeConfirmationDocumentsFetching: getIsTradeConfirmationDocumentsFetching(state),
    isTradeConfirmationDocumentsFetchingFail: getIsTradeConfirmationDocumentsFetchingFail(state),
    isAccountFetching: getIsAccountsFetching(state),
    isMergingDownloadPdfs: getIsMergingDownloadPdfs(state),
    isMergingDownloadPdfsDone: getIsMergingDownloadPdfsDone(state),
    isMergingDownloadPdfsFail: getIsMergingDownloadPdfsFail(state),
    getAllAccountsFiltered: getAllAccountsFiltered(state),
    culture: state.system.culture,
    sendMessageSuccess: getSendMessageSuccess(state),
    hasAccessToDocumentExchange: !hasOnlySharedAccounts(state),
    mergePdfsResults: getMergePdfsResults(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    allAccountIds: getAllAvailableAccounts(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAccountDocuments,
      fetchTradeConfirmationDocuments,
      downloadDocument,
      mergeDownloadPdfs,
      refreshSession,
      setDocumentReadStatusFlag,
      setDocumentDateFiltering,
      setFilteringAccountIds,
      clearDocuments,
      fetchTaxDocuments,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPage as any);
