import { createSelector } from 'reselect';
import { IAppRootState } from '~/app/rootReducer';
import moment from 'moment';
import { IOption } from '~/common/components/multiselect-dropdown/multiSelectDropdownTypes';
import { ITransactionState, ITransaction } from '../transactionTypes';
import { compareBy } from '~/common/helpers';

export const getTransactionState = (state: IAppRootState) => state.transaction;

export const getSelectedCurrencyIds = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.filter.currencyIds,
);

export const getSelectedProductTypeIds = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.filter.productTypeIds,
);

export const getSelectedOperationTypeIds = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.filter.operationTypeIds,
);

export const getIsDataExpired = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => !transactionState.dataExpiration
      || moment(transactionState.dataExpiration).isBefore(moment()),
);

export const getSelectedTransactionIds = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.filteredTransactionIds,
);

export const getSelectedTransactions = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => {
    const { filteredTransactionIds, transactions, filter } = transactionState;

    let transactionIds = [] as string[];
    if (filteredTransactionIds.length > 0) {
      transactionIds = filteredTransactionIds;
    }

    let selectedTransactions = transactionIds
      .filter((selectedId) => transactions[selectedId])
      .map((selectedId) => transactions[selectedId]);

    selectedTransactions = applySymbolDescriptionFilter(selectedTransactions, filter.symbolDescription);
    selectedTransactions = applyCurrencyFilter(selectedTransactions, filter.currencyIds);
    selectedTransactions = applyProductTypeFilter(selectedTransactions, filter.productTypeIds);
    selectedTransactions = applyOperationTypeFilter(selectedTransactions, filter.operationTypeIds);

    return selectedTransactions;
  },
);

export const applySymbolDescriptionFilter = (transactions: ITransaction[], symbolDescriptionFilter: string): ITransaction[] => {
  if (symbolDescriptionFilter.trim().length <= 0) {
    return transactions;
  }

  const symbolFiltering = transactions.filter((transaction: ITransaction) => transaction.symbol.toLowerCase().indexOf(symbolDescriptionFilter.toLowerCase()) >= 0);
  const descriptionFiltering = transactions.filter((transaction: ITransaction) => transaction.description.toLowerCase().indexOf(symbolDescriptionFilter.toLowerCase()) >= 0);
  const filtering = symbolFiltering.concat(descriptionFiltering);
  const unique = Array.from(new Set(filtering.map((item: any) => item)));

  return unique;
};

export const applyCurrencyFilter = (transactions: ITransaction[], currencyIds: string[]): ITransaction[] => {
  if (currencyIds.length <= 0) {
    return transactions;
  }

  return transactions.filter((transaction: ITransaction) => {
    let hasRightCurrentcy = false;
    currencyIds.forEach((currencyId) => {
      if (transaction.netAmountCurrency === currencyId) {
        hasRightCurrentcy = true;
      }
    });

    return hasRightCurrentcy;
  });
};

export const applyProductTypeFilter = (transactions: ITransaction[], productTypeIds: string[]): ITransaction[] => {
  if (productTypeIds.length <= 0) {
    return transactions;
  }

  return transactions.filter((transaction: ITransaction) => {
    let hasRightProductType = false;
    productTypeIds.forEach((productTypeId) => {
      if (transaction.productType.toString() === productTypeId) {
        hasRightProductType = true;
      }
    });

    return hasRightProductType;
  });
};

export const applyOperationTypeFilter = (transactions: ITransaction[], operationTypeIds: string[]): ITransaction[] => {
  if (operationTypeIds.length <= 0) {
    return transactions;
  }

  return transactions.filter((transaction: ITransaction) => {
    let hasRightOperationType = false;
    operationTypeIds.forEach((operationTypeId) => {
      if (transaction.operationType.toString() === operationTypeId) {
        hasRightOperationType = true;
      }
    });

    return hasRightOperationType;
  });
};

export const getOperationTypeContextualOptions = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => {
    const { transactions } = transactionState;
    const operationTypeOptions = [] as IOption[];

    for (const key in transactions) {
      if (transactions[key]) {
        if (!operationTypeOptions.some((t) => t.id === transactions[key].operationType)) {
          operationTypeOptions.push({ id: transactions[key].operationType, text: transactions[key].operationTypeDescription } as IOption);
        }
      }
    }

    return operationTypeOptions.sort(compareBy({ propertyToCompare: 'text' }));
  },
);

export const getIsTransactionFetching = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.isFetching,
);

export const getIsTransactionFetchingFail = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => transactionState.isFail,
);

export const getExcludeCloseAccount = createSelector(
  getTransactionState,
  (transactionState: ITransactionState) => !!(transactionState.excludeCloseAcount),
);
