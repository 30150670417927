import styled, { keyframes } from 'styled-components';

const LoaderCircleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

export const StyledCircle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  ${({ rotate }) => rotate && `
    transform: rotate(${rotate}deg);
  `}

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color:#878889;
    border-radius: 100%;
    animation: ${LoaderCircleFadeDelay} 1.2s infinite ease-in-out both;
    ${({ delay }) => delay && `
      animation-delay: ${delay}s;
    `}
  }
`;

export const StyledWrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: relative;
`;
