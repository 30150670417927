import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAccount, IRegisteredAccount } from '~/stores/account/accountTypes';
import {
  setFilteringAccountIds,
  setFilteringPartyAccountIds,
  setFilteringRegisterAccountIds,
} from '~/stores/account/actions/accountActions';
import { updateTransactionsAccounts } from '~/stores/transaction/actions/transactionActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { IAppRootState } from '~/app/rootReducer';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { getAllAvailableAccounts, getAllAccountsFiltered } from '~/stores/account/selectors/accountSelectors';
import type { DataNode } from 'antd/es/tree';
import i18n from '~/app/i18n';
import { Tree } from 'antd';
import styled from 'styled-components';
import { geRootFromAccount, getAccountsFromRoot } from '~/common/accountsHelpers';
import { Popper, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IAButtonSelect } from '~/common/styles/baseStyle';
import { refreshSession } from '~/stores/system/actions/systemActions';

export interface IPropsFromState {
  accounts: IAccount[];
  filteredAccountIds: string[];
  party: IPartyV1;
}

export interface IAccountFilterProps {
  onClose?: () => void;
}

export interface IAccountFilterState {
  isOpen: boolean;
  anchorEl: any;
  defaultAccountSelected: string[];
}

export interface IPropsFromDispatch {
  setFilteringPartyAccountIds: typeof setFilteringPartyAccountIds;
  refreshSession: typeof refreshSession;
}

const ContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 0px;
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans Regular', 'Open Sans';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  justify-content: flex-end;
`;

const ContainerFilter = styled.div`
  font-family: 'Open Sans Regular', 'Open Sans';
  box-shadow: 0px 5px 5px 2px rgba(102, 102, 102, 0.349019607843137);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  background-color: #fff;
  min-width: 290px;
  max-height: 450px;
  overflow: auto;
`;
type IProps = IPropsFromState & IPropsFromDispatch & IAccountFilterProps;

class PartyAccountFilter extends React.PureComponent<IProps, IAccountFilterState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      anchorEl: null,
      defaultAccountSelected: ['all'],
    };
  }

  private getTreeNodes = (roots: string[], accounts: IAccount[]) => {
    const rootTreeData: DataNode[] = [];
    roots.forEach((rootID, index) => {
      let owner = '';
      const accountsChild = getAccountsFromRoot(accounts, [rootID]);
      const accountsTreeData: DataNode[] = [];
      accountsChild.forEach((a: any) => {
        const title = a.alias === undefined || a.alias === '' ? `${a.accountType} (${a.id})` : a.alias;
        owner = a.groupedAccountName;
        accountsTreeData.push({ title, key: a.id, value: a.id } as DataNode);
      });
      const name = `${owner} (${accountsChild.length})`;
      rootTreeData.push({ value: name, title: name, key: rootID, children: accountsTreeData } as DataNode);
    });
    const treeData = [
      {
        title: i18n.t('common.nbAccountsSelectedAll'),
        key: 'all',
        children: rootTreeData,
        value: i18n.t('common.nbAccountsSelectedAll'),
      },
    ];
    return treeData;
  };

  private onCheck = (checkedKey: string[]) => {
    const account = checkedKey.filter((w) => w.length === 7);
    this.setState({ defaultAccountSelected: account });
    this.props.refreshSession();
    this.props.setFilteringPartyAccountIds(account);
  };

  private handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    if (this.state.isOpen && this.props.onClose) {
      const closeFn = this.props.onClose;
      closeFn();
    }
    this.setState({ isOpen: !this.state.isOpen, anchorEl: event.currentTarget });
  };

  private handleClickAway = () => {
    if (this.props.onClose) {
      const closeFn = this.props.onClose;
      closeFn();
    }
    this.setState({ isOpen: false, anchorEl: null });
  };

  private renderTreeNodes = (data: any[]) => {
    const result: DataNode[] = data.map((item) => {
      if (item.children) {
        return {
          expanded: true,
          title: item.title,
          key: item.key,
          dataRef: item,
          disableCheckbox: item.disableCheckbox,
          children: this.renderTreeNodes(item.children),
        } as DataNode;
      }
      return { key: item.key, ...item } as DataNode;
    });

    return result;
  };

  private isAllSelected = (selectedAccount: string[], accountsInFilter: IAccount[]): boolean =>
    selectedAccount.length === accountsInFilter.length || selectedAccount.length === 0;

  public render() {
    const { filteredAccountIds, accounts } = this.props;

    const id = this.state.isOpen ? 'popperAccount' : undefined;
    const isContainProgram = accounts.map((m: any) => m.productType).filter((w: any) => w !== '').length > 0;
    let selectedAccount: string[] = [];

    selectedAccount = filteredAccountIds.length > 0 ? filteredAccountIds : [];

    const accountsInFilter = [...accounts];

    const tree = this.getTreeNodes(geRootFromAccount(accountsInFilter), accountsInFilter);

    const labelNbAccount = i18n.t('common.nbAccountsSelected', {
      0: this.isAllSelected(selectedAccount, accountsInFilter)
        ? i18n.t('common.allAccounts')
        : selectedAccount.length.toString(),
    });
    return (
      <ContainerCol>
        <IAButtonSelect
          onClick={this.handleFilterClick}
          variant="outlined"
          endIcon={<ExpandMoreIcon htmlColor="#003da5" />}
        >
          {labelNbAccount}
        </IAButtonSelect>
        {this.state.isOpen && (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Popper
              id={id}
              open={this.state.isOpen}
              anchorEl={this.state.anchorEl}
              placement="bottom-end"
              disablePortal={false}
              modifiers={[{ name: 'flip', enabled: true }]}
            >
              <ContainerFilter>
                <Tree
                  checkable
                  onCheck={this.onCheck as any}
                  checkedKeys={selectedAccount.length > 0 ? selectedAccount : this.state.defaultAccountSelected}
                  defaultExpandAll
                  treeData={this.renderTreeNodes(tree)}
                />
              </ContainerFilter>
            </Popper>
          </ClickAwayListener>
        )}
      </ContainerCol>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    filteredAccountIds: getAllAccountsFiltered(state),
    accounts: getAllAvailableAccounts(state),
    party: getPartyV1(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilteringPartyAccountIds,
      refreshSession,
    },
    dispatch,
  );

export default connect<IPropsFromState, IPropsFromDispatch, IAccountFilterProps, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(PartyAccountFilter);
