import axios from 'axios';
import { getCurrentIsoLang, subsidiaryName } from '~/common/helpers';
import {
  IAccount,
  IAssetsAllocation,
  IClientAccount,
  IDeliveryPreferences,
  IDeliveryPreferencesSummary,
  IDeliveryPreferencesToSave,
  IDocument,
  IMergeDownloadPdfs,
  IRegisteredAccount,
} from '~/stores/account/accountTypes';
import { API_ACCOUNTS, API_ACCOUNTS_V2, API_DOCS, API_MARKETDATA, CLIENT_PORTAL_SOURCE_KEY } from '~/common/API';

export const AccountHttpClient = new (class {
  private readonly ACCOUNT_FIELDS =
    'id,name,cashBalanceUsd,cashBalanceCad,marginValueUsd,marginValueCad,currency,positions,totalMarketValueCad,totalMarketValueUsd,totalPreviousMarketValueCad,totalPreviousMarketValueUsd,accountType,accountTypeLong,productType,rootId,isOpen,repCode,beneficiary,spouseName,exchangeRate,alias,lastUpdate';

  public fetchAccounts = async (
    accountIds: string[],
    partyId: string,
    useIntraday: boolean,
    filteredAccounts: string[],
  ) => {
    const ids = `clientIds=${accountIds.map((a) => a).join(',')}`;
    const filteredIds = `filteredAccounts=${filteredAccounts.map((a) => a).join(',')}`;
    const response = await axios.get(
      `${API_ACCOUNTS}/Client?${ids}&partyId=${partyId}&subsidiary=${subsidiaryName}&${filteredIds}
      &useIntraday=${useIntraday}&lang=${getCurrentIsoLang()}`,
    );
    return response.data.data as IClientAccount;
  };

  public fetchAccountsInfo = async () => {
    const baseUrl = `${API_ACCOUNTS}/ClientInfo?`;
    const response = await axios.get(`${baseUrl}useIntraday=true&lang=${getCurrentIsoLang()}`);
    return response.data.data as IClientAccount;
  };

  public intradayUpdateAccounts = async (accounts: IAccount[], filteredAccounts: string[]) => {
    const body = {
      accounts,
      filteredAccounts,
    };

    const response = await axios.post(`${API_ACCOUNTS}/ClientIntraday`, body, {
      headers: { 'Accept-Language': getCurrentIsoLang() },
    });
    return response.data.data as IClientAccount;
  };

  public fetchAssetsAllocation = async (accountIds: string[]) => {
    const ids = `accountIds=${accountIds.join(',')}`;
    const response = await axios.get(
      `${API_ACCOUNTS}/AssetAllocation?${ids}&lang=${getCurrentIsoLang()}&subsidiary=${subsidiaryName}`,
    );
    return response.data.data as IAssetsAllocation[];
  };

  public fetchRegisteredAccounts = async (accountIds: string[], year: number) => {
    const ids = `accountIds=${accountIds.join(',')}`;

    const fields = `Data(registeredAccountType,registeredAccountGroup,year,jurisdiction,summary/*,transactions/*,account(${this.ACCOUNT_FIELDS}))`;
    const response = await axios.get(
      `${API_ACCOUNTS}/Transactions/RegisteredAccounts?${ids}&year=${year}&fields=${fields}&lang=${getCurrentIsoLang()}&subsidiary=${subsidiaryName}`,
    );

    return response.data.data as IRegisteredAccount[];
  };

  public fetchNetInvested = async (accountIds: string[]) => {
    const ids = `accountIds=${accountIds.join(',')}`;
    const response = await axios.get(`${API_ACCOUNTS}/NetInvested?${ids}&lang=${getCurrentIsoLang()}`);
    return response.data.data;
  };

  public fetchNetInvestedToken = async () => {
    const response = await axios.get(`${API_ACCOUNTS}/NetInvestedToken?`, {
      headers: { 'Accept-Language': getCurrentIsoLang() },
    });
    return response.data.data;
  };

  public fetchRateOfReturnV2 = async (accountIds: string[]) => {
    const ids = `accountIds=${accountIds.join(',')}`;
    const response = await axios.get(`${API_ACCOUNTS_V2}/RateOfReturn?${ids}`);
    return response.data.data.rateOfReturns as any[];
  };

  public fetchCashFlowSummaryV2 = async (accountIds: string[], totalMarketValueCad: number, netInvested: any) => {
    const ids = `accountIds=${accountIds.join(',')}`;
    const response = await axios.get(
      `${API_ACCOUNTS_V2}/CashFlowSummary?${ids}&totalMarketValue=${totalMarketValueCad}&previousOpening=${netInvested.previousOpeningMarketValue}&previousClosing=${netInvested.previousClosingMarketValue}&ytdOpening=${netInvested.openingMarketValue}`,
      { timeout: 120000 },
    );
    return response.data.data as any[];
  };

  public fetchDeliveryPreferences = async (clientIds: string[], partyId?: string) => {
    const ids = `clientIds=${clientIds.join(',')}`;
    const party = !partyId ? '' : `&partyId=${partyId}`;
    const url = `${API_ACCOUNTS_V2}/EDeliveryPreference?${ids}${party}`;
    const response = await axios.get(
      url,
      { headers: { 'x-source': CLIENT_PORTAL_SOURCE_KEY } },
    );
    return response.data.data as IDeliveryPreferences[];
  };

  public saveDeliveryPreferences = async (preferences: IDeliveryPreferencesToSave[]) => {
    const url = `${API_ACCOUNTS_V2}/EDeliveryPreference`;
    await axios.post(
      url,
      preferences,
      { headers: { 'x-source': CLIENT_PORTAL_SOURCE_KEY } },
    );
  };

  public fetchDeliveryPreferencesSummary = async (filteredDocumentTypes: string) => {
    const filteredTypes =
      filteredDocumentTypes.length > 0
        ? `filteredDocumentTypes=${filteredDocumentTypes.split(',').join('&filteredDocumentTypes=')}`
        : '';
    const url = `${API_ACCOUNTS_V2}/EDeliveryPreference/Summary?${filteredTypes}`;
    const response = await axios.get(
      url,
      { headers: { 'x-source': CLIENT_PORTAL_SOURCE_KEY } },
    );
    return response.data.data as IDeliveryPreferencesSummary;
  };

  public saveNickName = async (id: string, accountId: string, nickname: string) => {
    const url = `${API_ACCOUNTS}/Alias?partyId=${id}`;
    const body = [{ AccountId: accountId, Alias: nickname }];
    await axios.post(url, body);
  };

  public fetchDocuments = async (types: string, partyId: string, from: Date | null = null, to: Date | null = null) => {
    const dateFrom = from ? from.toISOString() : '';
    const dateTo = to ? to.toISOString() : '';
    const response = await axios.get(
      `${API_ACCOUNTS_V2}/documents?types=${types}&partyId=${partyId}&fromDate=${dateFrom}&toDate=${dateTo}&dateFilterField=${3}&usePreferredAccounts=false`,
      { headers: { 'Accept-Language': getCurrentIsoLang() } },
    );

    return response.data.data as IDocument[];
  };

  public fetchTaxDocuments = async (partyId: string) => {
    const response = await axios.get(
      `${API_ACCOUNTS_V2}/taxdocuments?partyId=${partyId}`,
      { headers: { 'Accept-Language': getCurrentIsoLang() } },
    );

    return response.data.data as IDocument[];
  };

  public downloadDocument = async (documentId: string) => {
    const url = `${API_DOCS}/${documentId}`;
    const resp = await axios.get(url);
    return resp.data;
  };

  public mergeDowloadPdfs = async (mergeBody: IMergeDownloadPdfs[]) => {
    const url = `${API_ACCOUNTS_V2}/Documents/MergeAndDownload`;
    const response = await axios.post(url, mergeBody);
    return response.data.data;
  };

  public fetchMarketData = async (stocks: string[]) => {
    const calls: any[] = [];
    const url = `${API_MARKETDATA}/Intraday`;
    const currencyUrl = `${API_MARKETDATA}/Currencies?symbols=USDCAD,CADUSD`;
    calls.push(axios.post(url, stocks));
    calls.push(axios.get(currencyUrl));
    const response = await Promise.all(calls);
    return response;
  };

  public fetchClosedMarkets = async () => {
    const url = `${API_MARKETDATA}/MarketClosedCountries`;
    const response = (await axios.get(url)) as any;
    return response.data;
  };

  public fetchCSV = async (accountIds: string[], useIntraday: boolean, domain: string, csvFilename: string) => {
    const response = await axios.get(
      `${API_ACCOUNTS_V2}/Export/Csv?accountIds=${accountIds}&useIntraday=${useIntraday}&domain=${domain}&csvFilename=${csvFilename}`,
      { headers: { 'Accept-Language': getCurrentIsoLang() } },
    );
    return response.data;
  };
})();
