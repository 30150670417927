import { IFetchError } from '~/common/types';
import { IQueryStringParameter, IToken, SystemActionTypes, IFeatureToggles } from '~/stores/system/systemTypes';
import { action } from 'typesafe-actions';

export const setCulture = (culture: string) => action(SystemActionTypes.SET_CULTURE, culture);
export const setAssetAllocationBreakDown = (isAssetBreakdown: boolean) =>
  action(SystemActionTypes.SET_ASSETALLOCATION_BREAKDONW, isAssetBreakdown);

export const setQueryStringParameters = (parameters: IQueryStringParameter[]) =>
  action(SystemActionTypes.SET_QUERY_STRING_PARAMETERS, parameters);

export const appGlobalError = (error: IFetchError | string) => action(SystemActionTypes.SYSTEM_GLOBAL_ERROR, error);

export const fetchInitialData = () => action(SystemActionTypes.FETCH_INITIAL_DATA);

export const fetchClaims = () => action(SystemActionTypes.FETCH_CLAIMS);
export const fetchClaimsSuccessful = (token: IToken) => action(SystemActionTypes.FETCH_CLAIMS_SUCCESSFUL, token);
export const fetchClaimsFailed = () => action(SystemActionTypes.FETCH_CLAIMS_FAILED);
export const fetchClaimsAborted = () => action(SystemActionTypes.FETCH_CLAIMS_ABORTED);

export const fetchFeatureToggles = () => action(SystemActionTypes.FETCH_FEATURE_TOGGLE);
export const fetchFeatureTogglesSuccessful = (token: IFeatureToggles) =>
  action(SystemActionTypes.FETCH_FEATURE_TOGGLE_SUCCESSFUL, token);
export const fetchFeatureTogglesFailed = () => action(SystemActionTypes.FETCH_FEATURE_TOGGLE_FAILED);
export const fetchFeatureTogglesAborted = () => action(SystemActionTypes.FETCH_FEATURE_TOGGLE_ABORTED);

export const fetchFeatureToggleFromUser = (token: IToken) => action(SystemActionTypes.FETCH_FEATURE_TOGGLE, token);

export const refreshSession = () => action(SystemActionTypes.REFRESH_SESSION);
