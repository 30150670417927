import { Autocomplete, Box, Grid, TextField, InputAdornment, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import i18n from '~/app/i18n';
import { connect, useDispatch } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { IMarketDataState, Security } from '~/stores/marketData/marketDataTypes';
import { fetchSecurityList, securityListClear, securityViewed } from '~/stores/marketData/actions/marketDataActions';
import { getCurrentIsoLang } from '~/common/helpers';
import ErrorIcon from '@mui/icons-material/ReportRounded';
import Colors from '~/common/themes/colors';
import InfoIcon from '@mui/icons-material/Info';
import { Trans } from 'react-i18next';

type ISecurityAutocompleteProps = {
  pathname: string;
};

export interface IPropsFromState {
  marketDataState: IMarketDataState;
}

export const SecurityAutocomplete: React.FC<ISecurityAutocompleteProps & IPropsFromState> = ({
  pathname,
  marketDataState,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(securityListClear());
  }, []);
  const onInputChange = (_: any, value: string, reason: string) => {
    fetchSecurityListLocal(value);
  };

  const onSelect = (_: any, value: any, reason: string) => {
    if (value) {
      const security: Security = value as Security;
      dispatch(securityViewed(security));
      history.push(RouteNames.positionOverview, {
        symbol: security.symbol,
        description: getCurrentIsoLang() === 'en' ? security.nameEn : security.nameFr,
        source: pathname,
      });
    }
  };

  const fetchSecurityListLocal = React.useCallback(
    debounce((query: string) => {
      if (query) {
        dispatch(fetchSecurityList(query));
      } else {
        dispatch(securityListClear());
      }
    }, 300),
    [],
  );

  /**
   * Because MUI Autocomplete doesn't provide custom HTML rendering for no-options list.
   * We're mocking empty list by adding a empty item to the list.
   */
  let securityList = marketDataState.securitySearchList;
  if (!marketDataState.securitySearchTerm) {
    // Rendering Recently Viewed and Searched
    securityList = marketDataState.viewedSecurityList.length
      ? [{ label: '' } as Security, ...marketDataState.viewedSecurityList]
      : [];
  } else if (marketDataState.isFetchingSecurityList) {
    securityList = [];
  } else if (marketDataState.isFailSecurityList || !marketDataState.securitySearchList.length) {
    // Mocking a item to render properly Failture or Empty Results
    securityList = [{ label: '' } as Security];
  }
  return (
    <div>
      <Autocomplete
        filterOptions={(x) => x}
        id="security-select"
        sx={{ width: 410 }}
        options={securityList}
        onInputChange={onInputChange}
        onChange={onSelect}
        freeSolo
        isOptionEqualToValue={(option: Security, value: any) => option.symbol === value.symbol}
        renderOption={(props, option: Security) => {
          const isEmptyItemList = option.label === '';

          if (!marketDataState.isFetchingSecurityList && marketDataState.isFailSecurityList) {
            return (
              <Stack
                paddingX={2}
                paddingY={1}
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ color: Colors.redAlert }}
              >
                <ErrorIcon color="error" fontSize="medium" />
                <span>
                  <Trans i18nKey="marketsPage.securitySearch.searchError" />
                </span>
              </Stack>
            );
          }

          if (!marketDataState.isFetchingSecurityList && !marketDataState.securitySearchTerm && isEmptyItemList) {
            return (
              <Stack paddingX={2} paddingY={1} paddingBottom={2} direction="column" sx={{ fontWeight: '700' }}>
                <span>
                  <Trans i18nKey="marketsPage.securitySearch.recentlyViewed" />
                </span>
              </Stack>
            );
          }

          if (!marketDataState.isFetchingSecurityList && marketDataState.securitySearchTerm && isEmptyItemList) {
            return (
              <Stack
                paddingX={2}
                paddingY={1}
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ color: Colors.lightGray }}
              >
                <InfoIcon fontSize="medium" />
                <span>
                  <Trans i18nKey="marketsPage.securitySearch.noResultFound" />
                </span>
              </Stack>
            );
          }

          const categoryText = option.category
            ? i18n.t(`marketsPage.securitySearch.${option.category?.toLocaleLowerCase()}`)
            : '';
          return (
            <Box flexDirection="column" component="li" key={option.symbol} {...props}>
              <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Typography noWrap>{option.ticker}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: Colors.anotherGrayShade }} align="right" noWrap>
                    {categoryText}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={{ color: Colors.anotherGrayShade }} noWrap>
                    {getCurrentIsoLang() === 'en' ? option.nameEn : option.nameFr}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ color: Colors.anotherGrayShade }} align="right" noWrap>
                    {option.exchange === 'fund' ? categoryText : option.exchange}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={marketDataState.securitySearchTerm}
            placeholder={i18n.t('marketsPage.quoteFinder')}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start" sx={{ marginRight: '0px' }}>
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    marketDataState: state.marketData,
  };
}

export default connect(mapStateToProps)(SecurityAutocomplete);
