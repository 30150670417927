import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import i18n from '~/app/i18n';
import { Table, Checkbox } from 'antd';
import type { DataNode } from 'antd/es/tree';
import {
  IRegisteredAccount,
  RegisteredAccountTypeCode,
  RegisteredAccountGroup,
  IAccount,
} from '~/stores/account/accountTypes';
import { fetchRegisteredAccounts } from '~/stores/account/actions/accountActions';
import {
  getRegisteredAccounts,
  getIsRegisteredAccountFetching,
  getIsAccountsFetching,
  getIsRegisteredAccountFetchingFail,
  getFilteredRegisterAccountIds,
  getAllAvailableAccounts,
  getAccountIds,
} from '~/stores/account/selectors/accountSelectors';
import { IAppRootState } from '~/app/rootReducer';
import memoizeOne from 'memoize-one';
import {
  MainAccountContainer,
  AccountHeaderContainer,
  AccountDisplaySummaryContainer,
  AccountSubHeaderContainer,
} from '~/common/components/account/account-style';
import {
  Container,
  PageTitleContainer,
  ContainerRow,
  HeaderPageContainer,
  HeaderSmall,
  LabelBold,
  FilterContainer,
  IAButtonSelect,
} from '~/common/styles/baseStyle';
import AccountDisplaySummary from '~/common/components/account/account-display-summary/accountDisplaySummary';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import DateFormat from '~/common/components/date-format/DateFormat';
import { ITransaction } from '~/stores/transaction/transactionTypes';
import AccountFilter from '~/common/components/account/HouseholdAccountFilter';
import moment from 'moment';
import NoData from '~/common/components/noData/NoData';
import { Popper, ClickAwayListener, Typography } from '@mui/material';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { MonetaryValue } from '~/common/components';
import MultiSelect, { IOption } from '~/pages/history-page/MultiSelect';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { refreshSession } from '~/stores/system/actions/systemActions';
import { getSelectedAccountIds, filterRegisteredAccounts } from '~/common/accountsHelpers';
import PrintButton from '~/common/components/print-button/PrintButton';
import Loading from '../../common/components/Loading/Loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ContainerFilter = styled.div`
  font-family: 'Montserrat';
  box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.35);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  background-color: #fff;
  max-height: 450px;
  overflow: auto;
`;

const RootAccountsContainer = styled.div`
  & > div {
    margin-bottom: 50px;
  }
`;
const AccountSummary = styled.div`
  display: flex;
  flex: 1;
`;

const RegistreAccountInfoItemContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  width: auto;
`;

const AccountInfoItemContainerLabel = styled.div`
  padding-right: 5px;
`;

const AccountInfoItemContainerValue = styled.div`
  white-space: nowrap;
`;

interface IPropsFromState {
  party: IPartyV1;
  accounts: IAccount[];
  accountIds: string[];
  isAccountFetching: boolean;
  isRegisteredAccountFetching: boolean;
  isRegisteredAccountFetchingFail: boolean;
  registeredAccounts: IRegisteredAccount[];
  filteredRegisterAccountIds: string[];
  culture: string;
}

interface IPropsFromDispatch {
  fetchRegisteredAccounts: typeof fetchRegisteredAccounts;
  refreshSession: typeof refreshSession;
}

interface IRegisteredAccountView {
  registerType: string[];
  yearSelected: number;
  isYearOpen: boolean;
  isFirstOpen: boolean;
  anchorYear: any;
}

interface IRrspTypeRowData {
  period: string;
  contribution: any;
  withdrawal: any;
  spousalContribution: any;
  transactions: ITransaction[];
  isSpousal: boolean;
}

interface ITfsaTypeRowData {
  period: any;
  contribution: any;
  withdrawal: any;
  grant: any;
  transactions: ITransaction[];
}

interface IBeneficiaryRowData {
  contributionPourcent: any;
  beneficiaryType: any;
  name: any;
  address: any;
}

interface IRrifTypeRowData {
  frequency: string;
  electedPayment: any;
  cumulativePayments: any;
  cumulativeTaxes: any;
  withholdingTaxPercentFederal: any;
  withholdingTaxPercentProvincial: any;
  transactions: ITransaction[];
}
interface IFhsaTypeRowData {
  period: any;
  contribution: any;
  withdrawal: any;
  transactions: ITransaction[];
}

export type align = 'right' | 'left';

class RegisteredAccountPage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IRegisteredAccountView> {
  constructor(props: any) {
    super(props);
    this.props.refreshSession();
    document.title = i18n.t('registeredAccount.documentTitle');

    this.state = {
      registerType: [],
      yearSelected: this.currentYear,
      isYearOpen: false,
      isFirstOpen: false,
      anchorYear: null,
    };
  }

  private fetchData = (year: number) => {
    const { accounts } = this.props;
    this.props.fetchRegisteredAccounts(getSelectedAccountIds([], accounts), year);
  };

  public switchYear = (event: any) => {
    this.props.refreshSession();
    this.setState({ yearSelected: event.target.value, isYearOpen: false });
    this.fetchData(Number(event.target.value));
  };

  public onChangeRegisterType = (accountType: string[]) => {
    this.props.refreshSession();
    this.setState({ registerType: accountType });
  };

  private isInitFetchData = false;
  private currentYear: number = new Date().getFullYear();

  private GetDecimalElement(val?: number) {
    return <DecimalValue value={val || 0} culture={this.props.culture} amountOfDigits={2} areTrailingZerosVisible />;
  }

  private tableRowsDataRrps = memoizeOne((registeredAccount: IRegisteredAccount): IRrspTypeRowData[] => {
    const rows = [] as IRrspTypeRowData[];

    rows.push({
      key: `first60Days_${registeredAccount.account.id}`,
      period: `${i18n.t('registeredAccount.first60days')} ${registeredAccount.year}`,
      contribution: this.GetDecimalElement(registeredAccount.summary.first60DaysContributions),
      withdrawal: this.GetDecimalElement(registeredAccount.summary.first60DaysWithdrawal),
      spousalContribution: this.GetDecimalElement(registeredAccount.summary.first60DaysSpousalContributions),
      transactions: this.filterRrspTransactionPeriod(registeredAccount.transactions, true),
      isSpousal: this.isRrspSpousalAccount(registeredAccount),
    } as IRrspTypeRowData);

    rows.push({
      key: `remainder_${registeredAccount.account.id}`,
      period: `${i18n.t('registeredAccount.remainder')} ${registeredAccount.year}`,
      contribution: this.GetDecimalElement(registeredAccount.summary.remainderContributions),
      withdrawal: this.GetDecimalElement(registeredAccount.summary.remainderWithdrawal),
      spousalContribution: this.GetDecimalElement(registeredAccount.summary.remainderSpousalContributions),
      transactions: this.filterRrspTransactionPeriod(registeredAccount.transactions, false),
      isSpousal: this.isRrspSpousalAccount(registeredAccount),
    } as IRrspTypeRowData);

    return rows;
  });

  private filterRrspTransactionPeriod = (transactions: ITransaction[], first60days: boolean): ITransaction[] => {
    if (transactions && transactions.length > 0) {
      const transactionDate: Date = new Date(transactions[0].tradeDate);
      const first60Days = moment(new Date(transactionDate.getFullYear(), 0, 1)).add(60, 'days').format();
      return first60days
        ? transactions.filter((a) => a.tradeDate < first60Days)
        : transactions.filter((a) => a.tradeDate >= first60Days);
    }

    return transactions;
  };

  private tableRowsDataRrif = memoizeOne((registeredAccount: IRegisteredAccount): IRrifTypeRowData[] => {
    const rows = [] as IRrifTypeRowData[];

    const { payment } = registeredAccount.summary;

    rows.push({
      key: `riff_${registeredAccount.account.id}`,
      frequency: payment ? payment.frequency : 'N/A',
      electedPayment: payment ? this.GetDecimalElement(payment.electedPayment) : 'N/A',
      cumulativePayments: payment ? this.GetDecimalElement(payment.cumulativePayments) : 'N/A',
      cumulativeTaxes: payment ? this.GetDecimalElement(payment.cumulativeTaxes) : 'N/A',
      withholdingTaxPercentFederal: payment ? this.GetDecimalElement(payment.withholdingTaxPercentFederal) : 'N/A',
      withholdingTaxPercentProvincial: payment
        ? this.GetDecimalElement(payment.withholdingTaxPercentProvincial)
        : 'N/A',
      transactions: registeredAccount.transactions,
    } as IRrifTypeRowData);

    return rows;
  });

  private tableRowsDataTfsa = memoizeOne((registeredAccount: IRegisteredAccount): ITfsaTypeRowData[] => {
    const rows = [] as ITfsaTypeRowData[];
    rows.push({
      key: `summary_${registeredAccount.account.id}`,
      period: registeredAccount.year,
      contribution: this.GetDecimalElement(registeredAccount.summary.totalContributions),
      withdrawal: this.GetDecimalElement(registeredAccount.summary.totalWithdrawal),
      grant: this.GetDecimalElement(registeredAccount.summary.totalGrant),
      transactions: registeredAccount.transactions,
    } as ITfsaTypeRowData);

    return rows;
  });
  private tableRowsDataFhsa = memoizeOne((registeredAccount: IRegisteredAccount): IFhsaTypeRowData[] => {
    const rows = [] as IFhsaTypeRowData[];
    rows.push({
      key: `summary_${registeredAccount.account.id}`,
      period: registeredAccount.year,
      contribution: this.GetDecimalElement(registeredAccount.summary.totalContributions),
      withdrawal: this.GetDecimalElement(registeredAccount.summary.totalWithdrawal),
      transactions: registeredAccount.transactions,
    } as IFhsaTypeRowData);

    return rows;
  });

  private isRrspSpousalAccount = (registeredAccount: IRegisteredAccount): boolean =>
    registeredAccount.registeredAccountType === RegisteredAccountTypeCode.RRSP_SPOUSAL_CAD ||
    registeredAccount.registeredAccountType === RegisteredAccountTypeCode.RRSP_SPOUSAL_USD;

  private showSpousalName = (registeredAccount: IRegisteredAccount): boolean =>
    this.isRrspSpousalAccount(registeredAccount) ||
    registeredAccount.registeredAccountType === RegisteredAccountTypeCode.RRIF_SPOUSAL_CAD;

  private showJurisdiction = (registeredAccount: IRegisteredAccount): boolean =>
    registeredAccount.registeredAccountType.startsWith(RegisteredAccountTypeCode.LIRA) ||
    registeredAccount.registeredAccountType.startsWith(RegisteredAccountTypeCode.LRSP) ||
    registeredAccount.registeredAccountGroup === RegisteredAccountGroup.LIF_LRIF;

  private footerRrspTable = (rows: IRrspTypeRowData[]) => {
    const contribution = rows.map((m) => m.contribution.props.value).reduce((a, b) => a + b, 0);
    const withdrawal = rows.map((m) => m.withdrawal.props.value).reduce((a, b) => a + b, 0);
    const spousalContribution = rows.map((m) => m.spousalContribution.props.value).reduce((a, b) => a + b, 0);
    const { isSpousal } = rows[0];

    return (
      <Grid fluid style={{ width: '100%', borderBottom: '2px solid #003DA5', padding: 4 }}>
        <Row between="xs" style={{ height: 30 }}>
          <Col xs={isSpousal ? 3 : 6}>
            <LabelBold>Total {this.state.yearSelected} </LabelBold>
          </Col>
          <Col xs={3} style={{ paddingRight: isSpousal ? 28 : 40 }}>
            <LabelBold>
              <MonetaryValue value={contribution} culture={this.props.culture} />
            </LabelBold>
          </Col>
          {isSpousal ? (
            <Col xs={3} style={{ paddingRight: 40 }}>
              <LabelBold>
                <MonetaryValue value={spousalContribution} culture={this.props.culture} />
              </LabelBold>
            </Col>
          ) : null}
          <Col xs={3} style={{ paddingRight: 52 }}>
            <LabelBold>
              <MonetaryValue value={withdrawal} culture={this.props.culture} />
            </LabelBold>
          </Col>
        </Row>
      </Grid>
    );
  };

  private renderAccountRrspTable = (registeredAccount: IRegisteredAccount) => {
    const data = this.tableRowsDataRrps(registeredAccount);

    const columns = [
      {
        title: i18n.t('registeredAccount.period'),
        dataIndex: 'period',
        width: this.isRrspSpousalAccount(registeredAccount) ? '24%' : '48%',
      },
      {
        title: i18n.t('registeredAccount.contributions'),
        dataIndex: 'contribution',
        width: '24%',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.withdrawal'),
        dataIndex: 'withdrawal',
        width: '24%',
        align: 'right' as align,
      },
      {
        title: '',
        width: '20px',
        align: 'right' as align,
      },
    ];

    if (this.isRrspSpousalAccount(registeredAccount)) {
      columns.splice(2, 0, {
        title: i18n.t('registeredAccount.spousalContributions'),
        align: 'right' as align,
        dataIndex: 'spousalContribution',
        width: '24%',
      });
    }

    return (
      <div style={{ width: '100%' }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
              expanded ? (
                <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
              ) : (
                <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
              ),
          }}
          expandedRowRender={this.onDetailClick}
          expandIconColumnIndex={this.isRrspSpousalAccount(registeredAccount) ? 4 : 3}
        />
        {this.footerRrspTable(data)}
      </div>
    );
  };

  private renderAccountRrifTable = (registeredAccount: IRegisteredAccount) => {
    const data = this.tableRowsDataRrif(registeredAccount);

    const columns = [
      {
        title: i18n.t('common.frequency'),
        dataIndex: 'frequency',
        width: '150px',
      },
      {
        title: i18n.t('registeredAccount.electedPayment'),
        dataIndex: 'electedPayment',
        width: '220px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.cumulativePayments'),
        dataIndex: 'cumulativePayments',
        width: '150px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.withholdingTaxPercentProvincial'),
        dataIndex: 'withholdingTaxPercentProvincial',
        width: '125px',
        align: 'right' as align,
      },
      {
        title: <Trans i18nKey="registeredAccount.withholdingTaxPercentFederal" />,
        dataIndex: 'withholdingTaxPercentFederal',
        width: '120px',
        align: 'right' as align,
      },
      {
        title: <Trans i18nKey="registeredAccount.cumulativeTaxes" />,
        dataIndex: 'cumulativeTaxes',
        width: '150px',
        align: 'right' as align,
      },
      {
        title: '',
        width: '20px',
        align: 'right' as align,
      },
    ];

    return (
      <Table
        className="bottomBlue"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
            expanded ? (
              <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
            ),
        }}
        expandedRowRender={this.onDetailRrifClick}
        expandIconColumnIndex={6}
      />
    );
  };

  private renderAccountTfsaTable = (registeredAccount: IRegisteredAccount) => {
    const data = this.tableRowsDataTfsa(registeredAccount);

    const columns = [
      {
        title: i18n.t('registeredAccount.period'),
        dataIndex: 'period',
        width: '300px',
      },
      {
        title: i18n.t('registeredAccount.contributions'),
        dataIndex: 'contribution',
        width: '200px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.withdrawal'),
        dataIndex: 'withdrawal',
        width: '180px',
        align: 'right' as align,
      },
      {
        title: '',
        width: '20px',
        align: 'right' as align,
      },
    ];
    const sortText = i18n.t('common.sort');

    return (
      <Table
        className="bottomBlue"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
            expanded ? (
              <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
            ),
        }}
        expandedRowRender={this.onDetailClick}
        expandIconColumnIndex={3}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
      />
    );
  };
  private renderAccountFhsaTable = (registeredAccount: IRegisteredAccount) => {
    const data = this.tableRowsDataFhsa(registeredAccount);

    const columns = [
      {
        title: i18n.t('registeredAccount.period'),
        dataIndex: 'period',
        width: '300px',
      },
      {
        title: i18n.t('registeredAccount.contributions'),
        dataIndex: 'contribution',
        width: '200px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.withdrawal'),
        dataIndex: 'withdrawal',
        width: '180px',
        align: 'right' as align,
      },
      {
        title: '',
        width: '20px',
        align: 'right' as align,
      },
    ];
    const sortText = i18n.t('common.sort');

    return (
      <Table
        className="bottomBlue"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
            expanded ? (
              <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
            ),
        }}
        expandedRowRender={this.onDetailClick}
        expandIconColumnIndex={3}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
      />
    );
  };
  private renderAccountRdspTable = (registeredAccount: IRegisteredAccount) => {
    const data = this.tableRowsDataTfsa(registeredAccount);

    const columns = [
      {
        title: i18n.t('registeredAccount.period'),
        dataIndex: 'period',
        width: '100px',
      },
      {
        title: i18n.t('registeredAccount.contributions'),
        dataIndex: 'contribution',
        width: '200px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.grants'),
        dataIndex: 'grant',
        width: '200px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.withdrawal'),
        dataIndex: 'withdrawal',
        width: '180px',
        align: 'right' as align,
      },
      {
        title: '',
        width: '20px',
        align: 'right' as align,
      },
    ];

    return (
      <Table
        className="bottomBlue"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
            expanded ? (
              <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
            ),
        }}
        expandedRowRender={this.onDetailClick}
        expandIconColumnIndex={4}
      />
    );
  };

  private renderBeneficiaryTable = (registeredAccount: IRegisteredAccount) => {
    const data = [] as IBeneficiaryRowData[];
    moment.locale(i18n.language);
    if (registeredAccount.summary && registeredAccount.summary.beneficiaries) {
      registeredAccount.summary.beneficiaries.forEach((beneficiary, index) => {
        data.push({
          key: `summary_${index}_${registeredAccount.account.id}`,
          contributionPourcent: `${beneficiary.contributionPourcent} %`,
          beneficiaryType: beneficiary.beneficiaryType,
          name: `${beneficiary.firstName} ${beneficiary.lastName}`,
          birthDate: moment(beneficiary.birthDate).format(i18n.language === 'fr-CA' ? 'DD/MM/YYYY' : 'YYYY/MM/DD'),
          address: `${beneficiary.address} ${beneficiary.address2} ${beneficiary.city} ${beneficiary.province}`,
        } as IBeneficiaryRowData);
      });
    }

    const columns = [
      {
        title: i18n.t('registeredAccount.beneficiaryInfo'),
        dataIndex: 'name',
        width: '200px',
      },
      {
        title: i18n.t('registeredAccount.birthDate'),
        dataIndex: 'birthDate',
        width: '100px',
      },
      {
        title: i18n.t('registeredAccount.address'),
        dataIndex: 'address',
        width: '300px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.relation'),
        dataIndex: 'beneficiaryType',
        width: '100px',
        align: 'right' as align,
      },
      {
        title: i18n.t('registeredAccount.percentages'),
        dataIndex: 'contributionPourcent',
        width: '100px',
        align: 'right' as align,
      },
    ];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  public onDetailClick = (record: any, index: number, indent: number, expanded: boolean) =>
    this.renderHistory(record.transactions);

  public onDetailRrifClick = (record: any, index: number, indent: number, expanded: boolean) =>
    this.renderHistoryRrif(record.transactions);

  private renderTransaction = (transaction: ITransaction) => (
    <Row between="xs">
      <Col xs={5}>{transaction.operationDescription}</Col>
      <Col xs={2}>
        <DateFormat value={transaction.tradeDate} />
      </Col>
      <Col xs={2}>
        <DateFormat value={transaction.settlementDate} />
      </Col>
      <Col xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.GetDecimalElement(transaction.net)}
      </Col>
    </Row>
  );

  private renderHistory = (transactions: ITransaction[]) => {
    if (transactions.length > 0) {
      return (
        <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
          <Row>
            <Col xs={12}>
              <Trans i18nKey="common.history" />
            </Col>
          </Row>
          <Row between="xs" style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)', padding: 2 }}>
            <Col xs={5}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.operation" />{' '}
              </HeaderSmall>
            </Col>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.tradeDate" />
              </HeaderSmall>
            </Col>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.settlementDate" />
              </HeaderSmall>
            </Col>
            <Col xs={3}>
              <HeaderSmall style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Trans i18nKey="registeredAccount.amount" />
              </HeaderSmall>
            </Col>
          </Row>
          {transactions.map((item) => this.renderTransaction(item))}
        </Grid>
      );
    }

    return <NoData text={i18n.t('common.noData')} />;
  };

  private renderTransactionRrif = (transaction: ITransaction) => (
    <Row between="xs">
      <Col xs={2}>{transaction.operationDescription}</Col>
      <Col xs={2}>
        <DateFormat value={transaction.tradeDate} />
      </Col>
      <Col xs={2}>
        <DateFormat value={transaction.settlementDate} />
      </Col>
      <Col xs={2}>{this.GetDecimalElement(transaction.grossAmount)}</Col>
      <Col xs={1}>{this.GetDecimalElement(transaction.withholdingTaxProvincial)}</Col>
      <Col xs={1}>{this.GetDecimalElement(transaction.withholdingTaxFederal)}</Col>

      <Col xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.GetDecimalElement(transaction.net)}
      </Col>
    </Row>
  );

  private renderHistoryRrif = (transactions: ITransaction[]) => {
    if (transactions.length > 0) {
      return (
        <Grid fluid style={{ width: '100%', backgroundColor: '#EFF1F4', padding: 20 }}>
          <Row>
            <Col xs={12}>
              <Trans i18nKey="common.history" />
            </Col>
          </Row>
          <Row between="xs" style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)', padding: 2 }}>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.operation" />{' '}
              </HeaderSmall>
            </Col>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.tradeDate" />
              </HeaderSmall>
            </Col>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.settlementDate" />
              </HeaderSmall>
            </Col>
            <Col xs={2}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.grossAmount" />
              </HeaderSmall>
            </Col>
            <Col xs={1}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.provincialTax" />
              </HeaderSmall>
            </Col>
            <Col xs={1}>
              <HeaderSmall>
                <Trans i18nKey="registeredAccount.federalTax" />
              </HeaderSmall>
            </Col>

            <Col xs={2}>
              <HeaderSmall style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Trans i18nKey="registeredAccount.amount" />
              </HeaderSmall>
            </Col>
          </Row>
          {transactions.map((item) => this.renderTransactionRrif(item))}
        </Grid>
      );
    }

    return <NoData text={i18n.t('common.noData')} />;
  };

  private renderRegistredAccount = (registeredAccount: IRegisteredAccount) => {
    const { accounts } = this.props;
    const currentAccount = accounts.filter((w) => w.id === registeredAccount.account.id)[0];

    return (
      <MainAccountContainer key={registeredAccount.account.id}>
        <AccountHeaderContainer>
          <AccountDisplaySummary account={currentAccount} />
          <AccountDisplaySummaryContainer>
            <RegistreAccountInfoItemContainer>
              <Typography variant="body2" component="div">
                <Trans i18nKey="registeredAccount.beneficiary" />
                :&nbsp;
              </Typography>
              <AccountInfoItemContainerValue>
                <Typography variant="body1" component="div">
                  {this.getbeneficiaryDisplay(registeredAccount)}
                </Typography>
              </AccountInfoItemContainerValue>
            </RegistreAccountInfoItemContainer>
            {this.showSpousalName(registeredAccount) ? (
              <RegistreAccountInfoItemContainer>
                <Typography variant="body2" component="div">
                  <Trans i18nKey="registeredAccount.spouse" />
                  :&nbsp;
                </Typography>
                <AccountInfoItemContainerValue>
                  <Typography variant="body1" component="div">
                    {registeredAccount.account.spouseName}
                  </Typography>
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
            ) : null}
            {this.showJurisdiction(registeredAccount) ? (
              <RegistreAccountInfoItemContainer>
                <Typography variant="body2" component="div">
                  <Trans i18nKey="registeredAccount.jurisdiction" />
                  :&nbsp;
                </Typography>
                <AccountInfoItemContainerValue>
                  <Typography variant="body1" component="div">
                    {registeredAccount.jurisdiction}
                  </Typography>
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
            ) : null}
          </AccountDisplaySummaryContainer>
        </AccountHeaderContainer>
        {(registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RRIF ||
          registeredAccount.registeredAccountGroup === RegisteredAccountGroup.LIF_LRIF) &&
        this.state.yearSelected === this.currentYear
          ? this.renderRrifHeader(registeredAccount, registeredAccount.registeredAccountGroup)
          : null}
        {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RESP ||
        registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RDSP
          ? this.renderBeneficiaryTable(registeredAccount)
          : null}
        <AccountSummary>
          <>
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RRSP
              ? this.renderAccountRrspTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RRIF
              ? this.renderAccountRrifTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.LIF_LRIF
              ? this.renderAccountRrifTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.TFSA
              ? this.renderAccountTfsaTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RESP
              ? this.renderAccountTfsaTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RDSP
              ? this.renderAccountRdspTable(registeredAccount)
              : null}
            {registeredAccount.registeredAccountGroup === RegisteredAccountGroup.FHSA
              ? this.renderAccountFhsaTable(registeredAccount)
              : null}
          </>
        </AccountSummary>
      </MainAccountContainer>
    );
  };

  private getbeneficiaryDisplay = (registeredAccount: IRegisteredAccount): string => {
    if (
      registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RESP ||
      registeredAccount.registeredAccountGroup === RegisteredAccountGroup.RDSP
    ) {
      return registeredAccount.summary.beneficiaryCategory;
    }

    return registeredAccount.account.beneficiary
      ? registeredAccount.account.beneficiary
      : i18n.t('registeredAccount.noBeneficiary');
  };

  private renderRrifHeader = (registeredAccount: IRegisteredAccount, accountGroup: string) => {
    const { payment } = registeredAccount.summary;
    return (
      <Typography variant="body1">
        <AccountSubHeaderContainer>
          <AccountHeaderContainer>
            <AccountDisplaySummaryContainer>
              <RegistreAccountInfoItemContainer>
                <AccountInfoItemContainerLabel>
                  <Trans i18nKey="registeredAccount.minAnnualWithdrawal" />:
                </AccountInfoItemContainerLabel>
                <AccountInfoItemContainerValue>
                  {registeredAccount.summary.minWithdrawal
                    ? this.GetDecimalElement(registeredAccount.summary.minWithdrawal)
                    : 'N/A'}
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
              {accountGroup === RegisteredAccountGroup.LIF_LRIF && (
                <RegistreAccountInfoItemContainer>
                  <AccountInfoItemContainerLabel>
                    <Trans i18nKey="registeredAccount.maxWithdrawal" />:{' '}
                  </AccountInfoItemContainerLabel>
                  <AccountInfoItemContainerValue>
                    {registeredAccount.summary.maxWithdrawal
                      ? this.GetDecimalElement(registeredAccount.summary.maxWithdrawal)
                      : 'N/A'}{' '}
                  </AccountInfoItemContainerValue>
                </RegistreAccountInfoItemContainer>
              )}
              <RegistreAccountInfoItemContainer>
                <AccountInfoItemContainerLabel>
                  <Trans i18nKey="registeredAccount.endOfYearAge" /> {registeredAccount.summary.endOfYear}:{' '}
                </AccountInfoItemContainerLabel>
                <AccountInfoItemContainerValue>
                  {registeredAccount.summary.endOfYearAge} <Trans i18nKey="registeredAccount.age" />
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
            </AccountDisplaySummaryContainer>
            <AccountDisplaySummaryContainer>
              <RegistreAccountInfoItemContainer>
                <AccountInfoItemContainerLabel>
                  <Trans i18nKey="registeredAccount.endOfYearMarketValue" /> {registeredAccount.summary.endOfYear}:
                </AccountInfoItemContainerLabel>
                <AccountInfoItemContainerValue>
                  {this.GetDecimalElement(registeredAccount.summary.marketValue)}
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
              <RegistreAccountInfoItemContainer>
                <AccountInfoItemContainerLabel>
                  <Trans i18nKey="registeredAccount.firstPaymentDate" />:
                </AccountInfoItemContainerLabel>
                <AccountInfoItemContainerValue>
                  {payment ? <DateFormat value={payment.firstPaymentDate} /> : null}
                </AccountInfoItemContainerValue>
              </RegistreAccountInfoItemContainer>
              {accountGroup === RegisteredAccountGroup.LIF_LRIF && (
                <RegistreAccountInfoItemContainer>&nbsp;</RegistreAccountInfoItemContainer>
              )}
            </AccountDisplaySummaryContainer>
          </AccountHeaderContainer>
        </AccountSubHeaderContainer>
      </Typography>
    );
  };

  private filterAccount = () => {
    const { registeredAccounts, filteredRegisterAccountIds, accountIds } = this.props;
    const { registerType } = this.state;

    if (registeredAccounts === undefined) {
      return [] as IRegisteredAccount[];
    }

    const registeredAccountsSelectedInFilter = filterRegisteredAccounts(
      filteredRegisterAccountIds,
      accountIds,
      registeredAccounts,
    );

    const registeredAccountList: IRegisteredAccount[] = [];

    if (registerType.length > 0 && registerType[0] !== 'All') {
      registeredAccountsSelectedInFilter.forEach((f) => {
        const typeAccount = f.registeredAccountType.replace('_CAD', '').replace('_USD', '');
        if (registerType.indexOf(typeAccount) >= 0) {
          registeredAccountList.push(f);
        }
      });
      return registeredAccountList;
    }
    return registeredAccountsSelectedInFilter;
  };

  private renderTreeNodes = (data: any[]) => {
    const result: DataNode[] = data.map((item) => {
      if (item.children) {
        return {
          expanded: true,
          title: item.title,
          key: item.key,
          dataRef: item,
          disableCheckbox: item.disableCheckbox,
          children: this.renderTreeNodes(item.children),
        } as DataNode;
      }
      return { key: item.key, ...item } as DataNode;
    });

    return result;
  };

  private getRegisterType = () => {
    const { registeredAccounts, filteredRegisterAccountIds, accountIds } = this.props;
    const accountsTreeData: any[] = [];
    if (registeredAccounts !== undefined) {
      const registeredAccountsSelectedInFilter = filterRegisteredAccounts(
        filteredRegisterAccountIds,
        accountIds,
        registeredAccounts,
      );
      const registerTypes = registeredAccountsSelectedInFilter
        .map((x) => x.registeredAccountType.replace('_CAD', '').replace('_USD', ''))
        .filter((value, index, self) => self.indexOf(value) === index);
      registerTypes.forEach((t) => {
        accountsTreeData.push({ text: i18n.t(`typeAccount.${t}`), key: t, id: t });
      });
    }

    const treeData = [
      {
        text: i18n.t('common.filter_Choice_All'),
        childOptions: accountsTreeData,
        id: 'all',
      },
    ];
    return treeData as IOption[];
  };

  private handleFilterYearClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ isYearOpen: !this.state.isYearOpen, isFirstOpen: true, anchorYear: event.currentTarget });
  };

  private handleClickAway = () => {
    if (!this.state.isFirstOpen) {
      this.setState({ isYearOpen: false });
    } else {
      this.setState({ isFirstOpen: false });
    }
  };
  public render() {
    const { isRegisteredAccountFetching, isRegisteredAccountFetchingFail, isAccountFetching } = this.props;

    if (isRegisteredAccountFetching) {
      return <Loading show />;
    }
    if (isAccountFetching) {
      this.isInitFetchData = false;
      return <Loading show />;
    }

    if (!this.isInitFetchData) {
      this.isInitFetchData = true;
      this.fetchData(this.currentYear);
    }
    const idYear = this.state.isYearOpen ? 'popperYear' : undefined;

    const registeredAccountList = this.filterAccount();
    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            <Typography variant="h1" component="div" style={{ paddingBottom: '12px' }}>
              <Trans i18nKey="common.RegisteredAccount" />
            </Typography>
            <DataLoadStamp isPreviousDay />
          </PageTitleContainer>

          <PageTitleContainer>
            <FilterContainer>
              <IAButtonSelect
                onClick={this.handleFilterYearClick}
                variant="outlined"
                endIcon={
                  this.state.isYearOpen ? (
                    <ExpandLessIcon htmlColor="#003da5" />
                  ) : (
                    <ExpandMoreIcon htmlColor="#003da5" />
                  )
                }
              >
                {' '}
                {this.state.yearSelected}
              </IAButtonSelect>
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Popper
                  id={idYear}
                  open={this.state.isYearOpen}
                  anchorEl={this.state.anchorYear}
                  placement="bottom-end"
                  disablePortal={false}
                  modifiers={[
                    { name: 'flip', enabled: true },
                    { name: 'preventOverflow', enabled: true, options: { boundariesElement: 'scrollParent' } },
                  ]}
                >
                  <ContainerFilter>
                    <ContainerRow style={{ padding: 5 }}>
                      <Checkbox
                        checked={this.state.yearSelected === this.currentYear}
                        onChange={this.switchYear}
                        value={this.currentYear}
                      >
                        {this.currentYear}
                      </Checkbox>
                    </ContainerRow>
                    <ContainerRow style={{ padding: 5 }}>
                      <Checkbox
                        checked={this.state.yearSelected === this.currentYear - 1}
                        onChange={this.switchYear}
                        value={this.currentYear - 1}
                      >
                        {this.currentYear - 1}
                      </Checkbox>
                    </ContainerRow>
                  </ContainerFilter>
                </Popper>
              </ClickAwayListener>
              <MultiSelect
                label="Type"
                options={this.getRegisterType()}
                selectedOptionIds={this.state.registerType}
                onOptionSelected={this.onChangeRegisterType}
              />

              <AccountFilter isRegisterAccountFilter />
            </FilterContainer>
          </PageTitleContainer>
        </HeaderPageContainer>

        {registeredAccountList.length === 0 && <NoData text={i18n.t('registeredAccount.noRegisterAccount')} />}

        <RootAccountsContainer>
          {registeredAccountList.map((x) => this.renderRegistredAccount(x))}
        </RootAccountsContainer>
        {!isRegisteredAccountFetchingFail && registeredAccountList.length > 0 && (
          <Typography variant="body1">
            <Trans i18nKey="registeredAccount.disclaimer" />
          </Typography>
        )}
        <PrintButton />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    party: getPartyV1(state),
    filteredRegisterAccountIds: getFilteredRegisterAccountIds(state),
    accountIds: getAccountIds(state),
    registeredAccounts: getRegisteredAccounts(state),
    isAccountFetching: getIsAccountsFetching(state),
    accounts: getAllAvailableAccounts(state),
    isRegisteredAccountFetching: getIsRegisteredAccountFetching(state),
    isRegisteredAccountFetchingFail: getIsRegisteredAccountFetchingFail(state),
    culture: state.system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchRegisteredAccounts,
      refreshSession,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredAccountPage);
