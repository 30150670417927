import { HouseholdType } from '~/common/partyHelpers';
import { IPartyAccount } from '../party/partyTypes';
import { z } from 'zod';

export const ACCOUNT_SUBSECTION_ATTRIBUTE = 'ACCINFO_DETAILS';
export const kycReviewIdsSchema = z.array(z.string().min(1).uuid());
export type KycReviewIdsType = z.infer<typeof kycReviewIdsSchema>;
export interface IKYCState {
  readonly show: boolean;
  readonly showMenu: boolean;
  readonly showPromptLoaded: boolean;
  readonly showPromptFetching: boolean;
  readonly showPromptFail: boolean;
  readonly reviewIds: KycReviewIdsType;
  readonly reviewIdsDueToBeReminded: KycReviewIdsType;
  readonly retryCount: number;
  readonly reviewKYCDetails: KYCDetailsResponseType | undefined;
  readonly detailsFetching: boolean;
  readonly detailsLoaded: boolean;
  readonly detailsFail: boolean;
  readonly isSavingRemindLater: boolean;
  readonly isSavingRemindLaterSuccess: boolean;
  readonly isSavingRemindLaterFail: boolean;
  readonly isSavingKYCDetails: boolean;
  // We're using permanent storage because redux state is lost on page refresh
  // readonly saveKYCDetailsSuccess: boolean;
  // readonly saveKYCDetailsNoLongerNeeded: boolean;
  readonly saveKYCDetailsFail: boolean;
}

/** Base schema/Types */
export type KYCClientReferenceSchemaType = z.infer<typeof KYCClientReferenceSchema>;

export const baseAnswerSchema = z
  // Simple string question
  .string()
  // Dynamic key/value object list
  .or(z.record(z.string(), z.string().nullish()))
  .nullish();

export const KYCDefinitionsSchema = z
  .array(
    z.object({
      title: z.string(),
      content: z.string(),
    }),
  )
  .default([])
  .nullish();

export const baseHasChangedSchema = z.string().default('');

/** Status Schema/Types */
export const KYCClientReferenceSchema = z.object({
  clientId: z.string(),
  noRole: z.string(),
  role: z.string(),
});

export type KYCStatusFetchSchemaType = z.infer<typeof KYCStatusFetchSchema>;

export const KYCStatusFetchSchema = z.object({
  kycReviewIds: kycReviewIdsSchema,
  kycReviewIdsDueToBeReminded: kycReviewIdsSchema,
  retryCount: z.number(),
});

/** Get Questionnaire */
const questionSchema = z.object({
  title: z.string(),
  attribute: z.string(),
  hasChanged: baseHasChangedSchema,
  definitions: KYCDefinitionsSchema,
  isDefinitionCollapsed: z.boolean().default(true),
  answer: baseAnswerSchema,
});

// This uses ZOD schema validator to be able to validate at runtime level:
//   Check zod docs for more info.
export type KYCDetailsResponseType = z.infer<typeof KYCDetailsResponseSchema>;

export const KYCDetailsResponseSchema = z.object({
  reviewIds: kycReviewIdsSchema,
  sections: z.array(
    z.object({
      title: z.string().min(1),
      attribute: z.string(),
      subSections: z.array(
        z.object({
          title: z.string(),
          attribute: z.string(),
          identifier: z.string().nullish(),
          questionnaires: z.array(
            z.object({
              title: z.string(),
              questions: z.array(questionSchema),
            }),
          ),
        }),
      ),
    }),
  ),
});

/** Form Questionnaire Schema/Types */

export type KYCDetailsFormSchemaType = z.infer<typeof KYCDetailsFormSchema>;

const questionFormSchema = z.object({
  title: z.string(),
  attribute: z.string(),
  hasChanged: baseHasChangedSchema.refine(
    (value) => ['true', 'false'].includes(value),
    'changed must be boolean or empty',
  ),
  answer: baseAnswerSchema,
});

export const KYCDetailsFormSchema = z.object({
  reviewIds: kycReviewIdsSchema,
  sections: z.array(
    z.object({
      title: z.string().min(1),
      attribute: z.string(),
      subSections: z.array(
        z.object({
          title: z.string(),
          attribute: z.string(),
          identifier: z.string().nullish(),
          questionnaires: z.array(
            z.object({
              title: z.string(),
              questions: z.array(questionFormSchema),
            }),
          ),
        }),
      ),
    }),
  ),
});

/** Submit Schema/Types */

const questionSubmitSchema = z.object({
  attribute: z.string(),
  hasChanged: z.boolean(),
  answer: baseAnswerSchema,
});

export type KYCDetailsSubmitSchemaType = z.infer<typeof KYCDetailsSubmitSchema>;

export const KYCDetailsSubmitSchema = z.object({
  reviewIds: kycReviewIdsSchema,
  sections: z.array(
    z.object({
      attribute: z.string(),
      subSections: z.array(
        z.object({
          attribute: z.string(),
          identifier: z.string().nullish(),
          questionnaires: z.array(
            z.object({
              questions: z.array(questionSubmitSchema),
            }),
          ),
        }),
      ),
    }),
  ),
});

export class KYCGetStatusRequest {
  constructor(private accounts: IPartyAccount[]) {}
  getKYCAccounts = () =>
    this.accounts.filter((account) => account.role === HouseholdType.OWNER && [11, 21, 22].includes(account.noRole));
}

export const enum KYCActionTypes {
  KYC_STATUS_FETCH = '[KYC] Status Fetch',
  KYC_STATUS_FETCH_SUCCESSFUL = '[KYC] Status Fetch Successful',
  KYC_STATUS_FETCH_FAILED = '[KYC] Status Fetch Failed',

  KYC_REMIND_LATER = '[KYC] Remind Later',
  KYC_REMIND_LATER_SUCCESSFUL = '[KYC] Remind Later Successful',
  KYC_REMIND_LATER_FAILED = '[KYC] Remind Later Failed',

  KYC_DETAILS_FETCH = '[KYC] Details Fetch',
  KYC_DETAILS_FETCH_SUCCESSFUL = '[KYC] Details Fetch Successful',
  KYC_DETAILS_FETCH_FAILED = '[KYC] Details Fetch Failed',

  KYC_DETAILS_SAVE = '[KYC] Details Save',
  KYC_DETAILS_SAVE_SUCCESSFUL = '[KYC] Details Save Successful',
  KYC_DETAILS_SAVE_NO_LONGER_NEEDED = '[KYC] Details Save KYCs No Longer Needed',
  KYC_DETAILS_SAVE_FAILED = '[KYC] Details Save Failed',

  KYC_FORM_COMPLETED = '[KYC] Form completed',
  KYC_FORM_IGNORE_SESSION = '[KYC] Form Ignore Session',
}
