import { Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import styled from 'styled-components';

export const StyledSimpleTabs = withStyles(Tabs, {
  root: {
    color: '#003DA5',
  },
  indicator: {
    backgroundColor: '#003DA5',
  },
});

export const StyledSimpleTab = withStyles(Tab, {
  root: {
    padding: '15px 40px',
    marginRight: '10px',
    borderTop: '5px',
    fontSize: '14px',
    fontWeigth: 400,
    textTransform: 'none',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#836c4c',
    },
  },
  selected: {
    backgroundColor: '#ffffff',

  },
});

export const Frequency = styled.select`
    width: 150px;   
    padding: 5px 5px;
    border-bottom: 2px solid #c0c0c0;
    margin-top: 10px;
`;
