import { IAppRootState } from '~/app/rootReducer';
import { CurrencyEnum } from '~/common/types';
import { IAccount, IAccountDictionnary, IAccountState, IPosition } from '~/stores/account/accountTypes';
import { IPartyState } from '~/stores/party/partyTypes';
import { getOwnedAccountIdsFromPartyState } from '~/stores/party/selectors/partySelectors';
import { ISystemState } from '~/stores/system/systemTypes';
import moment from 'moment';
import { createSelector } from 'reselect';

const getAccountState = (state: IAppRootState) => state.account;
const getPartyState = (state: IAppRootState) => state.party;
const getSystemState = (state: IAppRootState) => state.system;

export const getAccountsFromState = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.clientAccounts.accounts,
);

export const getClientAccountsFromState = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.clientAccounts,
);

export const getSimplifyClientAccountsFromState = createSelector(getAccountState, (accountState: IAccountState) => {
  const { accounts, ...rest } = accountState.clientAccounts;
  return rest;
});

const getOpenAccounts = createSelector(getAccountState, (accountState: IAccountState) => {
  const { accounts } = accountState.clientAccounts;
  const openAccountDictionary: IAccountDictionnary = {};
  Object.keys(accounts).forEach((key) => {
    if (accounts[key].isOpen) {
      openAccountDictionary[key] = accounts[key];
    }
  });
  return openAccountDictionary;
});

export const getAllAvailableAccounts = createSelector(getAccountState, (accountState: IAccountState) => {
  const { accounts } = accountState.clientAccounts;
  return filterAccounts([], accounts);
});

export const getAccountIds = createSelector(
  getPartyState,
  getAllAvailableAccounts,
  (partyState: IPartyState, allAvailableAccounts: IAccount[]) => {
    const { partyV1, isHouseHoldingView } = partyState;
    return isHouseHoldingView && partyV1.preferedAccountIds && partyV1.preferedAccountIds.length > 0
      ? partyV1.preferedAccountIds
      : allAvailableAccounts.map((m) => m.id);
  },
);

export const getRepCodesOfOwnedAccounts = createSelector(
  getOpenAccounts,
  getOwnedAccountIdsFromPartyState,
  (openAccounts: IAccountDictionnary, ownedAccountsRepCodes: string[]) => {
    let repCodes: string[] = [];
    let results: string[] = [];

    Object.keys(openAccounts).forEach((key) => {
      const currentAccount = openAccounts[key];
      if (currentAccount.isOpen && ownedAccountsRepCodes.indexOf(currentAccount.id.substring(0, 6)) > -1) {
        repCodes = [...repCodes, currentAccount.repCode];
      }
    });

    const uniqueRepCodes = new Set(repCodes);
    for (const value of Array.from(uniqueRepCodes)) {
      results = [...results, value];
    }

    return results;
  },
);

export const getOpenOwnedAccounts = createSelector(
  getOpenAccounts,
  getOwnedAccountIdsFromPartyState,
  (openAccounts: IAccountDictionnary, ownedAccountsRepCodes: string[]) => {
    let accounts: IAccount[] = [];
    Object.keys(openAccounts).forEach((key) => {
      const currentAccount = openAccounts[key];
      if (currentAccount.isOpen && ownedAccountsRepCodes.indexOf(currentAccount.id.substring(0, 6)) > -1) {
        accounts = [...accounts, currentAccount];
      }
    });

    return accounts;
  },
);

export const getIsDataExpired = createSelector(
  getAccountState,
  (accountState: IAccountState) =>
    !accountState.dataExpiration || moment(accountState.dataExpiration).isBefore(moment()),
);

export const getFilteredAccountIds = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.filteredAccountIds,
);

export const getAllAccountsFiltered = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.allAccountsFiltered,
);

export const getFilteredRegisterAccountIds = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.filteredRegisterAccountIds,
);

export const getIsAccountsFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetching,
);

export const getIsAccountsFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingFail,
);

export const getAllCurrentFilterAccounts = createSelector(
  getAccountState,
  getAccountIds,
  (accountState: IAccountState, accountIds: string[]) => {
    const { accounts } = accountState.clientAccounts;
    return filterAccounts(accountIds, accounts);
  },
);

function filterAccounts(accountIds: string[], accounts: IAccountDictionnary) {
  let ids = [];
  if (accountIds.length > 0) {
    ids = accountIds;
  } else {
    for (const accountId in accounts) {
      if (accounts.hasOwnProperty(accountId)) {
        ids.push(accountId);
      }
    }
  }

  const allAccounts = ids.filter((selectedId) => accounts[selectedId]).map(mapAccounts(accounts));

  return allAccounts.sort(compareAccountsByAccountType);
}

export const getAccountsPositions = createSelector(
  getAllCurrentFilterAccounts,
  getAccountState,
  (accounts: IAccount[]) => {
    let positions = [] as IPosition[];

    accounts.forEach((account) => (positions = positions.concat(account.positions)));

    return positions;
  },
);

const mapAccounts = (accounts: IAccountDictionnary) => (selectedId: string) => accounts[selectedId];

const compareAccountsByAccountType = (a: IAccount, b: IAccount) => a.accountType.localeCompare(b.accountType);

export const getAllBookValueAccounts = createSelector(
  getAllCurrentFilterAccounts,
  getAccountState,
  (accounts: IAccount[]): IAccount[] => accounts.map(calculateBookValueTotals),
);

export const calculateBookValueTotals = (account: IAccount): IAccount => {
  const newAccount = { ...account };

  if (!account.positions || account.positions.length <= 0) {
    return newAccount;
  }

  newAccount.totalBookValue = calculateBookValueTotal(account.positions, account.currency as CurrencyEnum);

  const positionsUnrealizedGainAndLoss = account.positions.map((position) => position.unrealizedGainAndLoss || 0);
  newAccount.totalUnrealizedGainAndLoss =
    positionsUnrealizedGainAndLoss && positionsUnrealizedGainAndLoss.length > 0
      ? positionsUnrealizedGainAndLoss.reduce(
          (accumulator, unrealizedGainAndLoss) => accumulator + unrealizedGainAndLoss,
        )
      : 0;

  newAccount.totalUnrealizedGainAndLossPercent =
    positionsUnrealizedGainAndLoss && positionsUnrealizedGainAndLoss.length > 0
      ? +((newAccount.totalUnrealizedGainAndLoss / newAccount.totalBookValue) * 100).toFixed(2)
      : 0;

  return newAccount;
};

export const calculateBookValueTotal = (positions: IPosition[], accountCurrency: CurrencyEnum) => {
  let positionTotals = [] as number[];

  if (!positions || positions.length <= 0) {
    return 0;
  }

  switch (accountCurrency) {
    case CurrencyEnum.CAD:
      positionTotals = positions.map((position) => +position.bookValueCad.toFixed(2));
      break;
    case CurrencyEnum.USD:
      positionTotals = positions.map((position) => +position.bookValueUsd.toFixed(2));
      break;
  }

  const positionsTotal = positionTotals.reduce((accumulator, positionTotal) => accumulator + positionTotal);

  return +positionsTotal.toFixed(2);
};

export const getHouseholdTotalMarketValue = createSelector(getAccountState, (accountState: IAccountState) => {
  const { accounts } = accountState.clientAccounts;

  let householdTotalMarketValue = 0;
  for (const accountId in accounts) {
    if (accounts.hasOwnProperty(accountId)) {
      const account = accounts[accountId];
      householdTotalMarketValue = +(householdTotalMarketValue + account.totalMarketValueCad).toFixed(2);
    }
  }

  return householdTotalMarketValue;
});

export const getIsAssetsAllocationDataExpired = createSelector(
  getAccountState,
  (accountState: IAccountState) =>
    !accountState.assetsAllocationDataExpiration ||
    moment(accountState.assetsAllocationDataExpiration).isBefore(moment()),
);

export const getIsAssetsAllocationFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isAssetsAllocationFetching,
);

export const getAssetsAllocation = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.assetsAllocation,
);

export const getIsRegisteredAccountDataExpired = createSelector(
  getAccountState,
  (accountState: IAccountState) =>
    !accountState.dataRegisteredAccountExpiration ||
    moment(accountState.dataRegisteredAccountExpiration).isBefore(moment()),
);

export const getRegisteredAccountYear = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.yearRegisteredAccount,
);

export const getIsRegisteredAccountFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isRegisteredAccountFetching,
);

export const getIsRegisteredAccountFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isRegisteredAccountFetchingFail,
);

export const getRegisteredAccounts = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.registeredAccounts,
);

export const getNetInvested = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.netInvested,
);

export const getIsNetInvestedFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingNetInvested,
);

export const getIsNetInvestedFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingNetInvestedFail,
);

export const getRateOfReturn = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.rateOfReturn,
);

export const getIsRateOfReturnFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingRateOfReturn,
);

export const getIsRateOfReturnFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingRateOfReturnFail,
);

export const getCashFlow = createSelector(getAccountState, (accountState: IAccountState) => accountState.cashFlow);

export const getCashFlowSummaryFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingCashFlowSummary,
);

export const getCashFlowSummaryFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingCashFlowSummaryFail,
);

export const getIsDeliveryPreferencesFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDeliveryPreferences,
);

export const getIsDeliveryPreferencesFetchFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDeliveryPreferencesFail,
);

export const getDeliveryPreferences = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.deliveryPreferences,
);

export const getIsDeliveryPreferencesSaving = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isSavingDeliveryPreferences,
);

export const getIsDeliveryPreferencesSaveFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isSavingDeliveryPreferencesFail,
);

export const getIsDeliveryPreferencesSummaryFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDeliveryPreferencesSummary,
);

export const getIsDeliveryPreferencesSummaryFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDeliveryPreferencesSummaryFail,
);

export const getDeliveryPreferencesSummary = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.deliveryPreferencesSummary,
);

export const getDocumentDateFiltering = createSelector(getAccountState, (accountState: IAccountState) => ({
  documentsStartDate: accountState.documentsStartDate,
  documentsEndDate: accountState.documentsEndDate,
}));

export const getIsAccountDocumentsFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDocuments,
);

export const getIsAccountDocumentsFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingDocumentsFail,
);

export const getIsAccountDocumentsLoaded = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isDocumentLoaded,
);

export const getIsTradeConfirmationsLoaded = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isTradeConfirmationsLoaded,
);

export const getIsTradeConfirmationDocumentsFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingTradeConfirmationDocuments,
);

export const getIsTradeConfirmationDocumentsFetchingFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingTradeConfirmationDocumentsFail,
);

// export const getIsTradeConfirmationDocumentsLoaded = createSelector(
//   getAccountState,
//   (accountState: IAccountState) => accountState.isDocumentLoaded
// );

export const getAccountDocuments = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.documents,
);

export const getIsMergingDownloadPdfs = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isMergingDownloadPdfs,
);

export const getIsMergingDownloadPdfsDone = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isMergingDownloadPdfsDone,
);

export const getIsMergingDownloadPdfsFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isMergingDownloadPdfsFail,
);

export const getMergePdfsResults = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.mergePdfsResults,
);

export const hasOnlySharedAccounts = createSelector(
  getSystemState,
  getPartyState,
  (systemState: ISystemState, partyState: IPartyState) => {
    const { user } = systemState;
    const { accounts } = partyState.partyV1;
    if (user && accounts) {
      const partyAccountsCount = accounts.length;
      const userSharedAccountsCount = user.accounts_shared.length;
      return partyAccountsCount === userSharedAccountsCount;
    }
    return false;
  },
);

export const getIsMarketDataFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingMarketData,
);

export const getIsMarketDataFetchingHasError = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingManrketDataHasError,
);

export const getIsClosedMarketsFetching = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingClosedMarkets,
);

export const getClosedMarkets = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.closedMarkets,
);

export const getisFetchingCSVByDomain = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingCSVByDomain,
);

export const getisFetchingCSVByDomainDone = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingCSVByDomainDone,
);

export const getisFetchingCSVByDomainFail = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.isFetchingCSVByDomainFail,
);

export const getCSVResult = createSelector(getAccountState, (accountState: IAccountState) => accountState.csvResult);

export const getIsLiveDataFromState = createSelector(
  getAccountState,
  (accountState: IAccountState) => accountState.clientAccounts.isLiveData,
);
