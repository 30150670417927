import axios from 'axios';
import { API_EVENTS } from '~/common/API';
import { IEventLogModel } from '../eventsTypes';

export const EventHttpClient = new class {
  public trackLogin = async (partyId: string) => {
    const payload: IEventLogModel = {
      eventType: 1,
      subsidiary: 'iasecurities',
      modifiedBy: null,
      owner: partyId,
      payloadCategory: 'LOGIN',
      payload: null,
      message: 'Login',
    };

    await axios.post(`${API_EVENTS}?partyId=${partyId}`, payload);
  };
  public trackError = async (partyId: any, err: string) => {
    const payload: IEventLogModel = {
      eventType: 1,
      subsidiary: 'iasecurities',
      modifiedBy: partyId,
      owner: partyId,
      message: err,
      payloadCategory: 'GLOBAL ERROR',
      payload: null,
    };

    await axios.post(`${API_EVENTS}?partyId=${partyId}`, payload);
  };
}();
