import i18n from '~/app/i18n';
import { formatDatePerLocale, formatMoneyPerLocale } from '~/common/helpers';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Brush,
} from 'recharts';
import moment from 'moment';

interface IBarChartProps {
  dividendHistory: any
}

const DividendsBarChart: React.FC<IBarChartProps> = (props) => {
  // const axisXLabel = (value: any) => value;
  const axisYLabel = (value: any) => formatMoneyPerLocale(value);
  const CustomTooltip = (toolTipProps: any) => {
    const { active, payload } = toolTipProps;
    return (
      active && payload && payload.length > 0 ?
        <div className="boxTooltip" style={{ width: '200px' }}>
          <p>{i18n.t('stockOverview.tabs.dividends.date')}: {formatDatePerLocale(payload[0].payload.payDate)}</p>
          <p>{i18n.t('stockOverview.tabs.dividends.dividend')}: {formatMoneyPerLocale(payload[0].payload.amount)} {payload[0].payload.currency}</p>
        </div>
        : null
    );
  };

  const datesAlreadyListed: string[] = [];
  let i = 0;
  for (const element of props.dividendHistory) {
    const year = moment(element.payDate).isValid() ? moment(element.payDate).format('YYYY') : '';
    if (!datesAlreadyListed.includes(year)) {
      datesAlreadyListed.push(year);
      props.dividendHistory[i].year = year;
    }
    i++;
  }

  return (
    <BarChart
      width={950}
      height={300}
      data={props.dividendHistory}
    >

      <XAxis dataKey="year" interval={0} />
      <YAxis dataKey="amount" tickFormatter={axisYLabel} domain={[0, 'dataMax']} />
      <Tooltip content={<CustomTooltip />} />
      <Brush dataKey="payDate" height={20} stroke="#003da5" data={props.dividendHistory} />
      <Bar dataKey="amount" stroke="#003da5" fill="#003da5" barSize={10} />
    </BarChart>

  );
};

export default DividendsBarChart;
