import React from 'react';
import { Stack } from '@mui/material';
import { BoxShadow, KeyIndicatorTitle, KeyIndicatorLabel, Label } from '~/common/styles/baseStyle';
import { IASparklineChartV2 } from './IASparklineChartV2';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { IKeyIndicator } from '~/stores/marketData/marketDataTypes';
import { Link } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { Trans } from 'react-i18next';
import moment from 'moment';
import Colors from '~/common/themes/colors';

const KeyIndicatorCard: React.FC<{ item: IKeyIndicator; count: number; culture: string }> = ({
  item,
  count,
  culture,
}) => {
  let xMax;
  let xMin;
  if (item && item.history && item.history.length > 0) {
    const startTime = moment('09:30', 'HH:mm');
    const endTime = moment('16:30', 'HH:mm');

    xMax = moment(item.history[0].timestamp)
      .set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second'),
      })
      .toDate();
    xMin = moment(item.history[0].timestamp)
      .set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second'),
      })
      .toDate();
  }
  return (
    <>
      <BoxShadow
        key={item.symbol + item.name}
        hover
        style={{
          width: '100%',
          borderRadius: '10px',
          cursor: 'pointer',
        }}
      >
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: RouteNames.marketProductOverview,
            state: {
              symbol: `${item.market}:${item.symbol}`,
              type: item.type,
              source: location.pathname,
            },
          }}
        >
          <KeyIndicatorTitle fontWeight={700}>{item.symbol}</KeyIndicatorTitle>
          <KeyIndicatorLabel fontSize={14} color="#696969">
            {item.name.length > 17 ? `${item.name.slice(0, 17)}...` : item.name}
          </KeyIndicatorLabel>
          {item.last && (
            <KeyIndicatorLabel fontSize={16} fontWeight={600}>
              <DecimalValue value={item.last} culture={culture} amountOfDigits={2} />{' '}
              {item.currency === 'USD' && item.symbol === 'Gold' ? item.currency : ''}
            </KeyIndicatorLabel>
          )}
          {count >= 2 && (
            <IASparklineChartV2
              baseLine={item.previousClose}
              data={item.history.map((history) => ({ value: history.value, date: new Date(history.timestamp) }))}
              trend={item.changePercentage < 0 ? 'negative' : 'positive'}
              fixedXAxis={xMax && xMin ? { max: xMax, min: xMin } : undefined}
            />
          )}
          <Stack direction="row" spacing={0} alignContent="center" marginTop={2}>
            {!item.change === null || item.change === undefined ? (
              <Label style={{ color: Colors.anotherGrayShade }} fontSize={17}>
                <Trans i18nKey="marketsPage.keyIndicators.noData" />
              </Label>
            ) : (
              <ChangeValue
                value={item.change}
                percent={item.changePercentage}
                decimalOverride={3}
                singleLineFormat
                arrowFontSize="17"
                fontSize="16px"
                mainContainerStyleRules={{ style: { display: 'inline-flex', fontWeight: 'lighter' } }}
              />
            )}
          </Stack>
        </Link>
      </BoxShadow>
    </>
  );
};

export default KeyIndicatorCard;
