import styled from 'styled-components';
import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const SendMessageForm = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 2em;
`;

export const BreadcrumbNavigation = styled.ul`
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-top: 15px;
  padding-left: 1.2em;
`;

export const BreadcrumbNavigationItem = styled.li`
  &::before {
    content: '<';
    position: absolute;
    left: 0;
  }
  font-size: 14px !important;
`;

export const SendMessagePageHeader = styled.div`
  padding-left: 10px;
`;

export const CancelButton = withStyles(Button, {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '9px 12px',
    marginRight: '10px',
    color: '#003DA5',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    borderColor: '#003DA5',
    borderRadius: 5,
    fontFamily: ['OpenSans', 'Open Sans'].join(','),
    '&:hover': {
      borderColor: '#003da5',
      color: '#003da5',
      boxShadow: 'none',
    },
  },
});

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;
  p {
    margin: 0;
  }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const UploadErrorList = styled.ul`
  padding: 0;
  list-style-type: none;
`;
export const UploadErrorListItem = styled.li`
  padding-bottom: 10px;
`;
