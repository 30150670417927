import * as React from 'react';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import Colors from '~/common/themes/colors';
import { Trans } from 'react-i18next';
import './SummarySliptGrid.css';

export interface IProps {
  backgroundColor?: string;
  labelKey: string;
  value: any;
  comment?: string;
  isFetching?: boolean;
}

const SummaryLeftGrid = ({ labelKey, value, backgroundColor, comment, isFetching }: IProps) => {
  const updatedBackgroundColor = backgroundColor || Colors.desertStorm;
  return (
    <Stack
      direction="column"
      rowGap={0.5}
      justifyContent="center"
      width="100%"
      height="100%"
      sx={{ backgroundColor: updatedBackgroundColor, padding: '24px 16px' }}
    >
      {isFetching ? (
        <Skeleton variant="rectangular" width="85%" height={20} sx={{ mt: 0.75 }} />
      ) : (
        <Typography variant="body2" component="div" id="labelSummaryGrid" className="typography16">
          <Trans i18nKey={labelKey} />
        </Typography>
      )}
      {isFetching ? (
        <Skeleton variant="rectangular" sx={{ p: 0 }} width="75%" height={23} />
      ) : (
        <Typography variant="h3" component="div" style={{ color: Colors.cobalt }}>
          {value}
        </Typography>
      )}
      {comment && (
        <>
          {isFetching ? (
            <Skeleton variant="rectangular" width="65%" height={20} />
          ) : (
            <Typography component="div">
              <Trans i18nKey={comment} />
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};

export default SummaryLeftGrid;
