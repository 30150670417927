import { Select } from 'antd';
import styled from 'styled-components';

export const ContentPageContainer = styled.div`
    background-color: #FFFFFF;
`;

export const { Option } = Select;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
`;

export const CsvModalWarningContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    background-color: #c3d7ee;
    padding: 20px;
    text-align: left;
    border-width: 1px;
    border-style: solid;
    border-color: #003da5;
    color: #003da5;
    margin-bottom: 20px;
`;

export const CsvModalWarningIcon = styled.div`
    font-size: 36px;
`;

export const CsvModalWarningText = styled.div`
    padding-left:20px;
    font-size: 14px;
`;

export const CsvLabel = styled.div`
    font-family: ${(props) => props.theme.fontSemiBold};
    font-weight: ${(props) => props.theme.fontSemiBoldWeight};
    font-size: 14px;
    padding-bottom: 5px;
`;

export const CsvModalSelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
    width:50%;
`;
