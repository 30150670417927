import { action } from 'typesafe-actions';
import { IDividendsHistoricalData, PositionDetailActionTypes, IEarningsEstimate } from '../types';

export const fetchHistoricalData = (symbol: string, frequency: string) => action(PositionDetailActionTypes.FETCH_HISTORICAL_DATA, { symbol, frequency });
export const fetchHistoricalDataSuccessful = (key: string, historicalData: any) => action(PositionDetailActionTypes.FETCH_HISTORICAL_DATA_SUCCESSFUL, { key, historicalData });
export const fetchHistoricalDataFailure = (key: string, historicalData: any) => action(PositionDetailActionTypes.FETCH_HISTORICAL_DATA_FAILED, { key, historicalData });

export const fetchFinancialData = (symbol: string, frequency: string) => action(PositionDetailActionTypes.FETCH_FINANCIAL_DATA, { symbol, frequency });
export const fetchFinancialDataSuccessful = (key: string, freqKey: string, financialData: any) => action(PositionDetailActionTypes.FETCH_FINANCIAL_DATA_SUCCESSFUL, { key, freqKey, financialData });
export const fetchFinancialDataFailure = (key: string, freqKey: string, financialData: any) => action(PositionDetailActionTypes.FETCH_FINANCIAL_DATA_FAILED, { key, freqKey, financialData });

export const fetchStockDetailedInfo = (symbol: string) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA, { symbol });
export const fetchStockDetailedInfoSuccessful = (key: string, stockDetailedInfo: any) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_SUCCESSFUL, { key, stockDetailedInfo });
export const fetchStockDetailedInfoFailure = (key: string, stockDetailedInfo: any) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_FAILED, { key, stockDetailedInfo });

export const fetchStockDetailedInfoRefresh = (symbol: string) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH, { symbol });
export const fetchStockDetailedInfoRefreshSuccessful = (key: string, stockDetailedInfo: any) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_SUCCESSFUL, { key, stockDetailedInfo });
export const fetchStockDetailedInfoRefreshFailure = (key: string) => action(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH_FAILED, { key });

export const fetchFundDetailedInfo = (symbol: string) => action(PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA, { symbol });
export const fetchFundDetailedInfoSuccessful = (key: string, fundDetailedInfo: any) => action(PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA_SUCCESSFUL, { key, fundDetailedInfo });
export const fetchFundDetailedInfoFailure = (key: string, fundDetailedInfo: any) => action(PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA_FAILED, { key, fundDetailedInfo });

// EarningEstimate API call is not currently being used
export const fetchEarningsEstimate = (symbol: string) => action(PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE, { symbol });
export const fetchEarningsEstimateSuccessful = (key: string, earningsEstimate: IEarningsEstimate) => action(PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE_SUCCESSFUL, { key, earningsEstimate });
export const fetchEarningsEstimateFailure = (key: string, earningsEstimate: IEarningsEstimate) => action(PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE_FAILURE, { key, earningsEstimate });

export const fetchDividendsHistoricalData = (symbol: string) => action(PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_DATA, { symbol });
export const fetchDividendsHistoricalDataSuccessful = (key: string, dividendsHistoricalData: IDividendsHistoricalData) => action(PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_DATA_SUCCESSFUL, { key, dividendsHistoricalData });
export const fetchDividendsHistoricalDataFailure = () => action(PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_FAILED);
