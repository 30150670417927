import axios from 'axios';
import { Reducer } from 'redux';

import { IoidcState, oidcActionTypes } from '../oidcTypes';

export const initialState: IoidcState = {
  tokenFetched: false,
  token: '',
  profile: { preferred_username: '' },
};

const handleFetchToken = (state: IoidcState) => ({ ...state, tokenFetched: false });

const handleFetchTokenError = (state: IoidcState) => ({ ...state, tokenFetched: false, token: '' });

const handleFetchTokenSuccessful = (state: IoidcState, result: any) => {
  axios.defaults.headers.common.Authorization = `Bearer ${result.access_token}`;
  return { ...state, token: result.access_token, tokenFetched: true, profile: result.profile };
};

export const oidcReducer: Reducer<IoidcState> = (state = initialState, action) => {
  switch (action.type) {
    case oidcActionTypes.FETCH_TOKEN:
      return handleFetchToken(state);
    case oidcActionTypes.FETCH_TOKEN_ERROR:
      return handleFetchTokenError(state);
    case oidcActionTypes.FETCH_TOKEN_SUCCESSFUL:
      return handleFetchTokenSuccessful(state, action.payload);
    default:
      return state;
  }
};
