import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const SymbolDescrptionContainer = styled.div`
  font-size: 14px;
`;

export interface ISymbolDescriptionDisplayProps {
  isSymbolLinkEnabled: boolean;
  investmentCategory: string;
  routeName: string;
  ticker: string;
  pathname: string;
  symbol: string;
  description: string;
  notFoundAtProvider: boolean;
  notShowDescription?: boolean;
  type?: string;
  section?: string;
}

export class SymbolDescriptionDisplay extends React.PureComponent<ISymbolDescriptionDisplayProps> {
  public render() {
    const {
      isSymbolLinkEnabled,
      investmentCategory,
      routeName,
      ticker,
      pathname,
      symbol,
      description,
      notFoundAtProvider,
      notShowDescription,
      type,
      section,
    } = this.props;
    return isSymbolLinkEnabled &&
      !notFoundAtProvider &&
      (investmentCategory === 'MF' || investmentCategory === 'EQ') ? (
      <div>
        <Link
          to={{
            pathname: routeName,
            state: {
              symbol: ticker,
              description,
              source: pathname,
              type,
              section,
            },
          }}
        >
          <Typography variant="h6" component="div" style={{ color: '#2062D4', textAlign: 'left' }}>
            {symbol}
          </Typography>
        </Link>
        {!notShowDescription && <SymbolDescrptionContainer>{description}</SymbolDescrptionContainer>}
      </div>
    ) : (
      <div>
        <Typography variant="h6" component="div" style={{ color: '#000000', textAlign: 'left' }}>
          {symbol}
        </Typography>{' '}
        <SymbolDescrptionContainer>{description}</SymbolDescrptionContainer>
      </div>
    );
  }
}

export default SymbolDescriptionDisplay;
