import * as React from 'react';
import { getMarketSymbolFormatted } from '~/common/helpers';
import Grid from '@mui/material/Grid';
import { TabsContainer } from '~/common/components/position-overview/PositionOverviewPage.style';
import Performance from '~/common/components/position-overview/performance/Performance';
import { isPeersSectionEnabled } from '~/common/featureToggleHelper';
import Peers from '../Peers';
import QuarterlyStatements from './QuarterlyStatements';
import Last12Months from './Last12Months';

interface IStockData {
  stockData: any,
  historicalData: any,
}
export const StockOverview: React.FunctionComponent<IStockData> = ({ stockData, historicalData }) => (
  <TabsContainer>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Performance symbol={getMarketSymbolFormatted(stockData)} historicalData={historicalData} />
      </Grid>
      <Grid item xs={6}>
        <QuarterlyStatements stock={stockData} />
      </Grid>
      <Grid item xs={6}>
        <Last12Months stock={stockData} />
      </Grid>
      {isPeersSectionEnabled() &&
        <Grid item xs={12}>
          <Peers data={stockData.peers} />
        </Grid>}
    </Grid>
  </TabsContainer>
);

export default StockOverview;
