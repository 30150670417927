import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { ToolTip } from '../../tooltip/tooltip';
import '../../account/account-view/SummarySliptGrid.css';

interface IPositionItemProps {
  xsInfo: any;
  labelKey?: string;
  positionValue?: any;
  toolTipKey?: string;
  toolTipColor?: string;
}

export const PositionItem = ({ xsInfo, labelKey, positionValue, toolTipKey, toolTipColor }: IPositionItemProps) => (
  <Grid item xs={xsInfo}>
    <Typography variant="body2" component="div" id="labelSummaryGrid" className="typography14">
      <Trans i18nKey={labelKey} />
      {toolTipKey && <ToolTip transKey={toolTipKey} color={toolTipColor} />}
    </Typography>
    <Typography component="div" id="labelSummaryGrid" className="typography16">
      {positionValue}
    </Typography>
  </Grid>
);
