import { connect } from 'react-redux';
import { Cultures, RouteNames } from '~/app/appTypes';
import { IAppRootState } from '~/app/rootReducer';
import SymbolDescriptionDisplay from '~/common/components/account/account-display/SymbolDescriptionDisplay';
import { isSymbolLinkEnabled } from '~/common/featureToggleHelper';
import * as React from 'react';
import i18n from '~/app/i18n';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { IMarketDataState, MarketType, TopMover } from '~/stores/marketData/marketDataTypes';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { Skeleton, Stack } from '@mui/material';
import { IASparklineChart, type SparklineData } from './IASparklineChart';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NoData from '~/common/components/noData/NoData';
import DataTableV2, { IColumnsV2 } from '~/common/components/data-table/data-table-v2';

export interface IPropsFromState {
  culture: string;
  marketDataState: IMarketDataState;
}

interface ITopMoversTableProps {
  topMovers: TopMover[];
  marketType: MarketType;
}

const mapInvestmentCategory = (type: MarketType) => {
  switch (type) {
    case 'stock':
    case 'etf':
      return 'EQ';
    case 'fund':
      return 'MF';
    default:
      return '';
  }
};

const DEFAULT_RECORDS = 10;

const emptyTopMover: TopMover = {
  symbol: '',
  currency: '',
  market: '',
  nameEn: '',
  nameFr: '',
  last: 0,
  change: 0,
  changePercentage: 0,
  type: 'stock',
  history: [],
  baseHistoryValue: 0,
};

const loadingTopMovers: TopMover[] = Array.from({ length: DEFAULT_RECORDS }, () => emptyTopMover);

const TopMoversTable = ({
  topMovers,
  culture,
  marketDataState,
  location,
}: ITopMoversTableProps & IPropsFromState & RouteComponentProps) => {
  const selectedMarketMoverType = marketDataState.topMoverSelectedMarketMoverType.get(
    marketDataState.topMoverSelectedMarketType,
  );

  const columns: IColumnsV2[] = [
    {
      header: i18n.t('marketsPage.topMovers.table.description'),
      render: (record: TopMover) => {
        let ticker = `${record.market}:${record.symbol}`;
        if (record.type === 'fund') {
          ticker = record.symbol;
        }

        return (
          <Stack gap={1} height={40}>
            {marketDataState.isFetchingTopMovers.get(
              `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType || 'gainers'}`,
            ) ? (
              <>
                <Skeleton variant="rectangular" width="70%" height={18} />
                <Skeleton variant="rectangular" width="25%" height={18} />
              </>
            ) : (
              <SymbolDescriptionDisplay
                isSymbolLinkEnabled={isSymbolLinkEnabled()}
                investmentCategory={mapInvestmentCategory(record.type)}
                routeName={RouteNames.positionOverview}
                ticker={ticker}
                pathname={location.pathname}
                notFoundAtProvider={false}
                symbol={culture === Cultures.english ? record.symbol : record.symbol}
                description={culture === Cultures.english ? record.nameEn : record.nameFr}
                section="TopMovers"
              />
            )}
          </Stack>
        );
      },
      align: 'left',
    },
    {
      header: i18n.t('marketsPage.topMovers.table.last'),
      render: (record: TopMover) =>
        marketDataState.isFetchingTopMovers.get(
          `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType || 'gainers'}`,
        ) ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
            <Skeleton variant="rectangular" width="60%" height={18} />
          </Stack>
        ) : (
          <DecimalValue value={record.last} culture={culture} amountOfDigits={2} />
        ),
      align: 'right',
    },
    {
      header: i18n.t('marketsPage.topMovers.table.dailyChange'),
      render: (record: TopMover) =>
        marketDataState.isFetchingTopMovers.get(
          `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType || 'gainers'}`,
        ) ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
            <Skeleton variant="rectangular" width="60%" height={18} />
          </Stack>
        ) : (
          <>
            <ChangeValue
              value={record.change}
              percent={record.changePercentage}
              decimalOverride={3}
              singleLineFormat
              arrowFontSize="17"
              mainContainerStyleRules={{ style: { display: 'inline-flex' } }}
            />
          </>
        ),
      align: 'right',
    },
    {
      header: i18n.t('marketsPage.topMovers.table.yearlyChart'),
      width: '120px',
      render: (record: TopMover) => {
        const sparklineData =
          record.history?.map((item) => ({ value: item.value, date: new Date(item.date) } as SparklineData)) || [];

        return marketDataState.isFetchingTopMovers.get(
          `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType || 'gainers'}`,
        ) ? (
          <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <Skeleton variant="rounded" width="70%" height={40} />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <IASparklineChart
              baseLine={record.baseHistoryValue}
              data={sparklineData}
              trend={record.changePercentage < 0 ? 'negative' : 'positive'}
              height={40}
              width={80}
            />
          </Stack>
        );
      },
      align: 'right',
    },
  ];

  return (
    <DataTableV2
      columns={columns}
      data={
        marketDataState.isFetchingTopMovers.get(
          `${marketDataState.topMoverSelectedMarketType}${selectedMarketMoverType || 'gainers'}`,
        )
          ? loadingTopMovers
          : topMovers
      }
      title=""
      emptyData={
        <Stack mt={3}>
          <NoData iconSize="md" text={i18n.t('marketsPage.topMovers.noData')} />
        </Stack>
      }
    />
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
    marketDataState: state.marketData,
  };
}

export default connect(mapStateToProps)(withRouter(TopMoversTable));
