import {
  ResponsiveChartContainer,
  ChartsReferenceLine,
  LinePlot,
  AreaPlot,
  areaElementClasses,
  lineElementClasses,
} from '@mui/x-charts';
import React from 'react';

export type SparklineData = {
  value: number;
  date: Date;
};

export type AxisLimits = {
  min: Date;
  max: Date;
};

export const IASparklineChartV2 = ({
  baseLine,
  data,
  trend,
  fixedXAxis,
}: {
  baseLine?: number;
  data: SparklineData[];
  trend: 'positive' | 'negative';
  fixedXAxis?: AxisLimits | undefined;
}) => {
  const maxY =
    data !== undefined
      ? Math.max(...data.map((o) => o.value).concat(baseLine ?? data[0].value)) +
        Math.max(...data.map((o) => o.value).concat(baseLine ?? data[0].value)) * 0.006
      : 0;
  const minY =
    data !== undefined
      ? Math.min(...data.map((o) => o.value).concat(baseLine ?? data[0].value)) -
        Math.min(...data.map((o) => o.value).concat(baseLine ?? data[0].value)) * 0.006
      : 0;
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <ResponsiveChartContainer
        sx={{
          [`& .${lineElementClasses.root}`]: {
            strokeWidth: '2.5px',
          },
          [`& .${areaElementClasses.root}`]: {
            fill: trend === 'negative' ? 'rgb(255,233,235)' : 'rgb(231,242,219)',
          },
        }}
        series={[
          {
            type: 'line',
            data: data?.map((item) => item.value),
            connectNulls: true,
            showMark: false,
            area: true,
            color: trend === 'negative' ? 'red' : 'green',
          },
        ]}
        xAxis={[
          {
            data: data?.map((item) => new Date(item.date).getTime()), // Convert Date to number using getTime()
            scaleType: 'time',
            max:
              data !== undefined && data.length > 0
                ? fixedXAxis
                  ? fixedXAxis.max
                  : Math.max(...data.map((o) => new Date(o.date).getTime()))
                : 0,
            min:
              data !== undefined && data.length > 0
                ? fixedXAxis
                  ? fixedXAxis.min
                  : Math.min(...data.map((o) => new Date(o.date).getTime()))
                : 0,
          },
        ]}
        yAxis={[
          {
            data: data?.map((item) => item.value),
            max: maxY,
            min: minY,
          },
        ]}
        height={70}
        margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
      >
        <AreaPlot />
        <LinePlot strokeWidth={13} />
        {baseLine && (
          <ChartsReferenceLine y={baseLine} lineStyle={{ stroke: '#B0B0B0', strokeDasharray: '10 4' }} />
        )}
      </ResponsiveChartContainer>
    </div>
  );
};
