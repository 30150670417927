import axios from 'axios';
import { API_ADVISORS } from '~/common/API';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';

export const AdvisorHttpClient = new class {
  private apiUrl = `${API_ADVISORS}`;

  public fetchAdvisor = async (token: string, repcodes: string[]) => {
    const params = repcodes.map((a) => `repCodes=${a}`).join('&');
    const response = await axios.get(`${this.apiUrl}?${params}`, {
      headers: {
        Bearer: token,
      },
      transformResponse: (data: any) => {
        const refMap: any = {};
        return JSON.parse(data, function parse(key, value) {
          if (key === '$id') {
            refMap[value] = this;
            // return undefined so that the property is deleted
            return undefined;
          }

          if (value && value.$ref) {
            return refMap[value.$ref];
          }

          return value;
        });
      },
    });
    return response.data as IAdvisor[];
  };
}();
