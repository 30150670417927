import * as React from 'react';
import { getMarketSymbolFormatted } from '~/common/helpers';
import Grid from '@mui/material/Grid';
import { isPeersSectionEnabled } from '~/common/featureToggleHelper';
import Peers from '../Peers';
import { TabsContainer } from '../PositionOverviewPage.style';
import PerformanceSection from '../performance/PerformanceSection';
import Performance from '../performance/Performance';

interface IStockData {
  stockData: any,
  historicalData: any,
}
export const EtfOverview: React.FunctionComponent<IStockData> = ({ stockData, historicalData }) => (
  <TabsContainer>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Performance symbol={getMarketSymbolFormatted(stockData)} historicalData={historicalData} />
      </Grid>
      {stockData.etfInfo.performance &&
        <Grid item xs={12}>
          <PerformanceSection perfomanceData={stockData.etfInfo.performance} symbol={stockData.symbol} />
        </Grid>}
      {isPeersSectionEnabled() &&
        <Grid item xs={12}>
          <Peers data={stockData.peers} />
        </Grid>}

    </Grid>
  </TabsContainer>
);

export default EtfOverview;
