import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import { Trans } from 'react-i18next';
import i18n from '~/app/i18n';
import { Dialog } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Steps } from 'antd';
import { Container, StyledLinkButton } from '~/common/styles/baseStyle';
import { getDidConsentToMailNotification, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { saveUpdateDeliveryPreferences, saveDocumentDeliveryNotification } from '~/stores/party/actions/partyActions';
import { RouteNames } from '~/app/appTypes';
import { saveDeliveryPreferences, fetchDeliveryPreferences } from '~/stores/account/actions/accountActions';
import LoadDeliveryPreferences from '~/pages/householding-page/documentdelivery-view/deliveryPreferencesLoadMapper';
import {
  getDeliveryPreferences,
  getIsDeliveryPreferencesSaving,
  getAllAvailableAccounts,
  getIsDeliveryPreferencesSaveFail,
} from '~/stores/account/selectors/accountSelectors';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { IDeliveryPreferences, IAccount, IDeliveryPreferencesToSave } from '~/stores/account/accountTypes';
import { IPartyV1, IUpdateDeliveryPreferencesMetadata } from '~/stores/party/partyTypes';
import ChangeAllToPaperless from '~/pages/householding-page/documentdelivery-view/ChangeAllToPaperless';
import Loading from '../../../common/components/Loading/Loading';
import { RenderFirstStep, RenderSecondStep, RenderThirdStep } from './Steps';
import {
  RightDiv,
  StyledDiv,
  Content,
  ButtonsContainer,
  InlineDiv,
  Title,
  Subtitle,
  ChangeAllContent,
  StyledSteps,
  CloseIconDiv,
  CloseIconStyle,
} from './DocumentDeliveryPrompt.style';
import background from './GoPaperlessBackground.jpg'; // relative path to image
import {
  MapToDeliveryPreferences,
  MapToDeliveryPreferencesTableRowForAllPaperless,
} from '~/pages/householding-page/documentdelivery-view/deliveryPreferencesHelperMapper';
import { SetStorageKeyValue } from '~/app/sessionManager';

const { Step } = Steps;

interface IPropsFromState {
  didConsentToMailNotification: boolean;
  deliveryPreferences: IDeliveryPreferences[];
  isDeliveryPreferencesSaving: boolean;
  isDeliveryPreferencesSaveFail: boolean;
  party: IPartyV1;
  accounts: IAccount[];
  isUserAdvisorOrAdmin: boolean;
  showDocumentDelivery: boolean;
}

interface IPropsFromDispatch {
  saveUpdateDeliveryPreferences: typeof saveUpdateDeliveryPreferences;
  saveDeliveryPreferences: typeof saveDeliveryPreferences;
  fetchDeliveryPreferences: typeof fetchDeliveryPreferences;
}

interface ILocalState {
  isVisible: boolean;
  isChangingAll: boolean;
  currentStep: number;
  isReceiveEmailChecked: boolean;
  hasAcceptedLegalConsent: boolean;
  navigateTo: string | undefined;
}

class DocumentDeliveryPrompt extends React.Component<IPropsFromState & IPropsFromDispatch, ILocalState> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.state = {
      isChangingAll: false,
      currentStep: 0,
      isReceiveEmailChecked: true,
      hasAcceptedLegalConsent: false,
      isVisible: true,
      navigateTo: undefined,
    };
  }
  public componentDidMount() {
    if (this.getIsOpen()) {
      this.fetchDeliveryPreferences();
    }
  }

  private fetchDeliveryPreferences() {
    const { party } = this.props;

    if (party && party.accounts !== undefined) {
      const clientIds = party.accounts.map((a) => a.id);
      this.props.fetchDeliveryPreferences(clientIds, '');
    }
  }

  public componentDidUpdate() {
    if (this.state.navigateTo) {
      window.location.href = this.state.navigateTo;
    }
  }

  private goToNextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  private confirmChanges = () => {
    this.saveAllToPaperless();
    this.goToNextStep();
  };

  private saveAllToPaperless = () => {
    const loadedDeliveryPreferences = LoadDeliveryPreferences(
      this.props.deliveryPreferences,
      this.props.accounts,
      this.props.isUserAdvisorOrAdmin,
    );
    if (!loadedDeliveryPreferences) {
      return;
    }
    const changesToBeAppliedForAll = MapToDeliveryPreferencesTableRowForAllPaperless(loadedDeliveryPreferences);
    const savablePreferences = MapToDeliveryPreferences(changesToBeAppliedForAll);

    if (savablePreferences && savablePreferences.length > 0) {
      this.saveDeliveryPreferences(savablePreferences);
    }
  };

  private onChangeAllClicked = () => {
    this.setState({ isChangingAll: true });
  };

  private handleLegalConsentChange = () => {
    this.setState({ hasAcceptedLegalConsent: !this.state.hasAcceptedLegalConsent });
  };

  private close = () => {
    this.setSplashSessionStoragetoShown();
    this.setState({ isVisible: false });
  };

  private remindLater = () => {
    const dateInTwoWeeks = new Date(Date.now());
    dateInTwoWeeks.setDate(dateInTwoWeeks.getDate() + 14);

    this.saveUpdateDeliveryPreferences({ showOn: dateInTwoWeeks });
    this.setSplashSessionStoragetoShown();
    this.setState({ isVisible: false });
  };

  private setSplashSessionStoragetoShown = () => {
    SetStorageKeyValue('eDelSplash', true);
  };

  private saveUpdateDeliveryPreferences = (metadata: IUpdateDeliveryPreferencesMetadata) => {
    if (!this.props.isUserAdvisorOrAdmin) {
      this.props.saveUpdateDeliveryPreferences(metadata);
    }
  };

  private saveDeliveryPreferences = (deliveryPreferences: IDeliveryPreferencesToSave[]) => {
    if (!this.props.isUserAdvisorOrAdmin) {
      this.props.saveDeliveryPreferences(deliveryPreferences);
      this.saveUpdateDeliveryPreferences({});
    }
  };

  private reviewSettings = () => {
    const dateNow = new Date(Date.now());
    this.saveUpdateDeliveryPreferences({ reviewedOn: dateNow });
    setTimeout(() => {
      this.setState({ isVisible: false, navigateTo: RouteNames.documentDeliveryView });
    }, 1000);
  };

  private stepsAccessSharing = () => [
    {
      title: i18n.t('documentDelivery.prompt.step1'),
      content: RenderFirstStep(this.goToNextStep),
    },
    {
      title: i18n.t('documentDelivery.prompt.step2'),
      content: RenderSecondStep({
        handleLegalConsentChange: this.handleLegalConsentChange,
        confirm: this.confirmChanges,
        canGoToNextStep: this.state.hasAcceptedLegalConsent,
        hasAcceptedLegalConsent: this.state.hasAcceptedLegalConsent,
      }),
    },
    {
      title: i18n.t('documentDelivery.prompt.step3'),
      content: RenderThirdStep(this.close, this.props.isDeliveryPreferencesSaveFail),
    },
  ];

  private getIsOpen() {
    return this.props.showDocumentDelivery && this.state.isVisible;
  }

  public render() {
    return (
      <Dialog open={this.getIsOpen()} onClose={this.close} fullWidth maxWidth="md" scroll="body">
        <Content>
          <StyledDiv>
            <CloseIconDiv>
              <HighlightOffIcon onClick={this.close} style={CloseIconStyle} />
            </CloseIconDiv>
            <InlineDiv>
              <Title>
                <Trans i18nKey="documentDelivery.goPaperless" />
              </Title>
              <Subtitle>
                <Trans i18nKey="documentDelivery.description" />
              </Subtitle>
            </InlineDiv>
            <RightDiv>
              <img src={background} />
            </RightDiv>
          </StyledDiv>
          {this.state.isChangingAll && (
            <ChangeAllContent>
              {this.state.currentStep !== 2 && (
                <StyledSteps current={this.state.currentStep}>
                  {this.stepsAccessSharing().map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </StyledSteps>
              )}
              {this.state.currentStep === 2 && this.props.isDeliveryPreferencesSaving ? (
                <Container>
                  <Loading show position="relative" />
                </Container>
              ) : (
                this.stepsAccessSharing()[this.state.currentStep].content
              )}
            </ChangeAllContent>
          )}
          {this.state.currentStep !== 2 && (
            <ButtonsContainer>
              {!this.state.isChangingAll && <ChangeAllToPaperless onChangeAllToPaperless={this.onChangeAllClicked} />}
              <StyledLinkButton onClick={this.reviewSettings}>
                <Trans i18nKey="documentDelivery.prompt.reviewSettings" />
              </StyledLinkButton>
              <StyledLinkButton onClick={this.remindLater}>
                <Trans i18nKey="documentDelivery.prompt.remindLater" />
              </StyledLinkButton>
            </ButtonsContainer>
          )}
        </Content>
      </Dialog>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    didConsentToMailNotification: getDidConsentToMailNotification(state),
    deliveryPreferences: getDeliveryPreferences(state),
    party: getPartyV1(state),
    accounts: getAllAvailableAccounts(state),
    showDocumentDelivery: state.system.showDocumentDelivery,
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    isDeliveryPreferencesSaving: getIsDeliveryPreferencesSaving(state),
    isDeliveryPreferencesSaveFail: getIsDeliveryPreferencesSaveFail(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveUpdateDeliveryPreferences,
      saveDeliveryPreferences,
      fetchDeliveryPreferences,
      saveDocumentDeliveryNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDeliveryPrompt);
