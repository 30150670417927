import styled from 'styled-components';
import { Row, Grid, Col } from 'react-flexbox-grid';
import { ChevronRight } from '@mui/icons-material';
import { P, ContainerLegend, ContainerRow, H3 } from '~/common/styles/baseStyle';

export const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    padding: 0px 20px 10px 10px;
`;

export const TotalLabel = styled.div<{ topPadding?: number }>`
    font-family: 'Open Sans Bold';
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #1B1B1B;
    padding-top: ${(props) => (props.topPadding ? `${props.topPadding}px` : '0px')};
`;

export const Total = styled.div`
    font-family: 'Montserrat Bold';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: #003DA5;
    padding-top:5px;
`;

export const ChangeLabel = styled.div`
    padding-top: 0px;
    font-family: 'Open Sans Bold';
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #1B1B1B;
`;

export const Link = styled.span`
    font-family: 'OpenSans',  'Open Sans';
    font-style: normal;
    font-size: 16px;
    cursor: pointer;
    line-height: 30px;
    &:hover {
        color: #003da5;
    }
`;

export const BtToggle = styled.div<{ isActif: boolean }>`
    font-family: ${(props) => props.isActif ? '"Open Sans Bold"' : '"Open Sans"'}; 
    font-weight: ${(props) => props.isActif ? 700 : 400}; 
    font-style: normal;
    font-size: 14px;
    width: 190px;
    cursor: pointer;
    padding-bottom:5px;
    border-bottom: 3px solid ${(props) => props.isActif ? '#003DA5' : '#CCCCCC'};
    color: #1B1B1B;
`;

export const MirrorViewContainer = styled.div`
    height: 46px;
    background: transparent
    padding: 4px 0px;
`;

export const BaselineRow = styled(Row)`
    alignItems: baseline
`;

export const StyledChevronRight = styled(ChevronRight)`
    top: .2em;
    position: relative
`;

export const MonetaryValueContainer = styled(P)`
    padding-right: 50px 
`;

export const DetailsGrid = styled(Grid)`
    width: 100%;
    background: #EFF1F4;
    padding: 20px
`;

export const FullNameContainer = styled(P)`
    font-size: 20px;
    font-weight: 400;
    font-family: "Montserrat Bold";
    color: #000;
`;

export const ChartContainer = styled(Grid)`
    width: 100%
`;

export const AssetAllocationLegendColumn = styled(Col)`
    padding-right: 14px;
`;

export const NetInvestmentLegend = styled(ContainerLegend)`
    width: 160px;
`;

export const CenteredContainerRow = styled(ContainerRow)`
    justify-content: center;
`;

export const AllAccountsTitle = styled(H3)`
    padding: 14px 14px 14px 22px;
`;
