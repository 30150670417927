import axios from 'axios';

import {
  API_CLAIMSMANAGER,
  IMPERSONATE,
  IMPERSONATE_ACCOUNTS,
  IMPERSONATE_ACCOUNTS_OWNED,
  IMPERSONATE_ACCOUNTS_SHARED,
  IMPERSONATE_PARTY_ID,
  IMPERSONATE_REP_CODES,
  IMPERSONATE_REP_CODES_OWNED_ACCOUNTS,
  IMPERSONATE_REP_CODES_SHARED_ACCOUNTS,
} from '~/common/API';
import { IToken } from '../systemTypes';

export const UserHttpClient = new (class {
  public fetchClaims = async () => {
    let data: any;
    if (IMPERSONATE) {
      data = {
        fw_accounts: IMPERSONATE_ACCOUNTS,
        fw_accounts_owned: IMPERSONATE_ACCOUNTS_OWNED,
        fw_rep_codes: IMPERSONATE_REP_CODES,
        fw_accounts_shared: IMPERSONATE_ACCOUNTS_SHARED,
        fw_party_id: IMPERSONATE_PARTY_ID,
        fw_rep_codes_shared_accounts: IMPERSONATE_REP_CODES_SHARED_ACCOUNTS,
        fw_rep_codes_owned_accounts: IMPERSONATE_REP_CODES_OWNED_ACCOUNTS,
      };
      console.info('Impersonating Party information', data);
    } else {
      try {
        const response = await axios.get(`${API_CLAIMSMANAGER}`);
        if (this.hasReceivedACachedData(response.data)) {
          data = response.data.custom_claims;
        } else {
          data = response.data.data;
        }
      } catch (error) {
        console.log('CATCHED ERROR');
        console.log(`-----> ${error}`);
      }
    }
    const claims: IToken = {
      partyId: data.fw_party_id,
      accounts: this.parseArrayIfExist(data.fw_accounts),
      accounts_owned: this.parseArrayIfExist(data.fw_accounts_owned),
      accounts_shared: this.parseArrayIfExist(data.fw_accounts_shared),
      repCodes: this.parseArrayIfExist(data.fw_rep_codes),
      roles: this.parseArrayIfExist(data.fw_roles),
    };
    return claims;
  };

  private hasReceivedACachedData = (data: any) => data.custom_claims !== null && data.custom_claims !== undefined;

  private parseArrayIfExist = (unparsed: string) => {
    if (unparsed === '' || unparsed === undefined) {
      return [];
    }

    return JSON.parse(unparsed);
  };
})();
