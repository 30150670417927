import * as React from 'react';
import { Trans } from 'react-i18next';
import Info from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Colors from '~/common/themes/colors';
import { Link } from '@mui/material';
import styled from 'styled-components';
import { stylesIcons } from './info-container.style';

export interface InfoProps {
  title: string;
  linkTitle?: string;
  title2?: string;
  text?: string;
  linkText?: string;
  clickFn?: () => void;
}

const styles = {
  largeIcon: {
    color: `${Colors.mariner}`,
    width: '30px',
    height: '30px',
    margin: '0 15px',
  },
};

export const LandingInfoSection = styled.div`
  background-color: #e3f2fd;
  color: ${Colors.anotherGrayShade3};
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  margin-bottom: 15px;
  border-color: ${Colors.mariner};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
`;

export const TextContainer = styled.div`
  font-size: 14px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  font-weight: 400;
`;

export const InfoContainer = (props: InfoProps) => (
  <LandingInfoSection>
    <Grid container alignItems="flex-start" spacing={0}>
      <Grid item style={{ textAlign: 'center' }}>
        <Info style={stylesIcons.largeIcon} />
      </Grid>
      <Grid item xs={12} container sm>
        <TextContainer>
          <Trans i18nKey={props.title} components={{ b: <strong /> }} />
          {props.linkTitle && (
            <Link onClick={props.clickFn}>
              <Trans i18nKey={props.linkTitle} components={{ b: <strong /> }} />
            </Link>
          )}
          {props.title2 && <Trans i18nKey={props.title2} components={{ b: <strong /> }} />}
        </TextContainer>
        {props.text && (
          <Grid item xs={12}>
            <TextContainer>
              <Trans i18nKey={props.text} />
              {props.linkText && (
                <Link onClick={props.clickFn}>
                  <Trans i18nKey={props.linkText} />
                </Link>
              )}
            </TextContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  </LandingInfoSection>
);

export default InfoContainer;
