import Grid from '@mui/material/Grid/Grid';
import { IAppRootState } from '~/app/rootReducer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchDividendsHistoricalData } from '~/stores/positionDetail/actions/positionDetailActions';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import { Container, H3 } from '~/common/styles/baseStyle';
import { GenericDataTable } from '~/common/components/data-table-legacy/GenericDataTable';
import { IHeader } from '~/common/components/data-table-legacy/types';
import { IDividendHistory } from '~/stores/positionDetail/types';
import Divider from '@mui/material/Divider/Divider';
import { formatMoneyPerLocale, isFieldEmptyOrNull } from '~/common/helpers';
import Loading from '../../../Loading/Loading';
import { StyledPaper } from './Dividends.style';
import DividentsBarChart from './Dividends.chart';
import { DividendSummarySection } from './DividendsSummary';

interface IDividendsHistoricalDataProps {
  symbol: string
  etf?: boolean
  positionDetails: any
  fetchDividendsHistoricalData: typeof fetchDividendsHistoricalData
}

const Dividends: React.FunctionComponent<IDividendsHistoricalDataProps> = (props) => {
  useEffect(() => {
    if (!props.positionDetails.positions[props.symbol].dividendsHistoricalData) {
      props.fetchDividendsHistoricalData(props.symbol);
    }
  }, [props.symbol]);

  const headers: IHeader[] = [
    {
      id: 'frequency',
      label: i18n.t('stockOverview.tabs.dividends.period'),
      sortable: true,
    },
    {
      id: 'amount',
      label: i18n.t('stockOverview.tabs.dividends.amount'),
      sortable: true,
    },
    {
      id: 'exDate',
      label: i18n.t('stockOverview.tabs.dividends.exDividendDate'),
      sortable: true,
      dataType: 'date',
    },
    {
      id: 'payDate',
      label: i18n.t('stockOverview.tabs.dividends.payDate'),
      sortable: true,
      dataType: 'date',
    },
  ];

  return (
    <StyledPaper>
      {
        props.positionDetails.isFetchingDividendsHistoricalData
          ?
          (
            <Container>
              <Loading show position="relative" />
            </Container>
          )
          :
          (
            <Grid container spacing={2} justifyContent="center">
              {
                props.positionDetails.positions[props.symbol].stockDetailedInfo &&
                (props.positionDetails.positions[props.symbol].stockDetailedInfo.stockInfo || props.positionDetails.positions[props.symbol].stockDetailedInfo.etfInfo) &&
                props.positionDetails.positions[props.symbol].dividendsHistoricalData
                &&
                <DividendSummarySection
                  nextDividendPayDate={props.positionDetails.positions[props.symbol].dividendsHistoricalData.nextPayableDividendDate}
                  ForwardDividendPerShare={props.positionDetails.positions[props.symbol].stockDetailedInfo.expectedDividendPerShare}
                  ForwardDividendYield={props.positionDetails.positions[props.symbol].stockDetailedInfo.expectedDividendYield}
                  etf={props.etf}
                />
              }
              {
                props.positionDetails.positions[props.symbol].dividendsHistoricalData
                  &&
                  props.positionDetails.positions[props.symbol].dividendsHistoricalData.dividendHistory.length > 0
                  ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <H3>{(props.etf ? i18n.t('stockOverview.tabs.dividends.distributionHistory') : i18n.t('stockOverview.tabs.dividends.dividendHistory')) as string}</H3>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <DividentsBarChart dividendHistory={props.positionDetails.positions[props.symbol].dividendsHistoricalData.dividendHistory} />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <Divider variant="fullWidth" />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <GenericDataTable
                          densed
                          headers={headers}
                          rows={props.positionDetails.positions[props.symbol].dividendsHistoricalData.dividendHistory.map((item: IDividendHistory) => ({
                            ...item,
                            frequency: i18n.t(`stockOverview.tabs.dividends.${item.frequency.toLowerCase()}`),
                            amount: `${formatMoneyPerLocale(item.amount)} ${item.currency}`,
                            recordDate: isFieldEmptyOrNull(item.recordDate) ? '-' : item.recordDate,
                          }))}
                          pagination={{ rowsPerPage: 5 }}
                          defaultSortColumnId="payDate"
                          defaultSortDirection="desc"
                        />

                      </Grid>

                    </>) : <NoData text={i18n.t('common.noData')} />
              }
            </Grid>
          )
      }
    </StyledPaper>
  );
};

function mapStateToProps(state: IAppRootState) {
  const { positionDetails } = state;
  return {
    positionDetails,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchDividendsHistoricalData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dividends);
