import * as React from 'react';
import { Table } from 'antd';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { Trans } from 'react-i18next';
import AccountDisplaySummary from '~/common/components/account/account-display-summary/accountDisplaySummary';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import './AccountDisplay.css';
import { getIsMarketDataFetching, getIsMarketDataFetchingHasError } from '~/stores/account/selectors/accountSelectors';
import { withRouter } from 'react-router-dom';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { accountHasPositionWithAccruedInterest } from '~/common/accountsHelpers';
import { RouteComponentProps } from 'react-router';
import SummarySplitGrid from '../account-view/SummarySplitGrid';
import { ComponentWithValidation } from '../../position-overview/summary-table/ComponentWithValidation';
import NumberQI from '../../changeValue/NumberQI';
import { buildDataArrayForBookCostSection } from '../account-view/SummaryAccountViewHelper';
import { Grid } from '@mui/material';
import {
  AccountPositionsContainers,
  IAccountDisplayProps,
  IAccountPropsFromState,
  IAccountSortingDisplayState,
  IAccountsPropsFromDispatch,
  align,
} from './AccountDisplayTypes';
import ChangeValue from '../../changeValue/ChangeValue';
import {
  renderMarketValuePrice,
  renderSymbol,
  saveHoldingSortPreferencesIfNeeded,
  sorterByBookValue,
  sorterByDescription,
  sorterByIndividualPrice,
  sorterByQuantity,
  sorterByUnrealizedGainAndLoss,
} from './AccountViewDisplayHelper';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { Dispatch, bindActionCreators } from 'redux';
import { saveHoldingSortPreferences } from '~/stores/party/actions/partyActions';
import { SortColumnKeys } from '~/stores/party/partyTypes';
import { SortOrder } from 'antd/lib/table/interface';

export class BookCostAccountDisplay extends React.PureComponent<
  RouteComponentProps & IAccountDisplayProps & IAccountPropsFromState & IAccountsPropsFromDispatch,
  IAccountSortingDisplayState
> {
  constructor(props: RouteComponentProps & IAccountDisplayProps & IAccountPropsFromState & IAccountsPropsFromDispatch) {
    super(props);
    this.state = {
      sortPreference: this.props.sortPreference,
      dataSource: this.props.account.positions,
    };
  }

  private renderBookCostAveUnitCost = (value: any, row: any, index: number) => {
    const bookValue = row.currency === 'CAD' ? row.bookValueCad : row.bookValueUsd;
    const obj = {
      children: (
        <Grid container>
          <Grid item xs={12}>
            <DecimalValue
              value={bookValue}
              culture={this.props.culture}
              amountOfDigits={2}
              zeroPlaceholder="0"
              areTrailingZerosVisible={row.isMutualFund}
            />
          </Grid>
          <Grid item xs={12}>
            <DecimalValue
              value={row.averageUnitCost}
              culture={this.props.culture}
              amountOfDigits={2}
              zeroPlaceholder="0"
              areTrailingZerosVisible={row.isMutualFund}
            />
          </Grid>
        </Grid>
      ),
    };
    return obj;
  };

  private renderUnrealizeGainLoss = (value: any, row: any, index: number) => {
    const unrealizedGainAndLoss = row.currency === 'CAD' ? row.unrealizedGainAndLossCAD : row.unrealizedGainAndLossUSD;
    const obj = {
      children: (
        <Grid container>
          <Grid item xs={12}>
            <DecimalValue
              value={unrealizedGainAndLoss}
              culture={this.props.culture}
              amountOfDigits={2}
              zeroPlaceholder={i18n.t('holdings.NA')}
              areTrailingZerosVisible={row.isMutualFund}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="end">
            <ChangeValue
              percent={row.unrealizedGainAndLossPercent}
              decimalOverrideForPercent={2}
              notFoundStyleRules={{ size: 'default', style: { marginLeft: '30px' } }}
              singleLineFormat
              noArrow
              signType="none"
              alignRight
              noShowValuesSign={false}
            />
          </Grid>
        </Grid>
      ),
    };
    return obj;
  };

  private handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      saveHoldingSortPreferencesIfNeeded(
        { bookCostView: { sortColumn: sorter.columnKey, sortDirection: sorter.order } },
        this.props.isUserAdvisorOrAdmin,
        this.props.saveHoldingSortPreferences,
      );

      // change sorting of all accounts display tables
      this.props.onChangedTableSort(sorter.columnKey, sorter.order);
    }
  };

  private renderTable() {
    const { culture, sortPreference } = this.props;
    const columns = [
      {
        title: <Trans i18nKey="common.symbolDescription" />,
        key: SortColumnKeys.DESCRIPTION,
        dataIndex: 'description',
        render: (value: any, record: any) =>
          renderSymbol(record, this.props.isSymbolLinkEnabled, this.props.location.pathname),
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByDescription(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.DESCRIPTION
            ? (sortPreference?.sortDirection.toString() as SortOrder)
            : undefined,
        className: 'wrap',
        width: '30%',
      },
      {
        title: i18n.t('holdings.quantity'),
        key: SortColumnKeys.QUANTITY,
        dataIndex: 'quantity',
        render: (value: any, record: any) => (
          <DecimalValue
            value={value}
            culture={culture}
            amountOfDigits={2}
            zeroPlaceholder="0"
            areTrailingZerosVisible={record.isMutualFund}
          />
        ),
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByQuantity(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.QUANTITY
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        align: 'right' as align,
        width: '15%',
      },
      {
        title: <Trans i18nKey="holdings.marketPriceMarketValue" />,
        key: SortColumnKeys.MK_PRICE_VALUE,
        dataIndex: 'individualMarketPrice',
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByIndividualPrice(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.MK_PRICE_VALUE
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        render: (value: any, record: any) => renderMarketValuePrice(value, record, this.props.culture),
        align: 'right' as align,
        width: '18%',
      },
      {
        title: <Trans i18nKey="holdings.bookCostAverageUnitCost" />,
        key: SortColumnKeys.BC,
        dataIndex: 'bookValueCad',
        render: this.renderBookCostAveUnitCost,
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByBookValue(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.BC
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        align: 'right' as align,
        width: '18%',
      },
      {
        title: <Trans i18nKey="holdings.unrealizedGainAndLoss" />,
        key: SortColumnKeys.UNREALIZED_GL,
        dataIndex: 'unrealizedGainAndLoss',
        render: this.renderUnrealizeGainLoss,
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByUnrealizedGainAndLoss(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.UNREALIZED_GL
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        align: 'right' as align,
        width: '18%',
      },
    ];

    const sortText = i18n.t('common.sort');

    return (
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="security"
        columns={columns}
        dataSource={this.state.dataSource}
        pagination={false}
        locale={{
          emptyText: <NoData text={i18n.t('common.noPosition')} />,
          triggerDesc: i18n.t('common.sortDescending'),
          triggerAsc: i18n.t('common.sortAscending'),
        }}
        style={{ width: '100%' }}
        loading={this.props.isFetchingMarketData}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
        onChange={this.handleTableChange}
      />
    );
  }

  public render() {
    const { account, sortPreference } = this.props;
    this.setState({ sortPreference: this.props.sortPreference });
    const annotation = accountHasPositionWithAccruedInterest([account]) ? '*' : undefined;
    let initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByDescription(a, b));
    if (sortPreference?.sortColumn === SortColumnKeys.QUANTITY) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByQuantity(a, b));
    }
    if (sortPreference?.sortColumn === SortColumnKeys.MK_PRICE_VALUE) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByIndividualPrice(a, b));
    }
    if (sortPreference?.sortColumn === SortColumnKeys.BC) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByBookValue(a, b));
    }
    if (sortPreference?.sortColumn === SortColumnKeys.UNREALIZED_GL) {
      initialOrderedPositionByPreferences = account.positions.sort((a, b) => sorterByUnrealizedGainAndLoss(a, b));
    }
    this.setState({ dataSource: initialOrderedPositionByPreferences });
    return (
      <Grid container style={{ padding: '16px' }}>
        <Grid container item xs={12}>
          <AccountDisplaySummary account={account} />
        </Grid>
        <SummarySplitGrid
          labelKeyLeft="common.totalMarketValue"
          valueLeft={
            <>
              <ComponentWithValidation
                value={account.totalMarketValueCad}
                componentWithValue={<NumberQI value={account.totalMarketValueCad} isMoney />}
              />
              {annotation}
            </>
          }
          colsOnRight={3}
          dataOnRightSide={buildDataArrayForBookCostSection(account, this.props.shouldNotShowMarketDataInfo)}
        />
        <AccountPositionsContainers>{this.renderTable()}</AccountPositionsContainers>
      </Grid>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    culture: system.culture,
    isFetchingMarketData: getIsMarketDataFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveHoldingSortPreferences,
    },
    dispatch,
  );

export default connect<IAccountPropsFromState, IAccountsPropsFromDispatch, IAccountDisplayProps, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BookCostAccountDisplay));
