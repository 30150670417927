import * as React from 'react';
import { arrayIncludes } from '~/common/helpers';
import styled from 'styled-components';
import { Checkmark } from '~/common/components';
import { IOption } from './multiSelectDropdownTypes';
import { establishIfAllOptionsAreSelected, establishLeafOptionIds } from './multiSelectDropdownUtilities';

const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    margin: 0px 8px;
    border-bottom: 1px solid #ededed;

    &:last-child {
        border-bottom: none;
    }
`;
const CheckmarkContainer = styled.div<{ childLevel: number }>`
    margin-left: ${(props) => props.childLevel * 26}px;
`;
const RenderedOptionContainer = styled.div`
    display: flex;
    flex-grow: 1;
    margin-left: 12px;
`;

export interface IMultiSelectDropdownContentProps {
    allSelectedText: string,
    selectedOptionIds: string[],
    options: IOption[],
    renderOption: (option: IOption) => JSX.Element,
    onOptionSelected: (selectedOptionIds: string[]) => void
}

export interface IMultiSelectDropdownContentState {
    selectedOptionIds: string[],
    areAllOptionSelected: boolean
}

class MultiSelectDropdownContent extends React.PureComponent<IMultiSelectDropdownContentProps, IMultiSelectDropdownContentState> {
  private selectAllOptionId = 'selectAll';
  private leafOptionIds: string[];

  constructor(props: IMultiSelectDropdownContentProps) {
    super(props);

    const areAllOptionSelected = establishIfAllOptionsAreSelected(props.selectedOptionIds, props.options);
    this.leafOptionIds = establishLeafOptionIds(props.options);

    this.state = {
      selectedOptionIds: areAllOptionSelected
        ? []
        : props.selectedOptionIds,
      areAllOptionSelected,
    };
  }

  private handleSelectAllClick() {
    const { areAllOptionSelected } = this.state;

    if (areAllOptionSelected) {
      return;
    }

    this.setState({
      selectedOptionIds: [],
      areAllOptionSelected: true,
    });
    this.handleSelectedOptionsChanged([], true);
  }

  private handleSelectedOptionsChanged(selectedOptionIds: string[], areAllOptionSelected: boolean) {
    const { onOptionSelected } = this.props;

    const actualSelectionOptionIds = areAllOptionSelected
      ? []
      : selectedOptionIds;

    onOptionSelected(actualSelectionOptionIds);
  }

  public handleCheckmarkClick = (id: any) => {
    if (id === this.selectAllOptionId) {
      this.handleSelectAllClick();
    } else if (this.isOptionLeaf(id)) {
      this.handleLeafClick([id]);
    } else {
      this.handleNodeClick(id);
    }
  };

  private handleLeafClick = (optionIds: string[]) => {
    const { selectedOptionIds } = this.state;
    const { options } = this.props;

    let newSelectedOptionIds = [...selectedOptionIds];

    optionIds.forEach((optionId) => {
      const isOptionSelected = arrayIncludes(newSelectedOptionIds, optionId);

      if (isOptionSelected) {
        newSelectedOptionIds.splice(newSelectedOptionIds.indexOf(optionId), 1);
      } else {
        newSelectedOptionIds.push(optionId);
      }
    });

    const areAllOptionSelected = establishIfAllOptionsAreSelected(newSelectedOptionIds, options);

    if (areAllOptionSelected) {
      newSelectedOptionIds = [];
    }

    this.setState({
      selectedOptionIds: newSelectedOptionIds,
      areAllOptionSelected,
    });
    this.handleSelectedOptionsChanged(newSelectedOptionIds, areAllOptionSelected);
  };

  private handleNodeClick = (optionId: string) => {
    const { selectedOptionIds } = this.state;
    const { options } = this.props;

    const nodeOption = options.find((option) => option.id === optionId);

    if (!nodeOption || !nodeOption.childOptions) {
      return;
    }

    const notSelectedChildrenIds = nodeOption.childOptions
      .filter((childOption) => selectedOptionIds.indexOf(childOption.id) < 0)
      .map((childOption) => childOption.id);

    if (notSelectedChildrenIds.length <= 0) {
      const childrenIds = nodeOption.childOptions.map((childOption) => childOption.id);
      this.handleLeafClick(childrenIds);
    } else {
      this.handleLeafClick(notSelectedChildrenIds);
    }
  };

  private isOptionLeaf = (optionId: string) => this.leafOptionIds.indexOf(optionId) >= 0;

  private renderOption(selectedOptionIds: string[], option: IOption, childLevel: number) {
    const { renderOption } = this.props;
    const { areAllOptionSelected } = this.state;

    const isSelected = !option.childOptions
      ? !areAllOptionSelected && arrayIncludes(selectedOptionIds, option.id)
      : this.isNodeSelected(selectedOptionIds, option);

    return (
      <OptionContainer key={option.id}>
        <CheckmarkContainer childLevel={childLevel}>
          <Checkmark isChecked={isSelected} id={option.id} onClick={this.handleCheckmarkClick} />
        </CheckmarkContainer>
        <RenderedOptionContainer>
          {renderOption(option)}
        </RenderedOptionContainer>
      </OptionContainer>
    );
  }

  private isNodeSelected = (selectedOptionIds: string[], option: IOption) => {
    const { areAllOptionSelected } = this.state;

    if (!option.childOptions) {
      return false;
    }

    const selectedChildrenAmount = option.childOptions.filter((childOption) => arrayIncludes(selectedOptionIds, childOption.id)).length;

    return !areAllOptionSelected && selectedChildrenAmount === option.childOptions.length;
  };

  private renderOptions(options: IOption[], selectedOptionIds: string[], childLevel: number): JSX.Element[] {
    let allRenderedOptions = [] as JSX.Element[];
    options.forEach((option, index) => {
      let renderedOptions = [] as JSX.Element[];
      renderedOptions.push(this.renderOption(selectedOptionIds, option, childLevel));

      if (option.childOptions !== undefined && option.childOptions !== null && option.childOptions.length > 0) {
        const newChildLevel = childLevel + 1;
        renderedOptions = renderedOptions.concat(this.renderOptions(option.childOptions, selectedOptionIds, newChildLevel));
      }

      allRenderedOptions = allRenderedOptions.concat(renderedOptions);
    });

    return allRenderedOptions;
  }

  private renderSelectAllOption() {
    const { allSelectedText } = this.props;
    const { areAllOptionSelected } = this.state;

    return (
      <OptionContainer key={this.selectAllOptionId}>
        <CheckmarkContainer childLevel={0}>
          <Checkmark isChecked={areAllOptionSelected} id={this.selectAllOptionId} onClick={this.handleCheckmarkClick} />
        </CheckmarkContainer>
        <RenderedOptionContainer>
          {allSelectedText}
        </RenderedOptionContainer>
      </OptionContainer>
    );
  }

  public render() {
    const { options } = this.props;
    const { selectedOptionIds } = this.state;

    const childLevel = 0;

    return (
      <>
        {this.renderSelectAllOption()}
        {this.renderOptions(options, selectedOptionIds, childLevel)}
      </>
    );
  }
}

export default MultiSelectDropdownContent;
