import * as React from 'react';
import { connect } from 'react-redux';
import { IAccount } from '~/stores/account/accountTypes';
import { Trans } from 'react-i18next';
import i18n from '~/app/i18n';
import { MonetaryValue } from '~/common/components';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import NoData from '~/common/components/noData/NoData';
import { Table } from 'antd';
import AccountDisplaySummary from '~/common/components/account/account-display-summary/accountDisplaySummary';
import { AccountHeaderContainer } from '~/common/components/account/account-style';
import { BoxShadow } from '~/common/styles/baseStyle';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { IAppRootState } from '~/app/rootReducer';
import { getIsMarketDataFetching, getIsMarketDataFetchingHasError } from '~/stores/account/selectors/accountSelectors';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { withRouter } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { IntradayPositionTypes } from '~/common/types';
import { getAccountPositionChangeValues } from '~/common/accountsHelpers';
import { RouteComponentProps } from 'react-router';
import SymbolDescriptionDisplay from '../account-display/SymbolDescriptionDisplay';

export interface IAccountDisplayProps {
  account: IAccount;
  isSymbolLinkEnabled: boolean;
}

export interface IPropsFromState {
  isFetchingMarketData: boolean;
  didSetMarketDataRealtime: boolean;
  isFetchingMarketDataHasError: boolean;
}

export type align = 'right' | 'left';

export class AccountBookValueDisplay extends React.PureComponent<
  RouteComponentProps & IAccountDisplayProps & IPropsFromState
> {
  public renderPositionTableWithBookValue() {
    const { account } = this.props;

    const renderSymbol = (value: any, row: any, index: number) => {
      let ticker = `${row.marketPriceSource}:${row.symbol}`;
      if (row.investmentCategory === 'MF') {
        ticker = row.symbol;
      }

      let obj = {
        children: value,
      };
      obj = {
        children: (
          <SymbolDescriptionDisplay
            isSymbolLinkEnabled={this.props.isSymbolLinkEnabled}
            investmentCategory={row.investmentCategory}
            routeName={RouteNames.positionOverview}
            ticker={ticker}
            pathname={this.props.location.pathname}
            symbol={row.symbol}
            description={row.description}
            notFoundAtProvider={row.notFoundAtProvider}
          />
        ),
      };
      return obj;
    };

    const renderGain = (value: any, row: any, index: number) => {
      const obj = {
        children: (
          <div>
            <MonetaryValue culture={i18n.language} value={row.unrealizedGainAndLoss || 0} />
            <MonetaryValue culture={i18n.language} value={row.unrealizedGainAndLossPercent || 0} currency="%" />
          </div>
        ),
      };
      return obj;
    };

    const columns = [
      {
        title: <Trans i18nKey="common.symbolDescription" />,
        key: 'symbol',
        dataIndex: 'symbol',
        render: renderSymbol,
        sorter: /* istanbul ignore next */ (a: any, b: any, sortOrder: any) => {
          if (a.symbol != null && b.symbol != null) {
            return a.symbol.localeCompare(b.symbol);
          }
          if (a.symbol) {
            return sortOrder === 'ascend' ? 1 : -1;
          }
          if (b.symbol) {
            return sortOrder === 'ascend' ? -1 : 1;
          }
          return 0;
        },
        className: 'wrap',
      },
      {
        title: <Trans i18nKey="holdings.quantity" />,
        key: 'quantity',
        dataIndex: 'quantity',
        render: (value: any, record: any) => (
          <DecimalValue
            value={value}
            culture={i18n.language}
            amountOfDigits={3}
            zeroPlaceholder="0"
            areTrailingZerosVisible={record.isMutualFund}
          />
        ),
        sorter: (a: any, b: any) => /* istanbul ignore next */ a.quantity - b.quantity,
        align: 'right' as align,
        className: 'fontStandard',
        width: '90px',
      },
      {
        title: <Trans i18nKey="bookValue.marketPrice" />,
        key: 'individualMarketPrice',
        dataIndex: 'individualMarketPrice',
        sorter: (a: any, b: any) => /* istanbul ignore next */ a.individualMarketPrice - b.individualMarketPrice,
        render: (value: any, record: any) => {
          const { dollarChange, changePercent } = getAccountPositionChangeValues({
            account: this.props.account,
            dollarChange: record.changeValue,
            changePercent: record.changePercent,
            isFetchingMarketDataHasError: this.props.isFetchingMarketDataHasError,
          });

          return (
            <>
              <div>
                {record.individualMarketPrice !== record.individualMarketPriceInAccountCurrency ? (
                  <MonetaryValue
                    culture={i18n.language}
                    value={record.individualMarketPriceInAccountCurrency}
                    amountOfDigits={3}
                    zeroPlaceholder={`${i18n.t('bookValue.NA')}¹`}
                  />
                ) : (
                  false
                )}
                {record.individualMarketPrice !== record.individualMarketPriceInAccountCurrency ? (
                  <MonetaryValue
                    culture={i18n.language}
                    value={record.individualMarketPrice}
                    amountOfDigits={3}
                    currency={record.marketPriceCurrency}
                    zeroPlaceholder={`${i18n.t('bookValue.NA')}¹`}
                  />
                ) : (
                  <MonetaryValue
                    culture={i18n.language}
                    value={record.individualMarketPrice}
                    amountOfDigits={3}
                    zeroPlaceholder={`${i18n.t('bookValue.NA')}¹`}
                  />
                )}
              </div>
              {this.props.didSetMarketDataRealtime &&
              Object.values(IntradayPositionTypes).includes(record.alternateType as IntradayPositionTypes) ? (
                <ChangeValue
                  value={record.notFoundAtProvider ? undefined : dollarChange}
                  percent={record.notFoundAtProvider ? undefined : changePercent}
                  decimalOverride={3}
                  singleLineFormat
                  arrowFontSize="13"
                  mainContainerStyleRules={{ style: { display: 'inline-flex' } }}
                />
              ) : (
                <div />
              )}
            </>
          );
        },
        align: 'right' as align,
        width: '130px',
        className: 'fontStandard',
      },

      {
        title: <Trans i18nKey="bookValue.marketValue_tableColumn" />,
        key: account.currency === 'CAD' ? 'totalMarketPriceCad' : 'totalMarketPriceUsd',
        dataIndex: account.currency === 'CAD' ? 'totalMarketPriceCad' : 'totalMarketPriceUsd',
        sorter: (a: any, b: any) => /* istanbul ignore next */ a.totalMarketPriceCad - b.totalMarketPriceCad,
        render: (value: any, record: any) => (
          <MonetaryValue
            value={value}
            culture={i18n.language}
            currency={record.priceCurrency}
            zeroPlaceholder={i18n.t('holdings.NA')}
          />
        ),
        align: 'right' as align,
        width: '130px',
        className: 'fontStandard',
      },
      {
        title: <Trans i18nKey="bookValue.averageUnitCost" />,
        key: 'averageUnitCost',
        dataIndex: 'averageUnitCost',
        sorter: (a: any, b: any) =>
          /* istanbul ignore next */
          a.averageUnitCost - b.averageUnitCost,
        render: (value: any, record: any) => (
          <MonetaryValue
            value={value}
            culture={i18n.language}
            amountOfDigits={3}
            currency={record.priceCurrency}
            zeroPlaceholder="-"
          />
        ),
        align: 'right' as align,
        width: '100px',
        className: 'fontStandard',
      },
      {
        title: <Trans i18nKey="bookValue.bookValue_tableColumn" />,
        key: account.currency === 'CAD' ? 'bookValueCad' : 'bookValueUsd',
        dataIndex: account.currency === 'CAD' ? 'bookValueCad' : 'bookValueUsd',
        sorter: (a: any, b: any) => /* istanbul ignore next */ a.bookValueCad - b.bookValueCad,
        render: (value: any, record: any) => (
          <MonetaryValue value={value} culture={i18n.language} currency={record.priceCurrency} zeroPlaceholder="-" />
        ),
        align: 'right' as align,
        width: '110px',
        className: 'fontStandard',
      },
      {
        title: <Trans i18nKey="bookValue.unrealizedGainAndLoss_tableColumn" />,
        key: 'unrealizedGainAndLossPercent',
        dataIndex: 'unrealizedGainAndLossPercent',
        sorter: (a: any, b: any) => /* istanbul ignore next */ a.unrealizedGainAndLoss - b.unrealizedGainAndLoss,
        render: renderGain,
        align: 'right' as align,
        width: '100px',
        className: 'fontStandard',
      },
    ];
    const sortText = i18n.t('common.sort');

    return (
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="security"
        columns={columns}
        footer={this.footer as any}
        dataSource={account.positions}
        pagination={false}
        locale={{
          emptyText: <NoData text={i18n.t('common.noPosition')} />,
          triggerDesc: i18n.t('common.sortDescending'),
          triggerAsc: i18n.t('common.sortAscending'),
        }}
        loading={this.props.isFetchingMarketData}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
      />
    );
  }

  private footer(currentPageValues: any[]) {
    const key = 'individualMarketPrice';
    return (
      <span>
        {currentPageValues.find((pos) => (pos[key] as any) === 0) !== undefined &&
          `¹ ${i18n.t('holdings.nonDeterminable')}`}
      </span>
    );
  }

  public render() {
    const { account } = this.props;

    return (
      <BoxShadow>
        <AccountHeaderContainer>
          <AccountDisplaySummary account={account} />
        </AccountHeaderContainer>
        {this.renderPositionTableWithBookValue()}
      </BoxShadow>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    isFetchingMarketData: getIsMarketDataFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
  };
}

export default connect<IPropsFromState, {}, IAccountDisplayProps, IAppRootState>(mapStateToProps)(
  withRouter(AccountBookValueDisplay),
);
