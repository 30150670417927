import * as React from 'react';
import { Button, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import Colors from '~/common/themes/colors';

export const RoundedButton = ({
    title,
    isSelected,
    onClick,
    icon,
  }: {
    title: string;
    isSelected: boolean;
    onClick: () => void;
    icon?: ReactElement;
  }) => {
    const theme = useTheme();

    const color = isSelected ? Colors.mariner : Colors.lightGray;
    return (
      <Button
        key={title}
        size="large"
        variant="contained"
        color="secondary"
        sx={{
          height: '40px',
          borderRadius: '24px',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: isSelected ? 700 : 400,
          color,
          border: `${isSelected ? '2' : '1'}px solid ${isSelected ? Colors.mariner : Colors.iaGray2}`,
          backgroundColor: isSelected ? Colors.babyBlue : theme.palette.background.paper,
          ':hover': {
            cursor: isSelected ? 'auto' : 'pointer',
            boxShadow: 'none',
            border: `${isSelected ? '2' : '1'}px solid ${isSelected ? Colors.mariner : Colors.quillGray}`,
            backgroundColor: isSelected ? Colors.babyBlue : Colors.desertStorm,
            color,
          },
        }}
        onClick={onClick}
        startIcon={icon}
      >
        {title}
      </Button>
    );
  };
