import * as React from 'react';
import { Trans } from 'react-i18next';
import { Input, Checkbox, Select, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from '@mui/material/Button';
import { CircularProgress, Grid } from '@mui/material';
import {
  Option,
  CsvModalWarningContainer,
  CsvModalWarningIcon,
  CsvModalWarningText,
  CsvLabel,
} from './ExportCSV.style';

import { GetApp as DownloadIcon } from '@mui/icons-material';
import LinkButton from '../link-button/LinkButton';

export interface IExportCSVProps {
  showModal: boolean;
  modalTitle: string;
  handleOk: () => void;
  handleCancel: () => void;
  handleCsvFilename: (event: React.ChangeEvent<HTMLInputElement>) => void;
  csvFilename: string;
  handleCsvDelimiter: (csvDelimiter: string) => void;
  delimiter: string;
  handleCsvDecimalSeparator: (csvDecimalSeparator: string) => void;
  decimalSeparator: string;
  handleCsvRemoveAccent: (event: any) => void;
  removeAccent: boolean;
  disclaimer: string;
  dateFormatInclude?: boolean;
  csvDateFormat?: string;
  handleCsvDateFormat?: (event: any) => void;
}

export const ExportCSV = ({
  csvFilename,
  decimalSeparator,
  delimiter,
  disclaimer,
  handleCancel,
  handleCsvDecimalSeparator,
  handleCsvDelimiter,
  handleCsvFilename,
  handleCsvRemoveAccent,
  handleOk,
  modalTitle,
  removeAccent,
  showModal,
  dateFormatInclude,
  csvDateFormat,
  handleCsvDateFormat,
}: IExportCSVProps) => (
  <Modal
    title={modalTitle}
    centered
    visible={showModal}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={[
      <Button key="submit" onClick={handleOk} variant="contained" color="secondary">
        <Trans i18nKey="common.export" />
      </Button>,
      <Button key="back" onClick={handleCancel}>
        <Trans i18nKey="common.cancel" />
      </Button>,
    ]}
  >
    <CsvModalWarningContainer>
      <CsvModalWarningIcon>
        <InfoCircleOutlined />
      </CsvModalWarningIcon>
      <CsvModalWarningText>{disclaimer}</CsvModalWarningText>
    </CsvModalWarningContainer>

    <CsvLabel>
      <Trans i18nKey="history.csvFilenameLabel" />
    </CsvLabel>
    <Input disabled={false} value={csvFilename} onChange={handleCsvFilename} />

    <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Grid item xs={4}>
        <CsvLabel>
          <Trans i18nKey="history.csvDelimiterLabel" />
        </CsvLabel>
        <div>
          <Select defaultValue={delimiter} style={{ width: 80, marginTop: 0 }} onChange={handleCsvDelimiter}>
            <Option disabled={decimalSeparator === ','} value=",">
              ,
            </Option>
            <Option value=";">;</Option>
            <Option value="|">|</Option>
            <Option value="~">~</Option>
          </Select>
        </div>
      </Grid>
      <Grid item xs={4}>
        <CsvLabel>
          <Trans i18nKey="history.csvDecimalSeparatorLabel" />
        </CsvLabel>
        <div>
          <Select
            defaultValue={decimalSeparator}
            style={{ width: 80, marginTop: 0 }}
            onChange={handleCsvDecimalSeparator}
          >
            <Option value=".">.</Option>
            <Option disabled={delimiter === ','} value=",">
              ,
            </Option>
          </Select>
        </div>
      </Grid>
      <Grid item xs={4}>
        {dateFormatInclude && (
          <>
            <CsvLabel>
              <Trans i18nKey="history.csvDateFormatLabel" />
            </CsvLabel>
            <div>
              <Select defaultValue={csvDateFormat} style={{ width: 130 }} onChange={handleCsvDateFormat}>
                <Option value="dd/mm/yyyy">dd/mm/yyyy</Option>
                <Option value="dd-mm-yyyy">dd-mm-yyyy</Option>
                <Option value="mm/dd/yy">mm/dd/yy</Option>
                <Option value="mm/dd/yyyy">mm/dd/yyyy</Option>
                <Option value="yyyy-mm-dd">yyyy-mm-dd</Option>
                <Option value="yyyy/mm/dd">yyyy/mm/dd</Option>
              </Select>
            </div>
          </>
        )}
      </Grid>
    </Grid>

    <Checkbox defaultChecked={removeAccent} onChange={handleCsvRemoveAccent}>
      <Trans i18nKey="history.csvRemoveAccentedLabel" />
    </Checkbox>
  </Modal>
);

export const ExportCSVButton = ({ onClick, isProcessing }: { onClick: () => void; isProcessing: boolean }) => (
  <LinkButton
    onClick={onClick}
    isProcessing={isProcessing}
    startIcon={!isProcessing ? <DownloadIcon /> : <CircularProgress size={15} />}
  >
    <Trans i18nKey="common.csvModalTitle" />
  </LinkButton>
);
