import * as React from 'react';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { Trans } from 'react-i18next';

import { Typography } from '@mui/material';
import { appTheme } from '~/common/themes/theme';
import { DocumentDeliveryStyle as Style } from './styles';
import { ChangeAllContainer, DarkSection, ChangeAllText } from './ChangeAllToPaperless.style';

interface ILocalProps {
  onChangeAllToPaperless: () => void;
}

const ChangeAllToPaperless: React.FC<ILocalProps> = (props: ILocalProps) => (
  <ChangeAllContainer onClick={props.onChangeAllToPaperless}>
    <DarkSection />
    <EnergySavingsLeafIcon htmlColor="#FFFFFF" style={Style.ecoIcon} />
    <ChangeAllText>
      <Typography variant="h6" style={{ fontWeight: 650, color: appTheme.palette.secondary.main }}>
        <Trans i18nKey="householding.changeAllToPaperless" />
      </Typography>
    </ChangeAllText>
  </ChangeAllContainer>
);

export default ChangeAllToPaperless;
