import * as React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Typography, FormControlLabel, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import Colors from '~/common/themes/colors';

export const OverviewToolTip = styled.div`
  color: #000;
  padding: 0px;
  padding-left: 2px;
  display: inline-block;
  svg {
    width: 16px;
    height: 16px;
    vertical-align: top;
  }
`;

interface IRadioProps {
  value: string;
  selected: boolean;
  label?: string;
  labelKey?: string;
  disabled?: boolean;
  invalid?: boolean;
  variant?: 'default' | 'blue';
}

const RadioControl: FC<IRadioProps> = (props: IRadioProps) => {
  const theme = useTheme();
  const isVariantBlue = props.variant === 'blue';

  let formControlBackgroundColor = props.selected ? Colors.babyBlue : theme.palette.background.paper;
  const color = props.selected || isVariantBlue ? Colors.mariner : Colors.lightGray;
  let borderColor = props.selected || isVariantBlue ? Colors.mariner : Colors.lightGray;

  if (props.invalid) {
    borderColor = 'rgb(231, 19, 19)';
  }

  if (isVariantBlue) {
    formControlBackgroundColor = '#FFFFFF';
  }

  return (
    <FormControlLabel
      style={{
        borderWidth: props.selected || props.invalid ? '2px' : '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        borderColor,
        padding: '5px',
        marginLeft: '0px',
        color,
        backgroundColor: props.selected ? 'rgb(227, 242, 253)' : formControlBackgroundColor,
      }}
      value={props.value}
      control={
        <Radio
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: '1.5px',
            paddingRight: '1.5px',
            borderWidth: '2px',
            color,
          }}
          color="secondary"
          size="small"
          disabled={props.disabled}
        />
      }
      label={
        <Typography
          style={{
            verticalAlign: 'middle',
            paddingRight: '2px',
            color,
          }}
          variant="body2"
          component="span"
        >
          {props.labelKey ? <Trans i18nKey={props.labelKey} /> : <>{props.label}</>}
        </Typography>
      }
    />
  );
};
export default RadioControl;
