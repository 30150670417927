import { call, put, takeLatest } from 'redux-saga/effects';
import { RequestStatus } from '~/common/types';
import { getMarketIdentificationCode, getSymbol } from '~/common/helpers';
import {
  fetchFinancialDataSuccessful, fetchHistoricalDataSuccessful, fetchFundDetailedInfoSuccessful, fetchFinancialDataFailure, fetchHistoricalDataFailure, fetchStockDetailedInfoFailure, fetchFundDetailedInfoFailure, fetchDividendsHistoricalDataSuccessful, fetchDividendsHistoricalDataFailure, fetchEarningsEstimateSuccessful, fetchEarningsEstimateFailure, fetchStockDetailedInfoRefreshFailure, fetchStockDetailedInfoRefreshSuccessful, fetchStockDetailedInfoSuccessful,
} from '../actions/positionDetailActions';
import { IDividendsHistoricalData, IFinancialInfo, IFundData, IHistoricalData, IStockData, PositionDetailActionTypes, IEarningsEstimate } from '../types';
import { PositionDetailsHttp } from '../services/positionDetailsHttp';
import { setMarketDataTimestamp } from '~/stores/marketData/actions/marketDataActions';

export function* fetchHistoricalData({ payload }: any) {
  const { symbol, frequency } = payload;
  try {
    const historicalData = (yield call(PositionDetailsHttp.fetchMarketDataStockHistorical, symbol, frequency) as unknown) as IHistoricalData;
    yield put(fetchHistoricalDataSuccessful(symbol, historicalData));
  } catch (err) {
    yield put(fetchHistoricalDataFailure(symbol, {
      symbol: getSymbol(symbol),
      market: '',
      marketIdentificationCode: getMarketIdentificationCode(symbol),
      currencyIsoCode: '',
      requestStatus: RequestStatus.FAILURE,
      periodicalDatas: [],
    }));
  }
}

export function* fetchFinancialData({ payload }: any) {
  const { symbol, frequency } = payload;
  try {
    const financialData = (yield call(PositionDetailsHttp.fetchMarketDataFinancialStatementInfo, symbol, frequency) as unknown) as IFinancialInfo;
    yield put(fetchFinancialDataSuccessful(symbol, frequency, financialData));
  } catch (err) {
    yield put(fetchFinancialDataFailure(
      symbol,
      frequency,
      {
        symbol: getSymbol(symbol),
        market: '',
        marketIdentificationCode: getMarketIdentificationCode(symbol),
        requestStatus: RequestStatus.FAILURE,
        incomeStatement: {},
        cashFlow: {},
        balanceSheet: {},
        ratio: {},
      },
    ));
  }
}

export function* fetchStockDetailedInfo({ payload }: any) {
  const { symbol } = payload;
  try {
    const stockDetailedInfo = (yield call(PositionDetailsHttp.fetchMarketDataStockDetailedInfo, symbol) as unknown) as IStockData;
    yield put(fetchStockDetailedInfoSuccessful(symbol, stockDetailedInfo));
    yield put(setMarketDataTimestamp());
  } catch (err) {
    yield put(fetchStockDetailedInfoFailure(
      symbol,
      {
        symbol: getSymbol(symbol),
        marketIdentificationCode: getMarketIdentificationCode(symbol),
        requestStatus: RequestStatus.FAILURE,
        stockInfo: {},
      },
    ));
  }
}

export function* fetchStockDetailedInfoRefresh({ payload }: any) {
  const { symbol } = payload;
  try {
    const stockDetailedInfo = (yield call(PositionDetailsHttp.fetchMarketDataStockDetailedInfoRefresh, symbol) as unknown) as IStockData;
    yield put(fetchStockDetailedInfoRefreshSuccessful(symbol, stockDetailedInfo));
  } catch (err) {
    yield put(fetchStockDetailedInfoRefreshFailure(symbol));
  }
}

export function* fetchFundDetailedInfo({ payload }: any) {
  const { symbol } = payload;
  try {
    const fundDetailedInfo = (yield call(PositionDetailsHttp.fetchMarketDataFundBasicInfo, symbol) as unknown) as IFundData;
    yield put(fetchFundDetailedInfoSuccessful(symbol, fundDetailedInfo));
    yield put(setMarketDataTimestamp());
  } catch (err) {
    yield put(fetchFundDetailedInfoFailure(
      symbol,
      {
        symbol: getSymbol(symbol),
        marketIdentificationCode: getMarketIdentificationCode(symbol),
        requestStatus: RequestStatus.FAILURE,
        profile: {},
        performance: {},
        assetAllocations: {},
        sectorAllocations: {},
        regionAllocations: {},
      },
    ));
  }
}

// EarningEstimate API call is not currently being used
export function* fetchEarningsEstimate({ payload }: any) {
  const { symbol } = payload;
  try {
    const earningsEstimate: IEarningsEstimate = (yield call(PositionDetailsHttp.fetchEarningsEstimate, symbol) as unknown) as IEarningsEstimate;
    if (earningsEstimate && (earningsEstimate.quarters.length > 0 || earningsEstimate.years.length > 0)) {
      yield put(fetchEarningsEstimateSuccessful(symbol, earningsEstimate));
    }
  } catch (err) {
    yield put(fetchEarningsEstimateFailure(symbol, {
      symbol: getSymbol(symbol),
      years: [],
      quarters: [],
    }));
  }
}

export function* fetchDividendsHistoricalData({ payload }: any) {
  const { symbol } = payload;
  try {
    const dividendsHistoricalData: IDividendsHistoricalData = (yield call(PositionDetailsHttp.fetchMarketDataDividendsHistoricalData, symbol) as unknown) as IDividendsHistoricalData;
    yield put(fetchDividendsHistoricalDataSuccessful(symbol, dividendsHistoricalData));
  } catch (err) {
    yield put(fetchDividendsHistoricalDataFailure());
  }
}

function* positionDetailSaga() {
  yield takeLatest(PositionDetailActionTypes.FETCH_HISTORICAL_DATA, fetchHistoricalData);
  yield takeLatest(PositionDetailActionTypes.FETCH_FINANCIAL_DATA, fetchFinancialData);
  yield takeLatest(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA, fetchStockDetailedInfo);
  yield takeLatest(PositionDetailActionTypes.FETCH_STOCK_DETAILED_INFO_DATA_REFRESH, fetchStockDetailedInfoRefresh);
  yield takeLatest(PositionDetailActionTypes.FETCH_FUND_DETAILED_INFO_DATA, fetchFundDetailedInfo);
  yield takeLatest(PositionDetailActionTypes.FETCH_EARNINGS_ESTIMATE, fetchEarningsEstimate);
  yield takeLatest(PositionDetailActionTypes.FETCH_DIVIDENDS_HISTORICAL_DATA, fetchDividendsHistoricalData);
}

export default positionDetailSaga;
