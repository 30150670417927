import styled from 'styled-components';
import { Grid, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainBox } from '~/common/styles/baseStyle';
import { appTheme } from '~/common/themes/theme';

export const StyledTabs = withStyles(Tabs, {
  root: {
    color: appTheme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: appTheme.palette.secondary.main,
  },
});

export const StyledTab = withStyles(Tab, {
  root: {
    padding: '15px 40px',
    marginRight: '10px',
    borderTop: '5px solid #EFF1F4',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'none',
    fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(','),
    background: '#BBC2CA ',
    '&:hover': {
      backgroundColor: appTheme.palette.secondary.main,
      color: '#ffffff',
    },
  },
  selected: {
    backgroundColor: '#ffffff',

  },
});

export const UpIconeStyle = {
  marginLeft: '3px',
  marginTop: '0px',
  color: '#3c763d',
  fontSize: '30px',
};

export const StockHeader = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    margin-top: 20px;
`;

export const StockName = styled.div`
    font-size: 24px;
    color: #003DA5;
    margin-right:30px;
`;

export const StockSymbol = styled.div`
    font-size: 16px;
    color: #003DA5;
    font-family: ${(props) => props.theme.fontBold};
    font-weight: ${(props) => props.theme.fontBoldTitleWeight};
`;

export const StockMarket = styled.div`
    font-size: 16px;
    color: #003DA5;
`;

export const StockPrice = styled.div`
    display: flex;
`;

export const StockData = styled.div`
    display: flex;
`;

export const StockPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right:100px;
`;

export const StockPriceCurrent = styled.div`
    display: flex;
    font-size: 30px;
    align-items: baseline;
    font-family: ${(props) => props.theme.fontBold};
    font-weight: ${(props) => props.theme.fontBoldTitleWeight};
`;

export const StockPriceChangeContainer = styled.div`
    font-size: 28px;
    display: flex;
    align-items: baseline;
`;

export const PriceChange = styled.div`
    color: #3c763d;
`;

export const StockPriceDateInfo = styled.div`
    font-size: 12px;
`;

export const StockDataDetail = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
`;

export const StockDataDetailRow = styled.div`
    display: flex;
    border-bottom: 1px solid #929292;
    padding: 5px;
`;

export const StockDataDetailItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-right:50px;

`;

export const PositionItemLabel = styled.div`
    font-family: ${(props) => props.theme.fontBold};
    font-weight: ${(props) => props.theme.fontBoldTitleWeight};
`;

export const PositionItemLabelSmall = styled.div`
    color: #836c4b;
    font-size:  11px;
`;

export const EmptyItemLabel = styled.div`
    min-height: 14px;
`;

export const PositionItemValue = styled.div`
    font-color: #000
`;

export const SectionHeaderLabel = styled.div`
    color: #1B1B1B;
    font-size: 21px;
    font-weight: 700;
    padding-top:10px;
    font-family:'Montserrat Bold', 'Montserrat Regular', 'Montserrat', sans-serif;
    margin-bottom: 15px;
`;

export const LastUpdateContainer = styled.div<{ color?: string }>`
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight : 400;
    color:  ${(props) => props.color ? props.color : '#003DA5'};
    text-align: left;
`;

export const HistoryChartContainer = styled.div`
    border: 1px solid #dadad8;
    background-color: #fff;
`;

export const TabsContainer = styled.div`
    background: #FFF;
    padding: 25px 20px 20px 20px;
`;

export const ChartContainer = styled.div`
    border:1px solid #DADAD8;
    width : 33.3%;
    height: 460px;
    float:left;
`;

export const ProfileContainer = withStyles(Grid, {
  container: {
    marginLeft: '10px',
    marginTop: '15px',
    marginBottom: '10px',
    marginRight: '10px',
  },
  item: {
    marginLeft: '10px',
    marginTop: '15px',
  },
});

export const PositionSummaryContainer = withStyles(Grid, {
  root: {
    padding: '0 20px 20px 20px',
    marginRight: '0px',
  },
});

export const PositionSummaryRow = withStyles(Grid, {
  root: {
    padding: '20px 0 0 0',
    marginRight: '0px',
  },
});

export const StyledContainer = styled(MainBox)`
    padding: 20px
`;
