import { RouteNames } from '~/app/appTypes';
import { INavigationOption } from './NavigationLinks';

export class CurrentLocationSetter {
  public set = (navigationOptions: INavigationOption[], currentLocation: string) => {
    navigationOptions.forEach((navigationOption) => this.setIsCurrentLocation(navigationOption, currentLocation));
  };

  private setIsCurrentLocation = (navigationOption: INavigationOption, currentLocation: string) => {
    if (!navigationOption.route && navigationOption.subOptions) {
      this.setSubOptionIsCurrentLocation(navigationOption.subOptions, currentLocation);
    }

    const hasCurrentSubOption = this.getIsParentOfCurrentLocation(navigationOption.subOptions);
    navigationOption.isCurrentLocation = this.getIsCurrentLocation(navigationOption.route, currentLocation, hasCurrentSubOption);
  };

  private getIsParentOfCurrentLocation = (subOptions: INavigationOption[] | undefined) => {
    let isParentOfCurrentLocation = subOptions && subOptions.some((sub) => sub.isCurrentLocation);
    if (isParentOfCurrentLocation === undefined) { isParentOfCurrentLocation = false; }

    return isParentOfCurrentLocation;
  };

  private setSubOptionIsCurrentLocation = (subNavigationOptions: INavigationOption[], currentLocation: string) => {
    subNavigationOptions.forEach((subNavigationOption) => {
      if (subNavigationOption.route && currentLocation.indexOf(subNavigationOption.route) >= 0) {
        subNavigationOption.isCurrentLocation = true;
      }
    });
  };

  private getIsCurrentLocation = (menuRoute: string | undefined, currentLocation: string, hasCurrentSubOption: boolean) => {
    if (menuRoute === RouteNames.landing && currentLocation === process.env.PUBLIC_URL) {
      return true;
    }
    if (menuRoute === RouteNames.householding && currentLocation.startsWith(RouteNames.householding)) {
      return true;
    }
    const isCurrentLocation = menuRoute && currentLocation === menuRoute;
    const isParentOfCurrentLocation = !menuRoute && hasCurrentSubOption;

    return isCurrentLocation || isParentOfCurrentLocation;
  };
}
