import * as React from 'react';
import { AccountHoldingsView, IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import AccountViewSubMenu from './AccountViewSubMenu';
import { ContentPageContainer } from '../HoldingsPage.style';
import AccountValueView from './AccountValueView';
import InvestedCapitalView from './InvestedCapitalView';
import BookCostView from './BookCostView';
import { Route, Switch } from 'react-router-dom';
import { RouteNames } from '~/app/appTypes';
import { refreshSession } from '~/stores/system/actions/systemActions';
import { IAppRootState } from '~/app/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ISortPreference } from '~/common/types';
import { HoldingViewKeys } from '~/stores/party/partyTypes';

export interface IAccountViewProps {
  accounts: IAccount[];
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary;
  shouldNotShowMarketDataInfo: boolean;
  holdingViewSortPreferences: Record<HoldingViewKeys, ISortPreference>;
}

export interface IPropsFromState {
  selectedAccountView: AccountHoldingsView;
}

interface IPropsFromDispatch {
  refreshSession: typeof refreshSession;
}

type Props = IPropsFromState & IPropsFromDispatch;

const AccountView: React.FC<Props & IAccountViewProps> = (props) => {
  const renderAccountValueView = () => {
    const { accounts, clientAccountTopLevelInfo, shouldNotShowMarketDataInfo, holdingViewSortPreferences } = props;
    props.refreshSession();
    return (
      <AccountValueView
        accountsParam={accounts}
        clientAccountTopLevelInfo={clientAccountTopLevelInfo}
        shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
        accountViewSortPreferences={holdingViewSortPreferences.accountView}
      />
    );
  };

  const renderIvestedCapitalView = () => {
    const { accounts, clientAccountTopLevelInfo, shouldNotShowMarketDataInfo, holdingViewSortPreferences } = props;
    props.refreshSession();
    return (
      <InvestedCapitalView
        accounts={accounts}
        clientAccountTopLevelInfo={clientAccountTopLevelInfo}
        shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
        investedViewSortPreferences={holdingViewSortPreferences.investedView}
      />
    );
  };

  const renderBookValueView = () => {
    const { accounts, clientAccountTopLevelInfo, shouldNotShowMarketDataInfo, holdingViewSortPreferences } = props;
    props.refreshSession();
    return (
      <BookCostView
        accounts={accounts}
        clientAccountTopLevelInfo={clientAccountTopLevelInfo}
        shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
        bookViewSortPreferences={holdingViewSortPreferences.bookCostView}
      />
    );
  };

  const selectedAccountViewRoute = (() => {
    switch (props.selectedAccountView) {
      case 'investedCapital':
        return RouteNames.holdingsAccountViewInvestedCapital;
      case 'bookCost':
        return RouteNames.holdingsAccountViewBookValue;
      default:
        return RouteNames.holdingsAccountViewAccountValue;
    }
  })();

  return (
    <>
      <AccountViewSubMenu />
      <ContentPageContainer>
        <Switch
          location={{
            search: '',
            pathname: selectedAccountViewRoute,
            state: undefined,
            hash: '',
          }}
        >
          <Route exact path={RouteNames.holdings} render={renderAccountValueView} />
          {/* <Route path={`${RouteNames.holdingsAccountViewInvestedCapital}`} render={renderIvestedCapitalView} /> */}
          <Route path={`${RouteNames.holdingsAccountViewBookValue}`} render={renderBookValueView} />
          <Route render={renderAccountValueView} />
        </Switch>
      </ContentPageContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ refreshSession }, dispatch);

function mapStateToProps(state: IAppRootState) {
  return {
    selectedAccountView: state.account.selectedHoldingsAccountView,
  };
}

export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountView);
