import { createSelector } from 'reselect';
import { IAppRootState } from '~/app/rootReducer';
import { IKYCState } from './kycTypes';

export const getKYCState = (state: IAppRootState): IKYCState => state.kyc;

export const getShowKYCPrompt = createSelector(
  getKYCState,
  (state: IKYCState) => state.show && !!state.reviewIdsDueToBeReminded.length,
);

export const getShowKYCMenu = createSelector(getKYCState, (state: IKYCState) => state.showMenu);

export const getKYCReviewIds = createSelector(getKYCState, (state: IKYCState) => state.reviewIds);
