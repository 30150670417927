import { connect } from 'react-redux';
import { Cultures, RouteNames } from '~/app/appTypes';
import { IAppRootState } from '~/app/rootReducer';
import SymbolDescriptionDisplay from '~/common/components/account/account-display/SymbolDescriptionDisplay';
import { isSymbolLinkEnabled } from '~/common/featureToggleHelper';
import * as React from 'react';
import i18n from '~/app/i18n';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { IMarketDataState, Trend, TrendType } from '~/stores/marketData/marketDataTypes';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { Skeleton, Stack } from '@mui/material';
import { IASparklineChart, type SparklineData } from './IASparklineChart';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NoData from '~/common/components/noData/NoData';
import DataTableV2, { IColumnsV2 } from '~/common/components/data-table/data-table-v2';

export interface IPropsFromState {
  culture: string;
  marketDataState: IMarketDataState;
}

interface ITrendsTableProps {
  trends: Trend[];
  trendType: TrendType;
}

const DEFAULT_RECORDS = 10;
const COMODITIES_DEFAULT_RECORDS = 4;

const emptyTrend: Trend = {
  type: 'commodity',
  symbol: '',
  currency: '',
  market: '',
  nameEn: '',
  nameFr: '',
  last: 0,
  change: 0,
  changePercentage: 0,
  history: [],
  baseHistoryValue: 0,
};

const loadingTrends = (trendType: TrendType): Trend[] =>
  Array.from({ length: trendType.includes('Sector') ? DEFAULT_RECORDS : COMODITIES_DEFAULT_RECORDS }, () => emptyTrend);

const TrendsTable = ({
  trends,
  culture,
  marketDataState,
  location,
  trendType,
}: ITrendsTableProps & IPropsFromState & RouteComponentProps) => {
  const columns: IColumnsV2[] = [
    {
      header: i18n.t('marketsPage.trends.table.description'),
      render: (record: Trend) => {
        // if at some point we need to display details for type === index, we need to add a condition for `${record.market}:${record.symbol}`
        const ticker = record.symbol;
        return (
          <Stack gap={1} height={40} justifyContent="center">
            {marketDataState.isFetchingTrends.get(trendType) ? (
              <>
                <Skeleton variant="rectangular" width="70%" height={18} />
                <Skeleton variant="rectangular" width="25%" height={18} />
              </>
            ) : (
              <SymbolDescriptionDisplay
                isSymbolLinkEnabled={isSymbolLinkEnabled() && !trendType.includes('Sector')}
                investmentCategory="EQ"
                routeName={RouteNames.marketProductOverview}
                ticker={ticker}
                type={record.type.toString()}
                pathname={location.pathname}
                notFoundAtProvider={false}
                symbol={`${
                  trendType === 'currency'
                    ? i18n.t(`marketProductDetails.currencySymbols.${record.symbol}`)
                    : culture === Cultures.english
                    ? record.nameEn
                    : record.nameFr
                }`}
                description={
                  trendType === 'currency' ? i18n.t(`marketsPage.trends.table.currencySymbols.${record.symbol}`) : ''
                }
                section="Trends"
              />
            )}
          </Stack>
        );
      },
      align: 'left',
    },
    {
      header:
        trendType === 'commodity'
          ? i18n.t('marketsPage.trends.table.lastUSD')
          : i18n.t('marketsPage.trends.table.last'),
      render: (record: Trend) =>
        marketDataState.isFetchingTrends.get(trendType) ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
            <Skeleton variant="rectangular" width="60%" height={18} />
          </Stack>
        ) : (
          <DecimalValue value={record.last} culture={culture} amountOfDigits={2} />
        ),
      align: 'right',
      width: '100',
    },
    {
      header: i18n.t('marketsPage.trends.table.dailyChange'),
      render: (record: Trend) =>
        marketDataState.isFetchingTrends.get(trendType) ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
            <Skeleton variant="rectangular" width="60%" height={18} />
          </Stack>
        ) : (
          <>
            <ChangeValue
              value={record.change}
              percent={record.changePercentage}
              decimalOverride={3}
              singleLineFormat
              arrowFontSize="17"
              mainContainerStyleRules={{ style: { display: 'inline-flex' } }}
            />
          </>
        ),
      align: 'right',
      width: '100',
    },
    {
      header: i18n.t('marketsPage.trends.table.yearlyChart'),
      render: (record: Trend) => {
        const sparklineData =
          record.history?.map((item) => ({ value: item.value, date: new Date(item.date) } as SparklineData)) || [];

        return marketDataState.isFetchingTrends.get(trendType) ? (
          <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <Skeleton variant="rounded" width="70%" height={40} />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <IASparklineChart
              baseLine={record.baseHistoryValue}
              data={sparklineData}
              trend={record.changePercentage < 0 ? 'negative' : 'positive'}
              height={40}
              width={80}
            />
          </Stack>
        );
      },
      align: 'center',
      width: '100',
    },
  ];

  const displayColumns = trendType.includes('Sector') ? columns.slice(undefined, 3) : columns;
  return (
    <DataTableV2
      columns={displayColumns}
      data={marketDataState.isFetchingTrends.get(trendType) ? loadingTrends(trendType) : trends}
      title=""
      emptyData={
        <Stack mt={3}>
          <NoData iconSize="md" text={i18n.t('marketsPage.trends.noData')} />
        </Stack>
      }
    />
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
    marketDataState: state.marketData,
  };
}

export default connect(mapStateToProps)(withRouter(TrendsTable));
