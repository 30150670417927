import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import * as React from 'react';

import Item from '../item-list/Item';
import ItemList from '../item-list/ItemList';
import { DragAndDropContent, DragAndDropZone, Icon, Message, StyledDiv } from './DragAndDropUpload.style';

const draggerProps: UploadProps = {
  name: 'files',
  multiple: true,
  directory: false,
  showUploadList: false,
};

interface ILocalProps {
  texts: any;
  files: UploadFile[];
  onFilesAdded: (filesInfo: UploadChangeParam) => void;
  onFileRemoved: (fileId: string) => void;
}

class DragAndDropUpload extends React.PureComponent<ILocalProps> {
  private handleBeforeUpload = (): boolean => false;

  private onDragAndDropZoneChanged = (filesInfo: UploadChangeParam): void => {
    const { onFilesAdded } = this.props;
    onFilesAdded(filesInfo);
  };

  private listItemsForFiles = (): Item[] => {
    const { files } = this.props;
    return files.map((file) => ({ id: file.uid, content: file.name }));
  };

  private onListItemRemoved = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemId: string): void => {
    const { onFileRemoved } = this.props;
    event.preventDefault();
    onFileRemoved(itemId);
  };

  public render() {
    const { texts, files } = this.props;
    return (
      <StyledDiv>
        <DragAndDropZone {...draggerProps} fileList={files} beforeUpload={this.handleBeforeUpload} onChange={this.onDragAndDropZoneChanged}>
          <DragAndDropContent>
            <Icon />
            <Message>{texts}</Message>
          </DragAndDropContent>
        </DragAndDropZone>
        {files.length > 0 && <ItemList items={this.listItemsForFiles()} onItemRemoved={this.onListItemRemoved} />}
      </StyledDiv>
    );
  }
}

export default DragAndDropUpload;
