import * as React from 'react';
import { Container, ContainerRow } from '~/common/styles/baseStyle';
import { PieChart, Pie, Cell } from 'recharts';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { SectionHeaderLabel } from '~/common/components/position-overview/PositionOverviewPage.style';

export interface IProps {
  data: any,
  title: string,
  type: string,
}
export default class FundAsset extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const COLORS = ['#003da5', '#3263b6', '#6489c7', '#765f40', '#907e65', '#ab9d8a'];
    if (this.props.data.length > 0) {
      return (
        <Container style={{ alignItems: 'center' }}>
          <SectionHeaderLabel> {this.props.title}</SectionHeaderLabel>

          <PieChart width={250} height={250}>
            <Pie data={this.props.data} dataKey="percent" isAnimationActive={false}>
              {this.props.data.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          {this.props.data.map((entry: any, index: number) => (
            <ContainerRow key={`legend-${index}`} style={{ paddingLeft: 3, paddingBottom: 3 }}>
              <div key={`color-${index}`} style={{ height: 20, backgroundColor: COLORS[index % COLORS.length], width: 20, marginRight: 2 }}>&nbsp;</div>
              <div style={{ width: 180, fontSize: 14 }}> {i18n.t(`${this.props.type}.${entry.nameEn}`) as string}</div>
              <div style={{ textAlign: 'right', width: 50, fontSize: 14 }}><NumberQI value={entry.percent} isPercent /></div>
            </ContainerRow>
          ))}
        </Container>

      );
    }
    return (
      <NoData text={i18n.t('common.noData')} />
    );
  }
}
