import { createTheme } from '@mui/material/styles';
import { appTheme } from './theme';

export const appFRTheme = createTheme(appTheme, {
  overrides: {
    MuiMenuItem: {
      root: {
        '&.MenuListDocumentsItem': {
          padding: '11px 10px 11px 20px !important',
        },
      },
    },
  },
});
