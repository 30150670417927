import { action } from 'typesafe-actions';
import {
  MarketDataActionTypes,
  MarketType,
  MarketMoverType,
  TopMover,
  TrendType,
  Trend,
  IKeyIndicator,
  Security,
} from '../marketDataTypes';

export const fetchMarketData = () => action(MarketDataActionTypes.FETCH_MARKET_DATA);

export const TopMoversSelectMarketType = (marketType: MarketType) =>
  action(MarketDataActionTypes.MARKET_DATA_TOP_MOVERS_SELECT_MARKET_TYPE, { marketType });
export const TopMoversSelectMarketMoverType = (marketMoverType: MarketMoverType) =>
  action(MarketDataActionTypes.MARKET_DATA_TOP_MOVERS_SELECT_MARKET_MOVER_TYPE, { marketMoverType });
export const fetchTopMovers = (marketType: MarketType, marketMoverType: MarketMoverType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS, { marketType, marketMoverType });
export const fetchTopMoversSuccessful = (
  topMovers: TopMover[],
  marketType: MarketType,
  marketMoverType: MarketMoverType,
) => action(MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_SUCCESSFUL, { topMovers, marketType, marketMoverType });
export const fetchTopMoversDataUnavailable = (marketType: MarketType, marketMoverType: MarketMoverType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_UNAVAILABLE, { marketType, marketMoverType });
export const fetchTopMoversFailed = (marketType: MarketType, marketMoverType: MarketMoverType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TOP_MOVERS_FAILED, { marketType, marketMoverType });

export const fetchSecurityList = (searchTerm: string) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST, { searchTerm });
export const fetchSecurityListSuccessful = (securityList: Security[]) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST_SUCCESSFUL, { securityList });
export const fetchSecurityListFailed = () => action(MarketDataActionTypes.FETCH_MARKET_DATA_SECURITY_LIST_FAILED);
export const securityViewed = (security: Security) =>
  action(MarketDataActionTypes.MARKET_DATA_SECURITY_VIEWED, { security });
export const securityListClear = () => action(MarketDataActionTypes.MARKET_DATA_SECURITY_LIST_CLEAR);

export const TrendsSelectMarketType = (trendType: TrendType) =>
  action(MarketDataActionTypes.MARKET_DATA_TRENDS_SELECT_MARKET_TYPE, { trendType });
export const fetchTrends = (trendType: TrendType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS, { trendType });
export const fetchTrendsSuccessful = (trends: Trend[], trendType: TrendType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS_SUCCESSFUL, { trends, trendType });
export const fetchTrendsFailed = (trendType: TrendType) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_TRENDS_FAILED, { trendType });
export const fetchKeyIndicators = () => action(MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS);
export const fetchKeyIndicatorsSuccessful = (keyIndicators: IKeyIndicator[]) =>
  action(MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS_SUCCESSFUL, { keyIndicators });
export const fetchKeyIndicatorsFailed = () => action(MarketDataActionTypes.FETCH_MARKET_DATA_KEY_INDICATORS_FAILED);
export const resetMarketData = () => action(MarketDataActionTypes.RESET_MARKET_DATA);
export const setMarketDataTimestamp = () => action(MarketDataActionTypes.SET_MARKET_DATA_TIMESTAMP);
