import * as React from 'react';
import { IAppRootState } from '~/app/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from '~/common/styles/baseStyle';
import { refreshSession } from '~/stores/system/actions/systemActions';
import {
  fetchHistoricalData,
  fetchStockDetailedInfo,
  fetchFundDetailedInfo,
  fetchStockDetailedInfoRefresh,
} from '~/stores/positionDetail/actions/positionDetailActions';
import { fetchMarketData } from '~/stores/account/actions/accountActions';
import { isFieldEmptyOrNull, isStockSymbol } from '~/common/helpers';
import { HistoricalFrequencyTypes, RequestStatus, SymbolInfoTypes } from '~/common/types';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import i18n from '~/app/i18n';
import StockView from '~/common/components/position-overview/stock/StockView';
import FundView from '~/common/components/position-overview/fund/FundView';
import EtfView from '../../common/components/position-overview/etf/EtfView';
import Loading from '../../common/components/Loading/Loading';
import {
  getAccountsFromState,
  getIsLiveDataFromState,
  getIsMarketDataFetching,
} from '~/stores/account/selectors/accountSelectors';
import { IAccountDictionnary } from '~/stores/account/accountTypes';

interface IPositionState {
  symbol: string;
  description: string;
  product: string;
  previous: string;
}

export interface IPropsFromState {
  culture: string;
  positionDetails: any;
  didSetMarketDataRealtime: boolean;
  isLiveData: boolean;
  isFetchingMarketData: boolean;
  accounts: IAccountDictionnary;
}

export interface IPropsFromDispatch {
  refreshSession: typeof refreshSession;
  fetchHistoricalData: typeof fetchHistoricalData;
  fetchStockDetailedInfo: typeof fetchStockDetailedInfo;
  fetchStockDetailedInfoRefresh: typeof fetchStockDetailedInfoRefresh;
  fetchFundDetailedInfo: typeof fetchFundDetailedInfo;
  fetchMarketData: typeof fetchMarketData;
}

class PositionOverviewPage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IPositionState> {
  protected mounted = false;
  private symbol = (this.props as any).location.state.symbol;
  private previous = (this.props as any).location.state.source;
  private previousSection = (this.props as any).location.state.section;
  private description = (this.props as any).location.state.description;
  constructor(props: any) {
    super(props);
    this.props.refreshSession();

    this.state = {
      symbol: this.symbol,
      description: this.description,
      product: this.symbol && this.symbol.indexOf(':') > 0 ? SymbolInfoTypes.STOCK : SymbolInfoTypes.FUND,
      previous: this.previous,
    };
  }

  public componentDidMount() {
    this.mounted = true;

    this.getHistoricalData();

    this.fetchProductInfo(this.symbol);

    window.scrollTo({ behavior: 'smooth', top: 0 });
  }

  public componentDidUpdate(prevProps: IPropsFromDispatch & IPropsFromState, prevState: IPositionState) {
    if ((this.props as any).location.state.symbol !== this.state.symbol) {
      this.symbol = (this.props as any).location.state.symbol;
      this.description = (this.props as any).location.state.description;
      this.getHistoricalData();

      this.setState({
        symbol: this.symbol,
        description: this.description,
        product: this.symbol.indexOf(':') > 0 ? SymbolInfoTypes.STOCK : SymbolInfoTypes.FUND,
        previous: this.previous,
      });
      this.fetchProductInfo(this.symbol);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public fetchProductInfo = (symbol: string) => {
    if (isStockSymbol(symbol)) {
      this.getStockInfo(symbol);
    } else {
      this.getFundInfo(symbol);
      document.title = i18n.t('stockOverview.fundTitle');
    }
  };

  private clientHeld = (symbol: string): boolean => {
    const { accounts } = this.props;
    for (const [key, value] of Object.entries(accounts)) {
      if (value.positions.filter((x) => `${x.marketPriceSource}:${x.symbol}` === symbol).length > 0) {
        return true;
      }
    }

    return false;
  };

  private getStockInfo = async (symbol: string) => {
    const held = this.clientHeld(symbol);
    if (
      isFieldEmptyOrNull(this.props.positionDetails.positions[symbol]) ||
      isFieldEmptyOrNull(this.props.positionDetails.positions[symbol].stockDetailedInfo) ||
      this.props.positionDetails.positions[symbol].stockDetailedInfo.requestStatus === RequestStatus.FAILURE
    ) {
      this.props.fetchStockDetailedInfo(symbol);
      if (this.props.didSetMarketDataRealtime && held) {
        this.props.fetchMarketData();
      }
    } else if (this.props.didSetMarketDataRealtime) {
      this.props.fetchStockDetailedInfoRefresh(symbol);
      if (held) {
        this.props.fetchMarketData();
      }
    }
  };

  private getFundInfo = async (symbol: string) => {
    if (
      isFieldEmptyOrNull(this.props.positionDetails.positions[symbol]) ||
      isFieldEmptyOrNull(this.props.positionDetails.positions[symbol].fundDetailedInfo) ||
      this.props.positionDetails.positions[symbol].fundDetailedInfo.requestStatus === RequestStatus.FAILURE
    ) {
      this.props.fetchFundDetailedInfo(symbol);
    }
  };

  private getHistoricalData() {
    // check if histocal data already download for the symbol, and refresh historical data is needed
    if (
      isFieldEmptyOrNull(this.props.positionDetails.positions[this.symbol]) ||
      isFieldEmptyOrNull(this.props.positionDetails.positions[this.symbol].historicalData) ||
      this.props.positionDetails.positions[this.symbol].historicalData.requestStatus === RequestStatus.FAILURE
    ) {
      this.props.fetchHistoricalData(this.symbol, HistoricalFrequencyTypes.DAILY);
    }
  }

  private refreshStockDetailedInfo = () => {
    this.props.fetchStockDetailedInfoRefresh(this.symbol);
    this.props.fetchMarketData();
  };

  public render() {
    if (
      this.props.positionDetails.isFetchingStockDetailedInfoData ||
      this.props.positionDetails.isFetchingFundDetailedInfoData ||
      this.props.positionDetails.isFetchingHistoricalData ||
      isFieldEmptyOrNull(this.props.positionDetails.positions[this.symbol])
    ) {
      return <Loading show />;
    }

    const isFromMarkets = this.previous.match(/markets/i) ? this.previous.match(/markets/i).length > 0 : false;
    return (
      <Container style={{ paddingTop: 20 }}>
        {this.state.previous && (
          <Link
            to={{
              pathname: this.previous,
              state: {
                section: this.previousSection,
              },
            }}
          >
            <Button style={{ color: '#003DA5', textTransform: 'none' }} startIcon={<ArrowBack />}>
              <Trans
                i18nKey={
                  this.previous.match(/holdings/i)
                    ? 'common.holdings'
                    : this.previous.match(/bookvalue/i)
                    ? 'common.bookValue'
                    : this.previous.match(/markets/i)
                    ? 'common.markets'
                    : ''
                }
              />
            </Button>
          </Link>
        )}

        {this.state.product === SymbolInfoTypes.STOCK ? (
          (this.props.positionDetails.positions[this.symbol].stockDetailedInfo.stockInfo && (
            <StockView
              symbolDescription={this.description}
              stockData={this.props.positionDetails.positions[this.symbol].stockDetailedInfo}
              historicalData={this.props.positionDetails.positions[this.symbol].historicalData}
              didSetMarketDataRealtime={this.props.didSetMarketDataRealtime}
              isLiveData={this.props.isLiveData}
              onBannerRefreshButtonClick={this.refreshStockDetailedInfo}
              onBannerRefreshButtonState={this.props.isFetchingMarketData}
              isHeld={this.clientHeld(this.symbol)}
              isFromMarketsScreen={isFromMarkets}
            />
          )) || (
            <EtfView
              symbolDescription={this.description}
              stockData={this.props.positionDetails.positions[this.symbol].stockDetailedInfo}
              historicalData={this.props.positionDetails.positions[this.symbol].historicalData}
              didSetMarketDataRealtime={this.props.didSetMarketDataRealtime}
              isLiveData={this.props.isLiveData}
              onBannerRefreshButtonClick={this.refreshStockDetailedInfo}
              onBannerRefreshButtonState={this.props.isFetchingMarketData}
              isHeld={this.clientHeld(this.symbol)}
              isFromMarketsScreen={isFromMarkets}
            />
          )
        ) : (
          <FundView
            symbolDescription={this.description}
            fundData={this.props.positionDetails.positions[this.symbol].fundDetailedInfo}
            historicalData={this.props.positionDetails.positions[this.symbol].historicalData}
            isHeld={this.clientHeld(this.symbol)}
            isFromMarketsScreen={isFromMarkets}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
    positionDetails: state.positionDetails,
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isLiveData: getIsLiveDataFromState(state),
    isFetchingMarketData: getIsMarketDataFetching(state),
    accounts: getAccountsFromState(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      refreshSession,
      fetchHistoricalData,
      fetchStockDetailedInfo,
      fetchStockDetailedInfoRefresh,
      fetchFundDetailedInfo,
      fetchMarketData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PositionOverviewPage) as any;
