import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  getDidSetIntradayChangePref,
  getDidSetMarketDataRealtime,
  getIsPartyFetching,
  getPartyV1,
} from '~/stores/party/selectors/partySelectors';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { IAppRootState } from '~/app/rootReducer';
import { Switch as SwitchToggle } from 'antd';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { Trans } from 'react-i18next';
import { saveMarketDataRealtime, saveIntradayChangePreference } from '~/stores/party/actions/partyActions';
import { Box, Typography } from '@mui/material';
import { SettingItemRow, SettingBox } from '../SettingPage.style';
import colors from '~/common/themes/colors';

interface IPropsFromDispatch {
  saveMarketDataRealtime: typeof saveMarketDataRealtime;
  saveIntradayChangePreference: typeof saveIntradayChangePreference;
}

export interface IPropsFromState {
  isPartyFetching: boolean;
  culture: string;
  didSetMarketDataRealtime: boolean;
  didSetIntradayChangePref: boolean;
  bIsUserAdvisorOrAdmin: boolean;
}

class HoldingsSettingsView extends React.PureComponent<IPropsFromState & IPropsFromDispatch> {
  constructor(props: IPropsFromState & IPropsFromDispatch) {
    super(props);
  }

  public saveMarketDataLivePreference = (checked: boolean) => {
    this.props.saveMarketDataRealtime(checked);
    if (!checked) {
      this.props.saveIntradayChangePreference(false);
    }
  };

  public saveIntradayChangePreference = (checked: boolean) => {
    this.props.saveIntradayChangePreference(checked);
  };

  public render() {
    const { didSetMarketDataRealtime, didSetIntradayChangePref, bIsUserAdvisorOrAdmin } = this.props;
    return (
      <>
        <SettingItemRow>
          <SettingBox>
            <Typography variant="body1">
              <Trans i18nKey="setting.intradayText" />
            </Typography>
            <Box display="flex" alignItems="center" style={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <SwitchToggle
                size="small"
                defaultChecked={didSetMarketDataRealtime}
                checked={didSetMarketDataRealtime}
                disabled={bIsUserAdvisorOrAdmin}
                onChange={this.saveMarketDataLivePreference}
              />
              <Typography variant="h6" component="span" style={{ paddingLeft: '10px' }}>
                <Trans i18nKey="setting.intradayToggle" />
              </Typography>
            </Box>

            <Typography variant="body1">
              <Trans i18nKey="setting.intradayChangeText" />
            </Typography>
            <Box display="flex" alignItems="center" style={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <SwitchToggle
                size="small"
                defaultChecked={didSetIntradayChangePref}
                checked={didSetIntradayChangePref}
                disabled={bIsUserAdvisorOrAdmin || !didSetMarketDataRealtime}
                onChange={this.saveIntradayChangePreference}
              />
              <Typography
                variant="h6"
                component="span"
                color={bIsUserAdvisorOrAdmin || !didSetMarketDataRealtime ? colors.iaGray2 : colors.codGray}
                style={{ paddingLeft: '10px' }}
              >
                <Trans i18nKey="setting.intradayChangeToggle" />
              </Typography>
            </Box>
          </SettingBox>
        </SettingItemRow>
        <Typography variant="body1">
          <Trans i18nKey="setting.intradayDisclaimer" />
        </Typography>
      </>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isPartyFetching: getIsPartyFetching(state),
    party: getPartyV1(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    didSetIntradayChangePref: getDidSetIntradayChangePref(state),
    bIsUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    culture: system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveMarketDataRealtime,
      saveIntradayChangePreference,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HoldingsSettingsView);
