import React, { ReactNode } from 'react';
import * as ReactDOM from 'react-dom';

export interface IPortalProps {
  children?: ReactNode;
}

class Portal extends React.PureComponent<IPortalProps> {
  private portalElement: Element;

  constructor(props: IPortalProps) {
    super(props);

    let portalsContainer = document.body.querySelector('.portalsContainer');
    if (!portalsContainer) {
      portalsContainer = document.createElement('div');
      portalsContainer.className = 'portalsContainer';
      document.body.appendChild(portalsContainer);
    }

    this.portalElement = document.createElement('div');
    portalsContainer.appendChild(this.portalElement);
  }

  public componentWillUnmount() {
    const portalsContainer = document.body.querySelector('.portalsContainer');
    if (this.portalElement && portalsContainer) {
      portalsContainer.removeChild(this.portalElement);
    }
  }

  public render() {
    const { children } = this.props;

    return ReactDOM.createPortal(
      children,
      this.portalElement,
    );
  }
}

export default Portal;
