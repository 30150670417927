import { RegulatoryRole } from '~/common/enum/RegulatoryRole';

export class RegulatoryRoleHelper {
  public static getRank(regulatoryRole: RegulatoryRole): number {
    switch (regulatoryRole) {
      case RegulatoryRole.UnlicensedAssistant:
        return 150;
      case RegulatoryRole.QcFinancialPlanner:
        return 175;
      case RegulatoryRole.LicensedAssistant:
        return 200;
      case RegulatoryRole.BranchAdministrator:
        return 250;
      case RegulatoryRole.Trader:
        return 275;
      case RegulatoryRole.AssociateInvestmentAdvisor:
        return 300;
      case RegulatoryRole.InvestmentAdvisor:
        return 350;
      case RegulatoryRole.WealthAdvisor:
        return 355;
      case RegulatoryRole.SeniorFinancialAdvisor:
        return 400;
      case RegulatoryRole.SeniorWealthAdvisor:
        return 450;
      case RegulatoryRole.SeniorWealthAdvisorFemale:
        return 451;
      case RegulatoryRole.SeniorInvestmentAdvisor:
        return 500;
      case RegulatoryRole.InstitutionalSupervisor:
        return 252;
      case RegulatoryRole.Supervisor:
        return 550;
      case RegulatoryRole.PortfolioManagerUnderSupervision:
        return 550;
      case RegulatoryRole.PortfolioManager:
        return 600;
      case RegulatoryRole.SeniorPortfolioManager:
        return 605;
      case RegulatoryRole.SupervisingPortfolioManager:
        return 650;
      case RegulatoryRole.CorporateBranchManager:
        return 700;
      case RegulatoryRole.SeniorBranchManager:
        return 750;
      case RegulatoryRole.RegionalVicePresident:
        return 800;
      case RegulatoryRole.ChiefFinancialOfficer:
        return 850;
      case RegulatoryRole.ChiefComplianceOfficer:
        return 900;
      default:
        return 9999;
    }
  }

  public static compare(a: RegulatoryRole, b: RegulatoryRole): number {
    const rankA = this.getRank(a);
    const rankB = this.getRank(b);
    if (rankA < rankB) {
      return 1;
    }
    if (rankA > rankB) {
      return -1;
    }
    return 0;
  }
}
