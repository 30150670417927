import * as React from 'react';
import { Trans } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Grid from '@mui/material/Grid';
import { AlertInfoSection, stylesIcons, Title, InfoText } from './info-container.style';
import { Button } from '@mui/material';

export interface AlertProps {
  title?: string;
  text?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  isButtonLoading?: boolean;
}

export const AlertContainer = (props: AlertProps) => (
  <AlertInfoSection>
    <Grid container alignItems="center" spacing={0}>
      <Grid item style={{ textAlign: 'center' }}>
        <ReportProblemIcon style={stylesIcons.largeAlertIcon} />
      </Grid>
      <Grid item xs={12} container sm>
        <Grid xs={12} container>
          <Title style={{ fontWeight: 'bold' }}>
            <Trans i18nKey={props.title} />
          </Title>
        </Grid>
        <Grid xs={12} container>
          <Grid xs={10} item>
            {props.text && (
              <InfoText>
                <Trans i18nKey={props.text} />
              </InfoText>
            )}
          </Grid>
          <Grid xs={2} item>
            {props.buttonText && (
              <Button
                key="apply"
                size="large"
                onClick={props.onButtonClick}
                variant="contained"
                color="secondary"
                disabled={props.isButtonLoading}
              >
                <span style={{ fontSize: '14px' }}>
                  <Trans i18nKey={props.buttonText} />
                </span>
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </AlertInfoSection>
);

export default AlertContainer;
