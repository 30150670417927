import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getIsPartyFetching, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { Trans } from 'react-i18next';
import { Container, PageTitleContainer, HeaderPageContainer, MainBox, LabelBold, P, ContainerRow, IAButtonOutline } from '~/common/styles/baseStyle';
import { Modal } from 'antd';
import i18n from '~/app/i18n';
import PrintButton from '~/common/components/print-button/PrintButton';
import HouseholdView from './household-view/HouseholdView';
import AccessSharingView from './accessharing-view/AccessSharingView';
import HouseholdSubMenu from './HouseholdSubMenu';
import DocumentDeliveryView from './documentdelivery-view/DocumentDeliveryView';
import Loading from '../../common/components/Loading/Loading';
import NicknameView from './nickname-view/NicknameView';

export interface IPropsFromState {
  party: IPartyV1,
  isPartyFetching: boolean,
  culture: string
}

export class HouseHoldingPage extends React.PureComponent<RouteComponentProps & IPropsFromState> {
  constructor(props: RouteComponentProps & IPropsFromState) {
    super(props);

    document.title = i18n.t('householding.documentTitle');
  }

  private renderHouseholdView = () => (
    <HouseholdView />
  );

  private renderAccessSharingView = () => (
    <AccessSharingView />
  );

  private renderDocumentDeliveryView = () => (
    <DocumentDeliveryView />
  );

  private renderNicknameView = () => (
    <NicknameView />
  );

  private isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  private showLearnMore = (e: any) => {
    Modal.info({
      title: i18n.t('householding.houseKeyTitle') as string,
      content: (
        <div>
          <Trans i18nKey="householding.information" components={{ b: <strong /> }} />
        </div>
      ),
    });
  };

  public render() {
    const { party, isPartyFetching } = this.props;

    if (isPartyFetching || this.isEmpty(party)) {
      return (
        <Container style={{ minHeight: 200 }}>
          <Loading show />
        </Container>
      );
    }

    return (
      <Container>
        <HeaderPageContainer>
          <PageTitleContainer>
            <Trans i18nKey="householding.houseHoldingTitle" />
          </PageTitleContainer>
          <PageTitleContainer>
            <MainBox style={{ padding: 7 }}>
              <ContainerRow>
                <LabelBold><Trans i18nKey="householding.houseKey" /><br />
                  <P style={{ fontSize: 14 }}>{party.id.substr(party.id.length - 6)}</P>
                </LabelBold>
                <IAButtonOutline onClick={this.showLearnMore} variant="outlined">
                  <Trans i18nKey="common.learnMore" />
                </IAButtonOutline>
              </ContainerRow>
            </MainBox>
          </PageTitleContainer>
        </HeaderPageContainer>

        <HouseholdSubMenu />

        <Switch>
          <Route exact path={RouteNames.householdingView} render={this.renderHouseholdView} />
          <Route path={`${RouteNames.accessSharingView}`} render={this.renderAccessSharingView} />
          <Route path={`${RouteNames.documentDeliveryView}`} render={this.renderDocumentDeliveryView} />
          <Route path={`${RouteNames.nickName}`} render={this.renderNicknameView} />
          <Route render={this.renderHouseholdView} />
        </Switch>
        <PrintButton />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isPartyFetching: getIsPartyFetching(state),
    party: getPartyV1(state),
    culture: system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HouseHoldingPage);
