import { IAccount } from '~/stores/account/accountTypes';
import { IAccountAccessSharingModel, IPartyAccount, IPartyV1 } from '~/stores/party/partyTypes';
import i18n from '~/app/i18n';
import { IPatchJsonOperation } from '../stores/system/systemTypes';
import { getAccountsFromRoot } from './accountsHelpers';

export interface IHouseholdViewData {
  key: string;
  owner: string;
  userId: string;
  accountNumber: string;
  productType: string;
  accountType: string;
  rowSpan: number;
  isUserOwner: boolean;
  isHousehold: boolean;
  isLastRow: boolean;
  role: string;
  noRole: string;
}

export enum HouseholdType {
  OWNER = 'CO',
  SHARING = 'SH',
  COMPANY = 'CORP',
}

export const CompanyRole = 31;

export const compareByName = (a: IAccount, b: IAccount): number => {
  if (a.name < b.name) { return -1; }
  if (a.name > b.name) { return 1; }
  return 0;
};

export const compareByAccountNumber = (a: IAccount, b: IAccount): number => {
  if (a.id < b.id) { return -1; }
  if (a.id > b.id) { return 1; }
  return 0;
};

export const mapPartyToShareListView = (sharingAccounts: IAccountAccessSharingModel[], accounts: IAccount[]): IHouseholdViewData[] => {
  const results = [] as IHouseholdViewData[];
  if (sharingAccounts !== undefined && sharingAccounts !== null) {
    let currentId = '';

    sharingAccounts.forEach((a, index) => {
      const listAccount = getAccountsFromRoot(accounts, [a.id]);

      let isFirstElement = false;
      if (currentId !== a.partyId) {
        currentId = a.partyId;
        isFirstElement = true;
      }

      results.push({
        key: a.id + a.partyId,
        role: a.role,
        noRole: a.noRole,
        userId: a.partyId,
        owner: a.name,
        productType: listAccount && listAccount.length > 0 ? listAccount[0].accountType : '',
        accountType: getAccountName(accounts, a.id),
        accountNumber: a.id,
        rowSpan: isFirstElement ? sharingAccounts.filter((w) => w.partyId === a.partyId).length : 0,
        isUserOwner: false,
        isHousehold: false,
        isLastRow: sharingAccounts.length === index + 1,
      });
    });
  }

  return results;
};

export const mapPartyToHouseholdView = (party: IPartyV1, accounts: IAccount[]): IHouseholdViewData[] => {
  const results = [] as IHouseholdViewData[];

  if (party && party.accounts) {
    const accountOwner = getIdsOwner(party);
    if (accountOwner.length > 0) {
      const listAccount = getAccountsFromRoot(accounts, accountOwner).sort(compareByAccountNumber);
      listAccount.forEach((a, index) => {
        results.push({
          key: a.id,
          role: '',
          noRole: '',
          userId: party.id,
          owner: i18n.t('common.individual'),
          productType: a.productType,
          accountType: a.accountType,
          accountNumber: a.id,
          rowSpan: index === 0 ? listAccount.length : 0,
          isUserOwner: true,
          isHousehold: party.preferedAccountIds.length > 0 ? party.preferedAccountIds.indexOf(a.id) > -1 : true,
          isLastRow: false,
        });
      });
    }

    const accountJoint = getIdsJoint(party);
    if (accountJoint.length > 0) {
      const listAccount = getAccountsFromRoot(accounts, accountJoint).sort(compareByAccountNumber);
      listAccount.forEach((a, index) => {
        results.push({
          key: a.id,
          role: '',
          noRole: '',
          userId: party.id,
          owner: i18n.t('common.joint'),
          productType: a.productType,
          accountType: a.accountType,
          accountNumber: a.id,
          rowSpan: index === 0 ? listAccount.length : 0,
          isUserOwner: true,
          isHousehold: party.preferedAccountIds.length > 0 ? party.preferedAccountIds.indexOf(a.id) > -1 : true,
          isLastRow: false,
        });
      });
    }

    const accountCompany = getAccountCompanyIds(party);
    accountCompany.forEach((companyRoot) => {
      if (accountCompany.length > 0) {
        const listAccount = getAccountsFromRoot(accounts, [companyRoot]).sort(compareByAccountNumber);
        listAccount.forEach((a, index) => {
          results.push({
            key: a.id,
            role: '',
            noRole: '',
            userId: party.id,
            owner: a.name,
            productType: a.productType,
            accountType: a.accountType,
            accountNumber: a.id,
            rowSpan: index === 0 ? listAccount.length : 0,
            isUserOwner: true,
            isHousehold: party.preferedAccountIds.length > 0 ? party.preferedAccountIds.indexOf(a.id) > -1 : true,
            isLastRow: false,
          });
        });
      }
    });

    const accountShare = getRootIdsShared(party);
    if (accountShare.length > 0) {
      const listShared = getAccountsFromRoot(accounts, accountShare).sort(compareByName);

      listShared.forEach((a, index) => {
        results.push({
          key: a.id,
          role: '',
          noRole: '',
          userId: party.id,
          owner: a.name,
          productType: a.productType,
          accountType: a.accountType,
          accountNumber: a.id,
          rowSpan: 1,
          isUserOwner: false,
          isHousehold: party.preferedAccountIds.length > 0 ? party.preferedAccountIds.indexOf(a.id) > -1 : true,
          isLastRow: listShared.length === index + 1,
        });
      });
    }
  }

  return results;
};

export const getAccountName = (accounts: IAccount[], rootId: string) => {
  const childAccounts = getAccountsFromRoot(accounts, [rootId]);
  const openAccounts = getOpenAccounts(childAccounts);
  if (openAccounts.length > 0) {
    return openAccounts[0].name;
  } if (childAccounts.length > 0) {
    return childAccounts[0].name;
  }
  return '';
};

export const getOpenAccounts = (accounts: IAccount[]) => accounts.filter((w) => w.isOpen);

export const getIdsOwner = (party: IPartyV1): string[] => {
  const filteredRoots = party.accounts.filter((a) => a.role === HouseholdType.OWNER && a.noRole === 11).map((m) => m.id);
  return filteredRoots;
};

export const getIdsJoint = (party: IPartyV1): string[] => {
  const filteredRoots = party.accounts.filter((a) => a.role === HouseholdType.OWNER && [21, 22].includes(a.noRole)).map((m) => m.id);
  return filteredRoots;
};

export const getAccountToShare = (party: IPartyV1): any[] => getOwnedOrCorpAccounts(party);

export const getOwnedOrCorpAccounts = (party: IPartyV1): any[] => {
  const filteredRoots = party.accounts.filter((a) => a.role === HouseholdType.OWNER || a.role === HouseholdType.COMPANY);
  return filteredRoots;
};

export const getOwnedOrCorpAccountsIds = (party: IPartyV1): string[] => getOwnedOrCorpAccounts(party).map((m) => m.id);

export const hasOwnedOrCorpAccounts = (party: IPartyV1): boolean => getOwnedOrCorpAccounts(party).length > 0;

export const getAccountCompanyIds = (party: IPartyV1): any[] => {
  const filteredRoots = party.accounts.filter((a) => a.role === HouseholdType.COMPANY).map((m) => m.id);
  return filteredRoots;
};

export const getRootIdsShared = (party: IPartyV1): string[] => {
  const filteredRoots = party.accounts.filter((a) => a.role === HouseholdType.SHARING).map((m) => m.id);
  return filteredRoots;
};

export const getPartyFullName = (party: IPartyV1): string => isBusiness(party.partyTypeCode) ? party.businessName : `${party.firstName} ${party.lastName}`;

export const isValidRepCode = (repCode: string): boolean => {
  if (repCode && repCode.length === 4) {
    return true;
  }

  return false;
};

export enum PartyTypeCode {
  Customer = 'customer',
  NonClient = 'non-client',
  Organization = 'organization',
}

export const isBusiness = (partyTypeCode: string): boolean => partyTypeCode.toLowerCase() === PartyTypeCode.Organization.toLowerCase();

export const sharedAccountsJsonPatchOperation = (accountsIdToShare: string[], from: IPartyV1, to: IPartyV1): IPatchJsonOperation[] => {
  const fromAccountsIds: string[] = from.accounts.map((account: IPartyAccount) => account.id);
  const actualAccountsSharedWithParty = to.accounts.filter((account: IPartyAccount) => account.role === HouseholdType.SHARING && fromAccountsIds.some((a: string) => a === account.id));
  const patchPath = '/accounts/';

  // check remove pathOperation
  actualAccountsSharedWithParty.forEach((account: IPartyAccount) => {
    if (accountsIdToShare.filter((el: string) => el === account.id).length === 0) {
      const indexToRemove = to.accounts.findIndex((accountToRemove: IPartyAccount) => account.id === accountToRemove.id);
      to.accounts.splice(indexToRemove, 1);
    }
  });

  // check add pathOperation
  accountsIdToShare.forEach((el: string) => {
    if (actualAccountsSharedWithParty.filter((account: IPartyAccount) => account.id === el).length === 0) {
      const accountToShare = from.accounts.find((account: IPartyAccount) => account.id === el);
      if (accountToShare) {
        const accountToAdd: IPartyAccount = {
          id: accountToShare.id,
          role: HouseholdType.SHARING,
          noRole: accountToShare.noRole,
          repCode: accountToShare.repCode,
        };

        to.accounts.push(accountToAdd);
      }
    }
  });

  return [{ op: 'replace', path: `${patchPath}`, value: to.accounts }];
};
