import * as React from 'react';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { isFieldEmptyOrNull } from '~/common/helpers';
import DataGrid, { IDataGridDisplay } from '~/common/components/data-grid/data-grid';

export interface IProps {
    stock: any
}

class Last12Months extends React.PureComponent<IProps> {
  public render() {
    const data : IDataGridDisplay[] = [
      {
        key: 'stockOverview.cashflowPerShare',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.cashFlowPerShareTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.cashFlowPerShareTTM} isMoney />,
      },
      {
        key: 'stockOverview.earningsPerShare',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.earningsPerShareTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.earningsPerShareTTM} isMoney />,
      },
      {
        key: 'stockOverview.earningsYield',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.earningsYieldTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.earningsYieldTTM} isPercent />,
      },
      {
        key: 'stockOverview.netIncome',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.netIncomeTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.netIncomeTTM} isMoney />,
      },
      {
        key: 'stockOverview.priceToBook',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.priceToBookTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.priceToBookTTM} />,
      },
      {
        key: 'stockOverview.priceToCashflow',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.priceToCashFlowTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.priceToCashFlowTTM} />,
      },
      {
        key: 'stockOverview.priceToEarnings',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.priceToEarningsRatioTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.priceToEarningsRatioTTM} />,
      },
      {
        key: 'stockOverview.priceToSales',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.priceToSalesTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.priceToSalesTTM} />,
      },
      {
        key: 'stockOverview.totalRevenue',
        value: isFieldEmptyOrNull(this.props.stock.stockInfo.totalRevenueTTM) ? <a>-</a> : <NumberQI isFormatLargeNumber value={this.props.stock.stockInfo.totalRevenueTTM} isMoney />,
      },
    ];
    return (
      <DataGrid data={data} title="stockOverview.last12Months" />
    );
  }
}

export default Last12Months;
