import * as React from 'react';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { RouteNames } from '~/app/appTypes';
import { Location } from 'history';
import { Grid, Stack } from '@mui/material';
import { RoundedButton } from '~/common/components/button/RoundedButton';
import i18n from '~/app/i18n';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Colors from '~/common/themes/colors';
import Monitoring from '~/common/icons/Monitoring.svg';
import Book4 from '~/common/icons/Book4.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AccountHoldingsView } from '~/stores/account/accountTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { selectHoldingsAccountView as selectHoldingsAccountViewFunction } from '~/stores/account/actions/accountActions';

export interface IPropsFromState extends RouteComponentProps {
  currentLocation: string;
  currentSearch: string;
  selectedAccountView: AccountHoldingsView;
}

interface IPropsFromDispatch {
  selectHoldingsAccountView: typeof selectHoldingsAccountViewFunction;
}

const AccountViewSubMenu = ({
  currentSearch,
  history,
  selectHoldingsAccountView,
  selectedAccountView,
}: IPropsFromState & IPropsFromDispatch) => {
  const handleClickChangeButtonTab = (path: string) => {
    const selectedAccountViewBasedOnRoute: AccountHoldingsView = (() => {
      switch (path) {
        case RouteNames.holdingsAccountViewInvestedCapital:
          return 'investedCapital';
        case RouteNames.holdingsAccountViewBookValue:
          return 'bookCost';
        default:
          return 'account';
      }
    })();

    selectHoldingsAccountView(selectedAccountViewBasedOnRoute);

    history.push(path);
  };

  return (
    <>
      <Stack direction="row" margin={2} marginTop={4} gap={1}>
        <RoundedButton
          isSelected={selectedAccountView === 'account'}
          onClick={() => handleClickChangeButtonTab(`${RouteNames.holdingsAccountViewAccountValue}${currentSearch}`)}
          title={i18n.t('holdings.accountTabView.accountValue')}
          key="accountValuebuttonTab"
          icon={<AccountBalanceIcon sx={{ color: Colors.mariner }} />}
        />
        {/* <RoundedButton
          isSelected={selectedAccountView === 'investedCapital'}
          onClick={() => handleClickChangeButtonTab(`${RouteNames.holdingsAccountViewInvestedCapital}${currentSearch}`)}
          title={i18n.t('holdings.accountTabView.investedCapital')}
          key="investedCapitalButtonTab"
          icon={<img src={Monitoring} color={Colors.mariner} />}
        /> */}
        <RoundedButton
          isSelected={selectedAccountView === 'bookCost'}
          onClick={() => handleClickChangeButtonTab(`${RouteNames.holdingsAccountViewBookValue}${currentSearch}`)}
          title={i18n.t('holdings.accountTabView.bookCost')}
          key="bookCostButtonTab"
          icon={<img src={Book4} color={Colors.mariner} />}
        />
      </Stack>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ selectHoldingsAccountView: selectHoldingsAccountViewFunction }, dispatch);

export function mapStateToProps(state: IAppRootState) {
  return {
    currentLocation: (state.router.location as Location<{ source: string }>).pathname,
    currentSearch: (state.router.location as Location<{ source: string }>).search,
    selectedAccountView: state.account.selectedHoldingsAccountView,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountViewSubMenu));
