import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 50%;
  display: flex;
  margin: 10px 0;
`;

export const Icon = styled.div`
  flex: 0 0 50px;
  svg {
    vertical-align: text-top;
  }
`;

export const Informations = styled.div`
  padding: 5px 0;
  flex: 0 0 calc(100% - 50px);
`;

export const Name = styled.h4`
  &&&{
    font-size: 16px;
    line-height: 35px;
    font-family: 'Open Sans Bold';
    font-weight: 700;
    font-style: normal;
    color: ${(props) => props.theme.black};
    margin: 0;
  }
`;

const baseSection = styled.div`
  font-size: 14px;
  a {
    -ms-word-break: break-all;
    word-break: break-all;
  }
`;

export const OrganisationSection = styled(baseSection)`
  font-size: 14px;
`;

export const EmailSection = styled(baseSection)`
  font-size: 14px;
`;

export const WebsitesSection = styled(baseSection)`
  font-size: 14px;
`;

export const PhoneSection = styled(baseSection)`
font-size: 14px;
`;
