import i18n from '~/app/i18n';
import { isFieldEmptyOrNull } from '~/common/helpers';
import * as React from 'react';
import styled from 'styled-components';

export const MonetaryValueContainer = styled.span`
  white-space: nowrap;
`;

interface IChangeValueProp {
  value?: number;
  isPercent?: boolean;
  isMoney?: boolean;
  //  requiresNegativeSign? : boolean;
  currency?: string;
  decimalOverride?: number;
  isFormatLargeNumber?: boolean;
  useBrackets?: boolean;
  noDollarSign?: boolean;
  noShowValueSign?: boolean;
}

export default class NumberQI extends React.Component<IChangeValueProp> {
  constructor(props: IChangeValueProp) {
    super(props);
  }

  public render() {
    const { value, isPercent, isMoney, currency, decimalOverride, isFormatLargeNumber, useBrackets, noDollarSign, noShowValueSign } =
      this.props;
    const val = value === undefined || isFieldEmptyOrNull(value) ? 0 : value;

    // if (!requiresNegativeSign) {
    //     val = Math.abs(val);
    // }
    let displayValue = '';

    if (isPercent !== undefined && isPercent === true) {
      let fractionDigits = 2;
      let compareValue = val > 0 ? '0.0' : '-0.0';
      do {
        fractionDigits++;
        compareValue += '0';
        displayValue = val.toLocaleString(i18n.language, {
          minimumFractionDigits: decimalOverride,
          maximumFractionDigits: decimalOverride,
        });
      } while (displayValue === compareValue && val !== 0 && fractionDigits < 4);
      const displayWithDecimalOverride = decimalOverride === 2 ? '0.00' : '0.000';
      displayValue = `${displayValue === '0.0000' || displayValue === '-0.0000' ? displayWithDecimalOverride : displayValue}%`;
      if (!noShowValueSign && displayValue.charAt(0) !== '-' && !displayValue.includes(displayWithDecimalOverride)) {
        displayValue = `+${displayValue}`;
      }
    } else if (isMoney !== undefined && isMoney === true) {
      let decimalPlaces = 2;
      if (decimalOverride !== undefined && decimalOverride >= 0) {
        decimalPlaces = decimalOverride;
      }
      if (noDollarSign !== undefined && noDollarSign === true) {
        displayValue = isFormatLargeNumber
          ? this.formatLargeFigures(val, decimalPlaces)
          : Number(val).toLocaleString(i18n.language, {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
      } else {
        displayValue =
          (i18n.language === 'en-CA' ? '$' : '') +
          (isFormatLargeNumber
            ? this.formatLargeFigures(val, decimalPlaces)
            : Number(val).toLocaleString(i18n.language, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
              })) +
          (i18n.language === 'fr-CA' ? '$' : '');
      }
    } else {
      displayValue = isFormatLargeNumber
        ? this.formatLargeFigures(val, 2)
        : Number(val).toLocaleString(i18n.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: val < 1 && val > -1 ? 4 : 2,
          });
    }

    return (
      <MonetaryValueContainer>
        {useBrackets ? '(' : ''}
        {displayValue}
        {currency !== undefined && currency !== '' ? ` ${currency}` : ''}
        {useBrackets ? ')' : ''}
      </MonetaryValueContainer>
    );
  }

  private formatLargeFigures = (val: any, decimalPlaces: any) =>
    Math.abs(Number(val)) >= 1.0e9
      ? // Six Zeroes for Billons
        (Number(val) / 1.0e9).toLocaleString(i18n.language, {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }) + i18n.t('numberQI.billion')
      : // Six Zeroes for Millions
      Math.abs(Number(val)) >= 1.0e6
      ? (Number(val) / 1.0e6).toLocaleString(i18n.language, {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }) + i18n.t('numberQI.million')
      : // Three Zeroes for Thousands
      Math.abs(Number(val)) >= 1.0e3
      ? (Number(val) / 1.0e3).toLocaleString(i18n.language, {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }) + i18n.t('numberQI.thousand')
      : Number(val).toLocaleString(i18n.language, {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: val < 1 && val > -1 ? 4 : decimalPlaces,
        });
}
