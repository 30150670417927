import * as React from 'react';
import styled from 'styled-components';
import { Popover } from '~/common/components';
import MultiSelectDropdownContent from './MultiSelectDropdownContent';
import { IOption } from './multiSelectDropdownTypes';
import { establishIfAllOptionsAreSelected } from './multiSelectDropdownUtilities';

const MultiSelectDropdownContainer = styled.div`
    display: flex;
`;
const SelectedOptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: white;
    border: 1px solid ${(props) => props.theme.inputBorderColor};
    padding: 6px 8px 5px 8px;
    min-width: 150px;

    > div {
        margin-right: 8px;

        &:last-child, &:nth-last-child(2) {
            margin-right: 0px;
        }
    }
`;
const ArrowDown = styled.div`
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #bababa;
    margin-left: 8px;
`;
const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export interface IMultiSelectDropdownProps {
    allSelectedText: any,
    oneSelectedText: any,
    someSelectedText: any,
    selectedOptionIds: string[],
    options: IOption[],
    renderOption: (option: IOption) => JSX.Element,
    onOptionSelected: (selectedOptionIds: string[]) => void
}

export interface IMultiSelectDropdownState {
    areOptionsVisible: boolean,
    selectedOptionIds: string[],
    areAllOptionSelected: boolean
}

class MultiSelectDropdown extends React.PureComponent<IMultiSelectDropdownProps, IMultiSelectDropdownState> {
  private selectedOptionsElement: React.RefObject<HTMLDivElement>;
  private optionsElement: React.RefObject<HTMLDivElement>;

  constructor(props: IMultiSelectDropdownProps) {
    super(props);

    this.selectedOptionsElement = React.createRef<HTMLDivElement>();
    this.optionsElement = React.createRef<HTMLDivElement>();

    const areAllOptionSelected = establishIfAllOptionsAreSelected(props.selectedOptionIds, props.options);

    this.state = {
      areOptionsVisible: false,
      selectedOptionIds: areAllOptionSelected
        ? []
        : props.selectedOptionIds,
      areAllOptionSelected,
    };
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  public handleOnSelectedOptionsClick = () => {
    const { areOptionsVisible } = this.state;

    this.setState({ areOptionsVisible: !areOptionsVisible });
  };

  private handleDocumentMouseDown = (event: Event) => {
    if (!this.optionsElement.current) {
      return;
    }

    if (!this.optionsElement.current.contains(event.target as any)) {
      this.setState({ areOptionsVisible: false });
    }
  };

  private handleOnOptionSelected = (selectedOptionIds: string[]) => {
    const { onOptionSelected, options } = this.props;

    const areAllOptionSelected = establishIfAllOptionsAreSelected(selectedOptionIds, options);

    this.setState({
      selectedOptionIds,
      areAllOptionSelected,
    });

    onOptionSelected(selectedOptionIds);
  };

  private renderOptionPopover() {
    const { options, allSelectedText, renderOption } = this.props;
    const { areOptionsVisible, selectedOptionIds } = this.state;

    if (!areOptionsVisible || !this.selectedOptionsElement.current) {
      return null;
    }

    const parentBoudingRect = this.selectedOptionsElement.current.getBoundingClientRect();

    return (
      <Popover parentBoundingRect={parentBoudingRect}>
        <OptionsContainer ref={this.optionsElement as any}>
          <MultiSelectDropdownContent
            allSelectedText={allSelectedText}
            selectedOptionIds={selectedOptionIds}
            options={options}
            renderOption={renderOption}
            onOptionSelected={this.handleOnOptionSelected}
          />
        </OptionsContainer>
      </Popover>
    );
  }

  private renderSelectedOptions() {
    const { selectedOptionIds, allSelectedText, oneSelectedText, someSelectedText } = this.props;
    const { areAllOptionSelected } = this.state;

    const selectionLength = selectedOptionIds.length;
    const selectionText = selectionLength > 1 ? someSelectedText : oneSelectedText;

    const displayedOptions = areAllOptionSelected
      ? allSelectedText
      : selectionText;

    return (
      <SelectedOptionsContainer onClick={this.handleOnSelectedOptionsClick} ref={this.selectedOptionsElement as any}>
        {displayedOptions}
        <ArrowDown />
      </SelectedOptionsContainer>
    );
  }

  public render() {
    return (
      <MultiSelectDropdownContainer>
        {this.renderSelectedOptions()}
        {this.renderOptionPopover()}
      </MultiSelectDropdownContainer>
    );
  }
}

export default MultiSelectDropdown;
