import { ITransaction } from '~/stores/transaction/transactionTypes';

export type AccountHoldingsView = 'account' | 'investedCapital' | 'bookCost';

export interface IAssetsAllocation {
  regular: IAssetsAllocationItem[];
  breakDown: IAssetsAllocationItem[];
}

export interface IAssetsAllocationItem {
  category: string;
  accountId: string;
  positions: IAssetAllocationPosition[];
}

export interface IAssetAllocationPosition {
  security: string;
  symbol: string;
  description: number;
  assetAllocationMarketPriceCad: number;
  mutualFundRatio?: number;
  isMutualFund: boolean;
}

export interface IClientAccount {
  totalMarketValue: number;
  daysChange: number;
  daysChangePercent: number;
  previousDayChangeValue: number;
  previousDayChangePercent: number;
  cashBalanceCad: number;
  marginValueCad: number;
  marginValueUsd: number;
  investedCapitalCAD: number;
  investedCapitalGainLossCAD: number;
  investedCapitalGainLossPercentage: number;
  bookValueCad: number;
  unrealizedGainAndLossCAD: number;
  unrealizedGainAndLossUSD: number;
  unrealizedGainAndLossPercent: number;
  currency: string;
  accounts: IAccount[];
  isLiveData: boolean;
  lastUpdatedDate: string;
}

export interface IDocumentDownload {
  content: string;
}

export interface IDocumentMergeDownload {
  fileData: string;
  fileName: string;
}
export interface IDocumentMergeDownloadComplete {
  data: IDocumentMergeDownload;
  mergedIds: IDocumentBase[];
}

export interface IAccount {
  id: string;
  name: string;
  overviewName: string;
  groupedAccountName: string;
  alias: string;
  cashBalanceUsd: number;
  cashBalanceCad: number;
  marginValueUsd: number;
  marginValueCad: number;
  currency: string;
  positions: IPosition[];
  totalMarketValueCad: number;
  totalMarketValueUsd: number;
  totalPreviousMarketValueCad: number;
  totalPreviousMarketValueUsd: number;
  accountType: string;
  accountTypeLong: string;
  productType: string;
  rootId?: string;
  totalBookValue?: number;
  totalUnrealizedGainAndLoss?: number;
  totalUnrealizedGainAndLossPercent?: number;
  isOpen: boolean;
  repCode: string;
  beneficiary: string;
  spouseName: string;
  exchangeRate: IExchangeRate;
  prevBusinessDayExchangeRate: IExchangeRate;
  lastUpdate: string;
  investedCapitalCAD?: number;
  investedCapitalUSD?: number
  investedCapitalGainLossCAD?: number;
  investedCapitalGainLossUSD?: number;
  investedCapitalGainLossPercentage: number;
  bookValueUsd: number;
  bookValueCad: number;
  unrealizedGainAndLossCAD: number;
  unrealizedGainAndLossUSD: number;
  unrealizedGainAndLossPercent: number;
  previousDayChangeValue: number;
  previousDayChangePercent: number;
  daysChange: number;
  daysChangePercent: number;
}

export interface IPerformance {
  rateOfReturn: any[];
  cashFlowSummary: any[];
}

export interface IRateOfReturn {
  RateOfReturns: any[];
}

export interface IExchangeRate {
  usdToCndRate: number;
  cndToUsdRate: number;
  loadStamp: Date;
}

export enum RegisteredAccountTypeCode {
  RRSP_CAD = 'RRSP_CAD',
  RRSP_USD = 'RRSP_USD',
  RRSP_SPOUSAL_CAD = 'RRSP_SPOUSAL_CAD',
  RRSP_SPOUSAL_USD = 'RRSP_SPOUSAL_USD',
  RRIF_CAD = 'RRIF_CAD',
  RRIF_USD = 'RRIF_USD',
  RRIF_SPOUSAL_CAD = 'RRIF_SPOUSAL_CAD',
  PRESCRIBED_RRIF = 'PRESCRIBED_RRIF',
  LRIF = 'LRIF',
  LIF = 'LIF',
  TFSA_CAD = 'TFSA_CAD',
  TFSA_USD = 'TFSA_USD',
  RESP = 'RESP',
  RESP_FAMILY = 'RESP_FAMILY',
  LIRA = 'LIRA',
  LRSP = 'LRSP',
  FHSA_CAD = 'FHSA_CAD',
}

export enum RegisteredAccountGroup {
  RRSP = 'RRSP',
  RRIF = 'RRIF',
  LIF_LRIF = 'LIF_LRIF',
  TFSA = 'TFSA',
  RESP = 'RESP',
  RDSP = 'RDSP',
  FHSA = 'FHSA',
}

export enum RegisteredAccountOperationType {
  CONTRIBUTION = 'CNT',
  WITHDRAWAL = 'WIT',
  SPOUSALCONTRIBUTION = 'CTS',
  PAYMENT = 'PAY',
}

export enum CsvExportDomain {
  OVERVIEW = 'Overview',
  HOLDINGS = 'Holdings',
  BOOKVALUE = 'BookValue',
  TRANSACTIONS = 'Transactions',
  CONSOLIDATED = 'Consolidated',
}

export interface IRegisteredAccountSummary {
  first60DaysContributions?: number;
  first60DaysWithdrawal?: number;
  remainderContributions?: number;
  remainderWithdrawal?: number;
  totalContributions?: number;
  totalWithdrawal?: number;
  totalGrant?: number;
  first60DaysSpousalContributions?: number;
  remainderSpousalContributions?: number;
  totalSpousalContributions?: number;
  transactions: ITransaction[];
  marketValue?: number;
  endOfYear?: number;
  endOfYearAge?: number;
  payment?: IRegisteredAccountPayment;
  beneficiaries?: IRegisteredBeneficiary[];
  beneficiaryCategory: string;
  maxWithdrawal?: number;
  minWithdrawal?: number;
}

export interface IRegisteredBeneficiary {
  contributionPourcent: number;
  beneficiaryType: string;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  province: string;
  birthDate: Date;
}

export interface IRegisteredAccount {
  registeredAccountType: string;
  registeredAccountGroup: string;
  year: number;
  account: IAccount;
  summary: IRegisteredAccountSummary;
  jurisdiction: string;
  transactions: ITransaction[];
}

export interface IRegisteredAccountPayment {
  minAnnualWithdrawal: number;
  firstPaymentDate: Date;
  frequency: string;
  electedPayment: number;
  withholdingTaxPercentFederal: number;
  withholdingTaxPercentProvincial: number;
  withholdingTaxPercentNonResident: number;
  cumulativeTaxes: number;
  cumulativePayments: number;
}

export interface IPosition {
  accountId: string;
  individualMarketPrice: number;
  individualMarketPriceInAccountCurrency: number;
  totalMarketPriceCad: number;
  totalMarketPriceUsd: number;
  quantity: number;
  description: string;
  symbol: string;
  security: string;
  marketPriceCurrency: string;
  bookValueUsd: number;
  bookValueCad: number;
  averageUnitCost?: number;
  unrealizedGainAndLoss: number;
  unrealizedGainAndLossCAD?: number;
  unrealizedGainAndLossUSD?: number;
  unrealizedGainAndLossPercent?: number;
  categoryId: number;
  categoryDesc: string;
  isMutualFund: boolean;
  investmentCategory: string;
  marketPriceSource: string;
  changeValue: number;
  changePercent: number;
  tranSummCurrency: string;
  alternateType: string;
  investedCapitalCAD?: number;
  investedCapitalGainLossCAD?: number;
  investedCapitalGainLossUSD?: number;
  investedCapitalUSD: number;
}

export interface IClientAccountSimplifiedDictionary {
  totalMarketValue: number;
  daysChange: number;
  daysChangePercent: number;
  previousDayChangeValue: number;
  previousDayChangePercent: number;
  cashBalanceCad: number;
  marginValueCad: number;
  marginValueUsd: number;
  investedCapitalCAD: number;
  investedCapitalGainLossCAD: number;
  investedCapitalGainLossPercentage: number;
  bookValueCad: number;
  unrealizedGainAndLossCAD: number;
  unrealizedGainAndLossUSD: number;
  unrealizedGainAndLossPercent: number;
  currency: string;
  isLiveData: boolean;
}

export interface IClientAccountDictionary extends IClientAccountSimplifiedDictionary {
  accounts: IAccountDictionnary;
}

export interface IAccountDictionnary {
  [id: string]: IAccount;
}

export interface IDocument {
  id: string;
  documentId: string;
  documentYear: string;
  effectiveDate: Date;
  publicationDate: Date;
  typeDocument: number;
  type: string;
  name: string;
  clientId: string;
  fromType: number;
  fromName: string;
  read: boolean;
}

export interface IAccountState {
  readonly filteredAccountIds: string[];
  readonly allAccountsFiltered: string[];
  readonly filteredRegisterAccountIds: string[];
  readonly clientAccounts: IClientAccountDictionary;
  readonly isFetching: boolean;
  readonly isFetchingFail: boolean;
  readonly dataExpiration?: Date;
  readonly assetsAllocation?: IAssetsAllocation[];
  readonly assetsAllocationDataExpiration?: Date;
  readonly isAssetsAllocationFetching: boolean;
  readonly registeredAccounts?: IRegisteredAccount[];
  readonly isRegisteredAccountFetching: boolean;
  readonly isRegisteredAccountFetchingFail: boolean;
  readonly dataRegisteredAccountExpiration?: Date;
  readonly yearRegisteredAccount?: number;
  readonly isFetchingNetInvested: boolean;
  readonly isFetchingNetInvestedFail: boolean;
  readonly netInvested: any;
  readonly isFetchingRateOfReturn: boolean;
  readonly isFetchingRateOfReturnFail: boolean;
  readonly isFetchingCashFlowSummary: boolean;
  readonly isFetchingCashFlowSummaryFail: boolean;
  readonly rateOfReturn: any;
  readonly cashFlow: ICashFlow;
  readonly isFetchingDeliveryPreferences: boolean;
  readonly isFetchingDeliveryPreferencesFail: boolean;
  readonly deliveryPreferences: IDeliveryPreferences[];
  readonly isSavingDeliveryPreferences: boolean;
  readonly isSavingDeliveryPreferencesFail: boolean;
  readonly isFetchingDeliveryPreferencesSummary: boolean;
  readonly isFetchingDeliveryPreferencesSummaryFail: boolean;
  readonly deliveryPreferencesSummary: IDeliveryPreferencesSummary;
  readonly isSavingNicknameFail: boolean;
  readonly isSavingNickname: boolean;
  readonly documentsStartDate: string;
  readonly documentsEndDate: string;
  readonly documents?: IDocument[];
  readonly isDocumentLoaded: boolean;
  readonly isTradeConfirmationsLoaded: boolean;
  readonly isFetchingDocuments: boolean;
  readonly isFetchingDocumentsFail: boolean;
  readonly isFetchingTradeConfirmationDocuments: boolean;
  readonly isFetchingTradeConfirmationDocumentsFail: boolean;
  readonly isMergingDownloadPdfs: boolean;
  readonly isMergingDownloadPdfsDone: boolean;
  readonly isMergingDownloadPdfsFail: boolean;
  readonly mergePdfsResults: string;
  readonly isFetchingMarketData: boolean;
  readonly lastUpdate?: Date;
  readonly lastUpdatePortal?: Date;
  readonly prevBusinessDayLastUpdate?: Date;
  readonly isFetchingManrketDataHasError: boolean;
  readonly isFetchingClosedMarkets: boolean;
  readonly closedMarkets: string[];
  readonly isFetchingCSVByDomain: boolean;
  readonly isFetchingCSVByDomainDone: boolean;
  readonly isFetchingCSVByDomainFail: boolean;
  readonly csvResult: string;

  readonly selectedHoldingsAccountView: AccountHoldingsView;
}

export interface IPositionWithAllocationPercent extends IPosition {
  percent: number;
}

export interface IPositionAfterIntraday extends IPosition {
  notFoundAtProvider: boolean;
}

export interface IDeliveryPreferencesToSave {
  clientId: string;
  documentPreferences: IDocumentPreferencesToSave[];
}

export interface IDeliveryPreferences {
  clientId: string;
  documentPreferences: IDocumentPreferences[];
  accountName: string;
  canModify: boolean;
}

export interface IDeliveryPreferencesSummary {
  IsPaperless: boolean;
  IsDueToReview: boolean;
}

export interface IDocumentPreferences {
  type: string;
  isPaperless: boolean;
  isEmailNotification?: boolean;
  isTaxSeason?: boolean;
}

export interface IDocumentPreferencesToSave {
  type: DocumentType;
  isPaperless: boolean;
  isEmailNotification?: boolean;
}

export interface IMergeDownloadPdfs {
  documents: IDocumentBase[];
  mergePdfDocuments: boolean;
  fromAdView: boolean;
}

export interface IDocumentBase {
  documentId: string;
}

export interface ICashFlow {
  ytd: ICashFlowYear;
  previousYear: ICashFlowYear;
  exchangeRate: any;
}

export interface ICashFlowYear {
  openingMarketValue: number;
  deposits: number;
  withdrawals: number;
  investmentIncome: {
    totalIncome: number;
    interest: number;
    drips: number;
    dividends: number;
  };
  feesAndCharges: {
    totalFees: number;
    adminFees: number;
    feeBasedServices: number;
  };
  taxes: number;
  changeInMarketValue: number;
  closingMarketValue: number;
  currency: string;
}

export interface IAccountNotificationDto {
  emailTemplate: string;
  languageCode: string;
  toEmail: string;
  dynamicData: IDynamicData[];
}

export interface IDynamicData {
  tag: string;
  value: string;
}

export const enum AccountActionTypes {
  FETCH_ACCOUNT = '[Account] Fetch',
  FETCH_ACCOUNT_SUCCESSFUL = '[Account] Fetch Successful',
  FETCH_ACCOUNT_FAILED = '[Account] Fetch Failed',

  FETCH_ACCOUNTS_INFO = '[Accounts] Fetch Initial Info',
  FETCH_ACCOUNTS = '[Accounts] Fetch',
  FETCH_ACCOUNTS_SUCCESSFUL = '[Accounts] Fetch Successful',
  FETCH_ACCOUNTS_FAILED = '[Accounts] Fetch Failed',
  FETCH_ACCOUNTS_ABORTED = '[Accounts] Fetch Aborted',

  SET_FILTERING_ACCOUNT_IDS = '[Accounts] Set selected Filtering account ids',
  SET_FILTERING_PARTY_ACCOUNT_IDS = '[Accounts] Set selected Filtering party account ids',
  CLEAN_FILTERING_ACCOUNT_IDS = '[Accounts] Clean selected Filtering account ids',
  SET_FILTERING_REGISTER_ACCOUNT_IDS = '[Accounts] Set selected Filtering Register account ids',

  FETCH_ACCOUNTS_ASSETS_ALLOCATION = '[Accounts] Fetch Assets Allocations',
  FETCH_ACCOUNTS_ASSETS_ALLOCATION_SUCCESSFUL = '[Accounts] Fetch Assets Allocation Successful',
  FETCH_ACCOUNTS_ASSETS_ALLOCATION_FAILED = '[Accounts] Fetch Assets Allocation Failed',
  FETCH_ACCOUNTS_ASSETS_ALLOCATION_ABORTED = '[Accounts] Fetch Assets Allocation Aborted',

  FETCH_REGISTERED_ACCOUNTS = '[Registered Accounts] Fetch',
  FETCH_REGISTERED_ACCOUNTS_SUCCESSFUL = '[Registered Accounts] Fetch Successful',
  FETCH_REGISTERED_ACCOUNTS_FAILED = '[Registered Accounts] Fetch Failed',
  FETCH_REGISTERED_ACCOUNTS_ABORTED = '[Registered Accounts] Fetch Aborted',

  FETCH_NETINVESTED = '[NetInvested] Fetch',
  FETCH_NETINVESTED_SUCCESSFUL = '[NetInvested] Fetch Successful',
  FETCH_NETINVESTED_FAILED = '[NetInvested] Fetch Failed',
  FETCH_NETINVESTED_ABORTED = '[NetInvested] Fetch Aborted',
  RESET_NETINVESTED_EXPIRATION_DATE = '[NetInvested] Reset expiration date',

  FETCH_RATEOFRETURN = '[RateOfReturn] Fetch',
  FETCH_RATEOFRETURN_SUCCESSFUL = '[RateOfReturn] Fetch Successful',
  FETCH_RATEOFRETURN_FAILED = '[RateOfReturn] Fetch Failed',
  FETCH_RATEOFRETURN_ABORTED = '[RateOfReturn] Fetch Aborted',

  FETCH_CASHFLOWSUMMARY = '[CashFlowSummary] Fetch',
  FETCH_CASHFLOWSUMMARY_SUCCESSFUL = '[CashFlowSummary] Fetch Successful',
  FETCH_CASHFLOWSUMMARY_FAILED = '[CashFlowSummary] Fetch Failed',
  FETCH_CASHFLOWSUMMARY_ABORTED = '[CashFlowSummary] Fetch Aborted',

  FETCH_DELIVERY_PREFERENCES = '[DeliveryPreferences] Fetch',
  FETCH_DELIVERY_PREFERENCES_SUCCESSFUL = '[DeliveryPreferences] Fetch Successful',
  FETCH_DELIVERY_PREFERENCES_FAILED = '[DeliveryPreferences] Fetch Failed',

  SAVE_DELIVERY_PREFERENCES = '[DeliveryPrefrences] Save',
  SAVE_DELIVERY_PREFERENCES_SUCCESSFUL = '[DeliveryPreferences] Save Successful',
  SAVE_DELIVERY_PREFERENCES_FAILED = '[DeliveryPreferences] Save Failed',

  FETCH_DELIVERY_PREFERENCES_SUMMARY = '[DeliveryPreferencesSummary] Fetch',
  FETCH_DELIVERY_PREFERENCES_SUMMARY_SUCCESSFUL = '[DeliveryPreferencesSummary] Fetch Successful',
  FETCH_DELIVERY_PREFERENCES_SUMMARY_FAILED = '[DeliveryPreferencesSummary] Fetch Failed',

  SAVE_NICKNAME = '[NICKNAME] Save',
  SAVE_NICKNAME_SUCCESSFUL = '[NICKNAME] Save Successful',
  SAVE_NICKNAME_FAILED = '[NICKNAME] Save Failed',

  FETCH_DOCUMENTS = '[Documents] Fetch',
  FETCH_DOCUMENTS_SUCCESSFUL = '[Documents] Fetch Successful',
  FETCH_DOCUMENTS_FAILED = '[Documents] Fetch Failed',
  FETCH_DOCUMENTS_ABORTED = '[Documents] Fetch Aborted',
  ADD_NEW_DOCUMENTS_SUCCESSFUL = '[Documents] Add New Documents Successful',
  DOWNLOAD_DOCUMENT = '[Documents] Download Document',
  MERGE_DOWNLOAD_PDFS = '[Documents] Merge/Download PDFs',
  MERGE_DOWNLOAD_PDFS_SUCCESSFUL = '[Documents] Merge/Download PDFs Successful',
  MERGE_DOWNLOAD_PDFS_FAILED = '[Documents] Merge/Download PDFs Failed',
  SET_DOCUMENT_READ_STATUS = '[Documents] Set document read status',
  SET_DOCUMENT_DATE_FILTERING = '[Documents] Set document date filtering',

  FETCH_TRADECONFIRMATION_DOCUMENTS = '[Trade Confirmation Documents] Fetch',
  FETCH_TRADECONFIRMATION_SUCCESSFUL = '[Trade Confirmation Documents] Fetch Successful',
  FETCH_TRADECONFIRMATION_FAILED = '[Trade Confirmation Documents] Fetch Failed',
  FETCH_TRADECONFIRMATION_ABORTED = '[Trade Confirmation Documents] Fetch Aborted',

  FETCH_MARKETDATA = '[Market] Fetch',
  FETCH_MARKETDATA_SUCCESSFUL = '[Market] Fetch Successful',
  FETCH_MARKETDATA_NOLIVE_SUCCESSFUL = '[Market] Fetch No Live Successful',
  FETCH_MARKETDATA_FAILED = '[Market] Fetch Failed',

  FETCH_CLOSEDMARKETS = '[Closed Markets] Fetch',
  FETCH_CLOSEDMARKETS_SUCCESSFUL = '[Closed Markets] Fetch Successful',
  FETCH_CLOSEDMARKETS_FAILED = '[Closed Markets] Fetch Failed',

  FETCH_CSV_BY_DOMAIN = '[Overview] Fetch CSV',
  FETCH_CSV_BY_DOMAIN_SUCCESSFUL = '[Overview] Fetch CSV Successful',
  FETCH_CSV_BY_DOMAIN_FAILED = '[Overview] Fetch CSV Failed',

  SEND_ACCOUNT_NOTIFICATION = '[DeliveryPrefrences] account notification',
  SEND_ACCOUNT_NOTIFICATION_SUCCESSFUL = '[DeliveryPrefrences] account notification Successful',
  SEND_ACCOUNT_NOTIFICATION_FAILED = '[DeliveryPrefrences] account notification Failed',

  SET_HOLDINGS_ACCOUNT_VIEW = '[Holdings] Set account view',

  CLEAR_DOCUMENTS = '[Documents] Clear store',
  FETCH_TAX_DOCUMENTS = '[Documents] Fetch Tax Documents',
  FETCH_TAX_DOCUMENTS_SUCCESSFUL = '[Documents] Fetch Tax Documents Successful',
  FETCH_TAX_DOCUMENTS_FAILED = '[Documents] Fetch Tax Documents Failed',
}
