import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { CurrencyEnum } from '~/common/types';

export const MonetaryValueContainer = styled.div<{
  isAlignRight: boolean;
  colorful?: boolean;
  valueSign: valueSignType;
}>`
  display: flex;
  justify-content: ${(props) => (props.isAlignRight ? 'flex-end' : 'flex-start')};
  align-items: baseline;
  font-weight: ${(props) => (props.colorful ? 700 : 400)};
  ${(props) => {
    const colorfulColor =
      props.valueSign !== 'none' && props.valueSign === 'positive' ? 'color: green;' : 'color: red;';
    return props.colorful ? colorfulColor : '';
  }}
`;
export const CurrencyContainer = styled.div<{ isCurrencyVisible: boolean; fixCurrency: boolean }>`
  margin-left: 4px;
  opacity: ${(props) => (props.isCurrencyVisible ? '1' : '0')};
  font-size: ${(props) => (props.fixCurrency ? '24px' : '')};
`;

type valueSignType = 'positive' | 'negative' | 'none';

export interface IMonetaryValueProps {
  value: number;
  currency?: string;
  culture: string;
  amountOfDigits?: number;
  zeroPlaceholder?: string;
  nanPlaceholder?: string;
  alignRight?: boolean;
  fixCurrency?: boolean;
  colorful?: boolean;
  signType?: 'parentheses' | 'symbol';
  valueSign?: valueSignType;
}

const MonetaryValue: React.FC<IMonetaryValueProps> = ({
  value,
  currency,
  culture,
  amountOfDigits,
  zeroPlaceholder,
  nanPlaceholder,
  colorful,
  alignRight = true,
  fixCurrency = false,
  signType = 'parentheses',
  valueSign = 'none',
}) => {
  const isCurrencyVisible = currency !== '';
  const fractionDigits = !amountOfDigits ? 2 : amountOfDigits;

  if (zeroPlaceholder && value === 0) {
    return (
      <MonetaryValueContainer valueSign={valueSign} colorful={colorful} isAlignRight={alignRight}>
        {zeroPlaceholder}
      </MonetaryValueContainer>
    );
  }

  if (nanPlaceholder && isNaN(value)) {
    return (
      <MonetaryValueContainer valueSign={valueSign} colorful={colorful} isAlignRight={alignRight}>
        {nanPlaceholder}
      </MonetaryValueContainer>
    );
  }

  const renderCurrency = () => {
    const hasNoCurrency = !currency && currency !== '';
    const displayedCurrency = currency || CurrencyEnum.CAD;

    if (hasNoCurrency) {
      return null;
    }

    return (
      <CurrencyContainer isCurrencyVisible={isCurrencyVisible} fixCurrency={fixCurrency}>
        {displayedCurrency}
      </CurrencyContainer>
    );
  };

  if (value < 0) {
    const val = Math.abs(value);
    const formattedValue = val.toLocaleString(culture, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
    const returnString = signType === 'symbol' ? `- ${formattedValue}` : `(${formattedValue})`;
    return (
      <MonetaryValueContainer valueSign={valueSign} colorful={colorful} isAlignRight={alignRight}>
        {returnString}
        {renderCurrency()}
      </MonetaryValueContainer>
    );
  }

  return (
    <MonetaryValueContainer valueSign={valueSign} colorful={colorful} isAlignRight={alignRight}>
      {signType === 'symbol' && valueSign === 'positive' && '+ '}
      {signType === 'symbol' && valueSign === 'negative' && '- '}
      <FormattedNumber
        value={value}
        style="decimal"
        minimumFractionDigits={fractionDigits}
        maximumFractionDigits={fractionDigits}
      />
      {renderCurrency()}
    </MonetaryValueContainer>
  );
};

export default MonetaryValue;
