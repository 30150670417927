import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Container } from '~/common/styles/baseStyle';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

interface IProps {
  children: JSX.Element[]
}
export const ProductHeaderContainer: React.FC<IProps> = ({ children }: IProps) => {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      {children}
    </Container>
  );
};
