import styled from 'styled-components';

export const AnswerError = styled.span`
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
  color: rgb(231, 19, 19);
  font-size: 14px;
`;

export const StepperText = styled.span`
  font-family: 'OpenSans-Regular', 'Open Sans', 'sans-serif';
  color: rgb(0, 61, 165);
  font-size: 14px;
`;

export const ConfirmationTitle = styled.span`
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
`;

export const Text = styled.span<{ variant?: 'lg' | 'md' | 'sm' | 'xs'; fontWeight?: '400' | '400' | '700' }>`
  font-style: normal;
  font-family: ${(props) => {
    if (props.fontWeight === '400') {
      return '"Montserrat-Regular", "Montserrat", sans-serif;';
    }
    switch (props.variant) {
      case 'lg': {
        return '"Montserrat-Regular", "Montserrat", sans-serif;';
      }
      case 'sm': {
        return 'Montserrat-SemiBold, "Montserrat SemiBold", Montserrat, sans-serif;';
      }
      default: {
        return '"Montserrat-Bold", "Montserrat Bold", "Montserrat", sans-serif';
      }
    }
  }};
  font-size: ${(props) => {
    switch (props.variant) {
      case 'lg': {
        return '35px';
      }
      case 'md': {
        return '21px';
      }
      case 'sm': {
        return '18px';
      }
      default: {
        return '14px';
      }
    }
  }};
  font-weight: ${(props) => {
    if (props.fontWeight) {
      return props.fontWeight;
    }
    switch (props.variant) {
      case 'lg': {
        return 400;
      }
      case 'sm': {
        return 650;
      }
      default: {
        return 700;
      }
    }
  }};
`;

const getWidthForVariant = (variant?: 'xl' | 'lg' | 'md' | 'sm') => {
  switch (variant) {
    case 'xl':
      return '225px';
    case 'md':
      return '160px';
    case 'sm':
      return '120px';
    default:
      return '180px';
  }
};
export const iaButtonStyle = (variant?: 'xl' | 'lg' | 'md' | 'sm') => ({
  width: getWidthForVariant(variant),
  height: '45px',
});

export const iaButtonOutlined = (variant?: 'xl' | 'lg' | 'md') => ({
  marginRight: '10px',
  color: 'rgb(0, 61, 165)',
  width: getWidthForVariant(variant),
  height: '45px',
  '&:hover': {
    backgroundColor: 'rgba(230, 230, 230, 1)',
    color: 'rgb(0, 61, 165)',
    border: 'rgb(0, 61, 165)',
  },
});

export const ConfirmContainer = styled.div`
  text-align: center;
  width: 100%;
`;

export const StyledP = styled.p`
  margin: 0px;
  color: #333333;
`;

export const Title = styled(StyledP)`
  font-size: 24px;
  font-weight: bold;
`;

export const ConfirmationIconStyle = {
  fontSize: '58px',
  color: '#7cb342',
};

export const InformationIconStyle = {
  fontSize: '58px',
  color: 'rgb(32, 98, 212)',
};

export const CollapseIconStyle = {
  fontSize: '24px',
  color: '#003da5',
};

export const LinkButton = styled.span`
  color: #2062d4;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 650;
  text-decoration: underline;
`;

export const QuestionTitle = styled.span`
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
`;

export const DefinitionText = styled.span`
  color: #2062d4;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 650;
  text-decoration: underline;
`;
