import accountSaga from '~/stores/account/sagas/accountSaga';
import advisorSaga from '~/stores/advisor/sagas/advisorSaga';
import partySaga from '~/stores/party/sagas/partySaga';
import systemSaga from '~/stores/system/sagas/systemSagas';
import marketDataSaga from '~/stores/marketData/sagas/marketDataSaga';
import transactionSaga from '~/stores/transaction/sagas/transactionSaga';
import { all, fork } from 'redux-saga/effects';
import messageSaga from '~/stores/message/sagas/messageSaga';
import positionDetailSaga from '~/stores/positionDetail/sagas/positionDetailSaga';
import oidcSaga from '../stores/oidc/sagas/oidcSagas';
import kycSaga from '~/stores/kyc/kycSagas';
import marketProductDetailSaga from '~/stores/marketData/sagas/marketProductDetailSaga';
import contestSaga from '~/stores/contest/sagas/contestSaga';

export default function* rootSaga() {
  yield all([
    fork(accountSaga),
    fork(transactionSaga),
    fork(systemSaga),
    fork(partySaga),
    fork(advisorSaga),
    fork(oidcSaga),
    fork(messageSaga),
    fork(positionDetailSaga),
    fork(kycSaga),
    fork(marketDataSaga),
    fork(marketProductDetailSaga),
    fork(contestSaga),
  ]);
}
