import * as React from 'react';
import { RouteNames } from '~/app/appTypes';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { isSymbolSearchEnabled } from '~/common/featureToggleHelper';
import { CurrentLocationSetter } from './CurrentLocationSetter';
import NavigationLinks, { INavigationOption } from './NavigationLinks';
import { Location } from 'history';
import { getPartyV1 } from '~/stores/party/selectors/partySelectors';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { isConsentFeatureActiveForClient } from '~/common/helpers';
import { getShowKYCMenu } from '~/stores/kyc/kycSelectors';
import { TOGGLE_MARKETDATA_FEATURE_PAGE } from '~/common/API';

interface IStockState {
  symbolTemporaire: string;
}

export interface IPropsFromState {
  currentLocation: string;
  party: IPartyV1;
  showKYCMenu: boolean | null;
}

class NavigationMenu extends React.PureComponent<IPropsFromState, IStockState> {
  private currentLocationSetter: CurrentLocationSetter;

  constructor(props: any) {
    super(props);

    this.currentLocationSetter = new CurrentLocationSetter();
    this.state = {
      symbolTemporaire: 'XTSE:IAG',
    };
  }

  private handleSymbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      symbolTemporaire: value,
    });
  };

  public render() {
    const { currentLocation } = this.props;

    const navigationOptions = [
      { key: 'home', text: <Trans i18nKey="common.home" />, route: RouteNames.landing },
      {
        key: 'accounts',
        text: <Trans i18nKey="common.menuAccounts" />,
        subOptions: [
          { text: <Trans i18nKey="common.holdings" />, route: RouteNames.holdings },
          { text: <Trans i18nKey="common.history" />, route: RouteNames.history },
          { text: <Trans i18nKey="common.menuRegisteredAccount" />, route: RouteNames.registeredAccount },
          { text: <Trans i18nKey="common.assetAllocation" />, route: RouteNames.assetsAllocation },
          { text: <Trans i18nKey="common.performance" />, route: RouteNames.performance },
        ],
      },
    ] as INavigationOption[];
    if (TOGGLE_MARKETDATA_FEATURE_PAGE) {
      navigationOptions.push({
        key: 'markets',
        text: <Trans i18nKey="common.menuMarkets" />,
        route: RouteNames.markets,
      } as INavigationOption);
    }
    navigationOptions.push({
      key: 'documents',
      text: <Trans i18nKey="common.menuDocuments" />,
      route: RouteNames.documents,
    } as INavigationOption);
    navigationOptions.push({
      key: 'householding',
      text: <Trans i18nKey="common.menuHouseholding" />,
      route: RouteNames.settings,
    } as INavigationOption);
    if (isConsentFeatureActiveForClient(this.props.party)) {
      navigationOptions.push({
        key: 'personalInformation',
        text: <Trans i18nKey="common.menuPersonalInformation" />,
        subOptions: [{ text: <Trans i18nKey="common.menuConsentPreferences" />, route: RouteNames.settingConsent }],
      } as INavigationOption);
    }
    if (this.props.showKYCMenu) {
      navigationOptions.push({
        key: 'kyc',
        text: <Trans i18nKey="common.menuKYC" />,
        route: RouteNames.kyc,
      } as INavigationOption);
    }

    this.currentLocationSetter.set(navigationOptions, currentLocation);

    return (
      <>
        <NavigationLinks options={navigationOptions} />
        {isSymbolSearchEnabled() && (
          <>
            <Input value={this.state.symbolTemporaire} onChange={this.handleSymbolChange} />
            <Link
              to={{
                pathname: `${RouteNames.positionOverview}/`,
                key: this.state.symbolTemporaire,
                state: {
                  symbol: this.state.symbolTemporaire,
                },
              }}
            >
              Refresh
            </Link>
          </>
        )}
      </>
    );
  }
}

export function mapStateToProps(state: IAppRootState) {
  return {
    currentLocation: (state.router.location as Location<{ source: string }>).pathname,
    party: getPartyV1(state),
    showKYCMenu: getShowKYCMenu(state),
  };
}

export default connect(mapStateToProps)(NavigationMenu);
