import { Box, Button, Fade, Link, Stack } from '@mui/material';
import React from 'react';
import background from './background.png';
import styled from 'styled-components';
import Colors from '~/common/themes/colors';
import CloseIcon from '@mui/icons-material/Clear';
import { Trans } from 'react-i18next';
import CustomDialog from '~/common/components/CustomDialog/CustomDialog';
import i18n from '~/app/i18n';
import { connect, useDispatch } from 'react-redux';

import { Contest, ContestDialogType } from '~/stores/contest/contestTypes';
import { IAppRootState } from '~/app/rootReducer';
import { postponeContest, unsubscribeContest } from '~/stores/contest/actions/contestActions';

export const BackgroundImage = styled.div`
  background-image: linear-gradient(transparent, white 65%), url(${background});
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  opacity: 0.2;
`;

const Title = styled.span`
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: ${Colors.cobalt};
  margin-bottom: 6px;
`;

const Description = styled.span`
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-size: 14px;
  > {
    p,
    span {
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }
`;

const CloseIconDiv = styled.span`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  color: ${Colors.mariner};
`;

const ButtonStyle = {
  fontFamily: '"OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans"',
  fontWeight: 650,
  fontStyle: 'normal',
  color: Colors.mariner,
  fontSize: '14px',
  paddingRight: '12px',
};

export const shouldShowContestBanner = (campaign: Contest) =>
  campaign && campaign.isActive && campaign.isSubscribed && !campaign.isPostponed;

const ContestBanner = ({ campaign, culture: _ }: { campaign: Contest; culture: string }) => {
  const [dialogType, setDialogType] = React.useState<ContestDialogType>('none');
  const dispatch = useDispatch();
  return (
    <>
      <CustomDialog
        isOpen={dialogType === 'details'}
        title={i18n.t('contestBanner.contestDetails')}
        okText={i18n.t('common.close')}
        okAction={() => {
          setDialogType('none');
        }}
        closeAction={() => setDialogType('none')}
      >
        <Description dangerouslySetInnerHTML={{ __html: campaign.description }}></Description>
        <Link
          style={{ ...ButtonStyle, paddingLeft: '0px', textDecoration: 'none' }}
          href={campaign.contestRulesUri}
          target="_blank"
        >{`${i18n.t('contestBanner.viewContestRules')}`}</Link>
      </CustomDialog>
      <CustomDialog
        isOpen={dialogType === 'unregister'}
        okText={i18n.t('contestBanner.confirm')}
        cancelText={i18n.t('common.cancel')}
        title={i18n.t('contestBanner.wantToSubscribe')}
        okAction={() => {
          dispatch(unsubscribeContest());
          setDialogType('none');
        }}
        cancelAction={() => setDialogType('none')}
        closeAction={() => setDialogType('none')}
      >
        <Description dangerouslySetInnerHTML={{ __html: campaign.unsubscribeConfirmationMessage }}></Description>
      </CustomDialog>
      <Fade in>
        <Box
          mt={2}
          mb={2}
          p={3}
          pt={3}
          sx={{
            backgroundColor: Colors.babyBlue,
            height: 'auto',
            width: '100%',
            position: 'relative',
          }}
        >
          <BackgroundImage />
          <CloseIconDiv
            onClick={() => {
              dispatch(postponeContest());
            }}
          >
            <CloseIcon />
          </CloseIconDiv>
          <Stack gap={1}>
            <Title dangerouslySetInnerHTML={{ __html: campaign.title }}></Title>
            <Description dangerouslySetInnerHTML={{ __html: campaign.summary }}></Description>
            <Stack direction="row" sx={{ paddingTop: '12px' }}>
              <Button style={{ ...ButtonStyle, paddingLeft: '0px' }} onClick={() => setDialogType('details')}>
                <Trans i18nKey="contestBanner.contestDetails" />
              </Button>
              <Button
                style={{ ...ButtonStyle, marginLeft: '12px', marginRight: '12px', color: Colors.mariner }}
                onClick={() => setDialogType('unregister')}
              >
                <Trans i18nKey="contestBanner.unsubscribe" />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
  };
}

export default connect(mapStateToProps, () => {})(ContestBanner);
