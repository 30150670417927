import * as React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import DataLoadStamp from '~/common/components/loadStamp/DataLoadStamp';
import { Container, HeaderPageContainer, PageTitleContainer, UpdateDataContainer } from '~/common/styles/baseStyle';
import Colors from '~/common/themes/colors';
import SecurityAutocomplete from './market-overview/SecurityAutocomplete';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TopMovers from './market-overview/TopMovers';
import Trends from './market-overview/Trends';
import { connect } from 'react-redux';
import { fetchMarketData } from '~/stores/marketData/actions/marketDataActions';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppRootState } from '~/app/rootReducer';
import i18n from '~/app/i18n';
import { appTheme } from '~/common/themes/theme';
import SyncIcon from '@mui/icons-material/Sync';
import styled from 'styled-components';
import KeyIndicators from './market-overview/KeyIndicators';
import ClosedMarketBanner from '~/common/components/closed-market-banner/ClosedMarketBanner';
import { UpdateDataButton } from '~/common/components/update-data-button/UpdateDataButton';

export interface IPropsFromState {}

export interface IPropsFromDispatch {
  fetchMarketData: typeof fetchMarketData;
}

export interface IMarketPageState {}

export interface IMarketPageProps {}

const DisclaimerContainer = styled.div`
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline;
`;

const MarketPage: React.FC<RouteComponentProps & IPropsFromDispatch & IPropsFromDispatch & IMarketPageProps> = (
  props,
) => {
  const topMoversRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  const trendsRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  document.title = i18n.t('marketsPage.documentTitle');

  React.useEffect(() => {
    const previousSection = (props.location.state as any)?.section;
    if (previousSection === 'TopMovers') {
      window.scrollTo({ behavior: 'smooth', top: topMoversRef.current?.offsetTop });
    } else if (previousSection === 'Trends') {
      window.scrollTo({ behavior: 'smooth', top: trendsRef.current?.offsetTop });
    }
    props.fetchMarketData();
  }, []);

  return (
    <Container>
      <HeaderPageContainer>
        <PageTitleContainer>
          <Typography variant="h2" component="div" style={{ paddingBottom: '12px', color: Colors.cobalt }}>
            <Trans i18nKey="common.markets" />
          </Typography>
          <DataLoadStamp isMarketDataTimestamp isLiveData isMarketScreen />
          <UpdateDataContainer>
            <UpdateDataButton onClick={() => props.fetchMarketData()} />
          </UpdateDataContainer>
        </PageTitleContainer>
        <PageTitleContainer>
          <SecurityAutocomplete pathname={props.location.pathname} />
        </PageTitleContainer>
      </HeaderPageContainer>
      <ClosedMarketBanner />
      <KeyIndicators />
      <div ref={trendsRef}>
        <Trends />
      </div>
      <div ref={topMoversRef}>
        <TopMovers />
      </div>
      <DisclaimerContainer>{i18n.t('marketsPage.disclaimer') as string}</DisclaimerContainer>
    </Container>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {};
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchMarketData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketPage));
