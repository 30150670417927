import * as React from 'react';
import DataTable, { IColumns } from '~/common/components/data-table/data-table';

export interface IProps {
    data: any[]
}

export interface IPropsFromState {
    previousPage: string,
}

export const TopHolding = ({ data } : {data:any[]}) => {
  const holdingData = data?.map((row) => (
    [
      row.securityName,
      row.marketPercent,
    ]
  ));

  const columns : IColumns[] = [
    {
      header: 'stockOverview.name',
      align: 'left',
    },
    {
      header: 'stockOverview.percentage',
      align: 'left',
    },
  ];

  return (
    <div>
      <DataTable data={holdingData} columns={columns} title="stockOverview.topHolding" boldFirstRow={false} />
    </div>
  );
};

export default (TopHolding);
