import { MainBox } from '~/common/styles/baseStyle';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Trans } from 'react-i18next';
import { SymbolInfoTypes } from '~/common/types';
import { ProductHeaderContainer } from '~/common/components/position-overview/ProductHeaderContainer';
import { PositionName } from '~/common/components/position-overview/PositionName';
import ProductHeaderSection from '~/common/components/position-overview/ProductHeaderSection';
import { StyledTab, StyledTabs } from '~/common/components/position-overview/PositionOverviewPage.style';
import { getMarketSymbolFormatted } from '~/common/helpers';
import Dividends from '~/common/components/position-overview/stock/dividends/Dividends';
import i18n from '~/app/i18n';
import { EtfSummary } from './EtfSummary';
import EtfOverview from './EtfOverview';
import AlertContainer from '../../info-container/alert-container';

export interface IProps {
  symbolDescription: string,
  stockData: any,
  historicalData: any,
  didSetMarketDataRealtime: boolean,
  isLiveData: boolean,
  onBannerRefreshButtonClick?: () => void,
  onBannerRefreshButtonState?: boolean,
  isHeld: boolean,
  isFromMarketsScreen: boolean,
}

interface IStockViewState {
  tabIndex: number
}

class EtfView extends React.PureComponent<IProps, IStockViewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }
  public componentDidMount() {
    document.title = i18n.t('stockOverview.etfTitle');
  }

  private handleTabChange = (e: any, value: any) => {
    this.setState({ tabIndex: value });
  };

  public render() {
    const { stockData, symbolDescription, didSetMarketDataRealtime, isLiveData } = this.props;
    const marketSymbolFormatted = getMarketSymbolFormatted(stockData);
    const shouldNotShowMarketDataUpdateButton = didSetMarketDataRealtime && !isLiveData;

    return (
      <>
        <ProductHeaderContainer>
          <PositionName symbol={stockData.symbol} name={symbolDescription} />
          <MainBox>
            {shouldNotShowMarketDataUpdateButton &&
              <div>
                <AlertContainer text="common.alertMarketDataMessage" buttonText="landing.updateData" onButtonClick={this.props.onBannerRefreshButtonClick} isButtonLoading={this.props.onBannerRefreshButtonState} />
              </div>}
            <Grid container>
              <ProductHeaderSection stockData={stockData} product={SymbolInfoTypes.ETF} isHeld={this.props.isHeld} isFromMarketsScreen={this.props.isFromMarketsScreen} />
              <EtfSummary stockData={stockData} />
            </Grid>
          </MainBox>
        </ProductHeaderContainer>
        <StyledTabs value={this.state.tabIndex} onChange={this.handleTabChange} textColor="secondary">
          <StyledTab label={<Trans i18nKey="stockOverview.tabs.overview" tabIndex={0} />} />
          <StyledTab label={<Trans i18nKey="stockOverview.tabs.distributions" />} tabIndex={1} />
        </StyledTabs>

        {this.state.tabIndex === 0 && <EtfOverview stockData={stockData} historicalData={this.props.historicalData} />}
        {this.state.tabIndex === 1 && <Dividends symbol={marketSymbolFormatted} etf />}
      </>
    );
  }
}

export default EtfView;
