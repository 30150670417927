import * as React from 'react';
import { IAppRootState } from '~/app/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from '~/common/styles/baseStyle';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import { IMarketProductDetailsState, TrendType } from '~/stores/marketData/marketDataTypes';
import { fetchProductDetailInfo } from '~/stores/marketData/actions/marketProductDetailAction';
import Loading from '~/common/components/Loading/Loading';
import { isFieldEmptyOrNull } from '~/common/helpers';
import { Cultures } from '~/app/appTypes';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import { getIsLiveDataFromState } from '~/stores/account/selectors/accountSelectors';
import MarketProductView from '~/common/components/market-overview/details/MarketProductView';

interface IMarketProductState {
  symbol: string;
  description: string;
  type: TrendType;
  previous: string;
}

export interface IPropsFromState {
  marketProductsDetails: IMarketProductDetailsState;
  culture: string;
}

export interface IPropsFromDispatch {
  fetchProductDetailInfo: typeof fetchProductDetailInfo;
  didSetMarketDataRealtime: boolean;
  isLiveData: boolean;
}

class MarketOverviewPage extends React.PureComponent<IPropsFromState & IPropsFromDispatch, IMarketProductState> {
  protected mounted = false;
  private symbol = (this.props as any).location.state.symbol;
  private previous = (this.props as any).location.state.source;
  private description = (this.props as any).location.state.description;
  private type = (this.props as any).location.state.type;
  private previousSection = (this.props as any).location.state.section;
  constructor(props: any) {
    super(props);

    this.state = {
      symbol: this.symbol,
      description: this.description,
      type: this.type,
      previous: this.previous,
    };
  }

  public componentDidMount() {
    this.mounted = true;
    this.fetchProductInfo(this.symbol, this.type);
  }

  public componentDidUpdate(prevProps: IPropsFromDispatch & IPropsFromState, prevState: IMarketProductState) {
    if ((this.props as any).location.state.symbol !== this.state.symbol) {
      this.symbol = (this.props as any).location.state.symbol;
      this.description = (this.props as any).location.state.description;
      this.type = (this.props as any).location.state.type;

      this.setState({
        symbol: this.symbol,
        description: this.description,
        type: this.type,
        previous: this.previous,
      });
      this.fetchProductInfo(this.symbol, this.type);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public fetchProductInfo = (symbol: string, type: TrendType) => {
    this.props.fetchProductDetailInfo(symbol, type);
  };

  private refreshProductDetailInfo = () => {
    this.props.fetchProductDetailInfo(this.symbol, this.type);
  };

  public render() {
    if (
      this.props.marketProductsDetails.isFetchingProductDetailedInfoData ||
      isFieldEmptyOrNull(this.props.marketProductsDetails.products[this.symbol])
    ) {
      return <Loading show />;
    }

    const currentProductDetails = this.props.marketProductsDetails.products[this.symbol];
    return (
      <Container style={{ paddingTop: 20 }}>
        {this.state.previous && (
          <Link
            to={{
              pathname: this.previous,
              state: {
                section: this.previousSection,
              },
            }}
          >
            <Button style={{ color: '#003DA5', textTransform: 'none' }} startIcon={<ArrowBack />}>
              <Trans i18nKey={this.previous.match(/markets/i) ? 'common.markets' : ''} />
            </Button>
          </Link>
        )}

        <MarketProductView
          symbolDescription={
            this.props.culture === Cultures.english ? currentProductDetails.nameEn : currentProductDetails.nameFr
          }
          productData={currentProductDetails}
          type={this.type}
          didSetMarketDataRealtime={this.props.didSetMarketDataRealtime}
          isLiveData={this.props.isLiveData}
          onBannerRefreshButtonClick={this.refreshProductDetailInfo}
          onBannerRefreshButtonState={this.props.marketProductsDetails.isFetchingProductDetailedInfoData}
          didFetchMarketProductDetailFailed={this.props.marketProductsDetails.isFetchingProductDetailedInfoDataHasError}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  return {
    marketProductsDetails: state.marketProductDetails,
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isLiveData: getIsLiveDataFromState(state),
    culture: state.system.culture,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchProductDetailInfo,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarketOverviewPage) as any;
