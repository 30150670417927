const Colors = {
  codGray: '#1b1b1b',
  cobalt: '#003da5',
  mariner: '#2062D4',
  crimson: '#E71313',
  japaneseLaurel: '#318500',
  yellowMetal: '#765F40',
  shadow: '#836c4b',
  donkeyBrown: '#A39073',
  quillGray: '#DADAD8',
  desertStorm: '#F0F0EF',
  babyBlue: '#E3F2FD',
  darkBabyBlue: '#cae5fb',

  iaGray: '#acafae', // TODO: find real color and replace
  iaGray2: '#D3D8DE', // Color indroduced by MarketData initiative (Should that replace other gray?)
  iaNavy: '#000548', // TODO: find real color and replace

  lightGray: '#6B6B6B',

  white: '#FFFFFF',

  redAlert: '#FF0303',
  anotherGrayShade: '#7F7F7F',
  anotherGrayShade2: '#BBC2CA',
  anotherGrayShade3: '#333333',
  anotherGrayShade4: '#7D7D7D',
  anotherGrayShade5: '#797979',
  anotherGrayShade6: '#666666',

  orangeAlert: '#F4AF84',
  orangeLightAlert: '#FEF4ED',
  orangeDarkAlert: '#EF6D18',
};

export default Colors;
