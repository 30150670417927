import * as React from 'react';
import { Trans } from 'react-i18next';

import { Row, Col } from 'react-flexbox-grid';
import { NoteContainer, VisibilityIconStyle } from './MirrorViewNote.style';

const MirrorViewNote: React.FC = () => (
  <NoteContainer>
    <Row middle="xs">
      <Col>
        <VisibilityIconStyle htmlColor="#FFFFFF" />
      </Col>
      <Col>
        <Trans i18nKey="landing.mirrorViewNote" />
      </Col>
    </Row>
  </NoteContainer>);

export default MirrorViewNote;
