import * as React from 'react';
import { RouteNames } from '~/app/appTypes';
import SymbolDescriptionDisplay from './SymbolDescriptionDisplay';
import { Grid, Stack } from '@mui/material';
import MonetaryValue from '../../monetary-value/MonetaryValue';
import i18n from '~/app/i18n';
import { ISortViewsMetadata } from '~/stores/party/partyTypes';

export function renderSymbol(row: any, isSymbolLinkEnabled: boolean, locationPathName: string) {
  let ticker = `${row.marketPriceSource}:${row.symbol}`;
  if (row.investmentCategory === 'MF') {
    ticker = row.symbol;
  }
  const obj = {
    children: (
      <SymbolDescriptionDisplay
        isSymbolLinkEnabled={isSymbolLinkEnabled}
        investmentCategory={row.investmentCategory}
        routeName={RouteNames.positionOverview}
        ticker={ticker}
        pathname={locationPathName}
        symbol={row.symbol}
        description={row.description}
        notFoundAtProvider={row.notFoundAtProvider}
      />
    ),
  };
  return obj;
}

export function renderMarketValuePrice(value: any, row: any, culture: string) {
  const totalMarketPrice = row.currency === 'CAD' ? row.totalMarketPriceCad : row.totalMarketPriceUsd;
  const obj = {
    children: (
      <Grid container direction="row" alignContent="flex-end">
        <Grid item xs={11}>
          <Stack>
            <MonetaryValue
              value={value}
              culture={culture}
              amountOfDigits={3}
              alignRight
              signType="parentheses"
              currency={
                row.individualMarketPrice !== row.individualMarketPriceInAccountCurrency
                  ? row.marketPriceCurrency
                  : undefined
              }
              zeroPlaceholder={`${i18n.t('holdings.NA')}¹`}
            />
            <MonetaryValue
              value={totalMarketPrice}
              culture={culture}
              alignRight
              currency={row.netAmountCurrency}
              zeroPlaceholder={i18n.t('holdings.NA')}
            />
          </Stack>
        </Grid>
        <Grid item xs="auto">
          <Stack>
            <span>&nbsp;</span>
            {row.accruedInterest && <span>*</span>}
          </Stack>
        </Grid>
      </Grid>
    ),
  };
  return obj;
}

export function sorterByDescription(a: any, b: any) {
  if (a.description != null && b.description != null) {
    return a.description.localeCompare(b.description);
  }
  return 0;
}

export function sorterByQuantity(a: any, b: any) {
  return a.quantity - b.quantity;
}

export function sorterByIndividualPrice(a: any, b: any) {
  return a.individualMarketPrice - b.individualMarketPrice;
}

export function sorterByTotalMarketPrice(a: any, b: any) {
  return a.totalMarketPriceCad - b.totalMarketPriceCad;
}

export function sorterByInvestedCapital(a: any, b: any) {
  return a.investedCapitalCAD - b.investedCapitalCAD;
}

export function sorterByInvestedCapitalGainLoss(a: any, b: any) {
  return a.investedCapitalGainLossCAD - b.investedCapitalGainLossCAD;
}

export function sorterByBookValue(a: any, b: any) {
  return a.bookValueCad - b.bookValueCad;
}

export function sorterByUnrealizedGainAndLoss(a: any, b: any) {
  return a.unrealizedGainAndLoss - b.unrealizedGainAndLoss;
}

export function saveHoldingSortPreferencesIfNeeded(
  metadata: ISortViewsMetadata,
  isUserAdvisorOrAdmin: boolean,
  saveHoldingSortPreferences: (metadata: ISortViewsMetadata) => void,
) {
  if (!isUserAdvisorOrAdmin) {
    saveHoldingSortPreferences(metadata);
  }
}
