import * as React from 'react';
import { SectionHeaderLabel, HistoryChartContainer } from '~/common/components/position-overview/PositionOverviewPage.style';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import HistoryGraph from './HistoryGraph';

export interface IStockData {
  symbol: string,
  historicalData: any,
  dataKey?: string,
  sectionTitleKey?: string,
  isMarketProduct?: boolean,
  defaultZoomFilter?: string,
}

export const Performance: React.FunctionComponent<IStockData> = ({ symbol, historicalData, dataKey, sectionTitleKey, isMarketProduct, defaultZoomFilter }) => (
  <>
    <SectionHeaderLabel>
      {sectionTitleKey ? (
        <Typography variant="h3" component="div">
          <Trans i18nKey={sectionTitleKey} />
        </Typography>
      ) : (
        <Typography variant="h3" component="div">
          <Trans i18nKey="common.performance" />
        </Typography>
      )}
    </SectionHeaderLabel>

    <HistoryChartContainer>
      <HistoryGraph symbol={symbol} isStock={false} historicalData={historicalData} dataKey={dataKey} isMarketProduct={isMarketProduct} defaultZoomFilter={defaultZoomFilter} />
    </HistoryChartContainer>
  </>
);

export default Performance;
