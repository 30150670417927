import * as React from 'react';
import { Modal } from 'antd';
import { ConfirmContainer, StyledP, Title } from './ModalSaveResult.style';
import { ModalSaveSuccessLayout } from './ModalSaveSuccess';
import { Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export interface IModalSaveResultProps {
  onClose: () => void;
  layout?: ModalSaveSuccessLayout;
  isVisible: boolean;
  header: string;
  icon: JSX.Element;
  title: string;
  message: string;
}

const ModalSaveResult: React.FC<IModalSaveResultProps> = (props: IModalSaveResultProps) => (
  <Modal
    width="750px"
    open={props.isVisible}
    centered
    title={props.header}
    closeIcon={props.layout === ModalSaveSuccessLayout.noHeader ? 'false' : ''}
    onCancel={props.onClose}
    footer={null}
  >
    <ConfirmContainer>
      <StyledP>{props.icon}</StyledP>
      <Title>{props.title}</Title>
      <div>
        <Typography style={props.layout === ModalSaveSuccessLayout.noHeader ? { padding: '12px 12px' } : {}}>
          {props.message}
        </Typography>
      </div>
      {props.layout && (
        <StyledP style={{ marginTop: '12px' }}>
          <Button variant="contained" color="secondary" onClick={props.onClose}>
            <Trans i18nKey="common.close" />
          </Button>
        </StyledP>
      )}
    </ConfirmContainer>
  </Modal>
);

export default ModalSaveResult;
