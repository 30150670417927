import * as React from 'react';
import { appTheme } from '~/common/themes/theme';
import { Box, Collapse, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { Link } from 'react-router-dom';

export interface INavigationOption {
  key: string;
  text: React.ReactNode;
  route?: string;
  subOptions?: INavigationOption[];
  isCurrentLocation: boolean;
}

export interface INavigationLinksProps {
  options: INavigationOption[];
}

export interface INavigationLinksState {
  openIndexs: number[];
  open: boolean;
  collapsed: number[];
}

class NavigationLinks extends React.PureComponent<INavigationLinksProps, INavigationLinksState> {
  private establishOpenIndexs = (options: INavigationOption[]): number[] => [1] as number[];

  constructor(props: INavigationLinksProps) {
    super(props);

    this.state = {
      openIndexs: this.establishOpenIndexs(props.options),
      open: true,
      collapsed: [],
    };
  }

  private handleClick = (index: number) => {
    const { collapsed } = this.state;

    const newArray = collapsed.slice();
    if (!collapsed.includes(index)) {
      newArray.push(index);
    } else {
      newArray.splice(collapsed.indexOf(index), 1);
    }
    this.setState({ collapsed: newArray });
  };

  public handleNavigationAggregateClick = (event: React.MouseEvent) => {
    const { openIndexs } = this.state;
    const eventTarget = event.currentTarget;
    if (!eventTarget) {
      return;
    }

    const clickedOptionIndex = eventTarget.getAttribute('data-index');
    if (!clickedOptionIndex) {
      return;
    }

    const openIndexsIndex = openIndexs.indexOf(+clickedOptionIndex);
    const newOpenIndex = [...openIndexs];

    if (openIndexsIndex >= 0) {
      newOpenIndex.splice(openIndexsIndex, 1);
    } else {
      newOpenIndex.push(+clickedOptionIndex);
    }

    this.setState({ openIndexs: newOpenIndex });
  };

  public renderNavigationLink = (option: INavigationOption, isMainLink: boolean) => {
    const route = option.route ? option.route : '/notimplementedyet';
    const paddingForItem = isMainLink ? '17px' : '35px';

    return (
      <ListItem
        key={`${option.route}${option.text}`}
        style={{ paddingLeft: paddingForItem, paddingRight: 0, textDecoration: 'none' }}
        selected={option.isCurrentLocation}
        divider
        button
        component={Link}
        to={route}
      >
        <ListItemText
          primary={
            <Typography variant={isMainLink ? 'caption' : 'subtitle2'} display="block" gutterBottom={false}>
              {' '}
              {option.text}{' '}
            </Typography>
          }
        />
      </ListItem>
    );
  };

  public renderNavigationLinkAggregate = (option: INavigationOption, index: number) => {
    const { collapsed } = this.state;

    const collapsedSub = !collapsed.includes(index);
    if (!option.subOptions) {
      return null;
    }

    return (
      <>
        <ListItem index-value={index} onClick={() => this.handleClick(index)} divider>
          {collapsedSub ? (
            <ArrowDropDownSharpIcon style={{ color: appTheme.palette.secondary.dark, marginBottom: '5px' }} />
          ) : (
            <ArrowRightSharpIcon style={{ color: appTheme.palette.secondary.dark, marginBottom: '5px' }} />
          )}
          <ListItemText
            primary={
              <Typography variant="caption" display="block" gutterBottom={false}>
                {' '}
                {option.text}{' '}
              </Typography>
            }
          />
        </ListItem>
        <Collapse in={collapsedSub} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {option.subOptions.map((item) => this.renderNavigationLink(item, false))}
          </List>
        </Collapse>
      </>
    );
  };

  public renderOption = (option: INavigationOption, index: number) => (
    <React.Fragment key={option.key}>
      <Divider />
      {option.subOptions ? this.renderNavigationLinkAggregate(option, index) : this.renderNavigationLink(option, true)}
    </React.Fragment>
  );

  public render() {
    const { options } = this.props;

    return (
      <Box
        style={{
          width: '230px',
          maxWidth: 360,
          backgroundColor: appTheme.palette.secondary.light,
          marginRight: '30px',
        }}
      >
        <nav aria-label="main menu">
          <List style={{ paddingTop: '44px' }}>{options.map(this.renderOption)}</List>
        </nav>
      </Box>
    );
  }
}

export default NavigationLinks;
