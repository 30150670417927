export interface IoidcState {
  tokenFetched: boolean;
  token: string;
  profile: ITokenProfile;
}

export interface IoidcResult {
  access_token: string;
}

export interface ITokenProfile {
  preferred_username: string;
}

export const enum oidcActionTypes {
  FETCH_TOKEN = '[OIDC] Fetch Token',
  FETCH_TOKEN_SUCCESSFUL = '[OIDC] Fetch Token Successful',
  FETCH_TOKEN_ERROR = '[OIDC] Fetch Token Error',
}
