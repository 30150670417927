import { UpdateIntradayChangePreferenceKey, UpdateMarketDataRealtimeKey } from '~/common/utils/MetadataHelper';
import {
  IPartyState,
  IPartyV1,
  PartyActionTypes,
  IConsentPreferences,
  IConsentVerbiages,
} from '~/stores/party/partyTypes';
import { Reducer } from 'redux';

export const initialState: IPartyState = {
  accountShare: [] as any[],
  partiesShared: [] as IPartyV1[],
  partyV1: {} as IPartyV1,
  accountSearch: null,
  isFetching: false,
  isFetchingPartySearch: false,
  isFetchingPartyUser: false,
  dataExpiration: undefined,
  isHouseHoldingView: true,
  isProgramView: false,
  isFetchingSavePreferredAccount: false,
  isFetchingAccesSharing: false,
  isSavePreferredAccountSuccess: false,
  isFetchingPartyUserHasError: false,
  isSavingConsentPreferences: false,
  isConsentVerbiageLoaded: false,
  isConsentVerbiageFetching: false,
  isConsentVerbiageFailed: false,
  consentVerbiages: [] as any[],
  isDocumentDeliveryNotificationSaving: false,
  isDocumentDeliveryNotificationFail: false,
  isSavingConsentPreferencesFail: false,
  isSavingConsentPreferencesSuccess: false,
};

const handleFetchAccesSharing = (state: IPartyState) => ({
  ...state,
  isFetchingAccesSharing: true,
  partiesShared: [],
});

const handleFetchAccesSharingSuccessful = (state: IPartyState, payload: any) => {
  const { accounts, parties } = payload;
  return {
    ...state,
    accountShare: accounts,
    partiesShared: parties,
    isFetchingAccesSharing: false,
  };
};

const handleFetchAccesSharingAborted = (state: IPartyState) => ({
  ...state,
  isFetchingAccesSharing: false,
});

const handleFetchPartySearch = (state: IPartyState) => ({
  ...state,
  accountSearch: null,
  isFetchingPartySearch: true,
});

const handleFetchPartySearchAborted = (state: IPartyState) => ({
  ...state,
  accountSearch: null,
  isFetchingPartySearch: false,
});

const handleFetchPartySearchSuccessful = (state: IPartyState, payload: any) => {
  const { user } = payload;

  return {
    ...state,
    accountSearch: user,
    isFetchingPartySearch: false,
  };
};

const handleFetchPartyUserAborted = (state: IPartyState) => ({
  ...state,
  isFetchingPartyUser: false,
  isFetchingPartyUserHasError: true,
});

const handleFetchPartySuccessful = (state: IPartyState, payload: any) => {
  const { party } = payload;
  return {
    ...state,
    isFetchingPartyUser: false,
    partyV1: party,
  };
};

const handleFetchParty = (state: IPartyState) => ({
  ...state,
  isFetchingPartyUser: true,
});

const handleSavePrefereredAccount = (state: IPartyState, payload: any) => ({
  ...state,
  isFetchingSavePreferredAccount: true,
  isSavePreferredAccountSuccess: false,
});

const handleSavePrefereredAccountAborted = (state: IPartyState) => ({
  ...state,
  isFetchingSavePreferredAccount: false,
  isSavePreferredAccountSuccess: false,
});

const handleSavePrefereredAccountSuccessful = (state: IPartyState) => ({
  ...state,
  isFetchingSavePreferredAccount: false,
  isSavePreferredAccountSuccess: true,
});

const handleResetPrefereredAccountSaving = (state: IPartyState) => ({
  ...state,
  isSavePreferredAccountSuccess: false,
});

const handleSaveShareAccount = (state: IPartyState, payload: any) => ({
  ...state,
});

const handleDeleteShareAccount = (state: IPartyState, payload: any) => ({
  ...state,
});

const handleToggleHouseholdView = (state: IPartyState) => ({
  ...state,
  isHouseHoldingView: !state.isHouseHoldingView,
});

const handleToggleProgramView = (state: IPartyState) => ({
  ...state,
  isProgramView: !state.isProgramView,
});

const handleSaveDocumentDeliveryNotification = (state: IPartyState, payload: any) => ({
  ...state,
  isDocumentDeliveryNotificationSaving: true,
  isDocumentDeliveryNotificationFail: false,
});

const handleSaveDocumentDeliveryNotificationSuccess = (state: IPartyState) => ({
  ...state,
  isDocumentDeliveryNotificationSaving: false,
  isDocumentDeliveryNotificationFail: false,
});

const handleSaveDocumentDeliveryNotificationFailed = (state: IPartyState) => ({
  ...state,
  isDocumentDeliveryNotificationSaving: false,
  isDocumentDeliveryNotificationFail: true,
});

const handleSaveUpdateDeliveryPreferencesMetadata = (state: IPartyState, payload: any) => ({
  ...state,
});

const handleSaveConsentPromptMetadata = (state: IPartyState, payload: any) => ({
  ...state,
});

const handleSaveHoldingSortPreferencesMetadata = (state: IPartyState, payload: any) => ({
  ...state,
});

const handleSaveMarketDataRealtime = (state: IPartyState, payload: any) => ({
  ...state,
  partyV1: {
    ...state.partyV1,
    metadata: [...UpdateMarketDataRealtimeKey(state.partyV1.metadata, payload.realtime)],
  },
});

const handlesSaveIntradayChan = (state: IPartyState, payload: any) => ({
  ...state,
  partyV1: {
    ...state.partyV1,
    metadata: [...UpdateIntradayChangePreferenceKey(state.partyV1.metadata, payload.realtime)],
  },
});

const handleSaveConsentPreferences = (state: IPartyState) => ({
  ...state,
  isSavingConsentPreferences: true,
  isSavingConsentPreferencesFail: false,
  isSavingConsentPreferencesSuccess: false,
});

const handleSaveConsentPreferencesSuccessful = (state: IPartyState) => ({
  ...state,
  isSavingConsentPreferences: false,
  isSavingConsentPreferencesFail: false,
  isSavingConsentPreferencesSuccess: true,
});

const handleSaveConsentPreferencesFailed = (state: IPartyState) => ({
  ...state,
  isSavingConsentPreferences: false,
  isSavingConsentPreferencesFail: true,
  isSavingConsentPreferencesSuccess: false,
});

const handleConsentVerbiagesLoaded = (state: IPartyState) => ({
  ...state,
  isConsentVerbiageLoaded: false,
  isConsentVerbiageFetching: true,
  isConsentVerbiageFailed: false,
});

const handleConsentVerbiagesLoadedSuccessful = (state: IPartyState, payload: any) => ({
  ...state,
  consentVerbiages: payload.verbiages,
  isConsentVerbiageLoaded: true,
  isConsentVerbiageFetching: false,
  isConsentVerbiageFailed: false,
});

const handleConsentVerbiagesLoadedFailed = (state: IPartyState) => ({
  ...state,
  isConsentVerbiageLoaded: false,
  isConsentVerbiageFetching: false,
  isConsentVerbiageFailed: true,
});

const handleUpdateConsents = (state: IPartyState, payload: any) => {
  const updates = payload as IConsentPreferences[];
  const current = state.consentVerbiages;

  const serializedArr = JSON.stringify(current);
  const currentState = JSON.parse(serializedArr);

  updates.forEach((x) => {
    currentState.filter(
      (c: IConsentVerbiages) =>
        c.consentPurpose === x.consentPurpose && c.consentVerbiageVersion === x.consentVerbiageVersion,
    )[0].value = x.value;
  });

  return {
    ...state,
    consentVerbiages: currentState,
  };
};

const handleResetSaveConsents = (state: IPartyState) => ({
  ...state,
  isSavingConsentPreferences: false,
  isSavingConsentPreferencesFail: false,
  isSavingConsentPreferencesSuccess: false,
});

export const partyReducer: Reducer<IPartyState> = (state = initialState, action) => {
  switch (action.type) {
    case PartyActionTypes.FETCH_PARTY_SEARCH:
      return handleFetchPartySearch(state);
    case PartyActionTypes.FETCH_PARTY_SEARCH_NOTFOUND:
    case PartyActionTypes.FETCH_PARTY_SEARCH_ABORTED:
    case PartyActionTypes.FETCH_PARTY_SEARCH_FAILED:
      return handleFetchPartySearchAborted(state);
    case PartyActionTypes.FETCH_PARTY_SEARCH_SUCCESSFUL:
      return handleFetchPartySearchSuccessful(state, action.payload);
    case PartyActionTypes.FETCH_PARTY_SUCCESSFUL:
      return handleFetchPartySuccessful(state, action.payload);
    case PartyActionTypes.FETCH_PARTY:
      return handleFetchParty(state);
    case PartyActionTypes.FETCH_PARTY_NOTFOUND:
    case PartyActionTypes.FETCH_PARTY_ABORTED:
    case PartyActionTypes.FETCH_PARTY_FAILED:
      return handleFetchPartyUserAborted(state);
    case PartyActionTypes.FETCH_ACCES_SHARING:
      return handleFetchAccesSharing(state);
    case PartyActionTypes.FETCH_ACCES_SHARING_SUCCESSFUL:
      return handleFetchAccesSharingSuccessful(state, action.payload);
    case PartyActionTypes.SAVE_PREFERRED_ACCOUNT_FAILED:
      return handleFetchAccesSharingAborted(state);
    case PartyActionTypes.SAVE_PREFERRED_ACCOUNT:
      return handleSavePrefereredAccount(state, action.payload);
    case PartyActionTypes.SAVE_PREFERRED_ACCOUNT_SUCCESSFUL:
      return handleSavePrefereredAccountSuccessful(state);
    case PartyActionTypes.SAVE_PREFERRED_ACCOUNT_FAILED:
      return handleSavePrefereredAccountAborted(state);
    case PartyActionTypes.RESET_PARTY_PREFERED_ACCOUNT_SAVING:
      return handleResetPrefereredAccountSaving(state);
    case PartyActionTypes.SAVE_SHARE_ACCOUNT:
      return handleSaveShareAccount(state, action.payload);
    case PartyActionTypes.DELETE_SHARE_ACCOUNT:
      return handleDeleteShareAccount(state, action.payload);
    case PartyActionTypes.TOGGLE_HOUSEHOLD_VIEW:
      return handleToggleHouseholdView(state);
    case PartyActionTypes.TOGGLE_PROGRAM_VIEW:
      return handleToggleProgramView(state);
    case PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION:
      return handleSaveDocumentDeliveryNotification(state, action.payload);
    case PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION_SUCCESSFUL:
      return handleSaveDocumentDeliveryNotificationSuccess(state);
    case PartyActionTypes.SAVE_DOCUMENT_DELIVERY_NOTIFICATION_FAILED:
      return handleSaveDocumentDeliveryNotificationFailed(state);
    case PartyActionTypes.SAVE_UPDATE_DELIVERY_PREFERENCES_METADATA:
      return handleSaveUpdateDeliveryPreferencesMetadata(state, action.payload);
    case PartyActionTypes.SAVE_CONSENT_PROMPT_METADATA:
      return handleSaveConsentPromptMetadata(state, action.payload);
    case PartyActionTypes.SAVE_MARKET_DATA_REALTIME_PREFERENCES_METADATA:
      return handleSaveMarketDataRealtime(state, action.payload);
    case PartyActionTypes.SAVE_INTRADAY_CHANGE_PREFERENCES_METADATA:
      return handlesSaveIntradayChan(state, action.payload);
    case PartyActionTypes.SAVE_HOLDING_SORT_PREFERENCES_METADATA:
      return handleSaveHoldingSortPreferencesMetadata(state, action.payload);
    case PartyActionTypes.SAVE_CONSENT_PREFERENCES:
      return handleSaveConsentPreferences(state);
    case PartyActionTypes.SAVE_CONSENT_PREFERENCES_SUCCESSFUL:
      return handleSaveConsentPreferencesSuccessful(state);
    case PartyActionTypes.SAVE_CONSENT_PREFERENCES_FAILED:
      return handleSaveConsentPreferencesFailed(state);
    case PartyActionTypes.LOAD_CONSENT_VERBIAGE_SUCCESSFUL:
      return handleConsentVerbiagesLoadedSuccessful(state, action.payload);
    case PartyActionTypes.LOAD_CONSENT_VERBIAGE_FAILED:
      return handleConsentVerbiagesLoadedFailed(state);
    case PartyActionTypes.LOAD_CONSENT_VERBIAGE:
      return handleConsentVerbiagesLoaded(state);
    case PartyActionTypes.UPDATE_PARTY_CONSENTS:
      return handleUpdateConsents(state, action.payload);
    case PartyActionTypes.RESET_SAVE_CONSENT_PREFERENCES:
      return handleResetSaveConsents(state);
    default:
      return state;
  }
};
