export enum RegulatoryRole {
    Trader = 'trader',
    LicensedAssistant = 'licensedAssistant',
    ChiefComplianceOfficer = 'chiefComplianceOfficer',
    Supervisor = 'supervisor',
    InvestmentAdvisor = 'investmentAdvisor',
    WealthAdvisor = 'wealthAdvisor',
    CorporateBranchManager = 'corporateBranchManager',
    SeniorInvestmentAdvisor = 'seniorInvestmentAdvisor',
    BranchAdministrator = 'branchAdministrator',
    SeniorFinancialAdvisor = 'seniorFinancialAdvisor',
    RegionalVicePresident = 'regionalVicePresident',
    SupervisingPortfolioManager = 'supervisingPortfolioManager',
    SeniorWealthAdvisor = 'seniorWealthAdvisor',
    SeniorWealthAdvisorFemale = 'seniorWealthAdvisorFemale',
    AssociateInvestmentAdvisor = 'associateInvestmentAdvisor',
    PortfolioManager = 'portfolioManager',
    SeniorPortfolioManager = 'seniorPortfolioManager',
    UnlicensedAssistant = 'unlicensedAssistant',
    SeniorBranchManager = 'seniorBranchManager',
    PortfolioManagerUnderSupervision = 'portfolioManagerUnderSupervision',
    ChiefFinancialOfficer = 'chiefFinancialOfficer',
    QcFinancialPlanner = 'qcFinancialPlanner',
    InstitutionalSupervisor = 'institutionalSupervisor',
    Other = 'other'
}
