import { Cultures, Languages, SortDirection } from '~/app/appTypes';
import i18n from '~/app/i18n';
import { IAccount } from '~/stores/account/accountTypes';
import { IQueryStringParameter } from '~/stores/system/systemTypes';
import moment from 'moment';

import {
  API_GATEWAY_ADVISOR_CLIENTID_EN,
  API_GATEWAY_ADVISOR_CLIENTID_FR,
  API_GATEWAY_AUTHORITY,
  API_GATEWAY_AUTHORITY_EN_NEW,
  API_GATEWAY_AUTHORITY_FR_NEW,
  API_GATEWAY_CLIENTID_EN_NEW,
  API_GATEWAY_CLIENTID_FR_NEW,
  API_GATEWAY_REDIRECT_URI,
  TOGGLE_CONSENT,
  TOGGLE_HIDE_QIR,
  TOGGLE_TRADECONFIRMATIONS,
} from './API';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { HouseholdType, PartyTypeCode } from './partyHelpers';
import { DocumentType } from '~/pages/householding-page/documentdelivery-view/deliveryPreferencesLoadMapper';

export const subsidiaryName = 'IaSecurities';
export const hostEnNew = 'iaprivatewealth';
export const hostFrNew = 'iagestionprivee';
export const hostAdvisorEn = 'iasa';
export const hostAdvisorFr = 'iavma';

export const getCurrentIsoLang = () => i18n.language.substring(0, 2);

export const isCurrentIsoLangEN = () => getCurrentIsoLang() === Languages.english;

export const isCurrentIsoLangFR = () => getCurrentIsoLang() === Languages.french;

export const moveItemInArray = (array: any[], from: number, to: number) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

export const arrayIncludes = (array: any[], item: any) => {
  for (const index in array) {
    if (array[index] === item) {
      return true;
    }
  }
  return false;
};

export const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`;

export interface ICompareArgument {
  propertyToCompare: string;
  direction?: SortDirection;
}

export const compareBy = (compareArgument: ICompareArgument) => (a: any, b: any) => {
  const { propertyToCompare } = compareArgument;
  const directionMultiplier = compareArgument.direction
    ? compareArgument.direction === SortDirection.Asc
      ? 1
      : -1
    : 1;

  return compare(a[propertyToCompare], b[propertyToCompare]) * directionMultiplier;
};

export const compareByMany = (compareArguments: ICompareArgument[]) => (a: any, b: any) => {
  let result = 0;
  compareArguments.forEach((compareArgument) => {
    if (result === 0) {
      const { propertyToCompare } = compareArgument;
      const directionMultiplier = compareArgument.direction
        ? compareArgument.direction === SortDirection.Asc
          ? 1
          : -1
        : 1;

      result = compare(a[propertyToCompare], b[propertyToCompare]) * directionMultiplier;
    }
  });

  return result;
};

export const groupBy = (list: any[], keyGetter: (a: any) => string) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const compare = (a: any, b: any) => {
  if (typeof a === 'string') {
    return a.localeCompare(b);
  }

  if (typeof a === 'number') {
    return a - b;
  }

  throw new Error('compare type has to be string or number');
};

export const establishCulture = (stringLanguage: string | undefined) => {
  let culture = Cultures.english;

  if (stringLanguage !== undefined && stringLanguage === Languages.french) {
    culture = Cultures.french;
  }

  return culture;
};

export const deserializeQueryString = (queryString: string): IQueryStringParameter[] => {
  const parameters = [] as IQueryStringParameter[];
  const parametersString = queryString.split('&');

  parametersString.forEach((paramterString: string) => {
    const isFirstParameter = paramterString.indexOf('?') >= 0;
    const seperatorIndex = paramterString.indexOf('=');
    const nameStartIndex = isFirstParameter ? 1 : 0;
    const nameLength = seperatorIndex - nameStartIndex;
    const valueStartIndex = seperatorIndex + 1;

    const name = paramterString.substr(nameStartIndex, nameLength);
    const value = paramterString.substr(valueStartIndex);

    parameters.push({ name, value });
  });

  return parameters;
};

export const serializeQueryStringParameters = (queryStringParameters: IQueryStringParameter[]): string => {
  let serializedParameters = '?';
  queryStringParameters.forEach((parameter, index) => {
    serializedParameters += `${parameter.name}=${parameter.value}${
      index < queryStringParameters.length - 1 ? '&' : ''
    }`;
  });

  return serializedParameters;
};

export const authHeader = (token: string) => ({
  headers: {
    Bearer: token,
  },
});

export const authHeaderJsonPatch = (token: string) => ({
  headers: {
    Bearer: token,
    'Content-Type': 'application/json-patch+json',
  },
});

export const getApiGatewayRedirectUri = () => {
  const host = window.location.origin;

  return host + API_GATEWAY_REDIRECT_URI;
};

export const getApiGatewayClientID = () => {
  const isNewHostEn: boolean = window.location.href.toLowerCase().indexOf(hostEnNew) >= 0;

  const isFromAdvisor: boolean =
    window.location.href.toLowerCase().indexOf(hostAdvisorEn) >= 0 ||
    window.location.href.toLowerCase().indexOf(hostAdvisorFr) >= 0;
  const isEn: boolean =
    window.location.href.toLowerCase().indexOf(hostAdvisorEn) >= 0 ||
    window.location.href.toLowerCase().indexOf(hostEnNew) >= 0 ||
    isNewHostEn;

  if (isFromAdvisor) {
    if (isEn) {
      return API_GATEWAY_ADVISOR_CLIENTID_EN;
    }
    return API_GATEWAY_ADVISOR_CLIENTID_FR;
  }
  if (isEn) {
    return API_GATEWAY_CLIENTID_EN_NEW;
  }
  return API_GATEWAY_CLIENTID_FR_NEW;
};

export const getApiGatewayAuthority = () => {
  const isNewHostEn: boolean = window.location.href.toLowerCase().indexOf(hostEnNew) >= 0;

  const isFromAdvisor: boolean =
    window.location.href.toLowerCase().indexOf(hostAdvisorEn) >= 0 ||
    window.location.href.toLowerCase().indexOf(hostAdvisorFr) >= 0;
  const isEn: boolean =
    window.location.href.toLowerCase().indexOf(hostAdvisorEn) >= 0 ||
    window.location.href.toLowerCase().indexOf(hostEnNew) >= 0 ||
    isNewHostEn;

  if (isFromAdvisor) {
    return API_GATEWAY_AUTHORITY;
  }
  if (isEn) {
    return API_GATEWAY_AUTHORITY_EN_NEW;
  }
  return API_GATEWAY_AUTHORITY_FR_NEW;
};

export const formatWebSiteUrl = (url: string): string => {
  const URL_SCHEME_REGEXP = /^((?:f|ht)tps?:)?\/\//;
  const DEFAULT_SCHEME = 'http';
  let formatedUrl = url;

  if (!URL_SCHEME_REGEXP.test(url)) {
    formatedUrl = `${DEFAULT_SCHEME}://${url}`;
  }

  return formatedUrl;
};

export const isTaxFilterEnabled = (): boolean => {
  const currentDate = moment();
  const currentYear = moment().year();
  if (
    currentDate.isSameOrAfter(`${currentYear}-01-15`, 'day') &&
    currentDate.isSameOrBefore(`${currentYear}-04-30`, 'day')
  ) {
    return true;
  }
  return false;
};

export const removeAccents = (str: string) => {
  const map = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  } as any;

  for (const pattern of Object.keys(map)) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str;
};

export const isFieldEmptyOrNull = (value: any) => {
  if (value === undefined || value === '' || value === null) {
    return true;
  }

  return false;
};

export const formatDate = (value: Date) => {
  if (value === undefined || value === null) {
    return '';
  }
  return value.toString().split('T')[0];
};

export const formatValueType = (value: any, type: string) => {
  if (value === undefined || value === null) {
    return '';
  }
  if (type === 'date') {
    return value.toString().split('T')[0];
  }
};

export const isStockSymbol = (symbol: string) => {
  if (symbol === undefined || symbol === null) {
    return false;
  }
  return symbol.indexOf(':') > 0;
};

export const getSymbol = (symbol: string) => (isStockSymbol(symbol) ? symbol.split(':')[1] : symbol);

export const getMarketIdentificationCode = (symbol: string) => (isStockSymbol(symbol) ? symbol.split(':')[0] : '');

export const getMarketSymbolFormatted = (stockData: any) => `${stockData.marketIdentificationCode}:${stockData.symbol}`;

export const formatDatePerLocale = (str: any, { includesDay }: { includesDay: boolean } = { includesDay: true }) => {
  const format = i18n.language.includes('fr-CA')
    ? includesDay
      ? 'DD-MM-YYYY'
      : 'MM-YYYY'
    : includesDay
    ? 'YYYY-MM-DD'
    : 'YYYY-MM';
  return !isFieldEmptyOrNull(str) && str.length > 5 && moment(str).isValid() ? moment(str).format(format) : str;
};

export const formatMoneyPerLocale = (num: number, { decimal }: { decimal: number } = { decimal: 2 }) =>
  Number(num).toLocaleString(i18n.language, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: num < 1 && num > -1 ? 4 : decimal,
  });

export const formatCSVDisclaimer = (
  isLiveMarket: boolean,
  exchangeRate: IAccount['exchangeRate'],
  culture: string,
  lastUpdate?: Date,
  lastUpdatePortal?: Date,
) => {
  let exchangeRateDate = moment(lastUpdate && isLiveMarket ? lastUpdate : exchangeRate.loadStamp)
    .locale(i18n.language)
    .format('LL');
  if (isLiveMarket) {
    exchangeRateDate = moment(lastUpdatePortal).locale(i18n.language).format('LL');
  }
  let time = '';
  if (lastUpdatePortal && isLiveMarket) {
    time = ` ${moment(lastUpdatePortal.toString()).format('LTS').replace('PM', '').replace('AM', '')} ${moment
      .tz('America/Toronto')
      .format('z')}`;
  }
  return i18n.t('common.csvDisclaimer', {
    0: exchangeRateDate,
    1: time,
    2: exchangeRate.usdToCndRate.toLocaleString(culture, {
      style: 'decimal',
      currency: 'CAD',
      maximumFractionDigits: 6,
      minimumFractionDigits: 6,
    }),
  });
};

export const isConsentFeatureActiveForClient = (party: IPartyV1): boolean =>
  TOGGLE_CONSENT && party?.partyTypeCode === PartyTypeCode.Customer;

export const isKYCFeatureActiveForClient = (party: IPartyV1): boolean =>
  party &&
  party?.partyTypeCode === PartyTypeCode.Customer &&
  party?.accounts.some((account) => account.role === HouseholdType.OWNER && [11, 21, 22].includes(account.noRole));

export const getEDeliveryTypesAvailable = (): string => {
  // as default is no types, we are assuming all types are available
  if (!TOGGLE_HIDE_QIR && TOGGLE_TRADECONFIRMATIONS) return '';
  if (TOGGLE_HIDE_QIR) {
    if (!TOGGLE_TRADECONFIRMATIONS) return `${DocumentType.Statement},${DocumentType.TaxDocument}`;
    return `${DocumentType.Statement},${DocumentType.TaxDocument},${DocumentType.TradeConfirmation}`;
  }
  return `${DocumentType.Statement},${DocumentType.TaxDocument},${DocumentType.ManagedStatement}`;
};
