import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import i18n from '~/app/i18n';
import moment from 'moment';
import { IaLocales } from '~/common/locales/ia-locales';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { getIsAccountsFetching, getAllAvailableAccounts } from '~/stores/account/selectors/accountSelectors';
import { IAccount } from '~/stores/account/accountTypes';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';

const ExchangeRateDisclaimerContainer = styled.div`
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline;
`;
const ExchangeRateDisclaimerInside = styled.div`
  display: inline;
  & > strong {
    & > div {
      display: inline-block;
    }
  }
`;

export interface IExchangeRateDisclaimerProps {
  forceOff?: boolean;
  accounts: IAccount[];
  isAccountsFetching: boolean;
  culture: string;
  state?: IAppRootState;
  didSetMarketDataRealtime: boolean;
  prevBusinessDayLastUpdate?: Date;
}

export class ExchangeRateDisclaimer extends React.PureComponent<IExchangeRateDisclaimerProps> {
  constructor(props: IExchangeRateDisclaimerProps) {
    super(props);
  }

  public render() {
    const { accounts, isAccountsFetching, culture, didSetMarketDataRealtime, prevBusinessDayLastUpdate, forceOff } =
      this.props;

    if (isAccountsFetching || !accounts || accounts.length === 0) {
      return false;
    }

    IaLocales.createMomentLocales();
    moment.locale(`${i18n.language}`);

    const exchangeRate = forceOff ? accounts[0].prevBusinessDayExchangeRate : accounts[0].exchangeRate;
    const exchangeDateAndUnit = `${moment(forceOff ? prevBusinessDayLastUpdate : exchangeRate.loadStamp)
      .locale(i18n.language)
      .format('LL')} (${i18n.t('common.exchangeRateUsdUnit')}`;
    const disclaimerFormattedText =
      didSetMarketDataRealtime && !forceOff
        ? i18n.t('common.exchangeRateDisclaimerMarketDataON', {
            0: `<strong> ${moment(prevBusinessDayLastUpdate).locale(i18n.language).format('LL')}</strong>`,
          })
        : i18n.t('common.exchangeRateDisclaimerMarketDataOFF');
    return (
      <ExchangeRateDisclaimerContainer>
        <ExchangeRateDisclaimerInside dangerouslySetInnerHTML={{ __html: disclaimerFormattedText }} />
        <ExchangeRateDisclaimerInside>
          &nbsp;
          <strong>
            {exchangeDateAndUnit}{' '}
            <DecimalValue
              value={exchangeRate.usdToCndRate}
              culture={culture}
              amountOfDigits={6}
              zeroPlaceholder="0"
              areTrailingZerosVisible
            />{' '}
            CAD
          </strong>
          )
        </ExchangeRateDisclaimerInside>
      </ExchangeRateDisclaimerContainer>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isAccountsFetching: getIsAccountsFetching(state),
    accounts: getAllAvailableAccounts(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    culture: system.culture,
    prevBusinessDayLastUpdate: state.account.prevBusinessDayLastUpdate,
    state,
  };
}

export default connect<
  IExchangeRateDisclaimerProps,
  any,
  {
    forceOff?: boolean;
  },
  IAppRootState
>(mapStateToProps)(ExchangeRateDisclaimer);
