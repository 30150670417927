import { createTheme, alpha } from '@mui/material/styles';
import Colors from './colors';

export const appTheme = createTheme({
  palette: {
    primary: {
      main: Colors.quillGray,
      contrastText: Colors.codGray,
    },
    secondary: {
      light: alpha(Colors.quillGray, 0.4980392156862745),
      main: Colors.cobalt, // Buttons are using this color
      dark: Colors.mariner,
      contrastText: Colors.white,
    },
    text: {
      primary: Colors.codGray,
      secondary: Colors.mariner,
      disabled: Colors.desertStorm,
    },
    background: {
      default: Colors.desertStorm,
      paper: Colors.white,
    },
    divider: Colors.white,
  },
  typography: {
    fontFamily: ['OpenSans-Regular', 'Open Sans', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 35,
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 400,
      fontStyle: 'normal',
      color: Colors.codGray,
    },
    h2: {
      fontSize: 24,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: Colors.codGray,
    },
    h3: {
      fontSize: '21px !important',
      fontFamily: [['Montserrat Bold', 'Montserrat Regular', 'Montserrat', 'sans-serif'].join(','), '!important'].join(' '),
      fontWeight: 700,
      fontStyle: 'normal !important',
      color: Colors.codGray,
    },
    h4: { // corresponds to Labelbold in current
      fontSize: 18,
      fontFamily: ['OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '),
      fontWeight: 650,
      color: Colors.codGray,
    },
    h5: {
      fontSize: 16,
      fontFamily: ['OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '),
      fontWeight: 600,
      color: Colors.codGray,
    },
    h6: {
      fontSize: '14px !important',
      fontFamily: [['OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '), '!important'].join(' '),
      fontWeight: 600,
      color: Colors.codGray,
    },
    button: {
      fontFamily: ['Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(','),
      fontWeight: 600,
      fontStyle: 'normal',
      color: Colors.white,
      textTransform: 'none',
      fontSize: '14px !important',
    },
    body1: {
      fontFamily: [['OpenSans-Regular', 'Open Sans', 'sans-serif'].join(','), '!important'].join(' '),
      fontSize: '14px !important',
      fontWeight: 400,
      fontStyle: 'normal !important',
      color: Colors.codGray,
    },
    body2: {
      fontFamily: [['Open Sans Bold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(','), '!important'].join(' '),
      fontSize: '14px !important',
      fontWeight: 700,
      fontStyle: 'normal !important',
      color: Colors.codGray,
    },
    subtitle1: { // This one to review after -  replacing HeaderSmall.
      fontFamily: [['OpenSans-Regular', 'Open Sans', 'sans-serif'].join(','), '!important'].join(' '),
      fontSize: '11px !important',
      fontWeight: 400,
      fontStyle: 'normal !important',
      color: Colors.codGray,
    },
    caption: { // for navigation main links
      fontFamily: [['Open Sans Bold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(','), '!important'].join(' '),
      fontWeight: 700,
      fontStyle: 'normal !important',
      fontSize: '13px !important',
      color: [Colors.cobalt, '!important'].join(' '),
    },
    subtitle2: { // for navigation no main links
      fontFamily: [['Open Sans', 'sans-serif'].join(','), '!important'].join(' '),
      fontWeight: 400,
      fontStyle: 'normal !important',
      fontSize: '13px !important',
      color: [Colors.cobalt, '!important'].join(' '),
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MenuListDocumentsItem': {
            padding: '11px 60px 11px 20px',
            color: '#000000',
            '&:hover': {
              backgroundColor: Colors.babyBlue,
              textDecoration: 'none',
              color: Colors.mariner,
            },
            '&:active': {
              backgroundColor: Colors.darkBabyBlue,
              textDecoration: 'none',
              color: Colors.mariner,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MenuListDocuments': {
            padding: '0px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 22,
              width: 10,
              height: 10,
              backgroundColor: Colors.white,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          minWidth: '560px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlinedSecondary': {
            color: Colors.mariner,
            border: ['1px solid', Colors.mariner].join(' '),
            backgroundColor: Colors.white,
            '&.Mui-disabled': {
              color: Colors.quillGray,
            },
            '&:hover': {
              backgroundColor: Colors.white,
              boxShadow: ['0 0 0 1px', Colors.mariner, 'inset'].join(' '),
            },
          },
          '&.MuiButton-textSecondary:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: Colors.mariner,
            color: [Colors.white, '!important'].join(' '),
            '& .MuiListItemText-root .MuiTypography-subtitle2': {
              color: [Colors.white, '!important'].join(' '),
            },
            '& .MuiListItemText-root .MuiTypography-caption': {
              color: [Colors.white, '!important'].join(' '),
            },
          },
          '&.Mui-selected:hover': {
            backgroundColor: Colors.mariner,
            color: Colors.white,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.anotherGrayShade,
          '&.Mui-checked': {
            color: Colors.cobalt,
            '&.Mui-disabled': {
              color: Colors.anotherGrayShade2,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white,
          '& .MuiOutlinedInput-input': {
            borderColor: [Colors.white, '!important'].join(' '),
            '&:focus': {
              backgroundColor: ['transparent', '!important'].join(' '),
              boxShadow: ['none', '!important'].join(' '),
            },
          },
        },
      },
    },
  },
});
