import { TableContainer, TableBody, TableRow, TableCell, TablePagination, Table } from '@mui/material';
import i18n from '~/app/i18n';
import { formatDatePerLocale } from '~/common/helpers';
// import moment from 'moment';
import React, { FC, useState } from 'react';
import GenericDataTableHeader from './GenericDataTable.Header';
import { useStyles } from './styles';
import { IGenericDataTableProps, Order } from './types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // if(moment(a[orderBy]).isValid() && moment(b[orderBy]).isValid()){
  //   return moment(a[orderBy]).isAfter(b[orderBy]) ? -1 : 1
  // }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const GenericDataTable: FC<IGenericDataTableProps> = (
  {
    densed,
    pagination,
    headers,
    rows,
    defaultSortColumnId,
    defaultSortDirection,
  },
) => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>(defaultSortDirection || 'asc');
  const [orderBy, setOrderBy] = useState<string>(defaultSortColumnId || headers[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pagination ? pagination.rowsPerPage : rows.length);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const HandleLabelDisplayedRows = ({ from, to, count }: { from: number, to: number, count: number }) => <>{`${from} - ${to} ${i18n.t('common.of')} ${count} `}</>;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={(classes as any).root}>
      <TableContainer>
        <Table
          className={(classes as any).table}
          size={densed ? 'small' : 'medium'}
        >
          <GenericDataTableHeader
            headCells={headers}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort as any}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  {
                    headers.map((header, ind) => <TableCell key={ind} align="center">{header.dataType && header.dataType === 'date' ? formatDatePerLocale(row[header.id]) : row[header.id]}</TableCell>)

                  }
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: (densed ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        pagination &&

        <TablePagination
          SelectProps={{
            disabled: false,
          }}

          labelRowsPerPage={i18n.t('common.rowsPerPage') as string}
          labelDisplayedRows={HandleLabelDisplayedRows}
          onPageChange={handleChangePage}
          nextIconButtonProps={{ disableFocusRipple: false, color: 'primary', size: 'small', title: '' }}
          backIconButtonProps={{ disableFocusRipple: false, color: 'primary', size: 'small', title: '' }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, { label: i18n.t('common.filter_Choice_All'), value: rows.length }]}
          component="div"
          count={rows.length}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </div>
  );
};
