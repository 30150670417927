export const REACT_APP_USE_API_GATEWAY: boolean = process.env.REACT_APP_USE_API_GATEWAY
  ? process.env.REACT_APP_USE_API_GATEWAY === 'true'
  : false;
export const API_GATEWAY_AUTHORITY: string = process.env.REACT_APP_AUTHORITY ? process.env.REACT_APP_AUTHORITY : '';
export const API_GATEWAY_AUTHORITY_FR_NEW: string = process.env.REACT_APP_AUTHORITY_FR_NEW
  ? process.env.REACT_APP_AUTHORITY_FR_NEW
  : '';
export const API_GATEWAY_AUTHORITY_EN_NEW: string = process.env.REACT_APP_AUTHORITY_EN_NEW
  ? process.env.REACT_APP_AUTHORITY_EN_NEW
  : '';
export const API_GATEWAY_REDIRECT_URI: string = process.env.REACT_APP_API_GATEWAY_REDIRECT_URI
  ? process.env.REACT_APP_API_GATEWAY_REDIRECT_URI
  : '';
export const API_GATEWAY_CLIENTID_EN_NEW: string = process.env.REACT_APP_API_GATEWAY_CLIENTID_EN_NEW
  ? process.env.REACT_APP_API_GATEWAY_CLIENTID_EN_NEW
  : '';
export const API_GATEWAY_CLIENTID_FR_NEW: string = process.env.REACT_APP_API_GATEWAY_CLIENTID_FR_NEW
  ? process.env.REACT_APP_API_GATEWAY_CLIENTID_FR_NEW
  : '';
export const API_GATEWAY_ADVISOR_CLIENTID_EN: string = process.env.REACT_APP_API_GATEWAY_ADVISOR_CLIENTID_EN
  ? process.env.REACT_APP_API_GATEWAY_ADVISOR_CLIENTID_EN
  : '';
export const API_GATEWAY_ADVISOR_CLIENTID_FR: string = process.env.REACT_APP_API_GATEWAY_ADVISOR_CLIENTID_FR
  ? process.env.REACT_APP_API_GATEWAY_ADVISOR_CLIENTID_FR
  : '';
export const API_EVENTS = process.env.REACT_APP_API_EVENTS;
export const API_PARTIES = process.env.REACT_APP_API_PARTIES;
export const API_PARTIES_V2 = process.env.REACT_APP_API_PARTIES_V2;
export const API_ACCOUNTS = process.env.REACT_APP_API_ACCOUNTS;
export const API_ACCOUNTS_V2 = process.env.REACT_APP_API_ACCOUNTS_V2;
export const API_ADVISORS = process.env.REACT_APP_API_ADVISORS;
export const API_IASECURITIES = process.env.REACT_APP_API_IASECURITIES;
export const API_CLAIMSMANAGER = process.env.REACT_APP_API_CLAIMSMANAGER;
export const API_MESSAGING = process.env.REACT_APP_API_MESSAGING;
export const API_MARKETDATA = process.env.REACT_APP_API_MARKETDATA;
export const API_DOCS = process.env.REACT_APP_API_DOC;
export const API_CONSENT = process.env.REACT_APP_API_CONSENT;
export const API_KYC = process.env.REACT_APP_API_KYC;
export const API_CONTEST = process.env.REACT_APP_API_CONTEST;
export const CONTEST_PROMOTION_CODE = process.env.REACT_APP_CONTEST_PROMOTION_CODE;
export const { REACT_APP_MOCK_TOKEN } = process.env;
export const TOGGLE_TRADECONFIRMATIONS: boolean = process.env.REACT_APP_TOGGLE_TRADECONFIRMATIONS
  ? process.env.REACT_APP_TOGGLE_TRADECONFIRMATIONS === 'true'
  : false;
export const TOGGLE_CONSENT: boolean = process.env.REACT_APP_TOGGLE_CONSENT
  ? process.env.REACT_APP_TOGGLE_CONSENT === 'true'
  : false;
export const TOGGLE_CONTEST_BANNER: boolean = process.env.REACT_APP_TOGGLE_CONTEST_BANNER
  ? process.env.REACT_APP_TOGGLE_CONTEST_BANNER === 'true'
  : false;
export const TOGGLE_KYC: boolean = process.env.REACT_APP_TOGGLE_KYC
  ? process.env.REACT_APP_TOGGLE_KYC === 'true'
  : false;
export const TOGGLE_MARKETDATA_FEATURE_PAGE: boolean = process.env.REACT_APP_TOGGLE_MARKETDATA_FEATURE_PAGE
  ? process.env.REACT_APP_TOGGLE_MARKETDATA_FEATURE_PAGE === 'true'
  : false;
export const TOGGLE_HIDE_QIR: boolean = process.env.REACT_APP_TOGGLE_HIDE_QIR
  ? process.env.REACT_APP_TOGGLE_HIDE_QIR === 'true'
  : false;
export const TOGGLE_EDOC: boolean = process.env.REACT_APP_TOGGLE_EDOC
  ? process.env.REACT_APP_TOGGLE_EDOC === 'true'
  : false;
export const REACT_APP_USE_MESSAGING: boolean =
  window.location.href.indexOf('messaging-on') > 0 ||
  (process.env.REACT_APP_USE_MESSAGING ? process.env.REACT_APP_USE_MESSAGING === 'true' : false);
export const TOGGLE_MARKETDATA: boolean = process.env.REACT_APP_TOGGLE_MARKETDATA
  ? process.env.REACT_APP_TOGGLE_MARKETDATA === 'true'
  : false;
export const MARKETDATA_HIDE_SYMBOL_LINKS: boolean = process.env.REACT_APP_TOGGLE_MARKETDATA
  ? process.env.REACT_APP_MARKETDATA_HIDE_SYMBOL_LINKS === 'true'
  : false;
export const MARKETDATA_HIDE_SYMBOL_SEARCH: boolean = process.env.REACT_APP_TOGGLE_MARKETDATA
  ? process.env.REACT_APP_MARKETDATA_HIDE_SYMBOL_SEARCH === 'true'
  : false;
export const MARKETDATA_USE_SECTOR_HISTORY: boolean = process.env.REACT_APP_MARKETDATA_USE_SECTOR_HISTORY
  ? process.env.REACT_APP_MARKETDATA_USE_SECTOR_HISTORY === 'true'
  : false;
export const MARKETDATA_HIDE_PEERS: boolean = process.env.REACT_APP_TOGGLE_HIDE_PEERS
  ? process.env.REACT_APP_TOGGLE_HIDE_PEERS === 'true'
  : false;
export const TOGGLE_HIDE_MFA_BANNER: boolean = process.env.REACT_APP_TOGGLE_HIDE_MFA_BANNER
  ? process.env.REACT_APP_TOGGLE_HIDE_MFA_BANNER === 'true'
  : false;
export const TOGGLE_HIDE_BROWSER_BANNER: boolean = process.env.REACT_APP_TOGGLE_HIDE_BROWSER_BANNER
  ? process.env.REACT_APP_TOGGLE_HIDE_BROWSER_BANNER === 'true'
  : false;
export const TOGGLE_DOCUMENTS_BANNER: boolean = process.env.REACT_APP_TOGGLE_HIDE_DOCUMENT_BANNER
  ? process.env.REACT_APP_TOGGLE_HIDE_DOCUMENT_BANNER === 'true'
  : false;
export const TOGGLE_EDOCS_BANNER: boolean = process.env.REACT_APP_TOGGLE_HIDE_EDOCS_BANNER
  ? process.env.REACT_APP_TOGGLE_HIDE_EDOCS_BANNER === 'true'
  : false;
export const TOGGLE_HIDE_MARKETDATA_BANNER: boolean = process.env.REACT_APP_TOGGLE_HIDE_MARKETDATA_BANNER
  ? process.env.REACT_APP_TOGGLE_HIDE_MARKETDATA_BANNER === 'true'
  : false;
export const TOGGLE_DOCUMENTS_TRADECONF_ERROR: boolean = process.env.REACT_APP_TOGGLE_HIDE_DOCUMENTS_TRADECONF_ERROR
  ? process.env.REACT_APP_TOGGLE_HIDE_DOCUMENTS_TRADECONF_ERROR === 'true'
  : false;
export const CLIENT_PORTAL_SOURCE_KEY = process.env.REACT_APP_CLIENT_PORTAL_SOURCE_KEY;
export const API_MARKET_DATA_PROVIDER = process.env.REACT_APP_API_MARKET_DATA_PROVIDER;
export const TOGGLE_TAX_DOCUMENTS_BANNER: boolean = process.env.REACT_APP_TOGGLE_TAX_DOCUMENTS_BANNER
  ? process.env.REACT_APP_TOGGLE_TAX_DOCUMENTS_BANNER === 'true'
  : false;

export const IMPERSONATE = process.env.REACT_APP_IMPERSONATE ? process.env.REACT_APP_IMPERSONATE === 'true' : false;
export const IMPERSONATE_ACCOUNTS = process.env.REACT_APP_IMPERSONATE_ACCOUNTS;
export const IMPERSONATE_ACCOUNTS_OWNED = process.env.REACT_APP_IMPERSONATE_ACCOUNTS_OWNED;
export const IMPERSONATE_ACCOUNTS_SHARED = process.env.REACT_APP_IMPERSONATE_ACCOUNTS_SHARED;
export const IMPERSONATE_REP_CODES = process.env.REACT_APP_IMPERSONATE_REP_CODES;
export const IMPERSONATE_PARTY_ID = process.env.REACT_APP_IMPERSONATE_PARTY_ID;
export const IMPERSONATE_REP_CODES_SHARED_ACCOUNTS = process.env.REACT_APP_IMPERSONATE_REP_CODES_SHARED_ACCOUNTS;
export const IMPERSONATE_REP_CODES_OWNED_ACCOUNTS = process.env.REACT_APP_IMPERSONATE_REP_CODES_OWNED_ACCOUNTS;
