import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import i18n from '~/app/i18n';
import moment from 'moment';
import { getIsAccountsFetching, getAllAvailableAccounts } from '~/stores/account/selectors/accountSelectors';
import { IAccount } from '~/stores/account/accountTypes';
import { Tooltip, Typography } from '@mui/material/';
import Info from '@mui/icons-material/Info';
import { Trans } from 'react-i18next';
import { IconButtonWithLeftPadding } from '~/common/styles/baseStyle';
import { IaLocales } from '~/common/locales/ia-locales';

const LoadStampContainer = styled.div<{ color?: string; font?: string }>`
  font-family: ${(props) => (props.font ? props.font : 'Montserrat')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : '#003DA5')};
`;

const ToolTipContainer = styled.div<{ color?: string; font?: string }>`
  font-size: 12px;
`;

export interface IPropsFromState {
  accounts: IAccount[];
  isAccountsFetching: boolean;
  culture: string;
  state?: IAppRootState;
  lastUpdate?: Date;
  lastUpdatePortal?: Date;
  prevBusinessDayLastUpdate?: Date;
  marketDataTimestamp?: Date;
}

export interface IProps {
  isLiveMarket?: boolean;
  isPreviousDay?: boolean;
  color?: string;
  font?: string;
  isPortalTimestamp?: boolean;
  isDocumentPage?: boolean;
  isLiveData?: boolean;
  isMarketDataTimestamp?: boolean;
  isMarketScreen?: boolean;
}

export class DataLoadStamp extends React.PureComponent<IProps & IPropsFromState> {
  public render() {
    const {
      isAccountsFetching,
      accounts,
      lastUpdate,
      isDocumentPage,
      lastUpdatePortal,
      isLiveMarket,
      isPreviousDay,
      prevBusinessDayLastUpdate,
      isPortalTimestamp,
      isLiveData,
      isMarketDataTimestamp,
      marketDataTimestamp,
      isMarketScreen,
    } = this.props;

    if (isAccountsFetching || !accounts || accounts.length === 0) {
      return false;
    }
    IaLocales.createMomentLocales();

    const { exchangeRate } = accounts[0];

    let exchangeRateDate = moment(lastUpdate && isLiveMarket ? lastUpdate : exchangeRate.loadStamp)
      .locale(i18n.language)
      .format('ll');

    if (isLiveMarket && isPortalTimestamp && isLiveData) {
      exchangeRateDate = moment(lastUpdatePortal).locale(i18n.language).format('ll');
    } else if (isPreviousDay) {
      exchangeRateDate = moment(prevBusinessDayLastUpdate).locale(i18n.language).format('ll');
    } else if (isMarketDataTimestamp) {
      exchangeRateDate = moment(marketDataTimestamp).locale(`${i18n.language}`).format('ll');
    }

    let time = '';
    if (lastUpdate && isLiveMarket && !isPortalTimestamp && isLiveData && !isMarketDataTimestamp) {
      time = ` ${i18n.t('common.at')} ${moment(lastUpdate).locale(`${i18n.language}`).format('LTS')} ${moment
        .tz('America/Toronto')
        .format('z')}`;
    } else if (lastUpdatePortal && isLiveMarket && isPortalTimestamp && isLiveData) {
      time = ` ${i18n.t('common.at')} ${moment(lastUpdatePortal.toString())
        .locale(`${i18n.language}`)
        .format('LTS')} ${moment.tz('America/Toronto').format('z')}`;
    } else if (isMarketDataTimestamp && marketDataTimestamp) {
      time = ` ${i18n.t('common.at')} ${moment(marketDataTimestamp.toString())
        .locale(`${i18n.language}`)
        .format('LTS')} ${moment.tz('America/Toronto').format('z')}`;
    }

    let textKey = '';
    if (isLiveMarket) {
      textKey = 'holdings.marketDataDisclaimer';
    } else if (isMarketScreen) {
      textKey = 'marketsPage.toolTip';
    } else if (isDocumentPage) {
      textKey = 'document.datePublishedDesc';
    }

    return (
      <LoadStampContainer color={this.props.color} font={this.props.font}>
        {this.props.font ? (
          `${i18n.t('common.exchangeDateLabel')} ${exchangeRateDate}${time}`
        ) : (
          <Typography variant="body1" component="span">
            {' '}
            {`${i18n.t('common.exchangeDateLabel')} ${exchangeRateDate}${time}`}
          </Typography>
        )}

        {(isLiveMarket || isMarketScreen || isDocumentPage) && (
          <Tooltip
            title={
              <ToolTipContainer>
                <Trans i18nKey={textKey} />
              </ToolTipContainer>
            }
            placement="top"
            arrow
          >
            <IconButtonWithLeftPadding>
              <Info fontSize="small" style={{ color: '#2062D4' }} />
            </IconButtonWithLeftPadding>
          </Tooltip>
        )}
      </LoadStampContainer>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    isAccountsFetching: getIsAccountsFetching(state),
    accounts: getAllAvailableAccounts(state),
    culture: system.culture,
    state,
    lastUpdate: state.account.lastUpdate,
    lastUpdatePortal: state.account.lastUpdatePortal,
    prevBusinessDayLastUpdate: state.account.prevBusinessDayLastUpdate,
    marketDataTimestamp: state.marketData.lastUpdateRequest,
  };
}

export default connect<IPropsFromState, null, IProps, IAppRootState>(mapStateToProps)(DataLoadStamp);
