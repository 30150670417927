import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Trans } from 'react-i18next';
import SaveDocumentDeliveryChanges from '~/pages/householding-page/documentdelivery-view/SaveChanges';
import { TOGGLE_HIDE_QIR, TOGGLE_TRADECONFIRMATIONS } from '~/common/API';
import i18n from '~/app/i18n';
import { Button } from '@mui/material';
import {
  IAButtonStyle, ChangeAllTitle, ChangeAllTableContent, HorizontalLine, CheckCircleIconStyle, ConfirmationP, ConfirmationTitle, ConfirmContainer, ConfirmationMessage, ConfirmationButton,
} from './DocumentDeliveryPrompt.style';
import { Cancel } from '@mui/icons-material';
import { CancelIconStyle } from '~/common/components/Modal/ModalSaveResult.style';

export const RenderFirstStep = (nextStep: () => void) => (
  <>
    <Grid fluid style={{ width: '100%', marginRight: '16px', paddingLeft: '0px', paddingBottom: '10px' }}>
      <Row>
        <Col xs={4}>
          <ChangeAllTitle>
            <Trans i18nKey="documentDelivery.prompt.documentsDeliveryPreferences" />
          </ChangeAllTitle>
        </Col>
        <Col xs={8}>
          <ChangeAllTitle>
            <Trans i18nKey="documentDelivery.prompt.changeTo" />
          </ChangeAllTitle>
        </Col>
      </Row>
      {RenderLine(i18n.t('documentDelivery.Statement'))}
      {TOGGLE_TRADECONFIRMATIONS && RenderLine(i18n.t('documentDelivery.TradeConfirmation'))}
      {RenderLine(i18n.t('documentDelivery.TaxDocument'))}
      {!TOGGLE_HIDE_QIR && RenderLine(i18n.t('documentDelivery.ManagedStatement'))}
    </Grid>
    <Button onClick={nextStep} variant="contained" color="secondary"><Trans i18nKey="documentDelivery.prompt.continue" /></Button>
  </>);

const RenderLine = (label: string) => (
  <>
    <Row>
      <Col xs={12}>
        <HorizontalLine />
      </Col>
    </Row>
    <Row>
      <Col xs={4}>
        <ChangeAllTableContent>
          {label}
        </ChangeAllTableContent>
      </Col>
      <Col xs={8}>
        <ChangeAllTableContent>
          <Trans i18nKey="householding.electronic" />
        </ChangeAllTableContent>
      </Col>
    </Row>
  </>
);

interface ISecondStepProps {
  handleLegalConsentChange: (event: CheckboxChangeEvent) => void,
  confirm: () => void,
  canGoToNextStep: boolean,
  hasAcceptedLegalConsent: boolean
}

export const RenderSecondStep = (props: ISecondStepProps) => (
  <>
    <SaveDocumentDeliveryChanges
      handleLegalConsentChange={props.handleLegalConsentChange}
      hasAcceptedLegalConsent={props.hasAcceptedLegalConsent}
    />
    <Button style={IAButtonStyle} disabled={!props.canGoToNextStep} onClick={props.confirm} variant="contained" color="secondary"><Trans i18nKey="documentDelivery.prompt.confirmModifications" /></Button>
  </>
);

export const RenderThirdStep = (close: () => void, savingFailed: boolean) => (
  <ConfirmContainer>
    {!savingFailed &&
      <>
        <ConfirmationP>
          <CheckCircleIcon style={CheckCircleIconStyle} />
        </ConfirmationP>
        <ConfirmationTitle>
          <Trans i18nKey="common.confirmationMessageTitle" />
        </ConfirmationTitle>
        <ConfirmationMessage>
          <Trans i18nKey="householding.savePreferencesConfirmation" />
        </ConfirmationMessage>
      </>}
    { savingFailed &&
      <>
        <ConfirmationP>
          <Cancel style={CancelIconStyle} />
        </ConfirmationP>
        <ConfirmationMessage>
          <Trans i18nKey="householding.savePreferencesFailure" />
        </ConfirmationMessage>
      </>}
    <ConfirmationButton onClick={close}>
      <Trans i18nKey="documentDelivery.prompt.goToOverview" />
    </ConfirmationButton>
  </ConfirmContainer>
);
