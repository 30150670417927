import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { ITeamUI } from '~/common/interfaces/advisor/Team';
import * as React from 'react';

import AdvisorCard from './AdvisorCard';
import BranchCard from './branchCard';
import NationalBranchCard from './nationalBranchCard';
import { AdvisorsSection, Container, InformationsContainer, Separator } from './styles/SearchResult.style';

export interface ISearchResultProps {
  advisors: IAdvisor[];
  teams :ITeamUI;
}

const advisorsCard = (advisors: IAdvisor[]): JSX.Element[] => {
  const displayNationalBranchCard = advisors.some((advisor: IAdvisor) => advisor.isNationalBranch);
  const advisorsToDisplay = advisors.filter((advisor: IAdvisor) => !advisor.isNationalBranch);

  const cards = advisorsToDisplay.map((advisor: IAdvisor) => <AdvisorCard key={advisor.id} advisor={advisor} />);

  if (displayNationalBranchCard) {
    const nationalBranch = advisors.filter((advisor: IAdvisor) => advisor.isNationalBranch)[0];
    cards.unshift(<NationalBranchCard key={0} nationalBranch={nationalBranch} />);
  }

  return cards;
};

const renderBranch = (branch: IAdvisor, associate: IAdvisor[]) => (
  <React.Fragment key={branch.id}>
    <div>
      <Separator />
      <BranchCard branch={branch} team={associate} />
    </div>
  </React.Fragment>
);

const SearchResult: React.FC<ISearchResultProps> = ({ advisors, teams }: ISearchResultProps) => (
  <Container>
    { (advisors.length > 0 || teams) &&
    <InformationsContainer>
      <AdvisorsSection>{advisorsCard(advisors)}</AdvisorsSection>
      {teams && teams.Branchs.map((m) => renderBranch(m.Branches, m.Assistants))}
    </InformationsContainer>}
  </Container>
);

export default SearchResult;
