import axios from 'axios';

import { API_KYC } from '~/common/API';
import {
  KYCDetailsFormSchemaType,
  KYCDetailsResponseType,
  KYCGetStatusRequest,
  KycReviewIdsType,
  KYCDetailsSubmitSchemaType,
  KYCClientReferenceSchemaType,
  KYCDetailsSubmitSchema,
} from './kycTypes';
import { getCurrentIsoLang } from '~/common/helpers';

// function delay(seconds: number, value: string) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, seconds * 1000, value);
//   });
// }

export const KYCHttpClient = new (class {
  private mapAccountsToKYCClientRefBody = (
    request: KYCGetStatusRequest,
  ): { clientReferences: KYCClientReferenceSchemaType[] } => ({
    clientReferences: request.getKYCAccounts().map((client) => ({
      clientId: client.id,
      noRole: `${client.noRole}`,
      role: client.role,
    })),
  });

  private mapKYCFormToSavePayload = (formAnswers: KYCDetailsFormSchemaType): KYCDetailsSubmitSchemaType =>
    ({
      reviewIds: formAnswers.reviewIds,
      sections: formAnswers.sections.map((section) => ({
        attribute: section.attribute,
        subSections: section.subSections.map((subSection) => ({
          attribute: subSection.attribute,
          identifier: subSection.identifier,
          questionnaires: subSection.questionnaires.map((questionnaire) => ({
            questions: questionnaire.questions.map((question) => ({
              attribute: question.attribute,
              hasChanged: question.hasChanged === 'true',
              answer: question.answer,
            })),
          })),
        })),
      })),
    } as KYCDetailsSubmitSchemaType);

  public fetchKYCStatus = async (request: KYCGetStatusRequest): Promise<KYCDetailsResponseType> => {
    const response = await axios.post(`${API_KYC}/status`, this.mapAccountsToKYCClientRefBody(request));
    return response.data;
  };

  public fetchKYCDetails = async (reviewIds: KycReviewIdsType): Promise<KYCDetailsResponseType> => {
    const response = await axios.get(
      `${API_KYC}/questionnaire?${reviewIds.map((reviewId) => `kycReviewIds=${reviewId}`).join('&')}`,
      {
        headers: { 'Accept-Language': getCurrentIsoLang() },
      },
    );
    return response.data;
  };

  public saveKYCRemindLater = async (reviewIds: KycReviewIdsType): Promise<void> => {
    await axios.post(`${API_KYC}/status/postpone?${reviewIds.map((reviewId) => `kycReviewIds=${reviewId}`).join('&')}`);
  };

  public submitKYCDetails = async (KYCFormData: KYCDetailsFormSchemaType): Promise<void> => {
    const submitPayload = this.mapKYCFormToSavePayload(KYCFormData);
    const parsedSubmitPayload = KYCDetailsSubmitSchema.safeParse(submitPayload);
    if (!parsedSubmitPayload.success) {
      console.info('schema not valid when submitting, sending anyway.', parsedSubmitPayload.error);
    }
    await axios.post(`${API_KYC}/saveQuestionnaire`, submitPayload);
  };
})();
