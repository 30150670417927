import { Reducer } from 'redux';
import { IMarketProductDetailsState, MarketProductDetailsActionTypes } from '../marketDataTypes';

const initialState: IMarketProductDetailsState = {
  isFetchingProductDetailedInfoData: false,
  isFetchingProductDetailedInfoDataHasError: false,
  products: {},
};

const handleFetchProductDetailInfo = (state: IMarketProductDetailsState): IMarketProductDetailsState => ({
  ...state,
  isFetchingProductDetailedInfoData: true,
  isFetchingProductDetailedInfoDataHasError: false,
});

const handleFetchPproductDetailInfoSuccessful = (state: IMarketProductDetailsState, payload: any) => {
  const { productDetailInfo, symbol } = payload;
  return {
    ...state,
    isFetchingProductDetailedInfoData: false,
    isFetchingProductDetailedInfoDataHasError: false,
    products: {
      ...state.products,
      [symbol]: {
        ...state.products[symbol],
        ...productDetailInfo,
      },
    },
  };
};

const handleFetchProductDetailInfoFailure = (state: IMarketProductDetailsState, payload: any) => {
  const { productDetailInfo, symbol } = payload;
  return {
    ...state,
    isFetchingProductDetailedInfoData: false,
    isFetchingProductDetailedInfoDataHasError: true,
    products: {
      ...state.products,
      [symbol]: {
        ...state.products[symbol],
        ...productDetailInfo,
      },
    },
  };
};

export const marketProductDetailReducer: Reducer<IMarketProductDetailsState> = (state = initialState, action) => {
  switch (action.type) {
      case MarketProductDetailsActionTypes.FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA:
          return handleFetchProductDetailInfo(state);
      case MarketProductDetailsActionTypes.FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA_SUCCESSFUL:
          return handleFetchPproductDetailInfoSuccessful(state, action.payload);
      case MarketProductDetailsActionTypes.FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA_FAILED:
          return handleFetchProductDetailInfoFailure(state, action.payload);
      default:
          return state;
      }
  };
