import * as React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';

export interface IDataGridDisplay {
  key: string,
  value: any
}

export interface IProps {
  data: IDataGridDisplay[],
  title: string | null
}

export const SectionHeaderLabel = styled.div`
    color: #1B1B1B;
    font-size: 21px;
    font-weight: 700;
    padding-top:20px;
    font-family:'Montserrat Bold', 'Montserrat Regular', 'Montserrat', sans-serif;
    margin-bottom: 15px;
`;
SectionHeaderLabel.displayName = 'SectionHeaderLabel';

const StyledRow = withStyles(TableRow, {
  root: {
    borderTop: '1px solid #b3b3b3;',
    borderBottom: '1px solid #b3b3b3;',
  },
});

const FieldCell = withStyles(TableCell, {
  root: {
    backgroundColor: '#e6e6e6',
    fontFamily: `${['Open sans', 'san-serif'].join(',')}!important`,
    fontSize: '14px!important',
    color: '#1B1B1B!important',
    borderTop: '1px solid #b3b3b3;',
    borderBottom: '1px solid #b3b3b3;',
    padding: '12px',
    fontWeight: 'unset!important' as any,
    '& a': {
      fontWeight: 'unset!important' as any,
      fontFamily: 'unset!important' as any,
    },
  },
});

const ValueCell = withStyles(TableCell, {
  root: {
    fontFamily: `${['Open sans', 'san-serif'].join(',')}!important`,
    fontSize: '14px!important',
    color: '#1B1B1B!important',
    borderTop: '1px solid #b3b3b3;',
    borderBottom: '1px solid #b3b3b3;',
    padding: '12px',
    fontWeight: 'unset!important' as any,
    '& a': {
      fontWeight: 'unset!important' as any,
      fontFamily: 'unset!important' as any,
    },
  },
});

export const DataGrid = ({ data, title }: { data: IDataGridDisplay[], title: string }) => (
  <div>
    <SectionHeaderLabel>
      <Typography variant="h3" component="div">
        <Trans i18nKey={title} />
      </Typography>
    </SectionHeaderLabel>

    <Table aria-label="data-grid table">
      <TableBody>
        {data && data.map((row, index) => (
          <StyledRow key={index}>
            <FieldCell>
              <Trans i18nKey={row.key} />
            </FieldCell>
            <ValueCell align="right">
              {row.value}
            </ValueCell>
          </StyledRow>
        ))}
      </TableBody>
    </Table>
  </div>
);
export default DataGrid;
