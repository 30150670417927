import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { RegulatoryRoleHelper } from './RegulatoryRoleHelper';

export class AdvisorHelper {
  public static compareByLastName(a: IAdvisor, b: IAdvisor): number {
    if (a.lastName < b.lastName) { return -1; }
    if (a.lastName > b.lastName) { return 1; }
    return 0;
  }

  public static compareByRank(a: IAdvisor, b: IAdvisor): number {
    const compareRegulatory = RegulatoryRoleHelper.compare(a.regulatoryRole, b.regulatoryRole);
    if (compareRegulatory === 0) { return AdvisorHelper.compareByLastName(a, b); }
    return compareRegulatory;
  }

  public static filterAdvisorsWithPrimaryEmail(advisors: IAdvisor[]) {
    return advisors.filter((advisor) => advisor.emails.length > 0 &&
      advisor.emails.filter((email) => email.primary).length > 0);
  }
}
