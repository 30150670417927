export const emailNotificationDocumentAvailableKey = 'mailNotificationDocumentAvailable';
export const updateDeliveryPreferencesKey = 'updateDeliveryPreferences';
export const consentPromptKey = 'consentPrompt';
export const marketDataRealtimeKey = 'marketDataRealtime';
export const intradayChangePreferenceKey = 'displayIntradayChange';
export const holdingSortPreferencesKey = 'holdingSortPreferences';

export const thirdPartyConsentAvailableKey = 'thirdPartyConsent';
export const affiliateConsentAvailableKey = 'affiliateConsent';
export const distinctiveClientExpConsentAvailableKey = 'distinctiveClientExpConsent';
export type consentAvailableKeys =
  | typeof thirdPartyConsentAvailableKey
  | typeof affiliateConsentAvailableKey
  | typeof distinctiveClientExpConsentAvailableKey;

export const thirdPartyConsentVerbiageAvailableKey = 'thirdPartyConsentVerbiage';
export const affiliateConsentVerbiageAvailableKey = 'affiliateConsentVerbiage';
export const system = 'clientPortalIas';
export const autoHideDurationError = 2000;
export const exportCSVType = 'text/csv;charset=utf-8';
