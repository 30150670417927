import * as React from 'react';
import { IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import Grid from '@mui/material/Grid';
import { establishAccounts } from '~/common/accountsHelpers';
import memoizeOne from 'memoize-one';
import SummarySplitGrid from '~/common/components/account/account-view/SummarySplitGrid';
import { ComponentWithValidation } from '~/common/components/position-overview/summary-table/ComponentWithValidation';
import NumberQI from '~/common/components/changeValue/NumberQI';
import Colors from '~/common/themes/colors';
import RootAccounts from '../root-accounts/RootAccounts';
import { AccountViewTabEnum, ISortPreference } from '~/common/types';
import { buildDataArrayForBookCostPage } from '~/common/components/account/account-view/SummaryAccountViewHelper';
import { useState } from 'react';
import { HoldingViewKeys, SortColumnKeys } from '~/stores/party/partyTypes';
import { SortDirection } from '~/app/appTypes';
import { GetStorageValueByKey, SetStorageKeyValue } from '~/app/sessionManager';

export interface IBookCostViewProps {
  accounts: IAccount[];
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary;
  shouldNotShowMarketDataInfo: boolean;
  bookViewSortPreferences: ISortPreference;
}

const accountsMemoizeOne = memoizeOne(establishAccounts);

const BookCostView: React.FC<IBookCostViewProps> = ({
  accounts,
  clientAccountTopLevelInfo,
  shouldNotShowMarketDataInfo,
  bookViewSortPreferences,
}: IBookCostViewProps) => {
  const accountList = accountsMemoizeOne(accounts);
  const topLevelValuesToBeDisplayed = buildDataArrayForBookCostPage(
    clientAccountTopLevelInfo,
    shouldNotShowMarketDataInfo,
  );

  const bcViewInSessionValue = GetStorageValueByKey(HoldingViewKeys.BOOK_COST_VIEW);
  const bcViewInSession = bcViewInSessionValue !== undefined ? JSON.parse(bcViewInSessionValue) as ISortPreference : undefined;
  const mostUpdatedBCView = bcViewInSession ?? bookViewSortPreferences;
  const [sortingPreferencesByTab, setSortingPreferencesByTab] = useState<ISortPreference>(mostUpdatedBCView);

   function onChangedTableSort(sortColumn: SortColumnKeys, sortDirection: SortDirection): void {
    // save current sorting preferences to session
    const sortingPreferencesByTabTemp: ISortPreference = { sortColumn, sortDirection };
    SetStorageKeyValue(HoldingViewKeys.BOOK_COST_VIEW, JSON.stringify(sortingPreferencesByTabTemp));

     setSortingPreferencesByTab((prevState) => ({
       ...prevState,
       sortColumn,
       sortDirection,
     }));
   }

  return (
    <>
      <Grid container style={{ padding: '0 16px' }}>
        <SummarySplitGrid
          backgroundLeftColor={Colors.babyBlue}
          labelKeyLeft="common.totalMarketValue"
          valueLeft={
            <ComponentWithValidation
              value={clientAccountTopLevelInfo.totalMarketValue}
              componentWithValue={<NumberQI value={clientAccountTopLevelInfo.totalMarketValue} isMoney />}
            />
          }
          commentKeyLeft="common.valuesAreInCAD"
          colsOnRight={3}
          dataOnRightSide={topLevelValuesToBeDisplayed}
        />
      </Grid>
      {accountList.map((rootAccounts) => (
        <RootAccounts
          {...rootAccounts}
          key={rootAccounts.rootId}
          shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
          viewType={AccountViewTabEnum.BookCost}
          onChangedTableSort={onChangedTableSort}
          sortingPreferencesByTab={sortingPreferencesByTab}
        />
      ))}
    </>
  );
};

export default BookCostView;
