import * as React from 'react';
import styled from 'styled-components';
import { IAButtonSelect } from '~/common/styles/baseStyle';
import { Popper, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { Trans } from 'react-i18next';

export interface IOption {
  id: string,
  text: string | React.ReactNode,
  childOptions?: IOption[],
  disabled?: boolean
}

const ContainerFilter = styled.div`
    font-family: 'Open Sans Regular', 'Open Sans';
    box-shadow: 0px 5px 5px 2px rgba(102, 102, 102, 0.349019607843137);
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    padding: 4px;
    background-color: #fff;
    max-height: 350px;
    overflow: auto;
`;

export interface IMultiSelectProps {
  label: string,
  options: IOption[]
  selectedOptionIds: string[],
  onOptionSelected: (selectedOptionIds: string[]) => void,
  disabled?: boolean,
}

export interface IMultiSelectState {
  isOpen: boolean,
  anchorfilter: any,
  defaultAccountSelected: string[]
}

class MultiSelect extends React.PureComponent<IMultiSelectProps, IMultiSelectState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
      anchorfilter: null,
      defaultAccountSelected: ['all'],
    };
  }

  private handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState(
      { isOpen: !this.state.isOpen, anchorfilter: event.currentTarget },
    );
  };

  private handleClickAway = () => {
    this.setState({ isOpen: false });
  };

  private renderTreeNodes = (data: IOption[]) => {
    const result: DataNode[] = data.map((item) => {
      if (item.childOptions) {
        return ({
          expanded: true,
          title: item.text,
          key: item.id,
          dataRef: item,
          disableCheckbox: item.disabled,
          children: this.renderTreeNodes(item.childOptions),
        } as DataNode);
      }
      return ({ key: item.id, title: item.text, dataRef: item, disableCheckbox: item.disabled, ...item } as DataNode);
    });
    return result;
  };

  public componentDidUpdate(prevProps: IMultiSelectProps & IMultiSelectState) {
    if (prevProps.selectedOptionIds !== this.props.selectedOptionIds) {
      this.setState({ defaultAccountSelected: this.props.selectedOptionIds });
    }
  }

  public render() {
    const { label, options, selectedOptionIds, onOptionSelected } = this.props;
    const idPopper = this.state.isOpen ? 'selectFilterID' : undefined;
    return (
      <div>
        <IAButtonSelect disabled={this.props.disabled} aria-describedby={idPopper} onClick={this.handleFilterClick} variant="outlined" endIcon={<ExpandMoreIcon htmlColor="#003da5" />}>
          {label} ({(selectedOptionIds.length === 0 || selectedOptionIds.includes('all')) ? <Trans i18nKey="common.filter_Choice_All" /> : selectedOptionIds.length})
        </IAButtonSelect>
        {this.state.isOpen &&
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Popper id={idPopper} open={this.state.isOpen} anchorEl={this.state.anchorfilter} placement="bottom-end" disablePortal={false}>
              <ContainerFilter>
                <Tree
                  checkable
                  defaultExpandAll
                  checkedKeys={selectedOptionIds.length === 0 ? this.state.defaultAccountSelected : selectedOptionIds}
                  onCheck={onOptionSelected as any}
                  treeData={this.renderTreeNodes(options)}
                />
              </ContainerFilter>
            </Popper>
          </ClickAwayListener>}
      </div>
    );
  }
}

export default MultiSelect;
