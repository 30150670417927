import * as React from 'react';
import { FormattedNumber } from 'react-intl';

export interface IDecimalValueProps {
  value: number,
  culture: string,
  amountOfDigits?: number,
  zeroPlaceholder?: string,
  areTrailingZerosVisible?: boolean,
  nanPlaceholder?: string
}

const DecimalValue: React.FC<IDecimalValueProps> = ({ value, culture, amountOfDigits, zeroPlaceholder, areTrailingZerosVisible, nanPlaceholder }) => {
  if (value === -0) {
    value = 0;
  }

  let fractionDigits = !amountOfDigits && amountOfDigits !== 0 ? 2 : amountOfDigits;

  if (!areTrailingZerosVisible) {
    fractionDigits = (value % 1) === 0 ? fractionDigits = 0 : fractionDigits; // Check if value is a whole number.
  }

  if (zeroPlaceholder && value === 0) {
    return (
      <div className="decimal-value">
        {zeroPlaceholder}
      </div>
    );
  }

  if (nanPlaceholder && isNaN(value)) {
    return (
      <div className="decimal-value">
        {nanPlaceholder}
      </div>
    );
  }

  if (value < 0) {
    const val = Math.abs(value);
    const returnString = `(${val.toLocaleString(culture, {
      minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits,
    })})`;
    return (
      <div className="decimal-value">
        {returnString}
      </div>
    );
  }
  return (

    <div className="decimal-value">
      <FormattedNumber
        value={value}
        style="decimal"
        minimumFractionDigits={fractionDigits}
        maximumFractionDigits={fractionDigits}
      >
        {
          (formattedNumber: string) => adjustFormat(formattedNumber, culture)
        }
      </FormattedNumber>
    </div>
  );
};

function adjustFormat(formattedNumber: string, culture: string) {
  // fix for IE
  if (culture === 'fr-CA') {
    formattedNumber = formattedNumber.replace('.', ',');
  }

  return <>{formattedNumber}</>;
}

export default DecimalValue;
