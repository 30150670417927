import { Typography } from '@mui/material';
import i18n from '~/app/i18n';
import { IAddress } from '~/common/interfaces/Address';
import { IAdvisor } from '~/common/interfaces/advisor/Advisor';
import { IWebsite } from '~/common/interfaces/Website';
import { Address as AddressIcon, Laptop, Phone as PhoneIcon } from '~/common/svg';
import * as React from 'react';

import { formatWebSiteUrl } from '../../helpers';
import BranchPhoneCard from './branchPhoneCard';
import { WebsitesSection } from './styles/advisorCard.style';
import {
  BranchDiv,
  BranchInfo,
  BranchInfos,
  FlexDiv,
  TeamMembers,
  TeamSection,
} from './styles/SearchResult.style';
import TeamMemberCard from './teamMemberCard';

interface ILocalProps {
  branch: IAdvisor;
  team: IAdvisor[];
}

const teamMemberCards = (teamMembers: IAdvisor[]): JSX.Element[] => teamMembers.slice(0, 3).map((teamMember: IAdvisor) => <TeamMemberCard key={teamMember.id} teamMember={teamMember} />);

const BranchCard: React.FC<ILocalProps> = ({ branch, team }: ILocalProps) => {
  if (branch === undefined || team === undefined) {
    return <></>;
  }
  const branchAddress: IAddress | null = branch.addresses.length > 0 ? branch.addresses[0] : null;
  const branchWebsite: IWebsite | null = branch.websites.length > 0 ? branch.websites[0] : null;
  return (
    <BranchDiv>
      <BranchInfos>
        {branchAddress !== null && (
          <FlexDiv>
            {AddressIcon(40)}
            <BranchInfo>
              <Typography variant="h5" component="div">  {i18n.t('contactCard.branch.address').toUpperCase()}</Typography>
              <div>{branchAddress.streetName}</div>
              <div>{branchAddress.additionalInfo}</div>
              <div>
                {branchAddress.municipality} {branchAddress.regionCode}
              </div>
              {branchAddress.postalCode}
            </BranchInfo>
          </FlexDiv>
        )}
        {branch.phones.length > 0 && (
          <FlexDiv>
            {PhoneIcon(40)}
            <BranchInfo>
              <Typography variant="h5" component="div">{i18n.t('contactCard.branch.phone').toUpperCase()}</Typography>
              <BranchPhoneCard phones={branch.phones} />
            </BranchInfo>
          </FlexDiv>
        )}
        {branchWebsite !== null && (
          <FlexDiv>
            {Laptop(40)}
            <BranchInfo>
              <Typography variant="h5" component="div">{i18n.t('contactCard.branch.website').toUpperCase()}</Typography>
              <WebsitesSection>
                <a href={formatWebSiteUrl(branchWebsite.url)} target="_blank">
                  {branchWebsite.url}
                </a>
              </WebsitesSection>
            </BranchInfo>
          </FlexDiv>
        )}
      </BranchInfos>
      {team.length !== 0 && (
        <TeamSection>
          <Typography variant="h5" component="span" style={{ paddingLeft: '40px' }}> {i18n.t('advisorCard.team').toUpperCase()}</Typography>
          <TeamMembers> {teamMemberCards(team)}</TeamMembers>
        </TeamSection>
      )}
    </BranchDiv>
  );
};

export default BranchCard;
