export const RouteNames = {
  landing: `${process.env.PUBLIC_URL}/`,
  holdings: `${process.env.PUBLIC_URL}/holdings`,
  holdingsAccountView: `${process.env.PUBLIC_URL}/holdings`,
  holdingsAccountViewAccountValue: `${process.env.PUBLIC_URL}/holdings/accountValue`,
  holdingsAccountViewInvestedCapital: `${process.env.PUBLIC_URL}/holdings/investedCapital`,
  holdingsAccountViewBookValue: `${process.env.PUBLIC_URL}/holdings/bookValue`,
  holdingsConsolidatedView: `${process.env.PUBLIC_URL}/holdings/consolidated`,
  history: `${process.env.PUBLIC_URL}/history`,
  assetsAllocation: `${process.env.PUBLIC_URL}/allocations`,
  householding: `${process.env.PUBLIC_URL}/householding`,
  registeredAccount: `${process.env.PUBLIC_URL}/registeredaccount`,
  householdingView: `${process.env.PUBLIC_URL}/householding`,
  accessSharingView: `${process.env.PUBLIC_URL}/householding/share`,
  documentDeliveryView: `${process.env.PUBLIC_URL}/householding/docdelivery`,
  nickName: `${process.env.PUBLIC_URL}/householding/nickname`,
  performance: `${process.env.PUBLIC_URL}/performance`,
  mirrorview: `${process.env.PUBLIC_URL}/mirrorview`,
  sendMessage: `${process.env.PUBLIC_URL}/messages/send`,
  markets: `${process.env.PUBLIC_URL}/markets`,
  marketsCurrencyTabView: `${process.env.PUBLIC_URL}/markets/currencyView`,
  documents: `${process.env.PUBLIC_URL}/documents`,
  settings: `${process.env.PUBLIC_URL}/settings`,
  settingHousehold: `${process.env.PUBLIC_URL}/settings/householding`,
  settingHoldings: `${process.env.PUBLIC_URL}/settings/holdings`,
  settingNickname: `${process.env.PUBLIC_URL}/settings/nickname`,
  settingAccessSharing: `${process.env.PUBLIC_URL}/settings/share`,
  settingdocumentDelivery: `${process.env.PUBLIC_URL}/settings/docdelivery`,
  settingConsent: `${process.env.PUBLIC_URL}/settings/consent`,
  kyc: `${process.env.PUBLIC_URL}/kyc`,
  positionOverview: `${process.env.PUBLIC_URL}/position/overview`,
  marketProductOverview: `${process.env.PUBLIC_URL}/marketProduct/overview`,
};

export const MenuHiddenRouteNames = [RouteNames.kyc];

export const Cultures = {
  english: 'en-CA',
  french: 'fr-CA',
};

export const Languages = {
  english: 'en',
  french: 'fr',
};

export enum SortDirection {
  Asc = 'ascending',
  Desc = 'descending',
}

export enum CookiesNameEnum {
  accountIds = 'accountIds',
  language = 'language',
  userId = 'userId',
}

export enum ErrorTypes {
  Token = 'Get token failed',
  NoAccount = 'No account',
}
