import { Reducer } from 'redux';
import {
  IAccount,
  IAccountState,
  AccountActionTypes,
  IAssetsAllocation,
  IDeliveryPreferences,
  IRateOfReturn,
  IClientAccount,
  IDeliveryPreferencesSummary,
  AccountHoldingsView,
} from '~/stores/account/accountTypes';
import { CurrencyEnum } from '~/common/types';
import moment from 'moment';

export const initialState: IAccountState = {
  filteredAccountIds: [],
  allAccountsFiltered: [],
  filteredRegisterAccountIds: [],
  clientAccounts: {
    totalMarketValue: 0,
    daysChange: 0,
    daysChangePercent: 0,
    previousDayChangeValue: 0,
    previousDayChangePercent: 0,
    cashBalanceCad: 0,
    marginValueCad: 0,
    marginValueUsd: 0,
    investedCapitalCAD: 0,
    investedCapitalGainLossCAD: 0,
    investedCapitalGainLossPercentage: 0,
    bookValueCad: 0,
    unrealizedGainAndLossCAD: 0,
    unrealizedGainAndLossUSD: 0,
    unrealizedGainAndLossPercent: 0,
    currency: '',
    accounts: {},
    isLiveData: true,
  },
  isFetching: false,
  isFetchingFail: false,
  dataExpiration: undefined,
  assetsAllocation: [],
  isAssetsAllocationFetching: false,
  assetsAllocationDataExpiration: undefined,
  isRegisteredAccountFetching: false,
  isRegisteredAccountFetchingFail: false,
  dataRegisteredAccountExpiration: undefined,
  yearRegisteredAccount: undefined,
  isFetchingNetInvested: false,
  isFetchingNetInvestedFail: false,
  netInvested: undefined,
  isFetchingRateOfReturn: false,
  isFetchingRateOfReturnFail: false,
  isFetchingCashFlowSummary: false,
  isFetchingCashFlowSummaryFail: false,
  rateOfReturn: undefined,
  cashFlow: {
    ytd: {
      openingMarketValue: 0,
      deposits: 0,
      withdrawals: 0,
      investmentIncome: { totalIncome: 0, drips: 0, dividends: 0, interest: 0 },
      feesAndCharges: { totalFees: 0, feeBasedServices: 0, adminFees: 0 },
      taxes: 0,
      changeInMarketValue: 0,
      closingMarketValue: 0,
      currency: '',
    },
    previousYear: {
      openingMarketValue: 0,
      deposits: 0,
      withdrawals: 0,
      investmentIncome: { totalIncome: 0, drips: 0, dividends: 0, interest: 0 },
      feesAndCharges: { totalFees: 0, feeBasedServices: 0, adminFees: 0 },
      taxes: 0,
      changeInMarketValue: 0,
      closingMarketValue: 0,
      currency: '',
    },
    exchangeRate: {},
  },
  isFetchingDeliveryPreferences: false,
  isFetchingDeliveryPreferencesFail: false,
  deliveryPreferences: [],
  isSavingDeliveryPreferencesFail: false,
  isSavingDeliveryPreferences: false,
  isFetchingDeliveryPreferencesSummary: false,
  isFetchingDeliveryPreferencesSummaryFail: false,
  deliveryPreferencesSummary: { IsPaperless: false, IsDueToReview: false },
  isSavingNicknameFail: false,
  isSavingNickname: false,
  documentsStartDate: moment().add(-180, 'd').format('YYYY-MM-DD'),
  documentsEndDate: moment().format('YYYY-MM-DD'),
  documents: [],
  isFetchingDocuments: false,
  isFetchingDocumentsFail: false,
  isFetchingTradeConfirmationDocuments: false,
  isFetchingTradeConfirmationDocumentsFail: false,
  isDocumentLoaded: false,
  isTradeConfirmationsLoaded: false,
  isMergingDownloadPdfs: false,
  isMergingDownloadPdfsDone: false,
  isMergingDownloadPdfsFail: false,
  mergePdfsResults: '', // TODO: Change when integrating
  isFetchingMarketData: false,
  lastUpdate: undefined,
  lastUpdatePortal: undefined,
  prevBusinessDayLastUpdate: undefined,
  isFetchingManrketDataHasError: false,
  isFetchingClosedMarkets: false,
  closedMarkets: [],
  isFetchingCSVByDomain: false,
  isFetchingCSVByDomainFail: false,
  isFetchingCSVByDomainDone: false,
  csvResult: '',
  selectedHoldingsAccountView: 'account',
};

const handleFetchAccounts = (state: IAccountState) => ({
  ...state,
  isFetching: true,
  isFetchingFail: false,
});

const handleFetchAccountsAborted = (state: IAccountState) => ({
  ...state,
  isFetching: false,
  isFetchingFail: true,
});

const handleFetchAccountsFailed = (state: IAccountState) => ({
  ...state,
  isFetching: false,
  isFetchingFail: true,
});

const handleFetchAccountsSuccessful = (state: IAccountState, clientAccounts: IClientAccount) => {
  const newAccounts = { ...state.clientAccounts.accounts };

  clientAccounts.accounts.map((account) => {
    newAccounts[account.id] = account;
  });
  return {
    ...state,
    clientAccounts: {
      totalMarketValue: clientAccounts.totalMarketValue,
      daysChange: clientAccounts.daysChange,
      daysChangePercent: clientAccounts.daysChangePercent,
      previousDayChangeValue: clientAccounts.previousDayChangeValue,
      previousDayChangePercent: clientAccounts.previousDayChangePercent,
      cashBalanceCad: clientAccounts.cashBalanceCad,
      marginValueCad: clientAccounts.marginValueCad,
      marginValueUsd: clientAccounts.marginValueUsd,
      investedCapitalCAD: clientAccounts.investedCapitalCAD,
      investedCapitalGainLossCAD: clientAccounts.investedCapitalGainLossCAD,
      investedCapitalGainLossPercentage: clientAccounts.investedCapitalGainLossPercentage,
      bookValueCad: clientAccounts.bookValueCad,
      unrealizedGainAndLossCAD: clientAccounts.unrealizedGainAndLossCAD,
      unrealizedGainAndLossUSD: clientAccounts.unrealizedGainAndLossUSD,
      unrealizedGainAndLossPercent: clientAccounts.unrealizedGainAndLossPercent,
      currency: clientAccounts.currency,
      accounts: newAccounts,
      isLiveData: clientAccounts.isLiveData,
    },
    isFetching: false,
    isFetchingFail: false,
    dataExpiration: moment().add(1, 'd').startOf('day').toDate(),
    prevBusinessDayLastUpdate: clientAccounts.accounts[0].prevBusinessDayExchangeRate.loadStamp,
  };
};

const handleSetFilteringAccountIds = (state: IAccountState, selectedAccountIds: string[]) => {
  const filteredAccountIds = selectedAccountIds && selectedAccountIds.length > 0 ? selectedAccountIds : [];
  return {
    ...state,
    filteredAccountIds,
  };
};

const handleSetFilteringPartyAccountIds = (state: IAccountState, selectedAccountIds: string[]) => {
  const allAccountsFiltered = selectedAccountIds && selectedAccountIds.length > 0 ? selectedAccountIds : [];
  return {
    ...state,
    allAccountsFiltered,
  };
};

const handleSetFilteringRegisterAccountIds = (state: IAccountState, selectedAccountIds: string[]) => {
  const filteredRegisterAccountIds = selectedAccountIds && selectedAccountIds.length > 0 ? selectedAccountIds : [];
  return {
    ...state,
    filteredRegisterAccountIds,
  };
};

const handleCleanFilteringAccountIds = (state: IAccountState, accountIds: string[]) => {
  const { filteredAccountIds } = state;
  if (!filteredAccountIds || filteredAccountIds.length < 0) {
    return state;
  }

  const newFilteredAccountIds = [] as string[];
  filteredAccountIds.forEach((f) => {
    if (accountIds.indexOf(f) >= 0) {
      newFilteredAccountIds.push(f);
    }
  });

  const { filteredRegisterAccountIds } = state;
  if (!filteredRegisterAccountIds || filteredRegisterAccountIds.length < 0) {
    return state;
  }

  const newfilteredRegisterAccountIds = [] as string[];
  filteredRegisterAccountIds.forEach((f) => {
    if (accountIds.indexOf(f) >= 0) {
      newfilteredRegisterAccountIds.push(f);
    }
  });

  return {
    ...state,
    documents: [],
    isDocumentLoaded: false,
    filteredAccountIds: newFilteredAccountIds,
    filteredRegisterAccountIds: newfilteredRegisterAccountIds,
  };
};

const handleFetchAssetsAllocation = (state: IAccountState) => ({
  ...state,
  isAssetsAllocationFetching: true,
});

const handleFetchAssetsAllocationSuccessful = (state: IAccountState, assetsAllocation: IAssetsAllocation[]) => ({
  ...state,
  assetsAllocation,
  isAssetsAllocationFetching: false,
  assetsAllocationDataExpiration: moment().add(1, 'd').startOf('day').toDate(),
});

const handleFetchAssetsAllocationAborted = (state: IAccountState) => ({
  ...state,
  isAssetsAllocationFetching: false,
});

const handleFetchRegisteredAccounts = (state: IAccountState) => ({
  ...state,
  isRegisteredAccountFetching: true,
});

const handleFetchRegisteredAccountSuccessful = (state: IAccountState, payload: any) => {
  const { registeredAccounts, year } = payload;

  return {
    ...state,
    registeredAccounts,
    isRegisteredAccountFetching: false,
    isRegisteredAccountFetchingFail: false,
    dataRegisteredAccountExpiration: moment().add(1, 'd').startOf('day').toDate(),
    yearRegisteredAccount: year,
  };
};

const handleFetchRegisteredAccountAborted = (state: IAccountState) => ({
  ...state,
  isRegisteredAccountFetching: false,
});

const handleFetchRegisteredAccountFail = (state: IAccountState) => ({
  ...state,
  isRegisteredAccountFetching: false,
  isRegisteredAccountFetchingFail: true,
});

const handleFetchNetInvested = (state: IAccountState) => ({
  ...state,
  isFetchingNetInvested: true,
});

const handleFetchNetInvestedAborted = (state: IAccountState) => ({
  ...state,
  isFetchingNetInvested: false,
});

const handleFetchNetInvestedFail = (state: IAccountState) => ({
  ...state,
  isFetchingNetInvested: false,
  isFetchingNetInvestedFail: true,
});

const handleFetchNetInvestedSuccessful = (state: IAccountState, netInvested: any) => ({
  ...state,
  netInvested,
  isFetchingNetInvested: false,
  isFetchingNetInvestedFail: false,
  datePerformanceExpiration: moment().add(1, 'd').startOf('day').toDate(),
});

const handleresetNetInvestedExpirationDate = (state: IAccountState) => ({
  ...state,
  datePerformanceExpiration: undefined,
});

const handleFetchRateOfReturn = (state: IAccountState) => ({
  ...state,
  isFetchingRateOfReturn: true,
});

const handleFetchRateOfReturnAborted = (state: IAccountState) => ({
  ...state,
  isFetchingRateOfReturn: false,
});

const handleFetchRateOfReturnFail = (state: IAccountState) => ({
  ...state,
  isFetchingRateOfReturn: false,
  isFetchingRateOfReturnFail: true,
});

const handleFetchRateOfReturnSuccessful = (state: IAccountState, ror: IRateOfReturn) => ({
  ...state,
  rateOfReturn: ror,
  isFetchingRateOfReturn: false,
  isFetchingRateOfReturnFail: false,
});

const handleFetchCashFlowSummary = (state: IAccountState) => ({
  ...state,
  isFetchingCashFlowSummary: true,
});

const handleFetchCashFlowSummaryAborted = (state: IAccountState) => ({
  ...state,
  isFetchingCashFlowSummary: false,
});

const handleFetchCashFlowSummaryFail = (state: IAccountState) => ({
  ...state,
  // cashFlow: [],
  isFetchingCashFlowSummary: false,
  isFetchingCashFlowSummaryFail: true,
});

const handleFetchCashFlowSummarySuccessful = (state: IAccountState, cashFlow: any) => ({
  ...state,
  cashFlow,
  isFetchingCashFlowSummary: false,
  isFetchingCashFlowSummaryFail: false,
});

const handleFetchDeliveryPreferences = (state: IAccountState) => ({
  ...state,
  isFetchingDeliveryPreferences: true,
});

const handleFetchDeliveryPreferencesSuccessful = (
  state: IAccountState,
  deliveryPreferences: IDeliveryPreferences[],
) => ({
  ...state,
  deliveryPreferences,
  isFetchingDeliveryPreferences: false,
  isFetchingDeliveryPreferencesFail: false,
});

const handleFetchDeliveryPreferencesFail = (state: IAccountState) => ({
  ...state,
  isFetchingDeliveryPreferences: false,
  isFetchingDeliveryPreferencesFail: true,
});

const handleSaveDeliveryPreferences = (state: IAccountState) => ({
  ...state,
  isSavingDeliveryPreferences: true,
  isSavingDeliveryPreferencesFail: false,
});

const handleSaveDeliveryPreferencesSuccessful = (state: IAccountState) => ({
  ...state,
  isSavingDeliveryPreferences: false,
  isSavingDeliveryPreferencesFail: false,
});

const handleSaveDeliveryPreferencesFailed = (state: IAccountState) => ({
  ...state,
  isSavingDeliveryPreferences: false,
  isSavingDeliveryPreferencesFail: true,
});

const handleFetchDeliveryPreferencesSummary = (state: IAccountState) => ({
  ...state,
  isFetchingDeliveryPreferencesSummary: true,
});

const handleFetchDeliveryPreferencesSummarySuccessful = (
  state: IAccountState,
  deliveryPreferencesSummary: IDeliveryPreferencesSummary,
) => ({
  ...state,
  deliveryPreferencesSummary,
  isFetchingDeliveryPreferencesSummary: false,
  isFetchingDeliveryPreferencesSummaryFail: false,
});

const handleFetchDeliveryPreferencesSummaryFail = (state: IAccountState) => ({
  ...state,
  isFetchingDeliveryPreferencesSummary: false,
  isFetchingDeliveryPreferencesSummaryFail: true,
});

const handleSaveNickname = (state: IAccountState) => ({
  ...state,
  isSavingNickname: true,
  isSavingNicknameFail: false,
});

const handleSaveNicknameSuccessful = (state: IAccountState) => ({
  ...state,
  isSavingNickname: false,
  isSavingNicknameFail: false,
});

const handleSaveNicknameFailed = (state: IAccountState) => ({
  ...state,
  isSavingNickname: false,
  isSavingNicknameFail: true,
});

const handleFetchAccountDocuments = (state: IAccountState) => ({
  ...state,
  isFetchingDocuments: true,
  isDocumentLoaded: false,
});

const handleFetchTaxDocuments = (state: IAccountState) => ({
  ...state,
  isFetchingDocuments: true,
  isDocumentLoaded: false,
  isFetchingTradeConfirmationDocuments: false,
  isTradeConfirmationsLoaded: true,
});

const handleFetchAccountDocumentsAborted = (state: IAccountState) => ({
  ...state,
  isFetchingDocuments: false,
  isDocumentLoaded: false,
});

const handleFetchAccountDocumentsFail = (state: IAccountState) => ({
  ...state,
  isFetchingDocuments: false,
  isFetchingDocumentsFail: true,
  isDocumentLoaded: false,
});

const handleFetchAccountDocumentsSuccessful = (state: IAccountState, docs: any) => ({
  ...state,
  documents: [...(state.documents ?? []), ...docs],
  isFetchingDocuments: false,
  isFetchingDocumentsFail: false,
  isDocumentLoaded: true,
});

const handleSetDocumentReadStatusFlag = (state: IAccountState, documnetId: any) => {
  const { documents } = state;
  const doc = documents?.filter((d) => d.documentId === documnetId)[0];
  if (doc !== undefined) {
    doc.read = true;
  }
  return {
    ...state,
    documents,
  };
};

const handleSetDocumentDateFiltering = (state: IAccountState, dateFiltering: any) => {
  const { from, to } = dateFiltering;

  const { documents, documentsStartDate, documentsEndDate } = state;

  const newFrom = documentsStartDate < from ? documentsStartDate : from;
  const newTo = documentsEndDate > to ? documentsEndDate : to;
  const isDateTheSame = newFrom === documentsStartDate && newTo === documentsEndDate;

  return {
    ...state,
    isDocumentLoaded: isDateTheSame,
    isTradeConfirmationsLoaded: isDateTheSame,
    documents: isDateTheSame ? documents : [],
    documentsStartDate: newFrom,
    documentsEndDate: newTo,
  };
};

const handleFetchTradeConfirmationDocuments = (state: IAccountState) => ({
  ...state,
  isFetchingTradeConfirmationDocuments: true,
  isTradeConfirmationsLoaded: false,
});

const handleFetchTradeConfirmationDocumentsAborted = (state: IAccountState) => ({
  ...state,
  isFetchingTradeConfirmationDocuments: false,
  isTradeConfirmationsLoaded: false,
});

const handleFetchTradeConfirmationDocumentsFail = (state: IAccountState) => ({
  ...state,
  isFetchingTradeConfirmationDocuments: false,
  isFetchingTradeConfirmationDocumentsFail: true,
  isTradeConfirmationsLoaded: false,
});

const handleFetchTradeConfirmationDocumentsSuccessful = (state: IAccountState, docs: any) => ({
  ...state,
  documents: [...(state.documents ?? []), ...docs],
  isFetchingTradeConfirmationDocuments: false,
  isFetchingTradeConfirmationDocumentsFail: false,
  isTradeConfirmationsLoaded: true,
});

const handleMergeDownloadPdfs = (state: IAccountState) => ({
  ...state,
  isMergingDownloadPdfs: true,
  isMergingDownloadPdfsFail: false,
  isMergingDownloadPdfsDone: false,
});

const handleMergeDownloadPdfsSuccessful = (state: IAccountState, results: any) => ({
  ...state,
  mergePdfsResults: results,
  isMergingDownloadPdfs: false,
  isMergingDownloadPdfsFail: false,
  isMergingDownloadPdfsDone: true,
});

const handleMergeDownloadPdfsFailed = (state: IAccountState) => ({
  ...state,
  isMergingDownloadPdfs: false,
  isMergingDownloadPdfsFail: true,
  isMergingDownloadPdfsDone: true,
});

const handleFetchMarketData = (state: IAccountState) => ({
  ...state,
  isFetchingMarketData: true,
});

const handleFetchMarketDataSuccessful = (state: IAccountState, responses: any[]) => {
  if (responses.length !== 2) {
    return {
      ...state,
      isFetchingMarketData: false,
      isFetchingManrketDataHasError: false,
    };
  }

  return {
    ...state,
    lastUpdate: responses[0],
    lastUpdatePortal: responses[1],
    isFetchingMarketData: false,
    isFetchingManrketDataHasError: false,
  };
};

const handleFetchMarketDataNoLiveSuccessful = (state: IAccountState, responses: any[]) => {
  if (responses.length !== 1) {
    return {
      ...state,
      isFetchingMarketData: false,
      isFetchingManrketDataHasError: false,
    };
  }

  return {
    ...state,
    lastUpdate: responses[0],
    isFetchingMarketData: false,
    isFetchingManrketDataHasError: false,
  };
};

const handleFetchMarketDataFail = (state: IAccountState) => ({
  ...state,
  isFetchingMarketData: false,
  isFetchingManrketDataHasError: true,
});

const handleFetchClosedMarkets = (state: IAccountState) => ({
  ...state,
  isFetchingClosedMarkets: true,
});

const handleFetchClosedMarketsFail = (state: IAccountState) => ({
  ...state,
  isFetchingClosedMarkets: false,
});

const handleFetchClosedMarketsSuccessful = (state: IAccountState, markets: any[]) => ({
  ...state,
  closedMarkets: markets,
  isFetchingClosedMarkets: false,
});

const handleFetchCSVByDomain = (state: IAccountState) => ({
  ...state,
  isFetchingCSVByDomain: true,
  isFetchingCSVByDomainFail: false,
  isFetchingCSVByDomainDone: false,
});

const handleFetchCSVByDomainSuccessful = (state: IAccountState, results: any) => ({
  ...state,
  csvResult: results,
  isFetchingCSVByDomain: false,
  isFetchingCSVByDomainFail: false,
  isFetchingCSVByDomainDone: true,
});

const handleFetchCSVByDomainFailed = (state: IAccountState) => ({
  ...state,
  isFetchingCSVByDomain: false,
  isFetchingCSVByDomainFail: true,
  isFetchingCSVByDomainDone: true,
});

const handleSetHoldingsAccountView = (state: IAccountState, payload: any): IAccountState => {
  const { accountView }: { accountView: AccountHoldingsView } = payload;

  return {
    ...state,
    selectedHoldingsAccountView: accountView,
  };
};

const handleClearDocuments = (state: IAccountState) => ({
  ...state,
  documents: [],
  isDocumentLoaded: false,
  isTradeConfirmationsLoaded: false,
  isFetchingDocuments: false,
  isFetchingTradeConfirmationDocuments: false,
  documentsStartDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  documentsEndDate: moment().add(1, 'days').format('YYYY-MM-DD'),
});

export const accountReducer: Reducer<IAccountState> = (state = initialState, action) => {
  switch (action.type) {
    case AccountActionTypes.FETCH_ACCOUNTS:
      return handleFetchAccounts(state);
    case AccountActionTypes.FETCH_ACCOUNTS_INFO:
      return handleFetchAccounts(state);
    case AccountActionTypes.FETCH_ACCOUNTS_ABORTED:
      return handleFetchAccountsAborted(state);
    case AccountActionTypes.FETCH_ACCOUNTS_FAILED:
      return handleFetchAccountsFailed(state);
    case AccountActionTypes.FETCH_ACCOUNTS_SUCCESSFUL:
      return handleFetchAccountsSuccessful(state, action.payload);
    case AccountActionTypes.SET_FILTERING_ACCOUNT_IDS:
      return handleSetFilteringAccountIds(state, action.payload);
    case AccountActionTypes.SET_FILTERING_PARTY_ACCOUNT_IDS:
      return handleSetFilteringPartyAccountIds(state, action.payload);
    case AccountActionTypes.CLEAN_FILTERING_ACCOUNT_IDS:
      return handleCleanFilteringAccountIds(state, action.payload);
    case AccountActionTypes.SET_FILTERING_REGISTER_ACCOUNT_IDS:
      return handleSetFilteringRegisterAccountIds(state, action.payload);
    case AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION:
      return handleFetchAssetsAllocation(state);
    case AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION_SUCCESSFUL:
      return handleFetchAssetsAllocationSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_ACCOUNTS_ASSETS_ALLOCATION_ABORTED:
      return handleFetchAssetsAllocationAborted(state);
    case AccountActionTypes.FETCH_REGISTERED_ACCOUNTS:
      return handleFetchRegisteredAccounts(state);
    case AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_SUCCESSFUL:
      return handleFetchRegisteredAccountSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_ABORTED:
      return handleFetchRegisteredAccountAborted(state);
    case AccountActionTypes.FETCH_REGISTERED_ACCOUNTS_FAILED:
      return handleFetchRegisteredAccountFail(state);
    case AccountActionTypes.FETCH_NETINVESTED:
      return handleFetchNetInvested(state);
    case AccountActionTypes.FETCH_NETINVESTED_FAILED:
      return handleFetchNetInvestedFail(state);
    case AccountActionTypes.FETCH_NETINVESTED_ABORTED:
      return handleFetchNetInvestedAborted(state);
    case AccountActionTypes.FETCH_NETINVESTED_SUCCESSFUL:
      return handleFetchNetInvestedSuccessful(state, action.payload);
    case AccountActionTypes.RESET_NETINVESTED_EXPIRATION_DATE:
      return handleresetNetInvestedExpirationDate(state);
    case AccountActionTypes.FETCH_RATEOFRETURN:
      return handleFetchRateOfReturn(state);
    case AccountActionTypes.FETCH_RATEOFRETURN_FAILED:
      return handleFetchRateOfReturnFail(state);
    case AccountActionTypes.FETCH_RATEOFRETURN_ABORTED:
      return handleFetchRateOfReturnAborted(state);
    case AccountActionTypes.FETCH_RATEOFRETURN_SUCCESSFUL:
      return handleFetchRateOfReturnSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_CASHFLOWSUMMARY:
      return handleFetchCashFlowSummary(state);
    case AccountActionTypes.FETCH_CASHFLOWSUMMARY_FAILED:
      return handleFetchCashFlowSummaryFail(state);
    case AccountActionTypes.FETCH_CASHFLOWSUMMARY_ABORTED:
      return handleFetchCashFlowSummaryAborted(state);
    case AccountActionTypes.FETCH_CASHFLOWSUMMARY_SUCCESSFUL:
      return handleFetchCashFlowSummarySuccessful(state, action.payload);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES:
      return handleFetchDeliveryPreferences(state);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUCCESSFUL:
      return handleFetchDeliveryPreferencesSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES_FAILED:
      return handleFetchDeliveryPreferencesFail(state);
    case AccountActionTypes.SAVE_DELIVERY_PREFERENCES:
      return handleSaveDeliveryPreferences(state);
    case AccountActionTypes.SAVE_DELIVERY_PREFERENCES_SUCCESSFUL:
      return handleSaveDeliveryPreferencesSuccessful(state);
    case AccountActionTypes.SAVE_DELIVERY_PREFERENCES_FAILED:
      return handleSaveDeliveryPreferencesFailed(state);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY:
      return handleFetchDeliveryPreferencesSummary(state);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY_SUCCESSFUL:
      return handleFetchDeliveryPreferencesSummarySuccessful(state, action.payload);
    case AccountActionTypes.FETCH_DELIVERY_PREFERENCES_SUMMARY_FAILED:
      return handleFetchDeliveryPreferencesSummaryFail(state);
    case AccountActionTypes.SAVE_NICKNAME:
      return handleSaveNickname(state);
    case AccountActionTypes.SAVE_NICKNAME_SUCCESSFUL:
      return handleSaveNicknameSuccessful(state);
    case AccountActionTypes.SAVE_NICKNAME_FAILED:
      return handleSaveNicknameFailed(state);
    case AccountActionTypes.FETCH_DOCUMENTS:
      return handleFetchAccountDocuments(state);
    case AccountActionTypes.FETCH_DOCUMENTS_FAILED:
      return handleFetchAccountDocumentsFail(state);
    case AccountActionTypes.FETCH_DOCUMENTS_ABORTED:
      return handleFetchAccountDocumentsAborted(state);
    case AccountActionTypes.FETCH_DOCUMENTS_SUCCESSFUL:
      return handleFetchAccountDocumentsSuccessful(state, action.payload);
    case AccountActionTypes.SET_DOCUMENT_READ_STATUS:
      return handleSetDocumentReadStatusFlag(state, action.payload);
    case AccountActionTypes.SET_DOCUMENT_DATE_FILTERING:
      return handleSetDocumentDateFiltering(state, action.payload);
    case AccountActionTypes.FETCH_TRADECONFIRMATION_DOCUMENTS:
      return handleFetchTradeConfirmationDocuments(state);
    case AccountActionTypes.FETCH_TRADECONFIRMATION_FAILED:
      return handleFetchTradeConfirmationDocumentsFail(state);
    case AccountActionTypes.FETCH_TRADECONFIRMATION_ABORTED:
      return handleFetchTradeConfirmationDocumentsAborted(state);
    case AccountActionTypes.FETCH_TRADECONFIRMATION_SUCCESSFUL:
      return handleFetchTradeConfirmationDocumentsSuccessful(state, action.payload);
    case AccountActionTypes.MERGE_DOWNLOAD_PDFS:
      return handleMergeDownloadPdfs(state);
    case AccountActionTypes.MERGE_DOWNLOAD_PDFS_SUCCESSFUL:
      return handleMergeDownloadPdfsSuccessful(state, action.payload);
    case AccountActionTypes.MERGE_DOWNLOAD_PDFS_FAILED:
      return handleMergeDownloadPdfsFailed(state);
    case AccountActionTypes.FETCH_MARKETDATA:
      return handleFetchMarketData(state);
    case AccountActionTypes.FETCH_MARKETDATA_SUCCESSFUL:
      return handleFetchMarketDataSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_MARKETDATA_NOLIVE_SUCCESSFUL:
      return handleFetchMarketDataNoLiveSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_MARKETDATA_FAILED:
      return handleFetchMarketDataFail(state);
    case AccountActionTypes.FETCH_CLOSEDMARKETS:
      return handleFetchClosedMarkets(state);
    case AccountActionTypes.FETCH_CLOSEDMARKETS_SUCCESSFUL:
      return handleFetchClosedMarketsSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_CLOSEDMARKETS_FAILED:
      return handleFetchClosedMarketsFail(state);
    case AccountActionTypes.FETCH_CSV_BY_DOMAIN:
      return handleFetchCSVByDomain(state);
    case AccountActionTypes.FETCH_CSV_BY_DOMAIN_SUCCESSFUL:
      return handleFetchCSVByDomainSuccessful(state, action.payload);
    case AccountActionTypes.FETCH_CSV_BY_DOMAIN_FAILED:
      return handleFetchCSVByDomainFailed(state);
    case AccountActionTypes.SET_HOLDINGS_ACCOUNT_VIEW:
      return handleSetHoldingsAccountView(state, action.payload);
    case AccountActionTypes.CLEAR_DOCUMENTS:
      return handleClearDocuments(state);
    case AccountActionTypes.FETCH_TAX_DOCUMENTS:
      return handleFetchTaxDocuments(state);
    case AccountActionTypes.FETCH_TAX_DOCUMENTS_FAILED:
      return handleFetchAccountDocumentsFail(state);
    case AccountActionTypes.FETCH_TAX_DOCUMENTS_SUCCESSFUL:
      return handleFetchAccountDocumentsSuccessful(state, action.payload);
    default:
      return state;
  }
};

export const calculateTotalMarketValue = (account: IAccount, currency?: CurrencyEnum) => {
  const selectedCurrency = currency || account.currency;
  let positionsTotal = 0;

  if (account.positions && account.positions.length > 0) {
    const { positions } = account;

    let positionTotals = [] as number[];
    switch (selectedCurrency) {
      case CurrencyEnum.CAD:
        positionTotals = positions.map((position) => +position.totalMarketPriceCad.toFixed(2));
        break;
      case CurrencyEnum.USD:
        positionTotals = positions.map((position) => +position.totalMarketPriceUsd.toFixed(2));
        break;
    }

    positionsTotal = positionTotals.reduce((accumulator, positionTotal) => accumulator + positionTotal, 0);
  }

  const cashBalance = selectedCurrency === CurrencyEnum.CAD ? account.cashBalanceCad : account.cashBalanceUsd;
  const roundedCashBalance = cashBalance ? +cashBalance.toFixed(2) : 0;

  return +(positionsTotal + roundedCashBalance).toFixed(2);
};
