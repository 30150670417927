import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchSharedDocumentsError, fetchSharedDocumentsSuccessful, sendMessageError, sendMessageSuccess, sendMessageSuccessWithWarning } from '../actions/messageActions';
import { MessageActionTypes } from '../messageTypes';
import { MessageHttpClient } from '../services/MessageHttpClient';

export function* sendMessage({ payload }: any): any {
  const { clientId, recipientId, recipients, attachments } = payload;

  try {
    const response = yield call(MessageHttpClient.saveMessage, clientId, recipientId, recipients, attachments);
    if (response.error) {
      if (response.data) {
        yield put(sendMessageSuccessWithWarning(response.data, response.error));
      } else {
        yield put(sendMessageError(response.error));
      }
    } else {
      yield put(sendMessageSuccess(response.data));
    }
  } catch (err) {
    yield put(sendMessageError(''));
  }
}

export function* fetchSharedDocuments({ payload }: any): any {
  try {
    const { partyId } = payload;
    const documents = yield call(MessageHttpClient.fetchDocuments, partyId);
    yield put(fetchSharedDocumentsSuccessful(documents));
  } catch (err) {
    console.log(err);
    yield put(fetchSharedDocumentsError());
  }
}

function* messageSaga() {
  yield takeLatest(MessageActionTypes.SEND_MESSAGE, sendMessage);
  yield takeLatest(MessageActionTypes.FETCH_DOCUMENTS, fetchSharedDocuments);
}

export default messageSaga;
