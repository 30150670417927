import { action } from 'typesafe-actions';
import {
  KYCActionTypes,
  KYCGetStatusRequest,
  KycReviewIdsType,
  KYCDetailsResponseType,
  KYCStatusFetchSchemaType,
  KYCDetailsFormSchemaType,
} from './kycTypes';

export const fetchKYCStatus = (kycRequest: KYCGetStatusRequest) =>
  action(KYCActionTypes.KYC_STATUS_FETCH, { kycRequest });
export const fetchKYCStatusSuccessful = (response: KYCStatusFetchSchemaType) =>
  action(KYCActionTypes.KYC_STATUS_FETCH_SUCCESSFUL, { response });
export const fetchKYCStatusFailed = () => action(KYCActionTypes.KYC_STATUS_FETCH_FAILED);

export const fetchKYCDetails = (reviewIds: KycReviewIdsType) => action(KYCActionTypes.KYC_DETAILS_FETCH, { reviewIds });
export const fetchKYCDetailsSuccessful = (response: KYCDetailsResponseType) =>
  action(KYCActionTypes.KYC_DETAILS_FETCH_SUCCESSFUL, { response });
export const fetchKYCDetailsFailed = () => action(KYCActionTypes.KYC_DETAILS_FETCH_FAILED);

export const saveKYCRemindLater = (reviewIds: KycReviewIdsType) =>
  action(KYCActionTypes.KYC_REMIND_LATER, { reviewIds });
export const saveKYCRemindLaterSuccessful = () => action(KYCActionTypes.KYC_REMIND_LATER_SUCCESSFUL);
export const saveKYCRemindLaterFailed = () => action(KYCActionTypes.KYC_REMIND_LATER_FAILED);

export const saveKYCDetails = (KYCs: KYCDetailsFormSchemaType) => action(KYCActionTypes.KYC_DETAILS_SAVE, { KYCs });
export const saveKYCDetailsSuccessful = () => action(KYCActionTypes.KYC_DETAILS_SAVE_SUCCESSFUL);
export const saveKYCDetailsNoLongerNeeded = () => action(KYCActionTypes.KYC_DETAILS_SAVE_NO_LONGER_NEEDED);
export const saveKYCDetailsFailed = () => action(KYCActionTypes.KYC_DETAILS_SAVE_FAILED);

export const KYCFormCompleted = () => action(KYCActionTypes.KYC_FORM_COMPLETED);
export const KYCFormIgnoreSession = () => action(KYCActionTypes.KYC_FORM_IGNORE_SESSION);
