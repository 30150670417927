import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAccount, IRegisteredAccount } from '~/stores/account/accountTypes';
import { setFilteringAccountIds, setFilteringRegisterAccountIds } from '~/stores/account/actions/accountActions';
import { updateTransactionsAccounts } from '~/stores/transaction/actions/transactionActions';
import { IPartyV1 } from '~/stores/party/partyTypes';
import { IAppRootState } from '~/app/rootReducer';
import { getPartyV1, getIsHouseHoldingView, getIsProgramView } from '~/stores/party/selectors/partySelectors';
import {
  getFilteredAccountIds,
  getRegisteredAccounts,
  getFilteredRegisterAccountIds,
  getAllCurrentFilterAccounts,
} from '~/stores/account/selectors/accountSelectors';
import type { DataNode } from 'antd/es/tree';
import i18n from '~/app/i18n';
import { Switch, Tree } from 'antd';
import { toggleHouseHoldView, toggleProgramView } from '~/stores/party/actions/partyActions';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { geRootFromAccount, getAccountsFromRoot } from '~/common/accountsHelpers';
import { Popper, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IAButtonSelect } from '~/common/styles/baseStyle';
import { refreshSession } from '~/stores/system/actions/systemActions';

export interface IPropsFromState {
  accounts: IAccount[];
  filteredAccountIds: string[];
  filteredRegisterAccountIds: string[];
  party: IPartyV1;
  isHouseHoldingView: boolean;
  isProgramView: boolean;
  registeredAccounts: IRegisteredAccount[];
}

export interface IAccountFilterProps {
  isRegisterAccountFilter: boolean;
  onClose?: () => void;
  disabled?: boolean;
}

export interface IAccountFilterState {
  isOpen: boolean;
  anchorEl: any;
  defaultAccountSelected: string[];
}

export interface IPropsFromDispatch {
  setFilteringAccountIds: typeof setFilteringAccountIds;
  setFilteringRegisterAccountIds: typeof setFilteringRegisterAccountIds;
  updateTransactionsAccounts: typeof updateTransactionsAccounts;
  toggleHouseHoldView: typeof toggleHouseHoldView;
  toggleProgramView: typeof toggleProgramView;
  refreshSession: typeof refreshSession;
}

const ContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 0px;
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans Regular', 'Open Sans';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  justify-content: flex-end;
`;

const ContainerFilter = styled.div`
  font-family: 'Open Sans Regular', 'Open Sans';
  box-shadow: 0px 5px 5px 2px rgba(102, 102, 102, 0.349019607843137);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding: 4px;
  background-color: #fff;
  min-width: 290px;
  max-height: 450px;
  overflow: auto;
`;
type IProps = IPropsFromState & IPropsFromDispatch & IAccountFilterProps;

class AccountFilter extends React.PureComponent<IProps, IAccountFilterState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      anchorEl: null,
      defaultAccountSelected: ['all'],
    };
  }

  private getTreeNodes = (roots: string[], accounts: IAccount[]) => {
    const rootTreeData: DataNode[] = [];
    roots.forEach((rootID, index) => {
      let owner = '';
      const accountsChild = getAccountsFromRoot(accounts, [rootID]);
      const accountsTreeData: DataNode[] = [];
      accountsChild.forEach((a: any) => {
        const title = a.alias === undefined || a.alias === '' ? `${a.accountType} (${a.id})` : a.alias;
        owner = a.groupedAccountName;
        accountsTreeData.push({ title, key: a.id, value: a.id } as DataNode);
      });
      const name = `${owner} (${accountsChild.length})`;
      rootTreeData.push({ value: name, title: name, key: rootID, children: accountsTreeData } as DataNode);
    });
    const treeData = [
      {
        title: i18n.t('common.nbAccountsSelectedAll'),
        key: 'all',
        children: rootTreeData,
        value: i18n.t('common.nbAccountsSelectedAll'),
      },
    ];
    return treeData;
  };

  private getTreeNodesByProgram = (accounts: IAccount[]) => {
    const rootTreeData: DataNode[] = [];

    const unique = (value: any, index: number, self: any) => self.indexOf(value) === index;

    const program = accounts.map((m) => m.productType).filter(unique);

    program.forEach((produit, index) => {
      let owner = '';
      const accountsChild = accounts.filter((m) => m.productType === produit);
      const accountsTreeData: DataNode[] = [];
      accountsChild.forEach((a: any) => {
        const title = a.alias === undefined || a.alias === '' ? `${a.accountType} (${a.id})` : a.alias;
        owner = a.productType === '' ? i18n.t('common.noProgram') : a.productType;
        accountsTreeData.push({ title, key: a.id, value: a.id } as DataNode);
      });
      const name = `${owner} (${accountsChild.length})`;
      rootTreeData.push({ value: name, title: name, key: produit, children: accountsTreeData } as DataNode);
    });
    const treeData = [
      {
        title: i18n.t('common.nbAccountsSelectedAll'),
        key: 'all',
        children: rootTreeData,
        value: i18n.t('common.nbAccountsSelectedAll'),
      },
    ];
    return treeData;
  };

  public handleShowHouseholdClick = (e: any) => {
    this.props.toggleHouseHoldView();
  };

  public handleGroupByProgramClick = (e: any) => {
    this.props.toggleProgramView();
  };

  private onCheck = (checkedKey: string[]) => {
    const { isRegisterAccountFilter } = this.props;
    const account = checkedKey.filter((w) => w.length === 7);
    this.setState({ defaultAccountSelected: account });
    this.props.refreshSession();
    if (isRegisterAccountFilter) {
      this.props.setFilteringRegisterAccountIds(account);
    } else {
      this.props.setFilteringAccountIds(account);
    }
  };

  private handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    if (this.state.isOpen && this.props.onClose) {
      const closeFn = this.props.onClose;
      closeFn();
    }
    this.setState({ isOpen: !this.state.isOpen, anchorEl: event.currentTarget });
  };

  private handleClickAway = () => {
    if (this.props.onClose) {
      const closeFn = this.props.onClose;
      closeFn();
    }
    this.setState({ isOpen: false, anchorEl: null });
  };

  private renderTreeNodes = (data: any[]) => {
    const result: DataNode[] = data.map((item) => {
      if (item.children) {
        return {
          expanded: true,
          title: item.title,
          key: item.key,
          dataRef: item,
          disableCheckbox: item.disableCheckbox,
          children: this.renderTreeNodes(item.children),
        } as DataNode;
      }
      return { key: item.key, ...item } as DataNode;
    });

    return result;
  };

  private isRegisterAccount = (id: string) => {
    const { registeredAccounts } = this.props;
    if (registeredAccounts === undefined) {
      return false;
    }

    const registerAccount = registeredAccounts.find((w: any) => w.account.id === id);
    if (registerAccount) {
      return true;
    }
    return false;
  };

  private isAllSelected = (selectedAccount: string[], accountsInFilter: IAccount[]): boolean =>
    selectedAccount.length === accountsInFilter.length || selectedAccount.length === 0;

  public componentDidUpdate() {
    if (this.props.filteredAccountIds.length === 0 && this.state.defaultAccountSelected.length > 0) {
      this.setState({ defaultAccountSelected: [] });
    }
  }

  public render() {
    const { isRegisterAccountFilter, filteredAccountIds, filteredRegisterAccountIds, accounts } = this.props;

    const id = this.state.isOpen ? 'popperAccount' : undefined;
    const isContainProgram = accounts.map((m: any) => m.productType).filter((w: any) => w !== '').length > 0;
    let selectedAccount: string[] = [];
    if (isRegisterAccountFilter) {
      selectedAccount = filteredRegisterAccountIds.length > 0 ? filteredRegisterAccountIds : [];
    } else {
      selectedAccount = filteredAccountIds.length > 0 ? filteredAccountIds : [];
    }

    let accountsInFilter = [...accounts];
    if (isRegisterAccountFilter) {
      accountsInFilter = accountsInFilter.filter((a) => this.isRegisterAccount(a.id));
    }

    let tree = this.getTreeNodes(geRootFromAccount(accountsInFilter), accountsInFilter);
    if (this.props.isProgramView && isContainProgram) {
      tree = this.getTreeNodesByProgram(accountsInFilter);
    }

    const labelNbAccount = i18n.t('common.nbAccountsSelected', {
      0: this.isAllSelected(selectedAccount, accountsInFilter)
        ? i18n.t('common.allAccounts')
        : selectedAccount.length.toString(),
    });
    return (
      <ContainerCol>
        <IAButtonSelect
          onClick={this.handleFilterClick}
          variant="outlined"
          endIcon={<ExpandMoreIcon htmlColor="#003da5" />}
        >
          {labelNbAccount}
        </IAButtonSelect>
        {this.state.isOpen && (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Popper
              id={id}
              open={this.state.isOpen}
              anchorEl={this.state.anchorEl}
              placement="bottom-end"
              disablePortal={false}
              modifiers={[{ name: 'flip', enabled: true }]}
            >
              <ContainerFilter>
                <ContainerRow>
                  <Trans i18nKey="householding.showHouseholdView" />
                  &nbsp;
                  <Switch
                    disabled={this.props.disabled}
                    size="small"
                    defaultChecked={this.props.isHouseHoldingView}
                    onChange={this.handleShowHouseholdClick}
                  />
                </ContainerRow>
                {isContainProgram && (
                  <ContainerRow>
                    <Trans i18nKey="common.groupByProgram" />
                    <Switch
                      disabled={this.props.disabled}
                      size="small"
                      defaultChecked={this.props.isProgramView}
                      onChange={this.handleGroupByProgramClick}
                    />
                  </ContainerRow>
                )}
                <Tree
                  checkable
                  disabled={this.props.disabled}
                  onCheck={this.onCheck as any}
                  checkedKeys={selectedAccount.length > 0 ? selectedAccount : this.state.defaultAccountSelected}
                  defaultExpandAll
                  treeData={this.renderTreeNodes(tree)}
                />
              </ContainerFilter>
            </Popper>
          </ClickAwayListener>
        )}
      </ContainerCol>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    filteredAccountIds: getFilteredAccountIds(state),
    filteredRegisterAccountIds: getFilteredRegisterAccountIds(state),
    isHouseHoldingView: getIsHouseHoldingView(state),
    isProgramView: getIsProgramView(state),
    accounts: getAllCurrentFilterAccounts(state),
    registeredAccounts: getRegisteredAccounts(state) as IRegisteredAccount[],
    party: getPartyV1(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleHouseHoldView,
      toggleProgramView,
      setFilteringAccountIds,
      setFilteringRegisterAccountIds,
      updateTransactionsAccounts,
      refreshSession,
    },
    dispatch,
  );

export default connect<IPropsFromState, IPropsFromDispatch, IAccountFilterProps, IAppRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountFilter);
