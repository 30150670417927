import styled from 'styled-components';

export const List = styled.ul`
  &&& {
    padding: 0;
    margin: 0;
    width: 100%;
    border-top: 1px solid #eff1f4;
  }
`;

export const ListItem = styled.li`
  &&& {
    display: flex;
    align-items: center;

    padding: 10px 15px;
    border-bottom: 1px solid #eff1f4;

    font-size: 14px;
  }

  &:hover {
    background-color: #eff1f4;
  }
`;

export const ItemContent = styled.span`
  color: #003da5;
  font-family: "OpenSans", "Open Sans";
  font-weight: bold;
`;

export const RemoveItem = styled.a`
  &&& {
    margin-left: auto;
    svg {
      fill: #003da5;
      vertical-align: middle;
    }

    &:hover {
      svg {
        fill: #ff585d;
        font-weight: bold;
      }
    }
  }
`;
