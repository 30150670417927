import { Reducer } from 'redux';
import { ContestActionTypes, Contest, IContestState } from '../contestTypes';

export const initialState: IContestState = {
  contest: undefined,
  contestDialogType: 'none',
};

const handleContestLoadedSuccessful = (state: IContestState, payload: { campaign?: Contest }): IContestState => ({
  ...state,
  contest: payload.campaign,
});

const handleContestLoadFailed = (state: IContestState): IContestState => ({
  ...state,
  contest: undefined,
});

const handleContestUnsubscribed = (state: IContestState): IContestState => ({
  ...state,
  contest: undefined,
});

const handleContestPostponed = (state: IContestState): IContestState => ({
  ...state,
  contest: undefined,
});

const handleContestSubscribedSuccessful = (state: IContestState): IContestState => {
  const campaign = state.contest;
  if (campaign) {
    campaign.isSubscribed = true;
    campaign.isPostponed = false;
  }
  return {
    ...state,
    contest: campaign,
  };
};

export const contestReducer: Reducer<IContestState> = (state = initialState, action) => {
  switch (action.type) {
    case ContestActionTypes.LOAD_CONTEST_FAILED:
      return handleContestLoadFailed(state);
    case ContestActionTypes.LOAD_CONTEST_SUCCESSFUL:
      return handleContestLoadedSuccessful(state, action.payload);
    case ContestActionTypes.UNSUBSCRIBE_CONTEST:
      return handleContestUnsubscribed(state);
    case ContestActionTypes.SUBSCRIBE_CONTEST_SUCCESSFUL:
      return handleContestSubscribedSuccessful(state);
    case ContestActionTypes.POSTPONE_CONTEST:
      return handleContestPostponed(state);
    default:
      return state;
  }
};
