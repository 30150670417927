import * as React from 'react';
import styled from 'styled-components';
import { IAccount } from '~/stores/account/accountTypes';
import { Grid, Typography } from '@mui/material';

const Seperator = styled.div`
  margin: 0px 4px;
`;

export interface IAccountDisplaySummaryProps {
  account: IAccount;
}

const AccountDisplaySummary = ({ account }: IAccountDisplaySummaryProps) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h3" component="span">
        {account.alias || account.name}
      </Typography>
    </Grid>
    <Grid container item xs={12} direction="row">
      <Grid item xs="auto">
        <Typography variant="h4" component="span">
          {account.accountTypeLong}
        </Typography>
        &nbsp;
      </Grid>
      <Grid item>
        <Typography variant="body1" component="span">
          {account.id}&nbsp;{account.productType.length > 0 ? '-' : ''}&nbsp;{account.productType}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

export default AccountDisplaySummary;
