import * as React from 'react';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import Colors from '~/common/themes/colors';
import { Trans } from 'react-i18next';
import NoData from './NoData';
import i18n from '~/app/i18n';

const NotAvailableTitle = styled.span`
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-size: 24px;
  color: ${Colors.anotherGrayShade};
`;

export const NoDataBanner = () => (
  <>
    <Stack
      mt={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: 'center', backgroundColor: Colors.white, borderRadius: 2 }}
      gap={2}
      paddingY={10}
      paddingX={10}
    >
      <NoData iconSize="md" text={i18n.t('marketsPage.topMovers.noData')} />
    </Stack>
  </>
);

export default NoDataBanner;
