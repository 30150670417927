import { IAppRootState } from '~/app/rootReducer';
import { ITeam } from '~/common/interfaces/advisor/Team';
import { ITeamByRepCode } from '~/common/interfaces/advisor/TeamByRepCode';
import { IAdvisorState } from '~/stores/advisor/AdvisorTypes';
import moment from 'moment';
import { createSelector } from 'reselect';

import { filterTeamWithPrimaryEmail, mapAdvisorsToTeams } from '../../../common/advisorTeamHelpers';
import { arrayIncludes } from '../../../common/helpers';
import { getRepCodesOfOwnedAccounts } from '../../account/selectors/accountSelectors';

export const getAdvisorState = (state: IAppRootState) => state.advisor;

export const getAdvisors = createSelector(
  getAdvisorState,
  (partyState: IAdvisorState) => partyState.advisors,
);

export const getIsAdvisorFetching = createSelector(
  getAdvisorState,
  (partyState: IAdvisorState) => partyState.isFetching,
);

export const getIsAdvisorFail = createSelector(
  getAdvisorState,
  (partyState: IAdvisorState) => partyState.isFail,
);

export const getIsDataExpired = createSelector(
  getAdvisorState,
  (advisorState: IAdvisorState) => !advisorState.dataExpiration || moment(advisorState.dataExpiration).isBefore(moment()),
);

export const getTeamsByRepCode = createSelector(
  getAdvisorState,
  (advisorState: IAdvisorState) => mapAdvisorsToTeams(advisorState.advisors),
);

export const getMainAdvisorTeam = createSelector(
  getTeamsByRepCode,
  getRepCodesOfOwnedAccounts,
  (teamByRepCodes: ITeamByRepCode, allOwnedAccountRepcodes: string[]) => {
    if (allOwnedAccountRepcodes.length === 0) {
      return undefined;
    }
    const filteredTeamKeys = Object.keys(teamByRepCodes).filter((key) => {
      const isKeyAOwnedRepcode: boolean = arrayIncludes(allOwnedAccountRepcodes, key);
      return isKeyAOwnedRepcode;
    });
    return teamByRepCodes[filteredTeamKeys[0]];
  },
);

export const getMainAdvisorTeamWithEmail = createSelector(
  getMainAdvisorTeam as any,
  (team: ITeam) => {
    if (team) {
      return filterTeamWithPrimaryEmail(team);
    }
    return undefined;
  },
);
