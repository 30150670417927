import * as React from 'react';
import { Container } from '~/common/styles/baseStyle';
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import NumberQI from '~/common/components/changeValue/NumberQI';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import moment from 'moment';
import { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export interface IProps {
  symbol?: string;
  isStock: boolean;
  historicalData: any;
  dataKey?: string;
  smallVersion?: boolean;
  isMarketProduct?: boolean;
  defaultZoomFilter?: string;
}

interface IHistoryState {
  historicalData: any;
  hourData: any;
  zoomFilter: string;
}

export const HistoryGraph: React.FunctionComponent<IProps> = (props) => {
  const [state, setState] = useState<IHistoryState>({
    historicalData: props.historicalData,
    hourData: null,
    zoomFilter: props.defaultZoomFilter ? props.defaultZoomFilter : '6M',
  });
  const { historicalData, hourData, zoomFilter } = state;
  const { smallVersion } = props;
  const chartWidth = smallVersion ? 546 : 900;
  const chartHeigth = smallVersion ? 212 : 277;
  const chartButtonGroupStyle = smallVersion ? '' : 'chartButtonGroup';
  const chartContainerStyle = smallVersion ? 'chartContainerSmall' : 'chartContainerBig';
  const chartTimeFrameStyle = smallVersion ? 'chartTimeFrameSmall' : 'chartTimeFrame';
  const fontChartSize = smallVersion ? '10' : '12';
  const areaChartStyle = smallVersion ? 'areaChartSmall' : '';

  const customTooltip = (viewBox: any) => {
    const { active, payload } = viewBox;
    if (active && payload && payload.length > 0 && payload[0].payload.date) {
      return ((props.symbol && props.symbol.indexOf(':') > 0) || props.isMarketProduct) ? (
        <div className="boxTooltip">
          {payload[0].payload.date.substring(0, payload[0].payload.date.indexOf('T'))} <br />
          {i18n.t('stockOverview.close')}: <NumberQI value={props.dataKey === 'close' ? payload[0].payload.close : payload[0].payload.adjustedClose} /> <br />
          {i18n.t('stockOverview.open')}: <NumberQI value={payload[0].payload.open} /> <br />
          {i18n.t('stockOverview.high')}: <NumberQI value={payload[0].payload.high} /> <br />
          {i18n.t('stockOverview.low')}: <NumberQI value={payload[0].payload.low} /> <br />
          {i18n.t('stockOverview.vol')}: <NumberQI value={payload[0].payload.volume} isFormatLargeNumber />
        </div>
      ) : (
        <div className="boxTooltip">
          {payload[0].payload.date.substring(0, payload[0].payload.date.indexOf('T'))} <br />
          NAV: <NumberQI value={payload[0].payload.close} />
        </div>
      );
    }

    return null;
  };

  const axisXLabel = (value: any) => {
    moment.locale(i18n.language);
    if (zoomFilter === 'hourly') {
      return moment(value).format('hh:mm A');
    }
    return moment(value).format('L');
  };

  const axisYLabel = (value: any) => value.toFixed(2);

  const handleZoomClick = (event: React.MouseEvent<HTMLElement>) => {
    const { id } = event.currentTarget;
    setState({
      ...state,
      zoomFilter: id,
    });
  };
  let data: any[] = [];

  if (historicalData && historicalData.periodicalDatas) {
    let month = -6;
    switch (zoomFilter) {
      case '1M': {
        month = -1;
        break;
      }
      case '3M': {
        month = -3;
        break;
      }
      case '6M': {
        month = -6;
        break;
      }
      case '1Y': {
        month = -12;
        break;
      }
      case '3Y': {
        month = -36;
        break;
      }
      case 'all': {
        month = 0;
        break;
      }
      default: {
        month = -6;
        break;
      }
    }
    moment.locale(i18n.language);
    const dateInterval = moment().add(month, 'month');
    if (zoomFilter === 'hourly') {
      data = hourData.periodicalDatas;
    } else if (month < 0) {
      data = (historicalData.periodicalDatas as any[]).filter((m) => moment(m.date) > dateInterval);
    } else {
      data = historicalData.periodicalDatas;
    }

    if (data.length === 0 && (historicalData.periodicalDatas as any[]).length > 0) {
      const sorted = historicalData.periodicalDatas as any[];
      // const sorted = (historicalData.periodicalDatas as any[]).sort(
      //  (d1, d2) => new Date(d1.date).getDate() - new Date(d2.date).getDate(),
      // );
      const lastValue = props.dataKey === 'close' ? sorted[sorted.length - 1].close : sorted[sorted.length - 1].adjustedClose;
      data = [
        {
          date: dateInterval.toString(),
          close: lastValue,
        },
        {
          date: moment().toString(),
          close: lastValue,
        },
      ];
    }
  }

  if (data.length > 0) {
    return (
      <Container className={chartContainerStyle}>
        <div style={{ marginBottom: '1rem' }}>
          <ButtonGroup className={chartButtonGroupStyle} aria-label="outlined button group">
            {props.isStock && (
              <Button
                className={`${chartTimeFrameStyle} ${zoomFilter === 'hourly' ? 'selected' : ''}`}
                id="hourly"
                onClick={handleZoomClick}
              >
                {i18n.t('stockOverview.1D') as string}{' '}
              </Button>
            )}
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === '1M' ? 'selected' : ''}`}
              id="1M"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.1M') as string}{' '}
            </Button>
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === '3M' ? 'selected' : ''}`}
              id="3M"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.3M') as string}{' '}
            </Button>
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === '6M' ? 'selected' : ''}`}
              id="6M"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.6M') as string}{' '}
            </Button>
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === '1Y' ? 'selected' : ''}`}
              id="1Y"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.1Y') as string}{' '}
            </Button>
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === '3Y' ? 'selected' : ''}`}
              id="3Y"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.3Y') as string}{' '}
            </Button>
            <Button
              className={`${chartTimeFrameStyle} ${zoomFilter === 'all' ? 'selected' : ''}`}
              id="all"
              onClick={handleZoomClick}
            >
              {i18n.t('stockOverview.all') as string}{' '}
            </Button>
          </ButtonGroup>
        </div>

        <AreaChart data={data} width={chartWidth} height={chartHeigth} className={areaChartStyle}>
          <XAxis
            dataKey="date"
            tickLine
            tick={{ fontSize: fontChartSize }}
            minTickGap={30}
            tickFormatter={axisXLabel}
          />
          <YAxis
            orientation="left"
            // domain={['dataMin-1', 'dataMax+1']}
            tick={{ fontSize: fontChartSize }}
            tickFormatter={axisYLabel}
          />
          <Tooltip content={customTooltip} />

          <Area
            type="monotone"
            dataKey={props.dataKey ? props.dataKey : 'adjustedClose'}
            stroke="#003da5"
            fill="#003da5"
            name="value"
            animationDuration={650}
          />
        </AreaChart>
      </Container>
    );
  }
  return <NoData text={i18n.t('common.noData')} />;
};

export default HistoryGraph;
