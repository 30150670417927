import i18n from '~/app/i18n';
import 'antd/lib/modal/style/index.css';
import '~/common/styles/styleIAS.css';
import { IAppRootState } from '~/app/rootReducer';
import {
  getPartyV1, getIsSavingConsent, getIsConsentVerbiageLoaded, getIsConsentVerbiageFetching, getIsConsentVerbiageFailed, getConsentVerbiages, getIsSavingConsentFailed, getIsSavingConsentSuccess,
} from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IPartyV1, IConsentVerbiages, IConsentPreferences } from '~/stores/party/partyTypes';
import { BoxShadow } from '~/common/styles/baseStyle';
import { saveConsentParty, loadConsentVerbiage, resetSaveConsent } from '~/stores/party/actions/partyActions';
import { Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { isUserAdvisorOrAdmin } from '../../../stores/system/selectors/SystemSelectors';
import { SharingViewContainer, ConsentsContainer } from '../accessharing-view/AccessSharingView.style';
import Footer from './Footer';
import Loading from '~/common/components/Loading/Loading';
import ModalSaveConfirmation from '../../../common/components/Modal/ModalSaveSuccess';
import ModalSaveFailed from '../../../common/components/Modal/ModalSaveFail';
import RadioControl from '~/common/components/radio/RadioControl';
import { Link } from '~/pages/landing-page/ConsentPrompt/ConsentPrompt.style';

export enum ConsentViewMode {
  Page,
  Popup
}

export interface IConsentViewProps {
  viewMode: ConsentViewMode,
  onCancel?: () => void
}

export interface IPropsFromDispatch {
  saveConsentParty: typeof saveConsentParty,
  loadConsentVerbiage: typeof loadConsentVerbiage
  resetSaveConsent: typeof resetSaveConsent
}

export interface IPropsFromState {
  culture: string,
  state: IAppRootState,
  party: IPartyV1,
  isUserAdvisorOrAdmin: boolean,
  isSavingConsent: boolean,
  isSavingConsentFailed: boolean,
  isSavingConsentSuccess: boolean,
  isConsentVerbiageLoaded: boolean,
  isConsentVerbiageFetching: boolean,
  isConsentVerbiageFailed: boolean,
  consentVerbiages: IConsentVerbiages[],
}

interface IConsentState {
  isDirty: boolean,
  stateConsents: IConsent[],
  updateCount: number,
  successModalVisible: boolean,
  failModalVisible: boolean,
  modalDismissed: boolean,
}

export interface IConsent {
  consentPurpose: string,
  value: boolean,
  frenchText: string[],
  englishText: string[],
}

class ConsentView extends React.PureComponent<IConsentViewProps & IPropsFromState & IPropsFromDispatch, IConsentState> {
  constructor(props: IConsentViewProps & IPropsFromState & IPropsFromDispatch) {
    super(props);
    this.state = {
      isDirty: false,
      stateConsents: [],
      updateCount: 0,
      successModalVisible: false,
      failModalVisible: false,
      modalDismissed: false,
    };
  }

  handleCancel = () => {
    this.setState({
      isDirty: false,
      stateConsents: [],
      updateCount: 0,
    });
  };

  initializeRecords = () => {
    if (this.state.stateConsents.length === 0 && this.props.isConsentVerbiageLoaded) {
      const dataSource: IConsent[] = [];
      this.props.consentVerbiages.forEach((c) => {
        const record: IConsent = {
          consentPurpose: c.consentPurpose,
          value: c.value,
          frenchText: c.frenchText,
          englishText: c.englishText,
        };

        dataSource.push(record);
      });
      this.setState({
        stateConsents: dataSource,
      });
    }
  };

  componentDidUpdate() {
    this.initializeRecords();

    if (this.props.viewMode === ConsentViewMode.Page) {
      if (this.props.isSavingConsentFailed && !this.state.failModalVisible && !this.state.modalDismissed) {
        this.showFailModal();
      } else if (this.props.isSavingConsentSuccess && !this.state.successModalVisible && !this.state.modalDismissed) {
        this.showSuccessModal();
      }
    }
  }

  componentDidMount() {
    this.initializeRecords();
  }

  showSuccessModal = () => {
    this.setState({
      successModalVisible: true,
      isDirty: false,
    });
  };

  showFailModal = () => {
    this.setState({
      failModalVisible: true,
    });
    setTimeout(() => { this.setState({ failModalVisible: false, modalDismissed: true }); }, 5000);
  };

  closeSuccessModal = () => {
    this.props.resetSaveConsent();
    this.setState({
      successModalVisible: false,
      modalDismissed: true,
    });
  };

  closeFailureModal = () => {
    this.props.resetSaveConsent();
    this.setState({
      failModalVisible: false,
      modalDismissed: true,
    });
  };

  handleSave = () => {
    if (this.state.stateConsents.filter((c) => c.value === undefined || c.value === null).length === 0) {
      const consents: IConsentPreferences[] = [];

      this.state.stateConsents.forEach((c) => {
        const verbiage = this.props.consentVerbiages.filter((v) => v.consentPurpose === c.consentPurpose)[0];
        if (verbiage.value !== c.value) {
          consents.push({
            consentPurpose: c.consentPurpose,
            consentVerbiageVersion: verbiage.consentVerbiageVersion,
            value: c.value,
          });
        }
      });
      this.props.saveConsentParty(consents);
      this.setState({
        modalDismissed: false,
      });
    }
  };

  handleChangeConsent = (event: any) => {
    let checked = false;
    if (event.target.value === 'agree') {
      checked = true;
    } else if (event.target.value === 'decline') {
      checked = false;
    }

    const updated = this.state.stateConsents;
    updated.filter((c) => c.consentPurpose === event.target.name)[0].value = checked;

    this.setState({
      isDirty: true,
      stateConsents: updated,
      updateCount: this.state.updateCount + 1,
    });
  };

  public renderConsent(consent: IConsent, language: string) {
    if (this.props.isConsentVerbiageLoaded) {
      return (<React.Fragment key={consent.consentPurpose + this.props.viewMode}>
        <Typography variant="body2" style={{ paddingTop: '20px' }}>
          {language === 'en' ? consent.englishText[0] : consent.frenchText[0]}
        </Typography>
        {consent.englishText.length > 1 &&
          <Typography variant="body2" style={{ paddingTop: '20px' }}>
            {language === 'en' ? consent.englishText[1] : consent.frenchText[1]}
          </Typography>}
        {consent.englishText.length > 2 &&
          <Typography variant="body2" style={{ paddingTop: '20px' }}>
            {language === 'en' ? consent.englishText[2] : consent.frenchText[2]}
          </Typography>}
        <RadioGroup
          row
          defaultValue="decline"
          name={consent.consentPurpose}
          value={consent.value === null ? 'none' : (consent.value ? 'agree' : 'decline')}
          onChange={this.handleChangeConsent}
          style={{ paddingTop: '10px' }}
        >
          <RadioControl value="agree" selected={(consent.value === null ? 'none' : (consent.value ? 'agree' : 'decline')) === 'agree'} labelKey="consent.agree" disabled={this.props.isUserAdvisorOrAdmin} />
          <RadioControl value="decline" selected={(consent.value === null ? 'none' : (consent.value ? 'agree' : 'decline')) === 'decline'} labelKey="consent.decline" disabled={this.props.isUserAdvisorOrAdmin} />
          <FormControlLabel value="none" control={<Radio style={{ paddingTop: 2, paddingBottom: 2 }} color="secondary" size="small" disabled={this.props.isUserAdvisorOrAdmin} />} label="" hidden />
        </RadioGroup>
      </React.Fragment>
      );
    }
    return <></>;
  }

  public render() {
    const loading = this.props.isSavingConsent;
    const failed = this.props.isConsentVerbiageFailed;
    const language = i18n.language.substring(0, 2).toLowerCase();

    if (!this.props.isConsentVerbiageLoaded && !this.props.isConsentVerbiageFetching && !this.props.isConsentVerbiageFailed) {
      this.props.loadConsentVerbiage();
    }

    if (failed) {
      return (<Typography variant="body2" component="div" style={{ paddingTop: '20px' }}>
        <Trans i18nKey="consent.loadFailed" />
      </Typography>);
    }
    return (
      <div>
        <ModalSaveConfirmation onClose={this.closeSuccessModal} isVisible={this.state.successModalVisible && !this.state.modalDismissed} message={i18n.t('householding.savePreferencesConfirmation')} />
        <ModalSaveFailed onClose={this.closeFailureModal} isVisible={this.state.failModalVisible && !this.state.modalDismissed} message={i18n.t('householding.savePreferencesFailure')} />

        {
          (loading || (!this.props.isConsentVerbiageLoaded)) ? (<Loading show />) :
            (<BoxShadow>
              <SharingViewContainer>
                <>
                  <Typography variant="body1">{`${i18n.t('consent.preface')}`}</Typography>

                  <ConsentsContainer>
                    {this.state.stateConsents.map((c) => this.renderConsent(c, language))}
                  </ConsentsContainer>

                  <Typography variant="body1" style={{ paddingTop: '20px' }}>{`${i18n.t('consent.review')}`}</Typography>
                  <Typography variant="body2" style={{ paddingTop: '20px' }}>{`${i18n.t('consent.inform')}`}</Typography>

                  <Typography variant="body1" style={{ paddingTop: '20px' }}>{`${i18n.t('consent.postface1')}`}
                    <ul>
                      <li>{`${i18n.t('consent.postfaceList1')}`}</li>
                      <li>{`${i18n.t('consent.postfaceList2')}`}</li>
                    </ul>
                  </Typography>
                  <Typography variant="body2" style={{ paddingTop: '20px' }}>{`${i18n.t('consent.postface2')}`}</Typography>
                  <Typography variant="body1" style={{ paddingTop: '20px' }}>
                    {`${i18n.t('consent.privacy')} `}
                    <Link href={i18n.t('consent.privacyRefNoticeLink')} target="_blank">
                      {`${i18n.t('consent.privacyNoticeLink')}`}
                    </Link>
                  </Typography>

                  <Footer
                    viewMode={this.props.viewMode}
                    onCancel={this.props.onCancel}
                    canSave={this.state.isDirty && (this.state.stateConsents.filter((c) => c.value === undefined || c.value === null).length === 0)}
                    canReset={this.state.isDirty}
                    onApply={this.handleSave}
                    onResetChanges={this.handleCancel}
                    isUserAdvisorOrAdmin={this.props.isUserAdvisorOrAdmin}
                  />
                </>
              </SharingViewContainer>
            </BoxShadow>
            )
        }
      </div>

    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    culture: state.system.culture,
    state,
    party: getPartyV1(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
    isSavingConsent: getIsSavingConsent(state),
    isSavingConsentFailed: getIsSavingConsentFailed(state),
    isSavingConsentSuccess: getIsSavingConsentSuccess(state),
    isConsentVerbiageLoaded: getIsConsentVerbiageLoaded(state),
    isConsentVerbiageFetching: getIsConsentVerbiageFetching(state),
    isConsentVerbiageFailed: getIsConsentVerbiageFailed(state),
    consentVerbiages: getConsentVerbiages(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  saveConsentParty,
  loadConsentVerbiage,
  resetSaveConsent,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsentView);
