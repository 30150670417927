import 'antd/lib/modal/style/index.css';
import 'antd/lib/steps/style/index.css';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import { Checkbox, Input, Modal, Steps, Tree } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import i18n from '~/app/i18n';
import { IAppRootState } from '~/app/rootReducer';
import { getAccountsFromRoot } from '~/common/accountsHelpers';
import { getAccountToShare, HouseholdType } from '~/common/partyHelpers';
import { IAccount } from '~/stores/account/accountTypes';
import { getAllAvailableAccounts } from '~/stores/account/selectors/accountSelectors';
import { fetchAccesSharing, fetchPartySearch, savePartyShareAccount } from '~/stores/party/actions/partyActions';
import { IPartySearchRequest, IPartyV1 } from '~/stores/party/partyTypes';
import { getAccountSearch, getIsSearchFetching, getPartyV1 } from '~/stores/party/selectors/partySelectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { isUserClient, isUserAdvisorOrAdmin } from '../../../stores/system/selectors/SystemSelectors';
import type { DataNode } from 'antd/es/tree';
import Loading from '../../../common/components/Loading/Loading';

interface IPropsFromState {
  party: IPartyV1,
  isFetchingPartySearch: boolean,
  partyFound: IPartyV1 | null,
  accounts: IAccount[],
  isUserAdvisorOrAdmin: boolean,
  isUserClient: boolean
}

interface ILocalProps {
  isEdit: boolean,
  userGuid: string | null,
  sharedAccount: any[],
}

interface IEditAccesSharingState {
  current: number,
  modalAddVisible: boolean,
  lastName: string,
  firstName: string,
  householdKey: string,
  msgError: string,
  selectedAccount: string[],
  isSearchParty: boolean,
  isAuthorizeShare: boolean
}

interface IPropsFromDispatch {
  fetchPartySearch: typeof fetchPartySearch,
  savePartyShareAccount: typeof savePartyShareAccount,
  fetchAccesSharing: typeof fetchAccesSharing
}

interface ITreeData {
  title: string,
  key: string
  children: ITreeData[]
  disableCheckbox: boolean;
}

const { Step } = Steps;

const SharingViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
`;

const ConfirmContainer = styled.div`
    text-align : center;
    width: 100%;
`;

const TreeContainer = styled.div`
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height:300px;
`;

const ErrorContainer = styled.div`
    display: flex;
    height: 100px;
    margin-top: 15px;
    padding-left: 85px;
    padding-top: 2px;
`;

type Props = ILocalProps & IPropsFromState & IPropsFromDispatch;
class EditAccesSharing extends React.PureComponent<Props, IEditAccesSharingState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      current: 0,
      modalAddVisible: false,
      lastName: '',
      firstName: '',
      householdKey: '',
      msgError: '',
      selectedAccount: [],
      isSearchParty: false,
      isAuthorizeShare: false,
    };
  }

  private renderTreeNodes = (data: any[]) => {
    const result: DataNode[] = data.map((item) => {
      if (item.children) {
        return ({
          title: item.title,
          key: item.key,
          dataRef: item,
          disableCheckbox: item.disableCheckbox,
          children: this.renderTreeNodes(item.children),
        } as DataNode);
      }
      return ({ key: item.key, ...item } as DataNode);
    });

    return result;
  };

  public handleUserFirstNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    this.setState({ firstName });
  };

  public handleUserLastNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    this.setState({ lastName });
  };

  public handleHouseKeyOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const householdKey = event.target.value;
    this.setState({ householdKey });
  };

  public fetchPartySearch = (householdKey: string, firstName: string, lastName: string) => {
    const request: IPartySearchRequest = { householdKey, firstName, lastName };
    this.props.fetchPartySearch(request);
  };

  public handleCancel = (e: any) => {
    const { party } = this.props;

    if (party && party.accounts !== undefined) {
      this.props.fetchAccesSharing();
    }

    this.closeModal();
  };

  public closeModal() {
    this.setState({
      modalAddVisible: false,
    });
  }

  public showAddModal = (event: any) => {
    this.setState({
      modalAddVisible: true,
      lastName: '',
      firstName: '',
      householdKey: '',
      msgError: '',
      selectedAccount: [],
      isAuthorizeShare: false,
      current: 0,
    });
  };

  public next = () => {
    const { party, partyFound, userGuid, isEdit } = this.props;

    // Mirror view skips validation
    if (this.props.isUserAdvisorOrAdmin) {
      this.setState({ current: this.state.current + 1, msgError: '' });
      return;
    }

    // Search client
    if (this.state.current === 0) {
      this.fetchPartySearch(this.state.householdKey, this.state.firstName, this.state.lastName);
    }

    if (!isEdit && partyFound == null) {
      // Validation si meme usager, householdkey est la fin du guid party
      if (party.id.indexOf(this.state.householdKey.toLowerCase()) > 25) {
        this.setState({ current: 0, msgError: i18n.t('householding.msgSameUser') });
      } else {
        this.setState({ current: 0, msgError: i18n.t('householding.msgEditError') });
      }
      return;
    }

    if (isEdit && userGuid == null) {
      return;
    }

    // Save Account selection
    if (this.state.current === 1) {
      const conflictAccounts = this.getConflictAccounts();
      if (conflictAccounts.length > 0) {
        const conflictAccountsText = conflictAccounts.map((a) => a.id).join(', ');
        this.setState({ current: 1, msgError: `${i18n.t('householding.sharingConflict')} ${conflictAccountsText}` });
        return;
      }

      if (partyFound !== null) {
        const guid = userGuid || partyFound.id;
        this.props.savePartyShareAccount(party.id, guid, this.state.selectedAccount);
      }
    }

    // Confirmation
    if (this.state.current === 2) {
      if (party && party.accounts !== undefined) {
        this.props.fetchAccesSharing();
      }
      this.closeModal();
      return;
    }

    this.setState({ current: this.state.current + 1, msgError: '' });
  };

  public prev = (e: any) => {
    this.setState({ current: this.state.current - 1 });
  };

  public onCheck = (checkedKey: string[]) => {
    const account = checkedKey.filter((w) => w !== '-1');
    this.setState({ selectedAccount: account });
  };

  public onAuthorize = (e: any) => {
    this.setState({ isAuthorizeShare: e.target.checked });
  };

  public getPreselectedAccounts = (partyId: string) => {
    const commonAccountIds = this.props.sharedAccount.filter((w) => w.partyId === partyId).map((m) => m.id);
    const shareeOwnerAccounts = this.shareeOwnerAccounts().map((m) => m.id);
    return commonAccountIds.filter((item) => shareeOwnerAccounts.indexOf(item) === -1);
  };

  public getConflictAccounts = () => this.shareeOwnerAccounts().filter((x) => this.state.selectedAccount.some((y) => x.id === y));

  public shareeOwnerAccounts = () => {
    const { userGuid, partyFound } = this.props;
    if (partyFound !== null) {
      const partyId = userGuid || partyFound.id;
      return this.props.sharedAccount.filter((w) => w.partyId === partyId && (w.role === HouseholdType.OWNER || w.role === HouseholdType.COMPANY));
    }
    return [];
  };

  public renderFormIdentification = () => (
    <div>
      <p><Trans i18nKey="householding.msgEditAcces" /></p>
      <SharingViewContainer>
        <label><Trans i18nKey="householding.firstName" /></label>
        <Input value={this.state.firstName} onChange={this.handleUserFirstNameOnChange} />
      </SharingViewContainer>
      <SharingViewContainer>
        <label><Trans i18nKey="householding.lastName" /></label>
        <Input value={this.state.lastName} onChange={this.handleUserLastNameOnChange} />
      </SharingViewContainer>

      <SharingViewContainer>
        <label><Trans i18nKey="householding.shareeHousekey" /></label>
        <Input value={this.state.householdKey} onChange={this.handleHouseKeyOnChange} />
      </SharingViewContainer>

    </div>
  );

  public renderSucces = (accountList: ITreeData[]) => (
    <ConfirmContainer>
      <h1><CheckOutlined style={{ color: 'green' }} /><Trans i18nKey="common.confirm" /></h1>
      <p><Trans i18nKey="householding.confirmMsg" /></p>

      {this.state.selectedAccount.map((item) => <p key={item}>{accountList.filter((w) => w.key === item)[0].title}</p>)}

    </ConfirmContainer>
  );

  public renderSelectMyAccount = (treeData: any) => (
    <div>
      <TreeContainer>
        <Tree
          checkable
          defaultExpandAll
          onCheck={this.onCheck as any}
          checkedKeys={this.state.selectedAccount}
          treeData={this.renderTreeNodes(treeData)}
        />
      </TreeContainer>

      <SharingViewContainer className="groupColumnSelected">
        <Checkbox disabled={this.props.isUserAdvisorOrAdmin} onChange={this.onAuthorize} checked={this.state.isAuthorizeShare}><Trans i18nKey="householding.shareAuthorizeNote" /></Checkbox>
      </SharingViewContainer>
    </div>
  );

  private getIsNextEnabled() {
    if (this.state.current === 0) {
      const hasFirstName = this.state.firstName.length > 0;
      const hasLastName = this.state.lastName.length > 0;
      const hasValidHouseholdKey = this.state.householdKey.length >= 4;
      return hasFirstName && hasLastName && hasValidHouseholdKey;
    }

    if (this.state.current === 1) {
      if (this.state.selectedAccount.length === 0) {
        return false;
      }
      return this.props.isUserClient && this.state.isAuthorizeShare;
    }

    if (this.state.current === 2) {
      return true;
    }

    return false;
  }

  public render() {
    const { party, isFetchingPartySearch, accounts } = this.props;

    if (isFetchingPartySearch) {
      this.setState({ isSearchParty: true });
    }
    if (this.state.isSearchParty && this.props.partyFound != null) {
      const selectedAccount = this.getPreselectedAccounts(this.props.partyFound.id);
      this.setState({ current: 1, msgError: '', isSearchParty: false, selectedAccount });
    }

    const rootTreeData: ITreeData[] = [];
    if (party && party.accounts) {
      getAccountToShare(party).forEach((a) => {
        rootTreeData.push({ title: a.id, key: a.id } as ITreeData);
      });
      rootTreeData.forEach((r) => {
        const childAccount = getAccountsFromRoot(accounts, [r.key]);
        const accountTreeData: ITreeData[] = [];
        childAccount.forEach((a) => {
          accountTreeData.push({ title: a.accountType, key: a.id, disableCheckbox: true } as ITreeData);
        });
        r.children = accountTreeData;
        r.disableCheckbox = this.props.isUserAdvisorOrAdmin;
      });
    }

    const treeData = [
      {
        title: i18n.t('common.allAccounts'),
        key: '-1',
        children: rootTreeData,
        disableCheckbox: this.props.isUserAdvisorOrAdmin,
      },
    ];

    const stepsAccesSharing = [{
      title: i18n.t('householding.shareeInfo'),
      content: this.renderFormIdentification(),
    }, {
      title: i18n.t('householding.clientSelection'),
      content: this.renderSelectMyAccount(treeData),
    }, {
      title: i18n.t('householding.acceptConfirm'),
      content: this.renderSucces(rootTreeData),
    }];

    const modalTitle = (!this.props.isEdit) ? i18n.t('householding.shareModalTitle') : i18n.t('householding.shareModalTitleEditing');

    const buttonFooter = [
      <Button key="previous" style={{ marginLeft: 8 }} onClick={this.prev} variant="contained" color="secondary"> <Trans i18nKey="common.previous" /></Button>,
      <Button key="next" onClick={this.next} style={{ margin: 8 }} disabled={!this.getIsNextEnabled()} variant="contained" color="secondary">
        {this.state.current !== 2 ? <Trans i18nKey="common.next" /> : 'Ok'}
      </Button>,
    ];
    if (this.state.current === 0 || this.props.isEdit) {
      buttonFooter.shift();
    }

    if (this.props.isEdit && this.props.userGuid && this.state.current === 0) {
      const selectedAccount = this.getPreselectedAccounts(this.props.userGuid);
      this.setState({ current: 1, msgError: '', isSearchParty: false, selectedAccount });
    }

    return (
      <div>
        <Modal title={modalTitle} centered visible={this.state.modalAddVisible} onOk={this.handleCancel} onCancel={this.handleCancel} width={600} footer={buttonFooter} maskClosable={false}>
          <Steps current={this.state.current}>
            {stepsAccesSharing.map((item) => <Step key={item.title} title={item.title} />)}
          </Steps>
          {this.state.msgError !== '' && !isFetchingPartySearch ? <ErrorContainer className="errorMsg">
            <b><Trans i18nKey="common.error" /></b> <br /> {this.state.msgError}
          </ErrorContainer> : null}
          {isFetchingPartySearch ?
            <SharingViewContainer style={{ height: 300 }}>
              <Loading show />
            </SharingViewContainer> :
            stepsAccesSharing[this.state.current].content}
        </Modal>
        {this.props.isEdit ? <IconButton onClick={this.showAddModal} style={{ color: '#003DA5' }}>
          <Edit />
        </IconButton> :
          <Button key="add" size="large" onClick={this.showAddModal} variant="contained" color="secondary" style={{ fontSize: '14px' }}><Trans i18nKey="householding.add" /></Button>}
      </div>
    );
  }
}

function mapStateToProps(state: IAppRootState): IPropsFromState {
  return {
    party: getPartyV1(state),
    isFetchingPartySearch: getIsSearchFetching(state),
    partyFound: getAccountSearch(state),
    accounts: getAllAvailableAccounts(state),
    isUserClient: isUserClient(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchPartySearch,
  savePartyShareAccount,
  fetchAccesSharing,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditAccesSharing);
