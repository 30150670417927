import React from 'react';
import KeyIndicatorCard from './KeyIndicatorCard';
import { Typography, Skeleton, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { IAppRootState } from '~/app/rootReducer';
import { IKeyIndicator } from '~/stores/marketData/marketDataTypes';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';

export interface IPropsFromState {
  keyIndicatorsData: IKeyIndicator[];
  isKeyIndicatorsFetching: boolean;
  isKeyIndicatorsFail: boolean;
  culture: string;
}

interface IKeyIndicatorsProps {}

const KeyIndicators: React.FC<IKeyIndicatorsProps & IPropsFromState> = ({
  keyIndicatorsData,
  isKeyIndicatorsFetching,
  isKeyIndicatorsFail,
  culture,
}) => {
  const count = keyIndicatorsData.filter((item) => item.history).length;
  return (
    <>
      <Typography
        variant="h4"
        component="span"
        style={{ paddingBottom: '12px', fontSize: '18px', fontWeight: '650', fontStyle: 'normal', lineHeight: '22px' }}
      >
        <Trans i18nKey="marketsPage.keyIndicators.title" />
      </Typography>
      {!isKeyIndicatorsFail ? (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
          alignItems="baseline"
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          {keyIndicatorsData.length === 0 || isKeyIndicatorsFetching ? (
            <>
              <Skeleton variant="rounded" width="20%" height={185}></Skeleton>
              <Skeleton variant="rounded" width="20%" height={185}></Skeleton>
              <Skeleton variant="rounded" width="20%" height={185}></Skeleton>
              <Skeleton variant="rounded" width="20%" height={185}></Skeleton>
              <Skeleton variant="rounded" width="20%" height={185}></Skeleton>
            </>
          ) : (
            keyIndicatorsData.map((item) => (
              <KeyIndicatorCard key={`${item.market}${item.symbol}`} item={item} count={count} culture={culture} />
            ))
          )}
        </Stack>
      ) : (
        <div
          style={{
            display: 'flex',
            borderRadius: '8px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            height: '162px',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <NoData iconSize="md" text={i18n.t('marketsPage.keyIndicators.noDataError')} />
        </div>
      )}
    </>
  );
};

function mapStateToProps(state: IAppRootState) {
  return {
    culture: state.system.culture,
    keyIndicatorsData: state.marketData.keyIndicators,
    isKeyIndicatorsFetching: state.marketData.isFetchingKeyIndicators,
    isKeyIndicatorsFail: state.marketData.isFailKeyIndicators,
  };
}
export default connect(mapStateToProps)(KeyIndicators);
