import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/styles';

export const DividendSummaryContainer = withStyles(Grid, {
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    background: '#FFF',
    boxShadow: '0px 2px 2px rgba(204, 204, 204, 0.35)',
    padding: '5px',
  },
});

export const Label = styled('div')({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontSize: '14px',
  fontWeight: 800,
});
