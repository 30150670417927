import styled from 'styled-components';
import Colors from '~/common/themes/colors';

export const stylesIcons = {
  largeIcon: {
    color: `${Colors.mariner}`,
    width: '30px',
    height: '30px',
    margin: '0 15px',
  },
  largeAlertIcon: {
    color: `${Colors.orangeDarkAlert}`,
    width: '30px',
    height: '30px',
    margin: '0 15px',
  },
};

export const LandingInfoSection = styled.div`
  background-color: #e3f2fd;
  color: ${Colors.anotherGrayShade3};
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  margin-bottom: 15px;
  border-color: ${Colors.mariner};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  font-weight: 400;
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  font-weight: 400;
`;

export const AlertInfoSection = styled.div`
  background-color: ${Colors.orangeLightAlert};
  color: ${Colors.anotherGrayShade3};
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-family: OpenSans-Regular, 'Open Sans', sans-serif;
  margin-bottom: 15px;
  border-color: ${Colors.orangeAlert};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
`;
