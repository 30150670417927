import * as React from 'react';
import { isFieldEmptyOrNull } from '~/common/helpers';
import { PositionSummaryContainer, PositionSummaryRow } from '../PositionOverviewPage.style';
import { PositionItem } from './PositionItem';
import { Divider, Grid } from '@mui/material';

interface IProps {
  rows?: number;
  cols: number;
  positionSummaryContainerXS?: any;
  data: any[];
}

export const PositionSummaryTable: React.FC<IProps> = ({ rows, cols, positionSummaryContainerXS, data }: IProps) => {
  positionSummaryContainerXS = isFieldEmptyOrNull(positionSummaryContainerXS) ? 8 : positionSummaryContainerXS;
  const positionItemXS = 12 / cols; // material-ui grid dimmesion is 12.
  return (
    <PositionSummaryContainer item xs={positionSummaryContainerXS}>
      {[...Array(rows)].map((v, i) => (
        <PositionSummaryRow container key={i}>
          {data.slice(i * cols, (i + 1) * cols).map((d, i2) => (
            <PositionItem
              xsInfo={d.xsItem ?? positionItemXS}
              labelKey={d.labelKey}
              toolTipKey={d.toolTipKey}
              toolTipColor={d.toolTipColor}
              positionValue={d.positionValue}
              key={Math.random()}
            />
          ))}
        </PositionSummaryRow>
      ))}
    </PositionSummaryContainer>
  );
};

// Because PositionSummaryTable is used everywhere, I've made this new component to be used in the SummaryRightGrid
// component only
export const PositionSummaryTable2: React.FC<IProps> = ({ rows, cols, positionSummaryContainerXS, data }: IProps) => {
  positionSummaryContainerXS = positionSummaryContainerXS || 8;
  const positionItemXS = 12 / cols; // material-ui grid dimmesion is 12.
  const calculatedRows = rows ?? Math.ceil(data.length / cols);
  return (
    <PositionSummaryContainer item xs={12}>
      {[...Array(calculatedRows)].map((_, rowIndex) => {
        const shouldRenderDivider = calculatedRows !== rowIndex + 1;
        return (
          <>
            <PositionSummaryRow container key={rowIndex} xs={12} sx={{ padding: 0 }}>
              {data.slice(rowIndex * cols, (rowIndex + 1) * cols).map(
                (d, colIndex) =>
                  d ? (
                    <PositionItem
                      xsInfo={d.xsItem ?? positionItemXS}
                      labelKey={d.labelKey}
                      toolTipKey={d.toolTipKey}
                      toolTipColor={d.toolTipColor}
                      positionValue={d.positionValue}
                      key={`row${rowIndex}-col${colIndex}`}
                    />
                  ) : (
                    // Empty element to push the next item to the right or next row
                    <Grid item xs={positionItemXS}></Grid>
                  ),
                // eslint-disable-next-line function-paren-newline
              )}
            </PositionSummaryRow>
            {shouldRenderDivider && (
              <Grid item xs={12} paddingTop={3}>
                <Divider sx={{ borderColor: 'rgb(218, 218, 216)' }} variant="fullWidth" />
              </Grid>
            )}
          </>
        );
      })}
    </PositionSummaryContainer>
  );
};
