import * as React from 'react';
import { IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import memoizeOne from 'memoize-one';
import { establishConsolidatedAccountInfo } from '~/common/accountsHelpers';
import { CurrencyEnum, ISortPreference, IntradayPositionTypes } from '~/common/types';
import NoData from '~/common/components/noData/NoData';
import i18n from '~/app/i18n';
import { Trans } from 'react-i18next';
import DecimalValue from '~/common/components/decimal-value/DecimalValue';
import { Table } from 'antd';
import { MonetaryValue } from '~/common/components';
import { BoxShadow } from '~/common/styles/baseStyle';
import { IAppRootState } from '~/app/rootReducer';
import { connect } from 'react-redux';
import ChangeValue from '~/common/components/changeValue/ChangeValue';
import { getIsMarketDataFetching, getIsMarketDataFetchingHasError } from '~/stores/account/selectors/accountSelectors';
import { getDidSetMarketDataRealtime } from '~/stores/party/selectors/partySelectors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Grid, Stack } from '@mui/material';
import ConsolidatedPositionDrilldown, { type IPositionAggregate } from './ConsolidatedPositionDrilldown';
import SummarySplitGrid from '~/common/components/account/account-view/SummarySplitGrid';
import { ComponentWithValidation } from '~/common/components/position-overview/summary-table/ComponentWithValidation';
import NumberQI from '~/common/components/changeValue/NumberQI';
import Colors from '~/common/themes/colors';
import { buildDataArrayForConsolidatedPage } from '~/common/components/account/account-view/SummaryAccountViewHelper';
import LinkButton from '~/common/components/link-button/LinkButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  renderSymbol,
  saveHoldingSortPreferencesIfNeeded,
  sorterByDescription,
  sorterByIndividualPrice,
  sorterByQuantity,
  sorterByTotalMarketPrice,
} from '~/common/components/account/account-display/AccountViewDisplayHelper';
import { HoldingViewKeys, SortColumnKeys } from '~/stores/party/partyTypes';
import { isUserAdvisorOrAdmin } from '~/stores/system/selectors/SystemSelectors';
import { Dispatch, bindActionCreators } from 'redux';
import { saveHoldingSortPreferences } from '~/stores/party/actions/partyActions';
import { IAccountsPropsFromDispatch, align } from '~/common/components/account/account-display/AccountDisplayTypes';
import { SortOrder } from 'antd/lib/table/interface';
import { GetStorageValueByKey, SetStorageKeyValue } from '~/app/sessionManager';

export interface IConsolidatedAccountInfo {
  consolidatedAccount: IAccount;
  positionAggregates: IPositionAggregate[];
}

export interface IConsolidatedViewProps {
  accounts: IAccount[];
  isSymbolLinkEnabled: boolean;
  shouldNotShowMarketDataInfo: boolean;
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary;
  holdingViewSortingPreferences: Record<HoldingViewKeys, ISortPreference>;
}

export interface IPropsFromState {
  culture: string;
  isFetchingMarketData: boolean;
  didSetMarketDataRealtime: boolean;
  isFetchingMarketDataHasError: boolean;
  isUserAdvisorOrAdmin: boolean;
}

export interface IConsolidatedState {
  isAllExpandedContentVisible: boolean;
  rowIdsExpand: string[];
  currentSortPreference: ISortPreference;
}

class ConsolidatedView extends React.PureComponent<
  IConsolidatedViewProps & RouteComponentProps & IPropsFromState & IAccountsPropsFromDispatch,
  IConsolidatedState
> {
  private consolidatedAccountInfo = memoizeOne(establishConsolidatedAccountInfo);

  constructor(props: IConsolidatedViewProps & RouteComponentProps & IPropsFromState & IAccountsPropsFromDispatch) {
    super(props);
    this.state = {
      isAllExpandedContentVisible: false,
      rowIdsExpand: [],
      currentSortPreference: this.props.holdingViewSortingPreferences?.consolidateView,
    };
  }

  public renderRowExpandedContent = (expandedContent: any) => {
    const { accounts } = this.props;

    const consolidatedAccountInfo = this.consolidatedAccountInfo(accounts);
    const matchingPositionAggregates = consolidatedAccountInfo.positionAggregates.filter(
      (positionAggregate) => positionAggregate.security === expandedContent.security,
    );

    return (
      <ConsolidatedPositionDrilldown
        isFetchingMarketDataHasError={this.props.isFetchingMarketDataHasError}
        didSetMarketDataRealtime={this.props.didSetMarketDataRealtime}
        positionAggregates={matchingPositionAggregates}
      />
    );
  };

  public HandleRowExpanded = (expandedContent: string[]) => {
    this.setState({ rowIdsExpand: expandedContent });
  };

  public componentDidUpdate(
    prevProps: RouteComponentProps &
      IConsolidatedViewProps &
      IPropsFromState &
      IConsolidatedState &
      IAccountsPropsFromDispatch,
  ) {
    if (prevProps.accounts !== this.props.accounts) {
      this.setState({ isAllExpandedContentVisible: false, rowIdsExpand: [] });
    }
  }

  private handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      saveHoldingSortPreferencesIfNeeded(
        { consolidateView: { sortColumn: sorter.columnKey, sortDirection: sorter.order } },
        this.props.isUserAdvisorOrAdmin,
        this.props.saveHoldingSortPreferences,
      );

      this.setState({ currentSortPreference: { sortColumn: sorter.columnKey, sortDirection: sorter.order } });

      // save current sorting preferences to session
      const sortingPreferencesByTabTemp: ISortPreference = {
        sortColumn: sorter.columnKey,
        sortDirection: sorter.order,
      };
      SetStorageKeyValue(HoldingViewKeys.CONSOLIDATE_VIEW, JSON.stringify(sortingPreferencesByTabTemp));
    }
  };

  private renderTable(accountCurrency: string) {
    const { accounts, culture, holdingViewSortingPreferences } = this.props;
    const consolidatedAccountInfo = this.consolidatedAccountInfo(accounts);
    const { rowIdsExpand } = this.state;

    const consolidatedViewInSessionValue = GetStorageValueByKey(HoldingViewKeys.CONSOLIDATE_VIEW);
    const consolidatedViewInSession =
      consolidatedViewInSessionValue !== undefined
        ? (JSON.parse(consolidatedViewInSessionValue) as ISortPreference)
        : undefined;
    const sortPreference = consolidatedViewInSession ?? this.state.currentSortPreference;

    const columns = [
      {
        title: <Trans i18nKey="common.symbolDescription" />,
        key: SortColumnKeys.DESCRIPTION,
        dataIndex: 'description',
        render: (value: any, record: any) =>
          renderSymbol(record, this.props.isSymbolLinkEnabled, this.props.location.pathname),
        sorter: /* istanbul ignore next */ (a: any, b: any, sortOrder: any) => sorterByDescription(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.DESCRIPTION
            ? (sortPreference?.sortDirection.toString() as SortOrder)
            : undefined,
        className: 'wrap',
        width: '40%',
      },
      {
        title: i18n.t('holdings.quantity'),
        key: SortColumnKeys.QUANTITY,
        dataIndex: 'quantity',
        render: (value: any, record: any) => (
          <DecimalValue
            value={value}
            culture={culture}
            amountOfDigits={2}
            zeroPlaceholder="0"
            areTrailingZerosVisible={record.isMutualFund}
          />
        ),
        sorter: (a: any, b: any) => sorterByQuantity(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.QUANTITY
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        align: 'right' as align,
        width: '20%',
      },
      {
        title: <Trans i18nKey="holdings.marketPrice" />,
        key: SortColumnKeys.MK_PRICE,
        dataIndex: 'individualMarketPrice',
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByIndividualPrice(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.MK_PRICE
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        render: (value: any, record: any) => (
          <>
            <MonetaryValue
              value={value}
              culture={culture}
              currency={record.priceCurrency}
              zeroPlaceholder={`${i18n.t('holdings.NA')}¹`}
              amountOfDigits={3}
            />
            {this.props.didSetMarketDataRealtime &&
            Object.values(IntradayPositionTypes).includes(record.alternateType as IntradayPositionTypes) ? (
              <ChangeValue
                value={
                  record.notFoundAtProvider || (this.props.isFetchingMarketDataHasError && value === 0)
                    ? undefined
                    : record.changeValue
                }
                percent={
                  record.notFoundAtProvider || (this.props.isFetchingMarketDataHasError && record.changePercent === 0)
                    ? undefined
                    : record.changePercent
                }
                decimalOverride={3}
                decimalOverrideForPercent={2}
                singleLineFormat
                mainContainerStyleRules={{ style: { display: 'inline-flex' } }}
                noArrow
                noShowValuesSign={false}
              />
            ) : (
              <div />
            )}
          </>
        ),
        align: 'right' as align,
        width: '20%',
      },
      {
        title: <Trans i18nKey="holdings.marketValue" values={{ 0: accountCurrency }} />,
        key: SortColumnKeys.MK_VALUE,
        dataIndex: accountCurrency === 'CAD' ? 'totalMarketPriceCad' : 'totalMarketPriceUsd',
        render: (value: any, record: any) => (
          <MonetaryValue
            value={value}
            culture={culture}
            currency={record.netAmountCurrency}
            zeroPlaceholder={i18n.t('holdings.NA')}
          />
        ),
        align: 'right' as align,
        sorter: /* istanbul ignore next */ (a: any, b: any) => sorterByTotalMarketPrice(a, b),
        sortOrder:
          sortPreference?.sortColumn === SortColumnKeys.MK_VALUE
            ? (sortPreference.sortDirection.toString() as SortOrder)
            : undefined,
        width: '20%',
      },
    ];
    const sortText = i18n.t('common.sort');

    let initialOrderedPositionByPreferences = consolidatedAccountInfo.consolidatedAccount.positions.sort(
      (a, b) => sorterByDescription(a, b),
      // eslint-disable-next-line function-paren-newline
    );
    console.log(holdingViewSortingPreferences);
    if (holdingViewSortingPreferences.consolidateView) {
      console.log(holdingViewSortingPreferences.consolidateView);
      if (sortPreference?.sortColumn === SortColumnKeys.QUANTITY) {
        console.log('HERE');
        initialOrderedPositionByPreferences = consolidatedAccountInfo.consolidatedAccount.positions.sort((a, b) =>
          sorterByQuantity(a, b),
        // eslint-disable-next-line function-paren-newline
        );
      }
      if (sortPreference?.sortColumn === SortColumnKeys.MK_PRICE) {
        initialOrderedPositionByPreferences = consolidatedAccountInfo.consolidatedAccount.positions.sort((a, b) =>
          sorterByIndividualPrice(a, b));
      }
      if (sortPreference?.sortColumn === SortColumnKeys.MK_VALUE) {
        initialOrderedPositionByPreferences = consolidatedAccountInfo.consolidatedAccount.positions.sort((a, b) =>
          sorterByTotalMarketPrice(a, b));
      }
    }

    return (
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="security"
        columns={columns}
        dataSource={initialOrderedPositionByPreferences}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any }) =>
            expanded ? (
              <ExpandLessIcon onClick={(e) => onExpand(record, e)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => onExpand(record, e)} />
            ),
        }}
        onExpandedRowsChange={this.HandleRowExpanded as any}
        expandedRowRender={this.renderRowExpandedContent}
        expandedRowKeys={rowIdsExpand}
        expandIconColumnIndex={4}
        locale={{
          emptyText: <NoData text={i18n.t('common.noPosition')} />,
          triggerDesc: i18n.t('common.sortDescending'),
          triggerAsc: i18n.t('common.sortAscending'),
        }}
        loading={this.props.isFetchingMarketData}
        showSorterTooltip={{ title: sortText, placement: 'bottom' }}
        onChange={this.handleTableChange}
      />
    );
  }

  customExpandIcon(props: { isActive: any; onItemClick: (arg0: any) => void; panelKey: any }) {
    let element;
    if (props.isActive) {
      element = (
        <a
          role="button"
          tabIndex={0}
          onClick={(e) => {
            props.onItemClick(props.panelKey);
          }}
          onKeyDown={(e) => {
            props.onItemClick(props.panelKey);
          }}
        >
          <ExpandMoreIcon />
        </a>
      );
    } else {
      element = (
        <a
          role="button"
          tabIndex={0}
          onClick={(e) => {
            props.onItemClick(props.panelKey);
          }}
          onKeyDown={(e) => {
            props.onItemClick(props.panelKey);
          }}
        >
          <ExpandMoreIcon />
        </a>
      );
    }
    return element;
  }
  public handleOnExpandAllButtonClick = () => {
    this.setState({ isAllExpandedContentVisible: !this.state.isAllExpandedContentVisible });
    if (!this.state.isAllExpandedContentVisible) {
      const { accounts } = this.props;
      const consolidatedAccountInfo = this.consolidatedAccountInfo(accounts);
      const selectAll = consolidatedAccountInfo.consolidatedAccount.positions.map((m) => m.security);
      this.setState({ rowIdsExpand: selectAll });
    } else {
      this.setState({ rowIdsExpand: [] });
    }
  };

  public render() {
    const { accounts, clientAccountTopLevelInfo, shouldNotShowMarketDataInfo } = this.props;
    const { isAllExpandedContentVisible } = this.state;
    const consolidatedAccountInfo = this.consolidatedAccountInfo(accounts);
    const totalMarketValueInCad = consolidatedAccountInfo?.consolidatedAccount?.totalMarketValueCad;

    const consolidatedData = buildDataArrayForConsolidatedPage(clientAccountTopLevelInfo, shouldNotShowMarketDataInfo);

    return (
      <BoxShadow>
        <Grid container>
          <SummarySplitGrid
            backgroundLeftColor={Colors.babyBlue}
            labelKeyLeft="common.totalMarketValue"
            valueLeft={
              <ComponentWithValidation
                value={totalMarketValueInCad}
                componentWithValue={<NumberQI value={totalMarketValueInCad} isMoney />}
              />
            }
            commentKeyLeft="common.valuesAreInCAD"
            colsOnRight={3}
            dataOnRightSide={consolidatedData}
          />
        </Grid>
        <Stack direction="row" my={1}>
          <LinkButton
            onClick={this.handleOnExpandAllButtonClick}
            endIcon={isAllExpandedContentVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            <Trans i18nKey={`common.${isAllExpandedContentVisible ? 'collapseAll' : 'expandAll'}`} />
          </LinkButton>
        </Stack>
        {this.renderTable(CurrencyEnum.CAD)}
      </BoxShadow>
    );
  }
}

function mapStateToProps(state: IAppRootState) {
  const { system } = state;

  return {
    culture: system.culture,
    isFetchingMarketData: getIsMarketDataFetching(state),
    didSetMarketDataRealtime: getDidSetMarketDataRealtime(state),
    isFetchingMarketDataHasError: getIsMarketDataFetchingHasError(state),
    isUserAdvisorOrAdmin: isUserAdvisorOrAdmin(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveHoldingSortPreferences,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConsolidatedView));
