import { action } from 'typesafe-actions';
import { ContestActionTypes, Contest } from '../contestTypes';

export const loadContest = () => action(ContestActionTypes.LOAD_CONTEST);
export const loadContestSuccessful = (campaign?: Contest) =>
  action(ContestActionTypes.LOAD_CONTEST_SUCCESSFUL, { campaign });
export const loadContestFailed = () => action(ContestActionTypes.LOAD_CONTEST_FAILED);

export const subscribeClientToContest = () => action(ContestActionTypes.SUBSCRIBE_CONTEST);
export const subscribeClientToContestSuccessful = () => action(ContestActionTypes.SUBSCRIBE_CONTEST_SUCCESSFUL);
export const subscribeClientToContestFailed = () => action(ContestActionTypes.SUBSCRIBE_CONTEST_FAILED);

export const incrementViewedContest = () => action(ContestActionTypes.INCREMENT_VIEWED_CONTEST);
export const incrementViewedContestSuccessful = () => action(ContestActionTypes.INCREMENT_VIEWED_CONTEST_SUCCESSFUL);
export const incrementViewedContestFailed = () => action(ContestActionTypes.INCREMENT_VIEWED_CONTEST_FAILED);

export const postponeContest = () => action(ContestActionTypes.POSTPONE_CONTEST);
export const postponeContestSuccessful = () => action(ContestActionTypes.POSTPONE_CONTEST_SUCCESSFUL);
export const postponeContestFailed = () => action(ContestActionTypes.POSTPONE_CONTEST_FAILED);

export const unsubscribeContest = () => action(ContestActionTypes.UNSUBSCRIBE_CONTEST);
export const unsubscribeContestSuccessful = () => action(ContestActionTypes.UNSUBSCRIBE_CONTEST_SUCCESSFUL);
export const unsubscribeContestFailed = () => action(ContestActionTypes.UNSUBSCRIBE_CONTEST_FAILED);
