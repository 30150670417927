import { getApiGatewayAuthority, getApiGatewayClientID, getApiGatewayRedirectUri } from '../../common/helpers';

export const oidcConfig = {
  authority: getApiGatewayAuthority(),
  client_id: getApiGatewayClientID(),
  redirect_uri: getApiGatewayRedirectUri(),
  response_type: 'code',
  scope: 'openid profile IA_Scopes',
  silent_redirect_uri: getApiGatewayRedirectUri(),
};
