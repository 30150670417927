import { MarketDataHttpClient } from '~/stores/marketData/services/marketDataHttpClient';
import { fetchProductDetailInfoFailure, fetchProductDetailInfoSuccessful } from '../actions/marketProductDetailAction';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getMarketIdentificationCode, getSymbol } from '~/common/helpers';
import { IMarketProductDetail, MarketProductDetailsActionTypes } from '../marketDataTypes';
import { setMarketDataTimestamp } from '../actions/marketDataActions';

export function* fetchMarketProductDetailInfo({ payload }: any) {
  const { type, symbol } = payload;
  try {
    const productDetailInfo = (yield call(
      MarketDataHttpClient.fetchMarketDataProductDetailedInfo,
      type,
      symbol,
    ) as unknown) as IMarketProductDetail;
    yield put(fetchProductDetailInfoSuccessful(symbol, productDetailInfo));
    yield put(setMarketDataTimestamp());
  } catch (err) {
    yield put(
      fetchProductDetailInfoFailure(symbol, {
        symbol: getSymbol(symbol),
        market: getMarketIdentificationCode(symbol),
        daysRange: {},
        weeks52Range: {},
      } as IMarketProductDetail),
    );
  }
}

function* marketProductDetailSaga() {
  yield takeLatest(
    MarketProductDetailsActionTypes.FETCH_MARKET_PRODUCT_DETAILS_INFO_DATA,
    fetchMarketProductDetailInfo,
  );
}

export default marketProductDetailSaga;
