import styled from 'styled-components';

export const ChangeAllContainer = styled.div`
    display: inline-grid;
    grid-template-columns: 42px auto;
    width: 242px;
    background-color: rgba(217, 236, 90, 1);
    height: 40px;
    justify-items: center;
    align-items: center;
    box-shadow:1px 1px 0px rgba(102, 102, 102, 0.349019607843137);
    cursor:pointer;
    font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans', sans-serif;
`;

export const DarkSection = styled.div`
    background-color:rgba(192, 202, 51, 1);
    place-self: stretch;
    grid-column: 1;
    grid-row: 1;
`;

export const ChangeAllText = styled.div`
    grid-column: 2;
    grid-row: 1;
    display:inline-block;
    text-align: center;
`;
