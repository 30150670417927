import { IPartyV1 } from '~/stores/party/partyTypes';

export interface ISystemState {
  readonly culture: string;
  readonly hasError: boolean;
  readonly token?: string;
  readonly tokenExpirationDate?: Date;
  readonly isClaimsFetching: boolean;
  readonly showDocumentDelivery: boolean;
  readonly showContactCard: boolean;
  readonly showAssetAllocationToggleButton: boolean;
  readonly isAssetBreakdown: boolean;
  readonly sessionExpiration: Date;
  readonly user?: IUser;
}

export interface IToken {
  partyId: string;
  accounts: string[];
  accounts_owned: string[];
  accounts_shared: string[];
  repCodes: string[];
  roles: string[];
}

export interface IUser extends IToken {
  party: IPartyV1;
}

export interface IQueryStringParameter {
  name: string;
  value: string;
}

export const enum UserType {
  Client,
  Advisor,
  Administrator,
}

export const enum UserRoles {
  Administrator = 'fw_ias_support',
}

export interface IPatchJsonOperation {
  op: string;
  path: string;
  value?: unknown;
}

export interface IFeatureToggles {
  showDocumentDelivery: boolean;
  showContactCard: boolean;
  showAssetAllocationToggleButton: boolean;
}

export const enum SystemActionTypes {
  SET_CULTURE = '[System] Set Culture',
  SET_QUERY_STRING_PARAMETERS = '[System] Set Query String Parameters',
  SYSTEM_GLOBAL_ERROR = '[System] Global Error',

  FETCH_INITIAL_DATA = '[System] Fetch Initial',

  FETCH_CLAIMS = '[System] Fetch Claims',
  FETCH_CLAIMS_SUCCESSFUL = '[System] Fetch Claims Successful',
  FETCH_CLAIMS_FAILED = '[System] Fetch Claims Failed',
  FETCH_CLAIMS_ABORTED = '[System] Fetch Claims Aborted',

  FETCH_PARTYID = '[System] Fetch Party ID',
  FETCH_PARTYID_SUCCESSFUL = '[System] Fetch Party ID Successful',
  FETCH_PARTYID_FAILED = '[System] Fetch Party ID Failed',
  FETCH_PARTYID_ABORTED = '[System] Fetch Party ID Aborted',

  FETCH_FEATURE_TOGGLE = '[System] Fetch Toggle Feature',
  FETCH_FEATURE_TOGGLE_SUCCESSFUL = '[System] Fetch Toggle Feature Successful',
  FETCH_FEATURE_TOGGLE_FAILED = '[System] Fetch Toggle Feature Failed',
  FETCH_FEATURE_TOGGLE_ABORTED = '[System] Fetch Toggle Feature Aborted',

  REFRESH_SESSION = '[System] Fetch Session',
  SESSION_MINUTES = 30,

  SET_ASSETALLOCATION_BREAKDONW = '[System] Set asset allocation breakdown view',
}
