import * as React from 'react';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { PositionSummaryTable } from '../../position-overview/summary-table/PositionSummaryTable';
import { ComponentWithValidation } from '../../position-overview/summary-table/ComponentWithValidation';

export const CurrencySummary = (currencyDetailsData: any) => {
  const { currencyData } = currencyDetailsData;
  return (
    <PositionSummaryTable
      rows={1}
      cols={3}
      positionSummaryContainerXS={8}
      data={[
        {
          labelKey: 'stockOverview.previousClose',
          positionValue: <ComponentWithValidation value={currencyData.previousClose} componentWithValue={<NumberQI isFormatLargeNumber value={currencyData.previousClose} isMoney />} />,
        },
      ]}
    />
  );
};
