import { Reducer } from 'redux';
import { IKYCState, KYCActionTypes, KYCDetailsResponseType, KYCStatusFetchSchemaType } from './kycTypes';
import { RemoveStorageByKey, SetStorageKeyValue } from '~/app/sessionManager';

export const initialState: IKYCState = {
  show: false,
  showMenu: false,
  showPromptFetching: false,
  showPromptLoaded: false,
  showPromptFail: false,
  reviewIds: [],
  reviewIdsDueToBeReminded: [],
  retryCount: 0,
  reviewKYCDetails: undefined,
  detailsFetching: false,
  detailsLoaded: false,
  detailsFail: false,
  isSavingRemindLater: false,
  isSavingRemindLaterSuccess: false,
  isSavingRemindLaterFail: false,
  isSavingKYCDetails: false,
  saveKYCDetailsFail: false,
};

const handleFetchingKYCStatus = (state: IKYCState): IKYCState => ({
  ...state,
  showPromptFetching: true,
});

const handleFetchStatus = (
  state: IKYCState,
  payload: { response: KYCStatusFetchSchemaType },
  success: boolean,
): IKYCState => {
  const { response } = payload;
  RemoveStorageByKey('isKYCSavedSuccessfully');
  RemoveStorageByKey('isKYCDetailsNoLongerNeeded');
  const kycReviewIdsDueToBeReminded = response?.kycReviewIdsDueToBeReminded || [];
  const kycReviewIds = response?.kycReviewIds || [];
  return {
    ...state,
    show: kycReviewIdsDueToBeReminded.length > 0,
    showMenu: kycReviewIds.length > 0,
    reviewIds: kycReviewIds,
    reviewIdsDueToBeReminded: kycReviewIdsDueToBeReminded,
    retryCount: response?.retryCount || 0,
    showPromptFail: !success,
    showPromptLoaded: success,
    showPromptFetching: false,
  } as IKYCState;
};

const handleSavingKYCStatus = (state: IKYCState): IKYCState => ({
  ...state,
  isSavingRemindLater: true,
});

const handleSavingKYCStatusSucess = (state: IKYCState): IKYCState => ({
  ...state,
  isSavingRemindLater: false,
  isSavingRemindLaterSuccess: true,
  isSavingRemindLaterFail: false,
});

const handleSavingKYCStatusFailure = (state: IKYCState): IKYCState => ({
  ...state,
  isSavingRemindLater: false,
  isSavingRemindLaterSuccess: false,
  isSavingRemindLaterFail: true,
});

const handleFetchingKYCDetails = (state: IKYCState): IKYCState => ({
  ...state,
  detailsFetching: true,
});

const handleFetchKYCDetails = (
  state: IKYCState,
  payload: { response: KYCDetailsResponseType | undefined },
  success: boolean,
): IKYCState => {
  const { response } = payload;
  return {
    ...state,
    detailsFetching: false,
    reviewKYCDetails: response,
    detailsLoaded: success,
    detailsFail: !success,
  } as IKYCState;
};

const handleSavingKYCDetails = (state: IKYCState): IKYCState =>
  ({
    ...state,
    isSavingKYCDetails: true,
    saveKYCDetailsFail: false,
  } as IKYCState);

const handleSaveKYCDetailsSuccess = (state: IKYCState): IKYCState => {
  SetStorageKeyValue('isKYCSavedSuccessfully', true);
  RemoveStorageByKey('isKYCDetailsNoLongerNeeded');
  return {
    ...state,
    show: false,
    showMenu: false,
    isSavingKYCDetails: false,
    saveKYCDetailsFail: false,
  } as IKYCState;
};
const handleIgnoreKYCSession = (state: IKYCState): IKYCState =>
  ({
    ...state,
    show: false,
    showMenu: true,
    reviewKYCDetails: undefined,
    isSavingKYCDetails: false,
    saveKYCDetailsFail: false,
  } as IKYCState);
const handleSaveKYCDetailsNoLongerNeeded = (state: IKYCState): IKYCState => {
  SetStorageKeyValue('isKYCSavedSuccessfully', true);
  SetStorageKeyValue('isKYCDetailsNoLongerNeeded', true);
  return {
    ...state,
    show: false,
    showMenu: false,
    isSavingKYCDetails: false,
    saveKYCDetailsFail: false,
  } as IKYCState;
};

const handleSaveKYCDetailsFailure = (state: IKYCState): IKYCState =>
  ({
    ...state,
    saveKYCDetailsFail: true,
    isSavingKYCDetails: false,
  } as IKYCState);

const handleKycCompleted = (state: IKYCState): IKYCState => {
  RemoveStorageByKey('isKYCSavedSuccessfully');
  RemoveStorageByKey('isKYCDetailsNoLongerNeeded');
  return {
    ...state,
    reviewIds: [],
    reviewIdsDueToBeReminded: [],
    reviewKYCDetails: undefined,
    show: false,
    showMenu: false,
  } as IKYCState;
};

export const kycReducer: Reducer<IKYCState> = (state = initialState, action) => {
  switch (action.type) {
    case KYCActionTypes.KYC_STATUS_FETCH:
      return handleFetchingKYCStatus(state);
    case KYCActionTypes.KYC_STATUS_FETCH_SUCCESSFUL:
      return handleFetchStatus(state, action.payload, true);
    case KYCActionTypes.KYC_STATUS_FETCH_FAILED:
      return handleFetchStatus(state, { response: { kycReviewIdsDueToBeReminded: [], kycReviewIds: [], retryCount: 0 } }, false);
    case KYCActionTypes.KYC_REMIND_LATER:
      return handleSavingKYCStatus(state);
    case KYCActionTypes.KYC_REMIND_LATER_SUCCESSFUL:
      return handleSavingKYCStatusSucess(state);
    case KYCActionTypes.KYC_REMIND_LATER_FAILED:
      return handleSavingKYCStatusFailure(state);
    case KYCActionTypes.KYC_DETAILS_FETCH:
      return handleFetchingKYCDetails(state);
    case KYCActionTypes.KYC_DETAILS_FETCH_SUCCESSFUL:
      return handleFetchKYCDetails(state, action.payload, true);
    case KYCActionTypes.KYC_DETAILS_FETCH_FAILED:
      return handleFetchKYCDetails(state, { response: undefined }, false);
    case KYCActionTypes.KYC_DETAILS_SAVE:
      return handleSavingKYCDetails(state);
    case KYCActionTypes.KYC_DETAILS_SAVE_SUCCESSFUL:
      return handleSaveKYCDetailsSuccess(state);
    case KYCActionTypes.KYC_DETAILS_SAVE_NO_LONGER_NEEDED:
      return handleSaveKYCDetailsNoLongerNeeded(state);
    case KYCActionTypes.KYC_DETAILS_SAVE_FAILED:
      return handleSaveKYCDetailsFailure(state);
    case KYCActionTypes.KYC_FORM_COMPLETED:
      return handleKycCompleted(state);
    case KYCActionTypes.KYC_FORM_IGNORE_SESSION:
      return handleIgnoreKYCSession(state);
    default:
      return state;
  }
};
