import * as React from 'react';
import { IAccount, IClientAccountSimplifiedDictionary } from '~/stores/account/accountTypes';
import Grid from '@mui/material/Grid';
import SummarySplitGrid from '~/common/components/account/account-view/SummarySplitGrid';
import Colors from '~/common/themes/colors';
import { ComponentWithValidation } from '~/common/components/position-overview/summary-table/ComponentWithValidation';
import NumberQI from '~/common/components/changeValue/NumberQI';
import RootAccounts from '../root-accounts/RootAccounts';
import { establishAccounts } from '~/common/accountsHelpers';
import memoizeOne from 'memoize-one';
import { buildDataArrayForInvestedCapitalPage } from '~/common/components/account/account-view/SummaryAccountViewHelper';
import { AccountViewTabEnum, ISortPreference } from '~/common/types';
import { useState } from 'react';
import { HoldingViewKeys, SortColumnKeys } from '~/stores/party/partyTypes';
import { SortDirection } from '~/app/appTypes';
import { GetStorageValueByKey, SetStorageKeyValue } from '~/app/sessionManager';

export interface IInvestedCapitalViewProps {
  accounts: IAccount[];
  clientAccountTopLevelInfo: IClientAccountSimplifiedDictionary;
  shouldNotShowMarketDataInfo: boolean;
  investedViewSortPreferences: ISortPreference;
}

const accountsMemoizeOne = memoizeOne(establishAccounts);

const InvestedCapitalView: React.FC<IInvestedCapitalViewProps> = ({
  accounts,
  clientAccountTopLevelInfo,
  shouldNotShowMarketDataInfo,
  investedViewSortPreferences,
}: IInvestedCapitalViewProps) => {
  const accountList = accountsMemoizeOne(accounts);
  const topLevelValuesToBeDisplayed = buildDataArrayForInvestedCapitalPage(
    clientAccountTopLevelInfo,
    shouldNotShowMarketDataInfo,
  );

  const invViewInSessionValue = GetStorageValueByKey(HoldingViewKeys.INVESTED_VIEW);
  const invViewInSession = invViewInSessionValue !== undefined ? JSON.parse(invViewInSessionValue) as ISortPreference : undefined;
  const mostUpdatedInvView = invViewInSession ?? investedViewSortPreferences;
  const [sortingPreferencesByTab, setSortingPreferencesByTab] = useState<ISortPreference>(mostUpdatedInvView);

  function onChangedTableSort(sortColumn: SortColumnKeys, sortDirection: SortDirection): void {
    // save current sorting preferences to session
    const sortingPreferencesByTabTemp: ISortPreference = { sortColumn, sortDirection };
    SetStorageKeyValue(HoldingViewKeys.INVESTED_VIEW, JSON.stringify(sortingPreferencesByTabTemp));

    setSortingPreferencesByTab((prevState) => ({
      ...prevState,
      sortColumn,
      sortDirection,
    }));
  }

  return (
    <>
      <Grid container style={{ padding: '0 16px' }}>
        <SummarySplitGrid
          backgroundLeftColor={Colors.babyBlue}
          labelKeyLeft="common.totalMarketValue"
          valueLeft={
            <ComponentWithValidation
              value={clientAccountTopLevelInfo.totalMarketValue}
              componentWithValue={<NumberQI value={clientAccountTopLevelInfo.totalMarketValue} isMoney />}
            />
          }
          commentKeyLeft="common.valuesAreInCAD"
          colsOnRight={3}
          dataOnRightSide={topLevelValuesToBeDisplayed}
        />
      </Grid>
      {accountList.map((rootAccounts) => (
        <RootAccounts
          {...rootAccounts}
          key={rootAccounts.rootId}
          shouldNotShowMarketDataInfo={shouldNotShowMarketDataInfo}
          viewType={AccountViewTabEnum.InvestedCapital}
          onChangedTableSort={onChangedTableSort}
          sortingPreferencesByTab={sortingPreferencesByTab}
        />
      ))}
    </>
  );
};

export default InvestedCapitalView;
