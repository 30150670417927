import styled from 'styled-components';
import { Steps } from 'antd';
import { StyledLinkButton } from '~/common/styles/baseStyle';

export const StyledDiv = styled.div`
  position: relative;
  background: #d9eaf8;
`;

export const CloseIconDiv = styled.span`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const Content = styled.div`
  padding-bottom: 30px;
  font-size: 14px;
  font-family: 'OpenSans', 'Open Sans', sans-serif;
`;

export const ChangeAllContent = styled.div`
  margin: 30px 30px 0px 30px;
  border: solid 4px rgb(239, 241, 244);
  padding: 26px 17px;
  div {
    box-sizing: border-box;
  }
`;

export const ButtonsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 30px 30px 0px 30px;
  vertical-alignment: top;
`;

export const RightDiv = styled.div`
  display: inline-block;
  text-align: right;
  width: 100%;
`;

export const InlineDiv = styled.div`
  position: absolute;
  width: 400px;
  margin-top: 100px;
  margin-left: 30px;
`;

export const Title = styled.p`
  color: #003da5;
  font-family: 'Montserrat-ExtraBold', 'Montserrat ExtraBold', 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0;
`;

export const Subtitle = styled.p`
  color: #333333;
`;

export const IAButtonStyle = {
  margin: '20px 0px 0px 0px',
  padding: '10px 20px',
};

export const StyledSteps = styled(Steps)`
  margin-bottom: 19px !important;
`;

export const ChangeAllTitle = styled.span`
  color: #1b1b1b;
  font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans', sans-serif;
`;

export const ChangeAllTableContent = styled.span`
  color: #333333;
`;

export const HorizontalLine = styled.hr`
  height: 1px;
  background-color: #bbc2ca;
  margin: 7px 0px;
  border-width: 0;
`;

export const CheckCircleIconStyle = {
  fontSize: '70px',
  color: '#7cb342',
};

export const CloseIconStyle = {
  fontSize: '32px',
  color: '#003da5',
};

export const ConfirmContainer = styled.div`
  text-align: center;
  width: 100%;
`;

export const ConfirmationP = styled.p`
  margin: 0px;
  color: #333333;
`;

export const ConfirmationTitle = styled(ConfirmationP)`
  font-size: 24px;
  font-weight: bold;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
`;

export const ConfirmationMessage = styled(ConfirmationP)`
  margin: 0px 88px 17px 88px;
`;

export const ConfirmationButton = styled(StyledLinkButton)``;
