import { IAccount, IAccountDictionnary, ICashFlow, IPosition, IRegisteredAccount } from '~/stores/account/accountTypes';
import { IPositionAggregate } from '~/pages/holdings-page/consolidated-view/ConsolidatedPositionDrilldown';
import { IConsolidatedAccountInfo } from '~/pages/holdings-page/consolidated-view/ConsolidatedView';
import { IntradayPositionTypes, CurrencyEnum, IRootAccounts } from '~/common/types';

export const establishAccounts = (accounts: IAccount[]): IRootAccounts[] => {
  const accountRoots = geRootFromAccount(accounts);

  const sortedAccounts = accountRoots.map((rootId) => {
    const accountsOfRoot = accounts.filter((a) => a.rootId === rootId);
    let totalMarketValueCad = 0;

    if (accountsOfRoot) {
      const totalMarketValuesCad = accountsOfRoot.map((account: IAccount) => account.totalMarketValueCad);
      totalMarketValueCad +=
        totalMarketValuesCad.length > 0 ? totalMarketValuesCad.reduce((acc, total) => (acc += total)) : 0;
    }

    return {
      rootId,
      totalMarketValueCad,
      accounts: accountsOfRoot,
    } as IRootAccounts;
  });

  return sortedAccounts;
};

export const filterAccounts = (accounts: IAccount[], filteredIds: string[]): IAccount[] => {
  if (!filteredIds || filteredIds.length <= 0) {
    return accounts;
  }

  const filteredAccounts = accounts.filter((a) => filteredIds.some((id) => id === a.id));

  return filteredAccounts;
};

export const isAllAccountSameCurrency = (accounts: IAccount[], currency: string): boolean => {
  if (accounts.every((a) => a.currency === currency)) {
    return true;
  }
  return false;
};

export const getTotalMarketValueInCurrency = (accounts: IAccount[], currency: string): number => {
  let totalMarketValue = 0;

  if (currency === 'CAD') {
    accounts.forEach((a) => {
      totalMarketValue = +(totalMarketValue + a.totalMarketValueCad).toFixed(2);
    });
  } else {
    accounts.forEach((a) => {
      totalMarketValue = +(totalMarketValue + a.totalMarketValueUsd).toFixed(2);
    });
  }

  return totalMarketValue;
};

export const getAccountsFromRoot = (accounts: IAccount[], rootId: string[]): IAccount[] => {
  if (!rootId || rootId.length <= 0) {
    return accounts;
  }

  const filteredAccounts = accounts.filter((a) => rootId.some((id) => id === a.rootId));

  return filteredAccounts;
};

export const getAccountsFromRootDictionary = (
  accountsDictionary: IAccountDictionnary,
  rootId: string[],
): IAccount[] => {
  const accounts = Object.values(accountsDictionary);

  return getAccountsFromRoot(accounts, rootId);
};

export const getSelectedAccountIds = (selectedAccounts: string[], accounts: IAccount[]): string[] => {
  if (selectedAccounts !== null && selectedAccounts.length > 0) {
    return selectedAccounts;
  }
  return accounts && accounts.length > 0 ? accounts.map((m) => m.id) : [];
};

export const filterRegisteredAccounts = (
  filteredAccountIds: string[],
  accountids: string[],
  registeredAccounts: IRegisteredAccount[],
): IRegisteredAccount[] => {
  let registeredAccountsInfilter = registeredAccounts;

  if (accountids != null && accountids.length > 0) {
    registeredAccountsInfilter = registeredAccounts.filter((a) => accountids.some((id) => id === a.account.id));
  }

  if (filteredAccountIds === null || filteredAccountIds.length === 0) {
    return registeredAccountsInfilter;
  }

  return registeredAccountsInfilter.filter((a) => filteredAccountIds.some((id) => id === a.account.id));
};

export const geRootFromAccount = (accounts: IAccount[]): string[] => {
  const rootsID = accounts.map((m) => (m.rootId === undefined ? '' : m.rootId));
  return rootsID.filter(unique);
};

export const getRepcodes = (accounts: IAccount[]): string[] => {
  const repcodes = accounts.map((m) => m.repCode);
  return repcodes.filter(unique);
};

export const getAccountName = (accounts: IAccount[], rootId: string[]) => {
  const childAccounts = getAccountsFromRoot(accounts, rootId);
  if (childAccounts !== undefined && childAccounts.length > 0) {
    return childAccounts[0].overviewName;
  }
  return '';
};
export const getOpenAccounts = (accounts: IAccount[]) => accounts.filter((w) => w.isOpen);

const unique = (value: any, index: number, self: any) => self.indexOf(value) === index;

export const accountHasPositionWithAccruedInterest = (accounts: IAccount[]): boolean => {
  for (const a of accounts) {
    if (a.positions && a.positions.length > 0) {
      const hasAccruedInterest = (a.positions as any).filter((p: any) => p.accruedInterest);
      if (hasAccruedInterest.length > 0) {
        return true;
      }
    }
  }
  return false;
};

export const accountHasNonDeterminable = (accounts: IAccount[]): boolean =>
  accounts.some(
    (account: IAccount) =>
      account.positions.some(
        (position: IPosition) =>
          position.individualMarketPrice === 0 ||
          position.individualMarketPrice === undefined ||
          position.individualMarketPrice === null,
      ),
    // eslint-disable-next-line function-paren-newline
  );

interface IGetAccountPositionChangeValues extends IGetAccountPositionChangeValuesResponse {
  account: IAccount;
  isFetchingMarketDataHasError: boolean;
}

interface IGetAccountPositionChangeValuesResponse {
  dollarChange: number | undefined;
  changePercent: number | undefined;
}

export const getAccountPositionChangeValues = ({
  account,
  dollarChange,
  changePercent,
  isFetchingMarketDataHasError,
}: IGetAccountPositionChangeValues): IGetAccountPositionChangeValuesResponse => {
  const currentTotal =
    account.currency === CurrencyEnum.CAD ? account.totalMarketValueCad : account.totalMarketValueUsd;
  const previousTotal =
    account.currency === CurrencyEnum.CAD ? account.totalPreviousMarketValueCad : account.totalPreviousMarketValueUsd;
  const isUndefined = currentTotal - previousTotal === 0 && isFetchingMarketDataHasError;
  return {
    dollarChange: isUndefined ? undefined : dollarChange,
    changePercent: isUndefined ? undefined : changePercent,
  };
};

export const establishConsolidatedAccountInfo = (accounts: IAccount[]): IConsolidatedAccountInfo => {
  if (!accounts || accounts.length <= 0) {
    return {} as IConsolidatedAccountInfo;
  }

  const consolidatedAccount = {
    ...accounts[0],
    positions: [] as IPosition[],
    marginValueCad: 0,
    cashBalanceCad: 0,
  };
  const positionAggregates = [] as IPositionAggregate[];

  accounts.forEach((account) => {
    consolidatedAccount.cashBalanceCad += account.cashBalanceCad;
    consolidatedAccount.marginValueCad += !account.marginValueCad ? 0 : account.marginValueCad;

    if (account.positions && account.positions.length > 0) {
      account.positions.forEach((position) => {
        positionAggregates.push({
          positionSymbol: position.symbol,
          accountOwner: account.name,
          accountType: account.accountType,
          accountId: account.id,
          quantity: position.quantity,
          marketPrice: position.individualMarketPrice,
          marketPriceCurrency: position.marketPriceCurrency,
          marketValue: position.totalMarketPriceCad,
          security: position.security,
          alternateType: position.alternateType,
          changeValue: position.changeValue,
          changePercent: position.changePercent,
        });

        const accumulatorPosition = consolidatedAccount.positions.find(
          (accPos) => accPos.security === position.security,
        );

        if (!accumulatorPosition) {
          const newConsolidatedPosition = { ...position, totalMarketPriceUsd: position.totalMarketPriceCad };

          consolidatedAccount.positions.push(newConsolidatedPosition);
        } else {
          accumulatorPosition.quantity += position.quantity;
          accumulatorPosition.totalMarketPriceCad += position.totalMarketPriceCad;
          accumulatorPosition.totalMarketPriceUsd += position.totalMarketPriceUsd;
        }
      });
    }
  });

  consolidatedAccount.totalMarketValueCad = accounts.reduce((a, b) => a + b.totalMarketValueCad, 0);

  consolidatedAccount.positions.sort(comparePositions);

  return {
    consolidatedAccount,
    positionAggregates,
  };
};

export const getIntradaySymbols = (accounts: IAccount[]): string[] => {
  const symbols: string[] = [];
  accounts.forEach((a) => {
    symbols.push(
      ...a.positions
        .filter(
          (w) =>
            w.investmentCategory === 'EQ' &&
            Object.values(IntradayPositionTypes).includes(w.alternateType as IntradayPositionTypes),
        )
        .map((m) => `${m.marketPriceSource}:${m.symbol}`),
    );
  });

  return symbols.filter(unique);
};

const comparePositions = (a: IPosition, b: IPosition) => a.symbol.localeCompare(b.symbol);

export const isCashFlowValid = (cashFlow: ICashFlow): boolean => Object.keys(cashFlow.exchangeRate).length > 0;
