import * as React from 'react';
import NumberQI from '~/common/components/changeValue/NumberQI';
import { PositionSummaryTable } from '../../position-overview/summary-table/PositionSummaryTable';
import { ComponentWithValidation } from '../../position-overview/summary-table/ComponentWithValidation';

export const IndexSummary = (indexDetailsData: any) => {
  const { indexData } = indexDetailsData;
  return (
    <PositionSummaryTable
      rows={2}
      cols={3}
      positionSummaryContainerXS={8}
      data={[
        {
          labelKey: 'stockOverview.previousClose',
          positionValue: (
            <ComponentWithValidation
              value={indexData.previousClose}
              componentWithValue={<NumberQI isFormatLargeNumber value={indexData.previousClose} isMoney noDollarSign />}
            />
          ),
        },
        {
          labelKey: 'stockOverview.dayRange',
          positionValue: (
            <>
              <ComponentWithValidation
                value={indexData.daysRange.low}
                componentWithValue={
                  <NumberQI isFormatLargeNumber value={indexData.daysRange.low} isMoney noDollarSign />
                }
              />{' '}
              -{' '}
              <ComponentWithValidation
                value={indexData.daysRange.high}
                componentWithValue={
                  <NumberQI isFormatLargeNumber value={indexData.daysRange.high} isMoney noDollarSign />
                }
              />{' '}
            </>
          ),
        },
        {
          labelKey: 'stockOverview.52WeekRange',
          positionValue: (
            <>
              <ComponentWithValidation
                value={indexData.weeks52Range.low}
                componentWithValue={
                  <NumberQI isFormatLargeNumber value={indexData.weeks52Range.low} isMoney noDollarSign />
                }
              />{' '}
              -{' '}
              <ComponentWithValidation
                value={indexData.weeks52Range.high}
                componentWithValue={
                  <NumberQI isFormatLargeNumber value={indexData.weeks52Range.high} isMoney noDollarSign />
                }
              />{' '}
            </>
          ),
        },

        {
          labelKey: 'stockOverview.open',
          positionValue: (
            <>
              <ComponentWithValidation
                value={indexData.open}
                componentWithValue={<NumberQI isFormatLargeNumber value={indexData.open} isMoney noDollarSign />}
              />
            </>
          ),
        },
        {
          labelKey: 'marketProductDetails.daysVolume',
          positionValue: (
            <>
              <ComponentWithValidation
                value={indexData.daysVolume}
                componentWithValue={<NumberQI isFormatLargeNumber value={indexData.daysVolume} noDollarSign />}
              />
            </>
          ),
        },
        // {
        //   labelKey: 'marketProductDetails.oneMonthAverage',
        //   positionValue: <><ComponentWithValidation value={indexData.month1AverageVolume} componentWithValue={<NumberQI isFormatLargeNumber value={indexData.month1AverageVolume} isMoney />} /> </>,
        // },
      ]}
    />
  );
};
