import {
  emailNotificationDocumentAvailableKey,
  updateDeliveryPreferencesKey,
  marketDataRealtimeKey,
  system,
  consentAvailableKeys,
  consentPromptKey,
  intradayChangePreferenceKey,
  holdingSortPreferencesKey,
} from '~/common/constants';
import { HoldingViewKeys, IConsentPromptMetadata, IMetadata, ISortViewsMetadata, IUpdateDeliveryPreferencesMetadata } from '~/stores/party/partyTypes';
import { ISortPreference } from '../types';

export const UpdateEmailNotificationDocumentAvailableKey = (metadatas: IMetadata[], value: boolean): IMetadata[] => {
  const formattedValue = [String(value)];
  const updatedMetadata = getUpdatedMetadata(metadatas, emailNotificationDocumentAvailableKey, formattedValue);
  return updatedMetadata;
};

export const UpdateDeliveryPreferences = (
  metadatas: IMetadata[],
  value: IUpdateDeliveryPreferencesMetadata,
): IMetadata[] => {
  const formattedValue = [JSON.stringify(value)];
  const updatedMetadata = getUpdatedMetadata(metadatas, updateDeliveryPreferencesKey, formattedValue);
  return updatedMetadata;
};

export const UpdateHoldingSortPreferences = (
  metadatas: IMetadata[],
  value: ISortViewsMetadata,
): IMetadata[] => {
  const formattedValue = [JSON.stringify(value)];
  const updatedMetadata = getUpdatedSortAccumulatedMetadata(metadatas, formattedValue);
  return updatedMetadata;
};

export const UpdateConsentPrompt = (metadatas: IMetadata[], value: IConsentPromptMetadata): IMetadata[] => {
  const formattedValue = [JSON.stringify(value)];
  const updatedMetadata = getUpdatedMetadata(metadatas, consentPromptKey, formattedValue);
  return updatedMetadata;
};

export const UpdateMarketDataRealtimeKey = (metadatas: IMetadata[], value: boolean): IMetadata[] => {
  const formattedValue = [String(value)];
  const updatedMetadata = getUpdatedMetadata(metadatas, marketDataRealtimeKey, formattedValue);
  return updatedMetadata;
};

export const UpdateIntradayChangePreferenceKey = (metadatas: IMetadata[], value: boolean): IMetadata[] => {
  const formattedValue = [String(value)];
  const updatedMetadata = getUpdatedMetadata(metadatas, intradayChangePreferenceKey, formattedValue);
  return updatedMetadata;
};

export const GetDidConsentToMailNotification = (metadatas: IMetadata[]): boolean => {
  const item = metadatas.find((m) => m.key === emailNotificationDocumentAvailableKey && m.system === system);
  if (item === undefined) {
    return true;
  }
  return item.value[0] === 'true';
};

export const GetDidConsentValueByKey = (
  metadatas: IMetadata[],
  consentKey: consentAvailableKeys,
): boolean | undefined => {
  const item = metadatas.find((m) => m.key === consentKey);
  if (item === undefined) {
    return undefined;
  }
  return item?.value[0].toLowerCase() === 'true';
};

export const GetDidSetMarketDataRealtime = (metadatas: IMetadata[]): boolean => {
  if (!metadatas || Object.values(metadatas).length === 0) {
    return false;
  }

  const item = metadatas.find((m) => m.key === marketDataRealtimeKey && m.system === system);
  return item === undefined || item.value[0] === 'true';
};

export const GetDidSetIntradayChangePref = (metadatas: IMetadata[]): boolean => {
  if (!metadatas || Object.values(metadatas).length === 0) {
    return false;
  }

  const item = metadatas.find((m) => m.key === intradayChangePreferenceKey && m.system === system);
  return item === undefined || item.value[0] === 'true';
};

export const GetHoldingSortPreferences = (metadatas: IMetadata[]): Record<HoldingViewKeys, ISortPreference> => {
  if (!metadatas || Object.values(metadatas).length === 0) {
    return { accountView: {}, investedView: {}, bookCostView: {}, consolidateView: {} } as Record<HoldingViewKeys, ISortPreference>;
  }

  const item = metadatas.find((m) => m.key === holdingSortPreferencesKey && m.system === system);
  return item === undefined || item.value[0] === undefined ? { accountView: {}, investedView: {}, bookCostView: {}, consolidateView: {} } as Record<HoldingViewKeys, ISortPreference> : JSON.parse(item.value[0]) as Record<HoldingViewKeys, ISortPreference>;
};

export const GetConsentPrompt = (metadatas: IMetadata[]): IConsentPromptMetadata => getUpdateConsentPrompt(metadatas);

export const GetShowConsentPrompt = (metadatas: IMetadata[]): boolean => {
  const consentPrompt = getUpdateConsentPrompt(metadatas);
  const postponements = consentPrompt.postponements ?? 0;
  const userHasPostponed3Times = postponements >= 3;
  if (!consentPrompt.show || userHasPostponed3Times) {
    return false;
  }
  if (!consentPrompt.showOn) {
    return true;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return consentPrompt.showOn <= today;
};

const getUpdatedMetadata = (metadatas: IMetadata[], key: string, formattedValue: string[]): IMetadata[] => {
  const existingMetadata = metadatas.find((m) => m.key === key && m.system === system);

  if (existingMetadata) {
    existingMetadata.value = formattedValue;
    existingMetadata.lastModified = new Date();
  } else {
    const newMetadata = getNewMetadata(key, formattedValue);
    metadatas.push(newMetadata);
  }

  return metadatas;
};

const getUpdatedSortAccumulatedMetadata = (metadatas: IMetadata[], formattedValue: string[]): IMetadata[] => {
  const existingMetadata = metadatas.find((m) => m.key === holdingSortPreferencesKey && m.system === system);
  if (existingMetadata) {
    const existingValue: ISortViewsMetadata = JSON.parse(existingMetadata.value[0]);
    if (existingValue) {
      const newValue: ISortViewsMetadata = JSON.parse(formattedValue[0]);
      if (newValue.accountView) {
        existingMetadata.value[0] = JSON.stringify({ ...JSON.parse(existingMetadata.value[0]), accountView: newValue.accountView });
      }
      if (newValue.investedView) {
        existingMetadata.value[0] = JSON.stringify({ ...JSON.parse(existingMetadata.value[0]), investedView: newValue.investedView });
      }
      if (newValue.bookCostView) {
        existingMetadata.value[0] = JSON.stringify({ ...JSON.parse(existingMetadata.value[0]), bookCostView: newValue.bookCostView });
      }
      if (newValue.consolidateView) {
        existingMetadata.value[0] = JSON.stringify({ ...JSON.parse(existingMetadata.value[0]), consolidateView: newValue.consolidateView });
      }
    }
    existingMetadata.lastModified = new Date();
  } else {
    const newMetadata = getNewMetadata(holdingSortPreferencesKey, formattedValue);
    metadatas.push(newMetadata);
  }
  return metadatas;
};

const getNewMetadata = (key: string, value: string[]): IMetadata => ({
  key,
  value,
  system,
  lastModified: new Date(),
});

const getUpdateConsentPrompt = (metadatas: IMetadata[]): IConsentPromptMetadata =>
  getBaseShowPrompt(metadatas, consentPromptKey);

const getBaseShowPrompt = (metadatas: IMetadata[], key: string) => {
  const metadata = metadatas.find((m) => m.key === key);

  if (!metadata) {
    return { show: true, showOn: undefined };
  }

  const value = JSON.parse(metadata.value[0]);
  if (value.showOn) {
    value.showOn = new Date(Date.parse(value.showOn));
    value.showOn.setHours(0, 0, 0, 0);
  }
  return value;
};
