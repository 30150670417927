import * as React from 'react';
import styled from 'styled-components';

const Label = styled.label`
    font-family: 'OpenSans-Bold', 'Open Sans' !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: 700 !important;
`;

export interface ILabelFormProps {
    name: string,
    label: any
}

const LabelForm: React.FC<ILabelFormProps> = ({ name, label }) => (
    <Label htmlFor={name}>{label}</Label>
);

export default LabelForm;
